// @flow

/* Modified by custom plugin at "@web-app/codegen/codegen-plugin" */ 

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  DateTime: any,
  /** The `Long` scalar type represents non-fractional signed whole numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: any,
  /** The `Upload` scalar type represents a part name of multipart request to a File. The Upload type is only meant to be use as an input, not as an output. */
  Upload: any,
  /** Void scalar */
  Void: any,
};

export type ActivityLog = {
  ...Model,
  ...Owned,
  ...{
     __typename: 'ActivityLog',
    entity: EntityPayload,
    translationKey: $ElementType<Scalars, 'String'>,
    parameters: Array<Parameter>,
    id: $ElementType<Scalars, 'ID'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    deletedAt?: ?$ElementType<Scalars, 'DateTime'>,
    createdBy?: ?UserPayload,
    updatedBy?: ?UserPayload,
    deletedBy?: ?UserPayload,
    ownedBy: OrganizationPayload,
  }
};

export const ActivityLogActionValues = Object.freeze({
  /** Delete */
  Delete: 'Delete', 
  /** Create */
  Create: 'Create', 
  /** UpdateField */
  UpdateField: 'UpdateField', 
  /** archived */
  Archived: 'archived', 
  /** Unarchived */
  Unarchived: 'Unarchived', 
  /** Tags */
  Tags: 'Tags', 
  /** Documents */
  Documents: 'Documents', 
  /** FieldValues */
  FieldValues: 'FieldValues', 
  /** Forwarders */
  Forwarders: 'Forwarders', 
  /** ReviseDate */
  ReviseDate: 'ReviseDate', 
  /** UnReviseDate */
  UnReviseDate: 'UnReviseDate'
});


export type ActivityLogAction = $Values<typeof ActivityLogActionValues>;

export type ActivityLogCreateInput = {
  translationKey: $ElementType<Scalars, 'String'>,
};

export type ActivityLogFilterInput = {
  query?: ?$ElementType<Scalars, 'String'>,
  ownerId?: ?$ElementType<Scalars, 'ID'>,
  excludeIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  createdAt?: ?DateRangeInput,
  updatedAt?: ?DateRangeInput,
  userId?: ?$ElementType<Scalars, 'ID'>,
  action?: ?ActivityLogAction,
  entityType?: ?$ElementType<Scalars, 'String'>,
  entityIdentifier?: ?$ElementType<Scalars, 'String'>,
  oldValue?: ?$ElementType<Scalars, 'String'>,
  newValue?: ?$ElementType<Scalars, 'String'>,
};

export type ActivityLogPayload = ActivityLog | BadRequest | Forbidden | NotFound;

export type ActivityLogPayloadPaginatedSearch = {
  ...Paginated,
  ...{
     __typename: 'ActivityLogPayloadPaginatedSearch',
    nodes: Array<ActivityLogPayload>,
    hits: Array<Hit>,
    page: $ElementType<Scalars, 'Int'>,
    perPage: $ElementType<Scalars, 'Int'>,
    totalPage: $ElementType<Scalars, 'Int'>,
    count: $ElementType<Scalars, 'Int'>,
    totalCount: $ElementType<Scalars, 'Int'>,
  }
};

export type ActivityLogPayloadScrolledSearch = {
  ...Scrolled,
  ...{
     __typename: 'ActivityLogPayloadScrolledSearch',
    nodes: Array<ActivityLogPayload>,
    hits: Array<Hit>,
    scrollId: $ElementType<Scalars, 'String'>,
    size: $ElementType<Scalars, 'Int'>,
    count: $ElementType<Scalars, 'Int'>,
    totalCount: $ElementType<Scalars, 'Int'>,
  }
};

export type ActivityLogSortInput = {
  createdAt?: ?SortOrder,
  updatedAt?: ?SortOrder,
};

// This has been truncated by a custom Zenport plugin
export const AirportValues = Object.freeze({
  /** Anaa Airport */
  Aaa: 'AAA'
});


export type Airport = $Values<typeof AirportValues>;

export type Authenticated = {
   __typename: 'Authenticated',
  authenticated: $ElementType<Scalars, 'Boolean'>,
  authenticatedMfa: $ElementType<Scalars, 'Boolean'>,
  mfaType?: ?$ElementType<Scalars, 'String'>,
};

export const AutoTrackingByTypeValues = Object.freeze({
  /** houseBlNo */
  HouseBlNo: 'HouseBlNo', 
  /** masterBlNo */
  MasterBlNo: 'MasterBlNo', 
  /** bookingNo */
  BookingNo: 'BookingNo'
});


export type AutoTrackingByType = $Values<typeof AutoTrackingByTypeValues>;

export const AutoTrackingTransportTypeValues = Object.freeze({
  /** Air */
  Air: 'Air', 
  /** Sea */
  Sea: 'Sea', 
  /** Courier */
  Courier: 'Courier'
});


export type AutoTrackingTransportType = $Values<typeof AutoTrackingTransportTypeValues>;

export type BadRequest = {
   __typename: 'BadRequest',
  violations: Array<Violation>,
};

export type Batch = {
  ...Model,
  ...Connected,
  ...Owned,
  ...Package,
  ...Tagged,
  ...Customizable,
  ...Memorizable,
  ...Sortable,
  ...{
     __typename: 'Batch',
    archived: $ElementType<Scalars, 'Boolean'>,
    no: $ElementType<Scalars, 'String'>,
    orderItem: OrderItemPayload,
    shipment?: ?ShipmentPayload,
    shipmentSort: $ElementType<Scalars, 'Int'>,
    container?: ?ContainerPayload,
    containerSort: $ElementType<Scalars, 'Int'>,
    quantity: $ElementType<Scalars, 'Float'>,
    producedQuantity?: ?$ElementType<Scalars, 'Float'>,
    preShippedQuantity?: ?$ElementType<Scalars, 'Float'>,
    shippedQuantity?: ?$ElementType<Scalars, 'Float'>,
    postShippedQuantity?: ?$ElementType<Scalars, 'Float'>,
    deliveredQuantity?: ?$ElementType<Scalars, 'Float'>,
    quantityUnit: $ElementType<Scalars, 'String'>,
    latestQuantity: $ElementType<Scalars, 'Float'>,
    latestQuantityField: $ElementType<Scalars, 'String'>,
    packageQuantity?: ?$ElementType<Scalars, 'Float'>,
    autoCalculatePackageQuantity: $ElementType<Scalars, 'Boolean'>,
    producedAt?: ?$ElementType<Scalars, 'DateTime'>,
    deliveredAt?: ?$ElementType<Scalars, 'DateTime'>,
    expiredAt?: ?$ElementType<Scalars, 'DateTime'>,
    desiredAt?: ?$ElementType<Scalars, 'DateTime'>,
    /** The batch's latest quantity multiplied by its order item's price suffixed by its order item's currency */
  newTotalPrice?: ?NewPricePayload,
    totalVolume: MetricValue,
    /** Whether the batch's total price is overridden by the user */
  totalPriceOverriding: $ElementType<Scalars, 'Boolean'>,
    /** Overridden batch's total price value. */
  totalPriceOverride?: ?NewPricePayload,
    totalVolumeOverride?: ?MetricValue,
    totalVolumeOverriding: $ElementType<Scalars, 'Boolean'>,
    timeline: Timeline,
    id: $ElementType<Scalars, 'ID'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    deletedAt?: ?$ElementType<Scalars, 'DateTime'>,
    createdBy?: ?UserPayload,
    updatedBy?: ?UserPayload,
    deletedBy?: ?UserPayload,
    connectionBy?: ?ConnectionPayload,
    ownedBy: OrganizationPayload,
    packageName?: ?$ElementType<Scalars, 'String'>,
    packageGrossWeight?: ?MetricValue,
    packageVolume?: ?MetricValue,
    autoCalculatePackageVolume: $ElementType<Scalars, 'Boolean'>,
    packageSize?: ?Size,
    packageCapacity?: ?$ElementType<Scalars, 'Float'>,
    packageCapacityUnit?: ?$ElementType<Scalars, 'String'>,
    tags: Array<TagPayload>,
    customFields: CustomFields,
    memo?: ?$ElementType<Scalars, 'String'>,
    sort: $ElementType<Scalars, 'Int'>,
  }
};

export type BatchBulkFilterInput = {
  nos?: ?ValuesWithMatchModeInput,
};

export type BatchCreateInput = {
  no: $ElementType<Scalars, 'String'>,
  quantity: $ElementType<Scalars, 'Float'>,
  producedQuantity?: ?$ElementType<Scalars, 'Float'>,
  preShippedQuantity?: ?$ElementType<Scalars, 'Float'>,
  shippedQuantity?: ?$ElementType<Scalars, 'Float'>,
  postShippedQuantity?: ?$ElementType<Scalars, 'Float'>,
  deliveredQuantity?: ?$ElementType<Scalars, 'Float'>,
  quantityUnit?: ?$ElementType<Scalars, 'String'>,
  producedAt?: ?$ElementType<Scalars, 'DateTime'>,
  deliveredAt?: ?$ElementType<Scalars, 'DateTime'>,
  expiredAt?: ?$ElementType<Scalars, 'DateTime'>,
  desiredAt?: ?$ElementType<Scalars, 'DateTime'>,
  packageQuantity?: ?$ElementType<Scalars, 'Float'>,
  autoCalculatePackageQuantity?: ?$ElementType<Scalars, 'Boolean'>,
  packageName?: ?$ElementType<Scalars, 'String'>,
  packageGrossWeight?: ?MetricValueInput,
  packageVolume?: ?MetricValueInput,
  autoCalculatePackageVolume?: ?$ElementType<Scalars, 'Boolean'>,
  packageSize?: ?SizeInput,
  packageCapacity?: ?$ElementType<Scalars, 'Float'>,
  packageCapacityUnit?: ?$ElementType<Scalars, 'String'>,
  memo?: ?$ElementType<Scalars, 'String'>,
  totalPriceOverride?: ?PriceInput,
  totalPriceOverriding?: ?$ElementType<Scalars, 'Boolean'>,
  totalVolumeOverride?: ?MetricValueInput,
  totalVolumeOverriding?: ?$ElementType<Scalars, 'Boolean'>,
  /** Deprecated */
  tagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  tags?: ?Array<EntityTagInput>,
  followerIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  customFields?: ?CustomFieldsInput,
  orderItemId: $ElementType<Scalars, 'ID'>,
  shipmentId?: ?$ElementType<Scalars, 'ID'>,
  containerId?: ?$ElementType<Scalars, 'ID'>,
};

export type BatchEqualSplitInput = {
  divideBy: $ElementType<Scalars, 'Int'>,
  precision: $ElementType<Scalars, 'Int'>,
};

export type Batches = {
   __typename: 'Batches',
  batches: Array<Batch>,
};

export type BatchesPayload = Batches | BadRequest | Forbidden | NotFound;

export type BatchFilterInput = {
  query?: ?$ElementType<Scalars, 'String'>,
  ownerId?: ?$ElementType<Scalars, 'ID'>,
  excludeIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  createdAt?: ?DateRangeInput,
  updatedAt?: ?DateRangeInput,
  customFields?: ?Array<EntityCustomFieldInput>,
  connectionIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  bulkFilter?: ?BatchBulkFilterInput,
  keywords?: ?ValuesWithMatchModeInput,
  archived?: ?$ElementType<Scalars, 'Boolean'>,
  ids?: ?Array<$ElementType<Scalars, 'ID'>>,
  producedAt?: ?DateRangeInput,
  deliveredAt?: ?DateRangeInput,
  expiredAt?: ?DateRangeInput,
  desiredAt?: ?DateRangeInput,
  hasShipment?: ?$ElementType<Scalars, 'Boolean'>,
  hasShipmentExcludeId?: ?$ElementType<Scalars, 'ID'>,
  /** Deprecated */
  tagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  tagIdsWithOperator?: ?IdsWithOperatorInput,
  notTagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  orderIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  orderExporterIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  orderImporterIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  orderSharedPartnerOrganizationIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  orderTagIdsWithOperator?: ?IdsWithOperatorInput,
  orderNotTagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  orderItemId?: ?$ElementType<Scalars, 'ID'>,
  orderItemIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  productIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  productProviderIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  productProviderSupplierIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  containerIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  containerWarehouseIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  shipmentIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  shipmentFollowerIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  shipmentTagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  shipmentNotTagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  shipmentArchived?: ?$ElementType<Scalars, 'Boolean'>,
  cargoReadyInitialDate?: ?DateRangeInput,
  shipmentCargoReadyInitialDate?: ?DateRangeInput,
  shipmentCargoReadyResultDate?: ?DateRangeInput,
  shipmentCargoReadyCurrentDate?: ?DateRangeInput,
  shipmentLoadTypes?: ?Array<LoadType>,
  shipmentLoadPortDepartureInitialDate?: ?DateRangeInput,
  shipmentLoadPortDepartureResultDate?: ?DateRangeInput,
  shipmentLoadPortDepartureCurrentDate?: ?DateRangeInput,
  shipmentFirstTransitPortArrivalInitialDate?: ?DateRangeInput,
  shipmentFirstTransitPortArrivalResultDate?: ?DateRangeInput,
  shipmentFirstTransitPortArrivalCurrentDate?: ?DateRangeInput,
  shipmentFirstTransitPortDepartureInitialDate?: ?DateRangeInput,
  shipmentFirstTransitPortDepartureResultDate?: ?DateRangeInput,
  shipmentFirstTransitPortDepartureCurrentDate?: ?DateRangeInput,
  shipmentSecondTransitPortArrivalInitialDate?: ?DateRangeInput,
  shipmentSecondTransitPortArrivalResultDate?: ?DateRangeInput,
  shipmentSecondTransitPortArrivalCurrentDate?: ?DateRangeInput,
  shipmentSecondTransitPortDepartureInitialDate?: ?DateRangeInput,
  shipmentSecondTransitPortDepartureResultDate?: ?DateRangeInput,
  shipmentSecondTransitPortDepartureCurrentDate?: ?DateRangeInput,
  shipmentDischargePortArrivalInitialDate?: ?DateRangeInput,
  shipmentDischargePortArrivalResultDate?: ?DateRangeInput,
  shipmentDischargePortArrivalCurrentDate?: ?DateRangeInput,
  shipmentCustomClearanceInitialDate?: ?DateRangeInput,
  shipmentCustomClearanceResultDate?: ?DateRangeInput,
  shipmentCustomClearanceCurrentDate?: ?DateRangeInput,
  shipmentWarehouseArrivalInitialDate?: ?DateRangeInput,
  shipmentWarehouseArrivalResultDate?: ?DateRangeInput,
  shipmentWarehouseArrivalCurrentDate?: ?DateRangeInput,
  shipmentDeliveryReadyInitialDate?: ?DateRangeInput,
  shipmentDeliveryReadyResultDate?: ?DateRangeInput,
  shipmentDeliveryReadyCurrentDate?: ?DateRangeInput,
  shipmentSharedPartnerOrganizationIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  shipmentCustomFields?: ?Array<EntityCustomFieldInput>,
};

export type BatchPayload = Batch | BadRequest | Forbidden | NotFound;

export type BatchPayloadPaginatedSearch = {
  ...Paginated,
  ...{
     __typename: 'BatchPayloadPaginatedSearch',
    nodes: Array<BatchPayload>,
    hits: Array<Hit>,
    page: $ElementType<Scalars, 'Int'>,
    perPage: $ElementType<Scalars, 'Int'>,
    totalPage: $ElementType<Scalars, 'Int'>,
    count: $ElementType<Scalars, 'Int'>,
    totalCount: $ElementType<Scalars, 'Int'>,
  }
};

export type BatchSimpleSplitInput = {
  quantity: $ElementType<Scalars, 'Float'>,
  no?: ?$ElementType<Scalars, 'String'>,
};

export const BatchSortFieldValues = Object.freeze({
  CreatedAt: 'createdAt', 
  UpdatedAt: 'updatedAt', 
  No: 'no', 
  CustomFields: 'customFields', 
  OrderPoNo: 'orderPoNo', 
  OrderExporter: 'orderExporter', 
  OrderImporterName: 'orderImporterName', 
  ProductName: 'productName', 
  ProductSerial: 'productSerial', 
  ProducedAt: 'producedAt', 
  DeliveredAt: 'deliveredAt', 
  ExpiredAt: 'expiredAt', 
  DesiredAt: 'desiredAt', 
  ContainerNo: 'containerNo', 
  ContainerFreeTimeDueDate: 'containerFreeTimeDueDate', 
  ContainerWarehouseAgreedArrivalDate: 'containerWarehouseAgreedArrivalDate', 
  ContainerCustomFields: 'containerCustomFields', 
  ShipmentNo: 'shipmentNo', 
  ShipmentInvoiceNo: 'shipmentInvoiceNo', 
  ShipmentCargoReadyResultDate: 'shipmentCargoReadyResultDate', 
  ShipmentCargoReadyCurrentDate: 'shipmentCargoReadyCurrentDate', 
  ShipmentLoadPort: 'shipmentLoadPort', 
  ShipmentLoadPortDepartureInitialDate: 'shipmentLoadPortDepartureInitialDate', 
  ShipmentLoadPortDepartureResultDate: 'shipmentLoadPortDepartureResultDate', 
  ShipmentLoadPortDepartureCurrentDate: 'shipmentLoadPortDepartureCurrentDate', 
  ShipmentFirstTransitPortArrivalCurrentDate: 'shipmentFirstTransitPortArrivalCurrentDate', 
  ShipmentFirstTransitPortArrivalResultDate: 'shipmentFirstTransitPortArrivalResultDate', 
  ShipmentFirstTransitPortDepartureCurrentDate: 'shipmentFirstTransitPortDepartureCurrentDate', 
  ShipmentFirstTransitPortDepartureResultDate: 'shipmentFirstTransitPortDepartureResultDate', 
  ShipmentSecondTransitPortArrivalCurrentDate: 'shipmentSecondTransitPortArrivalCurrentDate', 
  ShipmentSecondTransitPortArrivalResultDate: 'shipmentSecondTransitPortArrivalResultDate', 
  ShipmentSecondTransitPortDepartureCurrentDate: 'shipmentSecondTransitPortDepartureCurrentDate', 
  ShipmentSecondTransitPortDepartureResultDate: 'shipmentSecondTransitPortDepartureResultDate', 
  ShipmentDischargePort: 'shipmentDischargePort', 
  ShipmentDischargePortArrivalInitialDate: 'shipmentDischargePortArrivalInitialDate', 
  ShipmentDischargePortArrivalResultDate: 'shipmentDischargePortArrivalResultDate', 
  ShipmentDischargePortArrivalCurrentDate: 'shipmentDischargePortArrivalCurrentDate', 
  ShipmentCustomClearanceCurrentDate: 'shipmentCustomClearanceCurrentDate', 
  ShipmentCustomClearanceResultDate: 'shipmentCustomClearanceResultDate', 
  ShipmentWarehouseArrivalCurrentDate: 'shipmentWarehouseArrivalCurrentDate', 
  ShipmentWarehouseArrivalResultDate: 'shipmentWarehouseArrivalResultDate', 
  ShipmentDeliveryReadyCurrentDate: 'shipmentDeliveryReadyCurrentDate', 
  ShipmentDeliveryReadyResultDate: 'shipmentDeliveryReadyResultDate'
});


export type BatchSortField = $Values<typeof BatchSortFieldValues>;

export type BatchSortInput = {
  createdAt?: ?SortOrder,
  updatedAt?: ?SortOrder,
  no?: ?SortOrder,
  poNo?: ?SortOrder,
  orderExporter?: ?SortOrder,
  productName?: ?SortOrder,
  productSerial?: ?SortOrder,
  producedAt?: ?SortOrder,
  deliveredAt?: ?SortOrder,
  expiredAt?: ?SortOrder,
  desiredAt?: ?SortOrder,
  containerFreeTimeDueDate?: ?SortOrder,
  containerWarehouseAgreedArrivalDate?: ?SortOrder,
  shipmentNo?: ?SortOrder,
  shipmentLoadPort?: ?SortOrder,
  shipmentLoadPortDepartureInitialDate?: ?SortOrder,
  shipmentLoadPortDepartureCurrentDate?: ?SortOrder,
  shipmentLoadPortDepartureDateDifference?: ?SortOrder,
  shipmentDischargePort?: ?SortOrder,
  shipmentDischargePortArrivalInitialDate?: ?SortOrder,
  shipmentDischargePortArrivalCurrentDate?: ?SortOrder,
  shipmentDischargePortArrivalDateDifference?: ?SortOrder,
  customField?: ?GenericSortInput,
};

export type BatchSortsInput = {
  field: BatchSortField,
  customFieldId?: ?$ElementType<Scalars, 'ID'>,
  direction: SortOrder,
};

export type BatchUpdateInput = {
  no?: ?$ElementType<Scalars, 'String'>,
  quantity?: ?$ElementType<Scalars, 'Float'>,
  producedQuantity?: ?$ElementType<Scalars, 'Float'>,
  preShippedQuantity?: ?$ElementType<Scalars, 'Float'>,
  shippedQuantity?: ?$ElementType<Scalars, 'Float'>,
  postShippedQuantity?: ?$ElementType<Scalars, 'Float'>,
  deliveredQuantity?: ?$ElementType<Scalars, 'Float'>,
  quantityUnit?: ?$ElementType<Scalars, 'String'>,
  producedAt?: ?$ElementType<Scalars, 'DateTime'>,
  deliveredAt?: ?$ElementType<Scalars, 'DateTime'>,
  expiredAt?: ?$ElementType<Scalars, 'DateTime'>,
  desiredAt?: ?$ElementType<Scalars, 'DateTime'>,
  packageQuantity?: ?$ElementType<Scalars, 'Float'>,
  autoCalculatePackageQuantity?: ?$ElementType<Scalars, 'Boolean'>,
  packageName?: ?$ElementType<Scalars, 'String'>,
  packageGrossWeight?: ?MetricValueInput,
  packageVolume?: ?MetricValueInput,
  autoCalculatePackageVolume?: ?$ElementType<Scalars, 'Boolean'>,
  packageSize?: ?SizeInput,
  packageCapacity?: ?$ElementType<Scalars, 'Float'>,
  packageCapacityUnit?: ?$ElementType<Scalars, 'String'>,
  memo?: ?$ElementType<Scalars, 'String'>,
  totalPriceOverride?: ?PriceInput,
  totalPriceOverriding?: ?$ElementType<Scalars, 'Boolean'>,
  totalVolumeOverride?: ?MetricValueInput,
  totalVolumeOverriding?: ?$ElementType<Scalars, 'Boolean'>,
  /** Deprecated */
  tagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  tags?: ?Array<EntityTagInput>,
  followerIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  customFields?: ?CustomFieldsInput,
  orderItemId?: ?$ElementType<Scalars, 'ID'>,
  shipmentId?: ?$ElementType<Scalars, 'ID'>,
  containerId?: ?$ElementType<Scalars, 'ID'>,
};

export type BatchUpdateWrapperInput = {
  id: $ElementType<Scalars, 'ID'>,
  input: BatchUpdateInput,
};

export type Blur = {
   __typename: 'Blur',
  id: $ElementType<Scalars, 'ID'>,
  user: UserPayload,
};

export type BooleanValue = {
   __typename: 'BooleanValue',
  boolean: $ElementType<Scalars, 'Boolean'>,
};

export type ChangePasswordInput = {
  currentPassword: $ElementType<Scalars, 'String'>,
  newPassword: $ElementType<Scalars, 'String'>,
};

export type ChangeType = {
   __typename: 'ChangeType',
  old?: ?Value,
  new?: ?Value,
  field: $ElementType<Scalars, 'String'>,
};

export type CommentCreateInput = {
  content: $ElementType<Scalars, 'String'>,
  entity: EntityInput,
  mentionIds?: ?Array<$ElementType<Scalars, 'ID'>>,
};

export type CommentUpdateInput = {
  content?: ?$ElementType<Scalars, 'String'>,
  mentionIds?: ?Array<$ElementType<Scalars, 'ID'>>,
};

export type Connected = {
  connectionBy?: ?ConnectionPayload,
};

export type Connection = {
  ...Model,
  ...{
     __typename: 'Connection',
    key: $ElementType<Scalars, 'String'>,
    displayName: $ElementType<Scalars, 'String'>,
    organizations: Array<OrganizationPayload>,
    internalPartnerGroups: Array<InternalPartnerGroupPayload>,
    id: $ElementType<Scalars, 'ID'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    deletedAt?: ?$ElementType<Scalars, 'DateTime'>,
    createdBy?: ?UserPayload,
    updatedBy?: ?UserPayload,
    deletedBy?: ?UserPayload,
  }
};

export type ConnectionPayload = Connection | BadRequest | Forbidden | NotFound;

export type Container = {
  ...Model,
  ...Connected,
  ...Owned,
  ...Tagged,
  ...Sortable,
  ...Customizable,
  ...Memorizable,
  ...{
     __typename: 'Container',
    archived: $ElementType<Scalars, 'Boolean'>,
    no: $ElementType<Scalars, 'String'>,
    representativeBatch?: ?BatchPayload,
    warehouse?: ?WarehousePayload,
    vanningDate?: ?$ElementType<Scalars, 'DateTime'>,
    vanningDateApprovedBy?: ?UserPayload,
    vanningDateApprovedAt?: ?$ElementType<Scalars, 'DateTime'>,
    vanningActualDate?: ?$ElementType<Scalars, 'DateTime'>,
    vanningActualDateApprovedBy?: ?UserPayload,
    vanningActualDateApprovedAt?: ?$ElementType<Scalars, 'DateTime'>,
    warehouseArrivalAgreedDate?: ?$ElementType<Scalars, 'DateTime'>,
    warehouseArrivalAgreedDateApprovedBy?: ?UserPayload,
    warehouseArrivalAgreedDateApprovedAt?: ?$ElementType<Scalars, 'DateTime'>,
    warehouseArrivalAgreedDateAssignedTo: Array<UserPayload>,
    warehouseArrivalActualDate?: ?$ElementType<Scalars, 'DateTime'>,
    warehouseArrivalActualDateApprovedBy?: ?UserPayload,
    warehouseArrivalActualDateApprovedAt?: ?$ElementType<Scalars, 'DateTime'>,
    terminalArrivalPort?: ?Port,
    terminalArrival: TimelineDatePayload,
    shipment: ShipmentPayload,
    warehouseArrivalActualDateAssignedTo: Array<UserPayload>,
    freeTimeStartDate?: ?$ElementType<Scalars, 'DateTime'>,
    freeTimeDuration?: ?$ElementType<Scalars, 'Int'>,
    autoCalculatedFreeTimeStartDate: $ElementType<Scalars, 'Boolean'>,
    yardName?: ?$ElementType<Scalars, 'String'>,
    departureDate?: ?$ElementType<Scalars, 'DateTime'>,
    departureDateAssignedTo: Array<UserPayload>,
    departureDateApprovedBy?: ?UserPayload,
    departureDateApprovedAt?: ?$ElementType<Scalars, 'DateTime'>,
    containerType?: ?$ElementType<Scalars, 'String'>,
    containerOption?: ?ContainerOption,
    totalVolume: MetricValue,
    totalWeight: MetricValue,
    /** Use in export function */
  totalPrice?: ?Price,
    newTotalPrice?: ?NewPricePayload,
    totalQuantity: $ElementType<Scalars, 'Float'>,
    totalPackageQuantity: $ElementType<Scalars, 'Int'>,
    orderItemCount: $ElementType<Scalars, 'Int'>,
    /** sync to batch unit if all batch units are unified */
  batchQuantity: MetricValue,
    batchCount: $ElementType<Scalars, 'Int'>,
    batches: Array<BatchPayload>,
    timeline: Timeline,
    id: $ElementType<Scalars, 'ID'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    deletedAt?: ?$ElementType<Scalars, 'DateTime'>,
    createdBy?: ?UserPayload,
    updatedBy?: ?UserPayload,
    deletedBy?: ?UserPayload,
    connectionBy?: ?ConnectionPayload,
    ownedBy: OrganizationPayload,
    tags: Array<TagPayload>,
    sort: $ElementType<Scalars, 'Int'>,
    customFields: CustomFields,
    memo?: ?$ElementType<Scalars, 'String'>,
  }
};

export type ContainerBatchInput = {
  no?: ?$ElementType<Scalars, 'String'>,
  quantity?: ?$ElementType<Scalars, 'Float'>,
  producedQuantity?: ?$ElementType<Scalars, 'Float'>,
  preShippedQuantity?: ?$ElementType<Scalars, 'Float'>,
  shippedQuantity?: ?$ElementType<Scalars, 'Float'>,
  postShippedQuantity?: ?$ElementType<Scalars, 'Float'>,
  deliveredQuantity?: ?$ElementType<Scalars, 'Float'>,
  quantityUnit?: ?$ElementType<Scalars, 'String'>,
  producedAt?: ?$ElementType<Scalars, 'DateTime'>,
  deliveredAt?: ?$ElementType<Scalars, 'DateTime'>,
  expiredAt?: ?$ElementType<Scalars, 'DateTime'>,
  desiredAt?: ?$ElementType<Scalars, 'DateTime'>,
  packageQuantity?: ?$ElementType<Scalars, 'Float'>,
  autoCalculatePackageQuantity?: ?$ElementType<Scalars, 'Boolean'>,
  packageName?: ?$ElementType<Scalars, 'String'>,
  packageGrossWeight?: ?MetricValueInput,
  packageVolume?: ?MetricValueInput,
  autoCalculatePackageVolume?: ?$ElementType<Scalars, 'Boolean'>,
  packageSize?: ?SizeInput,
  packageCapacity?: ?$ElementType<Scalars, 'Float'>,
  packageCapacityUnit?: ?$ElementType<Scalars, 'String'>,
  memo?: ?$ElementType<Scalars, 'String'>,
  totalPriceOverride?: ?PriceInput,
  totalPriceOverriding?: ?$ElementType<Scalars, 'Boolean'>,
  totalVolumeOverride?: ?MetricValueInput,
  totalVolumeOverriding?: ?$ElementType<Scalars, 'Boolean'>,
  /** Deprecated */
  tagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  tags?: ?Array<EntityTagInput>,
  followerIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  customFields?: ?CustomFieldsInput,
  id?: ?$ElementType<Scalars, 'ID'>,
  orderItemId?: ?$ElementType<Scalars, 'ID'>,
};

export type ContainerBulkFilterInput = {
  nos?: ?ValuesWithMatchModeInput,
};

export type ContainerCreateInput = {
  no: $ElementType<Scalars, 'String'>,
  warehouseId?: ?$ElementType<Scalars, 'ID'>,
  departureDate?: ?$ElementType<Scalars, 'DateTime'>,
  departureDateApprovedById?: ?$ElementType<Scalars, 'ID'>,
  departureDateAssignedToIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  vanningDate?: ?$ElementType<Scalars, 'DateTime'>,
  vanningDateApprovedById?: ?$ElementType<Scalars, 'ID'>,
  vanningActualDate?: ?$ElementType<Scalars, 'DateTime'>,
  vanningActualDateApprovedById?: ?$ElementType<Scalars, 'ID'>,
  warehouseArrivalAgreedDate?: ?$ElementType<Scalars, 'DateTime'>,
  warehouseArrivalAgreedDateApprovedById?: ?$ElementType<Scalars, 'ID'>,
  warehouseArrivalAgreedDateAssignedToIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  warehouseArrivalActualDate?: ?$ElementType<Scalars, 'DateTime'>,
  warehouseArrivalActualDateApprovedById?: ?$ElementType<Scalars, 'ID'>,
  warehouseArrivalActualDateAssignedToIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  terminalArrival?: ?TimelineDateNestedInput,
  terminalArrivalPort?: ?PortInput,
  freeTimeStartDate?: ?$ElementType<Scalars, 'DateTime'>,
  freeTimeDuration?: ?$ElementType<Scalars, 'Int'>,
  autoCalculatedFreeTimeStartDate?: ?$ElementType<Scalars, 'Boolean'>,
  yardName?: ?$ElementType<Scalars, 'String'>,
  representativeBatchId?: ?$ElementType<Scalars, 'ID'>,
  representativeBatchIndex?: ?$ElementType<Scalars, 'Int'>,
  containerType?: ?$ElementType<Scalars, 'String'>,
  containerOption?: ?ContainerOption,
  batches?: ?Array<ContainerBatchInput>,
  memo?: ?$ElementType<Scalars, 'String'>,
  /** Deprecated */
  tagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  tags?: ?Array<EntityTagInput>,
  followerIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  customFields?: ?CustomFieldsInput,
  shipmentId: $ElementType<Scalars, 'ID'>,
};

export type ContainerFilterInput = {
  query?: ?$ElementType<Scalars, 'String'>,
  ownerId?: ?$ElementType<Scalars, 'ID'>,
  excludeIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  createdAt?: ?DateRangeInput,
  updatedAt?: ?DateRangeInput,
  connectionIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  bulkFilter?: ?ContainerBulkFilterInput,
  archived?: ?$ElementType<Scalars, 'Boolean'>,
  shipmentId?: ?$ElementType<Scalars, 'ID'>,
  productId?: ?$ElementType<Scalars, 'ID'>,
  terminalArrivalCurrentDate?: ?DateRangeInput,
  warehouseArrivalActualDate?: ?DateRangeInput,
  warehouseIds?: ?Array<$ElementType<Scalars, 'ID'>>,
};

export type ContainerGroup = {
  ...Model,
  ...Connected,
  ...Owned,
  ...Sortable,
  ...{
     __typename: 'ContainerGroup',
    customClearance: TimelineDatePayload,
    warehouseArrival: TimelineDatePayload,
    deliveryReady: TimelineDatePayload,
    warehouse?: ?WarehousePayload,
    shipment: ShipmentPayload,
    id: $ElementType<Scalars, 'ID'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    deletedAt?: ?$ElementType<Scalars, 'DateTime'>,
    createdBy?: ?UserPayload,
    updatedBy?: ?UserPayload,
    deletedBy?: ?UserPayload,
    connectionBy?: ?ConnectionPayload,
    ownedBy: OrganizationPayload,
    sort: $ElementType<Scalars, 'Int'>,
  }
};

export type ContainerGroupNestedInput = {
  id?: ?$ElementType<Scalars, 'ID'>,
  warehouseId?: ?$ElementType<Scalars, 'ID'>,
  customClearance?: ?TimelineDateNestedInput,
  warehouseArrival?: ?TimelineDateNestedInput,
  deliveryReady?: ?TimelineDateNestedInput,
};

export type ContainerGroupPayload = ContainerGroup | BadRequest | Forbidden | NotFound;

export const ContainerOptionValues = Object.freeze({
  /** Hanger */
  Hanger: 'Hanger'
});


export type ContainerOption = $Values<typeof ContainerOptionValues>;

export type ContainerPayload = Container | BadRequest | Forbidden | NotFound;

export type ContainerPayloadPaginatedSearch = {
  ...Paginated,
  ...{
     __typename: 'ContainerPayloadPaginatedSearch',
    nodes: Array<ContainerPayload>,
    hits: Array<Hit>,
    page: $ElementType<Scalars, 'Int'>,
    perPage: $ElementType<Scalars, 'Int'>,
    totalPage: $ElementType<Scalars, 'Int'>,
    count: $ElementType<Scalars, 'Int'>,
    totalCount: $ElementType<Scalars, 'Int'>,
  }
};

export type ContainerSortInput = {
  createdAt?: ?SortOrder,
  updatedAt?: ?SortOrder,
  warehouseName?: ?SortOrder,
  warehouseArrivalActualDate?: ?SortOrder,
  warehouseArrivalAgreedDate?: ?SortOrder,
  freeTimeDueDate?: ?SortOrder,
};

export type ContainerTypeCount = {
   __typename: 'ContainerTypeCount',
  containerType: $ElementType<Scalars, 'String'>,
  count: $ElementType<Scalars, 'Float'>,
};

export type ContainerUpdateInput = {
  no?: ?$ElementType<Scalars, 'String'>,
  warehouseId?: ?$ElementType<Scalars, 'ID'>,
  departureDate?: ?$ElementType<Scalars, 'DateTime'>,
  departureDateApprovedById?: ?$ElementType<Scalars, 'ID'>,
  departureDateAssignedToIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  vanningDate?: ?$ElementType<Scalars, 'DateTime'>,
  vanningDateApprovedById?: ?$ElementType<Scalars, 'ID'>,
  vanningActualDate?: ?$ElementType<Scalars, 'DateTime'>,
  vanningActualDateApprovedById?: ?$ElementType<Scalars, 'ID'>,
  warehouseArrivalAgreedDate?: ?$ElementType<Scalars, 'DateTime'>,
  warehouseArrivalAgreedDateApprovedById?: ?$ElementType<Scalars, 'ID'>,
  warehouseArrivalAgreedDateAssignedToIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  warehouseArrivalActualDate?: ?$ElementType<Scalars, 'DateTime'>,
  warehouseArrivalActualDateApprovedById?: ?$ElementType<Scalars, 'ID'>,
  warehouseArrivalActualDateAssignedToIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  terminalArrival?: ?TimelineDateNestedInput,
  terminalArrivalPort?: ?PortInput,
  freeTimeStartDate?: ?$ElementType<Scalars, 'DateTime'>,
  freeTimeDuration?: ?$ElementType<Scalars, 'Int'>,
  autoCalculatedFreeTimeStartDate?: ?$ElementType<Scalars, 'Boolean'>,
  yardName?: ?$ElementType<Scalars, 'String'>,
  representativeBatchId?: ?$ElementType<Scalars, 'ID'>,
  representativeBatchIndex?: ?$ElementType<Scalars, 'Int'>,
  containerType?: ?$ElementType<Scalars, 'String'>,
  containerOption?: ?ContainerOption,
  batches?: ?Array<ContainerBatchInput>,
  memo?: ?$ElementType<Scalars, 'String'>,
  /** Deprecated */
  tagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  tags?: ?Array<EntityTagInput>,
  followerIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  customFields?: ?CustomFieldsInput,
  shipmentId?: ?$ElementType<Scalars, 'ID'>,
};

export type ContainerUpdateWrapperInput = {
  id: $ElementType<Scalars, 'ID'>,
  input: ContainerUpdateInput,
};

export const CountryValues = Object.freeze({
  /** Andorra */
  And: 'AND', 
  /** United Arab Emirates */
  Are: 'ARE', 
  /** Afghanistan */
  Afg: 'AFG', 
  /** Antigua & Barbuda */
  Atg: 'ATG', 
  /** Anguilla */
  Aia: 'AIA', 
  /** Albania */
  Alb: 'ALB', 
  /** Armenia */
  Arm: 'ARM', 
  /** Angola */
  Ago: 'AGO', 
  /** Antarctica */
  Ata: 'ATA', 
  /** Argentina */
  Arg: 'ARG', 
  /** American Samoa */
  Asm: 'ASM', 
  /** Austria */
  Aut: 'AUT', 
  /** Australia */
  Aus: 'AUS', 
  /** Aruba */
  Abw: 'ABW', 
  /** Åland Islands */
  Ala: 'ALA', 
  /** Azerbaijan */
  Aze: 'AZE', 
  /** Bosnia & Herzegovina */
  Bih: 'BIH', 
  /** Barbados */
  Brb: 'BRB', 
  /** Bangladesh */
  Bgd: 'BGD', 
  /** Belgium */
  Bel: 'BEL', 
  /** Burkina Faso */
  Bfa: 'BFA', 
  /** Bulgaria */
  Bgr: 'BGR', 
  /** Bahrain */
  Bhr: 'BHR', 
  /** Burundi */
  Bdi: 'BDI', 
  /** Benin */
  Ben: 'BEN', 
  /** St. Barthélemy */
  Blm: 'BLM', 
  /** Bermuda */
  Bmu: 'BMU', 
  /** Brunei */
  Brn: 'BRN', 
  /** Bolivia */
  Bol: 'BOL', 
  /** Caribbean Netherlands */
  Bes: 'BES', 
  /** Brazil */
  Bra: 'BRA', 
  /** Bahamas */
  Bhs: 'BHS', 
  /** Bhutan */
  Btn: 'BTN', 
  /** Bouvet Island */
  Bvt: 'BVT', 
  /** Botswana */
  Bwa: 'BWA', 
  /** Belarus */
  Blr: 'BLR', 
  /** Belize */
  Blz: 'BLZ', 
  /** Canada */
  Can: 'CAN', 
  /** Cocos (Keeling) Islands */
  Cck: 'CCK', 
  /** Congo - Kinshasa */
  Cod: 'COD', 
  /** Central African Republic */
  Caf: 'CAF', 
  /** Congo - Brazzaville */
  Cog: 'COG', 
  /** Switzerland */
  Che: 'CHE', 
  /** Côte d’Ivoire */
  Civ: 'CIV', 
  /** Cook Islands */
  Cok: 'COK', 
  /** Chile */
  Chl: 'CHL', 
  /** Cameroon */
  Cmr: 'CMR', 
  /** China */
  Chn: 'CHN', 
  /** Colombia */
  Col: 'COL', 
  /** Costa Rica */
  Cri: 'CRI', 
  /** Cuba */
  Cub: 'CUB', 
  /** Cape Verde */
  Cpv: 'CPV', 
  /** Curaçao */
  Cuw: 'CUW', 
  /** Christmas Island */
  Cxr: 'CXR', 
  /** Cyprus */
  Cyp: 'CYP', 
  /** Czechia */
  Cze: 'CZE', 
  /** Germany */
  Deu: 'DEU', 
  /** Djibouti */
  Dji: 'DJI', 
  /** Denmark */
  Dnk: 'DNK', 
  /** Dominica */
  Dma: 'DMA', 
  /** Dominican Republic */
  Dom: 'DOM', 
  /** Algeria */
  Dza: 'DZA', 
  /** Ecuador */
  Ecu: 'ECU', 
  /** Estonia */
  Est: 'EST', 
  /** Egypt */
  Egy: 'EGY', 
  /** Western Sahara */
  Esh: 'ESH', 
  /** Eritrea */
  Eri: 'ERI', 
  /** Spain */
  Esp: 'ESP', 
  /** Ethiopia */
  Eth: 'ETH', 
  /** Finland */
  Fin: 'FIN', 
  /** Fiji */
  Fji: 'FJI', 
  /** Falkland Islands */
  Flk: 'FLK', 
  /** Micronesia */
  Fsm: 'FSM', 
  /** Faroe Islands */
  Fro: 'FRO', 
  /** France */
  Fra: 'FRA', 
  /** Gabon */
  Gab: 'GAB', 
  /** United Kingdom */
  Gbr: 'GBR', 
  /** Grenada */
  Grd: 'GRD', 
  /** Georgia */
  Geo: 'GEO', 
  /** French Guiana */
  Guf: 'GUF', 
  /** Guernsey */
  Ggy: 'GGY', 
  /** Ghana */
  Gha: 'GHA', 
  /** Gibraltar */
  Gib: 'GIB', 
  /** Greenland */
  Grl: 'GRL', 
  /** Gambia */
  Gmb: 'GMB', 
  /** Guinea */
  Gin: 'GIN', 
  /** Guadeloupe */
  Glp: 'GLP', 
  /** Equatorial Guinea */
  Gnq: 'GNQ', 
  /** Greece */
  Grc: 'GRC', 
  /** South Georgia & South Sandwich Islands */
  Sgs: 'SGS', 
  /** Guatemala */
  Gtm: 'GTM', 
  /** Guam */
  Gum: 'GUM', 
  /** Guinea-Bissau */
  Gnb: 'GNB', 
  /** Guyana */
  Guy: 'GUY', 
  /** Hong Kong SAR China */
  Hkg: 'HKG', 
  /** Heard & McDonald Islands */
  Hmd: 'HMD', 
  /** Honduras */
  Hnd: 'HND', 
  /** Croatia */
  Hrv: 'HRV', 
  /** Haiti */
  Hti: 'HTI', 
  /** Hungary */
  Hun: 'HUN', 
  /** Indonesia */
  Idn: 'IDN', 
  /** Ireland */
  Irl: 'IRL', 
  /** Israel */
  Isr: 'ISR', 
  /** Isle of Man */
  Imn: 'IMN', 
  /** India */
  Ind: 'IND', 
  /** British Indian Ocean Territory */
  Iot: 'IOT', 
  /** Iraq */
  Irq: 'IRQ', 
  /** Iran */
  Irn: 'IRN', 
  /** Iceland */
  Isl: 'ISL', 
  /** Italy */
  Ita: 'ITA', 
  /** Jersey */
  Jey: 'JEY', 
  /** Jamaica */
  Jam: 'JAM', 
  /** Jordan */
  Jor: 'JOR', 
  /** Japan */
  Jpn: 'JPN', 
  /** Kenya */
  Ken: 'KEN', 
  /** Kyrgyzstan */
  Kgz: 'KGZ', 
  /** Cambodia */
  Khm: 'KHM', 
  /** Kiribati */
  Kir: 'KIR', 
  /** Comoros */
  Com: 'COM', 
  /** St. Kitts & Nevis */
  Kna: 'KNA', 
  /** North Korea */
  Prk: 'PRK', 
  /** South Korea */
  Kor: 'KOR', 
  /** Kuwait */
  Kwt: 'KWT', 
  /** Cayman Islands */
  Cym: 'CYM', 
  /** Kazakhstan */
  Kaz: 'KAZ', 
  /** Laos */
  Lao: 'LAO', 
  /** Lebanon */
  Lbn: 'LBN', 
  /** St. Lucia */
  Lca: 'LCA', 
  /** Liechtenstein */
  Lie: 'LIE', 
  /** Sri Lanka */
  Lka: 'LKA', 
  /** Liberia */
  Lbr: 'LBR', 
  /** Lesotho */
  Lso: 'LSO', 
  /** Lithuania */
  Ltu: 'LTU', 
  /** Luxembourg */
  Lux: 'LUX', 
  /** Latvia */
  Lva: 'LVA', 
  /** Libya */
  Lby: 'LBY', 
  /** Morocco */
  Mar: 'MAR', 
  /** Monaco */
  Mco: 'MCO', 
  /** Moldova */
  Mda: 'MDA', 
  /** Montenegro */
  Mne: 'MNE', 
  /** St. Martin */
  Maf: 'MAF', 
  /** Madagascar */
  Mdg: 'MDG', 
  /** Marshall Islands */
  Mhl: 'MHL', 
  /** Macedonia */
  Mkd: 'MKD', 
  /** Mali */
  Mli: 'MLI', 
  /** Myanmar (Burma) */
  Mmr: 'MMR', 
  /** Mongolia */
  Mng: 'MNG', 
  /** Macau SAR China */
  Mac: 'MAC', 
  /** Northern Mariana Islands */
  Mnp: 'MNP', 
  /** Martinique */
  Mtq: 'MTQ', 
  /** Mauritania */
  Mrt: 'MRT', 
  /** Montserrat */
  Msr: 'MSR', 
  /** Malta */
  Mlt: 'MLT', 
  /** Mauritius */
  Mus: 'MUS', 
  /** Maldives */
  Mdv: 'MDV', 
  /** Malawi */
  Mwi: 'MWI', 
  /** Mexico */
  Mex: 'MEX', 
  /** Malaysia */
  Mys: 'MYS', 
  /** Mozambique */
  Moz: 'MOZ', 
  /** Namibia */
  Nam: 'NAM', 
  /** New Caledonia */
  Ncl: 'NCL', 
  /** Niger */
  Ner: 'NER', 
  /** Norfolk Island */
  Nfk: 'NFK', 
  /** Nigeria */
  Nga: 'NGA', 
  /** Nicaragua */
  Nic: 'NIC', 
  /** Netherlands */
  Nld: 'NLD', 
  /** Norway */
  Nor: 'NOR', 
  /** Nepal */
  Npl: 'NPL', 
  /** Nauru */
  Nru: 'NRU', 
  /** Niue */
  Niu: 'NIU', 
  /** New Zealand */
  Nzl: 'NZL', 
  /** Oman */
  Omn: 'OMN', 
  /** Panama */
  Pan: 'PAN', 
  /** Peru */
  Per: 'PER', 
  /** French Polynesia */
  Pyf: 'PYF', 
  /** Papua New Guinea */
  Png: 'PNG', 
  /** Philippines */
  Phl: 'PHL', 
  /** Pakistan */
  Pak: 'PAK', 
  /** Poland */
  Pol: 'POL', 
  /** St. Pierre & Miquelon */
  Spm: 'SPM', 
  /** Pitcairn Islands */
  Pcn: 'PCN', 
  /** Puerto Rico */
  Pri: 'PRI', 
  /** Palestinian Territories */
  Pse: 'PSE', 
  /** Portugal */
  Prt: 'PRT', 
  /** Palau */
  Plw: 'PLW', 
  /** Paraguay */
  Pry: 'PRY', 
  /** Qatar */
  Qat: 'QAT', 
  /** Réunion */
  Reu: 'REU', 
  /** Romania */
  Rou: 'ROU', 
  /** Serbia */
  Srb: 'SRB', 
  /** Russia */
  Rus: 'RUS', 
  /** Rwanda */
  Rwa: 'RWA', 
  /** Saudi Arabia */
  Sau: 'SAU', 
  /** Solomon Islands */
  Slb: 'SLB', 
  /** Seychelles */
  Syc: 'SYC', 
  /** Sudan */
  Sdn: 'SDN', 
  /** Sweden */
  Swe: 'SWE', 
  /** Singapore */
  Sgp: 'SGP', 
  /** St. Helena */
  Shn: 'SHN', 
  /** Slovenia */
  Svn: 'SVN', 
  /** Svalbard & Jan Mayen */
  Sjm: 'SJM', 
  /** Slovakia */
  Svk: 'SVK', 
  /** Sierra Leone */
  Sle: 'SLE', 
  /** San Marino */
  Smr: 'SMR', 
  /** Senegal */
  Sen: 'SEN', 
  /** Somalia */
  Som: 'SOM', 
  /** Suriname */
  Sur: 'SUR', 
  /** South Sudan */
  Ssd: 'SSD', 
  /** São Tomé & Príncipe */
  Stp: 'STP', 
  /** El Salvador */
  Slv: 'SLV', 
  /** Sint Maarten */
  Sxm: 'SXM', 
  /** Syria */
  Syr: 'SYR', 
  /** Swaziland */
  Swz: 'SWZ', 
  /** Turks & Caicos Islands */
  Tca: 'TCA', 
  /** Chad */
  Tcd: 'TCD', 
  /** French Southern Territories */
  Atf: 'ATF', 
  /** Togo */
  Tgo: 'TGO', 
  /** Thailand */
  Tha: 'THA', 
  /** Tajikistan */
  Tjk: 'TJK', 
  /** Tokelau */
  Tkl: 'TKL', 
  /** Timor-Leste */
  Tls: 'TLS', 
  /** Turkmenistan */
  Tkm: 'TKM', 
  /** Tunisia */
  Tun: 'TUN', 
  /** Tonga */
  Ton: 'TON', 
  /** Turkey */
  Tur: 'TUR', 
  /** Trinidad & Tobago */
  Tto: 'TTO', 
  /** Tuvalu */
  Tuv: 'TUV', 
  /** Taiwan */
  Twn: 'TWN', 
  /** Tanzania */
  Tza: 'TZA', 
  /** Ukraine */
  Ukr: 'UKR', 
  /** Uganda */
  Uga: 'UGA', 
  /** U.S. Outlying Islands */
  Umi: 'UMI', 
  /** United States */
  Usa: 'USA', 
  /** Uruguay */
  Ury: 'URY', 
  /** Uzbekistan */
  Uzb: 'UZB', 
  /** Vatican City */
  Vat: 'VAT', 
  /** St. Vincent & Grenadines */
  Vct: 'VCT', 
  /** Venezuela */
  Ven: 'VEN', 
  /** British Virgin Islands */
  Vgb: 'VGB', 
  /** U.S. Virgin Islands */
  Vir: 'VIR', 
  /** Vietnam */
  Vnm: 'VNM', 
  /** Vanuatu */
  Vut: 'VUT', 
  /** Wallis & Futuna */
  Wlf: 'WLF', 
  /** Samoa */
  Wsm: 'WSM', 
  /** Yemen */
  Yem: 'YEM', 
  /** Mayotte */
  Myt: 'MYT', 
  /** South Africa */
  Zaf: 'ZAF', 
  /** Zambia */
  Zmb: 'ZMB', 
  /** Zimbabwe */
  Zwe: 'ZWE'
});


export type Country = $Values<typeof CountryValues>;

export type CredentialsInput = {
  email: $ElementType<Scalars, 'String'>,
  password: $ElementType<Scalars, 'String'>,
};

export const CurrencyValues = Object.freeze({
  /** AED */
  Aed: 'AED', 
  /** AFN */
  Afn: 'AFN', 
  /** ALL */
  All: 'ALL', 
  /** AMD */
  Amd: 'AMD', 
  /** ANG */
  Ang: 'ANG', 
  /** AOA */
  Aoa: 'AOA', 
  /** ARS */
  Ars: 'ARS', 
  /** AUD */
  Aud: 'AUD', 
  /** AWG */
  Awg: 'AWG', 
  /** AZN */
  Azn: 'AZN', 
  /** BAM */
  Bam: 'BAM', 
  /** BBD */
  Bbd: 'BBD', 
  /** BDT */
  Bdt: 'BDT', 
  /** BGN */
  Bgn: 'BGN', 
  /** BHD */
  Bhd: 'BHD', 
  /** BIF */
  Bif: 'BIF', 
  /** BMD */
  Bmd: 'BMD', 
  /** BND */
  Bnd: 'BND', 
  /** BOB */
  Bob: 'BOB', 
  /** BRL */
  Brl: 'BRL', 
  /** BSD */
  Bsd: 'BSD', 
  /** BTN */
  Btn: 'BTN', 
  /** BWP */
  Bwp: 'BWP', 
  /** BYN */
  Byn: 'BYN', 
  /** BZD */
  Bzd: 'BZD', 
  /** CAD */
  Cad: 'CAD', 
  /** CDF */
  Cdf: 'CDF', 
  /** CHF */
  Chf: 'CHF', 
  /** CLP */
  Clp: 'CLP', 
  /** CNY */
  Cny: 'CNY', 
  /** COP */
  Cop: 'COP', 
  /** CRC */
  Crc: 'CRC', 
  /** CUP */
  Cup: 'CUP', 
  /** CVE */
  Cve: 'CVE', 
  /** CZK */
  Czk: 'CZK', 
  /** DJF */
  Djf: 'DJF', 
  /** DKK */
  Dkk: 'DKK', 
  /** DOP */
  Dop: 'DOP', 
  /** DZD */
  Dzd: 'DZD', 
  /** EGP */
  Egp: 'EGP', 
  /** ERN */
  Ern: 'ERN', 
  /** ETB */
  Etb: 'ETB', 
  /** EUR */
  Eur: 'EUR', 
  /** FJD */
  Fjd: 'FJD', 
  /** FKP */
  Fkp: 'FKP', 
  /** GBP */
  Gbp: 'GBP', 
  /** GEL */
  Gel: 'GEL', 
  /** GHS */
  Ghs: 'GHS', 
  /** GIP */
  Gip: 'GIP', 
  /** GMD */
  Gmd: 'GMD', 
  /** GNF */
  Gnf: 'GNF', 
  /** GTQ */
  Gtq: 'GTQ', 
  /** GYD */
  Gyd: 'GYD', 
  /** HKD */
  Hkd: 'HKD', 
  /** HNL */
  Hnl: 'HNL', 
  /** HRK */
  Hrk: 'HRK', 
  /** HTG */
  Htg: 'HTG', 
  /** HUF */
  Huf: 'HUF', 
  /** IDR */
  Idr: 'IDR', 
  /** ILS */
  Ils: 'ILS', 
  /** INR */
  Inr: 'INR', 
  /** IQD */
  Iqd: 'IQD', 
  /** IRR */
  Irr: 'IRR', 
  /** ISK */
  Isk: 'ISK', 
  /** JMD */
  Jmd: 'JMD', 
  /** JOD */
  Jod: 'JOD', 
  /** JPY */
  Jpy: 'JPY', 
  /** KES */
  Kes: 'KES', 
  /** KGS */
  Kgs: 'KGS', 
  /** KHR */
  Khr: 'KHR', 
  /** KMF */
  Kmf: 'KMF', 
  /** KPW */
  Kpw: 'KPW', 
  /** KRW */
  Krw: 'KRW', 
  /** KWD */
  Kwd: 'KWD', 
  /** KYD */
  Kyd: 'KYD', 
  /** KZT */
  Kzt: 'KZT', 
  /** LAK */
  Lak: 'LAK', 
  /** LBP */
  Lbp: 'LBP', 
  /** LKR */
  Lkr: 'LKR', 
  /** LRD */
  Lrd: 'LRD', 
  /** LSL */
  Lsl: 'LSL', 
  /** LYD */
  Lyd: 'LYD', 
  /** MAD */
  Mad: 'MAD', 
  /** MDL */
  Mdl: 'MDL', 
  /** MGA */
  Mga: 'MGA', 
  /** MKD */
  Mkd: 'MKD', 
  /** MMK */
  Mmk: 'MMK', 
  /** MNT */
  Mnt: 'MNT', 
  /** MOP */
  Mop: 'MOP', 
  /** MRO */
  Mro: 'MRO', 
  /** MUR */
  Mur: 'MUR', 
  /** MVR */
  Mvr: 'MVR', 
  /** MWK */
  Mwk: 'MWK', 
  /** MXN */
  Mxn: 'MXN', 
  /** MYR */
  Myr: 'MYR', 
  /** MZN */
  Mzn: 'MZN', 
  /** NAD */
  Nad: 'NAD', 
  /** NGN */
  Ngn: 'NGN', 
  /** NIO */
  Nio: 'NIO', 
  /** NOK */
  Nok: 'NOK', 
  /** NPR */
  Npr: 'NPR', 
  /** NZD */
  Nzd: 'NZD', 
  /** OMR */
  Omr: 'OMR', 
  /** PAB */
  Pab: 'PAB', 
  /** PEN */
  Pen: 'PEN', 
  /** PGK */
  Pgk: 'PGK', 
  /** PHP */
  Php: 'PHP', 
  /** PKR */
  Pkr: 'PKR', 
  /** PLN */
  Pln: 'PLN', 
  /** PYG */
  Pyg: 'PYG', 
  /** QAR */
  Qar: 'QAR', 
  /** RON */
  Ron: 'RON', 
  /** RSD */
  Rsd: 'RSD', 
  /** RUB */
  Rub: 'RUB', 
  /** RUR */
  Rur: 'RUR', 
  /** RWF */
  Rwf: 'RWF', 
  /** SAR */
  Sar: 'SAR', 
  /** SBD */
  Sbd: 'SBD', 
  /** SCR */
  Scr: 'SCR', 
  /** SDG */
  Sdg: 'SDG', 
  /** SEK */
  Sek: 'SEK', 
  /** SGD */
  Sgd: 'SGD', 
  /** SHP */
  Shp: 'SHP', 
  /** SLL */
  Sll: 'SLL', 
  /** SOS */
  Sos: 'SOS', 
  /** SRD */
  Srd: 'SRD', 
  /** SSP */
  Ssp: 'SSP', 
  /** STD */
  Std: 'STD', 
  /** SVC */
  Svc: 'SVC', 
  /** SYP */
  Syp: 'SYP', 
  /** SZL */
  Szl: 'SZL', 
  /** THB */
  Thb: 'THB', 
  /** TJS */
  Tjs: 'TJS', 
  /** TMT */
  Tmt: 'TMT', 
  /** TND */
  Tnd: 'TND', 
  /** TOP */
  Top: 'TOP', 
  /** TRY */
  Try: 'TRY', 
  /** TTD */
  Ttd: 'TTD', 
  /** TWD */
  Twd: 'TWD', 
  /** TZS */
  Tzs: 'TZS', 
  /** UAH */
  Uah: 'UAH', 
  /** UGX */
  Ugx: 'UGX', 
  /** USD */
  Usd: 'USD', 
  /** UYU */
  Uyu: 'UYU', 
  /** UZS */
  Uzs: 'UZS', 
  /** VEF */
  Vef: 'VEF', 
  /** VND */
  Vnd: 'VND', 
  /** VUV */
  Vuv: 'VUV', 
  /** WST */
  Wst: 'WST', 
  /** XAF */
  Xaf: 'XAF', 
  /** XAG */
  Xag: 'XAG', 
  /** XAU */
  Xau: 'XAU', 
  /** XBA */
  Xba: 'XBA', 
  /** XBB */
  Xbb: 'XBB', 
  /** XBC */
  Xbc: 'XBC', 
  /** XBD */
  Xbd: 'XBD', 
  /** XCD */
  Xcd: 'XCD', 
  /** XDR */
  Xdr: 'XDR', 
  /** XFU */
  Xfu: 'XFU', 
  /** XOF */
  Xof: 'XOF', 
  /** XPD */
  Xpd: 'XPD', 
  /** XPF */
  Xpf: 'XPF', 
  /** XPT */
  Xpt: 'XPT', 
  /** XSU */
  Xsu: 'XSU', 
  /** XTS */
  Xts: 'XTS', 
  /** XUA */
  Xua: 'XUA', 
  /** XXX */
  Xxx: 'XXX', 
  /** YER */
  Yer: 'YER', 
  /** ZAR */
  Zar: 'ZAR', 
  /** ZMW */
  Zmw: 'ZMW', 
  /** ZWL */
  Zwl: 'ZWL'
});


export type Currency = $Values<typeof CurrencyValues>;

export type CustomFieldFilterInput = {
  query?: ?$ElementType<Scalars, 'String'>,
  ownerId?: ?$ElementType<Scalars, 'ID'>,
  excludeIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  createdAt?: ?DateRangeInput,
  updatedAt?: ?DateRangeInput,
  entityTypes?: ?Array<CustomizableEntityType>,
  name?: ?$ElementType<Scalars, 'String'>,
  connectionIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  organizationIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  internalOnly?: ?$ElementType<Scalars, 'Boolean'>,
};

export type CustomFields = {
   __typename: 'CustomFields',
  mask?: ?MaskPayload,
  fieldValues: Array<FieldValuePayload>,
  fieldDefinitions: Array<FieldDefinitionPayload>,
};

export type CustomFieldsInput = {
  /** deprecated */
  maskId?: ?$ElementType<Scalars, 'ID'>,
  fieldValues?: ?Array<FieldValueInput>,
};

export type Customizable = {
  customFields: CustomFields,
};

export const CustomizableEntityTypeValues = Object.freeze({
  Product: 'Product', 
  ProductProvider: 'ProductProvider', 
  Order: 'Order', 
  OrderItem: 'OrderItem', 
  Batch: 'Batch', 
  Shipment: 'Shipment', 
  Container: 'Container', 
  Warehouse: 'Warehouse'
});


export type CustomizableEntityType = $Values<typeof CustomizableEntityTypeValues>;

export type DateRangeInput = {
  after?: ?$ElementType<Scalars, 'DateTime'>,
  before?: ?$ElementType<Scalars, 'DateTime'>,
};


export type DateTimeValue = {
   __typename: 'DateTimeValue',
  datetime: $ElementType<Scalars, 'DateTime'>,
};

export type DefaultFilterInput = {
  query?: ?$ElementType<Scalars, 'String'>,
  ownerId?: ?$ElementType<Scalars, 'ID'>,
  excludeIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  createdAt?: ?DateRangeInput,
  updatedAt?: ?DateRangeInput,
};

export type DefaultSortInput = {
  createdAt?: ?SortOrder,
  updatedAt?: ?SortOrder,
};

export type Documented = {
  hasFile: $ElementType<Scalars, 'Boolean'>,
  filesUnreadCount: $ElementType<Scalars, 'Int'>,
  files: Array<FilePayload>,
};

export type DraftMessage = {
  ...Model,
  ...{
     __typename: 'DraftMessage',
    content: $ElementType<Scalars, 'String'>,
    entity?: ?EntityPayload,
    messageGroup?: ?MessageGroupPayload,
    id: $ElementType<Scalars, 'ID'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    deletedAt?: ?$ElementType<Scalars, 'DateTime'>,
    createdBy?: ?UserPayload,
    updatedBy?: ?UserPayload,
    deletedBy?: ?UserPayload,
  }
};

export type DraftMessageGetInput = {
  entity: EntityInput,
  messageGroupId: $ElementType<Scalars, 'ID'>,
};

export type DraftMessagePayload = DraftMessage | BadRequest | Forbidden | NotFound;

export type DraftMessageUpdateInput = {
  content: $ElementType<Scalars, 'String'>,
  entity: EntityInput,
  messageGroupId: $ElementType<Scalars, 'ID'>,
};

// This has been truncated by a custom Zenport plugin
export const DryportValues = Object.freeze({
  /** Andorra la Vella */
  Adalv: 'ADALV'
});


export type Dryport = $Values<typeof DryportValues>;

export type Duration = {
   __typename: 'Duration',
  seconds: $ElementType<Scalars, 'Long'>,
  nanos: $ElementType<Scalars, 'Int'>,
};

export type DurationInput = {
  seconds: $ElementType<Scalars, 'Long'>,
  nanos?: ?$ElementType<Scalars, 'Int'>,
};

export type DurationValue = {
   __typename: 'DurationValue',
  duration: Duration,
};

export type Emoji = {
   __typename: 'Emoji',
  id: $ElementType<Scalars, 'ID'>,
  name: $ElementType<Scalars, 'String'>,
  emoji: $ElementType<Scalars, 'String'>,
  decimals: Array<$ElementType<Scalars, 'Int'>>,
};

export type EmojiPayload = Emoji | BadRequest | Forbidden | NotFound;

export type EmptyPayload = BadRequest | Forbidden | NotFound;

export type EntitiesMany = {
   __typename: 'EntitiesMany',
  products?: ?Array<?ProductPayload>,
  productProviders?: ?Array<?ProductProviderPayload>,
  productProviderPackages?: ?Array<?ProductProviderPackagePayload>,
  orders?: ?Array<?OrderPayload>,
  orderItems?: ?Array<?OrderItemPayload>,
  batches?: ?Array<?BatchPayload>,
  shipments?: ?Array<?ShipmentPayload>,
  containers?: ?Array<?ContainerPayload>,
  warehouses?: ?Array<?WarehousePayload>,
};

export type EntityCustomFieldInput = {
  fieldId: $ElementType<Scalars, 'String'>,
  entity: CustomizableEntityType,
  text?: ?$ElementType<Scalars, 'String'>,
  date?: ?DateRangeInput,
};

export type EntityEvent = {
   __typename: 'EntityEvent',
  lifeCycle: LifeCycle,
  entity: EntityPayload,
  reference: Reference,
  changes?: ?Array<ChangeType>,
};

export type EntityFileInput = {
  id: $ElementType<Scalars, 'ID'>,
  name?: ?$ElementType<Scalars, 'String'>,
  type?: ?FileType,
  status?: ?FileStatus,
  memo?: ?$ElementType<Scalars, 'String'>,
  orphan?: ?$ElementType<Scalars, 'Boolean'>,
  /** Deprecated */
  tagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  tags?: ?Array<EntityTagInput>,
  entity?: ?EntityInput,
  messageGroupId?: ?$ElementType<Scalars, 'String'>,
};

export type EntityHit = {
   __typename: 'EntityHit',
  field?: ?$ElementType<Scalars, 'String'>,
  entity: EntityPayload,
};

export type EntityInput = {
  productId?: ?$ElementType<Scalars, 'ID'>,
  productProviderId?: ?$ElementType<Scalars, 'ID'>,
  orderId?: ?$ElementType<Scalars, 'ID'>,
  orderItemId?: ?$ElementType<Scalars, 'ID'>,
  batchId?: ?$ElementType<Scalars, 'ID'>,
  shipmentId?: ?$ElementType<Scalars, 'ID'>,
  voyageId?: ?$ElementType<Scalars, 'ID'>,
  containerGroupId?: ?$ElementType<Scalars, 'ID'>,
  containerId?: ?$ElementType<Scalars, 'ID'>,
  warehouseId?: ?$ElementType<Scalars, 'ID'>,
  timelineDateId?: ?$ElementType<Scalars, 'ID'>,
  fileId?: ?$ElementType<Scalars, 'ID'>,
  folderId?: ?$ElementType<Scalars, 'ID'>,
};

export type EntityPayload = Product | ProductProvider | ProductProviderPackage | Order | OrderItem | Batch | Shipment | Voyage | Container | ContainerGroup | TimelineDate | TimelineDateRevision | Warehouse | Tag | User | Organization | Partnership | Role | File | Mask | MaskEdit | FieldDefinition | FieldValue | BadRequest | Forbidden | NotFound;

export type EntityTagInput = {
  id?: ?$ElementType<Scalars, 'ID'>,
  name?: ?$ElementType<Scalars, 'ID'>,
  color?: ?$ElementType<Scalars, 'String'>,
  description?: ?$ElementType<Scalars, 'String'>,
  entityTypes?: ?Array<TagEntityType>,
  deleted?: ?$ElementType<Scalars, 'Boolean'>,
};

export type EntityValue = {
   __typename: 'EntityValue',
  entity?: ?EntityPayload,
};

export type Export = {
   __typename: 'Export',
  id: $ElementType<Scalars, 'ID'>,
};

export type ExportExtension = {
   __typename: 'ExportExtension',
  extension: $ElementType<Scalars, 'String'>,
};

export type ExportExtensionPayload = ExportExtension | BadRequest | Forbidden | NotFound;

export type ExportPayload = Export | BadRequest | Forbidden | NotFound;

export type ExportTemplate = {
  ...Model,
  ...{
     __typename: 'ExportTemplate',
    name: $ElementType<Scalars, 'String'>,
    types: Array<ExportType>,
    extension: $ElementType<Scalars, 'String'>,
    builder: $ElementType<Scalars, 'String'>,
    fetcher: $ElementType<Scalars, 'String'>,
    normalizers: Array<$ElementType<Scalars, 'String'>>,
    ownedBy?: ?OrganizationPayload,
    id: $ElementType<Scalars, 'ID'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    deletedAt?: ?$ElementType<Scalars, 'DateTime'>,
    createdBy?: ?UserPayload,
    updatedBy?: ?UserPayload,
    deletedBy?: ?UserPayload,
  }
};

export type ExportTemplateFilterInput = {
  type?: ?ExportType,
  /** unused */
  dynamic?: ?$ElementType<Scalars, 'Boolean'>,
};

export type ExportTemplatePayload = ExportTemplate | BadRequest | Forbidden | NotFound;

export const ExportTypeValues = Object.freeze({
  /** Order */
  Order: 'Order', 
  /** Orders */
  Orders: 'Orders', 
  /** Shipment */
  Shipment: 'Shipment', 
  /** Shipments */
  Shipments: 'Shipments', 
  /** Product */
  Product: 'Product', 
  /** Products */
  Products: 'Products', 
  /** Container */
  Container: 'Container', 
  /** Containers */
  Containers: 'Containers', 
  /** Tag */
  Tag: 'Tag', 
  /** Tags */
  Tags: 'Tags', 
  /** Batch */
  Batch: 'Batch', 
  /** Batches */
  Batches: 'Batches', 
  /** Partner */
  Partner: 'Partner', 
  /** Partners */
  Partners: 'Partners', 
  /** Organization */
  Organization: 'Organization', 
  /** Organizations */
  Organizations: 'Organizations', 
  /** Warehouse */
  Warehouse: 'Warehouse', 
  /** Warehouses */
  Warehouses: 'Warehouses'
});


export type ExportType = $Values<typeof ExportTypeValues>;

export type FieldDefinition = {
  ...Model,
  ...Connected,
  ...Owned,
  ...Sortable,
  ...{
     __typename: 'FieldDefinition',
    name: $ElementType<Scalars, 'String'>,
    entityType: CustomizableEntityType,
    type: FieldDefinitionType,
    permission: FieldDefinitionPermission,
    id: $ElementType<Scalars, 'ID'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    deletedAt?: ?$ElementType<Scalars, 'DateTime'>,
    createdBy?: ?UserPayload,
    updatedBy?: ?UserPayload,
    deletedBy?: ?UserPayload,
    connectionBy?: ?ConnectionPayload,
    ownedBy: OrganizationPayload,
    sort: $ElementType<Scalars, 'Int'>,
  }
};

export type FieldDefinitionInput = {
  /** deprecated */
  id?: ?$ElementType<Scalars, 'ID'>,
  name?: ?$ElementType<Scalars, 'String'>,
  type?: ?FieldDefinitionType,
};

export type FieldDefinitionPayload = FieldDefinition | BadRequest | Forbidden | NotFound;

export type FieldDefinitionPayloadPaginatedSearch = {
  ...Paginated,
  ...{
     __typename: 'FieldDefinitionPayloadPaginatedSearch',
    nodes: Array<FieldDefinitionPayload>,
    hits: Array<Hit>,
    page: $ElementType<Scalars, 'Int'>,
    perPage: $ElementType<Scalars, 'Int'>,
    totalPage: $ElementType<Scalars, 'Int'>,
    count: $ElementType<Scalars, 'Int'>,
    totalCount: $ElementType<Scalars, 'Int'>,
  }
};

export const FieldDefinitionPermissionValues = Object.freeze({
  /** Internal */
  Internal: 'Internal', 
  /** Connection */
  Connection: 'Connection'
});


export type FieldDefinitionPermission = $Values<typeof FieldDefinitionPermissionValues>;

export type FieldDefinitions = {
   __typename: 'FieldDefinitions',
  fieldDefinitions: Array<FieldDefinition>,
};

export type FieldDefinitionsInput = {
  connectionId?: ?$ElementType<Scalars, 'ID'>,
  entityType: CustomizableEntityType,
  fieldDefinitions: Array<FieldDefinitionInput>,
};

export type FieldDefinitionsPayload = FieldDefinitions | BadRequest | Forbidden | NotFound;

export const FieldDefinitionTypeValues = Object.freeze({
  /** Text */
  Text: 'Text', 
  /** Integer */
  Integer: 'Integer', 
  /** Decimal */
  Decimal: 'Decimal', 
  /** Date */
  Date: 'Date'
});


export type FieldDefinitionType = $Values<typeof FieldDefinitionTypeValues>;

export type FieldValue = {
   __typename: 'FieldValue',
  value: Value,
  fieldDefinition: FieldDefinitionPayload,
  entity: EntityPayload,
};

export type FieldValueInput = {
  value?: ?ValueInput,
  fieldDefinitionId: $ElementType<Scalars, 'ID'>,
};

export type FieldValuePayload = FieldValue | BadRequest | Forbidden | NotFound;

export type File = {
  ...Model,
  ...Tagged,
  ...Owned,
  ...Memorizable,
  ...{
     __typename: 'File',
    name: $ElementType<Scalars, 'String'>,
    type: FileType,
    status: FileStatus,
    size: $ElementType<Scalars, 'Int'>,
    mimetype: $ElementType<Scalars, 'String'>,
    path: $ElementType<Scalars, 'String'>,
    pathExpiredAt: $ElementType<Scalars, 'DateTime'>,
    entity?: ?EntityPayload,
    orphan: $ElementType<Scalars, 'Boolean'>,
    timeline: Timeline,
    messageGroup?: ?MessageGroupPayload,
    id: $ElementType<Scalars, 'ID'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    deletedAt?: ?$ElementType<Scalars, 'DateTime'>,
    createdBy?: ?UserPayload,
    updatedBy?: ?UserPayload,
    deletedBy?: ?UserPayload,
    tags: Array<TagPayload>,
    ownedBy: OrganizationPayload,
    memo?: ?$ElementType<Scalars, 'String'>,
  }
};


export type FilePathArgs = {
  preset?: ?ResizePreset,
  width?: ?$ElementType<Scalars, 'Int'>,
  height?: ?$ElementType<Scalars, 'Int'>
};

export type FileFilterInput = {
  query?: ?$ElementType<Scalars, 'String'>,
  ownerId?: ?$ElementType<Scalars, 'ID'>,
  excludeIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  createdAt?: ?DateRangeInput,
  updatedAt?: ?DateRangeInput,
  type?: ?FileType,
  status?: ?FileStatus,
  mimetype?: ?$ElementType<Scalars, 'String'>,
  hasEntity?: ?$ElementType<Scalars, 'Boolean'>,
  hasEntityExcludeId?: ?$ElementType<Scalars, 'ID'>,
  /** Deprecated */
  tagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  tagIdsWithOperator?: ?IdsWithOperatorInput,
  notTagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  entityId?: ?$ElementType<Scalars, 'ID'>,
  entityIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  orderIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  orderItemIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  productProviderIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  productIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  shipmentIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  entityType?: ?$ElementType<Scalars, 'String'>,
  entityArchived?: ?$ElementType<Scalars, 'Boolean'>,
  createdByOrganizationIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  /** 
 * The organizations which have access to or relate to this file (e.g. the
   * organizations with access to the shipment this file is attached to)
 */
  organizationIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  includePrivate?: ?$ElementType<Scalars, 'Boolean'>,
  includeDeleted?: ?$ElementType<Scalars, 'Boolean'>,
};

export type FileInput = {
  name?: ?$ElementType<Scalars, 'String'>,
  type?: ?FileType,
  status?: ?FileStatus,
  memo?: ?$ElementType<Scalars, 'String'>,
  orphan?: ?$ElementType<Scalars, 'Boolean'>,
  /** Deprecated */
  tagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  tags?: ?Array<EntityTagInput>,
  entity?: ?EntityInput,
  messageGroupId?: ?$ElementType<Scalars, 'String'>,
};

export type FilePayload = File | BadRequest | Forbidden | NotFound;

export type FilePayloadPaginatedSearch = {
  ...Paginated,
  ...{
     __typename: 'FilePayloadPaginatedSearch',
    nodes: Array<FilePayload>,
    hits: Array<Hit>,
    page: $ElementType<Scalars, 'Int'>,
    perPage: $ElementType<Scalars, 'Int'>,
    totalPage: $ElementType<Scalars, 'Int'>,
    count: $ElementType<Scalars, 'Int'>,
    totalCount: $ElementType<Scalars, 'Int'>,
  }
};

export type FileSortInput = {
  createdAt?: ?SortOrder,
  updatedAt?: ?SortOrder,
  name?: ?SortOrder,
  type?: ?SortOrder,
  status?: ?SortOrder,
  size?: ?SortOrder,
};

export const FileStatusValues = Object.freeze({
  /** Draft */
  Draft: 'Draft', 
  /** Submitted */
  Submitted: 'Submitted', 
  /** Revise */
  Revise: 'Revise', 
  /** Approved */
  Approved: 'Approved'
});


export type FileStatus = $Values<typeof FileStatusValues>;

export const FileTypeValues = Object.freeze({
  /** File */
  File: 'File', 
  /** Image */
  Image: 'Image', 
  /** Document */
  Document: 'Document', 
  /** OrderPo */
  OrderPo: 'OrderPo', 
  /** OrderPi */
  OrderPi: 'OrderPi', 
  /** ShipmentBl */
  ShipmentBl: 'ShipmentBl', 
  /** ShipmentInvoice */
  ShipmentInvoice: 'ShipmentInvoice', 
  /** ShipmentPackingList */
  ShipmentPackingList: 'ShipmentPackingList', 
  /** ShipmentImportDeclaration */
  ShipmentImportDeclaration: 'ShipmentImportDeclaration', 
  /** ShipmentInspectionApplication */
  ShipmentInspectionApplication: 'ShipmentInspectionApplication', 
  /** ShipmentWarehouseArrivalReport */
  ShipmentWarehouseArrivalReport: 'ShipmentWarehouseArrivalReport', 
  /** ShipmentWarehouseInspectionReport */
  ShipmentWarehouseInspectionReport: 'ShipmentWarehouseInspectionReport', 
  /** ShipmentExportDeclaration */
  ShipmentExportDeclaration: 'ShipmentExportDeclaration', 
  /** ShipmentShippingAdvice */
  ShipmentShippingAdvice: 'ShipmentShippingAdvice', 
  /** ShipmentWarehouseLoadingReport */
  ShipmentWarehouseLoadingReport: 'ShipmentWarehouseLoadingReport', 
  /** ProductSpec */
  ProductSpec: 'ProductSpec', 
  /** ProductAnalysisCert */
  ProductAnalysisCert: 'ProductAnalysisCert', 
  /** ProductOriginCert */
  ProductOriginCert: 'ProductOriginCert'
});


export type FileType = $Values<typeof FileTypeValues>;

export type FloatValue = {
   __typename: 'FloatValue',
  float: $ElementType<Scalars, 'Float'>,
};

export type Focus = {
   __typename: 'Focus',
  id: $ElementType<Scalars, 'ID'>,
  user: UserPayload,
  entity: EntityPayload,
  field: $ElementType<Scalars, 'String'>,
};

export type FocusEvent = Focus | Blur;

export type FocusingInput = {
  entity: EntityInput,
  field: $ElementType<Scalars, 'String'>,
};

export type FocusPayload = Focus | BadRequest | Forbidden | NotFound;

export type Folder = {
   __typename: 'Folder',
  id: $ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
  entityType: FolderEntityType,
  folderType: FolderType,
  unreadMessageCount?: ?$ElementType<Scalars, 'Int'>,
  totalCount: $ElementType<Scalars, 'Int'>,
};


export type FolderTotalCountArgs = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?FileFilterInput,
  sortBy?: ?FileSortInput
};

export const FolderEntityTypeValues = Object.freeze({
  Order: 'Order', 
  OrderItem: 'OrderItem', 
  Shipment: 'Shipment', 
  Product: 'Product', 
  ProductProvider: 'ProductProvider', 
  Organization: 'Organization'
});


export type FolderEntityType = $Values<typeof FolderEntityTypeValues>;

export type FolderFilterInput = {
  query?: ?$ElementType<Scalars, 'String'>,
  ownerId?: ?$ElementType<Scalars, 'ID'>,
  excludeIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  createdAt?: ?DateRangeInput,
  updatedAt?: ?DateRangeInput,
  organizationIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  entityType?: ?FolderEntityType,
  folderType?: ?FolderType,
  shipmentIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  orderIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  productIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  productProviderIds?: ?Array<$ElementType<Scalars, 'ID'>>,
};

export type FolderPayload = Folder | BadRequest | Forbidden | NotFound;

export type FolderPayloadPaginatedSearch = {
  ...Paginated,
  ...{
     __typename: 'FolderPayloadPaginatedSearch',
    nodes: Array<FolderPayload>,
    hits: Array<Hit>,
    page: $ElementType<Scalars, 'Int'>,
    perPage: $ElementType<Scalars, 'Int'>,
    totalPage: $ElementType<Scalars, 'Int'>,
    count: $ElementType<Scalars, 'Int'>,
    totalCount: $ElementType<Scalars, 'Int'>,
  }
};

export type FolderSortInput = {
  createdAt?: ?SortOrder,
  updatedAt?: ?SortOrder,
  name?: ?SortOrder,
};

export const FolderTypeValues = Object.freeze({
  Entity: 'ENTITY', 
  Parentless: 'PARENTLESS', 
  Sender: 'SENDER'
});


export type FolderType = $Values<typeof FolderTypeValues>;

export type Followed = {
  followers: Array<UserPayload>,
  notificationUnseen: Array<NotificationPayload>,
  notificationUnseenCount: $ElementType<Scalars, 'Int'>,
};

export type Forbidden = {
   __typename: 'Forbidden',
  reference?: ?Reference,
  reason?: ?$ElementType<Scalars, 'String'>,
};

export type GenerateShipmentPurchaseOrderInput = {
  shipmentId: $ElementType<Scalars, 'String'>,
};

export type GenerateShipmentPurchaseOrderResponse = {
   __typename: 'GenerateShipmentPurchaseOrderResponse',
  fileUrl: $ElementType<Scalars, 'String'>,
};

export type GenerateShipmentPurchaseOrderResponsePayload = GenerateShipmentPurchaseOrderResponse | BadRequest | Forbidden | NotFound;

export type GenericFileCount = {
   __typename: 'GenericFileCount',
  count: $ElementType<Scalars, 'Int'>,
  miscellaneous: $ElementType<Scalars, 'Int'>,
};

export type GenericSortInput = {
  field: $ElementType<Scalars, 'String'>,
  direction: SortOrder,
};

export type Hit = {
   __typename: 'Hit',
  score: $ElementType<Scalars, 'Float'>,
  entityHits: Array<EntityHit>,
};

export type IdsWithOperatorInput = {
  ids?: ?Array<$ElementType<Scalars, 'ID'>>,
  operator?: ?$ElementType<Scalars, 'String'>,
};

export type Import = {
   __typename: 'Import',
  id: $ElementType<Scalars, 'ID'>,
};

export type ImportEvent = {
   __typename: 'ImportEvent',
  lifecycle: ImportLifecycle,
  sheet?: ?$ElementType<Scalars, 'String'>,
  error?: ?$ElementType<Scalars, 'String'>,
  violations: Array<Violation>,
};

export const ImportLifecycleValues = Object.freeze({
  /** Start */
  Start: 'Start', 
  /** StartRead */
  StartRead: 'StartRead', 
  /** EndRead */
  EndRead: 'EndRead', 
  /** StartPrepare */
  StartPrepare: 'StartPrepare', 
  /** EndPrepare */
  EndPrepare: 'EndPrepare', 
  /** StartImport */
  StartImport: 'StartImport', 
  /** EndImport */
  EndImport: 'EndImport', 
  /** EndImportOneSheet */
  EndImportOneSheet: 'EndImportOneSheet', 
  /** EndImportOneFile */
  EndImportOneFile: 'EndImportOneFile', 
  /** End */
  End: 'End', 
  /** Error */
  Error: 'Error'
});


export type ImportLifecycle = $Values<typeof ImportLifecycleValues>;

export type ImportPayload = Import | BadRequest | Forbidden | NotFound;

export const IncotermValues = Object.freeze({
  Fas: 'FAS', 
  Fob: 'FOB', 
  Cfr: 'CFR', 
  Cif: 'CIF', 
  Exw: 'EXW', 
  Fca: 'FCA', 
  Cpt: 'CPT', 
  Cip: 'CIP', 
  Dat: 'DAT', 
  Dap: 'DAP', 
  Ddp: 'DDP', 
  Dpu: 'DPU'
});


export type Incoterm = $Values<typeof IncotermValues>;

export type Integration = {
  ...Model,
  ...Owned,
  ...{
     __typename: 'Integration',
    name: $ElementType<Scalars, 'String'>,
    service: $ElementType<Scalars, 'String'>,
    bot: UserPayload,
    automated: $ElementType<Scalars, 'Boolean'>,
    id: $ElementType<Scalars, 'ID'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    deletedAt?: ?$ElementType<Scalars, 'DateTime'>,
    createdBy?: ?UserPayload,
    updatedBy?: ?UserPayload,
    deletedBy?: ?UserPayload,
    ownedBy: OrganizationPayload,
  }
};

export type IntegrationConfiguration = {
  ...Model,
  ...Owned,
  ...{
     __typename: 'IntegrationConfiguration',
    integration: IntegrationPayload,
    automated: $ElementType<Scalars, 'Boolean'>,
    interval: Duration,
    key?: ?$ElementType<Scalars, 'String'>,
    id: $ElementType<Scalars, 'ID'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    deletedAt?: ?$ElementType<Scalars, 'DateTime'>,
    createdBy?: ?UserPayload,
    updatedBy?: ?UserPayload,
    deletedBy?: ?UserPayload,
    ownedBy: OrganizationPayload,
  }
};

export type IntegrationConfigurationCreateInput = {
  integrationId: $ElementType<Scalars, 'ID'>,
  automated?: ?$ElementType<Scalars, 'Boolean'>,
  interval: DurationInput,
  key?: ?$ElementType<Scalars, 'String'>,
};

export type IntegrationConfigurationFilterInput = {
  query?: ?$ElementType<Scalars, 'String'>,
  ownerId?: ?$ElementType<Scalars, 'ID'>,
  excludeIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  createdAt?: ?DateRangeInput,
  updatedAt?: ?DateRangeInput,
  organizationId?: ?$ElementType<Scalars, 'ID'>,
  integrationId?: ?$ElementType<Scalars, 'ID'>,
};

export type IntegrationConfigurationPayload = IntegrationConfiguration | BadRequest | Forbidden | NotFound;

export type IntegrationConfigurationPayloadPaginatedSearch = {
  ...Paginated,
  ...{
     __typename: 'IntegrationConfigurationPayloadPaginatedSearch',
    nodes: Array<IntegrationConfigurationPayload>,
    hits: Array<Hit>,
    page: $ElementType<Scalars, 'Int'>,
    perPage: $ElementType<Scalars, 'Int'>,
    totalPage: $ElementType<Scalars, 'Int'>,
    count: $ElementType<Scalars, 'Int'>,
    totalCount: $ElementType<Scalars, 'Int'>,
  }
};

export type IntegrationConfigurationSortInput = {
  createdAt?: ?SortOrder,
  updatedAt?: ?SortOrder,
};

export type IntegrationConfigurationUpdateInput = {
  integrationId?: ?$ElementType<Scalars, 'ID'>,
  automated?: ?$ElementType<Scalars, 'Boolean'>,
  interval?: ?DurationInput,
  key?: ?$ElementType<Scalars, 'String'>,
};

export type IntegrationFilterInput = {
  query?: ?$ElementType<Scalars, 'String'>,
  ownerId?: ?$ElementType<Scalars, 'ID'>,
  excludeIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  createdAt?: ?DateRangeInput,
  updatedAt?: ?DateRangeInput,
  organizationId?: ?$ElementType<Scalars, 'ID'>,
};

export type IntegrationPayload = Integration | BadRequest | Forbidden | NotFound;

export type IntegrationPayloadPaginatedSearch = {
  ...Paginated,
  ...{
     __typename: 'IntegrationPayloadPaginatedSearch',
    nodes: Array<IntegrationPayload>,
    hits: Array<Hit>,
    page: $ElementType<Scalars, 'Int'>,
    perPage: $ElementType<Scalars, 'Int'>,
    totalPage: $ElementType<Scalars, 'Int'>,
    count: $ElementType<Scalars, 'Int'>,
    totalCount: $ElementType<Scalars, 'Int'>,
  }
};

export type IntegrationSortInput = {
  createdAt?: ?SortOrder,
  updatedAt?: ?SortOrder,
  name?: ?SortOrder,
};

export type InternalPartnerGroup = {
  ...Model,
  ...Memorizable,
  ...{
     __typename: 'InternalPartnerGroup',
    key: $ElementType<Scalars, 'String'>,
    connectionBy: ConnectionPayload,
    parentInternalPartnerGroup?: ?InternalPartnerGroupPayload,
    organizations: OrganizationPayloadPaginatedList,
    id: $ElementType<Scalars, 'ID'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    deletedAt?: ?$ElementType<Scalars, 'DateTime'>,
    createdBy?: ?UserPayload,
    updatedBy?: ?UserPayload,
    deletedBy?: ?UserPayload,
    memo?: ?$ElementType<Scalars, 'String'>,
  }
};


export type InternalPartnerGroupOrganizationsArgs = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?OrganizationFilterInput
};

export type InternalPartnerGroupPayload = InternalPartnerGroup | BadRequest | Forbidden | NotFound;

export type Interval = {
   __typename: 'Interval',
  years: $ElementType<Scalars, 'Int'>,
  months: $ElementType<Scalars, 'Int'>,
  weeks: $ElementType<Scalars, 'Int'>,
  days: $ElementType<Scalars, 'Int'>,
  hours: $ElementType<Scalars, 'Int'>,
  minutes: $ElementType<Scalars, 'Int'>,
  seconds: $ElementType<Scalars, 'Int'>,
  nanos: $ElementType<Scalars, 'Int'>,
};

export type IntervalInput = {
  years?: ?$ElementType<Scalars, 'Int'>,
  months?: ?$ElementType<Scalars, 'Int'>,
  weeks?: ?$ElementType<Scalars, 'Int'>,
  days?: ?$ElementType<Scalars, 'Int'>,
  hours?: ?$ElementType<Scalars, 'Int'>,
  minutes?: ?$ElementType<Scalars, 'Int'>,
  seconds?: ?$ElementType<Scalars, 'Int'>,
  nanos?: ?$ElementType<Scalars, 'Int'>,
};

export type IntervalValue = {
   __typename: 'IntervalValue',
  interval: Interval,
};

export type IntValue = {
   __typename: 'IntValue',
  int: $ElementType<Scalars, 'Int'>,
};

export const LanguageValues = Object.freeze({
  /** Afar */
  Aa: 'aa', 
  /** Abkhazian */
  Ab: 'ab', 
  /** Avestan */
  Ae: 'ae', 
  /** Afrikaans */
  Af: 'af', 
  /** Akan */
  Ak: 'ak', 
  /** Amharic */
  Am: 'am', 
  /** Aragonese */
  An: 'an', 
  /** Arabic */
  Ar: 'ar', 
  /** Assamese */
  As: 'as', 
  /** Avaric */
  Av: 'av', 
  /** Aymara */
  Ay: 'ay', 
  /** Azerbaijani */
  Az: 'az', 
  /** Bashkir */
  Ba: 'ba', 
  /** Belarusian */
  Be: 'be', 
  /** Bulgarian */
  Bg: 'bg', 
  /** Bihari */
  Bh: 'bh', 
  /** Bislama */
  Bi: 'bi', 
  /** Bambara */
  Bm: 'bm', 
  /** Bangla */
  Bn: 'bn', 
  /** Tibetan */
  Bo: 'bo', 
  /** Breton */
  Br: 'br', 
  /** Bosnian */
  Bs: 'bs', 
  /** Catalan */
  Ca: 'ca', 
  /** Chechen */
  Ce: 'ce', 
  /** Chamorro */
  Ch: 'ch', 
  /** Corsican */
  Co: 'co', 
  /** Cree */
  Cr: 'cr', 
  /** Czech */
  Cs: 'cs', 
  /** Church Slavic */
  Cu: 'cu', 
  /** Chuvash */
  Cv: 'cv', 
  /** Welsh */
  Cy: 'cy', 
  /** Danish */
  Da: 'da', 
  /** German */
  De: 'de', 
  /** Divehi */
  Dv: 'dv', 
  /** Dzongkha */
  Dz: 'dz', 
  /** Ewe */
  Ee: 'ee', 
  /** Greek */
  El: 'el', 
  /** English */
  En: 'en', 
  /** Esperanto */
  Eo: 'eo', 
  /** Spanish */
  Es: 'es', 
  /** Estonian */
  Et: 'et', 
  /** Basque */
  Eu: 'eu', 
  /** Persian */
  Fa: 'fa', 
  /** Fulah */
  Ff: 'ff', 
  /** Finnish */
  Fi: 'fi', 
  /** Fijian */
  Fj: 'fj', 
  /** Faroese */
  Fo: 'fo', 
  /** French */
  Fr: 'fr', 
  /** Western Frisian */
  Fy: 'fy', 
  /** Irish */
  Ga: 'ga', 
  /** Scottish Gaelic */
  Gd: 'gd', 
  /** Galician */
  Gl: 'gl', 
  /** Guarani */
  Gn: 'gn', 
  /** Gujarati */
  Gu: 'gu', 
  /** Manx */
  Gv: 'gv', 
  /** Hausa */
  Ha: 'ha', 
  /** Hebrew */
  Iw: 'iw', 
  /** Hindi */
  Hi: 'hi', 
  /** Hiri Motu */
  Ho: 'ho', 
  /** Croatian */
  Hr: 'hr', 
  /** Haitian Creole */
  Ht: 'ht', 
  /** Hungarian */
  Hu: 'hu', 
  /** Armenian */
  Hy: 'hy', 
  /** Herero */
  Hz: 'hz', 
  /** Interlingua */
  Ia: 'ia', 
  /** Indonesian */
  In: 'in', 
  /** Interlingue */
  Ie: 'ie', 
  /** Igbo */
  Ig: 'ig', 
  /** Sichuan Yi */
  Ii: 'ii', 
  /** Inupiaq */
  Ik: 'ik', 
  /** Ido */
  Io: 'io', 
  /** Icelandic */
  Is: 'is', 
  /** Italian */
  It: 'it', 
  /** Inuktitut */
  Iu: 'iu', 
  /** Japanese */
  Ja: 'ja', 
  /** Yiddish */
  Ji: 'ji', 
  /** Javanese */
  Jv: 'jv', 
  /** Georgian */
  Ka: 'ka', 
  /** Kongo */
  Kg: 'kg', 
  /** Kikuyu */
  Ki: 'ki', 
  /** Kuanyama */
  Kj: 'kj', 
  /** Kazakh */
  Kk: 'kk', 
  /** Kalaallisut */
  Kl: 'kl', 
  /** Khmer */
  Km: 'km', 
  /** Kannada */
  Kn: 'kn', 
  /** Korean */
  Ko: 'ko', 
  /** Kanuri */
  Kr: 'kr', 
  /** Kashmiri */
  Ks: 'ks', 
  /** Kurdish */
  Ku: 'ku', 
  /** Komi */
  Kv: 'kv', 
  /** Cornish */
  Kw: 'kw', 
  /** Kyrgyz */
  Ky: 'ky', 
  /** Latin */
  La: 'la', 
  /** Luxembourgish */
  Lb: 'lb', 
  /** Ganda */
  Lg: 'lg', 
  /** Limburgish */
  Li: 'li', 
  /** Lingala */
  Ln: 'ln', 
  /** Lao */
  Lo: 'lo', 
  /** Lithuanian */
  Lt: 'lt', 
  /** Luba-Katanga */
  Lu: 'lu', 
  /** Latvian */
  Lv: 'lv', 
  /** Malagasy */
  Mg: 'mg', 
  /** Marshallese */
  Mh: 'mh', 
  /** Maori */
  Mi: 'mi', 
  /** Macedonian */
  Mk: 'mk', 
  /** Malayalam */
  Ml: 'ml', 
  /** Mongolian */
  Mn: 'mn', 
  /** Moldavian */
  Mo: 'mo', 
  /** Marathi */
  Mr: 'mr', 
  /** Malay */
  Ms: 'ms', 
  /** Maltese */
  Mt: 'mt', 
  /** Burmese */
  My: 'my', 
  /** Nauru */
  Na: 'na', 
  /** Norwegian Bokmål */
  Nb: 'nb', 
  /** North Ndebele */
  Nd: 'nd', 
  /** Nepali */
  Ne: 'ne', 
  /** Ndonga */
  Ng: 'ng', 
  /** Dutch */
  Nl: 'nl', 
  /** Norwegian Nynorsk */
  Nn: 'nn', 
  /** Norwegian */
  No: 'no', 
  /** South Ndebele */
  Nr: 'nr', 
  /** Navajo */
  Nv: 'nv', 
  /** Nyanja */
  Ny: 'ny', 
  /** Occitan */
  Oc: 'oc', 
  /** Ojibwa */
  Oj: 'oj', 
  /** Oromo */
  Om: 'om', 
  /** Odia */
  Or: 'or', 
  /** Ossetic */
  Os: 'os', 
  /** Punjabi */
  Pa: 'pa', 
  /** Pali */
  Pi: 'pi', 
  /** Polish */
  Pl: 'pl', 
  /** Pashto */
  Ps: 'ps', 
  /** Portuguese */
  Pt: 'pt', 
  /** Quechua */
  Qu: 'qu', 
  /** Romansh */
  Rm: 'rm', 
  /** Rundi */
  Rn: 'rn', 
  /** Romanian */
  Ro: 'ro', 
  /** Russian */
  Ru: 'ru', 
  /** Kinyarwanda */
  Rw: 'rw', 
  /** Sanskrit */
  Sa: 'sa', 
  /** Sardinian */
  Sc: 'sc', 
  /** Sindhi */
  Sd: 'sd', 
  /** Northern Sami */
  Se: 'se', 
  /** Sango */
  Sg: 'sg', 
  /** Sinhala */
  Si: 'si', 
  /** Slovak */
  Sk: 'sk', 
  /** Slovenian */
  Sl: 'sl', 
  /** Samoan */
  Sm: 'sm', 
  /** Shona */
  Sn: 'sn', 
  /** Somali */
  So: 'so', 
  /** Albanian */
  Sq: 'sq', 
  /** Serbian */
  Sr: 'sr', 
  /** Swati */
  Ss: 'ss', 
  /** Southern Sotho */
  St: 'st', 
  /** Sundanese */
  Su: 'su', 
  /** Swedish */
  Sv: 'sv', 
  /** Swahili */
  Sw: 'sw', 
  /** Tamil */
  Ta: 'ta', 
  /** Telugu */
  Te: 'te', 
  /** Tajik */
  Tg: 'tg', 
  /** Thai */
  Th: 'th', 
  /** Tigrinya */
  Ti: 'ti', 
  /** Turkmen */
  Tk: 'tk', 
  /** Tagalog */
  Tl: 'tl', 
  /** Tswana */
  Tn: 'tn', 
  /** Tongan */
  To: 'to', 
  /** Turkish */
  Tr: 'tr', 
  /** Tsonga */
  Ts: 'ts', 
  /** Tatar */
  Tt: 'tt', 
  /** Twi */
  Tw: 'tw', 
  /** Tahitian */
  Ty: 'ty', 
  /** Uyghur */
  Ug: 'ug', 
  /** Ukrainian */
  Uk: 'uk', 
  /** Urdu */
  Ur: 'ur', 
  /** Uzbek */
  Uz: 'uz', 
  /** Venda */
  Ve: 've', 
  /** Vietnamese */
  Vi: 'vi', 
  /** Volapük */
  Vo: 'vo', 
  /** Walloon */
  Wa: 'wa', 
  /** Wolof */
  Wo: 'wo', 
  /** Xhosa */
  Xh: 'xh', 
  /** Yoruba */
  Yo: 'yo', 
  /** Zhuang */
  Za: 'za', 
  /** Chinese */
  Zh: 'zh', 
  /** Zulu */
  Zu: 'zu'
});


export type Language = $Values<typeof LanguageValues>;

export const LifeCycleValues = Object.freeze({
  /** Update */
  Update: 'Update', 
  /** Create */
  Create: 'Create', 
  /** SoftDelete */
  SoftDelete: 'SoftDelete', 
  /** Delete */
  Delete: 'Delete'
});


export type LifeCycle = $Values<typeof LifeCycleValues>;

export const LoadTypeValues = Object.freeze({
  Fcl: 'FCL', 
  Lcl: 'LCL', 
  Air: 'AIR'
});


export type LoadType = $Values<typeof LoadTypeValues>;

export type Log = {
   __typename: 'Log',
  id: $ElementType<Scalars, 'ID'>,
  entity: EntityPayload,
  translationKey: $ElementType<Scalars, 'String'>,
  parameters: Array<Parameter>,
};


export type Mask = {
  ...Model,
  ...Owned,
  ...Memorizable,
  ...{
     __typename: 'Mask',
    name: $ElementType<Scalars, 'String'>,
    entityType: CustomizableEntityType,
    fieldDefinitions: Array<FieldDefinitionPayload>,
    id: $ElementType<Scalars, 'ID'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    deletedAt?: ?$ElementType<Scalars, 'DateTime'>,
    createdBy?: ?UserPayload,
    updatedBy?: ?UserPayload,
    deletedBy?: ?UserPayload,
    ownedBy: OrganizationPayload,
    memo?: ?$ElementType<Scalars, 'String'>,
  }
};

export type MaskCreateInput = {
  name: $ElementType<Scalars, 'String'>,
  entityType: CustomizableEntityType,
  memo?: ?$ElementType<Scalars, 'String'>,
  fieldDefinitionIDs: Array<$ElementType<Scalars, 'ID'>>,
};

export type MaskEdit = {
  ...Model,
  ...Owned,
  ...Memorizable,
  ...{
     __typename: 'MaskEdit',
    name: $ElementType<Scalars, 'String'>,
    type: MaskEditType,
    fields: Array<$ElementType<Scalars, 'String'>>,
    columns: Array<MaskEditColumn>,
    sections: Array<MaskEditSection>,
    filterSort?: ?$ElementType<Scalars, 'String'>,
    collapses: Array<$ElementType<Scalars, 'String'>>,
    selectedMaskEdit?: ?MaskEdit,
    id: $ElementType<Scalars, 'ID'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    deletedAt?: ?$ElementType<Scalars, 'DateTime'>,
    createdBy?: ?UserPayload,
    updatedBy?: ?UserPayload,
    deletedBy?: ?UserPayload,
    ownedBy: OrganizationPayload,
    memo?: ?$ElementType<Scalars, 'String'>,
  }
};

export type MaskEditByUserInput = {
  name: $ElementType<Scalars, 'String'>,
  type: MaskEditType,
  memo?: ?$ElementType<Scalars, 'String'>,
  /** Deprecated */
  fields?: ?Array<$ElementType<Scalars, 'String'>>,
  columns: Array<MaskEditColumnInput>,
  sections?: ?Array<MaskEditSectionInput>,
  filterSort?: ?$ElementType<Scalars, 'String'>,
  collapses?: ?Array<$ElementType<Scalars, 'String'>>,
  selectedMaskEditId?: ?$ElementType<Scalars, 'ID'>,
};

export type MaskEditColumn = {
   __typename: 'MaskEditColumn',
  key: $ElementType<Scalars, 'String'>,
  hidden: $ElementType<Scalars, 'Boolean'>,
  group?: ?$ElementType<Scalars, 'String'>,
  width?: ?$ElementType<Scalars, 'Int'>,
  pinned?: ?$ElementType<Scalars, 'Boolean'>,
  highlight?: ?MaskEditColumnHighlight,
};

export const MaskEditColumnHighlightValues = Object.freeze({
  /** Red */
  Red: 'Red', 
  /** Orange */
  Orange: 'Orange', 
  /** Yellow */
  Yellow: 'Yellow', 
  /** Green */
  Green: 'Green', 
  /** Blue */
  Blue: 'Blue', 
  /** Purple */
  Purple: 'Purple'
});


export type MaskEditColumnHighlight = $Values<typeof MaskEditColumnHighlightValues>;

export type MaskEditColumnInput = {
  key: $ElementType<Scalars, 'String'>,
  hidden: $ElementType<Scalars, 'Boolean'>,
  group?: ?$ElementType<Scalars, 'String'>,
  width?: ?$ElementType<Scalars, 'Int'>,
  pinned?: ?$ElementType<Scalars, 'Boolean'>,
  highlight?: ?MaskEditColumnHighlight,
};

export type MaskEditCreateInput = {
  name: $ElementType<Scalars, 'String'>,
  type: MaskEditType,
  memo?: ?$ElementType<Scalars, 'String'>,
  /** Deprecated */
  fields?: ?Array<$ElementType<Scalars, 'String'>>,
  columns: Array<MaskEditColumnInput>,
  sections?: ?Array<MaskEditSectionInput>,
  filterSort?: ?$ElementType<Scalars, 'String'>,
  collapses?: ?Array<$ElementType<Scalars, 'String'>>,
};

export type MaskEditFilterInput = {
  query?: ?$ElementType<Scalars, 'String'>,
  ownerId?: ?$ElementType<Scalars, 'ID'>,
  excludeIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  createdAt?: ?DateRangeInput,
  updatedAt?: ?DateRangeInput,
  type?: ?MaskEditType,
};

export type MaskEditPayload = MaskEdit | BadRequest | Forbidden | NotFound;

export type MaskEditPayloadPaginatedSearch = {
  ...Paginated,
  ...{
     __typename: 'MaskEditPayloadPaginatedSearch',
    nodes: Array<MaskEditPayload>,
    hits: Array<Hit>,
    page: $ElementType<Scalars, 'Int'>,
    perPage: $ElementType<Scalars, 'Int'>,
    totalPage: $ElementType<Scalars, 'Int'>,
    count: $ElementType<Scalars, 'Int'>,
    totalCount: $ElementType<Scalars, 'Int'>,
  }
};

export type MaskEditSection = {
   __typename: 'MaskEditSection',
  name: $ElementType<Scalars, 'String'>,
  active: $ElementType<Scalars, 'Boolean'>,
};

export type MaskEditSectionInput = {
  name: $ElementType<Scalars, 'String'>,
  active: $ElementType<Scalars, 'Boolean'>,
};

export type MaskEditSortInput = {
  createdAt?: ?SortOrder,
  updatedAt?: ?SortOrder,
  name?: ?SortOrder,
};

export const MaskEditTypeValues = Object.freeze({
  /** Order */
  Order: 'Order', 
  /** OrderSheet */
  OrderSheet: 'OrderSheet', 
  /** ShipmentSheet */
  ShipmentSheet: 'ShipmentSheet', 
  /** BatchSheet */
  BatchSheet: 'BatchSheet'
});


export type MaskEditType = $Values<typeof MaskEditTypeValues>;

export type MaskEditUpdateInput = {
  name?: ?$ElementType<Scalars, 'String'>,
  type?: ?MaskEditType,
  memo?: ?$ElementType<Scalars, 'String'>,
  /** Deprecated */
  fields?: ?Array<$ElementType<Scalars, 'String'>>,
  columns?: ?Array<MaskEditColumnInput>,
  sections?: ?Array<MaskEditSectionInput>,
  filterSort?: ?$ElementType<Scalars, 'String'>,
  collapses?: ?Array<$ElementType<Scalars, 'String'>>,
};

export type MaskFilterInput = {
  query?: ?$ElementType<Scalars, 'String'>,
  ownerId?: ?$ElementType<Scalars, 'ID'>,
  excludeIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  createdAt?: ?DateRangeInput,
  updatedAt?: ?DateRangeInput,
  entityTypes?: ?Array<CustomizableEntityType>,
};

export type MaskPayload = Mask | BadRequest | Forbidden | NotFound;

export type MaskPayloadPaginatedSearch = {
  ...Paginated,
  ...{
     __typename: 'MaskPayloadPaginatedSearch',
    nodes: Array<MaskPayload>,
    hits: Array<Hit>,
    page: $ElementType<Scalars, 'Int'>,
    perPage: $ElementType<Scalars, 'Int'>,
    totalPage: $ElementType<Scalars, 'Int'>,
    count: $ElementType<Scalars, 'Int'>,
    totalCount: $ElementType<Scalars, 'Int'>,
  }
};

export type MaskSortInput = {
  createdAt?: ?SortOrder,
  updatedAt?: ?SortOrder,
  name?: ?SortOrder,
};

export type MaskUpdateInput = {
  name?: ?$ElementType<Scalars, 'String'>,
  entityType?: ?CustomizableEntityType,
  memo?: ?$ElementType<Scalars, 'String'>,
  fieldDefinitionIDs?: ?Array<$ElementType<Scalars, 'ID'>>,
};

export type Memorizable = {
  memo?: ?$ElementType<Scalars, 'String'>,
};

export type Message = {
  ...Model,
  ...{
     __typename: 'Message',
    content: $ElementType<Scalars, 'String'>,
    entity?: ?EntityPayload,
    file?: ?FilePayload,
    messageEmoji: Array<MessageEmojiPayload>,
    messageGroup?: ?MessageGroupPayload,
    id: $ElementType<Scalars, 'ID'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    deletedAt?: ?$ElementType<Scalars, 'DateTime'>,
    createdBy?: ?UserPayload,
    updatedBy?: ?UserPayload,
    deletedBy?: ?UserPayload,
  }
};

export type MessageCreateInput = {
  content: $ElementType<Scalars, 'String'>,
  entity: EntityInput,
  messageGroupId?: ?$ElementType<Scalars, 'ID'>,
};

export type MessageEmoji = {
  ...Model,
  ...{
     __typename: 'MessageEmoji',
    emoji: EmojiPayload,
    id: $ElementType<Scalars, 'ID'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    deletedAt?: ?$ElementType<Scalars, 'DateTime'>,
    createdBy?: ?UserPayload,
    updatedBy?: ?UserPayload,
    deletedBy?: ?UserPayload,
  }
};

export type MessageEmojiPayload = MessageEmoji | BadRequest | Forbidden | NotFound;

export type MessageEmojiUpdateInput = {
  messageId: $ElementType<Scalars, 'String'>,
  emojiId: $ElementType<Scalars, 'String'>,
};

export type MessageFilterInput = {
  query?: ?$ElementType<Scalars, 'String'>,
  ownerId?: ?$ElementType<Scalars, 'ID'>,
  excludeIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  createdAt?: ?DateRangeInput,
  updatedAt?: ?DateRangeInput,
  messageGroupId?: ?$ElementType<Scalars, 'ID'>,
  entityId?: ?$ElementType<Scalars, 'ID'>,
  entityType?: ?$ElementType<Scalars, 'String'>,
};

export type MessageGroup = {
  ...Model,
  ...Documented,
  ...{
     __typename: 'MessageGroup',
    entity: EntityPayload,
    organizations: Array<OrganizationPayload>,
    unreadMessageCount: $ElementType<Scalars, 'Int'>,
    id: $ElementType<Scalars, 'ID'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    deletedAt?: ?$ElementType<Scalars, 'DateTime'>,
    createdBy?: ?UserPayload,
    updatedBy?: ?UserPayload,
    deletedBy?: ?UserPayload,
    hasFile: $ElementType<Scalars, 'Boolean'>,
    filesUnreadCount: $ElementType<Scalars, 'Int'>,
    files: Array<FilePayload>,
  }
};

export type MessageGroupCreateInput = {
  entity: EntityInput,
  organizationIds?: ?Array<$ElementType<Scalars, 'ID'>>,
};

export type MessageGrouped = {
  hasMessage: $ElementType<Scalars, 'Boolean'>,
  messageGroups: Array<MessageGroupPayload>,
};

export type MessageGroupPayload = MessageGroup | BadRequest | Forbidden | NotFound;

export type MessagePayload = Message | BadRequest | Forbidden | NotFound;

export type MessagePayloadPaginatedSearch = {
  ...Paginated,
  ...{
     __typename: 'MessagePayloadPaginatedSearch',
    nodes: Array<MessagePayload>,
    hits: Array<Hit>,
    page: $ElementType<Scalars, 'Int'>,
    perPage: $ElementType<Scalars, 'Int'>,
    totalPage: $ElementType<Scalars, 'Int'>,
    count: $ElementType<Scalars, 'Int'>,
    totalCount: $ElementType<Scalars, 'Int'>,
  }
};

export type MessagePreferences = {
   __typename: 'MessagePreferences',
  sendMessageByEnter: $ElementType<Scalars, 'Boolean'>,
};

export type MessagePreferencesInput = {
  sendMessageByEnter?: ?$ElementType<Scalars, 'Boolean'>,
};

export type MessagePreferencesPayload = MessagePreferences | BadRequest | Forbidden | NotFound;

export type MessageSortInput = {
  createdAt?: ?SortOrder,
  updatedAt?: ?SortOrder,
};

export type MessageUpdateInput = {
  content: $ElementType<Scalars, 'String'>,
};

export type MetricRangeInput = {
  min?: ?$ElementType<Scalars, 'Float'>,
  max?: ?$ElementType<Scalars, 'Float'>,
  metric: $ElementType<Scalars, 'String'>,
};

export type MetricValue = {
   __typename: 'MetricValue',
  value: $ElementType<Scalars, 'Float'>,
  metric: $ElementType<Scalars, 'String'>,
};

export type MetricValueInput = {
  value: $ElementType<Scalars, 'Float'>,
  metric: $ElementType<Scalars, 'String'>,
};

export type MetricValueValue = {
   __typename: 'MetricValueValue',
  metricValue: MetricValue,
};

export type Model = {
  id: $ElementType<Scalars, 'ID'>,
  createdAt: $ElementType<Scalars, 'DateTime'>,
  updatedAt: $ElementType<Scalars, 'DateTime'>,
  deletedAt?: ?$ElementType<Scalars, 'DateTime'>,
  createdBy?: ?UserPayload,
  updatedBy?: ?UserPayload,
  deletedBy?: ?UserPayload,
};

export type Mutation = {
   __typename: 'Mutation',
  login: TokenPayload,
  logout: $ElementType<Scalars, 'Boolean'>,
  requestOneTimePassword: TokenPayload,
  verifyOneTimePassword: TokenPayload,
  changePassword?: ?EmptyPayload,
  requestResetPassword?: ?EmptyPayload,
  resetPassword?: ?EmptyPayload,
  refreshToken: TokenPayload,
  verifyToken: TokenPayload,
  userUpdate: UserPayload,
  organizationGroupCreate: OrganizationGroupPayload,
  organizationGroupUpdate: OrganizationGroupPayload,
  organizationGroupDelete?: ?EmptyPayload,
  partnerUpdate: PartnerPayload,
  productCreate: ProductPayload,
  productUpdate: ProductPayload,
  productUpdateMany: Array<?ProductPayload>,
  productDelete?: ?EmptyPayload,
  productProviderPackageUpdate: ProductProviderPackagePayload,
  productProviderUpdate: ProductProviderPayload,
  productProviderDelete?: ?EmptyPayload,
  orderCreate: OrderPayload,
  orderUpdate: OrderPayload,
  orderUpdateMany: Array<?OrderPayload>,
  orderDelete?: ?EmptyPayload,
  orderClone: OrderPayload,
  orderCloneMany: Array<?OrderPayload>,
  orderItemCreate: OrderItemPayload,
  orderItemCreateMany: Array<?OrderItemPayload>,
  orderItemUpdate: OrderItemPayload,
  orderItemUpdateMany: Array<?OrderItemPayload>,
  orderItemDelete?: ?EmptyPayload,
  orderItemClone: OrderItemPayload,
  orderItemCloneMany: Array<?OrderItemPayload>,
  batchCreate: BatchPayload,
  batchUpdate: BatchPayload,
  batchUpdateMany: Array<?BatchPayload>,
  batchDelete?: ?EmptyPayload,
  batchSimpleSplit: BatchesPayload,
  batchEqualSplit: BatchesPayload,
  batchBalanceSplit: BatchesPayload,
  batchBalanceSplitMany: Array<?BatchesPayload>,
  batchClone: BatchPayload,
  batchCloneMany: Array<?BatchPayload>,
  shipmentCreate: ShipmentPayload,
  shipmentUpdate: ShipmentPayload,
  shipmentUpdateMany: Array<?ShipmentPayload>,
  shipmentDelete?: ?EmptyPayload,
  shipmentClone: ShipmentPayload,
  shipmentCloneMany: Array<?ShipmentPayload>,
  containerCreate: ContainerPayload,
  containerUpdate: ContainerPayload,
  containerDelete?: ?EmptyPayload,
  containerClone: ContainerPayload,
  containerCloneMany: Array<?ContainerPayload>,
  warehouseCreate: WarehousePayload,
  warehouseUpdate: WarehousePayload,
  warehouseUpdateMany: Array<?WarehousePayload>,
  warehouseDelete?: ?EmptyPayload,
  entitiesUpdateMany: EntitiesMany,
  tagCreate: TagPayload,
  tagUpdate: TagPayload,
  tagDelete?: ?EmptyPayload,
  tagGroupCreate: TagGroupPayload,
  tagGroupUpdate: TagGroupPayload,
  tagGroupDelete?: ?EmptyPayload,
  fileMarkAsRead?: ?EmptyPayload,
  fileUpload: FilePayload,
  fileUpdate: FilePayload,
  fileDelete?: ?EmptyPayload,
  fileDeleteMany: Array<?EmptyPayload>,
  fieldDefinitionsUpdate: FieldDefinitionsPayload,
  maskCreate: MaskPayload,
  maskUpdate: MaskPayload,
  maskDelete?: ?EmptyPayload,
  maskEditCreate: MaskEditPayload,
  maskEditUpdate: MaskEditPayload,
  maskEditDelete?: ?EmptyPayload,
  maskEditByUser: MaskEditPayload,
  reminderCreate: ReminderPayload,
  reminderUpdate: ReminderPayload,
  reminderDelete?: ?EmptyPayload,
  viewState: ViewStatePayload,
  commentCreate: TimelineCommentPayload,
  commentUpdate: TimelineCommentPayload,
  commentDelete?: ?EmptyPayload,
  timelineRead?: ?EmptyPayload,
  activityLogCreate: ActivityLogPayload,
  notificationActive: $ElementType<Scalars, 'Boolean'>,
  notificationArchive: $ElementType<Scalars, 'Boolean'>,
  notificationArchiveAll: $ElementType<Scalars, 'Boolean'>,
  notificationSeeAll: $ElementType<Scalars, 'Boolean'>,
  notificationSeeByEntities: $ElementType<Scalars, 'Boolean'>,
  notificationPreferencesUpdate: NotificationPreferencesPayload,
  roleCreate: RolePayload,
  roleUpdate: RolePayload,
  roleDelete?: ?EmptyPayload,
  messageEmojiUpdate: MessagePayload,
  messageCreate: MessagePayload,
  messageUpdate: MessagePayload,
  messageDelete?: ?EmptyPayload,
  messageMarkAsRead?: ?EmptyPayload,
  messageGroupCreate: MessageGroupPayload,
  messageGroupMarkAsRead?: ?EmptyPayload,
  messagePreferencesUpdate: MessagePreferencesPayload,
  draftMessage: DraftMessagePayload,
  integrationConfigurationCreate: IntegrationConfigurationPayload,
  integrationConfigurationUpdate: IntegrationConfigurationPayload,
  integrationConfigurationDelete?: ?EmptyPayload,
  import: ImportPayload,
  aitImport?: ?EmptyPayload,
  focus: $ElementType<Scalars, 'Void'>,
  blur: $ElementType<Scalars, 'Void'>,
  entitySubscribe: $ElementType<Scalars, 'Void'>,
  entityUnsubscribe: $ElementType<Scalars, 'Void'>,
  entityUnsubscribeAll: $ElementType<Scalars, 'Void'>,
  focusSubscribe: Array<FocusPayload>,
  focusUnsubscribe: $ElementType<Scalars, 'Void'>,
  focusUnsubscribeAll: $ElementType<Scalars, 'Void'>,
  generateShipmentPurchaseOrder: GenerateShipmentPurchaseOrderResponsePayload,
};


export type MutationLoginArgs = {
  input: CredentialsInput
};


export type MutationRequestOneTimePasswordArgs = {
  type: OneTimePasswordType
};


export type MutationVerifyOneTimePasswordArgs = {
  code: $ElementType<Scalars, 'String'>
};


export type MutationChangePasswordArgs = {
  input: ChangePasswordInput
};


export type MutationRequestResetPasswordArgs = {
  input: RequestResetPasswordInput
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput
};


export type MutationUserUpdateArgs = {
  id: $ElementType<Scalars, 'ID'>,
  input: UserUpdateInput
};


export type MutationOrganizationGroupCreateArgs = {
  input: OrganizationGroupCreateInput
};


export type MutationOrganizationGroupUpdateArgs = {
  id: $ElementType<Scalars, 'ID'>,
  input: OrganizationGroupUpdateInput
};


export type MutationOrganizationGroupDeleteArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type MutationPartnerUpdateArgs = {
  id: $ElementType<Scalars, 'ID'>,
  input: PartnerUpdateInput
};


export type MutationProductCreateArgs = {
  input: ProductCreateInput
};


export type MutationProductUpdateArgs = {
  id: $ElementType<Scalars, 'ID'>,
  input: ProductUpdateInput
};


export type MutationProductUpdateManyArgs = {
  products: Array<ProductUpdateWrapperInput>
};


export type MutationProductDeleteArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type MutationProductProviderPackageUpdateArgs = {
  id: $ElementType<Scalars, 'ID'>,
  input: ProductProviderPackageUpdateInput
};


export type MutationProductProviderUpdateArgs = {
  id: $ElementType<Scalars, 'ID'>,
  input: ProductProviderUpdateInput
};


export type MutationProductProviderDeleteArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type MutationOrderCreateArgs = {
  input: OrderCreateInput
};


export type MutationOrderUpdateArgs = {
  id: $ElementType<Scalars, 'ID'>,
  input: OrderUpdateInput
};


export type MutationOrderUpdateManyArgs = {
  orders: Array<OrderUpdateWrapperInput>
};


export type MutationOrderDeleteArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type MutationOrderCloneArgs = {
  id: $ElementType<Scalars, 'ID'>,
  input: OrderUpdateInput
};


export type MutationOrderCloneManyArgs = {
  orders: Array<OrderUpdateWrapperInput>
};


export type MutationOrderItemCreateArgs = {
  input: OrderItemCreateInput
};


export type MutationOrderItemCreateManyArgs = {
  inputs: Array<OrderItemCreateInput>
};


export type MutationOrderItemUpdateArgs = {
  id: $ElementType<Scalars, 'ID'>,
  input: OrderItemUpdateInput
};


export type MutationOrderItemUpdateManyArgs = {
  orderItems: Array<OrderItemUpdateWrapperInput>
};


export type MutationOrderItemDeleteArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type MutationOrderItemCloneArgs = {
  id: $ElementType<Scalars, 'ID'>,
  input: OrderItemUpdateInput
};


export type MutationOrderItemCloneManyArgs = {
  orderItems: Array<OrderItemUpdateWrapperInput>
};


export type MutationBatchCreateArgs = {
  input: BatchCreateInput
};


export type MutationBatchUpdateArgs = {
  id: $ElementType<Scalars, 'ID'>,
  input: BatchUpdateInput
};


export type MutationBatchUpdateManyArgs = {
  batches: Array<BatchUpdateWrapperInput>
};


export type MutationBatchDeleteArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type MutationBatchSimpleSplitArgs = {
  id: $ElementType<Scalars, 'ID'>,
  input: BatchSimpleSplitInput
};


export type MutationBatchEqualSplitArgs = {
  id: $ElementType<Scalars, 'ID'>,
  input: BatchEqualSplitInput
};


export type MutationBatchBalanceSplitArgs = {
  orderItemId: $ElementType<Scalars, 'ID'>
};


export type MutationBatchBalanceSplitManyArgs = {
  orderItemIds: Array<$ElementType<Scalars, 'ID'>>
};


export type MutationBatchCloneArgs = {
  id: $ElementType<Scalars, 'ID'>,
  input: BatchUpdateInput
};


export type MutationBatchCloneManyArgs = {
  batches: Array<BatchUpdateWrapperInput>
};


export type MutationShipmentCreateArgs = {
  input: ShipmentCreateInput
};


export type MutationShipmentUpdateArgs = {
  id: $ElementType<Scalars, 'ID'>,
  input: ShipmentUpdateInput
};


export type MutationShipmentUpdateManyArgs = {
  shipments: Array<ShipmentUpdateWrapperInput>
};


export type MutationShipmentDeleteArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type MutationShipmentCloneArgs = {
  id: $ElementType<Scalars, 'ID'>,
  input: ShipmentUpdateInput
};


export type MutationShipmentCloneManyArgs = {
  shipments: Array<ShipmentUpdateWrapperInput>
};


export type MutationContainerCreateArgs = {
  input: ContainerCreateInput
};


export type MutationContainerUpdateArgs = {
  id: $ElementType<Scalars, 'ID'>,
  input: ContainerUpdateInput
};


export type MutationContainerDeleteArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type MutationContainerCloneArgs = {
  id: $ElementType<Scalars, 'ID'>,
  input: ContainerUpdateInput
};


export type MutationContainerCloneManyArgs = {
  containers: Array<ContainerUpdateWrapperInput>
};


export type MutationWarehouseCreateArgs = {
  input: WarehouseCreateInput
};


export type MutationWarehouseUpdateArgs = {
  id: $ElementType<Scalars, 'ID'>,
  input: WarehouseUpdateInput
};


export type MutationWarehouseUpdateManyArgs = {
  warehouses: Array<WarehouseUpdateWrapperInput>
};


export type MutationWarehouseDeleteArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type MutationEntitiesUpdateManyArgs = {
  products?: ?Array<ProductUpdateWrapperInput>,
  productProviders?: ?Array<ProductProviderUpdateWrapperInput>,
  productProviderPackages?: ?Array<ProductProviderPackageUpdateWrapperInput>,
  orders?: ?Array<OrderUpdateWrapperInput>,
  orderItems?: ?Array<OrderItemUpdateWrapperInput>,
  batches?: ?Array<BatchUpdateWrapperInput>,
  shipments?: ?Array<ShipmentUpdateWrapperInput>,
  containers?: ?Array<ContainerUpdateWrapperInput>,
  warehouses?: ?Array<WarehouseUpdateWrapperInput>
};


export type MutationTagCreateArgs = {
  input: TagCreateInput
};


export type MutationTagUpdateArgs = {
  id: $ElementType<Scalars, 'ID'>,
  input: TagUpdateInput
};


export type MutationTagDeleteArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type MutationTagGroupCreateArgs = {
  input: TagGroupCreateInput
};


export type MutationTagGroupUpdateArgs = {
  id: $ElementType<Scalars, 'ID'>,
  input: TagGroupUpdateInput
};


export type MutationTagGroupDeleteArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type MutationFileMarkAsReadArgs = {
  entity: EntityInput
};


export type MutationFileUploadArgs = {
  file: $ElementType<Scalars, 'Upload'>,
  input: FileInput
};


export type MutationFileUpdateArgs = {
  id: $ElementType<Scalars, 'ID'>,
  input: FileInput
};


export type MutationFileDeleteArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type MutationFileDeleteManyArgs = {
  ids: Array<$ElementType<Scalars, 'ID'>>
};


export type MutationFieldDefinitionsUpdateArgs = {
  input: FieldDefinitionsInput
};


export type MutationMaskCreateArgs = {
  input: MaskCreateInput
};


export type MutationMaskUpdateArgs = {
  id: $ElementType<Scalars, 'ID'>,
  input: MaskUpdateInput
};


export type MutationMaskDeleteArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type MutationMaskEditCreateArgs = {
  input: MaskEditCreateInput
};


export type MutationMaskEditUpdateArgs = {
  id: $ElementType<Scalars, 'ID'>,
  input: MaskEditUpdateInput
};


export type MutationMaskEditDeleteArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type MutationMaskEditByUserArgs = {
  input: MaskEditByUserInput
};


export type MutationReminderCreateArgs = {
  input: ReminderCreateInput
};


export type MutationReminderUpdateArgs = {
  id: $ElementType<Scalars, 'ID'>,
  input: ReminderUpdateInput
};


export type MutationReminderDeleteArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type MutationViewStateArgs = {
  input: ViewStateUpdateInput
};


export type MutationCommentCreateArgs = {
  input: CommentCreateInput
};


export type MutationCommentUpdateArgs = {
  id: $ElementType<Scalars, 'ID'>,
  input: CommentUpdateInput
};


export type MutationCommentDeleteArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type MutationTimelineReadArgs = {
  entity: EntityInput
};


export type MutationActivityLogCreateArgs = {
  input: ActivityLogCreateInput
};


export type MutationNotificationActiveArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type MutationNotificationArchiveArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type MutationNotificationSeeByEntitiesArgs = {
  entities: Array<EntityInput>
};


export type MutationNotificationPreferencesUpdateArgs = {
  input: NotificationPreferencesInput
};


export type MutationRoleCreateArgs = {
  input: RoleCreateInput
};


export type MutationRoleUpdateArgs = {
  id: $ElementType<Scalars, 'ID'>,
  input: RoleUpdateInput
};


export type MutationRoleDeleteArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type MutationMessageEmojiUpdateArgs = {
  input: MessageEmojiUpdateInput
};


export type MutationMessageCreateArgs = {
  input: MessageCreateInput
};


export type MutationMessageUpdateArgs = {
  id: $ElementType<Scalars, 'ID'>,
  input: MessageUpdateInput
};


export type MutationMessageDeleteArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type MutationMessageMarkAsReadArgs = {
  entity: EntityInput
};


export type MutationMessageGroupCreateArgs = {
  input: MessageGroupCreateInput
};


export type MutationMessageGroupMarkAsReadArgs = {
  messageGroupId: $ElementType<Scalars, 'ID'>
};


export type MutationMessagePreferencesUpdateArgs = {
  input: MessagePreferencesInput
};


export type MutationDraftMessageArgs = {
  input: DraftMessageUpdateInput
};


export type MutationIntegrationConfigurationCreateArgs = {
  input: IntegrationConfigurationCreateInput
};


export type MutationIntegrationConfigurationUpdateArgs = {
  id: $ElementType<Scalars, 'ID'>,
  input: IntegrationConfigurationUpdateInput
};


export type MutationIntegrationConfigurationDeleteArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type MutationImportArgs = {
  file: $ElementType<Scalars, 'Upload'>
};


export type MutationAitImportArgs = {
  file: $ElementType<Scalars, 'Upload'>
};


export type MutationFocusArgs = {
  id: $ElementType<Scalars, 'ID'>,
  input: FocusingInput
};


export type MutationBlurArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type MutationEntitySubscribeArgs = {
  id: $ElementType<Scalars, 'ID'>,
  input: SubscriptionInput
};


export type MutationEntityUnsubscribeArgs = {
  id: $ElementType<Scalars, 'ID'>,
  input: SubscriptionInput
};


export type MutationEntityUnsubscribeAllArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type MutationFocusSubscribeArgs = {
  id: $ElementType<Scalars, 'ID'>,
  input: SubscriptionInput
};


export type MutationFocusUnsubscribeArgs = {
  id: $ElementType<Scalars, 'ID'>,
  input: SubscriptionInput
};


export type MutationFocusUnsubscribeAllArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type MutationGenerateShipmentPurchaseOrderArgs = {
  input: GenerateShipmentPurchaseOrderInput
};

export type NewPrice = {
   __typename: 'NewPrice',
  amount: $ElementType<Scalars, 'Float'>,
  currency: Currency,
};

export type NewPricePayload = NewPrice | BadRequest | Forbidden | NotFound;

export type NotFound = {
   __typename: 'NotFound',
  reference?: ?Reference,
};

export type Notification = {
   __typename: 'Notification',
  id: $ElementType<Scalars, 'ID'>,
  contractId: $ElementType<Scalars, 'ID'>,
  sender: UserPayload,
  receiver: UserPayload,
  type: NotificationType,
  body: $ElementType<Scalars, 'String'>,
  entity: EntityPayload,
  relatedReferences: Array<Reference>,
  reminder?: ?ReminderPayload,
  archived: $ElementType<Scalars, 'Boolean'>,
  seen: $ElementType<Scalars, 'Boolean'>,
  createdAt: $ElementType<Scalars, 'DateTime'>,
};

export type NotificationFilterInput = {
  query?: ?$ElementType<Scalars, 'String'>,
  ownerId?: ?$ElementType<Scalars, 'ID'>,
  excludeIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  createdAt?: ?DateRangeInput,
  updatedAt?: ?DateRangeInput,
  archived?: ?$ElementType<Scalars, 'Boolean'>,
  seen?: ?$ElementType<Scalars, 'Boolean'>,
  ids?: ?Array<$ElementType<Scalars, 'ID'>>,
  senderId?: ?$ElementType<Scalars, 'ID'>,
  receiverId?: ?$ElementType<Scalars, 'ID'>,
  entityId?: ?$ElementType<Scalars, 'ID'>,
  entityType?: ?$ElementType<Scalars, 'String'>,
  /** deprecated */
  organizationIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  /** deprecated */
  userIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  /** maybe deprecated */
  types?: ?Array<NotificationType>,
};

export type NotificationPayload = Notification | BadRequest | Forbidden | NotFound;

export type NotificationPayloadPaginatedSearch = {
  ...Paginated,
  ...{
     __typename: 'NotificationPayloadPaginatedSearch',
    nodes: Array<NotificationPayload>,
    hits: Array<Hit>,
    page: $ElementType<Scalars, 'Int'>,
    perPage: $ElementType<Scalars, 'Int'>,
    totalPage: $ElementType<Scalars, 'Int'>,
    count: $ElementType<Scalars, 'Int'>,
    totalCount: $ElementType<Scalars, 'Int'>,
  }
};

export type NotificationPreference = {
   __typename: 'NotificationPreference',
  type: NotificationType,
  enabled: $ElementType<Scalars, 'Boolean'>,
};

export type NotificationPreferenceInput = {
  type: NotificationType,
  enabled: $ElementType<Scalars, 'Boolean'>,
};

export type NotificationPreferences = {
   __typename: 'NotificationPreferences',
  allowedEmail: $ElementType<Scalars, 'Boolean'>,
  emailInterval?: ?Interval,
  notifications: Array<NotificationPreference>,
};

export type NotificationPreferencesInput = {
  allowedEmail?: ?$ElementType<Scalars, 'Boolean'>,
  emailInterval?: ?IntervalInput,
  notifications?: ?Array<NotificationPreferenceInput>,
};

export type NotificationPreferencesPayload = NotificationPreferences | BadRequest | Forbidden | NotFound;

export type NotificationSortInput = {
  createdAt?: ?SortOrder,
  updatedAt?: ?SortOrder,
};

export const NotificationTypeValues = Object.freeze({
  /** shipment_create */
  ShipmentCreate: 'shipment_create', 
  /** shipment_update */
  ShipmentUpdate: 'shipment_update', 
  /** shipment_archived */
  ShipmentArchived: 'shipment_archived', 
  /** shipment_unarchived */
  ShipmentUnarchived: 'shipment_unarchived', 
  /** shipment_update_timeline_date */
  ShipmentUpdateTimelineDate: 'shipment_update_timeline_date', 
  /** shipment_update_timeline_summary_date */
  ShipmentUpdateTimelineSummaryDate: 'shipment_update_timeline_summary_date', 
  /** shipment_update_voyage_info */
  ShipmentUpdateVoyageInfo: 'shipment_update_voyage_info', 
  /** shipment_update_memo */
  ShipmentUpdateMemo: 'shipment_update_memo', 
  /** shipment_add_batch */
  ShipmentAddBatch: 'shipment_add_batch', 
  /** shipment_remove_batch */
  ShipmentRemoveBatch: 'shipment_remove_batch', 
  /** shipment_approved_date */
  ShipmentApprovedDate: 'shipment_approved_date', 
  /** shipment_add_partner_warehouse */
  ShipmentAddPartnerWarehouse: 'shipment_add_partner_warehouse', 
  /** shipment_add_forwarder */
  ShipmentAddForwarder: 'shipment_add_forwarder', 
  /** shipment_update_booking_no */
  ShipmentUpdateBookingNo: 'shipment_update_booking_no', 
  /** shipment_unapproved_date */
  ShipmentUnapprovedDate: 'shipment_unapproved_date', 
  /** shipment_update_tag */
  ShipmentUpdateTag: 'shipment_update_tag', 
  /** shipment_set_date */
  ShipmentSetDate: 'shipment_set_date', 
  /** shipment_update_date */
  ShipmentUpdateDate: 'shipment_update_date', 
  /** shipment_set_date_revision */
  ShipmentSetDateRevision: 'shipment_set_date_revision', 
  /** shipment_update_date_revision */
  ShipmentUpdateDateRevision: 'shipment_update_date_revision', 
  /** product_create */
  ProductCreate: 'product_create', 
  /** product_update */
  ProductUpdate: 'product_update', 
  /** product_archived */
  ProductArchived: 'product_archived', 
  /** product_unarchived */
  ProductUnarchived: 'product_unarchived', 
  /** product_create_provider */
  ProductCreateProvider: 'product_create_provider', 
  /** order_create */
  OrderCreate: 'order_create', 
  /** order_archived */
  OrderArchived: 'order_archived', 
  /** order_create_item */
  OrderCreateItem: 'order_create_item', 
  /** order_update_item_quantity */
  OrderUpdateItemQuantity: 'order_update_item_quantity', 
  /** order_update_item_price */
  OrderUpdateItemPrice: 'order_update_item_price', 
  /** order_update_item_delivery_date */
  OrderUpdateItemDeliveryDate: 'order_update_item_delivery_date', 
  /** order_update_tag */
  OrderUpdateTag: 'order_update_tag', 
  /** batch_create */
  BatchCreate: 'batch_create', 
  /** batch_update_quantity */
  BatchUpdateQuantity: 'batch_update_quantity', 
  /** batch_update_produced_quantity */
  BatchUpdateProducedQuantity: 'batch_update_produced_quantity', 
  /** batch_update_preshipped_quantity */
  BatchUpdatePreshippedQuantity: 'batch_update_preshipped_quantity', 
  /** batch_update_shipped_quantity */
  BatchUpdateShippedQuantity: 'batch_update_shipped_quantity', 
  /** batch_update_postshipped_quantity */
  BatchUpdatePostshippedQuantity: 'batch_update_postshipped_quantity', 
  /** batch_update_delivered_quantity */
  BatchUpdateDeliveredQuantity: 'batch_update_delivered_quantity', 
  /** batch_update_delivery_date */
  BatchUpdateDeliveryDate: 'batch_update_delivery_date', 
  /** batch_update_desired_date */
  BatchUpdateDesiredDate: 'batch_update_desired_date', 
  /** warehouse_create */
  WarehouseCreate: 'warehouse_create', 
  /** warehouse_update */
  WarehouseUpdate: 'warehouse_update', 
  /** warehouse_archived */
  WarehouseArchived: 'warehouse_archived', 
  /** warehouse_unarchived */
  WarehouseUnarchived: 'warehouse_unarchived', 
  /** comment_create */
  CommentCreate: 'comment_create', 
  /** container_create */
  ContainerCreate: 'container_create', 
  /** container_update_arrival_date */
  ContainerUpdateArrivalDate: 'container_update_arrival_date', 
  /** container_approve_arrival_date */
  ContainerApproveArrivalDate: 'container_approve_arrival_date', 
  /** container_update_container_type */
  ContainerUpdateContainerType: 'container_update_container_type', 
  /** container_update_agreed_date */
  ContainerUpdateAgreedDate: 'container_update_agreed_date', 
  /** container_approve_agreed_date */
  ContainerApproveAgreedDate: 'container_approve_agreed_date', 
  /** container_update_actual_date */
  ContainerUpdateActualDate: 'container_update_actual_date', 
  /** container_approve_actual_date */
  ContainerApproveActualDate: 'container_approve_actual_date', 
  /** file_uploaded_to_entity */
  FileUploadedToEntity: 'file_uploaded_to_entity', 
  /** file_uploaded_to_message_group */
  FileUploadedToMessageGroup: 'file_uploaded_to_message_group', 
  /** reminder_trigger */
  ReminderTrigger: 'reminder_trigger', 
  /** field_value_update */
  FieldValueUpdate: 'field_value_update', 
  /** file_import_succeeded */
  FileImportSucceeded: 'file_import_succeeded', 
  /** file_import_failed */
  FileImportFailed: 'file_import_failed'
});


export type NotificationType = $Values<typeof NotificationTypeValues>;

export const OneTimePasswordTypeValues = Object.freeze({
  Email: 'Email'
});


export type OneTimePasswordType = $Values<typeof OneTimePasswordTypeValues>;

export type Order = {
  ...Model,
  ...Connected,
  ...Owned,
  ...Tagged,
  ...Followed,
  ...Supervised,
  ...Documented,
  ...MessageGrouped,
  ...Customizable,
  ...Memorizable,
  ...{
     __typename: 'Order',
    archived: $ElementType<Scalars, 'Boolean'>,
    importer: OrganizationPayload,
    exporter: OrganizationPayload,
    poNo: $ElementType<Scalars, 'String'>,
    folder: $ElementType<Scalars, 'String'>,
    currency: Currency,
    issuedAt?: ?$ElementType<Scalars, 'DateTime'>,
    piNo?: ?$ElementType<Scalars, 'String'>,
    incoterm?: ?Incoterm,
    deliveryPlace?: ?$ElementType<Scalars, 'String'>,
    deliveryDate?: ?$ElementType<Scalars, 'DateTime'>,
    timeline: Timeline,
    fileCount: OrderFileCount,
    totalPrice: Price,
    newTotalPrice: NewPricePayload,
    totalVolume: MetricValue,
    totalOrdered: $ElementType<Scalars, 'Float'>,
    totalBatched: $ElementType<Scalars, 'Float'>,
    totalShipped: $ElementType<Scalars, 'Float'>,
    orderItemCount: $ElementType<Scalars, 'Int'>,
    orderItemChildlessCount: $ElementType<Scalars, 'Int'>,
    /** sync to Order item unit if all Order items are unified */
  calculatedOrderItemUnit: $ElementType<Scalars, 'String'>,
    batchCount: $ElementType<Scalars, 'Int'>,
    batchShippedCount: $ElementType<Scalars, 'Int'>,
    shipmentCount: $ElementType<Scalars, 'Int'>,
    containerCount: $ElementType<Scalars, 'Int'>,
    orderItems: Array<OrderItemPayload>,
    shipments: Array<ShipmentPayload>,
    containers: Array<ContainerPayload>,
    organizations: Array<OrganizationPayload>,
    id: $ElementType<Scalars, 'ID'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    deletedAt?: ?$ElementType<Scalars, 'DateTime'>,
    createdBy?: ?UserPayload,
    updatedBy?: ?UserPayload,
    deletedBy?: ?UserPayload,
    connectionBy?: ?ConnectionPayload,
    ownedBy: OrganizationPayload,
    tags: Array<TagPayload>,
    followers: Array<UserPayload>,
    notificationUnseen: Array<NotificationPayload>,
    notificationUnseenCount: $ElementType<Scalars, 'Int'>,
    inCharges: Array<UserPayload>,
    hasFile: $ElementType<Scalars, 'Boolean'>,
    filesUnreadCount: $ElementType<Scalars, 'Int'>,
    files: Array<FilePayload>,
    hasMessage: $ElementType<Scalars, 'Boolean'>,
    messageGroups: Array<MessageGroupPayload>,
    customFields: CustomFields,
    memo?: ?$ElementType<Scalars, 'String'>,
  }
};

export type OrderBulkFilterInput = {
  poNos?: ?ValuesWithMatchModeInput,
};

export type OrderCreateInput = {
  ownerId: $ElementType<Scalars, 'ID'>,
  importerId?: ?$ElementType<Scalars, 'ID'>,
  connectionId: $ElementType<Scalars, 'ID'>,
  exporterId: $ElementType<Scalars, 'ID'>,
  poNo: $ElementType<Scalars, 'String'>,
  issuedAt?: ?$ElementType<Scalars, 'DateTime'>,
  piNo?: ?$ElementType<Scalars, 'String'>,
  currency: Currency,
  incoterm?: ?Incoterm,
  deliveryPlace?: ?$ElementType<Scalars, 'String'>,
  deliveryDate?: ?$ElementType<Scalars, 'DateTime'>,
  memo?: ?$ElementType<Scalars, 'String'>,
  /** Deprecated */
  tagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  tags?: ?Array<EntityTagInput>,
  followerIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  orderItems?: ?Array<OrderOrderItemInput>,
  files?: ?Array<EntityFileInput>,
  customFields?: ?CustomFieldsInput,
  organizationIds?: ?Array<$ElementType<Scalars, 'ID'>>,
};

export type OrderFileCount = {
   __typename: 'OrderFileCount',
  count: $ElementType<Scalars, 'Int'>,
  po: $ElementType<Scalars, 'Int'>,
  pi: $ElementType<Scalars, 'Int'>,
  miscellaneous: $ElementType<Scalars, 'Int'>,
};

export type OrderFilterInput = {
  query?: ?$ElementType<Scalars, 'String'>,
  ownerId?: ?$ElementType<Scalars, 'ID'>,
  excludeIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  createdAt?: ?DateRangeInput,
  updatedAt?: ?DateRangeInput,
  customFields?: ?Array<EntityCustomFieldInput>,
  connectionIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  organizationIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  bulkFilter?: ?OrderBulkFilterInput,
  keywords?: ?ValuesWithMatchModeInput,
  archived?: ?$ElementType<Scalars, 'Boolean'>,
  ids?: ?Array<$ElementType<Scalars, 'ID'>>,
  poNos?: ?Array<$ElementType<Scalars, 'String'>>,
  followerIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  importerIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  exporterIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  /** Deprecated */
  supplierIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  /** Deprecated */
  tagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  tagIdsWithOperator?: ?IdsWithOperatorInput,
  notTagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  completelyBatched?: ?$ElementType<Scalars, 'Boolean'>,
  completelyShipped?: ?$ElementType<Scalars, 'Boolean'>,
  deliveryDate?: ?DateRangeInput,
  issuedAt?: ?DateRangeInput,
  orderItemPrice?: ?PriceRangeInput,
  /** Deprecated */
  batchTagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  batchTagIdsWithOperator?: ?IdsWithOperatorInput,
  batchDeliveredAt?: ?DateRangeInput,
  batchDesiredAt?: ?DateRangeInput,
  batchExpiredAt?: ?DateRangeInput,
  batchProducedAt?: ?DateRangeInput,
  batchTotalVolume?: ?MetricRangeInput,
  productId?: ?$ElementType<Scalars, 'ID'>,
  productIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  /** Deprecated */
  productTagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  productTagIdsWithOperator?: ?IdsWithOperatorInput,
  productProviderIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  productProviderSupplierIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  shipmentForwarderIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  shipmentFollowerIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  /** Deprecated */
  shipmentTagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  shipmentTagIdsWithOperator?: ?IdsWithOperatorInput,
  shipmentLoadPorts?: ?Array<PortInput>,
  shipmentFirstTransitPorts?: ?Array<PortInput>,
  shipmentSecondTransitPorts?: ?Array<PortInput>,
  shipmentDischargePorts?: ?Array<PortInput>,
  shipmentCargoReady?: ?DateRangeInput,
  shipmentLoadPortDeparture?: ?DateRangeInput,
  shipmentFirstTransitPortArrival?: ?DateRangeInput,
  shipmentFirstTransitPortDeparture?: ?DateRangeInput,
  shipmentSecondTransitPortArrival?: ?DateRangeInput,
  shipmentSecondTransitPortDeparture?: ?DateRangeInput,
  shipmentDischargePortArrival?: ?DateRangeInput,
  shipmentWarehouseIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  shipmentCustomClearance?: ?DateRangeInput,
  shipmentWarehouseArrival?: ?DateRangeInput,
  shipmentDeliveryReady?: ?DateRangeInput,
  shipmentCreatedAt?: ?DateRangeInput,
  shipmentUpdatedAt?: ?DateRangeInput,
  shipmentArchived?: ?$ElementType<Scalars, 'Boolean'>,
  containerContainerType?: ?$ElementType<Scalars, 'String'>,
  containerContainerOption?: ?ContainerOption,
  containerWarehouseIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  /** Deprecated */
  containerTagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  containerTagIdsWithOperator?: ?IdsWithOperatorInput,
  containerCreatedAt?: ?DateRangeInput,
  containerUpdatedAt?: ?DateRangeInput,
  containerWarehouseArrivalAgreedDate?: ?DateRangeInput,
  containerWarehouseArrivalActualDate?: ?DateRangeInput,
  containerFreeTimeDueDate?: ?DateRangeInput,
  containerFreeTimeOverdue?: ?$ElementType<Scalars, 'Boolean'>,
};

export type OrderItem = {
  ...Model,
  ...Connected,
  ...Owned,
  ...Sortable,
  ...Tagged,
  ...Documented,
  ...MessageGrouped,
  ...Customizable,
  ...Memorizable,
  ...{
     __typename: 'OrderItem',
    order: OrderPayload,
    productProvider: ProductProviderPayload,
    archived: $ElementType<Scalars, 'Boolean'>,
    no: $ElementType<Scalars, 'String'>,
    price: Price,
    newPrice: NewPricePayload,
    quantity: $ElementType<Scalars, 'Float'>,
    /** quantity - SUM(b.latestQuantity) */
  unbatchedQuantity: $ElementType<Scalars, 'Float'>,
    /** quantity - SUM(b.shippedQuantity) */
  unshippedQuantity: $ElementType<Scalars, 'Float'>,
    priceUnit: $ElementType<Scalars, 'String'>,
    calculatedUnit: $ElementType<Scalars, 'String'>,
    deliveryDate?: ?$ElementType<Scalars, 'DateTime'>,
    totalPrice: Price,
    newTotalPrice: NewPricePayload,
    totalBatched: $ElementType<Scalars, 'Float'>,
    totalShipped: $ElementType<Scalars, 'Float'>,
    batchCount: $ElementType<Scalars, 'Int'>,
    batchShippedCount: $ElementType<Scalars, 'Int'>,
    shipmentCount: $ElementType<Scalars, 'Int'>,
    batches: Array<BatchPayload>,
    shipments: Array<ShipmentPayload>,
    timeline: Timeline,
    fileCount: GenericFileCount,
    id: $ElementType<Scalars, 'ID'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    deletedAt?: ?$ElementType<Scalars, 'DateTime'>,
    createdBy?: ?UserPayload,
    updatedBy?: ?UserPayload,
    deletedBy?: ?UserPayload,
    connectionBy?: ?ConnectionPayload,
    ownedBy: OrganizationPayload,
    sort: $ElementType<Scalars, 'Int'>,
    tags: Array<TagPayload>,
    hasFile: $ElementType<Scalars, 'Boolean'>,
    filesUnreadCount: $ElementType<Scalars, 'Int'>,
    files: Array<FilePayload>,
    hasMessage: $ElementType<Scalars, 'Boolean'>,
    messageGroups: Array<MessageGroupPayload>,
    customFields: CustomFields,
    memo?: ?$ElementType<Scalars, 'String'>,
  }
};

export type OrderItemBatchInput = {
  no?: ?$ElementType<Scalars, 'String'>,
  quantity?: ?$ElementType<Scalars, 'Float'>,
  producedQuantity?: ?$ElementType<Scalars, 'Float'>,
  preShippedQuantity?: ?$ElementType<Scalars, 'Float'>,
  shippedQuantity?: ?$ElementType<Scalars, 'Float'>,
  postShippedQuantity?: ?$ElementType<Scalars, 'Float'>,
  deliveredQuantity?: ?$ElementType<Scalars, 'Float'>,
  quantityUnit?: ?$ElementType<Scalars, 'String'>,
  producedAt?: ?$ElementType<Scalars, 'DateTime'>,
  deliveredAt?: ?$ElementType<Scalars, 'DateTime'>,
  expiredAt?: ?$ElementType<Scalars, 'DateTime'>,
  desiredAt?: ?$ElementType<Scalars, 'DateTime'>,
  packageQuantity?: ?$ElementType<Scalars, 'Float'>,
  autoCalculatePackageQuantity?: ?$ElementType<Scalars, 'Boolean'>,
  packageName?: ?$ElementType<Scalars, 'String'>,
  packageGrossWeight?: ?MetricValueInput,
  packageVolume?: ?MetricValueInput,
  autoCalculatePackageVolume?: ?$ElementType<Scalars, 'Boolean'>,
  packageSize?: ?SizeInput,
  packageCapacity?: ?$ElementType<Scalars, 'Float'>,
  packageCapacityUnit?: ?$ElementType<Scalars, 'String'>,
  memo?: ?$ElementType<Scalars, 'String'>,
  totalPriceOverride?: ?PriceInput,
  totalPriceOverriding?: ?$ElementType<Scalars, 'Boolean'>,
  totalVolumeOverride?: ?MetricValueInput,
  totalVolumeOverriding?: ?$ElementType<Scalars, 'Boolean'>,
  /** Deprecated */
  tagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  tags?: ?Array<EntityTagInput>,
  followerIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  customFields?: ?CustomFieldsInput,
  id?: ?$ElementType<Scalars, 'ID'>,
  shipmentId?: ?$ElementType<Scalars, 'ID'>,
  containerId?: ?$ElementType<Scalars, 'ID'>,
};

export type OrderItemCreateInput = {
  productProviderId: $ElementType<Scalars, 'ID'>,
  no: $ElementType<Scalars, 'String'>,
  price: PriceInput,
  quantity: $ElementType<Scalars, 'Float'>,
  priceUnit?: ?$ElementType<Scalars, 'String'>,
  deliveryDate?: ?$ElementType<Scalars, 'DateTime'>,
  memo?: ?$ElementType<Scalars, 'String'>,
  /** Deprecated */
  tagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  tags?: ?Array<EntityTagInput>,
  batches?: ?Array<OrderItemBatchInput>,
  files?: ?Array<EntityFileInput>,
  followerIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  customFields?: ?CustomFieldsInput,
  orderId: $ElementType<Scalars, 'ID'>,
};

export type OrderItemFilterInput = {
  query?: ?$ElementType<Scalars, 'String'>,
  ownerId?: ?$ElementType<Scalars, 'ID'>,
  excludeIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  createdAt?: ?DateRangeInput,
  updatedAt?: ?DateRangeInput,
  customFields?: ?Array<EntityCustomFieldInput>,
  connectionIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  archived?: ?$ElementType<Scalars, 'Boolean'>,
  orderId?: ?$ElementType<Scalars, 'ID'>,
  productId?: ?$ElementType<Scalars, 'ID'>,
  productProviderId?: ?$ElementType<Scalars, 'ID'>,
  exporterId?: ?$ElementType<Scalars, 'ID'>,
  supplierId?: ?$ElementType<Scalars, 'ID'>,
  importerId?: ?$ElementType<Scalars, 'ID'>,
};

export type OrderItemPayload = OrderItem | BadRequest | Forbidden | NotFound;

export type OrderItemPayloadPaginatedSearch = {
  ...Paginated,
  ...{
     __typename: 'OrderItemPayloadPaginatedSearch',
    nodes: Array<OrderItemPayload>,
    hits: Array<Hit>,
    page: $ElementType<Scalars, 'Int'>,
    perPage: $ElementType<Scalars, 'Int'>,
    totalPage: $ElementType<Scalars, 'Int'>,
    count: $ElementType<Scalars, 'Int'>,
    totalCount: $ElementType<Scalars, 'Int'>,
  }
};

export type OrderItemSortInput = {
  createdAt?: ?SortOrder,
  updatedAt?: ?SortOrder,
  no?: ?SortOrder,
  currency?: ?SortOrder,
  productName?: ?SortOrder,
  productSerial?: ?SortOrder,
  productProviderName?: ?SortOrder,
  supplierName?: ?SortOrder,
};

export type OrderItemUpdateInput = {
  productProviderId?: ?$ElementType<Scalars, 'ID'>,
  no?: ?$ElementType<Scalars, 'String'>,
  price?: ?PriceInput,
  quantity?: ?$ElementType<Scalars, 'Float'>,
  priceUnit?: ?$ElementType<Scalars, 'String'>,
  deliveryDate?: ?$ElementType<Scalars, 'DateTime'>,
  memo?: ?$ElementType<Scalars, 'String'>,
  /** Deprecated */
  tagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  tags?: ?Array<EntityTagInput>,
  batches?: ?Array<OrderItemBatchInput>,
  files?: ?Array<EntityFileInput>,
  followerIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  customFields?: ?CustomFieldsInput,
  orderId?: ?$ElementType<Scalars, 'ID'>,
};

export type OrderItemUpdateWrapperInput = {
  id: $ElementType<Scalars, 'ID'>,
  input: OrderItemUpdateInput,
};

export type OrderOrderItemInput = {
  productProviderId?: ?$ElementType<Scalars, 'ID'>,
  no?: ?$ElementType<Scalars, 'String'>,
  price?: ?PriceInput,
  quantity?: ?$ElementType<Scalars, 'Float'>,
  priceUnit?: ?$ElementType<Scalars, 'String'>,
  deliveryDate?: ?$ElementType<Scalars, 'DateTime'>,
  memo?: ?$ElementType<Scalars, 'String'>,
  /** Deprecated */
  tagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  tags?: ?Array<EntityTagInput>,
  batches?: ?Array<OrderItemBatchInput>,
  files?: ?Array<EntityFileInput>,
  followerIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  customFields?: ?CustomFieldsInput,
  id?: ?$ElementType<Scalars, 'ID'>,
};

export type OrderPayload = Order | BadRequest | Forbidden | NotFound;

export type OrderPayloadPaginatedSearch = {
  ...Paginated,
  ...{
     __typename: 'OrderPayloadPaginatedSearch',
    nodes: Array<OrderPayload>,
    hits: Array<Hit>,
    page: $ElementType<Scalars, 'Int'>,
    perPage: $ElementType<Scalars, 'Int'>,
    totalPage: $ElementType<Scalars, 'Int'>,
    count: $ElementType<Scalars, 'Int'>,
    totalCount: $ElementType<Scalars, 'Int'>,
  }
};

export type OrderSortInput = {
  createdAt?: ?SortOrder,
  updatedAt?: ?SortOrder,
  poNo?: ?SortOrder,
  piNo?: ?SortOrder,
  issuedAt?: ?SortOrder,
  exporterName?: ?SortOrder,
  importerName?: ?SortOrder,
  currency?: ?SortOrder,
  incoterm?: ?SortOrder,
  deliveryPlace?: ?SortOrder,
  deliveryDate?: ?SortOrder,
  shipmentCargoReadyInitialDate?: ?SortOrder,
  shipmentCargoReadyResultDate?: ?SortOrder,
  shipmentLoadPortDepartureInitialDate?: ?SortOrder,
  shipmentDischargePortArrivalInitialDate?: ?SortOrder,
};

export type OrderUpdateInput = {
  archived?: ?$ElementType<Scalars, 'Boolean'>,
  importerId?: ?$ElementType<Scalars, 'ID'>,
  exporterId?: ?$ElementType<Scalars, 'ID'>,
  poNo?: ?$ElementType<Scalars, 'String'>,
  issuedAt?: ?$ElementType<Scalars, 'DateTime'>,
  piNo?: ?$ElementType<Scalars, 'String'>,
  currency?: ?Currency,
  incoterm?: ?Incoterm,
  deliveryPlace?: ?$ElementType<Scalars, 'String'>,
  deliveryDate?: ?$ElementType<Scalars, 'DateTime'>,
  memo?: ?$ElementType<Scalars, 'String'>,
  /** Deprecated */
  tagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  tags?: ?Array<EntityTagInput>,
  followerIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  orderItems?: ?Array<OrderOrderItemInput>,
  files?: ?Array<EntityFileInput>,
  customFields?: ?CustomFieldsInput,
  organizationIds?: ?Array<$ElementType<Scalars, 'ID'>>,
};

export type OrderUpdateWrapperInput = {
  id: $ElementType<Scalars, 'ID'>,
  input: OrderUpdateInput,
};

export type Organization = {
  ...Model,
  ...{
     __typename: 'Organization',
    types: Array<OrganizationType>,
    name: $ElementType<Scalars, 'String'>,
    key?: ?$ElementType<Scalars, 'String'>,
    name2?: ?$ElementType<Scalars, 'String'>,
    connections: Array<ConnectionPayload>,
    internalPartnerGroups: Array<InternalPartnerGroupPayload>,
    tel?: ?$ElementType<Scalars, 'String'>,
    street?: ?$ElementType<Scalars, 'String'>,
    locality?: ?$ElementType<Scalars, 'String'>,
    region?: ?$ElementType<Scalars, 'String'>,
    postalCode?: ?$ElementType<Scalars, 'String'>,
    country?: ?$ElementType<Scalars, 'String'>,
    paid: $ElementType<Scalars, 'Boolean'>,
    disabled: $ElementType<Scalars, 'Boolean'>,
    dummy: $ElementType<Scalars, 'Boolean'>,
    forceMFA: $ElementType<Scalars, 'Boolean'>,
    users: UserPayloadPaginatedSearch,
    teams: TeamPayloadPaginatedList,
    /** Resolving the current partnership of this organization from user's organization */
  partner?: ?PartnerPayload,
    relationPartners: OrganizationPayloadPaginatedList,
    partners: PartnerPayloadPaginatedSearch,
    id: $ElementType<Scalars, 'ID'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    deletedAt?: ?$ElementType<Scalars, 'DateTime'>,
    createdBy?: ?UserPayload,
    updatedBy?: ?UserPayload,
    deletedBy?: ?UserPayload,
  }
};


export type OrganizationUsersArgs = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?UserFilterInput,
  sortBy?: ?UserSortInput
};


export type OrganizationTeamsArgs = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?TeamFilterInput
};


export type OrganizationRelationPartnersArgs = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?OrganizationFilterInput
};


export type OrganizationPartnersArgs = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?PartnerFilterInput,
  sortBy?: ?PartnerSortInput
};

export type OrganizationFilterInput = {
  query?: ?$ElementType<Scalars, 'String'>,
  ownerId?: ?$ElementType<Scalars, 'ID'>,
  excludeIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  createdAt?: ?DateRangeInput,
  updatedAt?: ?DateRangeInput,
  connectionIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  types?: ?Array<OrganizationType>,
  paid?: ?$ElementType<Scalars, 'Boolean'>,
  name?: ?$ElementType<Scalars, 'String'>,
};

export type OrganizationGroup = {
  ...Model,
  ...Owned,
  ...{
     __typename: 'OrganizationGroup',
    name: $ElementType<Scalars, 'String'>,
    description?: ?$ElementType<Scalars, 'String'>,
    organizations: Array<OrganizationPayload>,
    id: $ElementType<Scalars, 'ID'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    deletedAt?: ?$ElementType<Scalars, 'DateTime'>,
    createdBy?: ?UserPayload,
    updatedBy?: ?UserPayload,
    deletedBy?: ?UserPayload,
    ownedBy: OrganizationPayload,
  }
};

export type OrganizationGroupCreateInput = {
  name: $ElementType<Scalars, 'String'>,
  description?: ?$ElementType<Scalars, 'String'>,
  organizationIds?: ?Array<$ElementType<Scalars, 'ID'>>,
};

export type OrganizationGroupFilterInput = {
  query?: ?$ElementType<Scalars, 'String'>,
  ownerId?: ?$ElementType<Scalars, 'ID'>,
  excludeIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  createdAt?: ?DateRangeInput,
  updatedAt?: ?DateRangeInput,
};

export type OrganizationGroupPayload = OrganizationGroup | BadRequest | Forbidden | NotFound;

export type OrganizationGroupPayloadPaginatedSearch = {
  ...Paginated,
  ...{
     __typename: 'OrganizationGroupPayloadPaginatedSearch',
    nodes: Array<OrganizationGroupPayload>,
    hits: Array<Hit>,
    page: $ElementType<Scalars, 'Int'>,
    perPage: $ElementType<Scalars, 'Int'>,
    totalPage: $ElementType<Scalars, 'Int'>,
    count: $ElementType<Scalars, 'Int'>,
    totalCount: $ElementType<Scalars, 'Int'>,
  }
};

export type OrganizationGroupSortInput = {
  createdAt?: ?SortOrder,
  updatedAt?: ?SortOrder,
  name?: ?SortOrder,
};

export type OrganizationGroupUpdateInput = {
  name?: ?$ElementType<Scalars, 'String'>,
  description?: ?$ElementType<Scalars, 'String'>,
  organizationIds?: ?Array<$ElementType<Scalars, 'ID'>>,
};

export type OrganizationPayload = Organization | BadRequest | Forbidden | NotFound;

export type OrganizationPayloadPaginatedList = {
  ...Paginated,
  ...{
     __typename: 'OrganizationPayloadPaginatedList',
    nodes: Array<OrganizationPayload>,
    page: $ElementType<Scalars, 'Int'>,
    perPage: $ElementType<Scalars, 'Int'>,
    totalPage: $ElementType<Scalars, 'Int'>,
    count: $ElementType<Scalars, 'Int'>,
    totalCount: $ElementType<Scalars, 'Int'>,
  }
};

export const OrganizationTypeValues = Object.freeze({
  /** Importer */
  Importer: 'Importer', 
  /** Exporter */
  Exporter: 'Exporter', 
  /** Forwarder */
  Forwarder: 'Forwarder', 
  /** Supplier */
  Supplier: 'Supplier', 
  /** Warehouser */
  Warehouser: 'Warehouser'
});


export type OrganizationType = $Values<typeof OrganizationTypeValues>;

export type Owned = {
  ownedBy: OrganizationPayload,
};

export type Package = {
  packageName?: ?$ElementType<Scalars, 'String'>,
  packageGrossWeight?: ?MetricValue,
  packageVolume?: ?MetricValue,
  autoCalculatePackageVolume: $ElementType<Scalars, 'Boolean'>,
  packageSize?: ?Size,
  packageCapacity?: ?$ElementType<Scalars, 'Float'>,
  packageCapacityUnit?: ?$ElementType<Scalars, 'String'>,
};

export type Paginated = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  totalPage: $ElementType<Scalars, 'Int'>,
  count: $ElementType<Scalars, 'Int'>,
  totalCount: $ElementType<Scalars, 'Int'>,
};

export type Parameter = {
   __typename: 'Parameter',
  key: $ElementType<Scalars, 'String'>,
  value?: ?Value,
};

export type Partner = {
  ...Model,
  ...Tagged,
  ...{
     __typename: 'Partner',
    organization: OrganizationPayload,
    types: Array<OrganizationType>,
    name?: ?$ElementType<Scalars, 'String'>,
    code?: ?$ElementType<Scalars, 'String'>,
    confirmed: $ElementType<Scalars, 'Boolean'>,
    approvedAt?: ?$ElementType<Scalars, 'DateTime'>,
    approvedByPartnerAt?: ?$ElementType<Scalars, 'DateTime'>,
    id: $ElementType<Scalars, 'ID'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    deletedAt?: ?$ElementType<Scalars, 'DateTime'>,
    createdBy?: ?UserPayload,
    updatedBy?: ?UserPayload,
    deletedBy?: ?UserPayload,
    tags: Array<TagPayload>,
  }
};

export type PartnerFilterInput = {
  query?: ?$ElementType<Scalars, 'String'>,
  ownerId?: ?$ElementType<Scalars, 'ID'>,
  excludeIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  createdAt?: ?DateRangeInput,
  updatedAt?: ?DateRangeInput,
  types?: ?Array<OrganizationType>,
  name?: ?$ElementType<Scalars, 'String'>,
  confirmed?: ?$ElementType<Scalars, 'Boolean'>,
  leftOrganizationId?: ?$ElementType<Scalars, 'String'>,
  rightOrganizationId?: ?$ElementType<Scalars, 'String'>,
  ownerOrganizationId?: ?$ElementType<Scalars, 'String'>,
  showRightPartners?: ?$ElementType<Scalars, 'Boolean'>,
  showLeftPartners?: ?$ElementType<Scalars, 'Boolean'>,
};

export type PartnerPayload = Partner | BadRequest | Forbidden | NotFound;

export type PartnerPayloadPaginatedSearch = {
  ...Paginated,
  ...{
     __typename: 'PartnerPayloadPaginatedSearch',
    nodes: Array<PartnerPayload>,
    hits: Array<Hit>,
    page: $ElementType<Scalars, 'Int'>,
    perPage: $ElementType<Scalars, 'Int'>,
    totalPage: $ElementType<Scalars, 'Int'>,
    count: $ElementType<Scalars, 'Int'>,
    totalCount: $ElementType<Scalars, 'Int'>,
  }
};

export type Partnership = {
  ...Model,
  ...{
     __typename: 'Partnership',
    confirmed: $ElementType<Scalars, 'Boolean'>,
    leftOrganization: OrganizationPayload,
    leftTypes: Array<OrganizationType>,
    leftName?: ?$ElementType<Scalars, 'String'>,
    leftCode?: ?$ElementType<Scalars, 'String'>,
    leftConfirmedAt?: ?$ElementType<Scalars, 'DateTime'>,
    leftTags: Array<TagPayload>,
    leftRoles: Array<RolePayload>,
    rightOrganization: OrganizationPayload,
    rightTypes: Array<OrganizationType>,
    rightName?: ?$ElementType<Scalars, 'String'>,
    rightCode?: ?$ElementType<Scalars, 'String'>,
    rightConfirmedAt?: ?$ElementType<Scalars, 'DateTime'>,
    rightTags: Array<TagPayload>,
    rightRoles: Array<RolePayload>,
    id: $ElementType<Scalars, 'ID'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    deletedAt?: ?$ElementType<Scalars, 'DateTime'>,
    createdBy?: ?UserPayload,
    updatedBy?: ?UserPayload,
    deletedBy?: ?UserPayload,
  }
};

export type PartnershipPayload = Partnership | BadRequest | Forbidden | NotFound;

export type PartnershipPayloadPaginatedSearch = {
  ...Paginated,
  ...{
     __typename: 'PartnershipPayloadPaginatedSearch',
    nodes: Array<PartnershipPayload>,
    hits: Array<Hit>,
    page: $ElementType<Scalars, 'Int'>,
    perPage: $ElementType<Scalars, 'Int'>,
    totalPage: $ElementType<Scalars, 'Int'>,
    count: $ElementType<Scalars, 'Int'>,
    totalCount: $ElementType<Scalars, 'Int'>,
  }
};

export type PartnerSortInput = {
  createdAt?: ?SortOrder,
  updatedAt?: ?SortOrder,
  name?: ?SortOrder,
  code?: ?SortOrder,
};

export type PartnerUpdateInput = {
  name?: ?$ElementType<Scalars, 'String'>,
  code?: ?$ElementType<Scalars, 'String'>,
  types?: ?Array<OrganizationType>,
  confirmedAt?: ?$ElementType<Scalars, 'DateTime'>,
  /** Deprecated */
  tagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  tags?: ?Array<EntityTagInput>,
};

export type Port = {
   __typename: 'Port',
  transportType: TransportType,
  code: $ElementType<Scalars, 'String'>,
  codeType?: ?TransportCodeType,
  name: $ElementType<Scalars, 'String'>,
  seaport?: ?Seaport,
  seaportName?: ?$ElementType<Scalars, 'String'>,
  airport?: ?Airport,
  airportName?: ?$ElementType<Scalars, 'String'>,
  road?: ?Road,
  roadName?: ?$ElementType<Scalars, 'String'>,
  rail?: ?Rail,
  railName?: ?$ElementType<Scalars, 'String'>,
  dryport?: ?Dryport,
  dryportName?: ?$ElementType<Scalars, 'String'>,
};

export type PortInput = {
  seaport?: ?Seaport,
  rail?: ?Rail,
  road?: ?Road,
  airport?: ?Airport,
  dryport?: ?Dryport,
};

export type Price = {
   __typename: 'Price',
  amount: $ElementType<Scalars, 'Float'>,
  currency: Currency,
};

export type PriceInput = {
  amount: $ElementType<Scalars, 'Float'>,
  currency: Currency,
};

export type PriceRangeInput = {
  min?: ?$ElementType<Scalars, 'Float'>,
  max?: ?$ElementType<Scalars, 'Float'>,
  currency?: ?Currency,
};

export type PriceValue = {
   __typename: 'PriceValue',
  priceValue: NewPrice,
};

export type Product = {
  ...Model,
  ...Owned,
  ...Tagged,
  ...Followed,
  ...Documented,
  ...Memorizable,
  ...Customizable,
  ...{
     __typename: 'Product',
    archived: $ElementType<Scalars, 'Boolean'>,
    name: $ElementType<Scalars, 'String'>,
    serial: $ElementType<Scalars, 'String'>,
    hsCode?: ?$ElementType<Scalars, 'String'>,
    janCode?: ?$ElementType<Scalars, 'String'>,
    material?: ?$ElementType<Scalars, 'String'>,
    productProviders: Array<ProductProviderPayload>,
    timeline: Timeline,
    orders: OrderPayloadPaginatedSearch,
    orderItems: OrderItemPayloadPaginatedSearch,
    batches: BatchPayloadPaginatedSearch,
    shipments: ShipmentPayloadPaginatedSearch,
    containers: ContainerPayloadPaginatedSearch,
    id: $ElementType<Scalars, 'ID'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    deletedAt?: ?$ElementType<Scalars, 'DateTime'>,
    createdBy?: ?UserPayload,
    updatedBy?: ?UserPayload,
    deletedBy?: ?UserPayload,
    ownedBy: OrganizationPayload,
    tags: Array<TagPayload>,
    followers: Array<UserPayload>,
    notificationUnseen: Array<NotificationPayload>,
    notificationUnseenCount: $ElementType<Scalars, 'Int'>,
    hasFile: $ElementType<Scalars, 'Boolean'>,
    filesUnreadCount: $ElementType<Scalars, 'Int'>,
    files: Array<FilePayload>,
    memo?: ?$ElementType<Scalars, 'String'>,
    customFields: CustomFields,
  }
};


export type ProductOrdersArgs = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?OrderFilterInput,
  sortBy?: ?OrderSortInput
};


export type ProductOrderItemsArgs = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?OrderItemFilterInput,
  sortBy?: ?OrderItemSortInput
};


export type ProductBatchesArgs = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?BatchFilterInput,
  sortBy?: ?BatchSortInput
};


export type ProductShipmentsArgs = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?ShipmentFilterInput,
  sortBy?: ?ShipmentSortInput
};


export type ProductContainersArgs = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?ContainerFilterInput,
  sortBy?: ?ContainerSortInput
};

export type ProductBulkFilterInput = {
  names?: ?ValuesWithMatchModeInput,
  serials?: ?ValuesWithMatchModeInput,
};

export type ProductCreateInput = {
  ownerId: $ElementType<Scalars, 'ID'>,
  /** Deprecated */
  importerId?: ?$ElementType<Scalars, 'ID'>,
  name: $ElementType<Scalars, 'String'>,
  serial: $ElementType<Scalars, 'String'>,
  janCode?: ?$ElementType<Scalars, 'String'>,
  hsCode?: ?$ElementType<Scalars, 'String'>,
  material?: ?$ElementType<Scalars, 'String'>,
  /** Deprecated */
  tagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  tags?: ?Array<EntityTagInput>,
  followerIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  files?: ?Array<EntityFileInput>,
  memo?: ?$ElementType<Scalars, 'String'>,
  productProviders: Array<ProductProductProviderCreateInput>,
  customFields?: ?CustomFieldsInput,
};

export type ProductFilterInput = {
  query?: ?$ElementType<Scalars, 'String'>,
  ownerId?: ?$ElementType<Scalars, 'ID'>,
  excludeIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  createdAt?: ?DateRangeInput,
  updatedAt?: ?DateRangeInput,
  bulkFilter?: ?ProductBulkFilterInput,
  archived?: ?$ElementType<Scalars, 'Boolean'>,
  name?: ?$ElementType<Scalars, 'String'>,
  serial?: ?$ElementType<Scalars, 'String'>,
  exporterId?: ?$ElementType<Scalars, 'ID'>,
  supplierId?: ?$ElementType<Scalars, 'ID'>,
  /** Deprecated */
  tagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  tagIdsWithOperator?: ?IdsWithOperatorInput,
  notTagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
};

export type ProductPayload = Product | BadRequest | Forbidden | NotFound;

export type ProductPayloadPaginatedSearch = {
  ...Paginated,
  ...{
     __typename: 'ProductPayloadPaginatedSearch',
    nodes: Array<ProductPayload>,
    hits: Array<Hit>,
    page: $ElementType<Scalars, 'Int'>,
    perPage: $ElementType<Scalars, 'Int'>,
    totalPage: $ElementType<Scalars, 'Int'>,
    count: $ElementType<Scalars, 'Int'>,
    totalCount: $ElementType<Scalars, 'Int'>,
  }
};

export type ProductProductProviderCreateInput = {
  name: $ElementType<Scalars, 'String'>,
  connectionId: $ElementType<Scalars, 'ID'>,
  /** Deprecated, use exporterIds instead */
  exporterId: $ElementType<Scalars, 'ID'>,
  /** Deprecated, use supplierIds instead */
  supplierId?: ?$ElementType<Scalars, 'ID'>,
  unitType?: ?$ElementType<Scalars, 'String'>,
  unitVolume?: ?MetricValueInput,
  autoCalculateUnitVolume?: ?$ElementType<Scalars, 'Boolean'>,
  unitWeight?: ?MetricValueInput,
  unitPrice?: ?PriceInput,
  unitSize?: ?SizeInput,
  inspectionFee?: ?PriceInput,
  origin?: ?Country,
  productionLeadTime?: ?$ElementType<Scalars, 'Int'>,
  files?: ?Array<EntityFileInput>,
  memo?: ?$ElementType<Scalars, 'String'>,
  defaultPackageId?: ?$ElementType<Scalars, 'ID'>,
  defaultPackageIndex?: ?$ElementType<Scalars, 'Int'>,
  packages: Array<ProductProviderPackageCreateInput>,
  customFields?: ?CustomFieldsInput,
  organizationIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  exporterIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  importerIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  supplierIds?: ?Array<$ElementType<Scalars, 'ID'>>,
};

export type ProductProductProviderUpdateInput = {
  archived?: ?$ElementType<Scalars, 'Boolean'>,
  name?: ?$ElementType<Scalars, 'String'>,
  /** Deprecated, use exporterIds instead */
  exporterId?: ?$ElementType<Scalars, 'ID'>,
  /** Deprecated, use supplierIds instead */
  supplierId?: ?$ElementType<Scalars, 'ID'>,
  unitType?: ?$ElementType<Scalars, 'String'>,
  unitVolume?: ?MetricValueInput,
  autoCalculateUnitVolume?: ?$ElementType<Scalars, 'Boolean'>,
  unitWeight?: ?MetricValueInput,
  unitPrice?: ?PriceInput,
  unitSize?: ?SizeInput,
  inspectionFee?: ?PriceInput,
  origin?: ?Country,
  productionLeadTime?: ?$ElementType<Scalars, 'Int'>,
  files?: ?Array<EntityFileInput>,
  memo?: ?$ElementType<Scalars, 'String'>,
  defaultPackageId?: ?$ElementType<Scalars, 'ID'>,
  defaultPackageIndex?: ?$ElementType<Scalars, 'Int'>,
  packages?: ?Array<ProductProviderPackageUpdateInput>,
  customFields?: ?CustomFieldsInput,
  organizationIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  exporterIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  importerIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  supplierIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  connectionId?: ?$ElementType<Scalars, 'ID'>,
  deleted?: ?$ElementType<Scalars, 'Boolean'>,
  id?: ?$ElementType<Scalars, 'ID'>,
};

export type ProductProvider = {
  ...Model,
  ...Connected,
  ...Owned,
  ...Sortable,
  ...Documented,
  ...MessageGrouped,
  ...Customizable,
  ...Memorizable,
  ...{
     __typename: 'ProductProvider',
    archived: $ElementType<Scalars, 'Boolean'>,
    /** Determined if the ProductProvider is referenced by at least one OrderItem or not */
  referenced: $ElementType<Scalars, 'Boolean'>,
    name: $ElementType<Scalars, 'String'>,
    product: ProductPayload,
    exporters: Array<OrganizationPayload>,
    importers: Array<OrganizationPayload>,
    suppliers: Array<OrganizationPayload>,
    exporter: OrganizationPayload,
    supplier?: ?OrganizationPayload,
    unitType?: ?$ElementType<Scalars, 'String'>,
    unitVolume?: ?MetricValue,
    autoCalculateUnitVolume: $ElementType<Scalars, 'Boolean'>,
    unitWeight?: ?MetricValue,
    unitPrice?: ?Price,
    newUnitPrice?: ?NewPricePayload,
    unitSize?: ?Size,
    inspectionFee?: ?Price,
    origin?: ?Country,
    productionLeadTime?: ?$ElementType<Scalars, 'Int'>,
    packageName?: ?$ElementType<Scalars, 'String'>,
    packageGrossWeight?: ?MetricValue,
    packageVolume?: ?MetricValue,
    autoCalculatePackageVolume: $ElementType<Scalars, 'Boolean'>,
    packageSize?: ?Size,
    packageCapacity?: ?$ElementType<Scalars, 'Float'>,
    defaultPackage?: ?ProductProviderPackagePayload,
    packages: Array<ProductProviderPackagePayload>,
    timeline: Timeline,
    fileCount: ProductProviderFileCount,
    organizations: Array<OrganizationPayload>,
    batches: BatchPayloadPaginatedSearch,
    id: $ElementType<Scalars, 'ID'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    deletedAt?: ?$ElementType<Scalars, 'DateTime'>,
    createdBy?: ?UserPayload,
    updatedBy?: ?UserPayload,
    deletedBy?: ?UserPayload,
    connectionBy?: ?ConnectionPayload,
    ownedBy: OrganizationPayload,
    sort: $ElementType<Scalars, 'Int'>,
    hasFile: $ElementType<Scalars, 'Boolean'>,
    filesUnreadCount: $ElementType<Scalars, 'Int'>,
    files: Array<FilePayload>,
    hasMessage: $ElementType<Scalars, 'Boolean'>,
    messageGroups: Array<MessageGroupPayload>,
    customFields: CustomFields,
    memo?: ?$ElementType<Scalars, 'String'>,
  }
};


export type ProductProviderBatchesArgs = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?BatchFilterInput,
  sortBy?: ?BatchSortInput
};

export type ProductProviderFileCount = {
   __typename: 'ProductProviderFileCount',
  count: $ElementType<Scalars, 'Int'>,
  spec: $ElementType<Scalars, 'Int'>,
  analysis_cert: $ElementType<Scalars, 'Int'>,
  origin_cert: $ElementType<Scalars, 'Int'>,
  miscellaneous: $ElementType<Scalars, 'Int'>,
};

export type ProductProviderFilterInput = {
  query?: ?$ElementType<Scalars, 'String'>,
  ownerId?: ?$ElementType<Scalars, 'ID'>,
  excludeIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  createdAt?: ?DateRangeInput,
  updatedAt?: ?DateRangeInput,
  connectionIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  archived?: ?$ElementType<Scalars, 'Boolean'>,
  name?: ?$ElementType<Scalars, 'String'>,
  productId?: ?$ElementType<Scalars, 'ID'>,
  /** Deprecated */
  exporterId?: ?$ElementType<Scalars, 'ID'>,
  /** Deprecated */
  supplierId?: ?$ElementType<Scalars, 'ID'>,
  /** Deprecated */
  importerId?: ?$ElementType<Scalars, 'ID'>,
  exporterIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  importerIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  supplierIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  /** Deprecated */
  tagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  tagIdsWithOperator?: ?IdsWithOperatorInput,
  notTagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
};

export type ProductProviderPackage = {
  ...Model,
  ...Connected,
  ...Owned,
  ...Sortable,
  ...{
     __typename: 'ProductProviderPackage',
    productProvider: ProductProviderPayload,
    name?: ?$ElementType<Scalars, 'String'>,
    grossWeight?: ?MetricValue,
    volume?: ?MetricValue,
    autoCalculateVolume: $ElementType<Scalars, 'Boolean'>,
    size?: ?Size,
    capacity?: ?$ElementType<Scalars, 'Float'>,
    id: $ElementType<Scalars, 'ID'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    deletedAt?: ?$ElementType<Scalars, 'DateTime'>,
    createdBy?: ?UserPayload,
    updatedBy?: ?UserPayload,
    deletedBy?: ?UserPayload,
    connectionBy?: ?ConnectionPayload,
    ownedBy: OrganizationPayload,
    sort: $ElementType<Scalars, 'Int'>,
  }
};

export type ProductProviderPackageCreateInput = {
  name?: ?$ElementType<Scalars, 'String'>,
  grossWeight?: ?MetricValueInput,
  volume?: ?MetricValueInput,
  autoCalculateVolume?: ?$ElementType<Scalars, 'Boolean'>,
  size?: ?SizeInput,
  capacity?: ?$ElementType<Scalars, 'Float'>,
};

export type ProductProviderPackagePayload = ProductProviderPackage | BadRequest | Forbidden | NotFound;

export type ProductProviderPackageUpdateInput = {
  id?: ?$ElementType<Scalars, 'ID'>,
  name?: ?$ElementType<Scalars, 'String'>,
  grossWeight?: ?MetricValueInput,
  volume?: ?MetricValueInput,
  autoCalculateVolume?: ?$ElementType<Scalars, 'Boolean'>,
  size?: ?SizeInput,
  capacity?: ?$ElementType<Scalars, 'Float'>,
};

export type ProductProviderPackageUpdateWrapperInput = {
  id: $ElementType<Scalars, 'ID'>,
  input: ProductProviderPackageUpdateInput,
};

export type ProductProviderPayload = ProductProvider | BadRequest | Forbidden | NotFound;

export type ProductProviderPayloadPaginatedSearch = {
  ...Paginated,
  ...{
     __typename: 'ProductProviderPayloadPaginatedSearch',
    nodes: Array<ProductProviderPayload>,
    hits: Array<Hit>,
    page: $ElementType<Scalars, 'Int'>,
    perPage: $ElementType<Scalars, 'Int'>,
    totalPage: $ElementType<Scalars, 'Int'>,
    count: $ElementType<Scalars, 'Int'>,
    totalCount: $ElementType<Scalars, 'Int'>,
  }
};

export type ProductProviderSortInput = {
  createdAt?: ?SortOrder,
  updatedAt?: ?SortOrder,
  name?: ?SortOrder,
  productName?: ?SortOrder,
  productSerial?: ?SortOrder,
  exporterName?: ?SortOrder,
  supplierName?: ?SortOrder,
  unitPriceCurrency?: ?SortOrder,
};

export type ProductProviderUpdateInput = {
  archived?: ?$ElementType<Scalars, 'Boolean'>,
  name?: ?$ElementType<Scalars, 'String'>,
  /** Deprecated, use exporterIds instead */
  exporterId?: ?$ElementType<Scalars, 'ID'>,
  /** Deprecated, use supplierIds instead */
  supplierId?: ?$ElementType<Scalars, 'ID'>,
  unitType?: ?$ElementType<Scalars, 'String'>,
  unitVolume?: ?MetricValueInput,
  autoCalculateUnitVolume?: ?$ElementType<Scalars, 'Boolean'>,
  unitWeight?: ?MetricValueInput,
  unitPrice?: ?PriceInput,
  unitSize?: ?SizeInput,
  inspectionFee?: ?PriceInput,
  origin?: ?Country,
  productionLeadTime?: ?$ElementType<Scalars, 'Int'>,
  files?: ?Array<EntityFileInput>,
  memo?: ?$ElementType<Scalars, 'String'>,
  defaultPackageId?: ?$ElementType<Scalars, 'ID'>,
  defaultPackageIndex?: ?$ElementType<Scalars, 'Int'>,
  packages?: ?Array<ProductProviderPackageUpdateInput>,
  customFields?: ?CustomFieldsInput,
  organizationIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  exporterIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  importerIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  supplierIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  connectionId?: ?$ElementType<Scalars, 'ID'>,
  deleted?: ?$ElementType<Scalars, 'Boolean'>,
  id?: ?$ElementType<Scalars, 'ID'>,
  productId?: ?$ElementType<Scalars, 'ID'>,
};

export type ProductProviderUpdateWrapperInput = {
  id: $ElementType<Scalars, 'ID'>,
  input: ProductProviderUpdateInput,
};

export type ProductSortInput = {
  createdAt?: ?SortOrder,
  updatedAt?: ?SortOrder,
  name?: ?SortOrder,
  serial?: ?SortOrder,
};

export type ProductUpdateInput = {
  archived?: ?$ElementType<Scalars, 'Boolean'>,
  /** Deprecated */
  importerId?: ?$ElementType<Scalars, 'ID'>,
  name?: ?$ElementType<Scalars, 'String'>,
  serial?: ?$ElementType<Scalars, 'String'>,
  janCode?: ?$ElementType<Scalars, 'String'>,
  hsCode?: ?$ElementType<Scalars, 'String'>,
  material?: ?$ElementType<Scalars, 'String'>,
  /** Deprecated */
  tagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  tags?: ?Array<EntityTagInput>,
  followerIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  files?: ?Array<EntityFileInput>,
  memo?: ?$ElementType<Scalars, 'String'>,
  productProviders?: ?Array<ProductProductProviderUpdateInput>,
  customFields?: ?CustomFieldsInput,
};

export type ProductUpdateWrapperInput = {
  id: $ElementType<Scalars, 'ID'>,
  input: ProductUpdateInput,
};

export type Query = {
   __typename: 'Query',
  viewer: Viewer,
  authenticated: $ElementType<Scalars, 'Boolean'>,
  authenticatedWithMFA: Authenticated,
  default: $ElementType<Scalars, 'Boolean'>,
  user: UserPayload,
  users: UserPayloadPaginatedSearch,
  usersByIDs: Array<UserPayload>,
  organization: OrganizationPayload,
  organizationsByIDs: Array<OrganizationPayload>,
  organizationGroup: OrganizationGroupPayload,
  organizationGroups: OrganizationGroupPayloadPaginatedSearch,
  organizationGroupsByIDs: Array<OrganizationGroupPayload>,
  partner: PartnerPayload,
  partnership: PartnershipPayload,
  partnerships: PartnershipPayloadPaginatedSearch,
  product: ProductPayload,
  products: ProductPayloadPaginatedSearch,
  productsByIDs: Array<ProductPayload>,
  productExport: ExportPayload,
  productsExport: ExportPayload,
  productsByIDsExport: ExportPayload,
  productProvider: ProductProviderPayload,
  productProviders: ProductProviderPayloadPaginatedSearch,
  productProvidersByIDs: Array<ProductProviderPayload>,
  ports: Array<Port>,
  order: OrderPayload,
  orders: OrderPayloadPaginatedSearch,
  ordersByIDs: Array<OrderPayload>,
  orderExport: ExportPayload,
  ordersExport: ExportPayload,
  ordersByIDsExport: ExportPayload,
  orderItem: OrderItemPayload,
  orderItems: OrderItemPayloadPaginatedSearch,
  orderItemsByIDs: Array<OrderItemPayload>,
  batch: BatchPayload,
  batches: BatchPayloadPaginatedSearch,
  batchesByIDs: Array<BatchPayload>,
  batchExport: ExportPayload,
  batchesExport: ExportPayload,
  batchesByIDsExport: ExportPayload,
  batchesTable: Array<BatchPayload>,
  shipment: ShipmentPayload,
  shipments: ShipmentPayloadPaginatedSearch,
  shipmentsByIDs: Array<ShipmentPayload>,
  shipmentExport: ExportPayload,
  shipmentsExport: ExportPayload,
  shipmentsByIDsExport: ExportPayload,
  container: ContainerPayload,
  containers: ContainerPayloadPaginatedSearch,
  containersByIDs: Array<ContainerPayload>,
  containerExport: ExportPayload,
  containersExport: ExportPayload,
  containersByIDsExport: ExportPayload,
  warehouse: WarehousePayload,
  warehouses: WarehousePayloadPaginatedSearch,
  warehousesExport: ExportPayload,
  warehousesByIDs: Array<WarehousePayload>,
  tag: TagPayload,
  tags: TagPayloadPaginatedSearch,
  tagsByIDs: Array<TagPayload>,
  tagExport: ExportPayload,
  tagsExport: ExportPayload,
  tagsByIDsExport: ExportPayload,
  tagGroup: TagGroupPayload,
  tagGroups: TagGroupPayloadPaginatedSearch,
  tagGroupsByIDs: Array<TagGroupPayload>,
  partnersExport: ExportPayload,
  fieldDefinition: FieldDefinitionPayload,
  fieldDefinitions: Array<FieldDefinitionPayload>,
  customFields: FieldDefinitionPayloadPaginatedSearch,
  mask: MaskPayload,
  masks: MaskPayloadPaginatedSearch,
  masksByIDs: Array<MaskPayload>,
  maskEdit: MaskEditPayload,
  maskEditByUser: MaskEditPayload,
  maskEdits: MaskEditPayloadPaginatedSearch,
  maskEditsByIDs: Array<MaskEditPayload>,
  viewState: ViewStatePayload,
  notification: NotificationPayload,
  notifications: NotificationPayloadPaginatedSearch,
  reminder: ReminderPayload,
  reminders: ReminderPayloadPaginatedSearch,
  remindersByIDs: Array<ReminderPayload>,
  integration: IntegrationPayload,
  integrations: IntegrationPayloadPaginatedSearch,
  integrationConfiguration: IntegrationConfigurationPayload,
  integrationConfigurations: IntegrationConfigurationPayloadPaginatedSearch,
  file: FilePayload,
  files: FilePayloadPaginatedSearch,
  filesByIDs: Array<FilePayload>,
  folder: FolderPayload,
  folders: FolderPayloadPaginatedSearch,
  exportTemplates: Array<ExportTemplatePayload>,
  exportExtensions: Array<ExportExtensionPayload>,
  genericExport: ExportPayload,
  focuses: Array<FocusPayload>,
  timelineEntriesByIDs: Array<TimelineEntry>,
  timelineEntries: TimelineEntryPaginatedSearch,
  timelineEntry: TimelineEntry,
  activityLogsScroll: ActivityLogPayloadScrolledSearch,
  activityLogsByIDs: Array<ActivityLogPayload>,
  activityLogs: ActivityLogPayloadPaginatedSearch,
  activityLog: ActivityLogPayload,
  messages: MessagePayloadPaginatedSearch,
  draftMessage: DraftMessagePayload,
  emojis: Array<EmojiPayload>,
};


export type QueryDefaultArgs = {
  filterBy?: ?DefaultFilterInput,
  sortBy?: ?DefaultSortInput
};


export type QueryUserArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type QueryUsersArgs = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?UserFilterInput,
  sortBy?: ?UserSortInput
};


export type QueryUsersByIDsArgs = {
  ids: Array<$ElementType<Scalars, 'ID'>>
};


export type QueryOrganizationArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type QueryOrganizationsByIDsArgs = {
  ids: Array<$ElementType<Scalars, 'ID'>>
};


export type QueryOrganizationGroupArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type QueryOrganizationGroupsArgs = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?OrganizationGroupFilterInput,
  sortBy?: ?OrganizationGroupSortInput
};


export type QueryOrganizationGroupsByIDsArgs = {
  ids: Array<$ElementType<Scalars, 'ID'>>
};


export type QueryPartnerArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type QueryPartnershipArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type QueryPartnershipsArgs = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?PartnerFilterInput,
  sortBy?: ?PartnerSortInput
};


export type QueryProductArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type QueryProductsArgs = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?ProductFilterInput,
  sortBy?: ?ProductSortInput
};


export type QueryProductsByIDsArgs = {
  ids: Array<$ElementType<Scalars, 'ID'>>
};


export type QueryProductExportArgs = {
  id: $ElementType<Scalars, 'ID'>,
  templateId: $ElementType<Scalars, 'ID'>,
  columns?: ?Array<$ElementType<Scalars, 'String'>>,
  localSortBy?: ?Array<GenericSortInput>
};


export type QueryProductsExportArgs = {
  templateId: $ElementType<Scalars, 'ID'>,
  columns?: ?Array<$ElementType<Scalars, 'String'>>,
  localSortBy?: ?Array<GenericSortInput>,
  filterBy?: ?ProductFilterInput,
  sortBy?: ?ProductSortInput
};


export type QueryProductsByIDsExportArgs = {
  ids: Array<$ElementType<Scalars, 'ID'>>,
  templateId: $ElementType<Scalars, 'ID'>,
  columns?: ?Array<$ElementType<Scalars, 'String'>>,
  localSortBy?: ?Array<GenericSortInput>
};


export type QueryProductProviderArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type QueryProductProvidersArgs = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?ProductProviderFilterInput,
  sortBy?: ?ProductProviderSortInput
};


export type QueryProductProvidersByIDsArgs = {
  ids: Array<$ElementType<Scalars, 'ID'>>
};


export type QueryPortsArgs = {
  keyword: $ElementType<Scalars, 'String'>
};


export type QueryOrderArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type QueryOrdersArgs = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?OrderFilterInput,
  sortBy?: ?OrderSortInput
};


export type QueryOrdersByIDsArgs = {
  ids: Array<$ElementType<Scalars, 'ID'>>
};


export type QueryOrderExportArgs = {
  id: $ElementType<Scalars, 'ID'>,
  templateId: $ElementType<Scalars, 'ID'>,
  columns?: ?Array<$ElementType<Scalars, 'String'>>,
  localSortBy?: ?Array<GenericSortInput>
};


export type QueryOrdersExportArgs = {
  templateId: $ElementType<Scalars, 'ID'>,
  columns?: ?Array<$ElementType<Scalars, 'String'>>,
  localSortBy?: ?Array<GenericSortInput>,
  filterBy?: ?OrderFilterInput,
  sortBy?: ?OrderSortInput
};


export type QueryOrdersByIDsExportArgs = {
  ids: Array<$ElementType<Scalars, 'ID'>>,
  templateId: $ElementType<Scalars, 'ID'>,
  columns?: ?Array<$ElementType<Scalars, 'String'>>,
  localSortBy?: ?Array<GenericSortInput>
};


export type QueryOrderItemArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type QueryOrderItemsArgs = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?OrderItemFilterInput,
  sortBy?: ?OrderItemSortInput
};


export type QueryOrderItemsByIDsArgs = {
  ids: Array<$ElementType<Scalars, 'ID'>>
};


export type QueryBatchArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type QueryBatchesArgs = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?BatchFilterInput,
  sortBy?: ?BatchSortInput,
  sortBys?: ?Array<BatchSortsInput>
};


export type QueryBatchesByIDsArgs = {
  ids: Array<$ElementType<Scalars, 'ID'>>
};


export type QueryBatchExportArgs = {
  id: $ElementType<Scalars, 'ID'>,
  templateId: $ElementType<Scalars, 'ID'>,
  columns?: ?Array<$ElementType<Scalars, 'String'>>,
  localSortBy?: ?Array<GenericSortInput>
};


export type QueryBatchesExportArgs = {
  templateId: $ElementType<Scalars, 'ID'>,
  columns?: ?Array<$ElementType<Scalars, 'String'>>,
  localSortBy?: ?Array<GenericSortInput>,
  filterBy?: ?BatchFilterInput,
  sortBy?: ?BatchSortInput
};


export type QueryBatchesByIDsExportArgs = {
  ids: Array<$ElementType<Scalars, 'ID'>>,
  templateId: $ElementType<Scalars, 'ID'>,
  columns?: ?Array<$ElementType<Scalars, 'String'>>,
  localSortBy?: ?Array<GenericSortInput>
};


export type QueryBatchesTableArgs = {
  entities: Array<EntityInput>
};


export type QueryShipmentArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type QueryShipmentsArgs = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?ShipmentFilterInput,
  sortBy?: ?ShipmentSortInput
};


export type QueryShipmentsByIDsArgs = {
  ids: Array<$ElementType<Scalars, 'ID'>>
};


export type QueryShipmentExportArgs = {
  id: $ElementType<Scalars, 'ID'>,
  templateId: $ElementType<Scalars, 'ID'>,
  columns?: ?Array<$ElementType<Scalars, 'String'>>,
  localSortBy?: ?Array<GenericSortInput>
};


export type QueryShipmentsExportArgs = {
  templateId: $ElementType<Scalars, 'ID'>,
  columns?: ?Array<$ElementType<Scalars, 'String'>>,
  localSortBy?: ?Array<GenericSortInput>,
  filterBy?: ?ShipmentFilterInput,
  sortBy?: ?ShipmentSortInput
};


export type QueryShipmentsByIDsExportArgs = {
  ids: Array<$ElementType<Scalars, 'ID'>>,
  templateId: $ElementType<Scalars, 'ID'>,
  columns?: ?Array<$ElementType<Scalars, 'String'>>,
  localSortBy?: ?Array<GenericSortInput>
};


export type QueryContainerArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type QueryContainersArgs = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?ContainerFilterInput,
  sortBy?: ?ContainerSortInput
};


export type QueryContainersByIDsArgs = {
  ids: Array<$ElementType<Scalars, 'ID'>>
};


export type QueryContainerExportArgs = {
  id: $ElementType<Scalars, 'ID'>,
  templateId: $ElementType<Scalars, 'ID'>,
  columns?: ?Array<$ElementType<Scalars, 'String'>>,
  localSortBy?: ?Array<GenericSortInput>
};


export type QueryContainersExportArgs = {
  templateId: $ElementType<Scalars, 'ID'>,
  columns?: ?Array<$ElementType<Scalars, 'String'>>,
  localSortBy?: ?Array<GenericSortInput>,
  filterBy?: ?ContainerFilterInput,
  sortBy?: ?ContainerSortInput
};


export type QueryContainersByIDsExportArgs = {
  ids: Array<$ElementType<Scalars, 'ID'>>,
  templateId: $ElementType<Scalars, 'ID'>,
  columns?: ?Array<$ElementType<Scalars, 'String'>>,
  localSortBy?: ?Array<GenericSortInput>
};


export type QueryWarehouseArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type QueryWarehousesArgs = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?WarehouseFilterInput,
  sortBy?: ?WarehouseSortInput
};


export type QueryWarehousesExportArgs = {
  templateId: $ElementType<Scalars, 'ID'>,
  columns?: ?Array<$ElementType<Scalars, 'String'>>,
  localSortBy?: ?Array<GenericSortInput>,
  filterBy?: ?WarehouseFilterInput,
  sortBy?: ?WarehouseSortInput
};


export type QueryWarehousesByIDsArgs = {
  ids: Array<$ElementType<Scalars, 'ID'>>
};


export type QueryTagArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type QueryTagsArgs = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?TagFilterInput,
  sortBy?: ?TagSortInput
};


export type QueryTagsByIDsArgs = {
  ids: Array<$ElementType<Scalars, 'ID'>>
};


export type QueryTagExportArgs = {
  id: $ElementType<Scalars, 'ID'>,
  templateId: $ElementType<Scalars, 'ID'>,
  columns?: ?Array<$ElementType<Scalars, 'String'>>,
  localSortBy?: ?Array<GenericSortInput>
};


export type QueryTagsExportArgs = {
  templateId: $ElementType<Scalars, 'ID'>,
  columns?: ?Array<$ElementType<Scalars, 'String'>>,
  localSortBy?: ?Array<GenericSortInput>,
  filterBy?: ?TagFilterInput,
  sortBy?: ?TagSortInput
};


export type QueryTagsByIDsExportArgs = {
  ids: Array<$ElementType<Scalars, 'ID'>>,
  templateId: $ElementType<Scalars, 'ID'>,
  columns?: ?Array<$ElementType<Scalars, 'String'>>,
  localSortBy?: ?Array<GenericSortInput>
};


export type QueryTagGroupArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type QueryTagGroupsArgs = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?TagGroupFilterInput,
  sortBy?: ?TagGroupSortInput
};


export type QueryTagGroupsByIDsArgs = {
  ids: Array<$ElementType<Scalars, 'ID'>>
};


export type QueryPartnersExportArgs = {
  templateId: $ElementType<Scalars, 'ID'>,
  columns?: ?Array<$ElementType<Scalars, 'String'>>,
  localSortBy?: ?Array<GenericSortInput>,
  filterBy?: ?PartnerFilterInput,
  sortBy?: ?PartnerSortInput
};


export type QueryFieldDefinitionArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type QueryFieldDefinitionsArgs = {
  entityType: CustomizableEntityType
};


export type QueryCustomFieldsArgs = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?CustomFieldFilterInput
};


export type QueryMaskArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type QueryMasksArgs = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?MaskFilterInput,
  sortBy?: ?MaskSortInput
};


export type QueryMasksByIDsArgs = {
  ids: Array<$ElementType<Scalars, 'ID'>>
};


export type QueryMaskEditArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type QueryMaskEditByUserArgs = {
  type: $ElementType<Scalars, 'String'>
};


export type QueryMaskEditsArgs = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?MaskEditFilterInput,
  sortBy?: ?MaskEditSortInput
};


export type QueryMaskEditsByIDsArgs = {
  ids: Array<$ElementType<Scalars, 'ID'>>
};


export type QueryViewStateArgs = {
  type: ViewStateType
};


export type QueryNotificationArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type QueryNotificationsArgs = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?NotificationFilterInput,
  sortBy?: ?NotificationSortInput
};


export type QueryReminderArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type QueryRemindersArgs = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?ReminderFilterInput,
  sortBy?: ?ReminderSortInput
};


export type QueryRemindersByIDsArgs = {
  ids: Array<$ElementType<Scalars, 'ID'>>
};


export type QueryIntegrationArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type QueryIntegrationsArgs = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?IntegrationFilterInput,
  sortBy?: ?IntegrationSortInput
};


export type QueryIntegrationConfigurationArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type QueryIntegrationConfigurationsArgs = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?IntegrationConfigurationFilterInput,
  sortBy?: ?IntegrationConfigurationSortInput
};


export type QueryFileArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type QueryFilesArgs = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?FileFilterInput,
  sortBy?: ?FileSortInput
};


export type QueryFilesByIDsArgs = {
  ids: Array<$ElementType<Scalars, 'ID'>>
};


export type QueryFolderArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type QueryFoldersArgs = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?FolderFilterInput,
  sortBy?: ?FolderSortInput
};


export type QueryExportTemplatesArgs = {
  filterBy?: ?ExportTemplateFilterInput
};


export type QueryGenericExportArgs = {
  extension: $ElementType<Scalars, 'String'>,
  columns: Array<$ElementType<Scalars, 'String'>>,
  rows: Array<Array<?$ElementType<Scalars, 'String'>>>
};


export type QueryFocusesArgs = {
  id: $ElementType<Scalars, 'ID'>,
  entities: Array<EntityInput>
};


export type QueryTimelineEntriesByIDsArgs = {
  ids: Array<$ElementType<Scalars, 'ID'>>
};


export type QueryTimelineEntriesArgs = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?TimelineEntryFilterInput,
  sortBy?: ?TimelineEntrySortInput
};


export type QueryTimelineEntryArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type QueryActivityLogsScrollArgs = {
  scrollId?: ?$ElementType<Scalars, 'String'>,
  size: $ElementType<Scalars, 'Int'>,
  filterBy?: ?ActivityLogFilterInput,
  sortBy?: ?ActivityLogSortInput
};


export type QueryActivityLogsByIDsArgs = {
  ids: Array<$ElementType<Scalars, 'ID'>>
};


export type QueryActivityLogsArgs = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?ActivityLogFilterInput,
  sortBy?: ?ActivityLogSortInput
};


export type QueryActivityLogArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type QueryMessagesArgs = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?MessageFilterInput,
  sortBy?: ?MessageSortInput
};


export type QueryDraftMessageArgs = {
  input: DraftMessageGetInput
};

// This has been truncated by a custom Zenport plugin
export const RailValues = Object.freeze({
  /** Fier */
  Alfie: 'ALFIE'
});


export type Rail = $Values<typeof RailValues>;

export type Reference = {
   __typename: 'Reference',
  id: $ElementType<Scalars, 'ID'>,
  type: $ElementType<Scalars, 'String'>,
  service: $ElementType<Scalars, 'String'>,
};

export type Reminder = {
  ...Model,
  ...Owned,
  ...{
     __typename: 'Reminder',
    archived: $ElementType<Scalars, 'Boolean'>,
    rule: ReminderRule,
    receiver: ReminderReceiver,
    section: ReminderSection,
    id: $ElementType<Scalars, 'ID'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    deletedAt?: ?$ElementType<Scalars, 'DateTime'>,
    createdBy?: ?UserPayload,
    updatedBy?: ?UserPayload,
    deletedBy?: ?UserPayload,
    ownedBy: OrganizationPayload,
  }
};

export type ReminderCreateInput = {
  archived?: ?$ElementType<Scalars, 'Boolean'>,
  rule: ReminderRuleInput,
  receiver: ReminderReceiverInput,
  section: ReminderSectionInput,
};

export const ReminderEntityTypeValues = Object.freeze({
  /** Order */
  Order: 'Order', 
  /** Shipment */
  Shipment: 'Shipment'
});


export type ReminderEntityType = $Values<typeof ReminderEntityTypeValues>;

export type ReminderFilterInput = {
  query?: ?$ElementType<Scalars, 'String'>,
  ownerId?: ?$ElementType<Scalars, 'ID'>,
  excludeIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  createdAt?: ?DateRangeInput,
  updatedAt?: ?DateRangeInput,
};

export type ReminderFormActionInput = {
  fileTypes: Array<FileType>,
};

export type ReminderPayload = Reminder | BadRequest | Forbidden | NotFound;

export type ReminderPayloadPaginatedSearch = {
  ...Paginated,
  ...{
     __typename: 'ReminderPayloadPaginatedSearch',
    nodes: Array<ReminderPayload>,
    hits: Array<Hit>,
    page: $ElementType<Scalars, 'Int'>,
    perPage: $ElementType<Scalars, 'Int'>,
    totalPage: $ElementType<Scalars, 'Int'>,
    count: $ElementType<Scalars, 'Int'>,
    totalCount: $ElementType<Scalars, 'Int'>,
  }
};

export type ReminderReceiver = {
   __typename: 'ReminderReceiver',
  organizationTypes: Array<ReminderReceiverOrganizationType>,
  organizations: Array<OrganizationPayload>,
  users: Array<UserPayload>,
};

export type ReminderReceiverInput = {
  organizationTypes: Array<ReminderReceiverOrganizationType>,
  organizationIds: Array<$ElementType<Scalars, 'ID'>>,
  userIds: Array<$ElementType<Scalars, 'ID'>>,
};

export const ReminderReceiverOrganizationTypeValues = Object.freeze({
  /** Importer */
  Importer: 'Importer', 
  /** Exporter */
  Exporter: 'Exporter', 
  /** Forwarder */
  Forwarder: 'Forwarder', 
  /** Supplier */
  Supplier: 'Supplier', 
  /** Warehouser */
  Warehouser: 'Warehouser'
});


export type ReminderReceiverOrganizationType = $Values<typeof ReminderReceiverOrganizationTypeValues>;

export type ReminderRule = {
   __typename: 'ReminderRule',
  template: ReminderRuleTemplate,
  name: $ElementType<Scalars, 'String'>,
  triggerField: ReminderRuleTriggerField,
  entityType: ReminderEntityType,
  period: ReminderRulePeriod,
  periodNumbers: $ElementType<Scalars, 'Int'>,
  beforeAfter: ReminderRuleBeforeAfter,
};

export const ReminderRuleBeforeAfterValues = Object.freeze({
  /** Before */
  Before: 'Before', 
  /** After */
  After: 'After'
});


export type ReminderRuleBeforeAfter = $Values<typeof ReminderRuleBeforeAfterValues>;

export type ReminderRuleInput = {
  template: ReminderRuleTemplate,
  name: $ElementType<Scalars, 'String'>,
  entityType: ReminderEntityType,
  triggerField: ReminderRuleTriggerField,
  periodNumbers: $ElementType<Scalars, 'Int'>,
  period: ReminderRulePeriod,
  beforeAfter: ReminderRuleBeforeAfter,
};

export const ReminderRulePeriodValues = Object.freeze({
  /** Days */
  Days: 'Days', 
  /** Weeks */
  Weeks: 'Weeks', 
  /** Months */
  Months: 'Months'
});


export type ReminderRulePeriod = $Values<typeof ReminderRulePeriodValues>;

export const ReminderRuleTemplateValues = Object.freeze({
  /** RequestToDocumentUpload */
  RequestToDocumentUpload: 'RequestToDocumentUpload', 
  /** RequestToCheckAndUpdateData */
  RequestToCheckAndUpdateData: 'RequestToCheckAndUpdateData'
});


export type ReminderRuleTemplate = $Values<typeof ReminderRuleTemplateValues>;

export const ReminderRuleTriggerFieldValues = Object.freeze({
  /** ShipmentBlDate */
  ShipmentBlDate: 'ShipmentBlDate', 
  /** ShipmentBookingDate */
  ShipmentBookingDate: 'ShipmentBookingDate', 
  /** ShipmentCargoReady */
  ShipmentCargoReady: 'ShipmentCargoReady', 
  /** ShipmentLoadPortDeparture */
  ShipmentLoadPortDeparture: 'ShipmentLoadPortDeparture', 
  /** ShipmentDischargePortArrival */
  ShipmentDischargePortArrival: 'ShipmentDischargePortArrival', 
  /** ShipmentCustomsClearance */
  ShipmentCustomsClearance: 'ShipmentCustomsClearance', 
  /** ShipmentWarehouseArrival */
  ShipmentWarehouseArrival: 'ShipmentWarehouseArrival', 
  /** ShipmentDeliveryReady */
  ShipmentDeliveryReady: 'ShipmentDeliveryReady', 
  /** OrderPoDate */
  OrderPoDate: 'OrderPoDate', 
  /** OrderContractDeliveryDate */
  OrderContractDeliveryDate: 'OrderContractDeliveryDate'
});


export type ReminderRuleTriggerField = $Values<typeof ReminderRuleTriggerFieldValues>;

export type ReminderSection = {
   __typename: 'ReminderSection',
  entityType: ReminderEntityType,
  view: ReminderSectionView,
  formAction: ReminderSectionFormActionType,
  tableAction: ReminderSectionTableActionType,
};

export type ReminderSectionFormActionType = {
   __typename: 'ReminderSectionFormActionType',
  fileTypes: Array<FileType>,
};

export type ReminderSectionInput = {
  entityType: ReminderEntityType,
  view: ReminderSectionView,
  formAction?: ?ReminderFormActionInput,
  tableAction?: ?ReminderTableActionInput,
};

export type ReminderSectionTableActionType = {
   __typename: 'ReminderSectionTableActionType',
  maskEdit?: ?MaskEditPayload,
};

export const ReminderSectionViewValues = Object.freeze({
  /** Form */
  Form: 'Form', 
  /** Table */
  Table: 'Table'
});


export type ReminderSectionView = $Values<typeof ReminderSectionViewValues>;

export type ReminderSortInput = {
  createdAt?: ?SortOrder,
  updatedAt?: ?SortOrder,
};

export type ReminderTableActionInput = {
  maskEditId: $ElementType<Scalars, 'ID'>,
};

export type ReminderUpdateInput = {
  archived?: ?$ElementType<Scalars, 'Boolean'>,
  rule?: ?ReminderRuleInput,
  receiver?: ?ReminderReceiverInput,
  section?: ?ReminderSectionInput,
};

export type RequestResetPasswordInput = {
  email?: ?$ElementType<Scalars, 'String'>,
};

export type ResetPasswordInput = {
  token: $ElementType<Scalars, 'String'>,
  password: $ElementType<Scalars, 'String'>,
};

export const ResizePresetValues = Object.freeze({
  /** Original */
  Original: 'Original', 
  /** Small 40x40 */
  Small: 'Small', 
  /** Medium 200x200 */
  Medium: 'Medium', 
  /** Large 880x880 */
  Large: 'Large'
});


export type ResizePreset = $Values<typeof ResizePresetValues>;

// This has been truncated by a custom Zenport plugin
export const RoadValues = Object.freeze({
  /** Andorra la Vella */
  Adalv: 'ADALV'
});


export type Road = $Values<typeof RoadValues>;

export type Role = {
  ...Model,
  ...Memorizable,
  ...{
     __typename: 'Role',
    name: $ElementType<Scalars, 'String'>,
    type: RoleType,
    parents: Array<RolePayload>,
    permissions: Array<$ElementType<Scalars, 'String'>>,
    flattenPermissions: Array<$ElementType<Scalars, 'String'>>,
    partnership?: ?PartnershipPayload,
    ownedBy?: ?OrganizationPayload,
    connectionBy?: ?ConnectionPayload,
    id: $ElementType<Scalars, 'ID'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    deletedAt?: ?$ElementType<Scalars, 'DateTime'>,
    createdBy?: ?UserPayload,
    updatedBy?: ?UserPayload,
    deletedBy?: ?UserPayload,
    memo?: ?$ElementType<Scalars, 'String'>,
  }
};

export type RoleCreateInput = {
  name: $ElementType<Scalars, 'String'>,
  type: RoleType,
  parents?: ?Array<$ElementType<Scalars, 'ID'>>,
  permissions: Array<$ElementType<Scalars, 'String'>>,
  partnershipId?: ?$ElementType<Scalars, 'ID'>,
  connectionId?: ?$ElementType<Scalars, 'ID'>,
  memo?: ?$ElementType<Scalars, 'String'>,
};

export type RolePayload = Role | BadRequest | Forbidden | NotFound;

export const RoleTypeValues = Object.freeze({
  /** default */
  Default: 'default', 
  /** internal */
  Internal: 'internal', 
  /** external */
  External: 'external', 
  /** connection */
  Connection: 'connection', 
  /** global */
  Global: 'global'
});


export type RoleType = $Values<typeof RoleTypeValues>;

export type RoleUpdateInput = {
  name?: ?$ElementType<Scalars, 'String'>,
  type?: ?RoleType,
  parents?: ?Array<$ElementType<Scalars, 'ID'>>,
  permissions?: ?Array<$ElementType<Scalars, 'String'>>,
  partnershipId?: ?$ElementType<Scalars, 'ID'>,
  connectionId?: ?$ElementType<Scalars, 'ID'>,
  memo?: ?$ElementType<Scalars, 'String'>,
};

export type Scrolled = {
  scrollId: $ElementType<Scalars, 'String'>,
  size: $ElementType<Scalars, 'Int'>,
  count: $ElementType<Scalars, 'Int'>,
  totalCount: $ElementType<Scalars, 'Int'>,
};

// This has been truncated by a custom Zenport plugin
export const SeaportValues = Object.freeze({
  /** Abu al Bukhoosh */
  Aeabu: 'AEABU'
});


export type Seaport = $Values<typeof SeaportValues>;

export type Shipment = {
  ...Model,
  ...Connected,
  ...Owned,
  ...Tagged,
  ...Followed,
  ...Supervised,
  ...Documented,
  ...MessageGrouped,
  ...Customizable,
  ...Memorizable,
  ...{
     __typename: 'Shipment',
    archived: $ElementType<Scalars, 'Boolean'>,
    importer?: ?OrganizationPayload,
    exporter?: ?OrganizationPayload,
    relatedExporters: Array<OrganizationPayload>,
    no: $ElementType<Scalars, 'String'>,
    folder: $ElementType<Scalars, 'String'>,
    blNo?: ?$ElementType<Scalars, 'String'>,
    blDate?: ?$ElementType<Scalars, 'DateTime'>,
    exportCustomsClearance?: ?$ElementType<Scalars, 'DateTime'>,
    cyCfsArrivalDate?: ?$ElementType<Scalars, 'DateTime'>,
    cyCfsCut?: ?$ElementType<Scalars, 'DateTime'>,
    cyCfsOpen?: ?$ElementType<Scalars, 'DateTime'>,
    masterBlNo?: ?$ElementType<Scalars, 'String'>,
    booked?: ?$ElementType<Scalars, 'Boolean'>,
    bookingNo?: ?$ElementType<Scalars, 'String'>,
    bookingDate?: ?$ElementType<Scalars, 'DateTime'>,
    invoiceNo?: ?$ElementType<Scalars, 'String'>,
    contractNo?: ?$ElementType<Scalars, 'String'>,
    incoterm?: ?Incoterm,
    placeOfReceipt?: ?$ElementType<Scalars, 'String'>,
    placeOfDelivery?: ?$ElementType<Scalars, 'String'>,
    loadType?: ?LoadType,
    transportType?: ?TransportType,
    autoTracking?: ?$ElementType<Scalars, 'Boolean'>,
    autoTrackingBy?: ?AutoTrackingByType,
    autoTrackingIncludeContainers?: ?$ElementType<Scalars, 'Boolean'>,
    autoTrackingTransportType?: ?AutoTrackingTransportType,
    trackingStatus?: ?ShipmentTrackingStatus,
    carrier?: ?$ElementType<Scalars, 'String'>,
    cargoReady: TimelineDatePayload,
    cargoReadyWarehouse?: ?WarehousePayload,
    voyages: Array<VoyagePayload>,
    containerGroups: Array<ContainerGroupPayload>,
    forwarders: Array<OrganizationPayload>,
    totalVolume: MetricValue,
    totalVolumeOverride?: ?MetricValue,
    totalVolumeOverriding: $ElementType<Scalars, 'Boolean'>,
    totalVolumeDisplayMetric: $ElementType<Scalars, 'String'>,
    totalWeight: MetricValue,
    totalWeightOverride?: ?MetricValue,
    totalWeightOverriding: $ElementType<Scalars, 'Boolean'>,
    totalWeightDisplayMetric: $ElementType<Scalars, 'String'>,
    totalPackageQuantity: $ElementType<Scalars, 'Float'>,
    totalPackageQuantityOverride?: ?$ElementType<Scalars, 'Float'>,
    totalPackageQuantityOverriding: $ElementType<Scalars, 'Boolean'>,
    totalPrice?: ?NewPricePayload,
    earliestWarehouseAgreedArrival?: ?$ElementType<Scalars, 'DateTime'>,
    latestWarehouseAgreedArrival?: ?$ElementType<Scalars, 'DateTime'>,
    earliestWarehouseActualArrival?: ?$ElementType<Scalars, 'DateTime'>,
    latestWarehouseActualArrival?: ?$ElementType<Scalars, 'DateTime'>,
    voyageCount: $ElementType<Scalars, 'Long'>,
    productCount: $ElementType<Scalars, 'Long'>,
    orderCount: $ElementType<Scalars, 'Int'>,
    orderItemCount: $ElementType<Scalars, 'Int'>,
    /** sync to batch unit if all batch units are unified */
  batchQuantity: MetricValue,
    batchCount: $ElementType<Scalars, 'Int'>,
    containerCount: $ElementType<Scalars, 'Int'>,
    containerTypeCounts: Array<ContainerTypeCount>,
    batches: Array<BatchPayload>,
    batchesWithoutContainer: Array<BatchPayload>,
    containers: Array<ContainerPayload>,
    timeline: Timeline,
    fileCount: ShipmentFileCount,
    organizations: Array<OrganizationPayload>,
    id: $ElementType<Scalars, 'ID'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    deletedAt?: ?$ElementType<Scalars, 'DateTime'>,
    createdBy?: ?UserPayload,
    updatedBy?: ?UserPayload,
    deletedBy?: ?UserPayload,
    connectionBy?: ?ConnectionPayload,
    ownedBy: OrganizationPayload,
    tags: Array<TagPayload>,
    followers: Array<UserPayload>,
    notificationUnseen: Array<NotificationPayload>,
    notificationUnseenCount: $ElementType<Scalars, 'Int'>,
    inCharges: Array<UserPayload>,
    hasFile: $ElementType<Scalars, 'Boolean'>,
    filesUnreadCount: $ElementType<Scalars, 'Int'>,
    files: Array<FilePayload>,
    hasMessage: $ElementType<Scalars, 'Boolean'>,
    messageGroups: Array<MessageGroupPayload>,
    customFields: CustomFields,
    memo?: ?$ElementType<Scalars, 'String'>,
  }
};

export type ShipmentBatchInput = {
  no?: ?$ElementType<Scalars, 'String'>,
  quantity?: ?$ElementType<Scalars, 'Float'>,
  producedQuantity?: ?$ElementType<Scalars, 'Float'>,
  preShippedQuantity?: ?$ElementType<Scalars, 'Float'>,
  shippedQuantity?: ?$ElementType<Scalars, 'Float'>,
  postShippedQuantity?: ?$ElementType<Scalars, 'Float'>,
  deliveredQuantity?: ?$ElementType<Scalars, 'Float'>,
  quantityUnit?: ?$ElementType<Scalars, 'String'>,
  producedAt?: ?$ElementType<Scalars, 'DateTime'>,
  deliveredAt?: ?$ElementType<Scalars, 'DateTime'>,
  expiredAt?: ?$ElementType<Scalars, 'DateTime'>,
  desiredAt?: ?$ElementType<Scalars, 'DateTime'>,
  packageQuantity?: ?$ElementType<Scalars, 'Float'>,
  autoCalculatePackageQuantity?: ?$ElementType<Scalars, 'Boolean'>,
  packageName?: ?$ElementType<Scalars, 'String'>,
  packageGrossWeight?: ?MetricValueInput,
  packageVolume?: ?MetricValueInput,
  autoCalculatePackageVolume?: ?$ElementType<Scalars, 'Boolean'>,
  packageSize?: ?SizeInput,
  packageCapacity?: ?$ElementType<Scalars, 'Float'>,
  packageCapacityUnit?: ?$ElementType<Scalars, 'String'>,
  memo?: ?$ElementType<Scalars, 'String'>,
  totalPriceOverride?: ?PriceInput,
  totalPriceOverriding?: ?$ElementType<Scalars, 'Boolean'>,
  totalVolumeOverride?: ?MetricValueInput,
  totalVolumeOverriding?: ?$ElementType<Scalars, 'Boolean'>,
  /** Deprecated */
  tagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  tags?: ?Array<EntityTagInput>,
  followerIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  customFields?: ?CustomFieldsInput,
  id?: ?$ElementType<Scalars, 'ID'>,
  orderItemId?: ?$ElementType<Scalars, 'ID'>,
};

export type ShipmentBulkFilterInput = {
  nos?: ?ValuesWithMatchModeInput,
  orderItemNos?: ?ValuesWithMatchModeInput,
  productNames?: ?ValuesWithMatchModeInput,
  productSerials?: ?ValuesWithMatchModeInput,
};

export type ShipmentContainerCreateInput = {
  no: $ElementType<Scalars, 'String'>,
  warehouseId?: ?$ElementType<Scalars, 'ID'>,
  departureDate?: ?$ElementType<Scalars, 'DateTime'>,
  departureDateApprovedById?: ?$ElementType<Scalars, 'ID'>,
  departureDateAssignedToIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  vanningDate?: ?$ElementType<Scalars, 'DateTime'>,
  vanningDateApprovedById?: ?$ElementType<Scalars, 'ID'>,
  vanningActualDate?: ?$ElementType<Scalars, 'DateTime'>,
  vanningActualDateApprovedById?: ?$ElementType<Scalars, 'ID'>,
  warehouseArrivalAgreedDate?: ?$ElementType<Scalars, 'DateTime'>,
  warehouseArrivalAgreedDateApprovedById?: ?$ElementType<Scalars, 'ID'>,
  warehouseArrivalAgreedDateAssignedToIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  warehouseArrivalActualDate?: ?$ElementType<Scalars, 'DateTime'>,
  warehouseArrivalActualDateApprovedById?: ?$ElementType<Scalars, 'ID'>,
  warehouseArrivalActualDateAssignedToIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  terminalArrival?: ?TimelineDateNestedInput,
  terminalArrivalPort?: ?PortInput,
  freeTimeStartDate?: ?$ElementType<Scalars, 'DateTime'>,
  freeTimeDuration?: ?$ElementType<Scalars, 'Int'>,
  autoCalculatedFreeTimeStartDate?: ?$ElementType<Scalars, 'Boolean'>,
  yardName?: ?$ElementType<Scalars, 'String'>,
  representativeBatchId?: ?$ElementType<Scalars, 'ID'>,
  representativeBatchIndex?: ?$ElementType<Scalars, 'Int'>,
  containerType?: ?$ElementType<Scalars, 'String'>,
  containerOption?: ?ContainerOption,
  batches?: ?Array<ContainerBatchInput>,
  memo?: ?$ElementType<Scalars, 'String'>,
  /** Deprecated */
  tagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  tags?: ?Array<EntityTagInput>,
  followerIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  customFields?: ?CustomFieldsInput,
};

export type ShipmentContainerUpdateInput = {
  no?: ?$ElementType<Scalars, 'String'>,
  warehouseId?: ?$ElementType<Scalars, 'ID'>,
  departureDate?: ?$ElementType<Scalars, 'DateTime'>,
  departureDateApprovedById?: ?$ElementType<Scalars, 'ID'>,
  departureDateAssignedToIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  vanningDate?: ?$ElementType<Scalars, 'DateTime'>,
  vanningDateApprovedById?: ?$ElementType<Scalars, 'ID'>,
  vanningActualDate?: ?$ElementType<Scalars, 'DateTime'>,
  vanningActualDateApprovedById?: ?$ElementType<Scalars, 'ID'>,
  warehouseArrivalAgreedDate?: ?$ElementType<Scalars, 'DateTime'>,
  warehouseArrivalAgreedDateApprovedById?: ?$ElementType<Scalars, 'ID'>,
  warehouseArrivalAgreedDateAssignedToIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  warehouseArrivalActualDate?: ?$ElementType<Scalars, 'DateTime'>,
  warehouseArrivalActualDateApprovedById?: ?$ElementType<Scalars, 'ID'>,
  warehouseArrivalActualDateAssignedToIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  terminalArrival?: ?TimelineDateNestedInput,
  terminalArrivalPort?: ?PortInput,
  freeTimeStartDate?: ?$ElementType<Scalars, 'DateTime'>,
  freeTimeDuration?: ?$ElementType<Scalars, 'Int'>,
  autoCalculatedFreeTimeStartDate?: ?$ElementType<Scalars, 'Boolean'>,
  yardName?: ?$ElementType<Scalars, 'String'>,
  representativeBatchId?: ?$ElementType<Scalars, 'ID'>,
  representativeBatchIndex?: ?$ElementType<Scalars, 'Int'>,
  containerType?: ?$ElementType<Scalars, 'String'>,
  containerOption?: ?ContainerOption,
  batches?: ?Array<ContainerBatchInput>,
  memo?: ?$ElementType<Scalars, 'String'>,
  /** Deprecated */
  tagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  tags?: ?Array<EntityTagInput>,
  followerIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  customFields?: ?CustomFieldsInput,
  id?: ?$ElementType<Scalars, 'ID'>,
};

export type ShipmentCreateInput = {
  ownerId: $ElementType<Scalars, 'ID'>,
  connectionId: $ElementType<Scalars, 'ID'>,
  importerId?: ?$ElementType<Scalars, 'ID'>,
  exporterId?: ?$ElementType<Scalars, 'ID'>,
  no: $ElementType<Scalars, 'String'>,
  blNo?: ?$ElementType<Scalars, 'String'>,
  blDate?: ?$ElementType<Scalars, 'DateTime'>,
  exportCustomsClearance?: ?$ElementType<Scalars, 'DateTime'>,
  cyCfsArrivalDate?: ?$ElementType<Scalars, 'DateTime'>,
  cyCfsCut?: ?$ElementType<Scalars, 'DateTime'>,
  cyCfsOpen?: ?$ElementType<Scalars, 'DateTime'>,
  masterBlNo?: ?$ElementType<Scalars, 'String'>,
  booked?: ?$ElementType<Scalars, 'Boolean'>,
  bookingNo?: ?$ElementType<Scalars, 'String'>,
  bookingDate?: ?$ElementType<Scalars, 'DateTime'>,
  invoiceNo?: ?$ElementType<Scalars, 'String'>,
  contractNo?: ?$ElementType<Scalars, 'String'>,
  loadType?: ?LoadType,
  transportType?: ?TransportType,
  incoterm?: ?Incoterm,
  placeOfReceipt?: ?$ElementType<Scalars, 'String'>,
  placeOfDelivery?: ?$ElementType<Scalars, 'String'>,
  autoTracking?: ?$ElementType<Scalars, 'Boolean'>,
  autoTrackingBy?: ?AutoTrackingByType,
  autoTrackingIncludeContainers?: ?$ElementType<Scalars, 'Boolean'>,
  autoTrackingTransportType?: ?AutoTrackingTransportType,
  carrier?: ?$ElementType<Scalars, 'String'>,
  trackingStatus?: ?ShipmentTrackingStatus,
  cargoReady?: ?TimelineDateNestedInput,
  /** Deprecated */
  tagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  tags?: ?Array<EntityTagInput>,
  followerIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  forwarderIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  batches?: ?Array<ShipmentBatchInput>,
  voyages?: ?Array<VoyageNestedInput>,
  containerGroups?: ?Array<ContainerGroupNestedInput>,
  containers?: ?Array<ShipmentContainerCreateInput>,
  files?: ?Array<EntityFileInput>,
  memo?: ?$ElementType<Scalars, 'String'>,
  totalVolumeOverride?: ?MetricValueInput,
  totalVolumeOverriding?: ?$ElementType<Scalars, 'Boolean'>,
  totalVolumeDisplayMetric?: ?$ElementType<Scalars, 'String'>,
  totalWeightOverride?: ?MetricValueInput,
  totalWeightOverriding?: ?$ElementType<Scalars, 'Boolean'>,
  totalWeightDisplayMetric?: ?$ElementType<Scalars, 'String'>,
  totalPackageQuantityOverride?: ?$ElementType<Scalars, 'Float'>,
  totalPackageQuantityOverriding?: ?$ElementType<Scalars, 'Boolean'>,
  customFields?: ?CustomFieldsInput,
  organizationIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  cargoReadyWarehouseId?: ?$ElementType<Scalars, 'ID'>,
};

export type ShipmentFileCount = {
   __typename: 'ShipmentFileCount',
  count: $ElementType<Scalars, 'Int'>,
  bl: $ElementType<Scalars, 'Int'>,
  invoice: $ElementType<Scalars, 'Int'>,
  packingList: $ElementType<Scalars, 'Int'>,
  importDeclaration: $ElementType<Scalars, 'Int'>,
  inspectionApplication: $ElementType<Scalars, 'Int'>,
  warehouseArrivalReport: $ElementType<Scalars, 'Int'>,
  warehouseInspectionReport: $ElementType<Scalars, 'Int'>,
  miscellaneous: $ElementType<Scalars, 'Int'>,
};

export type ShipmentFilterInput = {
  query?: ?$ElementType<Scalars, 'String'>,
  ownerId?: ?$ElementType<Scalars, 'ID'>,
  excludeIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  createdAt?: ?DateRangeInput,
  updatedAt?: ?DateRangeInput,
  customFields?: ?Array<EntityCustomFieldInput>,
  connectionIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  bulkFilter?: ?ShipmentBulkFilterInput,
  keywords?: ?ValuesWithMatchModeInput,
  autoTracking?: ?$ElementType<Scalars, 'Boolean'>,
  archived?: ?$ElementType<Scalars, 'Boolean'>,
  unseenNotification?: ?$ElementType<Scalars, 'Boolean'>,
  ids?: ?Array<$ElementType<Scalars, 'ID'>>,
  importerId?: ?$ElementType<Scalars, 'ID'>,
  importerIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  exporterIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  sharedPartnerIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  /** Deprecated */
  tagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  tagIdsWithOperator?: ?IdsWithOperatorInput,
  notTagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  forwarderIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  followerIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  booked?: ?$ElementType<Scalars, 'Boolean'>,
  bookingDate?: ?DateRangeInput,
  houseBlNo?: ?$ElementType<Scalars, 'String'>,
  blDate?: ?DateRangeInput,
  loadTypes?: ?Array<LoadType>,
  loadPorts?: ?Array<PortInput>,
  firstTransitPorts?: ?Array<PortInput>,
  secondTransitPorts?: ?Array<PortInput>,
  dischargePorts?: ?Array<PortInput>,
  warehouseIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  cargoReadyWarehouseIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  firstTransitPortDepartureArrivalUpdatedAt?: ?DateRangeInput,
  cargoReadyInitialDate?: ?DateRangeInput,
  cargoReadyResultDate?: ?DateRangeInput,
  cargoReadyCurrentDate?: ?DateRangeInput,
  loadPortDepartureInitialDate?: ?DateRangeInput,
  loadPortDepartureResultDate?: ?DateRangeInput,
  loadPortDepartureCurrentDate?: ?DateRangeInput,
  firstTransitPortArrivalInitialDate?: ?DateRangeInput,
  firstTransitPortArrivalResultDate?: ?DateRangeInput,
  firstTransitPortArrivalCurrentDate?: ?DateRangeInput,
  firstTransitPortDepartureInitialDate?: ?DateRangeInput,
  firstTransitPortDepartureResultDate?: ?DateRangeInput,
  firstTransitPortDepartureCurrentDate?: ?DateRangeInput,
  secondTransitPortArrivalInitialDate?: ?DateRangeInput,
  secondTransitPortArrivalResultDate?: ?DateRangeInput,
  secondTransitPortArrivalCurrentDate?: ?DateRangeInput,
  secondTransitPortDepartureInitialDate?: ?DateRangeInput,
  secondTransitPortDepartureResultDate?: ?DateRangeInput,
  secondTransitPortDepartureCurrentDate?: ?DateRangeInput,
  dischargePortArrivalInitialDate?: ?DateRangeInput,
  dischargePortArrivalResultDate?: ?DateRangeInput,
  dischargePortArrivalCurrentDate?: ?DateRangeInput,
  customClearanceInitialDate?: ?DateRangeInput,
  customClearanceResultDate?: ?DateRangeInput,
  customClearanceCurrentDate?: ?DateRangeInput,
  warehouseArrivalInitialDate?: ?DateRangeInput,
  warehouseArrivalResultDate?: ?DateRangeInput,
  warehouseArrivalCurrentDate?: ?DateRangeInput,
  deliveryReadyInitialDate?: ?DateRangeInput,
  deliveryReadyResultDate?: ?DateRangeInput,
  deliveryReadyCurrentDate?: ?DateRangeInput,
  includeNullTrackingStatus?: ?$ElementType<Scalars, 'Boolean'>,
  trackingStatuses?: ?Array<ShipmentTrackingStatus>,
  containerIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  containerContainerType?: ?$ElementType<Scalars, 'String'>,
  containerContainerOption?: ?ContainerOption,
  containerWarehouseIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  /** Deprecated */
  containerTagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  containerTagIdsWithOperator?: ?IdsWithOperatorInput,
  containerNotTagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  containerCreatedAt?: ?DateRangeInput,
  containerUpdatedAt?: ?DateRangeInput,
  containerWarehouseArrivalAgreedDate?: ?DateRangeInput,
  containerWarehouseArrivalActualDate?: ?DateRangeInput,
  containerFreeTimeDueDate?: ?DateRangeInput,
  containerFreeTimeOverdue?: ?$ElementType<Scalars, 'Boolean'>,
  orderIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  orderArchived?: ?$ElementType<Scalars, 'Boolean'>,
  orderImporterIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  orderExporterIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  orderFollowerIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  /** Deprecated */
  orderTagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  orderTagIdsWithOperator?: ?IdsWithOperatorInput,
  orderNotTagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  orderCompletelyBatched?: ?$ElementType<Scalars, 'Boolean'>,
  orderCompletelyShipped?: ?$ElementType<Scalars, 'Boolean'>,
  orderCreatedAt?: ?DateRangeInput,
  orderUpdatedAt?: ?DateRangeInput,
  orderItemTagIdsWithOperator?: ?IdsWithOperatorInput,
  orderItemNotTagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  batchIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  batchDeliveredAt?: ?DateRangeInput,
  batchDesiredAt?: ?DateRangeInput,
  batchExpiredAt?: ?DateRangeInput,
  batchProducedAt?: ?DateRangeInput,
  batchTotalVolume?: ?MetricRangeInput,
  /** Deprecated */
  batchTagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  batchTagIdsWithOperator?: ?IdsWithOperatorInput,
  batchNotTagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  productId?: ?$ElementType<Scalars, 'ID'>,
  productIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  /** Deprecated */
  productTagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  productTagIdsWithOperator?: ?IdsWithOperatorInput,
  productNotTagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  productProviderIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  productProviderExporterIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  productProviderSupplierIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  productProviderOrigin?: ?Country,
};

export type ShipmentPayload = Shipment | BadRequest | Forbidden | NotFound;

export type ShipmentPayloadPaginatedSearch = {
  ...Paginated,
  ...{
     __typename: 'ShipmentPayloadPaginatedSearch',
    nodes: Array<ShipmentPayload>,
    hits: Array<Hit>,
    page: $ElementType<Scalars, 'Int'>,
    perPage: $ElementType<Scalars, 'Int'>,
    totalPage: $ElementType<Scalars, 'Int'>,
    count: $ElementType<Scalars, 'Int'>,
    totalCount: $ElementType<Scalars, 'Int'>,
  }
};

export type ShipmentSortInput = {
  createdAt?: ?SortOrder,
  updatedAt?: ?SortOrder,
  archived?: ?SortOrder,
  no?: ?SortOrder,
  blNo?: ?SortOrder,
  masterBlNo?: ?SortOrder,
  CargoReadyInitialDate?: ?SortOrder,
  CargoReadyResultDate?: ?SortOrder,
  cargoReadyCurrentDate?: ?SortOrder,
  loadPort?: ?SortOrder,
  loadPortDepartureCurrentDate?: ?SortOrder,
  loadPortDepartureInitialDate?: ?SortOrder,
  loadPortDepartureDateDifference?: ?SortOrder,
  firstTransitPortArrivalCurrentDate?: ?SortOrder,
  firstTransitPortDepartureCurrentDate?: ?SortOrder,
  secondTransitPortArrivalCurrentDate?: ?SortOrder,
  secondTransitPortDepartureCurrentDate?: ?SortOrder,
  dischargePort?: ?SortOrder,
  dischargePortArrivalCurrentDate?: ?SortOrder,
  dischargePortArrivalInitialDate?: ?SortOrder,
  dischargePortArrivalDateDifference?: ?SortOrder,
  exportCustomsClearanceDate?: ?SortOrder,
  cyCfsCut?: ?SortOrder,
  cyCfsArrivalDate?: ?SortOrder,
  vesselName?: ?SortOrder,
  customClearanceCurrentDate?: ?SortOrder,
  warehouseArrivalCurrentDate?: ?SortOrder,
  deliveryReadyCurrentDate?: ?SortOrder,
  importerName?: ?SortOrder,
  invoiceNo?: ?SortOrder,
  exporterName?: ?SortOrder,
  totalVolumeValue?: ?SortOrder,
  totalWeightValue?: ?SortOrder,
  totalPriceAmount?: ?SortOrder,
  customField?: ?GenericSortInput,
};

export const ShipmentTrackingStatusValues = Object.freeze({
  /** Undefined status. */
  ShipmentTrackingStatusUnspecified: 'SHIPMENT_TRACKING_STATUS_UNSPECIFIED', 
  /** Shipment is still being processed. */
  ShipmentTrackingStatusPending: 'SHIPMENT_TRACKING_STATUS_PENDING', 
  /** Shipment is in transit. */
  ShipmentTrackingStatusActive: 'SHIPMENT_TRACKING_STATUS_ACTIVE', 
  /** Shipment is scheduled but not yet started. */
  ShipmentTrackingStatusPlanned: 'SHIPMENT_TRACKING_STATUS_PLANNED', 
  /** Shipment contains incorrect data. */
  ShipmentTrackingStatusInvalid: 'SHIPMENT_TRACKING_STATUS_INVALID', 
  /** Shipment has been completed. */
  ShipmentTrackingStatusCompleted: 'SHIPMENT_TRACKING_STATUS_COMPLETED', 
  /** Shipment has been archived after completion. */
  ShipmentTrackingStatusArchived: 'SHIPMENT_TRACKING_STATUS_ARCHIVED', 
  /** Shipment not found in the tracking system. */
  ShipmentTrackingStatusNotFound: 'SHIPMENT_TRACKING_STATUS_NOT_FOUND', 
  /** Shipment has been delayed beyond the expected date. */
  ShipmentTrackingStatusDelayed: 'SHIPMENT_TRACKING_STATUS_DELAYED'
});


/** Shipment tracking status values */
export type ShipmentTrackingStatus = $Values<typeof ShipmentTrackingStatusValues>;

export type ShipmentUpdateInput = {
  archived?: ?$ElementType<Scalars, 'Boolean'>,
  importerId?: ?$ElementType<Scalars, 'ID'>,
  exporterId?: ?$ElementType<Scalars, 'ID'>,
  no?: ?$ElementType<Scalars, 'String'>,
  blNo?: ?$ElementType<Scalars, 'String'>,
  blDate?: ?$ElementType<Scalars, 'DateTime'>,
  exportCustomsClearance?: ?$ElementType<Scalars, 'DateTime'>,
  cyCfsArrivalDate?: ?$ElementType<Scalars, 'DateTime'>,
  cyCfsCut?: ?$ElementType<Scalars, 'DateTime'>,
  cyCfsOpen?: ?$ElementType<Scalars, 'DateTime'>,
  masterBlNo?: ?$ElementType<Scalars, 'String'>,
  booked?: ?$ElementType<Scalars, 'Boolean'>,
  bookingNo?: ?$ElementType<Scalars, 'String'>,
  bookingDate?: ?$ElementType<Scalars, 'DateTime'>,
  invoiceNo?: ?$ElementType<Scalars, 'String'>,
  contractNo?: ?$ElementType<Scalars, 'String'>,
  loadType?: ?LoadType,
  transportType?: ?TransportType,
  incoterm?: ?Incoterm,
  placeOfReceipt?: ?$ElementType<Scalars, 'String'>,
  placeOfDelivery?: ?$ElementType<Scalars, 'String'>,
  autoTracking?: ?$ElementType<Scalars, 'Boolean'>,
  /** Deprecated */
  autoTrackingBy?: ?AutoTrackingByType,
  autoTrackingIncludeContainers?: ?$ElementType<Scalars, 'Boolean'>,
  autoTrackingTransportType?: ?AutoTrackingTransportType,
  carrier?: ?$ElementType<Scalars, 'String'>,
  trackingStatus?: ?ShipmentTrackingStatus,
  cargoReady?: ?TimelineDateNestedInput,
  /** Deprecated */
  tagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  tags?: ?Array<EntityTagInput>,
  followerIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  forwarderIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  batches?: ?Array<ShipmentBatchInput>,
  voyages?: ?Array<VoyageNestedInput>,
  containerGroups?: ?Array<ContainerGroupNestedInput>,
  containers?: ?Array<ShipmentContainerUpdateInput>,
  files?: ?Array<EntityFileInput>,
  memo?: ?$ElementType<Scalars, 'String'>,
  totalVolumeOverride?: ?MetricValueInput,
  totalVolumeOverriding?: ?$ElementType<Scalars, 'Boolean'>,
  totalVolumeDisplayMetric?: ?$ElementType<Scalars, 'String'>,
  totalWeightOverride?: ?MetricValueInput,
  totalWeightOverriding?: ?$ElementType<Scalars, 'Boolean'>,
  totalWeightDisplayMetric?: ?$ElementType<Scalars, 'String'>,
  totalPackageQuantityOverride?: ?$ElementType<Scalars, 'Float'>,
  totalPackageQuantityOverriding?: ?$ElementType<Scalars, 'Boolean'>,
  customFields?: ?CustomFieldsInput,
  organizationIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  cargoReadyWarehouseId?: ?$ElementType<Scalars, 'ID'>,
};

export type ShipmentUpdateWrapperInput = {
  id: $ElementType<Scalars, 'ID'>,
  input: ShipmentUpdateInput,
};

export type Size = {
   __typename: 'Size',
  length: MetricValue,
  width: MetricValue,
  height: MetricValue,
};

export type SizeInput = {
  length: MetricValueInput,
  width: MetricValueInput,
  height: MetricValueInput,
};

export type SizeValue = {
   __typename: 'SizeValue',
  size: Size,
};

export type Sortable = {
  sort: $ElementType<Scalars, 'Int'>,
};

export const SortOrderValues = Object.freeze({
  Ascending: 'ASCENDING', 
  Descending: 'DESCENDING'
});


export type SortOrder = $Values<typeof SortOrderValues>;

export type StringValue = {
   __typename: 'StringValue',
  string: $ElementType<Scalars, 'String'>,
};

export type Subscription = {
   __typename: 'Subscription',
  messageNew: Message,
  notificationNew: Notification,
  importEvent: ImportEvent,
  exportReady: File,
  entityEvent: EntityEvent,
  focusEvent: FocusEvent,
};


export type SubscriptionMessageNewArgs = {
  entity: EntityInput,
  messageGroupId?: ?$ElementType<Scalars, 'ID'>
};


export type SubscriptionImportEventArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type SubscriptionExportReadyArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type SubscriptionEntityEventArgs = {
  id: $ElementType<Scalars, 'ID'>
};


export type SubscriptionFocusEventArgs = {
  id: $ElementType<Scalars, 'ID'>
};

export type SubscriptionInput = {
  entities: Array<EntityInput>,
};

export type Supervised = {
  inCharges: Array<UserPayload>,
};

export type Tag = {
  ...Model,
  ...Connected,
  ...Owned,
  ...{
     __typename: 'Tag',
    name: $ElementType<Scalars, 'String'>,
    description?: ?$ElementType<Scalars, 'String'>,
    color: $ElementType<Scalars, 'String'>,
    entityTypes: Array<TagEntityType>,
    tagGroups: Array<TagGroupPayload>,
    id: $ElementType<Scalars, 'ID'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    deletedAt?: ?$ElementType<Scalars, 'DateTime'>,
    createdBy?: ?UserPayload,
    updatedBy?: ?UserPayload,
    deletedBy?: ?UserPayload,
    connectionBy?: ?ConnectionPayload,
    ownedBy: OrganizationPayload,
  }
};

export type TagCreateInput = {
  connectionId: $ElementType<Scalars, 'ID'>,
  name: $ElementType<Scalars, 'String'>,
  color?: ?$ElementType<Scalars, 'String'>,
  description?: ?$ElementType<Scalars, 'String'>,
  entityTypes: Array<TagEntityType>,
};

export const TagEntityTypeValues = Object.freeze({
  /** Product */
  Product: 'Product', 
  /** Order */
  Order: 'Order', 
  /** OrderItem */
  OrderItem: 'OrderItem', 
  /** Batch */
  Batch: 'Batch', 
  /** Shipment */
  Shipment: 'Shipment', 
  /** Container */
  Container: 'Container', 
  /** User */
  User: 'User', 
  /** Partner */
  Partner: 'Partner', 
  /** File */
  File: 'File'
});


export type TagEntityType = $Values<typeof TagEntityTypeValues>;

export type TagFilterInput = {
  query?: ?$ElementType<Scalars, 'String'>,
  ownerId?: ?$ElementType<Scalars, 'ID'>,
  excludeIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  createdAt?: ?DateRangeInput,
  updatedAt?: ?DateRangeInput,
  connectionIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  organizationId?: ?$ElementType<Scalars, 'ID'>,
  organizationIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  /** deprecated */
  includeAllShared?: ?$ElementType<Scalars, 'Boolean'>,
  name?: ?$ElementType<Scalars, 'String'>,
  entityTypes?: ?Array<TagEntityType>,
};

export type Tagged = {
  tags: Array<TagPayload>,
};

export type TagGroup = {
  ...Model,
  ...Owned,
  ...Tagged,
  ...{
     __typename: 'TagGroup',
    name: $ElementType<Scalars, 'String'>,
    description?: ?$ElementType<Scalars, 'String'>,
    organizationGroups: Array<OrganizationGroupPayload>,
    id: $ElementType<Scalars, 'ID'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    deletedAt?: ?$ElementType<Scalars, 'DateTime'>,
    createdBy?: ?UserPayload,
    updatedBy?: ?UserPayload,
    deletedBy?: ?UserPayload,
    ownedBy: OrganizationPayload,
    tags: Array<TagPayload>,
  }
};

export type TagGroupCreateInput = {
  name: $ElementType<Scalars, 'String'>,
  description?: ?$ElementType<Scalars, 'String'>,
  /** Deprecated */
  tagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  tags?: ?Array<EntityTagInput>,
  organizationGroupIds?: ?Array<$ElementType<Scalars, 'ID'>>,
};

export type TagGroupFilterInput = {
  query?: ?$ElementType<Scalars, 'String'>,
  ownerId?: ?$ElementType<Scalars, 'ID'>,
  excludeIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  createdAt?: ?DateRangeInput,
  updatedAt?: ?DateRangeInput,
  organizationGroupIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  tagIdsWithOperator?: ?IdsWithOperatorInput,
  notTagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
};

export type TagGroupPayload = TagGroup | BadRequest | Forbidden | NotFound;

export type TagGroupPayloadPaginatedSearch = {
  ...Paginated,
  ...{
     __typename: 'TagGroupPayloadPaginatedSearch',
    nodes: Array<TagGroupPayload>,
    hits: Array<Hit>,
    page: $ElementType<Scalars, 'Int'>,
    perPage: $ElementType<Scalars, 'Int'>,
    totalPage: $ElementType<Scalars, 'Int'>,
    count: $ElementType<Scalars, 'Int'>,
    totalCount: $ElementType<Scalars, 'Int'>,
  }
};

export type TagGroupSortInput = {
  createdAt?: ?SortOrder,
  updatedAt?: ?SortOrder,
  name?: ?SortOrder,
};

export type TagGroupUpdateInput = {
  name?: ?$ElementType<Scalars, 'ID'>,
  description?: ?$ElementType<Scalars, 'String'>,
  /** Deprecated */
  tagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  tags?: ?Array<EntityTagInput>,
  organizationGroupIds?: ?Array<$ElementType<Scalars, 'ID'>>,
};

export type TagPayload = Tag | BadRequest | Forbidden | NotFound;

export type TagPayloadPaginatedSearch = {
  ...Paginated,
  ...{
     __typename: 'TagPayloadPaginatedSearch',
    nodes: Array<TagPayload>,
    hits: Array<Hit>,
    page: $ElementType<Scalars, 'Int'>,
    perPage: $ElementType<Scalars, 'Int'>,
    totalPage: $ElementType<Scalars, 'Int'>,
    count: $ElementType<Scalars, 'Int'>,
    totalCount: $ElementType<Scalars, 'Int'>,
  }
};

export type TagSortInput = {
  createdAt?: ?SortOrder,
  updatedAt?: ?SortOrder,
  name?: ?SortOrder,
};

export type TagUpdateInput = {
  name?: ?$ElementType<Scalars, 'ID'>,
  color?: ?$ElementType<Scalars, 'String'>,
  description?: ?$ElementType<Scalars, 'String'>,
  entityTypes?: ?Array<TagEntityType>,
};

export type Team = {
  ...Model,
  ...{
     __typename: 'Team',
    key: $ElementType<Scalars, 'String'>,
    users: Array<UserPayload>,
    roles: Array<RolePayload>,
    organization: OrganizationPayload,
    connectionBy: ConnectionPayload,
    id: $ElementType<Scalars, 'ID'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    deletedAt?: ?$ElementType<Scalars, 'DateTime'>,
    createdBy?: ?UserPayload,
    updatedBy?: ?UserPayload,
    deletedBy?: ?UserPayload,
  }
};

export type TeamFilterInput = {
  organizationId?: ?$ElementType<Scalars, 'ID'>,
};

export type TeamPayload = Team | BadRequest | Forbidden | NotFound;

export type TeamPayloadPaginatedList = {
  ...Paginated,
  ...{
     __typename: 'TeamPayloadPaginatedList',
    nodes: Array<TeamPayload>,
    page: $ElementType<Scalars, 'Int'>,
    perPage: $ElementType<Scalars, 'Int'>,
    totalPage: $ElementType<Scalars, 'Int'>,
    count: $ElementType<Scalars, 'Int'>,
    totalCount: $ElementType<Scalars, 'Int'>,
  }
};

export type Timeline = {
   __typename: 'Timeline',
  unreadCount: $ElementType<Scalars, 'Int'>,
  unreadMessageCount: $ElementType<Scalars, 'Int'>,
  entries: TimelineEntryPaginatedList,
};


export type TimelineEntriesArgs = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>
};

export type TimelineComment = {
  ...Model,
  ...Owned,
  ...{
     __typename: 'TimelineComment',
    entity: EntityPayload,
    content: $ElementType<Scalars, 'String'>,
    mentions: Array<UserPayload>,
    id: $ElementType<Scalars, 'ID'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    deletedAt?: ?$ElementType<Scalars, 'DateTime'>,
    createdBy?: ?UserPayload,
    updatedBy?: ?UserPayload,
    deletedBy?: ?UserPayload,
    ownedBy: OrganizationPayload,
  }
};

export type TimelineCommentPayload = TimelineComment | BadRequest | Forbidden | NotFound;

export type TimelineDate = {
  ...Model,
  ...Connected,
  ...Owned,
  ...Memorizable,
  ...{
     __typename: 'TimelineDate',
    date?: ?$ElementType<Scalars, 'DateTime'>,
    currentDate?: ?$ElementType<Scalars, 'DateTime'>,
    latestDate?: ?$ElementType<Scalars, 'DateTime'>,
    assignedTo: Array<UserPayload>,
    approvedBy?: ?UserPayload,
    approvedAt?: ?$ElementType<Scalars, 'DateTime'>,
    resultDate?: ?$ElementType<Scalars, 'DateTime'>,
    timelineDateRevisions: Array<TimelineDateRevisionPayload>,
    id: $ElementType<Scalars, 'ID'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    deletedAt?: ?$ElementType<Scalars, 'DateTime'>,
    createdBy?: ?UserPayload,
    updatedBy?: ?UserPayload,
    deletedBy?: ?UserPayload,
    connectionBy?: ?ConnectionPayload,
    ownedBy: OrganizationPayload,
    memo?: ?$ElementType<Scalars, 'String'>,
  }
};

export type TimelineDateNestedInput = {
  date?: ?$ElementType<Scalars, 'DateTime'>,
  assignedToIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  approvedById?: ?$ElementType<Scalars, 'ID'>,
  resultDate?: ?$ElementType<Scalars, 'DateTime'>,
  memo?: ?$ElementType<Scalars, 'String'>,
  timelineDateRevisions?: ?Array<TimelineDateRevisionInput>,
};

export type TimelineDatePayload = TimelineDate | BadRequest | Forbidden | NotFound;

export type TimelineDateRevision = {
  ...Model,
  ...Connected,
  ...Owned,
  ...Sortable,
  ...Memorizable,
  ...{
     __typename: 'TimelineDateRevision',
    date: $ElementType<Scalars, 'DateTime'>,
    type: TimelineDateRevisionType,
    timelineDate: TimelineDatePayload,
    id: $ElementType<Scalars, 'ID'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    deletedAt?: ?$ElementType<Scalars, 'DateTime'>,
    createdBy?: ?UserPayload,
    updatedBy?: ?UserPayload,
    deletedBy?: ?UserPayload,
    connectionBy?: ?ConnectionPayload,
    ownedBy: OrganizationPayload,
    sort: $ElementType<Scalars, 'Int'>,
    memo?: ?$ElementType<Scalars, 'String'>,
  }
};

export type TimelineDateRevisionInput = {
  id?: ?$ElementType<Scalars, 'ID'>,
  date?: ?$ElementType<Scalars, 'DateTime'>,
  type?: ?TimelineDateRevisionType,
  memo?: ?$ElementType<Scalars, 'String'>,
};

export type TimelineDateRevisionPayload = TimelineDateRevision | BadRequest | Forbidden | NotFound;

export const TimelineDateRevisionTypeValues = Object.freeze({
  /** Delay */
  Delay: 'Delay', 
  /** Ahead */
  Ahead: 'Ahead', 
  /** Other */
  Other: 'Other'
});


export type TimelineDateRevisionType = $Values<typeof TimelineDateRevisionTypeValues>;

export type TimelineEntry = TimelineEvent | TimelineComment | BadRequest | Forbidden | NotFound;

export type TimelineEntryFilterInput = {
  query?: ?$ElementType<Scalars, 'String'>,
  ownerId?: ?$ElementType<Scalars, 'ID'>,
  excludeIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  createdAt?: ?DateRangeInput,
  updatedAt?: ?DateRangeInput,
  isComment?: ?$ElementType<Scalars, 'Boolean'>,
  isEntityRemoved?: ?$ElementType<Scalars, 'Boolean'>,
  entityId?: ?$ElementType<Scalars, 'ID'>,
  entityType?: ?$ElementType<Scalars, 'String'>,
};

export type TimelineEntryPaginatedList = {
  ...Paginated,
  ...{
     __typename: 'TimelineEntryPaginatedList',
    nodes: Array<TimelineEntry>,
    page: $ElementType<Scalars, 'Int'>,
    perPage: $ElementType<Scalars, 'Int'>,
    totalPage: $ElementType<Scalars, 'Int'>,
    count: $ElementType<Scalars, 'Int'>,
    totalCount: $ElementType<Scalars, 'Int'>,
  }
};

export type TimelineEntryPaginatedSearch = {
  ...Paginated,
  ...{
     __typename: 'TimelineEntryPaginatedSearch',
    nodes: Array<TimelineEntry>,
    hits: Array<Hit>,
    page: $ElementType<Scalars, 'Int'>,
    perPage: $ElementType<Scalars, 'Int'>,
    totalPage: $ElementType<Scalars, 'Int'>,
    count: $ElementType<Scalars, 'Int'>,
    totalCount: $ElementType<Scalars, 'Int'>,
  }
};

export type TimelineEntrySortInput = {
  createdAt?: ?SortOrder,
  updatedAt?: ?SortOrder,
};

export type TimelineEvent = {
  ...Model,
  ...Owned,
  ...{
     __typename: 'TimelineEvent',
    entity: EntityPayload,
    logs: Array<Log>,
    id: $ElementType<Scalars, 'ID'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    deletedAt?: ?$ElementType<Scalars, 'DateTime'>,
    createdBy?: ?UserPayload,
    updatedBy?: ?UserPayload,
    deletedBy?: ?UserPayload,
    ownedBy: OrganizationPayload,
  }
};

export type Token = {
   __typename: 'Token',
  token: $ElementType<Scalars, 'String'>,
};

export type TokenPayload = Token | BadRequest | Forbidden | NotFound;

export const TransportCodeTypeValues = Object.freeze({
  /** UNLO */
  Unlo: 'UNLO', 
  /** IATA */
  Iata: 'IATA'
});


export type TransportCodeType = $Values<typeof TransportCodeTypeValues>;

export const TransportTypeValues = Object.freeze({
  /** Air */
  Air: 'Air', 
  /** Sea */
  Sea: 'Sea', 
  /** Rail */
  Rail: 'Rail', 
  /** Road */
  Road: 'Road', 
  /** Dry */
  Dry: 'Dry'
});


export type TransportType = $Values<typeof TransportTypeValues>;


export type User = {
  ...Model,
  ...Tagged,
  ...Memorizable,
  ...{
     __typename: 'User',
    email: $ElementType<Scalars, 'String'>,
    connections: Array<ConnectionPayload>,
    organization?: ?OrganizationPayload,
    superAdmin: $ElementType<Scalars, 'Boolean'>,
    roles: Array<RolePayload>,
    role: $ElementType<Scalars, 'String'>,
    disabled: $ElementType<Scalars, 'Boolean'>,
    firstName: $ElementType<Scalars, 'String'>,
    lastName: $ElementType<Scalars, 'String'>,
    firstName2?: ?$ElementType<Scalars, 'String'>,
    lastName2?: ?$ElementType<Scalars, 'String'>,
    language: Language,
    timezone: $ElementType<Scalars, 'String'>,
    mfaType?: ?$ElementType<Scalars, 'String'>,
    teams: Array<TeamPayload>,
    id: $ElementType<Scalars, 'ID'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    deletedAt?: ?$ElementType<Scalars, 'DateTime'>,
    createdBy?: ?UserPayload,
    updatedBy?: ?UserPayload,
    deletedBy?: ?UserPayload,
    tags: Array<TagPayload>,
    memo?: ?$ElementType<Scalars, 'String'>,
  }
};

export type UserBulkFilterInput = {
  emails?: ?ValuesWithMatchModeInput,
};

export type UserFilterInput = {
  query?: ?$ElementType<Scalars, 'String'>,
  ownerId?: ?$ElementType<Scalars, 'ID'>,
  excludeIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  createdAt?: ?DateRangeInput,
  updatedAt?: ?DateRangeInput,
  connectionIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  bulkFilter?: ?UserBulkFilterInput,
  organizationId?: ?$ElementType<Scalars, 'ID'>,
  organizationIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  /** Deprecated */
  tagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  tagIdsWithOperator?: ?IdsWithOperatorInput,
  notTagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
};

export type UserPayload = User | BadRequest | Forbidden | NotFound;

export type UserPayloadPaginatedSearch = {
  ...Paginated,
  ...{
     __typename: 'UserPayloadPaginatedSearch',
    nodes: Array<UserPayload>,
    hits: Array<Hit>,
    page: $ElementType<Scalars, 'Int'>,
    perPage: $ElementType<Scalars, 'Int'>,
    totalPage: $ElementType<Scalars, 'Int'>,
    count: $ElementType<Scalars, 'Int'>,
    totalCount: $ElementType<Scalars, 'Int'>,
  }
};

export type UserSortInput = {
  createdAt?: ?SortOrder,
  updatedAt?: ?SortOrder,
  firstName?: ?SortOrder,
  lastName?: ?SortOrder,
  fullName?: ?SortOrder,
};

export type UserUpdateInput = {
  firstName?: ?$ElementType<Scalars, 'String'>,
  lastName?: ?$ElementType<Scalars, 'String'>,
  firstName2?: ?$ElementType<Scalars, 'String'>,
  lastName2?: ?$ElementType<Scalars, 'String'>,
  email?: ?$ElementType<Scalars, 'String'>,
  password?: ?$ElementType<Scalars, 'String'>,
  superAdmin?: ?$ElementType<Scalars, 'Boolean'>,
  roleIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  disabled?: ?$ElementType<Scalars, 'Boolean'>,
  language?: ?Language,
  timezone?: ?$ElementType<Scalars, 'String'>,
  organizationId?: ?$ElementType<Scalars, 'ID'>,
  memo?: ?$ElementType<Scalars, 'String'>,
  /** Deprecated */
  tagIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  tags?: ?Array<EntityTagInput>,
  connectionIds?: ?Array<$ElementType<Scalars, 'ID'>>,
};

export type Value = StringValue | IntValue | FloatValue | BooleanValue | DateTimeValue | IntervalValue | DurationValue | MetricValueValue | PriceValue | SizeValue | EntityValue | Values;

export type ValueInput = {
  string?: ?$ElementType<Scalars, 'String'>,
  int?: ?$ElementType<Scalars, 'Int'>,
  float?: ?$ElementType<Scalars, 'Float'>,
  boolean?: ?$ElementType<Scalars, 'Boolean'>,
};

export type Values = {
   __typename: 'Values',
  values: Array<?Value>,
};

export const ValuesWithMatchModeValues = Object.freeze({
  /** Partial */
  Partial: 'Partial', 
  /** Exactly */
  Exactly: 'Exactly'
});


export type ValuesWithMatchMode = $Values<typeof ValuesWithMatchModeValues>;

export type ValuesWithMatchModeInput = {
  values?: ?Array<$ElementType<Scalars, 'String'>>,
  matchMode?: ?ValuesWithMatchMode,
};

export type Viewer = {
   __typename: 'Viewer',
  user: UserPayload,
  permissions: Array<$ElementType<Scalars, 'String'>>,
  permissionsForOrganization: Array<$ElementType<Scalars, 'String'>>,
  notificationCount: $ElementType<Scalars, 'Int'>,
  notificationUnseenCount: $ElementType<Scalars, 'Int'>,
  notificationPreferences: NotificationPreferences,
  messagePreferences: MessagePreferences,
};


export type ViewerPermissionsArgs = {
  connectionId: $ElementType<Scalars, 'ID'>
};


export type ViewerPermissionsForOrganizationArgs = {
  connectionId: $ElementType<Scalars, 'ID'>,
  organizationId: $ElementType<Scalars, 'ID'>
};

export type ViewState = {
  ...Model,
  ...Owned,
  ...{
     __typename: 'ViewState',
    type: ViewStateType,
    filterSort?: ?$ElementType<Scalars, 'String'>,
    id: $ElementType<Scalars, 'ID'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    deletedAt?: ?$ElementType<Scalars, 'DateTime'>,
    createdBy?: ?UserPayload,
    updatedBy?: ?UserPayload,
    deletedBy?: ?UserPayload,
    ownedBy: OrganizationPayload,
  }
};

export type ViewStatePayload = ViewState | BadRequest | Forbidden | NotFound;

export const ViewStateTypeValues = Object.freeze({
  /** OrderMap */
  OrderMap: 'OrderMap', 
  /** ShipmentMap */
  ShipmentMap: 'ShipmentMap', 
  /** OrderCard */
  OrderCard: 'OrderCard', 
  /** OrderItemCard */
  OrderItemCard: 'OrderItemCard', 
  /** ShipmentCard */
  ShipmentCard: 'ShipmentCard', 
  /** BatchCard */
  BatchCard: 'BatchCard', 
  /** ContainerCard */
  ContainerCard: 'ContainerCard', 
  /** ProductCard */
  ProductCard: 'ProductCard', 
  /** DocumentCard */
  DocumentCard: 'DocumentCard', 
  /** DocumentFolderCard */
  DocumentFolderCard: 'DocumentFolderCard'
});


export type ViewStateType = $Values<typeof ViewStateTypeValues>;

export type ViewStateUpdateInput = {
  type?: ?ViewStateType,
  filterSort?: ?$ElementType<Scalars, 'String'>,
};

export type Violation = {
   __typename: 'Violation',
  message: $ElementType<Scalars, 'String'>,
  error: $ElementType<Scalars, 'String'>,
  code: $ElementType<Scalars, 'String'>,
  path: $ElementType<Scalars, 'String'>,
  parameters: Array<ViolationParameter>,
};

export type ViolationParameter = {
   __typename: 'ViolationParameter',
  key: $ElementType<Scalars, 'String'>,
  value: $ElementType<Scalars, 'String'>,
};


export type Voyage = {
  ...Connected,
  ...Model,
  ...Owned,
  ...Sortable,
  ...{
     __typename: 'Voyage',
    no?: ?$ElementType<Scalars, 'String'>,
    vesselName?: ?$ElementType<Scalars, 'String'>,
    vesselCode?: ?$ElementType<Scalars, 'String'>,
    departurePort?: ?Port,
    departure: TimelineDatePayload,
    arrivalPort?: ?Port,
    arrival: TimelineDatePayload,
    shipment: ShipmentPayload,
    connectionBy?: ?ConnectionPayload,
    id: $ElementType<Scalars, 'ID'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    deletedAt?: ?$ElementType<Scalars, 'DateTime'>,
    createdBy?: ?UserPayload,
    updatedBy?: ?UserPayload,
    deletedBy?: ?UserPayload,
    ownedBy: OrganizationPayload,
    sort: $ElementType<Scalars, 'Int'>,
  }
};

export type VoyageNestedInput = {
  no?: ?$ElementType<Scalars, 'String'>,
  vesselName?: ?$ElementType<Scalars, 'String'>,
  vesselCode?: ?$ElementType<Scalars, 'String'>,
  departurePort?: ?PortInput,
  arrivalPort?: ?PortInput,
  departure?: ?TimelineDateNestedInput,
  arrival?: ?TimelineDateNestedInput,
  id?: ?$ElementType<Scalars, 'ID'>,
};

export type VoyagePayload = Voyage | BadRequest | Forbidden | NotFound;

export type Warehouse = {
  ...Model,
  ...Owned,
  ...Supervised,
  ...Followed,
  ...Customizable,
  ...{
     __typename: 'Warehouse',
    archived: $ElementType<Scalars, 'Boolean'>,
    name: $ElementType<Scalars, 'String'>,
    no?: ?$ElementType<Scalars, 'String'>,
    surface?: ?MetricValue,
    street?: ?$ElementType<Scalars, 'String'>,
    locality?: ?$ElementType<Scalars, 'String'>,
    region?: ?$ElementType<Scalars, 'String'>,
    postalCode?: ?$ElementType<Scalars, 'String'>,
    country?: ?Country,
    organizations: Array<OrganizationPayload>,
    id: $ElementType<Scalars, 'ID'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    deletedAt?: ?$ElementType<Scalars, 'DateTime'>,
    createdBy?: ?UserPayload,
    updatedBy?: ?UserPayload,
    deletedBy?: ?UserPayload,
    ownedBy: OrganizationPayload,
    inCharges: Array<UserPayload>,
    followers: Array<UserPayload>,
    notificationUnseen: Array<NotificationPayload>,
    notificationUnseenCount: $ElementType<Scalars, 'Int'>,
    customFields: CustomFields,
  }
};

export type WarehouseCreateInput = {
  name: $ElementType<Scalars, 'String'>,
  no?: ?$ElementType<Scalars, 'String'>,
  surface?: ?MetricValueInput,
  street?: ?$ElementType<Scalars, 'String'>,
  locality?: ?$ElementType<Scalars, 'String'>,
  region?: ?$ElementType<Scalars, 'String'>,
  postalCode?: ?$ElementType<Scalars, 'String'>,
  country?: ?Country,
  customFields?: ?CustomFieldsInput,
  followerIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  organizationIds?: ?Array<$ElementType<Scalars, 'ID'>>,
};

export type WarehouseFilterInput = {
  query?: ?$ElementType<Scalars, 'String'>,
  ownerId?: ?$ElementType<Scalars, 'ID'>,
  excludeIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  createdAt?: ?DateRangeInput,
  updatedAt?: ?DateRangeInput,
  archived?: ?$ElementType<Scalars, 'Boolean'>,
};

export type WarehousePayload = Warehouse | BadRequest | Forbidden | NotFound;

export type WarehousePayloadPaginatedSearch = {
  ...Paginated,
  ...{
     __typename: 'WarehousePayloadPaginatedSearch',
    nodes: Array<WarehousePayload>,
    hits: Array<Hit>,
    page: $ElementType<Scalars, 'Int'>,
    perPage: $ElementType<Scalars, 'Int'>,
    totalPage: $ElementType<Scalars, 'Int'>,
    count: $ElementType<Scalars, 'Int'>,
    totalCount: $ElementType<Scalars, 'Int'>,
  }
};

export type WarehouseSortInput = {
  createdAt?: ?SortOrder,
  updatedAt?: ?SortOrder,
};

export type WarehouseUpdateInput = {
  archived?: ?$ElementType<Scalars, 'Boolean'>,
  name?: ?$ElementType<Scalars, 'String'>,
  no?: ?$ElementType<Scalars, 'String'>,
  surface?: ?MetricValueInput,
  street?: ?$ElementType<Scalars, 'String'>,
  locality?: ?$ElementType<Scalars, 'String'>,
  region?: ?$ElementType<Scalars, 'String'>,
  postalCode?: ?$ElementType<Scalars, 'String'>,
  country?: ?Country,
  customFields?: ?CustomFieldsInput,
  followerIds?: ?Array<$ElementType<Scalars, 'ID'>>,
  organizationIds?: ?Array<$ElementType<Scalars, 'ID'>>,
};

export type WarehouseUpdateWrapperInput = {
  id: $ElementType<Scalars, 'ID'>,
  input: WarehouseUpdateInput,
};

// $FlowIgnore (this util type inevitably creates a bug)
type $Pick<Origin: Object, Keys: Object> = $ObjMapi<Keys, <Key>(k: Key) => $ElementType<Origin, Key>>;

export type LogFragment = ({
    ...{| __typename: 'ActivityLog' |},
  ...$Pick<ActivityLog, { id: *, updatedAt: *, createdAt: *, translationKey: * }>,
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, createdBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { firstName: *, lastName: *, id: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: ({
      ...{| __typename: 'Product' |},
    ...$Pick<Product, { id: * }>
  }) | ({
      ...{| __typename: 'ProductProvider' |},
    ...$Pick<ProductProvider, { id: * }>
  }) | ({
      ...{| __typename: 'ProductProviderPackage' |},
    ...$Pick<ProductProviderPackage, { id: * }>
  }) | ({
      ...{| __typename: 'Order' |},
    ...$Pick<Order, { id: * }>
  }) | ({
      ...{| __typename: 'OrderItem' |},
    ...$Pick<OrderItem, { id: * }>
  }) | ({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { id: * }>
  }) | ({
      ...{| __typename: 'Shipment' |},
    ...$Pick<Shipment, { id: * }>
  }) | ({
      ...{| __typename: 'Voyage' |},
    ...$Pick<Voyage, { id: * }>
  }) | ({
      ...{| __typename: 'Container' |},
    ...$Pick<Container, { id: * }>
  }) | ({
      ...{| __typename: 'ContainerGroup' |},
    ...$Pick<ContainerGroup, { id: * }>
  }) | ({
      ...{| __typename: 'TimelineDate' |},
    ...$Pick<TimelineDate, { id: * }>
  }) | ({
      ...{| __typename: 'TimelineDateRevision' |},
    ...$Pick<TimelineDateRevision, { id: * }>
  }) | ({
      ...{| __typename: 'Warehouse' |},
    ...$Pick<Warehouse, { id: * }>
  }) | ({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: * }>
  }) | ({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: * }>
  }) | ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | ({
      ...{| __typename: 'Partnership' |},
    ...$Pick<Partnership, { id: * }>
  }) | ({
      ...{| __typename: 'Role' |},
    ...$Pick<Role, { id: * }>
  }) | ({
      ...{| __typename: 'File' |},
    ...$Pick<File, { id: *, type: * }>
  }) | ({
      ...{| __typename: 'Mask' |},
    ...$Pick<Mask, { id: * }>
  }) | ({
      ...{| __typename: 'MaskEdit' |},
    ...$Pick<MaskEdit, { id: * }>
  }) | ({
      ...{| __typename: 'FieldDefinition' |},
    ...$Pick<FieldDefinition, { id: * }>
  }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, parameters: Array<({
      ...{| __typename: 'Parameter' |},
    ...$Pick<Parameter, { key: * }>,
    ...{ value?: ?({
        ...{| __typename: 'StringValue' |},
      ...$Pick<StringValue, { string: * }>
    }) | ({
        ...{| __typename: 'IntValue' |},
      ...$Pick<IntValue, { int: * }>
    }) | ({
        ...{| __typename: 'FloatValue' |},
      ...$Pick<FloatValue, { float: * }>
    }) | ({
        ...{| __typename: 'BooleanValue' |},
      ...$Pick<BooleanValue, { boolean: * }>
    }) | ({
        ...{| __typename: 'DateTimeValue' |},
      ...$Pick<DateTimeValue, { datetime: * }>
    }) | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | ({
        ...{| __typename: 'MetricValueValue' |},
      ...{ metricValue: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }) }
    }) | {| __typename: 'PriceValue' |} | ({
        ...{| __typename: 'SizeValue' |},
      ...{ size: ({
          ...{| __typename: 'Size' |},
        ...{ height: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), length: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), width: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }) }
      }) }
    }) | ({
        ...{| __typename: 'EntityValue' |},
      ...{ entity?: ?({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: * }>
      }) | ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: * }>
      }) | ({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: * }>
      }) | ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: * }>
      }) | ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: * }>
      }) | ({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>
      }) | ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: * }>
      }) | ({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: * }>
      }) | ({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: * }>
      }) | ({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>
      }) | ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: * }>
      }) | ({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: * }>
      }) | ({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: * }>
      }) | ({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>
      }) | ({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: * }>
      }) | ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | ({
          ...{| __typename: 'Partnership' |},
        ...$Pick<Partnership, { id: * }>
      }) | ({
          ...{| __typename: 'Role' |},
        ...$Pick<Role, { id: * }>
      }) | ({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: * }>
      }) | ({
          ...{| __typename: 'Mask' |},
        ...$Pick<Mask, { id: * }>
      }) | ({
          ...{| __typename: 'MaskEdit' |},
        ...$Pick<MaskEdit, { id: * }>
      }) | ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>
      }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Values' |},
      ...{ values: Array<?({
          ...{| __typename: 'StringValue' |},
        ...$Pick<StringValue, { string: * }>
      }) | ({
          ...{| __typename: 'IntValue' |},
        ...$Pick<IntValue, { int: * }>
      }) | ({
          ...{| __typename: 'FloatValue' |},
        ...$Pick<FloatValue, { float: * }>
      }) | ({
          ...{| __typename: 'BooleanValue' |},
        ...$Pick<BooleanValue, { boolean: * }>
      }) | ({
          ...{| __typename: 'DateTimeValue' |},
        ...$Pick<DateTimeValue, { datetime: * }>
      }) | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | ({
          ...{| __typename: 'MetricValueValue' |},
        ...{ metricValue: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }) }
      }) | {| __typename: 'PriceValue' |} | ({
          ...{| __typename: 'SizeValue' |},
        ...{ size: ({
            ...{| __typename: 'Size' |},
          ...{ height: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), length: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), width: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }) }
        }) }
      }) | ({
          ...{| __typename: 'EntityValue' |},
        ...{ entity?: ?({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: * }>
        }) | ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: * }>
        }) | ({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: * }>
        }) | ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: * }>
        }) | ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>
        }) | ({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>
        }) | ({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: * }>
        }) | ({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: * }>
        }) | ({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: * }>
        }) | ({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>
        }) | ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: * }>
        }) | ({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: * }>
        }) | ({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: * }>
        }) | ({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>
        }) | ({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: * }>
        }) | ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | ({
            ...{| __typename: 'Partnership' |},
          ...$Pick<Partnership, { id: * }>
        }) | ({
            ...{| __typename: 'Role' |},
          ...$Pick<Role, { id: * }>
        }) | ({
            ...{| __typename: 'File' |},
          ...$Pick<File, { id: * }>
        }) | ({
            ...{| __typename: 'Mask' |},
          ...$Pick<Mask, { id: * }>
        }) | ({
            ...{| __typename: 'MaskEdit' |},
          ...$Pick<MaskEdit, { id: * }>
        }) | ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: * }>
        }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'Values' |}> }
    }) }
  })> }
});

export type ActivityLogCreateMutationVariables = {
  input: ActivityLogCreateInput
};


export type ActivityLogCreateMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ activityLogCreate: {| __typename: 'ActivityLog' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type ActivityLogsQueryVariables = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?ActivityLogFilterInput,
  sortBy?: ?ActivityLogSortInput
};


export type ActivityLogsQuery = ({
    ...{| __typename: 'Query' |},
  ...{ activityLogs: ({
      ...{| __typename: 'ActivityLogPayloadPaginatedSearch' |},
    ...$Pick<ActivityLogPayloadPaginatedSearch, { page: *, totalPage: * }>,
    ...{ nodes: Array<({
        ...{| __typename: 'ActivityLog' |},
      ...$Pick<ActivityLog, { id: *, updatedAt: *, createdAt: *, translationKey: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, createdBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { firstName: *, lastName: *, id: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: ({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: * }>
      }) | ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: * }>
      }) | ({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: * }>
      }) | ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: * }>
      }) | ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: * }>
      }) | ({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>
      }) | ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: * }>
      }) | ({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: * }>
      }) | ({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: * }>
      }) | ({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>
      }) | ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: * }>
      }) | ({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: * }>
      }) | ({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: * }>
      }) | ({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: * }>
      }) | ({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | ({
          ...{| __typename: 'Partnership' |},
        ...$Pick<Partnership, { id: * }>
      }) | ({
          ...{| __typename: 'Role' |},
        ...$Pick<Role, { id: * }>
      }) | ({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: *, type: * }>
      }) | ({
          ...{| __typename: 'Mask' |},
        ...$Pick<Mask, { id: * }>
      }) | ({
          ...{| __typename: 'MaskEdit' |},
        ...$Pick<MaskEdit, { id: * }>
      }) | ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>
      }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, parameters: Array<({
          ...{| __typename: 'Parameter' |},
        ...$Pick<Parameter, { key: * }>,
        ...{ value?: ?({
            ...{| __typename: 'StringValue' |},
          ...$Pick<StringValue, { string: * }>
        }) | ({
            ...{| __typename: 'IntValue' |},
          ...$Pick<IntValue, { int: * }>
        }) | ({
            ...{| __typename: 'FloatValue' |},
          ...$Pick<FloatValue, { float: * }>
        }) | ({
            ...{| __typename: 'BooleanValue' |},
          ...$Pick<BooleanValue, { boolean: * }>
        }) | ({
            ...{| __typename: 'DateTimeValue' |},
          ...$Pick<DateTimeValue, { datetime: * }>
        }) | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | ({
            ...{| __typename: 'MetricValueValue' |},
          ...{ metricValue: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }) }
        }) | {| __typename: 'PriceValue' |} | ({
            ...{| __typename: 'SizeValue' |},
          ...{ size: ({
              ...{| __typename: 'Size' |},
            ...{ height: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), length: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), width: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }) }
          }) }
        }) | ({
            ...{| __typename: 'EntityValue' |},
          ...{ entity?: ?({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: * }>
          }) | ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>
          }) | ({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: * }>
          }) | ({
              ...{| __typename: 'Order' |},
            ...$Pick<Order, { id: * }>
          }) | ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>
          }) | ({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: * }>
          }) | ({
              ...{| __typename: 'Shipment' |},
            ...$Pick<Shipment, { id: * }>
          }) | ({
              ...{| __typename: 'Voyage' |},
            ...$Pick<Voyage, { id: * }>
          }) | ({
              ...{| __typename: 'Container' |},
            ...$Pick<Container, { id: * }>
          }) | ({
              ...{| __typename: 'ContainerGroup' |},
            ...$Pick<ContainerGroup, { id: * }>
          }) | ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: * }>
          }) | ({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: * }>
          }) | ({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: * }>
          }) | ({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>
          }) | ({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: * }>
          }) | ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: * }>
          }) | ({
              ...{| __typename: 'Partnership' |},
            ...$Pick<Partnership, { id: * }>
          }) | ({
              ...{| __typename: 'Role' |},
            ...$Pick<Role, { id: * }>
          }) | ({
              ...{| __typename: 'File' |},
            ...$Pick<File, { id: * }>
          }) | ({
              ...{| __typename: 'Mask' |},
            ...$Pick<Mask, { id: * }>
          }) | ({
              ...{| __typename: 'MaskEdit' |},
            ...$Pick<MaskEdit, { id: * }>
          }) | ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: * }>
          }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Values' |},
          ...{ values: Array<?({
              ...{| __typename: 'StringValue' |},
            ...$Pick<StringValue, { string: * }>
          }) | ({
              ...{| __typename: 'IntValue' |},
            ...$Pick<IntValue, { int: * }>
          }) | ({
              ...{| __typename: 'FloatValue' |},
            ...$Pick<FloatValue, { float: * }>
          }) | ({
              ...{| __typename: 'BooleanValue' |},
            ...$Pick<BooleanValue, { boolean: * }>
          }) | ({
              ...{| __typename: 'DateTimeValue' |},
            ...$Pick<DateTimeValue, { datetime: * }>
          }) | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | ({
              ...{| __typename: 'MetricValueValue' |},
            ...{ metricValue: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }) }
          }) | {| __typename: 'PriceValue' |} | ({
              ...{| __typename: 'SizeValue' |},
            ...{ size: ({
                ...{| __typename: 'Size' |},
              ...{ height: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), length: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), width: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }) }
            }) }
          }) | ({
              ...{| __typename: 'EntityValue' |},
            ...{ entity?: ?({
                ...{| __typename: 'Product' |},
              ...$Pick<Product, { id: * }>
            }) | ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>
            }) | ({
                ...{| __typename: 'ProductProviderPackage' |},
              ...$Pick<ProductProviderPackage, { id: * }>
            }) | ({
                ...{| __typename: 'Order' |},
              ...$Pick<Order, { id: * }>
            }) | ({
                ...{| __typename: 'OrderItem' |},
              ...$Pick<OrderItem, { id: * }>
            }) | ({
                ...{| __typename: 'Batch' |},
              ...$Pick<Batch, { id: * }>
            }) | ({
                ...{| __typename: 'Shipment' |},
              ...$Pick<Shipment, { id: * }>
            }) | ({
                ...{| __typename: 'Voyage' |},
              ...$Pick<Voyage, { id: * }>
            }) | ({
                ...{| __typename: 'Container' |},
              ...$Pick<Container, { id: * }>
            }) | ({
                ...{| __typename: 'ContainerGroup' |},
              ...$Pick<ContainerGroup, { id: * }>
            }) | ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: * }>
            }) | ({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: * }>
            }) | ({
                ...{| __typename: 'Warehouse' |},
              ...$Pick<Warehouse, { id: * }>
            }) | ({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>
            }) | ({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: * }>
            }) | ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: * }>
            }) | ({
                ...{| __typename: 'Partnership' |},
              ...$Pick<Partnership, { id: * }>
            }) | ({
                ...{| __typename: 'Role' |},
              ...$Pick<Role, { id: * }>
            }) | ({
                ...{| __typename: 'File' |},
              ...$Pick<File, { id: * }>
            }) | ({
                ...{| __typename: 'Mask' |},
              ...$Pick<Mask, { id: * }>
            }) | ({
                ...{| __typename: 'MaskEdit' |},
              ...$Pick<MaskEdit, { id: * }>
            }) | ({
                ...{| __typename: 'FieldDefinition' |},
              ...$Pick<FieldDefinition, { id: * }>
            }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'Values' |}> }
        }) }
      })> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) }
});

export type UserFragment = ({
    ...{| __typename: 'User' |},
  ...$Pick<User, { id: *, email: *, disabled: *, createdAt: *, firstName: *, lastName: *, language: *, timezone: *, mfaType?: * }>,
  ...{ connections: Array<({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>,
    ...{ organizations: Array<({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, roles: Array<({
      ...{| __typename: 'Role' |},
    ...$Pick<Role, { id: *, name: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization?: ?({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, disabled: *, name: *, name2?: *, types: * }>,
    ...{ connections: Array<({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type UsersOrganizationFragment = ({
    ...{| __typename: 'Organization' |},
  ...$Pick<Organization, { id: *, disabled: *, name: *, name2?: *, types: * }>,
  ...{ connections: Array<({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type AuthenticationQueryVariables = {};


export type AuthenticationQuery = ({
    ...{| __typename: 'Query' |},
  ...$Pick<Query, { authenticated: * }>,
  ...{ authenticatedWithMFA: ({
      ...{| __typename: 'Authenticated' |},
    ...$Pick<Authenticated, { authenticated: *, authenticatedMfa: *, mfaType?: * }>
  }) }
});

export type PermissionsForOrganizationQueryVariables = {
  organizationId: $ElementType<Scalars, 'ID'>,
  connectionId?: ?$ElementType<Scalars, 'ID'>
};


export type PermissionsForOrganizationQuery = ({
    ...{| __typename: 'Query' |},
  ...{ viewer: ({
      ...{| __typename: 'Viewer' |},
    ...$Pick<Viewer, { permissionsForOrganization: * }>
  }) }
});

export type ViewerQueryVariables = {};


export type ViewerQuery = ({
    ...{| __typename: 'Query' |},
  ...{ viewer: ({
      ...{| __typename: 'Viewer' |},
    ...{ user: ({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, email: *, disabled: *, createdAt: *, firstName: *, lastName: *, language: *, timezone: *, mfaType?: * }>,
      ...{ connections: Array<({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>,
        ...{ organizations: Array<({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, roles: Array<({
          ...{| __typename: 'Role' |},
        ...$Pick<Role, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, disabled: *, name: *, name2?: *, types: * }>,
        ...{ connections: Array<({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) }
});

export type BatchCardFragment = ({
    ...{| __typename: 'Batch' |},
  ...$Pick<Batch, { id: *, no: *, archived: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: *, deliveredAt?: *, desiredAt?: *, packageQuantity?: * }>,
  ...{ totalPrice?: ?({
      ...{| __typename: 'NewPrice' |},
    ...$Pick<NewPrice, { amount: *, currency: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), packageVolume?: ?({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, shipment?: ?({
      ...{| __typename: 'Shipment' |},
    ...$Pick<Shipment, { id: *, no: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
      ...{| __typename: 'Container' |},
    ...$Pick<Container, { id: *, no: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, orderItem: ({
      ...{| __typename: 'OrderItem' |},
    ...$Pick<OrderItem, { id: * }>,
    ...{ price: ({
        ...{| __typename: 'NewPrice' |},
      ...$Pick<NewPrice, { amount: *, currency: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, order: ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: *, poNo: *, currency: * }>,
      ...{ importer: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: *, name: * }>,
      ...{ product: ({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: *, serial: * }>,
        ...{ files: Array<({
            ...{| __typename: 'File' |},
          ...$Pick<File, { id: *, path: * }>,
          ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type BatchCardSummaryFragment = ({
    ...{| __typename: 'Batch' |},
  ...$Pick<Batch, { id: *, quantity: *, latestQuantity: *, packageQuantity?: * }>,
  ...{ packageGrossWeight?: ?({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), orderItem: ({
      ...{| __typename: 'OrderItem' |},
    ...$Pick<OrderItem, { id: * }>,
    ...{ price: ({
        ...{| __typename: 'NewPrice' |},
      ...$Pick<NewPrice, { amount: *, currency: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: * }>,
      ...{ product: ({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type BatchCardWithOwnedFragment = ({
    ...{| __typename: 'Batch' |},
  ...$Pick<Batch, { id: *, no: *, archived: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: *, deliveredAt?: *, desiredAt?: *, packageQuantity?: * }>,
  ...{ totalPrice?: ?({
      ...{| __typename: 'NewPrice' |},
    ...$Pick<NewPrice, { amount: *, currency: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), packageVolume?: ?({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, shipment?: ?({
      ...{| __typename: 'Shipment' |},
    ...$Pick<Shipment, { id: *, no: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
      ...{| __typename: 'Container' |},
    ...$Pick<Container, { id: *, no: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, orderItem: ({
      ...{| __typename: 'OrderItem' |},
    ...$Pick<OrderItem, { id: * }>,
    ...{ price: ({
        ...{| __typename: 'NewPrice' |},
      ...$Pick<NewPrice, { amount: *, currency: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, order: ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: *, poNo: *, currency: * }>,
      ...{ importer: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: *, name: * }>,
      ...{ product: ({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: *, serial: * }>,
        ...{ files: Array<({
            ...{| __typename: 'File' |},
          ...$Pick<File, { id: *, path: * }>,
          ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type BatchEntityCardFragment = ({
    ...{| __typename: 'Batch' |},
  ...$Pick<Batch, { id: *, updatedAt: *, createdAt: *, deliveredAt?: *, expiredAt?: *, desiredAt?: *, archived: *, no: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: * }>,
  ...{ totalVolume: ({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, shipment?: ?({
      ...{| __typename: 'Shipment' |},
    ...$Pick<Shipment, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
      ...{| __typename: 'Container' |},
    ...$Pick<Container, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type BatchForMoveFragment = ({
    ...{| __typename: 'Batch' |},
  ...$Pick<Batch, { id: *, latestQuantity: * }>,
  ...{ container?: ?({
      ...{| __typename: 'Container' |},
    ...$Pick<Container, { id: * }>,
    ...{ shipment: ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment?: ?({
      ...{| __typename: 'Shipment' |},
    ...$Pick<Shipment, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, orderItem: ({
      ...{| __typename: 'OrderItem' |},
    ...$Pick<OrderItem, { id: *, no: * }>,
    ...{ price: ({
        ...{| __typename: 'NewPrice' |},
      ...$Pick<NewPrice, { amount: *, currency: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, order: ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: *, currency: * }>,
      ...{ exporter: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type BatchFormFragment = ({
    ...{| __typename: 'Batch' |},
  ...$Pick<Batch, { id: *, sort: *, shipmentSort: *, archived: *, autoCalculatePackageQuantity: *, autoCalculatePackageVolume: *, updatedAt: *, memo?: *, no: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: *, latestQuantityField: *, producedAt?: *, deliveredAt?: *, desiredAt?: *, expiredAt?: *, packageName?: *, packageCapacity?: *, packageQuantity?: * }>,
  ...{ updatedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), totalPrice?: ?({
      ...{| __typename: 'NewPrice' |},
    ...$Pick<NewPrice, { amount: *, currency: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
      ...{| __typename: 'CustomFields' |},
    ...{ fieldValues: Array<({
        ...{| __typename: 'FieldValue' |},
      ...{ value: ({
          ...{| __typename: 'StringValue' |},
        ...$Pick<StringValue, { string: * }>
      }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }), packageGrossWeight?: ?({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), packageVolume?: ?({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), packageSize?: ?({
      ...{| __typename: 'Size' |},
    ...{ length: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), width: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), height: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }) }
  }), tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, orderItem: ({
      ...{| __typename: 'OrderItem' |},
    ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
    ...{ price: ({
        ...{| __typename: 'NewPrice' |},
      ...$Pick<NewPrice, { amount: *, currency: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, order: ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
      ...{ totalPrice: ({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, timeline: ({
          ...{| __typename: 'Timeline' |},
        ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
      }), ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
          ...{| __typename: 'Notification' |},
        ...$Pick<Notification, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: *, name: * }>,
      ...{ exporter: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
          ...{ tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
          ...{ tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
        ...{ grossWeight?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), volume?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), size?: ?({
            ...{| __typename: 'Size' |},
          ...{ length: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), width: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), height: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }) }
        }) }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
        ...{ grossWeight?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), volume?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), size?: ?({
            ...{| __typename: 'Size' |},
          ...{ length: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), width: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), height: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }) }
        }) }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, product: ({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: *, serial: * }>,
        ...{ tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, files: Array<({
            ...{| __typename: 'File' |},
          ...$Pick<File, { id: *, path: * }>,
          ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment?: ?({
      ...{| __typename: 'Shipment' |},
    ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
    ...{ forwarders: Array<({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>,
      ...{ partner?: ?({
          ...{| __typename: 'Partner' |},
        ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
        ...{ tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolume: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), totalVolumeOverride?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), timeline: ({
        ...{| __typename: 'Timeline' |},
      ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
    }), containerTypeCounts: Array<({
        ...{| __typename: 'ContainerTypeCount' |},
      ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
    })>, importer?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>,
      ...{ partner?: ?({
          ...{| __typename: 'Partner' |},
        ...$Pick<Partner, { id: *, name?: *, code?: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>,
      ...{ partner?: ?({
          ...{| __typename: 'Partner' |},
        ...$Pick<Partner, { id: *, name?: *, code?: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, date: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, voyages: Array<({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
      ...{ departurePort?: ?({
          ...{| __typename: 'Port' |},
        ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
      }), arrivalPort?: ?({
          ...{| __typename: 'Port' |},
        ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
      }), departure: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>,
      ...{ customClearance: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
      ...{ warehouse?: ?({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: * }>,
      ...{ orderItem: ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: * }>,
        ...{ productProvider: ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: * }>,
          ...{ exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
        ...{| __typename: 'Notification' |},
      ...$Pick<Notification, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
      ...{| __typename: 'Container' |},
    ...$Pick<Container, { id: *, no: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: * }>,
    ...{ representativeBatch?: ?({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: * }>,
      ...{ orderItem: ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: * }>,
        ...{ productProvider: ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: * }>,
          ...{ product: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: *, serial: * }>,
            ...{ files: Array<({
                ...{| __typename: 'File' |},
              ...$Pick<File, { id: *, path: * }>,
              ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), batches: Array<({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, no: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type BatchIdentifiersFragment = ({
    ...{| __typename: 'Batch' |},
  ...$Pick<Batch, { id: *, no: * }>,
  ...{ orderItem: ({
      ...{| __typename: 'OrderItem' |},
    ...$Pick<OrderItem, { id: *, no: * }>,
    ...{ order: ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: *, poNo: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: *, name: * }>,
      ...{ product: ({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: *, serial: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type BatchQuantityFragment = ({
    ...{| __typename: 'Batch' |},
  ...$Pick<Batch, { no: *, quantity: *, latestQuantity: *, latestQuantityField: * }>
});

export type BatchSplitFragment = ({
    ...{| __typename: 'Batch' |},
  ...$Pick<Batch, { id: *, no: *, quantity: *, latestQuantity: *, latestQuantityField: * }>,
  ...{ orderItem: ({
      ...{| __typename: 'OrderItem' |},
    ...$Pick<OrderItem, { id: *, no: * }>,
    ...{ order: ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: *, poNo: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: *, name: * }>,
      ...{ product: ({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: *, serial: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type BatchBalanceSplitManyMutationVariables = {
  orderItemIds: Array<$ElementType<Scalars, 'ID'>>
};


export type BatchBalanceSplitManyMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ batchBalanceSplitMany: Array<?({
      ...{| __typename: 'Batches' |},
    ...{ batches: Array<({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: * }>
    })> }
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}> }
});

export type BatchCloneManyMutationVariables = {
  batches: Array<BatchUpdateWrapperInput>
};


export type BatchCloneManyMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ batchCloneMany: Array<?({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { createdAt: *, updatedAt: *, no: *, quantity: *, quantityUnit: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, deliveredAt?: *, desiredAt?: *, totalVolumeOverriding: *, expiredAt?: *, producedAt?: *, packageName?: *, packageCapacity?: *, packageQuantity?: *, autoCalculatePackageQuantity: *, latestQuantity: *, archived: *, autoCalculatePackageVolume: *, memo?: *, sort: *, containerSort: *, shipmentSort: *, id: * }>,
    ...{ createdBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { displayName: *, id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalPrice?: ?({
        ...{| __typename: 'NewPrice' |},
      ...$Pick<NewPrice, { amount: *, currency: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), totalVolumeOverride?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), packageGrossWeight?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), packageVolume?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), packageSize?: ?({
        ...{| __typename: 'Size' |},
      ...{ length: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), width: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), height: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }) }
    }), tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, shipment?: ?({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { archived: *, no: *, blNo?: *, masterBlNo?: *, blDate?: *, autoTracking?: *, autoTrackingTransportType?: *, autoTrackingIncludeContainers?: *, containerCount: *, booked?: *, bookingNo?: *, bookingDate?: *, invoiceNo?: *, contractNo?: *, loadType?: *, incoterm?: *, carrier?: *, memo?: *, totalWeightOverriding: *, totalWeightDisplayMetric: *, totalVolumeOverriding: *, totalVolumeDisplayMetric: *, totalPackageQuantity: *, totalPackageQuantityOverride?: *, totalPackageQuantityOverriding: *, filesUnreadCount: *, hasFile: *, id: *, notificationUnseenCount: * }>,
      ...{ followers: Array<({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batchQuantity: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalWeight: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalWeightOverride?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalVolumeOverride?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), importer?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, forwarders: Array<({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organizations: Array<({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, cargoReady: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
          ...{ updatedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timeline: ({
          ...{| __typename: 'Timeline' |},
        ...$Pick<Timeline, { unreadMessageCount: * }>
      }), voyages: Array<({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: *, no?: *, vesselName?: *, vesselCode?: * }>,
        ...{ departurePort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), arrivalPort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), departure: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
            ...{ updatedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
            ...{ updatedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customClearance: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
            ...{ updatedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
            ...{ updatedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
            ...{ updatedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, messageGroups: Array<({
          ...{| __typename: 'MessageGroup' |},
        ...$Pick<MessageGroup, { unreadMessageCount: *, filesUnreadCount: * }>
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, files: Array<({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
        ...{ tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, entity?: ?({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: *, name: * }>,
          ...{ product: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: *, poNo: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | ({
            ...{| __typename: 'Partnership' |},
          ...$Pick<Partnership, { id: * }>
        }) | ({
            ...{| __typename: 'Role' |},
          ...$Pick<Role, { id: * }>
        }) | ({
            ...{| __typename: 'File' |},
          ...$Pick<File, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Mask' |},
          ...$Pick<Mask, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'MaskEdit' |},
          ...$Pick<MaskEdit, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { displayName: *, id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
          ...{| __typename: 'CustomFields' |},
        ...{ fieldValues: Array<({
            ...{| __typename: 'FieldValue' |},
          ...{ value: ({
              ...{| __typename: 'StringValue' |},
            ...$Pick<StringValue, { string: * }>
          }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }), notificationUnseen: Array<({
          ...{| __typename: 'Notification' |},
        ...$Pick<Notification, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { no: *, archived: *, autoCalculatedFreeTimeStartDate: *, freeTimeStartDate?: *, freeTimeDuration?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDateApprovedAt?: *, yardName?: *, departureDate?: *, departureDateApprovedAt?: *, containerType?: *, containerOption?: *, memo?: *, sort: *, id: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { displayName: *, id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, representativeBatch?: ?({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, terminalArrivalPort?: ?({
          ...{| __typename: 'Port' |},
        ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
      }), terminalArrival: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
          ...{ updatedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, batchQuantity: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalWeight: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalPrice?: ?({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, departureDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
          ...{| __typename: 'CustomFields' |},
        ...{ fieldValues: Array<({
            ...{| __typename: 'FieldValue' |},
          ...{ value: ({
              ...{| __typename: 'StringValue' |},
            ...$Pick<StringValue, { string: * }>
          }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, orderItem: ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { no: *, quantity: *, priceUnit: *, calculatedUnit: *, archived: *, deliveryDate?: *, memo?: *, hasFile: *, sort: *, id: * }>,
      ...{ order: ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { createdAt: *, updatedAt: *, archived: *, poNo: *, memo?: *, issuedAt?: *, piNo?: *, currency: *, calculatedOrderItemUnit: *, totalOrdered: *, incoterm?: *, deliveryPlace?: *, deliveryDate?: *, hasFile: *, id: * }>,
        ...{ createdBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { displayName: *, id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, followers: Array<({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, importer: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, files: Array<({
            ...{| __typename: 'File' |},
          ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
          ...{ tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, entity?: ?({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: *, name: * }>,
            ...{ product: ({
                ...{| __typename: 'Product' |},
              ...$Pick<Product, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Order' |},
            ...$Pick<Order, { id: *, poNo: * }>,
            ...{ connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: *, no: * }>,
            ...{ connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: *, no: * }>,
            ...{ connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Shipment' |},
            ...$Pick<Shipment, { id: *, no: * }>,
            ...{ connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Voyage' |},
            ...$Pick<Voyage, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Container' |},
            ...$Pick<Container, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'ContainerGroup' |},
            ...$Pick<ContainerGroup, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: * }>
          }) | ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: * }>
          }) | ({
              ...{| __typename: 'Partnership' |},
            ...$Pick<Partnership, { id: * }>
          }) | ({
              ...{| __typename: 'Role' |},
            ...$Pick<Role, { id: * }>
          }) | ({
              ...{| __typename: 'File' |},
            ...$Pick<File, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Mask' |},
            ...$Pick<Mask, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'MaskEdit' |},
            ...$Pick<MaskEdit, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, organizations: Array<({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
            ...{| __typename: 'CustomFields' |},
          ...{ fieldValues: Array<({
              ...{| __typename: 'FieldValue' |},
            ...{ value: ({
                ...{| __typename: 'StringValue' |},
              ...$Pick<StringValue, { string: * }>
            }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
                ...{| __typename: 'FieldDefinition' |},
              ...$Pick<FieldDefinition, { id: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { createdAt: *, updatedAt: *, id: *, name: *, hasFile: * }>,
        ...{ product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { createdAt: *, updatedAt: *, name: *, serial: *, material?: *, id: * }>,
          ...{ createdBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, files: Array<({
              ...{| __typename: 'File' |},
            ...$Pick<File, { id: *, path: * }>,
            ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, productProviders: Array<({
              ...{| __typename: 'ProductProvider' |},
            ...{ connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
              ...{| __typename: 'CustomFields' |},
            ...{ fieldValues: Array<({
                ...{| __typename: 'FieldValue' |},
              ...{ value: ({
                  ...{| __typename: 'StringValue' |},
                ...$Pick<StringValue, { string: * }>
              }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
                  ...{| __typename: 'FieldDefinition' |},
                ...$Pick<FieldDefinition, { id: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, createdBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { displayName: *, id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, unitPrice?: ?({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, files: Array<({
            ...{| __typename: 'File' |},
          ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
          ...{ tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, entity?: ?({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: *, name: * }>,
            ...{ product: ({
                ...{| __typename: 'Product' |},
              ...$Pick<Product, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Order' |},
            ...$Pick<Order, { id: *, poNo: * }>,
            ...{ connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: *, no: * }>,
            ...{ connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: *, no: * }>,
            ...{ connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Shipment' |},
            ...$Pick<Shipment, { id: *, no: * }>,
            ...{ connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Voyage' |},
            ...$Pick<Voyage, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Container' |},
            ...$Pick<Container, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'ContainerGroup' |},
            ...$Pick<ContainerGroup, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: * }>
          }) | ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: * }>
          }) | ({
              ...{| __typename: 'Partnership' |},
            ...$Pick<Partnership, { id: * }>
          }) | ({
              ...{| __typename: 'Role' |},
            ...$Pick<Role, { id: * }>
          }) | ({
              ...{| __typename: 'File' |},
            ...$Pick<File, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Mask' |},
            ...$Pick<Mask, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'MaskEdit' |},
            ...$Pick<MaskEdit, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
            ...{| __typename: 'CustomFields' |},
          ...{ fieldValues: Array<({
              ...{| __typename: 'FieldValue' |},
            ...{ value: ({
                ...{| __typename: 'StringValue' |},
              ...$Pick<StringValue, { string: * }>
            }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
                ...{| __typename: 'FieldDefinition' |},
              ...$Pick<FieldDefinition, { id: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, price: ({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalPrice: ({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, files: Array<({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
        ...{ tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, entity?: ?({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: *, name: * }>,
          ...{ product: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: *, poNo: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | ({
            ...{| __typename: 'Partnership' |},
          ...$Pick<Partnership, { id: * }>
        }) | ({
            ...{| __typename: 'Role' |},
          ...$Pick<Role, { id: * }>
        }) | ({
            ...{| __typename: 'File' |},
          ...$Pick<File, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Mask' |},
          ...$Pick<Mask, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'MaskEdit' |},
          ...$Pick<MaskEdit, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
          ...{| __typename: 'CustomFields' |},
        ...{ fieldValues: Array<({
            ...{| __typename: 'FieldValue' |},
          ...{ value: ({
              ...{| __typename: 'StringValue' |},
            ...$Pick<StringValue, { string: * }>
          }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
        ...{| __typename: 'CustomFields' |},
      ...{ fieldValues: Array<({
          ...{| __typename: 'FieldValue' |},
        ...{ value: ({
            ...{| __typename: 'StringValue' |},
          ...$Pick<StringValue, { string: * }>
        }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) }
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}> }
});

export type BatchCloneManyIdOnlyMutationVariables = {
  batches: Array<BatchUpdateWrapperInput>
};


export type BatchCloneManyIdOnlyMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ batchCloneMany: Array<?({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type BatchCreateMutationVariables = {
  input: BatchCreateInput
};


export type BatchCreateMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ batchCreate: ({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { id: *, sort: *, shipmentSort: *, archived: *, autoCalculatePackageQuantity: *, autoCalculatePackageVolume: *, updatedAt: *, memo?: *, no: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: *, latestQuantityField: *, producedAt?: *, deliveredAt?: *, desiredAt?: *, expiredAt?: *, packageName?: *, packageCapacity?: *, packageQuantity?: * }>,
    ...{ updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), totalPrice?: ?({
        ...{| __typename: 'NewPrice' |},
      ...$Pick<NewPrice, { amount: *, currency: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
        ...{| __typename: 'CustomFields' |},
      ...{ fieldValues: Array<({
          ...{| __typename: 'FieldValue' |},
        ...{ value: ({
            ...{| __typename: 'StringValue' |},
          ...$Pick<StringValue, { string: * }>
        }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }), packageGrossWeight?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), packageVolume?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), packageSize?: ?({
        ...{| __typename: 'Size' |},
      ...{ length: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), width: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), height: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }) }
    }), tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, orderItem: ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
      ...{ price: ({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, order: ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
        ...{ totalPrice: ({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, timeline: ({
            ...{| __typename: 'Timeline' |},
          ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
        }), ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
            ...{| __typename: 'Notification' |},
          ...$Pick<Notification, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ exporter: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
          ...{ grossWeight?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), volume?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), size?: ?({
              ...{| __typename: 'Size' |},
            ...{ length: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), width: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), height: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }) }
          }) }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
          ...{ grossWeight?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), volume?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), size?: ?({
              ...{| __typename: 'Size' |},
            ...{ length: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), width: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), height: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }) }
          }) }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: *, serial: * }>,
          ...{ tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, files: Array<({
              ...{| __typename: 'File' |},
            ...$Pick<File, { id: *, path: * }>,
            ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment?: ?({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
      ...{ forwarders: Array<({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
          ...{ tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalVolumeOverride?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), timeline: ({
          ...{| __typename: 'Timeline' |},
        ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
      }), containerTypeCounts: Array<({
          ...{| __typename: 'ContainerTypeCount' |},
        ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
      })>, importer?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, voyages: Array<({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
        ...{ departurePort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), arrivalPort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), departure: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ customClearance: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
        ...{ warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>,
        ...{ orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>,
          ...{ productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>,
            ...{ exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
          ...{| __typename: 'Notification' |},
        ...$Pick<Notification, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: *, no: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: * }>,
      ...{ representativeBatch?: ?({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>,
        ...{ orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>,
          ...{ productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>,
            ...{ product: ({
                ...{| __typename: 'Product' |},
              ...$Pick<Product, { id: *, name: *, serial: * }>,
              ...{ files: Array<({
                  ...{| __typename: 'File' |},
                ...$Pick<File, { id: *, path: * }>,
                ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |} }
});

export type BatchDeleteMutationVariables = {
  id: $ElementType<Scalars, 'ID'>
};


export type BatchDeleteMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ batchDelete?: ?({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |} }
});

export type BatchSimpleSplitMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  input: BatchSimpleSplitInput
};


export type BatchSimpleSplitMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ batchSimpleSplit: ({
      ...{| __typename: 'Batches' |},
    ...{ batches: Array<({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { createdAt: *, updatedAt: *, no: *, quantity: *, quantityUnit: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, deliveredAt?: *, desiredAt?: *, totalVolumeOverriding: *, expiredAt?: *, producedAt?: *, packageName?: *, packageCapacity?: *, packageQuantity?: *, autoCalculatePackageQuantity: *, latestQuantity: *, archived: *, autoCalculatePackageVolume: *, memo?: *, sort: *, containerSort: *, shipmentSort: *, id: * }>,
      ...{ createdBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { displayName: *, id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalPrice?: ?({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalVolumeOverride?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), packageGrossWeight?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), packageVolume?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), packageSize?: ?({
          ...{| __typename: 'Size' |},
        ...{ length: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), width: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), height: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }) }
      }), tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, shipment?: ?({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { archived: *, no: *, blNo?: *, masterBlNo?: *, blDate?: *, autoTracking?: *, autoTrackingTransportType?: *, autoTrackingIncludeContainers?: *, containerCount: *, booked?: *, bookingNo?: *, bookingDate?: *, invoiceNo?: *, contractNo?: *, loadType?: *, incoterm?: *, carrier?: *, memo?: *, totalWeightOverriding: *, totalWeightDisplayMetric: *, totalVolumeOverriding: *, totalVolumeDisplayMetric: *, totalPackageQuantity: *, totalPackageQuantityOverride?: *, totalPackageQuantityOverriding: *, filesUnreadCount: *, hasFile: *, id: *, notificationUnseenCount: * }>,
        ...{ followers: Array<({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batchQuantity: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), totalWeight: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), totalWeightOverride?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), totalVolumeOverride?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), importer?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, forwarders: Array<({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organizations: Array<({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, cargoReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
            ...{ updatedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timeline: ({
            ...{| __typename: 'Timeline' |},
          ...$Pick<Timeline, { unreadMessageCount: * }>
        }), voyages: Array<({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: *, no?: *, vesselName?: *, vesselCode?: * }>,
          ...{ departurePort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), arrivalPort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), departure: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
            ...{ connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
              ...{ updatedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
            ...{ connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
              ...{ updatedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>,
          ...{ warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customClearance: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
            ...{ connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
              ...{ updatedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
            ...{ connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
              ...{ updatedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
            ...{ connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
              ...{ updatedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, messageGroups: Array<({
            ...{| __typename: 'MessageGroup' |},
          ...$Pick<MessageGroup, { unreadMessageCount: *, filesUnreadCount: * }>
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, files: Array<({
            ...{| __typename: 'File' |},
          ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
          ...{ tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, entity?: ?({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: *, name: * }>,
            ...{ product: ({
                ...{| __typename: 'Product' |},
              ...$Pick<Product, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Order' |},
            ...$Pick<Order, { id: *, poNo: * }>,
            ...{ connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: *, no: * }>,
            ...{ connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: *, no: * }>,
            ...{ connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Shipment' |},
            ...$Pick<Shipment, { id: *, no: * }>,
            ...{ connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Voyage' |},
            ...$Pick<Voyage, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Container' |},
            ...$Pick<Container, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'ContainerGroup' |},
            ...$Pick<ContainerGroup, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: * }>
          }) | ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: * }>
          }) | ({
              ...{| __typename: 'Partnership' |},
            ...$Pick<Partnership, { id: * }>
          }) | ({
              ...{| __typename: 'Role' |},
            ...$Pick<Role, { id: * }>
          }) | ({
              ...{| __typename: 'File' |},
            ...$Pick<File, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Mask' |},
            ...$Pick<Mask, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'MaskEdit' |},
            ...$Pick<MaskEdit, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { displayName: *, id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
            ...{| __typename: 'CustomFields' |},
          ...{ fieldValues: Array<({
              ...{| __typename: 'FieldValue' |},
            ...{ value: ({
                ...{| __typename: 'StringValue' |},
              ...$Pick<StringValue, { string: * }>
            }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
                ...{| __typename: 'FieldDefinition' |},
              ...$Pick<FieldDefinition, { id: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }), notificationUnseen: Array<({
            ...{| __typename: 'Notification' |},
          ...$Pick<Notification, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { no: *, archived: *, autoCalculatedFreeTimeStartDate: *, freeTimeStartDate?: *, freeTimeDuration?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDateApprovedAt?: *, yardName?: *, departureDate?: *, departureDateApprovedAt?: *, containerType?: *, containerOption?: *, memo?: *, sort: *, id: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { displayName: *, id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, representativeBatch?: ?({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, terminalArrivalPort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), terminalArrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
            ...{ updatedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, batchQuantity: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), totalWeight: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), totalPrice?: ?({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, departureDateApprovedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
            ...{| __typename: 'CustomFields' |},
          ...{ fieldValues: Array<({
              ...{| __typename: 'FieldValue' |},
            ...{ value: ({
                ...{| __typename: 'StringValue' |},
              ...$Pick<StringValue, { string: * }>
            }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
                ...{| __typename: 'FieldDefinition' |},
              ...$Pick<FieldDefinition, { id: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, orderItem: ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { no: *, quantity: *, priceUnit: *, calculatedUnit: *, archived: *, deliveryDate?: *, memo?: *, hasFile: *, sort: *, id: * }>,
        ...{ order: ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { createdAt: *, updatedAt: *, archived: *, poNo: *, memo?: *, issuedAt?: *, piNo?: *, currency: *, calculatedOrderItemUnit: *, totalOrdered: *, incoterm?: *, deliveryPlace?: *, deliveryDate?: *, hasFile: *, id: * }>,
          ...{ createdBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { displayName: *, id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, followers: Array<({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, importer: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, files: Array<({
              ...{| __typename: 'File' |},
            ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | ({
                ...{| __typename: 'Forbidden' |},
              ...$Pick<Forbidden, { reason?: * }>,
              ...{ reference?: ?({
                  ...{| __typename: 'Reference' |},
                ...$Pick<Reference, { id: * }>
              }) }
            }) | {| __typename: 'NotFound' |}>, entity?: ?({
                ...{| __typename: 'Product' |},
              ...$Pick<Product, { id: *, name: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: *, types: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: *, name: * }>,
              ...{ product: ({
                  ...{| __typename: 'Product' |},
                ...$Pick<Product, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: *, types: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | ({
                ...{| __typename: 'ProductProviderPackage' |},
              ...$Pick<ProductProviderPackage, { id: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: *, types: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | ({
                ...{| __typename: 'Order' |},
              ...$Pick<Order, { id: *, poNo: * }>,
              ...{ connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: *, types: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | ({
                ...{| __typename: 'OrderItem' |},
              ...$Pick<OrderItem, { id: *, no: * }>,
              ...{ connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: *, types: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | ({
                ...{| __typename: 'Batch' |},
              ...$Pick<Batch, { id: *, no: * }>,
              ...{ connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: *, types: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | ({
                ...{| __typename: 'Shipment' |},
              ...$Pick<Shipment, { id: *, no: * }>,
              ...{ connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: *, types: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | ({
                ...{| __typename: 'Voyage' |},
              ...$Pick<Voyage, { id: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: *, types: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | ({
                ...{| __typename: 'Container' |},
              ...$Pick<Container, { id: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: *, types: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | ({
                ...{| __typename: 'ContainerGroup' |},
              ...$Pick<ContainerGroup, { id: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: *, types: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: *, types: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | ({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: *, types: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | ({
                ...{| __typename: 'Warehouse' |},
              ...$Pick<Warehouse, { id: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: *, types: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | ({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: *, types: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | ({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: * }>
            }) | ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: * }>
            }) | ({
                ...{| __typename: 'Partnership' |},
              ...$Pick<Partnership, { id: * }>
            }) | ({
                ...{| __typename: 'Role' |},
              ...$Pick<Role, { id: * }>
            }) | ({
                ...{| __typename: 'File' |},
              ...$Pick<File, { id: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: *, types: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | ({
                ...{| __typename: 'Mask' |},
              ...$Pick<Mask, { id: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: *, types: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | ({
                ...{| __typename: 'MaskEdit' |},
              ...$Pick<MaskEdit, { id: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: *, types: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | ({
                ...{| __typename: 'FieldDefinition' |},
              ...$Pick<FieldDefinition, { id: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: *, types: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, organizations: Array<({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
              ...{| __typename: 'CustomFields' |},
            ...{ fieldValues: Array<({
                ...{| __typename: 'FieldValue' |},
              ...{ value: ({
                  ...{| __typename: 'StringValue' |},
                ...$Pick<StringValue, { string: * }>
              }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
                  ...{| __typename: 'FieldDefinition' |},
                ...$Pick<FieldDefinition, { id: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { createdAt: *, updatedAt: *, id: *, name: *, hasFile: * }>,
          ...{ product: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { createdAt: *, updatedAt: *, name: *, serial: *, material?: *, id: * }>,
            ...{ createdBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | ({
                ...{| __typename: 'Forbidden' |},
              ...$Pick<Forbidden, { reason?: * }>,
              ...{ reference?: ?({
                  ...{| __typename: 'Reference' |},
                ...$Pick<Reference, { id: * }>
              }) }
            }) | {| __typename: 'NotFound' |}>, files: Array<({
                ...{| __typename: 'File' |},
              ...$Pick<File, { id: *, path: * }>,
              ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, productProviders: Array<({
                ...{| __typename: 'ProductProvider' |},
              ...{ connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
                ...{| __typename: 'CustomFields' |},
              ...{ fieldValues: Array<({
                  ...{| __typename: 'FieldValue' |},
                ...{ value: ({
                    ...{| __typename: 'StringValue' |},
                  ...$Pick<StringValue, { string: * }>
                }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
                    ...{| __typename: 'FieldDefinition' |},
                  ...$Pick<FieldDefinition, { id: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, createdBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { displayName: *, id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, unitPrice?: ?({
              ...{| __typename: 'NewPrice' |},
            ...$Pick<NewPrice, { amount: *, currency: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, files: Array<({
              ...{| __typename: 'File' |},
            ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | ({
                ...{| __typename: 'Forbidden' |},
              ...$Pick<Forbidden, { reason?: * }>,
              ...{ reference?: ?({
                  ...{| __typename: 'Reference' |},
                ...$Pick<Reference, { id: * }>
              }) }
            }) | {| __typename: 'NotFound' |}>, entity?: ?({
                ...{| __typename: 'Product' |},
              ...$Pick<Product, { id: *, name: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: *, types: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: *, name: * }>,
              ...{ product: ({
                  ...{| __typename: 'Product' |},
                ...$Pick<Product, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: *, types: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | ({
                ...{| __typename: 'ProductProviderPackage' |},
              ...$Pick<ProductProviderPackage, { id: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: *, types: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | ({
                ...{| __typename: 'Order' |},
              ...$Pick<Order, { id: *, poNo: * }>,
              ...{ connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: *, types: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | ({
                ...{| __typename: 'OrderItem' |},
              ...$Pick<OrderItem, { id: *, no: * }>,
              ...{ connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: *, types: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | ({
                ...{| __typename: 'Batch' |},
              ...$Pick<Batch, { id: *, no: * }>,
              ...{ connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: *, types: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | ({
                ...{| __typename: 'Shipment' |},
              ...$Pick<Shipment, { id: *, no: * }>,
              ...{ connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: *, types: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | ({
                ...{| __typename: 'Voyage' |},
              ...$Pick<Voyage, { id: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: *, types: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | ({
                ...{| __typename: 'Container' |},
              ...$Pick<Container, { id: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: *, types: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | ({
                ...{| __typename: 'ContainerGroup' |},
              ...$Pick<ContainerGroup, { id: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: *, types: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: *, types: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | ({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: *, types: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | ({
                ...{| __typename: 'Warehouse' |},
              ...$Pick<Warehouse, { id: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: *, types: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | ({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: *, types: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | ({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: * }>
            }) | ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: * }>
            }) | ({
                ...{| __typename: 'Partnership' |},
              ...$Pick<Partnership, { id: * }>
            }) | ({
                ...{| __typename: 'Role' |},
              ...$Pick<Role, { id: * }>
            }) | ({
                ...{| __typename: 'File' |},
              ...$Pick<File, { id: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: *, types: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | ({
                ...{| __typename: 'Mask' |},
              ...$Pick<Mask, { id: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: *, types: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | ({
                ...{| __typename: 'MaskEdit' |},
              ...$Pick<MaskEdit, { id: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: *, types: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | ({
                ...{| __typename: 'FieldDefinition' |},
              ...$Pick<FieldDefinition, { id: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: *, types: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
              ...{| __typename: 'CustomFields' |},
            ...{ fieldValues: Array<({
                ...{| __typename: 'FieldValue' |},
              ...{ value: ({
                  ...{| __typename: 'StringValue' |},
                ...$Pick<StringValue, { string: * }>
              }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
                  ...{| __typename: 'FieldDefinition' |},
                ...$Pick<FieldDefinition, { id: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, price: ({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalPrice: ({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, files: Array<({
            ...{| __typename: 'File' |},
          ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
          ...{ tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, entity?: ?({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: *, name: * }>,
            ...{ product: ({
                ...{| __typename: 'Product' |},
              ...$Pick<Product, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Order' |},
            ...$Pick<Order, { id: *, poNo: * }>,
            ...{ connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: *, no: * }>,
            ...{ connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: *, no: * }>,
            ...{ connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Shipment' |},
            ...$Pick<Shipment, { id: *, no: * }>,
            ...{ connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Voyage' |},
            ...$Pick<Voyage, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Container' |},
            ...$Pick<Container, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'ContainerGroup' |},
            ...$Pick<ContainerGroup, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: * }>
          }) | ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: * }>
          }) | ({
              ...{| __typename: 'Partnership' |},
            ...$Pick<Partnership, { id: * }>
          }) | ({
              ...{| __typename: 'Role' |},
            ...$Pick<Role, { id: * }>
          }) | ({
              ...{| __typename: 'File' |},
            ...$Pick<File, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Mask' |},
            ...$Pick<Mask, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'MaskEdit' |},
            ...$Pick<MaskEdit, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
            ...{| __typename: 'CustomFields' |},
          ...{ fieldValues: Array<({
              ...{| __typename: 'FieldValue' |},
            ...{ value: ({
                ...{| __typename: 'StringValue' |},
              ...$Pick<StringValue, { string: * }>
            }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
                ...{| __typename: 'FieldDefinition' |},
              ...$Pick<FieldDefinition, { id: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
          ...{| __typename: 'CustomFields' |},
        ...{ fieldValues: Array<({
            ...{| __typename: 'FieldValue' |},
          ...{ value: ({
              ...{| __typename: 'StringValue' |},
            ...$Pick<StringValue, { string: * }>
          }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) }
    })> }
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |} }
});

export type BatchSimpleSplitIdOnlyMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  input: BatchSimpleSplitInput
};


export type BatchSimpleSplitIdOnlyMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ batchSimpleSplit: ({
      ...{| __typename: 'Batches' |},
    ...{ batches: Array<({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: * }>
    })> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type BatchUpdateMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  input: BatchUpdateInput
};


export type BatchUpdateMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ batchUpdate: ({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { id: *, sort: *, shipmentSort: *, archived: *, autoCalculatePackageQuantity: *, autoCalculatePackageVolume: *, updatedAt: *, memo?: *, no: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: *, latestQuantityField: *, producedAt?: *, deliveredAt?: *, desiredAt?: *, expiredAt?: *, packageName?: *, packageCapacity?: *, packageQuantity?: * }>,
    ...{ updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), totalPrice?: ?({
        ...{| __typename: 'NewPrice' |},
      ...$Pick<NewPrice, { amount: *, currency: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
        ...{| __typename: 'CustomFields' |},
      ...{ fieldValues: Array<({
          ...{| __typename: 'FieldValue' |},
        ...{ value: ({
            ...{| __typename: 'StringValue' |},
          ...$Pick<StringValue, { string: * }>
        }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }), packageGrossWeight?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), packageVolume?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), packageSize?: ?({
        ...{| __typename: 'Size' |},
      ...{ length: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), width: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), height: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }) }
    }), tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, orderItem: ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
      ...{ price: ({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, order: ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
        ...{ totalPrice: ({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, timeline: ({
            ...{| __typename: 'Timeline' |},
          ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
        }), ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
            ...{| __typename: 'Notification' |},
          ...$Pick<Notification, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ exporter: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
          ...{ grossWeight?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), volume?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), size?: ?({
              ...{| __typename: 'Size' |},
            ...{ length: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), width: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), height: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }) }
          }) }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
          ...{ grossWeight?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), volume?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), size?: ?({
              ...{| __typename: 'Size' |},
            ...{ length: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), width: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), height: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }) }
          }) }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: *, serial: * }>,
          ...{ tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, files: Array<({
              ...{| __typename: 'File' |},
            ...$Pick<File, { id: *, path: * }>,
            ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment?: ?({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
      ...{ forwarders: Array<({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
          ...{ tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalVolumeOverride?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), timeline: ({
          ...{| __typename: 'Timeline' |},
        ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
      }), containerTypeCounts: Array<({
          ...{| __typename: 'ContainerTypeCount' |},
        ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
      })>, importer?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, voyages: Array<({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
        ...{ departurePort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), arrivalPort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), departure: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ customClearance: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
        ...{ warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>,
        ...{ orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>,
          ...{ productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>,
            ...{ exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
          ...{| __typename: 'Notification' |},
        ...$Pick<Notification, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: *, no: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: * }>,
      ...{ representativeBatch?: ?({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>,
        ...{ orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>,
          ...{ productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>,
            ...{ product: ({
                ...{| __typename: 'Product' |},
              ...$Pick<Product, { id: *, name: *, serial: * }>,
              ...{ files: Array<({
                  ...{| __typename: 'File' |},
                ...$Pick<File, { id: *, path: * }>,
                ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |} }
});

export type BatchUpdateManyMutationVariables = {
  batches: Array<BatchUpdateWrapperInput>
};


export type BatchUpdateManyMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ batchUpdateMany: Array<?({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { id: * }>,
    ...{ shipment?: ?({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}> }
});

export type BatchExportQueryVariables = {
  id: $ElementType<Scalars, 'ID'>,
  templateId: $ElementType<Scalars, 'ID'>
};


export type BatchExportQuery = ({
    ...{| __typename: 'Query' |},
  ...{ batchExport: ({
      ...{| __typename: 'Export' |},
    ...$Pick<Export, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type BatchFormQueryVariables = {
  id: $ElementType<Scalars, 'ID'>
};


export type BatchFormQuery = ({
    ...{| __typename: 'Query' |},
  ...{ batch: ({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { id: *, sort: *, shipmentSort: *, archived: *, autoCalculatePackageQuantity: *, autoCalculatePackageVolume: *, updatedAt: *, memo?: *, no: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: *, latestQuantityField: *, producedAt?: *, deliveredAt?: *, desiredAt?: *, expiredAt?: *, packageName?: *, packageCapacity?: *, packageQuantity?: * }>,
    ...{ updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), totalPrice?: ?({
        ...{| __typename: 'NewPrice' |},
      ...$Pick<NewPrice, { amount: *, currency: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
        ...{| __typename: 'CustomFields' |},
      ...{ fieldValues: Array<({
          ...{| __typename: 'FieldValue' |},
        ...{ value: ({
            ...{| __typename: 'StringValue' |},
          ...$Pick<StringValue, { string: * }>
        }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }), packageGrossWeight?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), packageVolume?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), packageSize?: ?({
        ...{| __typename: 'Size' |},
      ...{ length: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), width: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), height: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }) }
    }), tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, orderItem: ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
      ...{ price: ({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, order: ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
        ...{ totalPrice: ({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, timeline: ({
            ...{| __typename: 'Timeline' |},
          ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
        }), ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
            ...{| __typename: 'Notification' |},
          ...$Pick<Notification, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ exporter: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
          ...{ grossWeight?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), volume?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), size?: ?({
              ...{| __typename: 'Size' |},
            ...{ length: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), width: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), height: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }) }
          }) }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
          ...{ grossWeight?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), volume?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), size?: ?({
              ...{| __typename: 'Size' |},
            ...{ length: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), width: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), height: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }) }
          }) }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: *, serial: * }>,
          ...{ tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, files: Array<({
              ...{| __typename: 'File' |},
            ...$Pick<File, { id: *, path: * }>,
            ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment?: ?({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
      ...{ forwarders: Array<({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
          ...{ tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalVolumeOverride?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), timeline: ({
          ...{| __typename: 'Timeline' |},
        ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
      }), containerTypeCounts: Array<({
          ...{| __typename: 'ContainerTypeCount' |},
        ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
      })>, importer?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, voyages: Array<({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
        ...{ departurePort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), arrivalPort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), departure: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ customClearance: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
        ...{ warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>,
        ...{ orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>,
          ...{ productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>,
            ...{ exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
          ...{| __typename: 'Notification' |},
        ...$Pick<Notification, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: *, no: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: * }>,
      ...{ representativeBatch?: ?({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>,
        ...{ orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>,
          ...{ productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>,
            ...{ product: ({
                ...{| __typename: 'Product' |},
              ...$Pick<Product, { id: *, name: *, serial: * }>,
              ...{ files: Array<({
                  ...{| __typename: 'File' |},
                ...$Pick<File, { id: *, path: * }>,
                ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type BatchesIdentifiersQueryVariables = {
  ids: Array<$ElementType<Scalars, 'ID'>>
};


export type BatchesIdentifiersQuery = ({
    ...{| __typename: 'Query' |},
  ...{ batchesByIDs: Array<({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { id: *, no: * }>,
    ...{ orderItem: ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: *, no: * }>,
      ...{ order: ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, poNo: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: *, serial: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type BatchListQueryVariables = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?BatchFilterInput,
  sortBy?: ?BatchSortInput
};


export type BatchListQuery = ({
    ...{| __typename: 'Query' |},
  ...{ batches: ({
      ...{| __typename: 'BatchPayloadPaginatedSearch' |},
    ...$Pick<BatchPayloadPaginatedSearch, { page: *, totalPage: * }>,
    ...{ nodes: Array<({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: *, no: *, archived: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: *, deliveredAt?: *, desiredAt?: *, packageQuantity?: * }>,
      ...{ totalPrice?: ?({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), packageVolume?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, shipment?: ?({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, orderItem: ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: * }>,
        ...{ price: ({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, order: ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: *, poNo: *, currency: * }>,
          ...{ importer: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: *, name: * }>,
          ...{ product: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: *, serial: * }>,
            ...{ files: Array<({
                ...{| __typename: 'File' |},
              ...$Pick<File, { id: *, path: * }>,
              ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}> }
  }) }
});

export type BatchTimelineQueryVariables = {
  id: $ElementType<Scalars, 'ID'>,
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>
};


export type BatchTimelineQuery = ({
    ...{| __typename: 'Query' |},
  ...{ batch: ({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { id: * }>,
    ...{ timeline: ({
        ...{| __typename: 'Timeline' |},
      ...{ entries: ({
          ...{| __typename: 'TimelineEntryPaginatedList' |},
        ...$Pick<TimelineEntryPaginatedList, { page: *, totalPage: * }>,
        ...{ nodes: Array<({
            ...{| __typename: 'TimelineEvent' |},
          ...$Pick<TimelineEvent, { id: *, createdAt: * }>,
          ...{ entity: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: * }>
          }) | ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>
          }) | ({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: * }>
          }) | ({
              ...{| __typename: 'Order' |},
            ...$Pick<Order, { id: * }>
          }) | ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>
          }) | ({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: * }>
          }) | ({
              ...{| __typename: 'Shipment' |},
            ...$Pick<Shipment, { id: * }>
          }) | ({
              ...{| __typename: 'Voyage' |},
            ...$Pick<Voyage, { id: * }>
          }) | ({
              ...{| __typename: 'Container' |},
            ...$Pick<Container, { id: * }>
          }) | ({
              ...{| __typename: 'ContainerGroup' |},
            ...$Pick<ContainerGroup, { id: * }>
          }) | ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: * }>
          }) | ({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: * }>
          }) | ({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: * }>
          }) | ({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: * }>
          }) | ({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: * }>
          }) | ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: * }>
          }) | ({
              ...{| __typename: 'Partnership' |},
            ...$Pick<Partnership, { id: * }>
          }) | ({
              ...{| __typename: 'Role' |},
            ...$Pick<Role, { id: * }>
          }) | ({
              ...{| __typename: 'File' |},
            ...$Pick<File, { id: * }>
          }) | ({
              ...{| __typename: 'Mask' |},
            ...$Pick<Mask, { id: * }>
          }) | ({
              ...{| __typename: 'MaskEdit' |},
            ...$Pick<MaskEdit, { id: * }>
          }) | ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: * }>
          }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, createdBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, logs: Array<({
              ...{| __typename: 'Log' |},
            ...$Pick<Log, { id: *, translationKey: * }>,
            ...{ entity: ({
                ...{| __typename: 'Product' |},
              ...$Pick<Product, { id: * }>
            }) | ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>
            }) | ({
                ...{| __typename: 'ProductProviderPackage' |},
              ...$Pick<ProductProviderPackage, { id: * }>
            }) | ({
                ...{| __typename: 'Order' |},
              ...$Pick<Order, { id: * }>
            }) | ({
                ...{| __typename: 'OrderItem' |},
              ...$Pick<OrderItem, { id: * }>
            }) | ({
                ...{| __typename: 'Batch' |},
              ...$Pick<Batch, { id: * }>
            }) | ({
                ...{| __typename: 'Shipment' |},
              ...$Pick<Shipment, { id: * }>
            }) | ({
                ...{| __typename: 'Voyage' |},
              ...$Pick<Voyage, { id: * }>
            }) | ({
                ...{| __typename: 'Container' |},
              ...$Pick<Container, { id: * }>
            }) | ({
                ...{| __typename: 'ContainerGroup' |},
              ...$Pick<ContainerGroup, { id: * }>
            }) | ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: * }>
            }) | ({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: * }>
            }) | ({
                ...{| __typename: 'Warehouse' |},
              ...$Pick<Warehouse, { id: * }>
            }) | ({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: * }>
            }) | ({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: * }>
            }) | ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: * }>
            }) | ({
                ...{| __typename: 'Partnership' |},
              ...$Pick<Partnership, { id: * }>
            }) | ({
                ...{| __typename: 'Role' |},
              ...$Pick<Role, { id: * }>
            }) | ({
                ...{| __typename: 'File' |},
              ...$Pick<File, { id: *, type: * }>
            }) | ({
                ...{| __typename: 'Mask' |},
              ...$Pick<Mask, { id: * }>
            }) | ({
                ...{| __typename: 'MaskEdit' |},
              ...$Pick<MaskEdit, { id: * }>
            }) | ({
                ...{| __typename: 'FieldDefinition' |},
              ...$Pick<FieldDefinition, { id: * }>
            }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, parameters: Array<({
                ...{| __typename: 'Parameter' |},
              ...$Pick<Parameter, { key: * }>,
              ...{ value?: ?({
                  ...{| __typename: 'StringValue' |},
                ...$Pick<StringValue, { string: * }>
              }) | ({
                  ...{| __typename: 'IntValue' |},
                ...$Pick<IntValue, { int: * }>
              }) | ({
                  ...{| __typename: 'FloatValue' |},
                ...$Pick<FloatValue, { float: * }>
              }) | ({
                  ...{| __typename: 'BooleanValue' |},
                ...$Pick<BooleanValue, { boolean: * }>
              }) | ({
                  ...{| __typename: 'DateTimeValue' |},
                ...$Pick<DateTimeValue, { datetime: * }>
              }) | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | ({
                  ...{| __typename: 'MetricValueValue' |},
                ...{ metricValue: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }) }
              }) | ({
                  ...{| __typename: 'PriceValue' |},
                ...{ priceValue: ({
                    ...{| __typename: 'NewPrice' |},
                  ...$Pick<NewPrice, { amount: *, currency: * }>
                }) }
              }) | ({
                  ...{| __typename: 'SizeValue' |},
                ...{ size: ({
                    ...{| __typename: 'Size' |},
                  ...{ height: ({
                      ...{| __typename: 'MetricValue' |},
                    ...$Pick<MetricValue, { value: *, metric: * }>
                  }), length: ({
                      ...{| __typename: 'MetricValue' |},
                    ...$Pick<MetricValue, { value: *, metric: * }>
                  }), width: ({
                      ...{| __typename: 'MetricValue' |},
                    ...$Pick<MetricValue, { value: *, metric: * }>
                  }) }
                }) }
              }) | ({
                  ...{| __typename: 'EntityValue' |},
                ...{ entity?: ?({
                    ...{| __typename: 'Product' |},
                  ...$Pick<Product, { id: * }>
                }) | ({
                    ...{| __typename: 'ProductProvider' |},
                  ...$Pick<ProductProvider, { id: * }>
                }) | ({
                    ...{| __typename: 'ProductProviderPackage' |},
                  ...$Pick<ProductProviderPackage, { id: * }>
                }) | ({
                    ...{| __typename: 'Order' |},
                  ...$Pick<Order, { id: * }>
                }) | ({
                    ...{| __typename: 'OrderItem' |},
                  ...$Pick<OrderItem, { id: * }>
                }) | ({
                    ...{| __typename: 'Batch' |},
                  ...$Pick<Batch, { id: * }>
                }) | ({
                    ...{| __typename: 'Shipment' |},
                  ...$Pick<Shipment, { id: * }>
                }) | ({
                    ...{| __typename: 'Voyage' |},
                  ...$Pick<Voyage, { id: * }>
                }) | ({
                    ...{| __typename: 'Container' |},
                  ...$Pick<Container, { id: * }>
                }) | ({
                    ...{| __typename: 'ContainerGroup' |},
                  ...$Pick<ContainerGroup, { id: * }>
                }) | ({
                    ...{| __typename: 'TimelineDate' |},
                  ...$Pick<TimelineDate, { id: * }>
                }) | ({
                    ...{| __typename: 'TimelineDateRevision' |},
                  ...$Pick<TimelineDateRevision, { id: * }>
                }) | ({
                    ...{| __typename: 'Warehouse' |},
                  ...$Pick<Warehouse, { id: * }>
                }) | ({
                    ...{| __typename: 'Tag' |},
                  ...$Pick<Tag, { id: *, name: *, color: * }>
                }) | ({
                    ...{| __typename: 'User' |},
                  ...$Pick<User, { id: *, firstName: *, lastName: * }>
                }) | ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: *, types: * }>,
                  ...{ partner?: ?({
                      ...{| __typename: 'Partner' |},
                    ...$Pick<Partner, { id: *, name?: *, code?: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
                }) | ({
                    ...{| __typename: 'Partnership' |},
                  ...$Pick<Partnership, { id: * }>
                }) | ({
                    ...{| __typename: 'Role' |},
                  ...$Pick<Role, { id: * }>
                }) | ({
                    ...{| __typename: 'File' |},
                  ...$Pick<File, { id: * }>
                }) | ({
                    ...{| __typename: 'Mask' |},
                  ...$Pick<Mask, { id: * }>
                }) | ({
                    ...{| __typename: 'MaskEdit' |},
                  ...$Pick<MaskEdit, { id: * }>
                }) | ({
                    ...{| __typename: 'FieldDefinition' |},
                  ...$Pick<FieldDefinition, { id: * }>
                }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | ({
                  ...{| __typename: 'Values' |},
                ...{ values: Array<?({
                    ...{| __typename: 'StringValue' |},
                  ...$Pick<StringValue, { string: * }>
                }) | ({
                    ...{| __typename: 'IntValue' |},
                  ...$Pick<IntValue, { int: * }>
                }) | ({
                    ...{| __typename: 'FloatValue' |},
                  ...$Pick<FloatValue, { float: * }>
                }) | ({
                    ...{| __typename: 'BooleanValue' |},
                  ...$Pick<BooleanValue, { boolean: * }>
                }) | ({
                    ...{| __typename: 'DateTimeValue' |},
                  ...$Pick<DateTimeValue, { datetime: * }>
                }) | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | ({
                    ...{| __typename: 'MetricValueValue' |},
                  ...{ metricValue: ({
                      ...{| __typename: 'MetricValue' |},
                    ...$Pick<MetricValue, { value: *, metric: * }>
                  }) }
                }) | ({
                    ...{| __typename: 'PriceValue' |},
                  ...{ priceValue: ({
                      ...{| __typename: 'NewPrice' |},
                    ...$Pick<NewPrice, { amount: *, currency: * }>
                  }) }
                }) | ({
                    ...{| __typename: 'SizeValue' |},
                  ...{ size: ({
                      ...{| __typename: 'Size' |},
                    ...{ height: ({
                        ...{| __typename: 'MetricValue' |},
                      ...$Pick<MetricValue, { value: *, metric: * }>
                    }), length: ({
                        ...{| __typename: 'MetricValue' |},
                      ...$Pick<MetricValue, { value: *, metric: * }>
                    }), width: ({
                        ...{| __typename: 'MetricValue' |},
                      ...$Pick<MetricValue, { value: *, metric: * }>
                    }) }
                  }) }
                }) | ({
                    ...{| __typename: 'EntityValue' |},
                  ...{ entity?: ?({
                      ...{| __typename: 'Product' |},
                    ...$Pick<Product, { id: * }>
                  }) | ({
                      ...{| __typename: 'ProductProvider' |},
                    ...$Pick<ProductProvider, { id: * }>
                  }) | ({
                      ...{| __typename: 'ProductProviderPackage' |},
                    ...$Pick<ProductProviderPackage, { id: * }>
                  }) | ({
                      ...{| __typename: 'Order' |},
                    ...$Pick<Order, { id: * }>
                  }) | ({
                      ...{| __typename: 'OrderItem' |},
                    ...$Pick<OrderItem, { id: * }>
                  }) | ({
                      ...{| __typename: 'Batch' |},
                    ...$Pick<Batch, { id: * }>
                  }) | ({
                      ...{| __typename: 'Shipment' |},
                    ...$Pick<Shipment, { id: * }>
                  }) | ({
                      ...{| __typename: 'Voyage' |},
                    ...$Pick<Voyage, { id: * }>
                  }) | ({
                      ...{| __typename: 'Container' |},
                    ...$Pick<Container, { id: * }>
                  }) | ({
                      ...{| __typename: 'ContainerGroup' |},
                    ...$Pick<ContainerGroup, { id: * }>
                  }) | ({
                      ...{| __typename: 'TimelineDate' |},
                    ...$Pick<TimelineDate, { id: * }>
                  }) | ({
                      ...{| __typename: 'TimelineDateRevision' |},
                    ...$Pick<TimelineDateRevision, { id: * }>
                  }) | ({
                      ...{| __typename: 'Warehouse' |},
                    ...$Pick<Warehouse, { id: * }>
                  }) | ({
                      ...{| __typename: 'Tag' |},
                    ...$Pick<Tag, { id: *, name: *, color: * }>
                  }) | ({
                      ...{| __typename: 'User' |},
                    ...$Pick<User, { id: *, firstName: *, lastName: * }>
                  }) | ({
                      ...{| __typename: 'Organization' |},
                    ...$Pick<Organization, { id: *, name: *, types: * }>,
                    ...{ partner?: ?({
                        ...{| __typename: 'Partner' |},
                      ...$Pick<Partner, { id: *, name?: *, code?: * }>
                    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
                  }) | ({
                      ...{| __typename: 'Partnership' |},
                    ...$Pick<Partnership, { id: * }>
                  }) | ({
                      ...{| __typename: 'Role' |},
                    ...$Pick<Role, { id: * }>
                  }) | ({
                      ...{| __typename: 'File' |},
                    ...$Pick<File, { id: * }>
                  }) | ({
                      ...{| __typename: 'Mask' |},
                    ...$Pick<Mask, { id: * }>
                  }) | ({
                      ...{| __typename: 'MaskEdit' |},
                    ...$Pick<MaskEdit, { id: * }>
                  }) | ({
                      ...{| __typename: 'FieldDefinition' |},
                    ...$Pick<FieldDefinition, { id: * }>
                  }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
                }) | {| __typename: 'Values' |}> }
              }) }
            })> }
          })> }
        }) | ({
            ...{| __typename: 'TimelineComment' |},
          ...$Pick<TimelineComment, { id: *, content: *, createdAt: *, updatedAt: * }>,
          ...{ createdBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) }
    }) }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type BatchesExportQueryVariables = {
  templateId: $ElementType<Scalars, 'ID'>,
  filterBy?: ?BatchFilterInput,
  sortBy?: ?BatchSortInput,
  localSortBy?: ?Array<GenericSortInput>,
  columns?: ?Array<$ElementType<Scalars, 'String'>>
};


export type BatchesExportQuery = ({
    ...{| __typename: 'Query' |},
  ...{ batchesExport: ({
      ...{| __typename: 'Export' |},
    ...$Pick<Export, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type BatchesForMoveQueryVariables = {
  ids: Array<$ElementType<Scalars, 'ID'>>
};


export type BatchesForMoveQuery = ({
    ...{| __typename: 'Query' |},
  ...{ batchesByIDs: Array<({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { id: *, latestQuantity: * }>,
    ...{ container?: ?({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: * }>,
      ...{ shipment: ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment?: ?({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, orderItem: ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: *, no: * }>,
      ...{ price: ({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, order: ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, currency: * }>,
        ...{ exporter: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type BatchIdentifiersQueryVariables = {
  id: $ElementType<Scalars, 'ID'>
};


export type BatchIdentifiersQuery = ({
    ...{| __typename: 'Query' |},
  ...{ batch: ({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { id: *, no: * }>,
    ...{ orderItem: ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: *, no: * }>,
      ...{ order: ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, poNo: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: *, serial: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type BatchesSplitQueryVariables = {
  ids: Array<$ElementType<Scalars, 'ID'>>
};


export type BatchesSplitQuery = ({
    ...{| __typename: 'Query' |},
  ...{ batchesByIDs: Array<({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { id: *, no: *, quantity: *, latestQuantity: *, latestQuantityField: * }>,
    ...{ orderItem: ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: *, no: * }>,
      ...{ order: ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, poNo: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: *, serial: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type SelectBatchListQueryVariables = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?BatchFilterInput,
  sortBy?: ?BatchSortInput
};


export type SelectBatchListQuery = ({
    ...{| __typename: 'Query' |},
  ...{ batches: ({
      ...{| __typename: 'BatchPayloadPaginatedSearch' |},
    ...$Pick<BatchPayloadPaginatedSearch, { page: *, totalPage: * }>,
    ...{ nodes: Array<({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: *, sort: *, shipmentSort: *, archived: *, autoCalculatePackageQuantity: *, autoCalculatePackageVolume: *, updatedAt: *, memo?: *, no: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: *, latestQuantityField: *, producedAt?: *, deliveredAt?: *, desiredAt?: *, expiredAt?: *, packageName?: *, packageCapacity?: *, packageQuantity?: * }>,
      ...{ updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalPrice?: ?({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
          ...{| __typename: 'CustomFields' |},
        ...{ fieldValues: Array<({
            ...{| __typename: 'FieldValue' |},
          ...{ value: ({
              ...{| __typename: 'StringValue' |},
            ...$Pick<StringValue, { string: * }>
          }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }), packageGrossWeight?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), packageVolume?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), packageSize?: ?({
          ...{| __typename: 'Size' |},
        ...{ length: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), width: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), height: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }) }
      }), tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, orderItem: ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
        ...{ price: ({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, order: ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
          ...{ totalPrice: ({
              ...{| __typename: 'NewPrice' |},
            ...$Pick<NewPrice, { amount: *, currency: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, timeline: ({
              ...{| __typename: 'Timeline' |},
            ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
          }), ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
              ...{| __typename: 'Notification' |},
            ...$Pick<Notification, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: *, name: * }>,
          ...{ exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
            ...{ grossWeight?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), volume?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), size?: ?({
                ...{| __typename: 'Size' |},
              ...{ length: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), width: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), height: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }) }
            }) }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
            ...{ grossWeight?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), volume?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), size?: ?({
                ...{| __typename: 'Size' |},
              ...{ length: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), width: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), height: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }) }
            }) }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, product: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: *, serial: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | ({
                ...{| __typename: 'Forbidden' |},
              ...$Pick<Forbidden, { reason?: * }>,
              ...{ reference?: ?({
                  ...{| __typename: 'Reference' |},
                ...$Pick<Reference, { id: * }>
              }) }
            }) | {| __typename: 'NotFound' |}>, files: Array<({
                ...{| __typename: 'File' |},
              ...$Pick<File, { id: *, path: * }>,
              ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment?: ?({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
        ...{ totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), totalVolumeOverride?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), timeline: ({
            ...{| __typename: 'Timeline' |},
          ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
        }), containerTypeCounts: Array<({
            ...{| __typename: 'ContainerTypeCount' |},
          ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
        })>, importer?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, voyages: Array<({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
          ...{ departurePort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), arrivalPort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), departure: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>,
          ...{ customClearance: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
          ...{ warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>,
          ...{ orderItem: ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>,
            ...{ productProvider: ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>,
              ...{ exporter: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
            ...{| __typename: 'Notification' |},
          ...$Pick<Notification, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: * }>,
        ...{ representativeBatch?: ?({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>,
          ...{ orderItem: ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>,
            ...{ productProvider: ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>,
              ...{ product: ({
                  ...{| __typename: 'Product' |},
                ...$Pick<Product, { id: *, name: *, serial: * }>,
                ...{ files: Array<({
                    ...{| __typename: 'File' |},
                  ...$Pick<File, { id: *, path: * }>,
                  ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), batches: Array<({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, no: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) }
});

export type EmojiFragment = ({
    ...{| __typename: 'Emoji' |},
  ...$Pick<Emoji, { id: *, decimals: *, name: *, emoji: * }>
});

export type MessageFragment = ({
    ...{| __typename: 'Message' |},
  ...$Pick<Message, { content: *, id: *, createdAt: *, updatedAt: *, deletedAt?: * }>,
  ...{ messageEmoji: Array<({
      ...{| __typename: 'MessageEmoji' |},
    ...{ emoji: ({
        ...{| __typename: 'Emoji' |},
      ...$Pick<Emoji, { id: *, decimals: *, name: *, emoji: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, createdBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, file?: ?({
      ...{| __typename: 'File' |},
    ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
    ...{ tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, entity?: ?({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: *, name: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: *, name: * }>,
      ...{ product: ({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: *, poNo: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: * }>
    }) | ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | ({
        ...{| __typename: 'Partnership' |},
      ...$Pick<Partnership, { id: * }>
    }) | ({
        ...{| __typename: 'Role' |},
      ...$Pick<Role, { id: * }>
    }) | ({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Mask' |},
      ...$Pick<Mask, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'MaskEdit' |},
      ...$Pick<MaskEdit, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'FieldDefinition' |},
      ...$Pick<FieldDefinition, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}, messageGroup?: ?({
      ...{| __typename: 'MessageGroup' |},
    ...$Pick<MessageGroup, { id: *, unreadMessageCount: *, createdAt: *, updatedAt: *, deletedAt?: *, filesUnreadCount: * }>,
    ...{ organizations: Array<({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, createdBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deletedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, files: Array<({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
      ...{ tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, entity?: ?({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, poNo: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | ({
          ...{| __typename: 'Partnership' |},
        ...$Pick<Partnership, { id: * }>
      }) | ({
          ...{| __typename: 'Role' |},
        ...$Pick<Role, { id: * }>
      }) | ({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Mask' |},
        ...$Pick<Mask, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'MaskEdit' |},
        ...$Pick<MaskEdit, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, createdBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deletedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type MessageEmojiFragment = ({
    ...{| __typename: 'MessageEmoji' |},
  ...{ emoji: ({
      ...{| __typename: 'Emoji' |},
    ...$Pick<Emoji, { id: *, decimals: *, name: *, emoji: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, createdBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type MessageGroupFragment = ({
    ...{| __typename: 'MessageGroup' |},
  ...$Pick<MessageGroup, { id: *, unreadMessageCount: *, createdAt: *, updatedAt: *, deletedAt?: *, filesUnreadCount: * }>,
  ...{ organizations: Array<({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, createdBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deletedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, files: Array<({
      ...{| __typename: 'File' |},
    ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
    ...{ tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, entity?: ?({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: *, name: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: *, name: * }>,
      ...{ product: ({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: *, poNo: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: * }>
    }) | ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | ({
        ...{| __typename: 'Partnership' |},
      ...$Pick<Partnership, { id: * }>
    }) | ({
        ...{| __typename: 'Role' |},
      ...$Pick<Role, { id: * }>
    }) | ({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Mask' |},
      ...$Pick<Mask, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'MaskEdit' |},
      ...$Pick<MaskEdit, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'FieldDefinition' |},
      ...$Pick<FieldDefinition, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type MessageGroupUnreadFragment = ({
    ...{| __typename: 'MessageGroup' |},
  ...$Pick<MessageGroup, { unreadMessageCount: *, filesUnreadCount: * }>
});

export type DraftMessageUpdateMutationVariables = {
  input: DraftMessageUpdateInput
};


export type DraftMessageUpdateMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ draftMessage: ({
      ...{| __typename: 'DraftMessage' |},
    ...$Pick<DraftMessage, { content: * }>
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |} }
});

export type MessageCreateMutationVariables = {
  input: MessageCreateInput
};


export type MessageCreateMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ messageCreate: ({
      ...{| __typename: 'Message' |},
    ...$Pick<Message, { content: *, id: *, createdAt: *, updatedAt: *, deletedAt?: * }>,
    ...{ messageEmoji: Array<({
        ...{| __typename: 'MessageEmoji' |},
      ...{ emoji: ({
          ...{| __typename: 'Emoji' |},
        ...$Pick<Emoji, { id: *, decimals: *, name: *, emoji: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, createdBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, file?: ?({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
      ...{ tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, entity?: ?({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, poNo: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | ({
          ...{| __typename: 'Partnership' |},
        ...$Pick<Partnership, { id: * }>
      }) | ({
          ...{| __typename: 'Role' |},
        ...$Pick<Role, { id: * }>
      }) | ({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Mask' |},
        ...$Pick<Mask, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'MaskEdit' |},
        ...$Pick<MaskEdit, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'BadRequest' |},
      ...{ violations: Array<({
          ...{| __typename: 'Violation' |},
        ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
        ...{ parameters: Array<({
            ...{| __typename: 'ViolationParameter' |},
          ...$Pick<ViolationParameter, { key: *, value: * }>
        })> }
      })> }
    }) | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}, messageGroup?: ?({
        ...{| __typename: 'MessageGroup' |},
      ...$Pick<MessageGroup, { id: *, unreadMessageCount: *, createdAt: *, updatedAt: *, deletedAt?: *, filesUnreadCount: * }>,
      ...{ organizations: Array<({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, createdBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deletedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, files: Array<({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
        ...{ tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, entity?: ?({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: *, name: * }>,
          ...{ product: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: *, poNo: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | ({
            ...{| __typename: 'Partnership' |},
          ...$Pick<Partnership, { id: * }>
        }) | ({
            ...{| __typename: 'Role' |},
          ...$Pick<Role, { id: * }>
        }) | ({
            ...{| __typename: 'File' |},
          ...$Pick<File, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Mask' |},
          ...$Pick<Mask, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'MaskEdit' |},
          ...$Pick<MaskEdit, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, createdBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deletedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type MessageDeleteMutationVariables = {
  id: $ElementType<Scalars, 'ID'>
};


export type MessageDeleteMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ messageDelete?: ?({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type MessageEmojiUpdateMutationVariables = {
  input: MessageEmojiUpdateInput
};


export type MessageEmojiUpdateMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ messageEmojiUpdate: ({
      ...{| __typename: 'Message' |},
    ...$Pick<Message, { id: * }>,
    ...{ messageEmoji: Array<({
        ...{| __typename: 'MessageEmoji' |},
      ...{ emoji: ({
          ...{| __typename: 'Emoji' |},
        ...$Pick<Emoji, { id: *, decimals: *, name: *, emoji: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, createdBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |} }
});

export type MessageGroupCreateMutationVariables = {
  input: MessageGroupCreateInput
};


export type MessageGroupCreateMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ messageGroupCreate: ({
      ...{| __typename: 'MessageGroup' |},
    ...$Pick<MessageGroup, { id: *, unreadMessageCount: *, createdAt: *, updatedAt: *, deletedAt?: *, filesUnreadCount: * }>,
    ...{ organizations: Array<({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, createdBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deletedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, files: Array<({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
      ...{ tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, entity?: ?({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, poNo: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | ({
          ...{| __typename: 'Partnership' |},
        ...$Pick<Partnership, { id: * }>
      }) | ({
          ...{| __typename: 'Role' |},
        ...$Pick<Role, { id: * }>
      }) | ({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Mask' |},
        ...$Pick<Mask, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'MaskEdit' |},
        ...$Pick<MaskEdit, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |} }
});

export type MessageGroupMarkAsReadMutationVariables = {
  messageGroupId: $ElementType<Scalars, 'ID'>
};


export type MessageGroupMarkAsReadMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ messageGroupMarkAsRead?: ?({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |} }
});

export type MessageMarkAsReadMutationVariables = {
  entity: EntityInput
};


export type MessageMarkAsReadMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ messageMarkAsRead?: ?({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |} }
});

export type MessageUpdateMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  input: MessageUpdateInput
};


export type MessageUpdateMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ messageUpdate: ({
      ...{| __typename: 'Message' |},
    ...$Pick<Message, { content: *, id: *, createdAt: *, updatedAt: *, deletedAt?: * }>,
    ...{ messageEmoji: Array<({
        ...{| __typename: 'MessageEmoji' |},
      ...{ emoji: ({
          ...{| __typename: 'Emoji' |},
        ...$Pick<Emoji, { id: *, decimals: *, name: *, emoji: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, createdBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, file?: ?({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
      ...{ tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, entity?: ?({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, poNo: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | ({
          ...{| __typename: 'Partnership' |},
        ...$Pick<Partnership, { id: * }>
      }) | ({
          ...{| __typename: 'Role' |},
        ...$Pick<Role, { id: * }>
      }) | ({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Mask' |},
        ...$Pick<Mask, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'MaskEdit' |},
        ...$Pick<MaskEdit, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'BadRequest' |},
      ...{ violations: Array<({
          ...{| __typename: 'Violation' |},
        ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
        ...{ parameters: Array<({
            ...{| __typename: 'ViolationParameter' |},
          ...$Pick<ViolationParameter, { key: *, value: * }>
        })> }
      })> }
    }) | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}, messageGroup?: ?({
        ...{| __typename: 'MessageGroup' |},
      ...$Pick<MessageGroup, { id: *, unreadMessageCount: *, createdAt: *, updatedAt: *, deletedAt?: *, filesUnreadCount: * }>,
      ...{ organizations: Array<({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, createdBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deletedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, files: Array<({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
        ...{ tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, entity?: ?({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: *, name: * }>,
          ...{ product: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: *, poNo: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | ({
            ...{| __typename: 'Partnership' |},
          ...$Pick<Partnership, { id: * }>
        }) | ({
            ...{| __typename: 'Role' |},
          ...$Pick<Role, { id: * }>
        }) | ({
            ...{| __typename: 'File' |},
          ...$Pick<File, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Mask' |},
          ...$Pick<Mask, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'MaskEdit' |},
          ...$Pick<MaskEdit, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, createdBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deletedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type DraftMessageQueryVariables = {
  input: DraftMessageGetInput
};


export type DraftMessageQuery = ({
    ...{| __typename: 'Query' |},
  ...{ draftMessage: ({
      ...{| __typename: 'DraftMessage' |},
    ...$Pick<DraftMessage, { content: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type EmojisQueryVariables = {};


export type EmojisQuery = ({
    ...{| __typename: 'Query' |},
  ...{ emojis: Array<({
      ...{| __typename: 'Emoji' |},
    ...$Pick<Emoji, { id: *, decimals: *, name: *, emoji: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type MessagePreferencesQueryVariables = {};


export type MessagePreferencesQuery = ({
    ...{| __typename: 'Query' |},
  ...{ viewer: ({
      ...{| __typename: 'Viewer' |},
    ...{ messagePreferences: ({
        ...{| __typename: 'MessagePreferences' |},
      ...$Pick<MessagePreferences, { sendMessageByEnter: * }>
    }) }
  }) }
});

export type MessagesQueryVariables = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?MessageFilterInput,
  sortBy?: ?MessageSortInput
};


export type MessagesQuery = ({
    ...{| __typename: 'Query' |},
  ...{ messages: ({
      ...{| __typename: 'MessagePayloadPaginatedSearch' |},
    ...$Pick<MessagePayloadPaginatedSearch, { page: *, perPage: *, totalPage: *, count: *, totalCount: * }>,
    ...{ nodes: Array<({
        ...{| __typename: 'Message' |},
      ...$Pick<Message, { content: *, id: *, createdAt: *, updatedAt: *, deletedAt?: * }>,
      ...{ messageEmoji: Array<({
          ...{| __typename: 'MessageEmoji' |},
        ...{ emoji: ({
            ...{| __typename: 'Emoji' |},
          ...$Pick<Emoji, { id: *, decimals: *, name: *, emoji: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, createdBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, file?: ?({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
        ...{ tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, entity?: ?({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: *, name: * }>,
          ...{ product: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: *, poNo: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | ({
            ...{| __typename: 'Partnership' |},
          ...$Pick<Partnership, { id: * }>
        }) | ({
            ...{| __typename: 'Role' |},
          ...$Pick<Role, { id: * }>
        }) | ({
            ...{| __typename: 'File' |},
          ...$Pick<File, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Mask' |},
          ...$Pick<Mask, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'MaskEdit' |},
          ...$Pick<MaskEdit, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'BadRequest' |},
        ...{ violations: Array<({
            ...{| __typename: 'Violation' |},
          ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
          ...{ parameters: Array<({
              ...{| __typename: 'ViolationParameter' |},
            ...$Pick<ViolationParameter, { key: *, value: * }>
          })> }
        })> }
      }) | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}, messageGroup?: ?({
          ...{| __typename: 'MessageGroup' |},
        ...$Pick<MessageGroup, { id: *, unreadMessageCount: *, createdAt: *, updatedAt: *, deletedAt?: *, filesUnreadCount: * }>,
        ...{ organizations: Array<({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, createdBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deletedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, files: Array<({
            ...{| __typename: 'File' |},
          ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
          ...{ tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, entity?: ?({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: *, name: * }>,
            ...{ product: ({
                ...{| __typename: 'Product' |},
              ...$Pick<Product, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Order' |},
            ...$Pick<Order, { id: *, poNo: * }>,
            ...{ connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: *, no: * }>,
            ...{ connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: *, no: * }>,
            ...{ connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Shipment' |},
            ...$Pick<Shipment, { id: *, no: * }>,
            ...{ connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Voyage' |},
            ...$Pick<Voyage, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Container' |},
            ...$Pick<Container, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'ContainerGroup' |},
            ...$Pick<ContainerGroup, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: * }>
          }) | ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: * }>
          }) | ({
              ...{| __typename: 'Partnership' |},
            ...$Pick<Partnership, { id: * }>
          }) | ({
              ...{| __typename: 'Role' |},
            ...$Pick<Role, { id: * }>
          }) | ({
              ...{| __typename: 'File' |},
            ...$Pick<File, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Mask' |},
            ...$Pick<Mask, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'MaskEdit' |},
            ...$Pick<MaskEdit, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, createdBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deletedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) }
});

export type MessageNewSubscriptionVariables = {
  entity: EntityInput,
  messageGroupId?: ?$ElementType<Scalars, 'ID'>
};


export type MessageNewSubscription = ({
    ...{| __typename: 'Subscription' |},
  ...{ messageNew: ({
      ...{| __typename: 'Message' |},
    ...$Pick<Message, { content: *, id: *, createdAt: *, updatedAt: *, deletedAt?: * }>,
    ...{ messageEmoji: Array<({
        ...{| __typename: 'MessageEmoji' |},
      ...{ emoji: ({
          ...{| __typename: 'Emoji' |},
        ...$Pick<Emoji, { id: *, decimals: *, name: *, emoji: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, createdBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, file?: ?{| __typename: 'File' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, messageGroup?: ?{| __typename: 'MessageGroup' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, createdBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deletedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) }
});

export type CommentFragment = ({
    ...{| __typename: 'TimelineComment' |},
  ...$Pick<TimelineComment, { id: *, content: *, createdAt: *, updatedAt: * }>,
  ...{ createdBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type ConnectionFragment = ({
    ...{| __typename: 'Connection' |},
  ...$Pick<Connection, { id: *, displayName: * }>
});

export type ConnectionByWithOrganizationsFragment = ({
    ...{| __typename: 'Connection' |},
  ...$Pick<Connection, { id: *, displayName: * }>,
  ...{ organizations: Array<({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type CustomFieldsFragment = ({
    ...{| __typename: 'CustomFields' |},
  ...{ fieldValues: Array<({
      ...{| __typename: 'FieldValue' |},
    ...{ value: ({
        ...{| __typename: 'StringValue' |},
      ...$Pick<StringValue, { string: * }>
    }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
        ...{| __typename: 'FieldDefinition' |},
      ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type EnumFragment = ({
    ...{| __typename: '__EnumValue' |},
  ...$Pick<__EnumValue, { name: *, description?: * }>
});

export type EventFragment = ({
    ...{| __typename: 'TimelineEvent' |},
  ...$Pick<TimelineEvent, { id: *, createdAt: * }>,
  ...{ entity: ({
      ...{| __typename: 'Product' |},
    ...$Pick<Product, { id: * }>
  }) | ({
      ...{| __typename: 'ProductProvider' |},
    ...$Pick<ProductProvider, { id: * }>
  }) | ({
      ...{| __typename: 'ProductProviderPackage' |},
    ...$Pick<ProductProviderPackage, { id: * }>
  }) | ({
      ...{| __typename: 'Order' |},
    ...$Pick<Order, { id: * }>
  }) | ({
      ...{| __typename: 'OrderItem' |},
    ...$Pick<OrderItem, { id: * }>
  }) | ({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { id: * }>
  }) | ({
      ...{| __typename: 'Shipment' |},
    ...$Pick<Shipment, { id: * }>
  }) | ({
      ...{| __typename: 'Voyage' |},
    ...$Pick<Voyage, { id: * }>
  }) | ({
      ...{| __typename: 'Container' |},
    ...$Pick<Container, { id: * }>
  }) | ({
      ...{| __typename: 'ContainerGroup' |},
    ...$Pick<ContainerGroup, { id: * }>
  }) | ({
      ...{| __typename: 'TimelineDate' |},
    ...$Pick<TimelineDate, { id: * }>
  }) | ({
      ...{| __typename: 'TimelineDateRevision' |},
    ...$Pick<TimelineDateRevision, { id: * }>
  }) | ({
      ...{| __typename: 'Warehouse' |},
    ...$Pick<Warehouse, { id: * }>
  }) | ({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: * }>
  }) | ({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: * }>
  }) | ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | ({
      ...{| __typename: 'Partnership' |},
    ...$Pick<Partnership, { id: * }>
  }) | ({
      ...{| __typename: 'Role' |},
    ...$Pick<Role, { id: * }>
  }) | ({
      ...{| __typename: 'File' |},
    ...$Pick<File, { id: * }>
  }) | ({
      ...{| __typename: 'Mask' |},
    ...$Pick<Mask, { id: * }>
  }) | ({
      ...{| __typename: 'MaskEdit' |},
    ...$Pick<MaskEdit, { id: * }>
  }) | ({
      ...{| __typename: 'FieldDefinition' |},
    ...$Pick<FieldDefinition, { id: * }>
  }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, createdBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, logs: Array<({
      ...{| __typename: 'Log' |},
    ...$Pick<Log, { id: *, translationKey: * }>,
    ...{ entity: ({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: * }>
    }) | ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: * }>
    }) | ({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: * }>
    }) | ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: * }>
    }) | ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: * }>
    }) | ({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: * }>
    }) | ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: * }>
    }) | ({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: * }>
    }) | ({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: * }>
    }) | ({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>
    }) | ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: * }>
    }) | ({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: * }>
    }) | ({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: * }>
    }) | ({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: * }>
    }) | ({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: * }>
    }) | ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | ({
        ...{| __typename: 'Partnership' |},
      ...$Pick<Partnership, { id: * }>
    }) | ({
        ...{| __typename: 'Role' |},
      ...$Pick<Role, { id: * }>
    }) | ({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: *, type: * }>
    }) | ({
        ...{| __typename: 'Mask' |},
      ...$Pick<Mask, { id: * }>
    }) | ({
        ...{| __typename: 'MaskEdit' |},
      ...$Pick<MaskEdit, { id: * }>
    }) | ({
        ...{| __typename: 'FieldDefinition' |},
      ...$Pick<FieldDefinition, { id: * }>
    }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, parameters: Array<({
        ...{| __typename: 'Parameter' |},
      ...$Pick<Parameter, { key: * }>,
      ...{ value?: ?({
          ...{| __typename: 'StringValue' |},
        ...$Pick<StringValue, { string: * }>
      }) | ({
          ...{| __typename: 'IntValue' |},
        ...$Pick<IntValue, { int: * }>
      }) | ({
          ...{| __typename: 'FloatValue' |},
        ...$Pick<FloatValue, { float: * }>
      }) | ({
          ...{| __typename: 'BooleanValue' |},
        ...$Pick<BooleanValue, { boolean: * }>
      }) | ({
          ...{| __typename: 'DateTimeValue' |},
        ...$Pick<DateTimeValue, { datetime: * }>
      }) | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | ({
          ...{| __typename: 'MetricValueValue' |},
        ...{ metricValue: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }) }
      }) | ({
          ...{| __typename: 'PriceValue' |},
        ...{ priceValue: ({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) }
      }) | ({
          ...{| __typename: 'SizeValue' |},
        ...{ size: ({
            ...{| __typename: 'Size' |},
          ...{ height: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), length: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), width: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }) }
        }) }
      }) | ({
          ...{| __typename: 'EntityValue' |},
        ...{ entity?: ?({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: * }>
        }) | ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: * }>
        }) | ({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: * }>
        }) | ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: * }>
        }) | ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>
        }) | ({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>
        }) | ({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: * }>
        }) | ({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: * }>
        }) | ({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: * }>
        }) | ({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>
        }) | ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: * }>
        }) | ({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: * }>
        }) | ({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: * }>
        }) | ({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>
        }) | ({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: * }>
        }) | ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Partnership' |},
          ...$Pick<Partnership, { id: * }>
        }) | ({
            ...{| __typename: 'Role' |},
          ...$Pick<Role, { id: * }>
        }) | ({
            ...{| __typename: 'File' |},
          ...$Pick<File, { id: * }>
        }) | ({
            ...{| __typename: 'Mask' |},
          ...$Pick<Mask, { id: * }>
        }) | ({
            ...{| __typename: 'MaskEdit' |},
          ...$Pick<MaskEdit, { id: * }>
        }) | ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: * }>
        }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Values' |},
        ...{ values: Array<?({
            ...{| __typename: 'StringValue' |},
          ...$Pick<StringValue, { string: * }>
        }) | ({
            ...{| __typename: 'IntValue' |},
          ...$Pick<IntValue, { int: * }>
        }) | ({
            ...{| __typename: 'FloatValue' |},
          ...$Pick<FloatValue, { float: * }>
        }) | ({
            ...{| __typename: 'BooleanValue' |},
          ...$Pick<BooleanValue, { boolean: * }>
        }) | ({
            ...{| __typename: 'DateTimeValue' |},
          ...$Pick<DateTimeValue, { datetime: * }>
        }) | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | ({
            ...{| __typename: 'MetricValueValue' |},
          ...{ metricValue: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }) }
        }) | ({
            ...{| __typename: 'PriceValue' |},
          ...{ priceValue: ({
              ...{| __typename: 'NewPrice' |},
            ...$Pick<NewPrice, { amount: *, currency: * }>
          }) }
        }) | ({
            ...{| __typename: 'SizeValue' |},
          ...{ size: ({
              ...{| __typename: 'Size' |},
            ...{ height: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), length: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), width: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }) }
          }) }
        }) | ({
            ...{| __typename: 'EntityValue' |},
          ...{ entity?: ?({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: * }>
          }) | ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>
          }) | ({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: * }>
          }) | ({
              ...{| __typename: 'Order' |},
            ...$Pick<Order, { id: * }>
          }) | ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>
          }) | ({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: * }>
          }) | ({
              ...{| __typename: 'Shipment' |},
            ...$Pick<Shipment, { id: * }>
          }) | ({
              ...{| __typename: 'Voyage' |},
            ...$Pick<Voyage, { id: * }>
          }) | ({
              ...{| __typename: 'Container' |},
            ...$Pick<Container, { id: * }>
          }) | ({
              ...{| __typename: 'ContainerGroup' |},
            ...$Pick<ContainerGroup, { id: * }>
          }) | ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: * }>
          }) | ({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: * }>
          }) | ({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: * }>
          }) | ({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>
          }) | ({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: * }>
          }) | ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Partnership' |},
            ...$Pick<Partnership, { id: * }>
          }) | ({
              ...{| __typename: 'Role' |},
            ...$Pick<Role, { id: * }>
          }) | ({
              ...{| __typename: 'File' |},
            ...$Pick<File, { id: * }>
          }) | ({
              ...{| __typename: 'Mask' |},
            ...$Pick<Mask, { id: * }>
          }) | ({
              ...{| __typename: 'MaskEdit' |},
            ...$Pick<MaskEdit, { id: * }>
          }) | ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: * }>
          }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'Values' |}> }
      }) }
    })> }
  })> }
});

export type FieldDefinitionFragment = ({
    ...{| __typename: 'FieldDefinition' |},
  ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type FieldValuesFragment = ({
    ...{| __typename: 'FieldValue' |},
  ...{ value: ({
      ...{| __typename: 'StringValue' |},
    ...$Pick<StringValue, { string: * }>
  }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
      ...{| __typename: 'FieldDefinition' |},
    ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type HitFragment = ({
    ...{| __typename: 'Hit' |},
  ...{ entityHits: Array<({
      ...{| __typename: 'EntityHit' |},
    ...$Pick<EntityHit, { field?: * }>,
    ...{ entity: ({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: * }>
    }) | ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: * }>
    }) | ({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: * }>
    }) | ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: * }>
    }) | ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: * }>
    }) | ({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: * }>
    }) | ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: * }>
    }) | ({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: * }>
    }) | ({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: * }>
    }) | ({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>
    }) | ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: * }>
    }) | ({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: * }>
    }) | ({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: * }>
    }) | ({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: * }>
    }) | ({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: * }>
    }) | ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | ({
        ...{| __typename: 'Partnership' |},
      ...$Pick<Partnership, { id: * }>
    }) | ({
        ...{| __typename: 'Role' |},
      ...$Pick<Role, { id: * }>
    }) | ({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: * }>
    }) | ({
        ...{| __typename: 'Mask' |},
      ...$Pick<Mask, { id: * }>
    }) | ({
        ...{| __typename: 'MaskEdit' |},
      ...$Pick<MaskEdit, { id: * }>
    }) | ({
        ...{| __typename: 'FieldDefinition' |},
      ...$Pick<FieldDefinition, { id: * }>
    }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  })> }
});

export type ImageFragment = ({
    ...{| __typename: 'File' |},
  ...$Pick<File, { id: *, path: * }>,
  ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
});

export type MaskFragment = ({
    ...{| __typename: 'Mask' |},
  ...$Pick<Mask, { id: *, name: *, memo?: *, entityType: * }>,
  ...{ fieldDefinitions: Array<({
      ...{| __typename: 'FieldDefinition' |},
    ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type MetricFragment = ({
    ...{| __typename: 'MetricValue' |},
  ...$Pick<MetricValue, { value: *, metric: * }>
});

export type MinimalOrganizationFragment = ({
    ...{| __typename: 'Organization' |},
  ...$Pick<Organization, { id: *, name: *, types: * }>
});

export type OrganizationFragment = ({
    ...{| __typename: 'Organization' |},
  ...$Pick<Organization, { id: *, name: *, types: * }>
});

export type PartnerNameFragment = ({
    ...{| __typename: 'Organization' |},
  ...$Pick<Organization, { id: *, name: *, types: * }>,
  ...{ partner?: ?({
      ...{| __typename: 'Partner' |},
    ...$Pick<Partner, { id: *, name?: *, code?: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type PortFragment = ({
    ...{| __typename: 'Port' |},
  ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
});

export type PriceFragment = ({
    ...{| __typename: 'NewPrice' |},
  ...$Pick<NewPrice, { amount: *, currency: * }>
});

export type SizeFragment = ({
    ...{| __typename: 'Size' |},
  ...{ length: ({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), width: ({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), height: ({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }) }
});

export type TagFragment = ({
    ...{| __typename: 'Tag' |},
  ...$Pick<Tag, { id: *, name: *, color: * }>,
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type TimelineDateFullFragment = ({
    ...{| __typename: 'TimelineDate' |},
  ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
  ...{ connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
      ...{| __typename: 'TimelineDateRevision' |},
    ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
    ...{ updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type TimelineDateMinimalFragment = ({
    ...{| __typename: 'TimelineDate' |},
  ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
  ...{ approvedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
      ...{| __typename: 'TimelineDateRevision' |},
    ...$Pick<TimelineDateRevision, { id: *, date: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type TimelineDateRevisionFullFragment = ({
    ...{| __typename: 'TimelineDateRevision' |},
  ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
  ...{ updatedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type Value_StringValue_Fragment = ({
    ...{| __typename: 'StringValue' |},
  ...$Pick<StringValue, { string: * }>
});

export type Value_IntValue_Fragment = ({
    ...{| __typename: 'IntValue' |},
  ...$Pick<IntValue, { int: * }>
});

export type Value_FloatValue_Fragment = ({
    ...{| __typename: 'FloatValue' |},
  ...$Pick<FloatValue, { float: * }>
});

export type Value_BooleanValue_Fragment = ({
    ...{| __typename: 'BooleanValue' |},
  ...$Pick<BooleanValue, { boolean: * }>
});

export type Value_DateTimeValue_Fragment = ({
    ...{| __typename: 'DateTimeValue' |},
  ...$Pick<DateTimeValue, { datetime: * }>
});

export type Value_IntervalValue_Fragment = {| __typename: 'IntervalValue' |};

export type Value_DurationValue_Fragment = {| __typename: 'DurationValue' |};

export type Value_MetricValueValue_Fragment = ({
    ...{| __typename: 'MetricValueValue' |},
  ...{ metricValue: ({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }) }
});

export type Value_PriceValue_Fragment = ({
    ...{| __typename: 'PriceValue' |},
  ...{ priceValue: ({
      ...{| __typename: 'NewPrice' |},
    ...$Pick<NewPrice, { amount: *, currency: * }>
  }) }
});

export type Value_SizeValue_Fragment = ({
    ...{| __typename: 'SizeValue' |},
  ...{ size: ({
      ...{| __typename: 'Size' |},
    ...{ height: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), length: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), width: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }) }
  }) }
});

export type Value_EntityValue_Fragment = ({
    ...{| __typename: 'EntityValue' |},
  ...{ entity?: ?({
      ...{| __typename: 'Product' |},
    ...$Pick<Product, { id: * }>
  }) | ({
      ...{| __typename: 'ProductProvider' |},
    ...$Pick<ProductProvider, { id: * }>
  }) | ({
      ...{| __typename: 'ProductProviderPackage' |},
    ...$Pick<ProductProviderPackage, { id: * }>
  }) | ({
      ...{| __typename: 'Order' |},
    ...$Pick<Order, { id: * }>
  }) | ({
      ...{| __typename: 'OrderItem' |},
    ...$Pick<OrderItem, { id: * }>
  }) | ({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { id: * }>
  }) | ({
      ...{| __typename: 'Shipment' |},
    ...$Pick<Shipment, { id: * }>
  }) | ({
      ...{| __typename: 'Voyage' |},
    ...$Pick<Voyage, { id: * }>
  }) | ({
      ...{| __typename: 'Container' |},
    ...$Pick<Container, { id: * }>
  }) | ({
      ...{| __typename: 'ContainerGroup' |},
    ...$Pick<ContainerGroup, { id: * }>
  }) | ({
      ...{| __typename: 'TimelineDate' |},
    ...$Pick<TimelineDate, { id: * }>
  }) | ({
      ...{| __typename: 'TimelineDateRevision' |},
    ...$Pick<TimelineDateRevision, { id: * }>
  }) | ({
      ...{| __typename: 'Warehouse' |},
    ...$Pick<Warehouse, { id: * }>
  }) | ({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>
  }) | ({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: * }>
  }) | ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>,
    ...{ partner?: ?({
        ...{| __typename: 'Partner' |},
      ...$Pick<Partner, { id: *, name?: *, code?: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | ({
      ...{| __typename: 'Partnership' |},
    ...$Pick<Partnership, { id: * }>
  }) | ({
      ...{| __typename: 'Role' |},
    ...$Pick<Role, { id: * }>
  }) | ({
      ...{| __typename: 'File' |},
    ...$Pick<File, { id: * }>
  }) | ({
      ...{| __typename: 'Mask' |},
    ...$Pick<Mask, { id: * }>
  }) | ({
      ...{| __typename: 'MaskEdit' |},
    ...$Pick<MaskEdit, { id: * }>
  }) | ({
      ...{| __typename: 'FieldDefinition' |},
    ...$Pick<FieldDefinition, { id: * }>
  }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type Value_Values_Fragment = {| __typename: 'Values' |};

export type ValueFragment = Value_StringValue_Fragment | Value_IntValue_Fragment | Value_FloatValue_Fragment | Value_BooleanValue_Fragment | Value_DateTimeValue_Fragment | Value_IntervalValue_Fragment | Value_DurationValue_Fragment | Value_MetricValueValue_Fragment | Value_PriceValue_Fragment | Value_SizeValue_Fragment | Value_EntityValue_Fragment | Value_Values_Fragment;

export type EnumQueryVariables = {
  enum: $ElementType<Scalars, 'String'>
};


export type EnumQuery = ({
    ...{| __typename: 'Query' |},
  ...{ __type?: ?({
      ...{| __typename: '__Type' |},
    ...{ enumValues?: ?Array<({
        ...{| __typename: '__EnumValue' |},
      ...$Pick<__EnumValue, { name: *, description?: * }>
    })> }
  }) }
});

export type OrganizationsByIDsQueryVariables = {
  ids: Array<$ElementType<Scalars, 'ID'>>
};


export type OrganizationsByIDsQuery = ({
    ...{| __typename: 'Query' |},
  ...{ organizationsByIDs: Array<({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>,
    ...{ partner?: ?({
        ...{| __typename: 'Partner' |},
      ...$Pick<Partner, { id: *, name?: *, code?: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type Connected_Tag_Fragment = ({
    ...{| __typename: 'Tag' |},
  ...{ connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type Connected_FieldDefinition_Fragment = ({
    ...{| __typename: 'FieldDefinition' |},
  ...{ connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type Connected_ProductProvider_Fragment = ({
    ...{| __typename: 'ProductProvider' |},
  ...{ connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type Connected_ProductProviderPackage_Fragment = ({
    ...{| __typename: 'ProductProviderPackage' |},
  ...{ connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type Connected_Batch_Fragment = ({
    ...{| __typename: 'Batch' |},
  ...{ connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type Connected_OrderItem_Fragment = ({
    ...{| __typename: 'OrderItem' |},
  ...{ connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type Connected_Order_Fragment = ({
    ...{| __typename: 'Order' |},
  ...{ connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type Connected_Shipment_Fragment = ({
    ...{| __typename: 'Shipment' |},
  ...{ connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type Connected_TimelineDate_Fragment = ({
    ...{| __typename: 'TimelineDate' |},
  ...{ connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type Connected_TimelineDateRevision_Fragment = ({
    ...{| __typename: 'TimelineDateRevision' |},
  ...{ connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type Connected_Voyage_Fragment = ({
    ...{| __typename: 'Voyage' |},
  ...{ connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type Connected_ContainerGroup_Fragment = ({
    ...{| __typename: 'ContainerGroup' |},
  ...{ connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type Connected_Container_Fragment = ({
    ...{| __typename: 'Container' |},
  ...{ connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type ConnectedFragment = Connected_Tag_Fragment | Connected_FieldDefinition_Fragment | Connected_ProductProvider_Fragment | Connected_ProductProviderPackage_Fragment | Connected_Batch_Fragment | Connected_OrderItem_Fragment | Connected_Order_Fragment | Connected_Shipment_Fragment | Connected_TimelineDate_Fragment | Connected_TimelineDateRevision_Fragment | Connected_Voyage_Fragment | Connected_ContainerGroup_Fragment | Connected_Container_Fragment;

export type ContainerCardFragment = ({
    ...{| __typename: 'Container' |},
  ...$Pick<Container, { id: *, archived: *, no: *, containerType?: *, containerOption?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: *, freeTimeStartDate?: *, freeTimeDuration?: * }>,
  ...{ representativeBatch?: ?({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { id: * }>,
    ...{ orderItem: ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: * }>,
      ...{ productProvider: ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: * }>,
        ...{ product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: *, serial: * }>,
          ...{ files: Array<({
              ...{| __typename: 'File' |},
            ...$Pick<File, { id: *, path: * }>,
            ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), batches: Array<({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
      ...{| __typename: 'Warehouse' |},
    ...$Pick<Warehouse, { id: *, name: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
      ...{| __typename: 'Shipment' |},
    ...$Pick<Shipment, { id: *, no: * }>,
    ...{ importer?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type ContainerCardWithOwnedFragment = ({
    ...{| __typename: 'Container' |},
  ...$Pick<Container, { id: *, archived: *, no: *, containerType?: *, containerOption?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: *, freeTimeStartDate?: *, freeTimeDuration?: * }>,
  ...{ warehouse?: ?({
      ...{| __typename: 'Warehouse' |},
    ...$Pick<Warehouse, { id: *, name: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, representativeBatch?: ?({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { id: * }>,
    ...{ orderItem: ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: * }>,
      ...{ productProvider: ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: * }>,
        ...{ product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: *, serial: * }>,
          ...{ files: Array<({
              ...{| __typename: 'File' |},
            ...$Pick<File, { id: *, path: * }>,
            ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), batches: Array<({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouseArrivalAgreedDateApprovedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
      ...{| __typename: 'Shipment' |},
    ...$Pick<Shipment, { id: *, no: * }>,
    ...{ importer?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type ContainerEntityCardFragment = ({
    ...{| __typename: 'Container' |},
  ...$Pick<Container, { id: *, no: *, archived: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: *, containerType?: * }>,
  ...{ tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, terminalArrivalPort?: ?({
      ...{| __typename: 'Port' |},
    ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
  }), terminalArrival: ({
      ...{| __typename: 'TimelineDate' |},
    ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
    ...{ approvedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: *, date: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
      ...{| __typename: 'Warehouse' |},
    ...$Pick<Warehouse, { id: *, name: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
      ...{| __typename: 'Shipment' |},
    ...$Pick<Shipment, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type ContainerFormFragment = ({
    ...{| __typename: 'Container' |},
  ...$Pick<Container, { id: *, archived: *, updatedAt: *, no: *, containerType?: *, containerOption?: *, memo?: *, vanningDate?: *, vanningDateApprovedAt?: *, vanningActualDate?: *, vanningActualDateApprovedAt?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: *, freeTimeStartDate?: *, freeTimeDuration?: *, autoCalculatedFreeTimeStartDate: *, yardName?: *, departureDate?: *, departureDateApprovedAt?: * }>,
  ...{ updatedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
      ...{| __typename: 'Warehouse' |},
    ...$Pick<Warehouse, { id: *, name: *, archived: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, terminalArrivalPort?: ?({
      ...{| __typename: 'Port' |},
    ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
  }), terminalArrival: ({
      ...{| __typename: 'TimelineDate' |},
    ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
    ...{ approvedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: *, date: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, vanningDateApprovedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, vanningActualDateApprovedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, departureDateApprovedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, customFields: ({
      ...{| __typename: 'CustomFields' |},
    ...{ fieldValues: Array<({
        ...{| __typename: 'FieldValue' |},
      ...{ value: ({
          ...{| __typename: 'StringValue' |},
        ...$Pick<StringValue, { string: * }>
      }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }), shipment: ({
      ...{| __typename: 'Shipment' |},
    ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
    ...{ totalVolume: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), totalVolumeOverride?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), timeline: ({
        ...{| __typename: 'Timeline' |},
      ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
    }), containerTypeCounts: Array<({
        ...{| __typename: 'ContainerTypeCount' |},
      ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
    })>, importer?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>,
      ...{ partner?: ?({
          ...{| __typename: 'Partner' |},
        ...$Pick<Partner, { id: *, name?: *, code?: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>,
      ...{ partner?: ?({
          ...{| __typename: 'Partner' |},
        ...$Pick<Partner, { id: *, name?: *, code?: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, date: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, voyages: Array<({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
      ...{ departurePort?: ?({
          ...{| __typename: 'Port' |},
        ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
      }), arrivalPort?: ?({
          ...{| __typename: 'Port' |},
        ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
      }), departure: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>,
      ...{ customClearance: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
      ...{ warehouse?: ?({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: * }>,
      ...{ orderItem: ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: * }>,
        ...{ productProvider: ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: * }>,
          ...{ exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
        ...{| __typename: 'Notification' |},
      ...$Pick<Notification, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, batches: Array<({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { id: *, sort: *, shipmentSort: *, archived: *, autoCalculatePackageQuantity: *, autoCalculatePackageVolume: *, updatedAt: *, memo?: *, no: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: *, latestQuantityField: *, producedAt?: *, deliveredAt?: *, desiredAt?: *, expiredAt?: *, packageName?: *, packageCapacity?: *, packageQuantity?: * }>,
    ...{ updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), totalPrice?: ?({
        ...{| __typename: 'NewPrice' |},
      ...$Pick<NewPrice, { amount: *, currency: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
        ...{| __typename: 'CustomFields' |},
      ...{ fieldValues: Array<({
          ...{| __typename: 'FieldValue' |},
        ...{ value: ({
            ...{| __typename: 'StringValue' |},
          ...$Pick<StringValue, { string: * }>
        }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }), packageGrossWeight?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), packageVolume?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), packageSize?: ?({
        ...{| __typename: 'Size' |},
      ...{ length: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), width: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), height: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }) }
    }), tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, orderItem: ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
      ...{ price: ({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, order: ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
        ...{ totalPrice: ({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, timeline: ({
            ...{| __typename: 'Timeline' |},
          ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
        }), ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
            ...{| __typename: 'Notification' |},
          ...$Pick<Notification, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ exporter: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
          ...{ grossWeight?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), volume?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), size?: ?({
              ...{| __typename: 'Size' |},
            ...{ length: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), width: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), height: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }) }
          }) }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
          ...{ grossWeight?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), volume?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), size?: ?({
              ...{| __typename: 'Size' |},
            ...{ length: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), width: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), height: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }) }
          }) }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: *, serial: * }>,
          ...{ tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, files: Array<({
              ...{| __typename: 'File' |},
            ...$Pick<File, { id: *, path: * }>,
            ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment?: ?({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
      ...{ forwarders: Array<({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
          ...{ tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalVolumeOverride?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), timeline: ({
          ...{| __typename: 'Timeline' |},
        ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
      }), containerTypeCounts: Array<({
          ...{| __typename: 'ContainerTypeCount' |},
        ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
      })>, importer?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, voyages: Array<({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
        ...{ departurePort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), arrivalPort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), departure: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ customClearance: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
        ...{ warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>,
        ...{ orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>,
          ...{ productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>,
            ...{ exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
          ...{| __typename: 'Notification' |},
        ...$Pick<Notification, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: *, no: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: * }>,
      ...{ representativeBatch?: ?({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>,
        ...{ orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>,
          ...{ productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>,
            ...{ product: ({
                ...{| __typename: 'Product' |},
              ...$Pick<Product, { id: *, name: *, serial: * }>,
              ...{ files: Array<({
                  ...{| __typename: 'File' |},
                ...$Pick<File, { id: *, path: * }>,
                ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, representativeBatch?: ?({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { id: *, sort: *, shipmentSort: *, archived: *, autoCalculatePackageQuantity: *, autoCalculatePackageVolume: *, updatedAt: *, memo?: *, no: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: *, latestQuantityField: *, producedAt?: *, deliveredAt?: *, desiredAt?: *, expiredAt?: *, packageName?: *, packageCapacity?: *, packageQuantity?: * }>,
    ...{ updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), totalPrice?: ?({
        ...{| __typename: 'NewPrice' |},
      ...$Pick<NewPrice, { amount: *, currency: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
        ...{| __typename: 'CustomFields' |},
      ...{ fieldValues: Array<({
          ...{| __typename: 'FieldValue' |},
        ...{ value: ({
            ...{| __typename: 'StringValue' |},
          ...$Pick<StringValue, { string: * }>
        }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }), packageGrossWeight?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), packageVolume?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), packageSize?: ?({
        ...{| __typename: 'Size' |},
      ...{ length: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), width: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), height: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }) }
    }), tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, orderItem: ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
      ...{ price: ({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, order: ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
        ...{ totalPrice: ({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, timeline: ({
            ...{| __typename: 'Timeline' |},
          ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
        }), ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
            ...{| __typename: 'Notification' |},
          ...$Pick<Notification, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ exporter: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
          ...{ grossWeight?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), volume?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), size?: ?({
              ...{| __typename: 'Size' |},
            ...{ length: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), width: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), height: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }) }
          }) }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
          ...{ grossWeight?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), volume?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), size?: ?({
              ...{| __typename: 'Size' |},
            ...{ length: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), width: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), height: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }) }
          }) }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: *, serial: * }>,
          ...{ tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, files: Array<({
              ...{| __typename: 'File' |},
            ...$Pick<File, { id: *, path: * }>,
            ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment?: ?({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
      ...{ forwarders: Array<({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
          ...{ tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalVolumeOverride?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), timeline: ({
          ...{| __typename: 'Timeline' |},
        ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
      }), containerTypeCounts: Array<({
          ...{| __typename: 'ContainerTypeCount' |},
        ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
      })>, importer?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, voyages: Array<({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
        ...{ departurePort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), arrivalPort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), departure: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ customClearance: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
        ...{ warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>,
        ...{ orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>,
          ...{ productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>,
            ...{ exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
          ...{| __typename: 'Notification' |},
        ...$Pick<Notification, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: *, no: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: * }>,
      ...{ representativeBatch?: ?({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>,
        ...{ orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>,
          ...{ productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>,
            ...{ product: ({
                ...{| __typename: 'Product' |},
              ...$Pick<Product, { id: *, name: *, serial: * }>,
              ...{ files: Array<({
                  ...{| __typename: 'File' |},
                ...$Pick<File, { id: *, path: * }>,
                ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type ContainerListFragment = ({
    ...{| __typename: 'Container' |},
  ...$Pick<Container, { id: *, archived: *, no: *, containerType?: *, containerOption?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: *, freeTimeStartDate?: *, freeTimeDuration?: * }>,
  ...{ shipment: ({
      ...{| __typename: 'Shipment' |},
    ...$Pick<Shipment, { id: *, no: * }>,
    ...{ exporter?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
      ...{| __typename: 'Warehouse' |},
    ...$Pick<Warehouse, { id: *, name: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, representativeBatch?: ?({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { id: * }>,
    ...{ orderItem: ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: * }>,
      ...{ productProvider: ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: * }>,
        ...{ product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: *, serial: * }>,
          ...{ files: Array<({
              ...{| __typename: 'File' |},
            ...$Pick<File, { id: *, path: * }>,
            ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), batches: Array<({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouseArrivalAgreedDateApprovedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type ContainerCloneManyMutationVariables = {
  containers: Array<ContainerUpdateWrapperInput>
};


export type ContainerCloneManyMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ containerCloneMany: Array<?({
      ...{| __typename: 'Container' |},
    ...$Pick<Container, { id: * }>,
    ...{ shipment: ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, batches: Array<({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}> }
});

export type ContainerCreateMutationVariables = {
  input: ContainerCreateInput
};


export type ContainerCreateMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ containerCreate: ({
      ...{| __typename: 'Container' |},
    ...$Pick<Container, { id: * }>,
    ...{ shipment: ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |} }
});

export type ContainerUpdateMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  input: ContainerUpdateInput
};


export type ContainerUpdateMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ containerUpdate: ({
      ...{| __typename: 'Container' |},
    ...$Pick<Container, { id: *, archived: *, updatedAt: *, no: *, containerType?: *, containerOption?: *, memo?: *, vanningDate?: *, vanningDateApprovedAt?: *, vanningActualDate?: *, vanningActualDateApprovedAt?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: *, freeTimeStartDate?: *, freeTimeDuration?: *, autoCalculatedFreeTimeStartDate: *, yardName?: *, departureDate?: *, departureDateApprovedAt?: * }>,
    ...{ updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: *, name: *, archived: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, terminalArrivalPort?: ?({
        ...{| __typename: 'Port' |},
      ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
    }), terminalArrival: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, date: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, vanningDateApprovedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, vanningActualDateApprovedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, departureDateApprovedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, customFields: ({
        ...{| __typename: 'CustomFields' |},
      ...{ fieldValues: Array<({
          ...{| __typename: 'FieldValue' |},
        ...{ value: ({
            ...{| __typename: 'StringValue' |},
          ...$Pick<StringValue, { string: * }>
        }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }), shipment: ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
      ...{ totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalVolumeOverride?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), timeline: ({
          ...{| __typename: 'Timeline' |},
        ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
      }), containerTypeCounts: Array<({
          ...{| __typename: 'ContainerTypeCount' |},
        ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
      })>, importer?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, voyages: Array<({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
        ...{ departurePort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), arrivalPort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), departure: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ customClearance: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
        ...{ warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>,
        ...{ orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>,
          ...{ productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>,
            ...{ exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
          ...{| __typename: 'Notification' |},
        ...$Pick<Notification, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, batches: Array<({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: *, sort: *, shipmentSort: *, archived: *, autoCalculatePackageQuantity: *, autoCalculatePackageVolume: *, updatedAt: *, memo?: *, no: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: *, latestQuantityField: *, producedAt?: *, deliveredAt?: *, desiredAt?: *, expiredAt?: *, packageName?: *, packageCapacity?: *, packageQuantity?: * }>,
      ...{ updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalPrice?: ?({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
          ...{| __typename: 'CustomFields' |},
        ...{ fieldValues: Array<({
            ...{| __typename: 'FieldValue' |},
          ...{ value: ({
              ...{| __typename: 'StringValue' |},
            ...$Pick<StringValue, { string: * }>
          }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }), packageGrossWeight?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), packageVolume?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), packageSize?: ?({
          ...{| __typename: 'Size' |},
        ...{ length: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), width: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), height: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }) }
      }), tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, orderItem: ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
        ...{ price: ({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, order: ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
          ...{ totalPrice: ({
              ...{| __typename: 'NewPrice' |},
            ...$Pick<NewPrice, { amount: *, currency: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, timeline: ({
              ...{| __typename: 'Timeline' |},
            ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
          }), ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
              ...{| __typename: 'Notification' |},
            ...$Pick<Notification, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: *, name: * }>,
          ...{ exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
            ...{ grossWeight?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), volume?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), size?: ?({
                ...{| __typename: 'Size' |},
              ...{ length: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), width: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), height: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }) }
            }) }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
            ...{ grossWeight?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), volume?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), size?: ?({
                ...{| __typename: 'Size' |},
              ...{ length: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), width: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), height: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }) }
            }) }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, product: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: *, serial: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | ({
                ...{| __typename: 'Forbidden' |},
              ...$Pick<Forbidden, { reason?: * }>,
              ...{ reference?: ?({
                  ...{| __typename: 'Reference' |},
                ...$Pick<Reference, { id: * }>
              }) }
            }) | {| __typename: 'NotFound' |}>, files: Array<({
                ...{| __typename: 'File' |},
              ...$Pick<File, { id: *, path: * }>,
              ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment?: ?({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
        ...{ forwarders: Array<({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), totalVolumeOverride?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), timeline: ({
            ...{| __typename: 'Timeline' |},
          ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
        }), containerTypeCounts: Array<({
            ...{| __typename: 'ContainerTypeCount' |},
          ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
        })>, importer?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, voyages: Array<({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
          ...{ departurePort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), arrivalPort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), departure: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>,
          ...{ customClearance: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
          ...{ warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>,
          ...{ orderItem: ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>,
            ...{ productProvider: ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>,
              ...{ exporter: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
            ...{| __typename: 'Notification' |},
          ...$Pick<Notification, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: * }>,
        ...{ representativeBatch?: ?({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>,
          ...{ orderItem: ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>,
            ...{ productProvider: ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>,
              ...{ product: ({
                  ...{| __typename: 'Product' |},
                ...$Pick<Product, { id: *, name: *, serial: * }>,
                ...{ files: Array<({
                    ...{| __typename: 'File' |},
                  ...$Pick<File, { id: *, path: * }>,
                  ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), batches: Array<({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, no: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, representativeBatch?: ?({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: *, sort: *, shipmentSort: *, archived: *, autoCalculatePackageQuantity: *, autoCalculatePackageVolume: *, updatedAt: *, memo?: *, no: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: *, latestQuantityField: *, producedAt?: *, deliveredAt?: *, desiredAt?: *, expiredAt?: *, packageName?: *, packageCapacity?: *, packageQuantity?: * }>,
      ...{ updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalPrice?: ?({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
          ...{| __typename: 'CustomFields' |},
        ...{ fieldValues: Array<({
            ...{| __typename: 'FieldValue' |},
          ...{ value: ({
              ...{| __typename: 'StringValue' |},
            ...$Pick<StringValue, { string: * }>
          }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }), packageGrossWeight?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), packageVolume?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), packageSize?: ?({
          ...{| __typename: 'Size' |},
        ...{ length: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), width: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), height: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }) }
      }), tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, orderItem: ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
        ...{ price: ({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, order: ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
          ...{ totalPrice: ({
              ...{| __typename: 'NewPrice' |},
            ...$Pick<NewPrice, { amount: *, currency: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, timeline: ({
              ...{| __typename: 'Timeline' |},
            ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
          }), ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
              ...{| __typename: 'Notification' |},
            ...$Pick<Notification, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: *, name: * }>,
          ...{ exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
            ...{ grossWeight?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), volume?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), size?: ?({
                ...{| __typename: 'Size' |},
              ...{ length: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), width: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), height: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }) }
            }) }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
            ...{ grossWeight?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), volume?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), size?: ?({
                ...{| __typename: 'Size' |},
              ...{ length: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), width: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), height: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }) }
            }) }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, product: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: *, serial: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | ({
                ...{| __typename: 'Forbidden' |},
              ...$Pick<Forbidden, { reason?: * }>,
              ...{ reference?: ?({
                  ...{| __typename: 'Reference' |},
                ...$Pick<Reference, { id: * }>
              }) }
            }) | {| __typename: 'NotFound' |}>, files: Array<({
                ...{| __typename: 'File' |},
              ...$Pick<File, { id: *, path: * }>,
              ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment?: ?({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
        ...{ forwarders: Array<({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), totalVolumeOverride?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), timeline: ({
            ...{| __typename: 'Timeline' |},
          ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
        }), containerTypeCounts: Array<({
            ...{| __typename: 'ContainerTypeCount' |},
          ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
        })>, importer?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, voyages: Array<({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
          ...{ departurePort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), arrivalPort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), departure: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>,
          ...{ customClearance: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
          ...{ warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>,
          ...{ orderItem: ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>,
            ...{ productProvider: ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>,
              ...{ exporter: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
            ...{| __typename: 'Notification' |},
          ...$Pick<Notification, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: * }>,
        ...{ representativeBatch?: ?({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>,
          ...{ orderItem: ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>,
            ...{ productProvider: ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>,
              ...{ product: ({
                  ...{| __typename: 'Product' |},
                ...$Pick<Product, { id: *, name: *, serial: * }>,
                ...{ files: Array<({
                    ...{| __typename: 'File' |},
                  ...$Pick<File, { id: *, path: * }>,
                  ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), batches: Array<({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, no: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |} }
});

export type ContainerExportQueryVariables = {
  id: $ElementType<Scalars, 'ID'>,
  templateId: $ElementType<Scalars, 'ID'>
};


export type ContainerExportQuery = ({
    ...{| __typename: 'Query' |},
  ...{ containerExport: ({
      ...{| __typename: 'Export' |},
    ...$Pick<Export, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type ContainerFormQueryVariables = {
  id: $ElementType<Scalars, 'ID'>
};


export type ContainerFormQuery = ({
    ...{| __typename: 'Query' |},
  ...{ container: ({
      ...{| __typename: 'Container' |},
    ...$Pick<Container, { id: *, archived: *, updatedAt: *, no: *, containerType?: *, containerOption?: *, memo?: *, vanningDate?: *, vanningDateApprovedAt?: *, vanningActualDate?: *, vanningActualDateApprovedAt?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: *, freeTimeStartDate?: *, freeTimeDuration?: *, autoCalculatedFreeTimeStartDate: *, yardName?: *, departureDate?: *, departureDateApprovedAt?: * }>,
    ...{ updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: *, name: *, archived: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, terminalArrivalPort?: ?({
        ...{| __typename: 'Port' |},
      ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
    }), terminalArrival: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, date: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, vanningDateApprovedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, vanningActualDateApprovedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, departureDateApprovedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, customFields: ({
        ...{| __typename: 'CustomFields' |},
      ...{ fieldValues: Array<({
          ...{| __typename: 'FieldValue' |},
        ...{ value: ({
            ...{| __typename: 'StringValue' |},
          ...$Pick<StringValue, { string: * }>
        }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }), shipment: ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
      ...{ totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalVolumeOverride?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), timeline: ({
          ...{| __typename: 'Timeline' |},
        ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
      }), containerTypeCounts: Array<({
          ...{| __typename: 'ContainerTypeCount' |},
        ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
      })>, importer?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, voyages: Array<({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
        ...{ departurePort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), arrivalPort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), departure: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ customClearance: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
        ...{ warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>,
        ...{ orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>,
          ...{ productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>,
            ...{ exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
          ...{| __typename: 'Notification' |},
        ...$Pick<Notification, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, batches: Array<({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: *, sort: *, shipmentSort: *, archived: *, autoCalculatePackageQuantity: *, autoCalculatePackageVolume: *, updatedAt: *, memo?: *, no: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: *, latestQuantityField: *, producedAt?: *, deliveredAt?: *, desiredAt?: *, expiredAt?: *, packageName?: *, packageCapacity?: *, packageQuantity?: * }>,
      ...{ updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalPrice?: ?({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
          ...{| __typename: 'CustomFields' |},
        ...{ fieldValues: Array<({
            ...{| __typename: 'FieldValue' |},
          ...{ value: ({
              ...{| __typename: 'StringValue' |},
            ...$Pick<StringValue, { string: * }>
          }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }), packageGrossWeight?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), packageVolume?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), packageSize?: ?({
          ...{| __typename: 'Size' |},
        ...{ length: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), width: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), height: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }) }
      }), tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, orderItem: ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
        ...{ price: ({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, order: ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
          ...{ totalPrice: ({
              ...{| __typename: 'NewPrice' |},
            ...$Pick<NewPrice, { amount: *, currency: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, timeline: ({
              ...{| __typename: 'Timeline' |},
            ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
          }), ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
              ...{| __typename: 'Notification' |},
            ...$Pick<Notification, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: *, name: * }>,
          ...{ exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
            ...{ grossWeight?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), volume?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), size?: ?({
                ...{| __typename: 'Size' |},
              ...{ length: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), width: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), height: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }) }
            }) }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
            ...{ grossWeight?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), volume?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), size?: ?({
                ...{| __typename: 'Size' |},
              ...{ length: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), width: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), height: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }) }
            }) }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, product: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: *, serial: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | ({
                ...{| __typename: 'Forbidden' |},
              ...$Pick<Forbidden, { reason?: * }>,
              ...{ reference?: ?({
                  ...{| __typename: 'Reference' |},
                ...$Pick<Reference, { id: * }>
              }) }
            }) | {| __typename: 'NotFound' |}>, files: Array<({
                ...{| __typename: 'File' |},
              ...$Pick<File, { id: *, path: * }>,
              ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment?: ?({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
        ...{ forwarders: Array<({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), totalVolumeOverride?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), timeline: ({
            ...{| __typename: 'Timeline' |},
          ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
        }), containerTypeCounts: Array<({
            ...{| __typename: 'ContainerTypeCount' |},
          ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
        })>, importer?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, voyages: Array<({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
          ...{ departurePort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), arrivalPort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), departure: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>,
          ...{ customClearance: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
          ...{ warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>,
          ...{ orderItem: ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>,
            ...{ productProvider: ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>,
              ...{ exporter: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
            ...{| __typename: 'Notification' |},
          ...$Pick<Notification, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: * }>,
        ...{ representativeBatch?: ?({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>,
          ...{ orderItem: ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>,
            ...{ productProvider: ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>,
              ...{ product: ({
                  ...{| __typename: 'Product' |},
                ...$Pick<Product, { id: *, name: *, serial: * }>,
                ...{ files: Array<({
                    ...{| __typename: 'File' |},
                  ...$Pick<File, { id: *, path: * }>,
                  ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), batches: Array<({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, no: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, representativeBatch?: ?({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: *, sort: *, shipmentSort: *, archived: *, autoCalculatePackageQuantity: *, autoCalculatePackageVolume: *, updatedAt: *, memo?: *, no: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: *, latestQuantityField: *, producedAt?: *, deliveredAt?: *, desiredAt?: *, expiredAt?: *, packageName?: *, packageCapacity?: *, packageQuantity?: * }>,
      ...{ updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalPrice?: ?({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
          ...{| __typename: 'CustomFields' |},
        ...{ fieldValues: Array<({
            ...{| __typename: 'FieldValue' |},
          ...{ value: ({
              ...{| __typename: 'StringValue' |},
            ...$Pick<StringValue, { string: * }>
          }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }), packageGrossWeight?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), packageVolume?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), packageSize?: ?({
          ...{| __typename: 'Size' |},
        ...{ length: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), width: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), height: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }) }
      }), tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, orderItem: ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
        ...{ price: ({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, order: ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
          ...{ totalPrice: ({
              ...{| __typename: 'NewPrice' |},
            ...$Pick<NewPrice, { amount: *, currency: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, timeline: ({
              ...{| __typename: 'Timeline' |},
            ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
          }), ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
              ...{| __typename: 'Notification' |},
            ...$Pick<Notification, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: *, name: * }>,
          ...{ exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
            ...{ grossWeight?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), volume?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), size?: ?({
                ...{| __typename: 'Size' |},
              ...{ length: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), width: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), height: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }) }
            }) }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
            ...{ grossWeight?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), volume?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), size?: ?({
                ...{| __typename: 'Size' |},
              ...{ length: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), width: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), height: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }) }
            }) }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, product: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: *, serial: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | ({
                ...{| __typename: 'Forbidden' |},
              ...$Pick<Forbidden, { reason?: * }>,
              ...{ reference?: ?({
                  ...{| __typename: 'Reference' |},
                ...$Pick<Reference, { id: * }>
              }) }
            }) | {| __typename: 'NotFound' |}>, files: Array<({
                ...{| __typename: 'File' |},
              ...$Pick<File, { id: *, path: * }>,
              ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment?: ?({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
        ...{ forwarders: Array<({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), totalVolumeOverride?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), timeline: ({
            ...{| __typename: 'Timeline' |},
          ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
        }), containerTypeCounts: Array<({
            ...{| __typename: 'ContainerTypeCount' |},
          ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
        })>, importer?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, voyages: Array<({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
          ...{ departurePort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), arrivalPort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), departure: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>,
          ...{ customClearance: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
          ...{ warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>,
          ...{ orderItem: ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>,
            ...{ productProvider: ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>,
              ...{ exporter: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
            ...{| __typename: 'Notification' |},
          ...$Pick<Notification, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: * }>,
        ...{ representativeBatch?: ?({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>,
          ...{ orderItem: ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>,
            ...{ productProvider: ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>,
              ...{ product: ({
                  ...{| __typename: 'Product' |},
                ...$Pick<Product, { id: *, name: *, serial: * }>,
                ...{ files: Array<({
                    ...{| __typename: 'File' |},
                  ...$Pick<File, { id: *, path: * }>,
                  ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), batches: Array<({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, no: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type ContainerListQueryVariables = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?ContainerFilterInput,
  sortBy?: ?ContainerSortInput
};


export type ContainerListQuery = ({
    ...{| __typename: 'Query' |},
  ...{ containers: ({
      ...{| __typename: 'ContainerPayloadPaginatedSearch' |},
    ...$Pick<ContainerPayloadPaginatedSearch, { page: *, totalPage: * }>,
    ...{ nodes: Array<({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: *, archived: *, no: *, containerType?: *, containerOption?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: *, freeTimeStartDate?: *, freeTimeDuration?: * }>,
      ...{ shipment: ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>,
        ...{ exporter?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: *, name: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, representativeBatch?: ?({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>,
        ...{ orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>,
          ...{ productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>,
            ...{ product: ({
                ...{| __typename: 'Product' |},
              ...$Pick<Product, { id: *, name: *, serial: * }>,
              ...{ files: Array<({
                  ...{| __typename: 'File' |},
                ...$Pick<File, { id: *, path: * }>,
                ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouseArrivalAgreedDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'BadRequest' |},
      ...{ violations: Array<({
          ...{| __typename: 'Violation' |},
        ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
        ...{ parameters: Array<({
            ...{| __typename: 'ViolationParameter' |},
          ...$Pick<ViolationParameter, { key: *, value: * }>
        })> }
      })> }
    }) | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | ({
        ...{| __typename: 'NotFound' |},
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: *, type: *, service: * }>
      }) }
    })> }
  }) }
});

export type ContainerTimelineQueryVariables = {
  id: $ElementType<Scalars, 'ID'>,
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>
};


export type ContainerTimelineQuery = ({
    ...{| __typename: 'Query' |},
  ...{ container: ({
      ...{| __typename: 'Container' |},
    ...$Pick<Container, { id: * }>,
    ...{ timeline: ({
        ...{| __typename: 'Timeline' |},
      ...{ entries: ({
          ...{| __typename: 'TimelineEntryPaginatedList' |},
        ...$Pick<TimelineEntryPaginatedList, { page: *, totalPage: * }>,
        ...{ nodes: Array<({
            ...{| __typename: 'TimelineEvent' |},
          ...$Pick<TimelineEvent, { id: *, createdAt: * }>,
          ...{ entity: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: * }>
          }) | ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>
          }) | ({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: * }>
          }) | ({
              ...{| __typename: 'Order' |},
            ...$Pick<Order, { id: * }>
          }) | ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>
          }) | ({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: * }>
          }) | ({
              ...{| __typename: 'Shipment' |},
            ...$Pick<Shipment, { id: * }>
          }) | ({
              ...{| __typename: 'Voyage' |},
            ...$Pick<Voyage, { id: * }>
          }) | ({
              ...{| __typename: 'Container' |},
            ...$Pick<Container, { id: * }>
          }) | ({
              ...{| __typename: 'ContainerGroup' |},
            ...$Pick<ContainerGroup, { id: * }>
          }) | ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: * }>
          }) | ({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: * }>
          }) | ({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: * }>
          }) | ({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: * }>
          }) | ({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: * }>
          }) | ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: * }>
          }) | ({
              ...{| __typename: 'Partnership' |},
            ...$Pick<Partnership, { id: * }>
          }) | ({
              ...{| __typename: 'Role' |},
            ...$Pick<Role, { id: * }>
          }) | ({
              ...{| __typename: 'File' |},
            ...$Pick<File, { id: * }>
          }) | ({
              ...{| __typename: 'Mask' |},
            ...$Pick<Mask, { id: * }>
          }) | ({
              ...{| __typename: 'MaskEdit' |},
            ...$Pick<MaskEdit, { id: * }>
          }) | ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: * }>
          }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, createdBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, logs: Array<({
              ...{| __typename: 'Log' |},
            ...$Pick<Log, { id: *, translationKey: * }>,
            ...{ entity: ({
                ...{| __typename: 'Product' |},
              ...$Pick<Product, { id: * }>
            }) | ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>
            }) | ({
                ...{| __typename: 'ProductProviderPackage' |},
              ...$Pick<ProductProviderPackage, { id: * }>
            }) | ({
                ...{| __typename: 'Order' |},
              ...$Pick<Order, { id: * }>
            }) | ({
                ...{| __typename: 'OrderItem' |},
              ...$Pick<OrderItem, { id: * }>
            }) | ({
                ...{| __typename: 'Batch' |},
              ...$Pick<Batch, { id: * }>
            }) | ({
                ...{| __typename: 'Shipment' |},
              ...$Pick<Shipment, { id: * }>
            }) | ({
                ...{| __typename: 'Voyage' |},
              ...$Pick<Voyage, { id: * }>
            }) | ({
                ...{| __typename: 'Container' |},
              ...$Pick<Container, { id: * }>
            }) | ({
                ...{| __typename: 'ContainerGroup' |},
              ...$Pick<ContainerGroup, { id: * }>
            }) | ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: * }>
            }) | ({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: * }>
            }) | ({
                ...{| __typename: 'Warehouse' |},
              ...$Pick<Warehouse, { id: * }>
            }) | ({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: * }>
            }) | ({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: * }>
            }) | ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: * }>
            }) | ({
                ...{| __typename: 'Partnership' |},
              ...$Pick<Partnership, { id: * }>
            }) | ({
                ...{| __typename: 'Role' |},
              ...$Pick<Role, { id: * }>
            }) | ({
                ...{| __typename: 'File' |},
              ...$Pick<File, { id: *, type: * }>
            }) | ({
                ...{| __typename: 'Mask' |},
              ...$Pick<Mask, { id: * }>
            }) | ({
                ...{| __typename: 'MaskEdit' |},
              ...$Pick<MaskEdit, { id: * }>
            }) | ({
                ...{| __typename: 'FieldDefinition' |},
              ...$Pick<FieldDefinition, { id: * }>
            }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, parameters: Array<({
                ...{| __typename: 'Parameter' |},
              ...$Pick<Parameter, { key: * }>,
              ...{ value?: ?({
                  ...{| __typename: 'StringValue' |},
                ...$Pick<StringValue, { string: * }>
              }) | ({
                  ...{| __typename: 'IntValue' |},
                ...$Pick<IntValue, { int: * }>
              }) | ({
                  ...{| __typename: 'FloatValue' |},
                ...$Pick<FloatValue, { float: * }>
              }) | ({
                  ...{| __typename: 'BooleanValue' |},
                ...$Pick<BooleanValue, { boolean: * }>
              }) | ({
                  ...{| __typename: 'DateTimeValue' |},
                ...$Pick<DateTimeValue, { datetime: * }>
              }) | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | ({
                  ...{| __typename: 'MetricValueValue' |},
                ...{ metricValue: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }) }
              }) | ({
                  ...{| __typename: 'PriceValue' |},
                ...{ priceValue: ({
                    ...{| __typename: 'NewPrice' |},
                  ...$Pick<NewPrice, { amount: *, currency: * }>
                }) }
              }) | ({
                  ...{| __typename: 'SizeValue' |},
                ...{ size: ({
                    ...{| __typename: 'Size' |},
                  ...{ height: ({
                      ...{| __typename: 'MetricValue' |},
                    ...$Pick<MetricValue, { value: *, metric: * }>
                  }), length: ({
                      ...{| __typename: 'MetricValue' |},
                    ...$Pick<MetricValue, { value: *, metric: * }>
                  }), width: ({
                      ...{| __typename: 'MetricValue' |},
                    ...$Pick<MetricValue, { value: *, metric: * }>
                  }) }
                }) }
              }) | ({
                  ...{| __typename: 'EntityValue' |},
                ...{ entity?: ?({
                    ...{| __typename: 'Product' |},
                  ...$Pick<Product, { id: * }>
                }) | ({
                    ...{| __typename: 'ProductProvider' |},
                  ...$Pick<ProductProvider, { id: * }>
                }) | ({
                    ...{| __typename: 'ProductProviderPackage' |},
                  ...$Pick<ProductProviderPackage, { id: * }>
                }) | ({
                    ...{| __typename: 'Order' |},
                  ...$Pick<Order, { id: * }>
                }) | ({
                    ...{| __typename: 'OrderItem' |},
                  ...$Pick<OrderItem, { id: * }>
                }) | ({
                    ...{| __typename: 'Batch' |},
                  ...$Pick<Batch, { id: * }>
                }) | ({
                    ...{| __typename: 'Shipment' |},
                  ...$Pick<Shipment, { id: * }>
                }) | ({
                    ...{| __typename: 'Voyage' |},
                  ...$Pick<Voyage, { id: * }>
                }) | ({
                    ...{| __typename: 'Container' |},
                  ...$Pick<Container, { id: * }>
                }) | ({
                    ...{| __typename: 'ContainerGroup' |},
                  ...$Pick<ContainerGroup, { id: * }>
                }) | ({
                    ...{| __typename: 'TimelineDate' |},
                  ...$Pick<TimelineDate, { id: * }>
                }) | ({
                    ...{| __typename: 'TimelineDateRevision' |},
                  ...$Pick<TimelineDateRevision, { id: * }>
                }) | ({
                    ...{| __typename: 'Warehouse' |},
                  ...$Pick<Warehouse, { id: * }>
                }) | ({
                    ...{| __typename: 'Tag' |},
                  ...$Pick<Tag, { id: *, name: *, color: * }>
                }) | ({
                    ...{| __typename: 'User' |},
                  ...$Pick<User, { id: *, firstName: *, lastName: * }>
                }) | ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: *, types: * }>,
                  ...{ partner?: ?({
                      ...{| __typename: 'Partner' |},
                    ...$Pick<Partner, { id: *, name?: *, code?: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
                }) | ({
                    ...{| __typename: 'Partnership' |},
                  ...$Pick<Partnership, { id: * }>
                }) | ({
                    ...{| __typename: 'Role' |},
                  ...$Pick<Role, { id: * }>
                }) | ({
                    ...{| __typename: 'File' |},
                  ...$Pick<File, { id: * }>
                }) | ({
                    ...{| __typename: 'Mask' |},
                  ...$Pick<Mask, { id: * }>
                }) | ({
                    ...{| __typename: 'MaskEdit' |},
                  ...$Pick<MaskEdit, { id: * }>
                }) | ({
                    ...{| __typename: 'FieldDefinition' |},
                  ...$Pick<FieldDefinition, { id: * }>
                }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | ({
                  ...{| __typename: 'Values' |},
                ...{ values: Array<?({
                    ...{| __typename: 'StringValue' |},
                  ...$Pick<StringValue, { string: * }>
                }) | ({
                    ...{| __typename: 'IntValue' |},
                  ...$Pick<IntValue, { int: * }>
                }) | ({
                    ...{| __typename: 'FloatValue' |},
                  ...$Pick<FloatValue, { float: * }>
                }) | ({
                    ...{| __typename: 'BooleanValue' |},
                  ...$Pick<BooleanValue, { boolean: * }>
                }) | ({
                    ...{| __typename: 'DateTimeValue' |},
                  ...$Pick<DateTimeValue, { datetime: * }>
                }) | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | ({
                    ...{| __typename: 'MetricValueValue' |},
                  ...{ metricValue: ({
                      ...{| __typename: 'MetricValue' |},
                    ...$Pick<MetricValue, { value: *, metric: * }>
                  }) }
                }) | ({
                    ...{| __typename: 'PriceValue' |},
                  ...{ priceValue: ({
                      ...{| __typename: 'NewPrice' |},
                    ...$Pick<NewPrice, { amount: *, currency: * }>
                  }) }
                }) | ({
                    ...{| __typename: 'SizeValue' |},
                  ...{ size: ({
                      ...{| __typename: 'Size' |},
                    ...{ height: ({
                        ...{| __typename: 'MetricValue' |},
                      ...$Pick<MetricValue, { value: *, metric: * }>
                    }), length: ({
                        ...{| __typename: 'MetricValue' |},
                      ...$Pick<MetricValue, { value: *, metric: * }>
                    }), width: ({
                        ...{| __typename: 'MetricValue' |},
                      ...$Pick<MetricValue, { value: *, metric: * }>
                    }) }
                  }) }
                }) | ({
                    ...{| __typename: 'EntityValue' |},
                  ...{ entity?: ?({
                      ...{| __typename: 'Product' |},
                    ...$Pick<Product, { id: * }>
                  }) | ({
                      ...{| __typename: 'ProductProvider' |},
                    ...$Pick<ProductProvider, { id: * }>
                  }) | ({
                      ...{| __typename: 'ProductProviderPackage' |},
                    ...$Pick<ProductProviderPackage, { id: * }>
                  }) | ({
                      ...{| __typename: 'Order' |},
                    ...$Pick<Order, { id: * }>
                  }) | ({
                      ...{| __typename: 'OrderItem' |},
                    ...$Pick<OrderItem, { id: * }>
                  }) | ({
                      ...{| __typename: 'Batch' |},
                    ...$Pick<Batch, { id: * }>
                  }) | ({
                      ...{| __typename: 'Shipment' |},
                    ...$Pick<Shipment, { id: * }>
                  }) | ({
                      ...{| __typename: 'Voyage' |},
                    ...$Pick<Voyage, { id: * }>
                  }) | ({
                      ...{| __typename: 'Container' |},
                    ...$Pick<Container, { id: * }>
                  }) | ({
                      ...{| __typename: 'ContainerGroup' |},
                    ...$Pick<ContainerGroup, { id: * }>
                  }) | ({
                      ...{| __typename: 'TimelineDate' |},
                    ...$Pick<TimelineDate, { id: * }>
                  }) | ({
                      ...{| __typename: 'TimelineDateRevision' |},
                    ...$Pick<TimelineDateRevision, { id: * }>
                  }) | ({
                      ...{| __typename: 'Warehouse' |},
                    ...$Pick<Warehouse, { id: * }>
                  }) | ({
                      ...{| __typename: 'Tag' |},
                    ...$Pick<Tag, { id: *, name: *, color: * }>
                  }) | ({
                      ...{| __typename: 'User' |},
                    ...$Pick<User, { id: *, firstName: *, lastName: * }>
                  }) | ({
                      ...{| __typename: 'Organization' |},
                    ...$Pick<Organization, { id: *, name: *, types: * }>,
                    ...{ partner?: ?({
                        ...{| __typename: 'Partner' |},
                      ...$Pick<Partner, { id: *, name?: *, code?: * }>
                    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
                  }) | ({
                      ...{| __typename: 'Partnership' |},
                    ...$Pick<Partnership, { id: * }>
                  }) | ({
                      ...{| __typename: 'Role' |},
                    ...$Pick<Role, { id: * }>
                  }) | ({
                      ...{| __typename: 'File' |},
                    ...$Pick<File, { id: * }>
                  }) | ({
                      ...{| __typename: 'Mask' |},
                    ...$Pick<Mask, { id: * }>
                  }) | ({
                      ...{| __typename: 'MaskEdit' |},
                    ...$Pick<MaskEdit, { id: * }>
                  }) | ({
                      ...{| __typename: 'FieldDefinition' |},
                    ...$Pick<FieldDefinition, { id: * }>
                  }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
                }) | {| __typename: 'Values' |}> }
              }) }
            })> }
          })> }
        }) | ({
            ...{| __typename: 'TimelineComment' |},
          ...$Pick<TimelineComment, { id: *, content: *, createdAt: *, updatedAt: * }>,
          ...{ createdBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) }
    }) }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type ContainersQueryVariables = {
  filterBy?: ?ContainerFilterInput,
  sortBy?: ?ContainerSortInput,
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>
};


export type ContainersQuery = ({
    ...{| __typename: 'Query' |},
  ...{ containers: ({
      ...{| __typename: 'ContainerPayloadPaginatedSearch' |},
    ...$Pick<ContainerPayloadPaginatedSearch, { page: *, totalPage: * }>,
    ...{ nodes: Array<({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: *, archived: *, no: *, containerType?: *, containerOption?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: *, freeTimeStartDate?: *, freeTimeDuration?: * }>,
      ...{ representativeBatch?: ?({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>,
        ...{ orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>,
          ...{ productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>,
            ...{ product: ({
                ...{| __typename: 'Product' |},
              ...$Pick<Product, { id: *, name: *, serial: * }>,
              ...{ files: Array<({
                  ...{| __typename: 'File' |},
                ...$Pick<File, { id: *, path: * }>,
                ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>,
        ...{ importer?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}> }
  }) }
});

export type ContainersByIDsQueryVariables = {
  ids: Array<$ElementType<Scalars, 'ID'>>
};


export type ContainersByIDsQuery = ({
    ...{| __typename: 'Query' |},
  ...{ containersByIDs: Array<({
      ...{| __typename: 'Container' |},
    ...$Pick<Container, { id: *, no: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type ContainersExportQueryVariables = {
  filterBy?: ?ContainerFilterInput,
  sortBy?: ?ContainerSortInput,
  templateId: $ElementType<Scalars, 'ID'>
};


export type ContainersExportQuery = ({
    ...{| __typename: 'Query' |},
  ...{ containersExport: ({
      ...{| __typename: 'Export' |},
    ...$Pick<Export, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type DocumentFragment = ({
    ...{| __typename: 'File' |},
  ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
  ...{ tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, entity?: ?({
      ...{| __typename: 'Product' |},
    ...$Pick<Product, { id: *, name: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | ({
      ...{| __typename: 'ProductProvider' |},
    ...$Pick<ProductProvider, { id: *, name: * }>,
    ...{ product: ({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | ({
      ...{| __typename: 'ProductProviderPackage' |},
    ...$Pick<ProductProviderPackage, { id: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | ({
      ...{| __typename: 'Order' |},
    ...$Pick<Order, { id: *, poNo: * }>,
    ...{ connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | ({
      ...{| __typename: 'OrderItem' |},
    ...$Pick<OrderItem, { id: *, no: * }>,
    ...{ connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | ({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { id: *, no: * }>,
    ...{ connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | ({
      ...{| __typename: 'Shipment' |},
    ...$Pick<Shipment, { id: *, no: * }>,
    ...{ connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | ({
      ...{| __typename: 'Voyage' |},
    ...$Pick<Voyage, { id: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | ({
      ...{| __typename: 'Container' |},
    ...$Pick<Container, { id: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | ({
      ...{| __typename: 'ContainerGroup' |},
    ...$Pick<ContainerGroup, { id: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | ({
      ...{| __typename: 'TimelineDate' |},
    ...$Pick<TimelineDate, { id: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | ({
      ...{| __typename: 'TimelineDateRevision' |},
    ...$Pick<TimelineDateRevision, { id: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | ({
      ...{| __typename: 'Warehouse' |},
    ...$Pick<Warehouse, { id: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | ({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | ({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: * }>
  }) | ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | ({
      ...{| __typename: 'Partnership' |},
    ...$Pick<Partnership, { id: * }>
  }) | ({
      ...{| __typename: 'Role' |},
    ...$Pick<Role, { id: * }>
  }) | ({
      ...{| __typename: 'File' |},
    ...$Pick<File, { id: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | ({
      ...{| __typename: 'Mask' |},
    ...$Pick<Mask, { id: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | ({
      ...{| __typename: 'MaskEdit' |},
    ...$Pick<MaskEdit, { id: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | ({
      ...{| __typename: 'FieldDefinition' |},
    ...$Pick<FieldDefinition, { id: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type DocumentFolderCardFragment = ({
    ...{| __typename: 'Folder' |},
  ...$Pick<Folder, { id: *, name: *, entityType: *, folderType: *, totalCount: * }>
});

export type DocumentFormFragment = ({
    ...{| __typename: 'File' |},
  ...$Pick<File, { id: *, updatedAt: *, name: *, type: *, status: *, size: *, path: *, memo?: * }>,
  ...{ updatedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, messageGroup?: ?({
      ...{| __typename: 'MessageGroup' |},
    ...$Pick<MessageGroup, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity?: ?({
      ...{| __typename: 'Product' |},
    ...$Pick<Product, { id: * }>
  }) | ({
      ...{| __typename: 'ProductProvider' |},
    ...$Pick<ProductProvider, { id: * }>
  }) | ({
      ...{| __typename: 'ProductProviderPackage' |},
    ...$Pick<ProductProviderPackage, { id: * }>
  }) | ({
      ...{| __typename: 'Order' |},
    ...$Pick<Order, { id: * }>
  }) | ({
      ...{| __typename: 'OrderItem' |},
    ...$Pick<OrderItem, { id: * }>
  }) | ({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { id: * }>
  }) | ({
      ...{| __typename: 'Shipment' |},
    ...$Pick<Shipment, { id: * }>
  }) | ({
      ...{| __typename: 'Voyage' |},
    ...$Pick<Voyage, { id: * }>
  }) | ({
      ...{| __typename: 'Container' |},
    ...$Pick<Container, { id: * }>
  }) | ({
      ...{| __typename: 'ContainerGroup' |},
    ...$Pick<ContainerGroup, { id: * }>
  }) | ({
      ...{| __typename: 'TimelineDate' |},
    ...$Pick<TimelineDate, { id: * }>
  }) | ({
      ...{| __typename: 'TimelineDateRevision' |},
    ...$Pick<TimelineDateRevision, { id: * }>
  }) | ({
      ...{| __typename: 'Warehouse' |},
    ...$Pick<Warehouse, { id: * }>
  }) | ({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: * }>
  }) | ({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: * }>
  }) | ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | ({
      ...{| __typename: 'Partnership' |},
    ...$Pick<Partnership, { id: * }>
  }) | ({
      ...{| __typename: 'Role' |},
    ...$Pick<Role, { id: * }>
  }) | ({
      ...{| __typename: 'File' |},
    ...$Pick<File, { id: * }>
  }) | ({
      ...{| __typename: 'Mask' |},
    ...$Pick<Mask, { id: * }>
  }) | ({
      ...{| __typename: 'MaskEdit' |},
    ...$Pick<MaskEdit, { id: * }>
  }) | ({
      ...{| __typename: 'FieldDefinition' |},
    ...$Pick<FieldDefinition, { id: * }>
  }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, order?: ?({
      ...{| __typename: 'Product' |},
    ...$Pick<Product, { id: * }>
  }) | ({
      ...{| __typename: 'ProductProvider' |},
    ...$Pick<ProductProvider, { id: * }>
  }) | ({
      ...{| __typename: 'ProductProviderPackage' |},
    ...$Pick<ProductProviderPackage, { id: * }>
  }) | ({
      ...{| __typename: 'Order' |},
    ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
    ...{ totalPrice: ({
        ...{| __typename: 'NewPrice' |},
      ...$Pick<NewPrice, { amount: *, currency: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>,
      ...{ partner?: ?({
          ...{| __typename: 'Partner' |},
        ...$Pick<Partner, { id: *, name?: *, code?: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>,
      ...{ partner?: ?({
          ...{| __typename: 'Partner' |},
        ...$Pick<Partner, { id: *, name?: *, code?: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, timeline: ({
        ...{| __typename: 'Timeline' |},
      ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
    }), ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
        ...{| __typename: 'Notification' |},
      ...$Pick<Notification, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | ({
      ...{| __typename: 'OrderItem' |},
    ...$Pick<OrderItem, { id: * }>
  }) | ({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { id: * }>
  }) | ({
      ...{| __typename: 'Shipment' |},
    ...$Pick<Shipment, { id: * }>
  }) | ({
      ...{| __typename: 'Voyage' |},
    ...$Pick<Voyage, { id: * }>
  }) | ({
      ...{| __typename: 'Container' |},
    ...$Pick<Container, { id: * }>
  }) | ({
      ...{| __typename: 'ContainerGroup' |},
    ...$Pick<ContainerGroup, { id: * }>
  }) | ({
      ...{| __typename: 'TimelineDate' |},
    ...$Pick<TimelineDate, { id: * }>
  }) | ({
      ...{| __typename: 'TimelineDateRevision' |},
    ...$Pick<TimelineDateRevision, { id: * }>
  }) | ({
      ...{| __typename: 'Warehouse' |},
    ...$Pick<Warehouse, { id: * }>
  }) | ({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: * }>
  }) | ({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: * }>
  }) | ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | ({
      ...{| __typename: 'Partnership' |},
    ...$Pick<Partnership, { id: * }>
  }) | ({
      ...{| __typename: 'Role' |},
    ...$Pick<Role, { id: * }>
  }) | ({
      ...{| __typename: 'File' |},
    ...$Pick<File, { id: * }>
  }) | ({
      ...{| __typename: 'Mask' |},
    ...$Pick<Mask, { id: * }>
  }) | ({
      ...{| __typename: 'MaskEdit' |},
    ...$Pick<MaskEdit, { id: * }>
  }) | ({
      ...{| __typename: 'FieldDefinition' |},
    ...$Pick<FieldDefinition, { id: * }>
  }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, orderItem?: ?({
      ...{| __typename: 'Product' |},
    ...$Pick<Product, { id: * }>
  }) | ({
      ...{| __typename: 'ProductProvider' |},
    ...$Pick<ProductProvider, { id: * }>
  }) | ({
      ...{| __typename: 'ProductProviderPackage' |},
    ...$Pick<ProductProviderPackage, { id: * }>
  }) | ({
      ...{| __typename: 'Order' |},
    ...$Pick<Order, { id: * }>
  }) | ({
      ...{| __typename: 'OrderItem' |},
    ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
    ...{ price: ({
        ...{| __typename: 'NewPrice' |},
      ...$Pick<NewPrice, { amount: *, currency: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, order: ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: *, poNo: * }>,
      ...{ importer: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: *, name: * }>,
      ...{ product: ({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: *, serial: * }>,
        ...{ tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, files: Array<({
            ...{| __typename: 'File' |},
          ...$Pick<File, { id: *, path: * }>,
          ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | ({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { id: * }>
  }) | ({
      ...{| __typename: 'Shipment' |},
    ...$Pick<Shipment, { id: * }>
  }) | ({
      ...{| __typename: 'Voyage' |},
    ...$Pick<Voyage, { id: * }>
  }) | ({
      ...{| __typename: 'Container' |},
    ...$Pick<Container, { id: * }>
  }) | ({
      ...{| __typename: 'ContainerGroup' |},
    ...$Pick<ContainerGroup, { id: * }>
  }) | ({
      ...{| __typename: 'TimelineDate' |},
    ...$Pick<TimelineDate, { id: * }>
  }) | ({
      ...{| __typename: 'TimelineDateRevision' |},
    ...$Pick<TimelineDateRevision, { id: * }>
  }) | ({
      ...{| __typename: 'Warehouse' |},
    ...$Pick<Warehouse, { id: * }>
  }) | ({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: * }>
  }) | ({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: * }>
  }) | ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | ({
      ...{| __typename: 'Partnership' |},
    ...$Pick<Partnership, { id: * }>
  }) | ({
      ...{| __typename: 'Role' |},
    ...$Pick<Role, { id: * }>
  }) | ({
      ...{| __typename: 'File' |},
    ...$Pick<File, { id: * }>
  }) | ({
      ...{| __typename: 'Mask' |},
    ...$Pick<Mask, { id: * }>
  }) | ({
      ...{| __typename: 'MaskEdit' |},
    ...$Pick<MaskEdit, { id: * }>
  }) | ({
      ...{| __typename: 'FieldDefinition' |},
    ...$Pick<FieldDefinition, { id: * }>
  }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment?: ?({
      ...{| __typename: 'Product' |},
    ...$Pick<Product, { id: * }>
  }) | ({
      ...{| __typename: 'ProductProvider' |},
    ...$Pick<ProductProvider, { id: * }>
  }) | ({
      ...{| __typename: 'ProductProviderPackage' |},
    ...$Pick<ProductProviderPackage, { id: * }>
  }) | ({
      ...{| __typename: 'Order' |},
    ...$Pick<Order, { id: * }>
  }) | ({
      ...{| __typename: 'OrderItem' |},
    ...$Pick<OrderItem, { id: * }>
  }) | ({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { id: * }>
  }) | ({
      ...{| __typename: 'Shipment' |},
    ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
    ...{ totalVolume: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), totalVolumeOverride?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), timeline: ({
        ...{| __typename: 'Timeline' |},
      ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
    }), containerTypeCounts: Array<({
        ...{| __typename: 'ContainerTypeCount' |},
      ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
    })>, importer?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>,
      ...{ partner?: ?({
          ...{| __typename: 'Partner' |},
        ...$Pick<Partner, { id: *, name?: *, code?: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>,
      ...{ partner?: ?({
          ...{| __typename: 'Partner' |},
        ...$Pick<Partner, { id: *, name?: *, code?: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, date: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, voyages: Array<({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
      ...{ departurePort?: ?({
          ...{| __typename: 'Port' |},
        ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
      }), arrivalPort?: ?({
          ...{| __typename: 'Port' |},
        ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
      }), departure: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>,
      ...{ customClearance: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
      ...{ warehouse?: ?({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: * }>,
      ...{ orderItem: ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: * }>,
        ...{ productProvider: ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: * }>,
          ...{ exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
        ...{| __typename: 'Notification' |},
      ...$Pick<Notification, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | ({
      ...{| __typename: 'Voyage' |},
    ...$Pick<Voyage, { id: * }>
  }) | ({
      ...{| __typename: 'Container' |},
    ...$Pick<Container, { id: * }>
  }) | ({
      ...{| __typename: 'ContainerGroup' |},
    ...$Pick<ContainerGroup, { id: * }>
  }) | ({
      ...{| __typename: 'TimelineDate' |},
    ...$Pick<TimelineDate, { id: * }>
  }) | ({
      ...{| __typename: 'TimelineDateRevision' |},
    ...$Pick<TimelineDateRevision, { id: * }>
  }) | ({
      ...{| __typename: 'Warehouse' |},
    ...$Pick<Warehouse, { id: * }>
  }) | ({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: * }>
  }) | ({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: * }>
  }) | ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | ({
      ...{| __typename: 'Partnership' |},
    ...$Pick<Partnership, { id: * }>
  }) | ({
      ...{| __typename: 'Role' |},
    ...$Pick<Role, { id: * }>
  }) | ({
      ...{| __typename: 'File' |},
    ...$Pick<File, { id: * }>
  }) | ({
      ...{| __typename: 'Mask' |},
    ...$Pick<Mask, { id: * }>
  }) | ({
      ...{| __typename: 'MaskEdit' |},
    ...$Pick<MaskEdit, { id: * }>
  }) | ({
      ...{| __typename: 'FieldDefinition' |},
    ...$Pick<FieldDefinition, { id: * }>
  }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider?: ?({
      ...{| __typename: 'Product' |},
    ...$Pick<Product, { id: * }>
  }) | ({
      ...{| __typename: 'ProductProvider' |},
    ...$Pick<ProductProvider, { id: *, archived: *, name: * }>,
    ...{ exporter: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>,
      ...{ partner?: ?({
          ...{| __typename: 'Partner' |},
        ...$Pick<Partner, { id: *, name?: *, code?: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>,
      ...{ partner?: ?({
          ...{| __typename: 'Partner' |},
        ...$Pick<Partner, { id: *, name?: *, code?: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, unitPrice?: ?({
        ...{| __typename: 'NewPrice' |},
      ...$Pick<NewPrice, { amount: *, currency: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, product: ({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: *, name: *, serial: * }>,
      ...{ tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, files: Array<({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: *, path: * }>,
        ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
      ...{ grossWeight?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), volume?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), size?: ?({
          ...{| __typename: 'Size' |},
        ...{ length: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), width: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), height: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }) }
      }) }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
      ...{ grossWeight?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), volume?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), size?: ?({
          ...{| __typename: 'Size' |},
        ...{ length: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), width: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), height: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }) }
      }) }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | ({
      ...{| __typename: 'ProductProviderPackage' |},
    ...$Pick<ProductProviderPackage, { id: * }>
  }) | ({
      ...{| __typename: 'Order' |},
    ...$Pick<Order, { id: * }>
  }) | ({
      ...{| __typename: 'OrderItem' |},
    ...$Pick<OrderItem, { id: * }>
  }) | ({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { id: * }>
  }) | ({
      ...{| __typename: 'Shipment' |},
    ...$Pick<Shipment, { id: * }>
  }) | ({
      ...{| __typename: 'Voyage' |},
    ...$Pick<Voyage, { id: * }>
  }) | ({
      ...{| __typename: 'Container' |},
    ...$Pick<Container, { id: * }>
  }) | ({
      ...{| __typename: 'ContainerGroup' |},
    ...$Pick<ContainerGroup, { id: * }>
  }) | ({
      ...{| __typename: 'TimelineDate' |},
    ...$Pick<TimelineDate, { id: * }>
  }) | ({
      ...{| __typename: 'TimelineDateRevision' |},
    ...$Pick<TimelineDateRevision, { id: * }>
  }) | ({
      ...{| __typename: 'Warehouse' |},
    ...$Pick<Warehouse, { id: * }>
  }) | ({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: * }>
  }) | ({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: * }>
  }) | ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | ({
      ...{| __typename: 'Partnership' |},
    ...$Pick<Partnership, { id: * }>
  }) | ({
      ...{| __typename: 'Role' |},
    ...$Pick<Role, { id: * }>
  }) | ({
      ...{| __typename: 'File' |},
    ...$Pick<File, { id: * }>
  }) | ({
      ...{| __typename: 'Mask' |},
    ...$Pick<Mask, { id: * }>
  }) | ({
      ...{| __typename: 'MaskEdit' |},
    ...$Pick<MaskEdit, { id: * }>
  }) | ({
      ...{| __typename: 'FieldDefinition' |},
    ...$Pick<FieldDefinition, { id: * }>
  }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type DocumentUpdateMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  input: FileInput
};


export type DocumentUpdateMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ fileUpdate: ({
      ...{| __typename: 'File' |},
    ...$Pick<File, { id: *, updatedAt: *, name: *, type: *, status: *, size: *, path: *, memo?: * }>,
    ...{ updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, messageGroup?: ?({
        ...{| __typename: 'MessageGroup' |},
      ...$Pick<MessageGroup, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity?: ?({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: * }>
    }) | ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: * }>
    }) | ({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: * }>
    }) | ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: * }>
    }) | ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: * }>
    }) | ({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: * }>
    }) | ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: * }>
    }) | ({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: * }>
    }) | ({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: * }>
    }) | ({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>
    }) | ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: * }>
    }) | ({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: * }>
    }) | ({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: * }>
    }) | ({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: * }>
    }) | ({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: * }>
    }) | ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | ({
        ...{| __typename: 'Partnership' |},
      ...$Pick<Partnership, { id: * }>
    }) | ({
        ...{| __typename: 'Role' |},
      ...$Pick<Role, { id: * }>
    }) | ({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: * }>
    }) | ({
        ...{| __typename: 'Mask' |},
      ...$Pick<Mask, { id: * }>
    }) | ({
        ...{| __typename: 'MaskEdit' |},
      ...$Pick<MaskEdit, { id: * }>
    }) | ({
        ...{| __typename: 'FieldDefinition' |},
      ...$Pick<FieldDefinition, { id: * }>
    }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, order?: ?({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: * }>
    }) | ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: * }>
    }) | ({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: * }>
    }) | ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
      ...{ totalPrice: ({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, timeline: ({
          ...{| __typename: 'Timeline' |},
        ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
      }), ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
          ...{| __typename: 'Notification' |},
        ...$Pick<Notification, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: * }>
    }) | ({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: * }>
    }) | ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: * }>
    }) | ({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: * }>
    }) | ({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: * }>
    }) | ({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>
    }) | ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: * }>
    }) | ({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: * }>
    }) | ({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: * }>
    }) | ({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: * }>
    }) | ({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: * }>
    }) | ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | ({
        ...{| __typename: 'Partnership' |},
      ...$Pick<Partnership, { id: * }>
    }) | ({
        ...{| __typename: 'Role' |},
      ...$Pick<Role, { id: * }>
    }) | ({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: * }>
    }) | ({
        ...{| __typename: 'Mask' |},
      ...$Pick<Mask, { id: * }>
    }) | ({
        ...{| __typename: 'MaskEdit' |},
      ...$Pick<MaskEdit, { id: * }>
    }) | ({
        ...{| __typename: 'FieldDefinition' |},
      ...$Pick<FieldDefinition, { id: * }>
    }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, orderItem?: ?({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: * }>
    }) | ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: * }>
    }) | ({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: * }>
    }) | ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: * }>
    }) | ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
      ...{ price: ({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, order: ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, poNo: * }>,
        ...{ importer: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: *, serial: * }>,
          ...{ tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, files: Array<({
              ...{| __typename: 'File' |},
            ...$Pick<File, { id: *, path: * }>,
            ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: * }>
    }) | ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: * }>
    }) | ({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: * }>
    }) | ({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: * }>
    }) | ({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>
    }) | ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: * }>
    }) | ({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: * }>
    }) | ({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: * }>
    }) | ({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: * }>
    }) | ({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: * }>
    }) | ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | ({
        ...{| __typename: 'Partnership' |},
      ...$Pick<Partnership, { id: * }>
    }) | ({
        ...{| __typename: 'Role' |},
      ...$Pick<Role, { id: * }>
    }) | ({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: * }>
    }) | ({
        ...{| __typename: 'Mask' |},
      ...$Pick<Mask, { id: * }>
    }) | ({
        ...{| __typename: 'MaskEdit' |},
      ...$Pick<MaskEdit, { id: * }>
    }) | ({
        ...{| __typename: 'FieldDefinition' |},
      ...$Pick<FieldDefinition, { id: * }>
    }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment?: ?({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: * }>
    }) | ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: * }>
    }) | ({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: * }>
    }) | ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: * }>
    }) | ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: * }>
    }) | ({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: * }>
    }) | ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
      ...{ totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalVolumeOverride?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), timeline: ({
          ...{| __typename: 'Timeline' |},
        ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
      }), containerTypeCounts: Array<({
          ...{| __typename: 'ContainerTypeCount' |},
        ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
      })>, importer?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, voyages: Array<({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
        ...{ departurePort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), arrivalPort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), departure: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ customClearance: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
        ...{ warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>,
        ...{ orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>,
          ...{ productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>,
            ...{ exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
          ...{| __typename: 'Notification' |},
        ...$Pick<Notification, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: * }>
    }) | ({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: * }>
    }) | ({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>
    }) | ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: * }>
    }) | ({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: * }>
    }) | ({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: * }>
    }) | ({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: * }>
    }) | ({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: * }>
    }) | ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | ({
        ...{| __typename: 'Partnership' |},
      ...$Pick<Partnership, { id: * }>
    }) | ({
        ...{| __typename: 'Role' |},
      ...$Pick<Role, { id: * }>
    }) | ({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: * }>
    }) | ({
        ...{| __typename: 'Mask' |},
      ...$Pick<Mask, { id: * }>
    }) | ({
        ...{| __typename: 'MaskEdit' |},
      ...$Pick<MaskEdit, { id: * }>
    }) | ({
        ...{| __typename: 'FieldDefinition' |},
      ...$Pick<FieldDefinition, { id: * }>
    }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider?: ?({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: * }>
    }) | ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: *, archived: *, name: * }>,
      ...{ exporter: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, unitPrice?: ?({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, product: ({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: *, serial: * }>,
        ...{ tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, files: Array<({
            ...{| __typename: 'File' |},
          ...$Pick<File, { id: *, path: * }>,
          ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
        ...{ grossWeight?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), volume?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), size?: ?({
            ...{| __typename: 'Size' |},
          ...{ length: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), width: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), height: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }) }
        }) }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
        ...{ grossWeight?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), volume?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), size?: ?({
            ...{| __typename: 'Size' |},
          ...{ length: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), width: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), height: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }) }
        }) }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: * }>
    }) | ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: * }>
    }) | ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: * }>
    }) | ({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: * }>
    }) | ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: * }>
    }) | ({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: * }>
    }) | ({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: * }>
    }) | ({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>
    }) | ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: * }>
    }) | ({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: * }>
    }) | ({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: * }>
    }) | ({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: * }>
    }) | ({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: * }>
    }) | ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | ({
        ...{| __typename: 'Partnership' |},
      ...$Pick<Partnership, { id: * }>
    }) | ({
        ...{| __typename: 'Role' |},
      ...$Pick<Role, { id: * }>
    }) | ({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: * }>
    }) | ({
        ...{| __typename: 'Mask' |},
      ...$Pick<Mask, { id: * }>
    }) | ({
        ...{| __typename: 'MaskEdit' |},
      ...$Pick<MaskEdit, { id: * }>
    }) | ({
        ...{| __typename: 'FieldDefinition' |},
      ...$Pick<FieldDefinition, { id: * }>
    }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |} }
});

export type FileDeleteMutationVariables = {
  id: $ElementType<Scalars, 'ID'>
};


export type FileDeleteMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ fileDelete?: ?({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |} }
});

export type FileDeleteManyMutationVariables = {
  ids: Array<$ElementType<Scalars, 'ID'>>
};


export type FileDeleteManyMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ fileDeleteMany: Array<?({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}> }
});

export type FileMarkAsReadMutationVariables = {
  entity: EntityInput
};


export type FileMarkAsReadMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ fileMarkAsRead?: ?({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |} }
});

export type FileUploadMutationVariables = {
  file: $ElementType<Scalars, 'Upload'>,
  input: FileInput
};


export type FileUploadMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ fileUpload: ({
      ...{| __typename: 'File' |},
    ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
    ...{ tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, entity?: ?({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: *, name: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: *, name: * }>,
      ...{ product: ({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: *, poNo: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: * }>
    }) | ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | ({
        ...{| __typename: 'Partnership' |},
      ...$Pick<Partnership, { id: * }>
    }) | ({
        ...{| __typename: 'Role' |},
      ...$Pick<Role, { id: * }>
    }) | ({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Mask' |},
      ...$Pick<Mask, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'MaskEdit' |},
      ...$Pick<MaskEdit, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'FieldDefinition' |},
      ...$Pick<FieldDefinition, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |} }
});

export type DocumentQueryVariables = {
  id: $ElementType<Scalars, 'ID'>
};


export type DocumentQuery = ({
    ...{| __typename: 'Query' |},
  ...{ file: ({
      ...{| __typename: 'File' |},
    ...$Pick<File, { id: *, updatedAt: *, name: *, type: *, status: *, size: *, path: *, memo?: * }>,
    ...{ updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, messageGroup?: ?({
        ...{| __typename: 'MessageGroup' |},
      ...$Pick<MessageGroup, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity?: ?({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: * }>
    }) | ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: * }>
    }) | ({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: * }>
    }) | ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: * }>
    }) | ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: * }>
    }) | ({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: * }>
    }) | ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: * }>
    }) | ({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: * }>
    }) | ({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: * }>
    }) | ({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>
    }) | ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: * }>
    }) | ({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: * }>
    }) | ({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: * }>
    }) | ({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: * }>
    }) | ({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: * }>
    }) | ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | ({
        ...{| __typename: 'Partnership' |},
      ...$Pick<Partnership, { id: * }>
    }) | ({
        ...{| __typename: 'Role' |},
      ...$Pick<Role, { id: * }>
    }) | ({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: * }>
    }) | ({
        ...{| __typename: 'Mask' |},
      ...$Pick<Mask, { id: * }>
    }) | ({
        ...{| __typename: 'MaskEdit' |},
      ...$Pick<MaskEdit, { id: * }>
    }) | ({
        ...{| __typename: 'FieldDefinition' |},
      ...$Pick<FieldDefinition, { id: * }>
    }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, order?: ?({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: * }>
    }) | ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: * }>
    }) | ({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: * }>
    }) | ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
      ...{ totalPrice: ({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, timeline: ({
          ...{| __typename: 'Timeline' |},
        ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
      }), ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
          ...{| __typename: 'Notification' |},
        ...$Pick<Notification, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: * }>
    }) | ({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: * }>
    }) | ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: * }>
    }) | ({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: * }>
    }) | ({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: * }>
    }) | ({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>
    }) | ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: * }>
    }) | ({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: * }>
    }) | ({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: * }>
    }) | ({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: * }>
    }) | ({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: * }>
    }) | ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | ({
        ...{| __typename: 'Partnership' |},
      ...$Pick<Partnership, { id: * }>
    }) | ({
        ...{| __typename: 'Role' |},
      ...$Pick<Role, { id: * }>
    }) | ({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: * }>
    }) | ({
        ...{| __typename: 'Mask' |},
      ...$Pick<Mask, { id: * }>
    }) | ({
        ...{| __typename: 'MaskEdit' |},
      ...$Pick<MaskEdit, { id: * }>
    }) | ({
        ...{| __typename: 'FieldDefinition' |},
      ...$Pick<FieldDefinition, { id: * }>
    }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, orderItem?: ?({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: * }>
    }) | ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: * }>
    }) | ({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: * }>
    }) | ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: * }>
    }) | ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
      ...{ price: ({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, order: ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, poNo: * }>,
        ...{ importer: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: *, serial: * }>,
          ...{ tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, files: Array<({
              ...{| __typename: 'File' |},
            ...$Pick<File, { id: *, path: * }>,
            ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: * }>
    }) | ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: * }>
    }) | ({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: * }>
    }) | ({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: * }>
    }) | ({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>
    }) | ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: * }>
    }) | ({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: * }>
    }) | ({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: * }>
    }) | ({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: * }>
    }) | ({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: * }>
    }) | ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | ({
        ...{| __typename: 'Partnership' |},
      ...$Pick<Partnership, { id: * }>
    }) | ({
        ...{| __typename: 'Role' |},
      ...$Pick<Role, { id: * }>
    }) | ({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: * }>
    }) | ({
        ...{| __typename: 'Mask' |},
      ...$Pick<Mask, { id: * }>
    }) | ({
        ...{| __typename: 'MaskEdit' |},
      ...$Pick<MaskEdit, { id: * }>
    }) | ({
        ...{| __typename: 'FieldDefinition' |},
      ...$Pick<FieldDefinition, { id: * }>
    }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment?: ?({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: * }>
    }) | ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: * }>
    }) | ({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: * }>
    }) | ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: * }>
    }) | ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: * }>
    }) | ({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: * }>
    }) | ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
      ...{ totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalVolumeOverride?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), timeline: ({
          ...{| __typename: 'Timeline' |},
        ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
      }), containerTypeCounts: Array<({
          ...{| __typename: 'ContainerTypeCount' |},
        ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
      })>, importer?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, voyages: Array<({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
        ...{ departurePort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), arrivalPort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), departure: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ customClearance: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
        ...{ warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>,
        ...{ orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>,
          ...{ productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>,
            ...{ exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
          ...{| __typename: 'Notification' |},
        ...$Pick<Notification, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: * }>
    }) | ({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: * }>
    }) | ({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>
    }) | ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: * }>
    }) | ({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: * }>
    }) | ({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: * }>
    }) | ({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: * }>
    }) | ({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: * }>
    }) | ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | ({
        ...{| __typename: 'Partnership' |},
      ...$Pick<Partnership, { id: * }>
    }) | ({
        ...{| __typename: 'Role' |},
      ...$Pick<Role, { id: * }>
    }) | ({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: * }>
    }) | ({
        ...{| __typename: 'Mask' |},
      ...$Pick<Mask, { id: * }>
    }) | ({
        ...{| __typename: 'MaskEdit' |},
      ...$Pick<MaskEdit, { id: * }>
    }) | ({
        ...{| __typename: 'FieldDefinition' |},
      ...$Pick<FieldDefinition, { id: * }>
    }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider?: ?({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: * }>
    }) | ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: *, archived: *, name: * }>,
      ...{ exporter: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, unitPrice?: ?({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, product: ({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: *, serial: * }>,
        ...{ tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, files: Array<({
            ...{| __typename: 'File' |},
          ...$Pick<File, { id: *, path: * }>,
          ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
        ...{ grossWeight?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), volume?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), size?: ?({
            ...{| __typename: 'Size' |},
          ...{ length: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), width: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), height: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }) }
        }) }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
        ...{ grossWeight?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), volume?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), size?: ?({
            ...{| __typename: 'Size' |},
          ...{ length: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), width: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), height: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }) }
        }) }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: * }>
    }) | ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: * }>
    }) | ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: * }>
    }) | ({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: * }>
    }) | ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: * }>
    }) | ({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: * }>
    }) | ({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: * }>
    }) | ({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>
    }) | ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: * }>
    }) | ({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: * }>
    }) | ({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: * }>
    }) | ({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: * }>
    }) | ({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: * }>
    }) | ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | ({
        ...{| __typename: 'Partnership' |},
      ...$Pick<Partnership, { id: * }>
    }) | ({
        ...{| __typename: 'Role' |},
      ...$Pick<Role, { id: * }>
    }) | ({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: * }>
    }) | ({
        ...{| __typename: 'Mask' |},
      ...$Pick<Mask, { id: * }>
    }) | ({
        ...{| __typename: 'MaskEdit' |},
      ...$Pick<MaskEdit, { id: * }>
    }) | ({
        ...{| __typename: 'FieldDefinition' |},
      ...$Pick<FieldDefinition, { id: * }>
    }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |} }
});

export type DocumentFolderQueryVariables = {
  id: $ElementType<Scalars, 'ID'>
};


export type DocumentFolderQuery = ({
    ...{| __typename: 'Query' |},
  ...{ folder: ({
      ...{| __typename: 'Folder' |},
    ...$Pick<Folder, { id: *, name: *, entityType: *, folderType: *, totalCount: * }>
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | ({
      ...{| __typename: 'NotFound' |},
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: *, type: *, service: * }>
    }) }
  }) }
});

export type DocumentFoldersQueryVariables = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?FolderFilterInput,
  sortBy?: ?FolderSortInput
};


export type DocumentFoldersQuery = ({
    ...{| __typename: 'Query' |},
  ...{ folders: ({
      ...{| __typename: 'FolderPayloadPaginatedSearch' |},
    ...$Pick<FolderPayloadPaginatedSearch, { page: *, totalPage: * }>,
    ...{ nodes: Array<({
        ...{| __typename: 'Folder' |},
      ...$Pick<Folder, { id: *, name: *, entityType: *, folderType: *, totalCount: * }>
    }) | ({
        ...{| __typename: 'BadRequest' |},
      ...{ violations: Array<({
          ...{| __typename: 'Violation' |},
        ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
        ...{ parameters: Array<({
            ...{| __typename: 'ViolationParameter' |},
          ...$Pick<ViolationParameter, { key: *, value: * }>
        })> }
      })> }
    }) | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | ({
        ...{| __typename: 'NotFound' |},
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: *, type: *, service: * }>
      }) }
    })> }
  }) }
});

export type DocumentListQueryVariables = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?FileFilterInput,
  sortBy?: ?FileSortInput
};


export type DocumentListQuery = ({
    ...{| __typename: 'Query' |},
  ...{ files: ({
      ...{| __typename: 'FilePayloadPaginatedSearch' |},
    ...$Pick<FilePayloadPaginatedSearch, { page: *, totalPage: * }>,
    ...{ nodes: Array<({
        ...{| __typename: 'File' |},
      ...$Pick<File, { orphan: *, id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, entity?: ?({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, poNo: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | ({
          ...{| __typename: 'Partnership' |},
        ...$Pick<Partnership, { id: * }>
      }) | ({
          ...{| __typename: 'Role' |},
        ...$Pick<Role, { id: * }>
      }) | ({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Mask' |},
        ...$Pick<Mask, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'MaskEdit' |},
        ...$Pick<MaskEdit, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}> }
  }) }
});

export type DocumentTimelineQueryVariables = {
  id: $ElementType<Scalars, 'ID'>,
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>
};


export type DocumentTimelineQuery = ({
    ...{| __typename: 'Query' |},
  ...{ file: ({
      ...{| __typename: 'File' |},
    ...$Pick<File, { id: * }>,
    ...{ timeline: ({
        ...{| __typename: 'Timeline' |},
      ...{ entries: ({
          ...{| __typename: 'TimelineEntryPaginatedList' |},
        ...$Pick<TimelineEntryPaginatedList, { page: *, totalPage: * }>,
        ...{ nodes: Array<({
            ...{| __typename: 'TimelineEvent' |},
          ...$Pick<TimelineEvent, { id: *, createdAt: * }>,
          ...{ entity: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: * }>
          }) | ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>
          }) | ({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: * }>
          }) | ({
              ...{| __typename: 'Order' |},
            ...$Pick<Order, { id: * }>
          }) | ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>
          }) | ({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: * }>
          }) | ({
              ...{| __typename: 'Shipment' |},
            ...$Pick<Shipment, { id: * }>
          }) | ({
              ...{| __typename: 'Voyage' |},
            ...$Pick<Voyage, { id: * }>
          }) | ({
              ...{| __typename: 'Container' |},
            ...$Pick<Container, { id: * }>
          }) | ({
              ...{| __typename: 'ContainerGroup' |},
            ...$Pick<ContainerGroup, { id: * }>
          }) | ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: * }>
          }) | ({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: * }>
          }) | ({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: * }>
          }) | ({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: * }>
          }) | ({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: * }>
          }) | ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: * }>
          }) | ({
              ...{| __typename: 'Partnership' |},
            ...$Pick<Partnership, { id: * }>
          }) | ({
              ...{| __typename: 'Role' |},
            ...$Pick<Role, { id: * }>
          }) | ({
              ...{| __typename: 'File' |},
            ...$Pick<File, { id: * }>
          }) | ({
              ...{| __typename: 'Mask' |},
            ...$Pick<Mask, { id: * }>
          }) | ({
              ...{| __typename: 'MaskEdit' |},
            ...$Pick<MaskEdit, { id: * }>
          }) | ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: * }>
          }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, createdBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, logs: Array<({
              ...{| __typename: 'Log' |},
            ...$Pick<Log, { id: *, translationKey: * }>,
            ...{ entity: ({
                ...{| __typename: 'Product' |},
              ...$Pick<Product, { id: * }>
            }) | ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>
            }) | ({
                ...{| __typename: 'ProductProviderPackage' |},
              ...$Pick<ProductProviderPackage, { id: * }>
            }) | ({
                ...{| __typename: 'Order' |},
              ...$Pick<Order, { id: * }>
            }) | ({
                ...{| __typename: 'OrderItem' |},
              ...$Pick<OrderItem, { id: * }>
            }) | ({
                ...{| __typename: 'Batch' |},
              ...$Pick<Batch, { id: * }>
            }) | ({
                ...{| __typename: 'Shipment' |},
              ...$Pick<Shipment, { id: * }>
            }) | ({
                ...{| __typename: 'Voyage' |},
              ...$Pick<Voyage, { id: * }>
            }) | ({
                ...{| __typename: 'Container' |},
              ...$Pick<Container, { id: * }>
            }) | ({
                ...{| __typename: 'ContainerGroup' |},
              ...$Pick<ContainerGroup, { id: * }>
            }) | ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: * }>
            }) | ({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: * }>
            }) | ({
                ...{| __typename: 'Warehouse' |},
              ...$Pick<Warehouse, { id: * }>
            }) | ({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: * }>
            }) | ({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: * }>
            }) | ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: * }>
            }) | ({
                ...{| __typename: 'Partnership' |},
              ...$Pick<Partnership, { id: * }>
            }) | ({
                ...{| __typename: 'Role' |},
              ...$Pick<Role, { id: * }>
            }) | ({
                ...{| __typename: 'File' |},
              ...$Pick<File, { id: *, type: * }>
            }) | ({
                ...{| __typename: 'Mask' |},
              ...$Pick<Mask, { id: * }>
            }) | ({
                ...{| __typename: 'MaskEdit' |},
              ...$Pick<MaskEdit, { id: * }>
            }) | ({
                ...{| __typename: 'FieldDefinition' |},
              ...$Pick<FieldDefinition, { id: * }>
            }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, parameters: Array<({
                ...{| __typename: 'Parameter' |},
              ...$Pick<Parameter, { key: * }>,
              ...{ value?: ?({
                  ...{| __typename: 'StringValue' |},
                ...$Pick<StringValue, { string: * }>
              }) | ({
                  ...{| __typename: 'IntValue' |},
                ...$Pick<IntValue, { int: * }>
              }) | ({
                  ...{| __typename: 'FloatValue' |},
                ...$Pick<FloatValue, { float: * }>
              }) | ({
                  ...{| __typename: 'BooleanValue' |},
                ...$Pick<BooleanValue, { boolean: * }>
              }) | ({
                  ...{| __typename: 'DateTimeValue' |},
                ...$Pick<DateTimeValue, { datetime: * }>
              }) | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | ({
                  ...{| __typename: 'MetricValueValue' |},
                ...{ metricValue: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }) }
              }) | ({
                  ...{| __typename: 'PriceValue' |},
                ...{ priceValue: ({
                    ...{| __typename: 'NewPrice' |},
                  ...$Pick<NewPrice, { amount: *, currency: * }>
                }) }
              }) | ({
                  ...{| __typename: 'SizeValue' |},
                ...{ size: ({
                    ...{| __typename: 'Size' |},
                  ...{ height: ({
                      ...{| __typename: 'MetricValue' |},
                    ...$Pick<MetricValue, { value: *, metric: * }>
                  }), length: ({
                      ...{| __typename: 'MetricValue' |},
                    ...$Pick<MetricValue, { value: *, metric: * }>
                  }), width: ({
                      ...{| __typename: 'MetricValue' |},
                    ...$Pick<MetricValue, { value: *, metric: * }>
                  }) }
                }) }
              }) | ({
                  ...{| __typename: 'EntityValue' |},
                ...{ entity?: ?({
                    ...{| __typename: 'Product' |},
                  ...$Pick<Product, { id: * }>
                }) | ({
                    ...{| __typename: 'ProductProvider' |},
                  ...$Pick<ProductProvider, { id: * }>
                }) | ({
                    ...{| __typename: 'ProductProviderPackage' |},
                  ...$Pick<ProductProviderPackage, { id: * }>
                }) | ({
                    ...{| __typename: 'Order' |},
                  ...$Pick<Order, { id: * }>
                }) | ({
                    ...{| __typename: 'OrderItem' |},
                  ...$Pick<OrderItem, { id: * }>
                }) | ({
                    ...{| __typename: 'Batch' |},
                  ...$Pick<Batch, { id: * }>
                }) | ({
                    ...{| __typename: 'Shipment' |},
                  ...$Pick<Shipment, { id: * }>
                }) | ({
                    ...{| __typename: 'Voyage' |},
                  ...$Pick<Voyage, { id: * }>
                }) | ({
                    ...{| __typename: 'Container' |},
                  ...$Pick<Container, { id: * }>
                }) | ({
                    ...{| __typename: 'ContainerGroup' |},
                  ...$Pick<ContainerGroup, { id: * }>
                }) | ({
                    ...{| __typename: 'TimelineDate' |},
                  ...$Pick<TimelineDate, { id: * }>
                }) | ({
                    ...{| __typename: 'TimelineDateRevision' |},
                  ...$Pick<TimelineDateRevision, { id: * }>
                }) | ({
                    ...{| __typename: 'Warehouse' |},
                  ...$Pick<Warehouse, { id: * }>
                }) | ({
                    ...{| __typename: 'Tag' |},
                  ...$Pick<Tag, { id: *, name: *, color: * }>
                }) | ({
                    ...{| __typename: 'User' |},
                  ...$Pick<User, { id: *, firstName: *, lastName: * }>
                }) | ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: *, types: * }>,
                  ...{ partner?: ?({
                      ...{| __typename: 'Partner' |},
                    ...$Pick<Partner, { id: *, name?: *, code?: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
                }) | ({
                    ...{| __typename: 'Partnership' |},
                  ...$Pick<Partnership, { id: * }>
                }) | ({
                    ...{| __typename: 'Role' |},
                  ...$Pick<Role, { id: * }>
                }) | ({
                    ...{| __typename: 'File' |},
                  ...$Pick<File, { id: * }>
                }) | ({
                    ...{| __typename: 'Mask' |},
                  ...$Pick<Mask, { id: * }>
                }) | ({
                    ...{| __typename: 'MaskEdit' |},
                  ...$Pick<MaskEdit, { id: * }>
                }) | ({
                    ...{| __typename: 'FieldDefinition' |},
                  ...$Pick<FieldDefinition, { id: * }>
                }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | ({
                  ...{| __typename: 'Values' |},
                ...{ values: Array<?({
                    ...{| __typename: 'StringValue' |},
                  ...$Pick<StringValue, { string: * }>
                }) | ({
                    ...{| __typename: 'IntValue' |},
                  ...$Pick<IntValue, { int: * }>
                }) | ({
                    ...{| __typename: 'FloatValue' |},
                  ...$Pick<FloatValue, { float: * }>
                }) | ({
                    ...{| __typename: 'BooleanValue' |},
                  ...$Pick<BooleanValue, { boolean: * }>
                }) | ({
                    ...{| __typename: 'DateTimeValue' |},
                  ...$Pick<DateTimeValue, { datetime: * }>
                }) | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | ({
                    ...{| __typename: 'MetricValueValue' |},
                  ...{ metricValue: ({
                      ...{| __typename: 'MetricValue' |},
                    ...$Pick<MetricValue, { value: *, metric: * }>
                  }) }
                }) | ({
                    ...{| __typename: 'PriceValue' |},
                  ...{ priceValue: ({
                      ...{| __typename: 'NewPrice' |},
                    ...$Pick<NewPrice, { amount: *, currency: * }>
                  }) }
                }) | ({
                    ...{| __typename: 'SizeValue' |},
                  ...{ size: ({
                      ...{| __typename: 'Size' |},
                    ...{ height: ({
                        ...{| __typename: 'MetricValue' |},
                      ...$Pick<MetricValue, { value: *, metric: * }>
                    }), length: ({
                        ...{| __typename: 'MetricValue' |},
                      ...$Pick<MetricValue, { value: *, metric: * }>
                    }), width: ({
                        ...{| __typename: 'MetricValue' |},
                      ...$Pick<MetricValue, { value: *, metric: * }>
                    }) }
                  }) }
                }) | ({
                    ...{| __typename: 'EntityValue' |},
                  ...{ entity?: ?({
                      ...{| __typename: 'Product' |},
                    ...$Pick<Product, { id: * }>
                  }) | ({
                      ...{| __typename: 'ProductProvider' |},
                    ...$Pick<ProductProvider, { id: * }>
                  }) | ({
                      ...{| __typename: 'ProductProviderPackage' |},
                    ...$Pick<ProductProviderPackage, { id: * }>
                  }) | ({
                      ...{| __typename: 'Order' |},
                    ...$Pick<Order, { id: * }>
                  }) | ({
                      ...{| __typename: 'OrderItem' |},
                    ...$Pick<OrderItem, { id: * }>
                  }) | ({
                      ...{| __typename: 'Batch' |},
                    ...$Pick<Batch, { id: * }>
                  }) | ({
                      ...{| __typename: 'Shipment' |},
                    ...$Pick<Shipment, { id: * }>
                  }) | ({
                      ...{| __typename: 'Voyage' |},
                    ...$Pick<Voyage, { id: * }>
                  }) | ({
                      ...{| __typename: 'Container' |},
                    ...$Pick<Container, { id: * }>
                  }) | ({
                      ...{| __typename: 'ContainerGroup' |},
                    ...$Pick<ContainerGroup, { id: * }>
                  }) | ({
                      ...{| __typename: 'TimelineDate' |},
                    ...$Pick<TimelineDate, { id: * }>
                  }) | ({
                      ...{| __typename: 'TimelineDateRevision' |},
                    ...$Pick<TimelineDateRevision, { id: * }>
                  }) | ({
                      ...{| __typename: 'Warehouse' |},
                    ...$Pick<Warehouse, { id: * }>
                  }) | ({
                      ...{| __typename: 'Tag' |},
                    ...$Pick<Tag, { id: *, name: *, color: * }>
                  }) | ({
                      ...{| __typename: 'User' |},
                    ...$Pick<User, { id: *, firstName: *, lastName: * }>
                  }) | ({
                      ...{| __typename: 'Organization' |},
                    ...$Pick<Organization, { id: *, name: *, types: * }>,
                    ...{ partner?: ?({
                        ...{| __typename: 'Partner' |},
                      ...$Pick<Partner, { id: *, name?: *, code?: * }>
                    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
                  }) | ({
                      ...{| __typename: 'Partnership' |},
                    ...$Pick<Partnership, { id: * }>
                  }) | ({
                      ...{| __typename: 'Role' |},
                    ...$Pick<Role, { id: * }>
                  }) | ({
                      ...{| __typename: 'File' |},
                    ...$Pick<File, { id: * }>
                  }) | ({
                      ...{| __typename: 'Mask' |},
                    ...$Pick<Mask, { id: * }>
                  }) | ({
                      ...{| __typename: 'MaskEdit' |},
                    ...$Pick<MaskEdit, { id: * }>
                  }) | ({
                      ...{| __typename: 'FieldDefinition' |},
                    ...$Pick<FieldDefinition, { id: * }>
                  }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
                }) | {| __typename: 'Values' |}> }
              }) }
            })> }
          })> }
        }) | ({
            ...{| __typename: 'TimelineComment' |},
          ...$Pick<TimelineComment, { id: *, content: *, createdAt: *, updatedAt: * }>,
          ...{ createdBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) }
    }) }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type OrderItemFilesQueryVariables = {
  id: $ElementType<Scalars, 'ID'>
};


export type OrderItemFilesQuery = ({
    ...{| __typename: 'Query' |},
  ...{ orderItem: ({
      ...{| __typename: 'OrderItem' |},
    ...$Pick<OrderItem, { id: * }>,
    ...{ files: Array<({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
      ...{ tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, entity?: ?({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, poNo: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | ({
          ...{| __typename: 'Partnership' |},
        ...$Pick<Partnership, { id: * }>
      }) | ({
          ...{| __typename: 'Role' |},
        ...$Pick<Role, { id: * }>
      }) | ({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Mask' |},
        ...$Pick<Mask, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'MaskEdit' |},
        ...$Pick<MaskEdit, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type ProductFilesQueryVariables = {
  id: $ElementType<Scalars, 'ID'>
};


export type ProductFilesQuery = ({
    ...{| __typename: 'Query' |},
  ...{ product: ({
      ...{| __typename: 'Product' |},
    ...$Pick<Product, { id: * }>,
    ...{ productProviders: Array<({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: *, name: *, archived: *, updatedAt: *, memo?: * }>,
      ...{ updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, files: Array<({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
        ...{ tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, entity?: ?({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: *, name: * }>,
          ...{ product: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: *, poNo: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | ({
            ...{| __typename: 'Partnership' |},
          ...$Pick<Partnership, { id: * }>
        }) | ({
            ...{| __typename: 'Role' |},
          ...$Pick<Role, { id: * }>
        }) | ({
            ...{| __typename: 'File' |},
          ...$Pick<File, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Mask' |},
          ...$Pick<Mask, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'MaskEdit' |},
          ...$Pick<MaskEdit, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |} }
});

export type BadRequestFragment = ({
    ...{| __typename: 'BadRequest' |},
  ...{ violations: Array<({
      ...{| __typename: 'Violation' |},
    ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
    ...{ parameters: Array<({
        ...{| __typename: 'ViolationParameter' |},
      ...$Pick<ViolationParameter, { key: *, value: * }>
    })> }
  })> }
});

export type ForbiddenFragment = ({
    ...{| __typename: 'Forbidden' |},
  ...$Pick<Forbidden, { reason?: * }>,
  ...{ reference?: ?({
      ...{| __typename: 'Reference' |},
    ...$Pick<Reference, { id: * }>
  }) }
});

export type NotFoundFragment = ({
    ...{| __typename: 'NotFound' |},
  ...{ reference?: ?({
      ...{| __typename: 'Reference' |},
    ...$Pick<Reference, { id: *, type: *, service: * }>
  }) }
});

export type NotificationUnseen_Product_Fragment = ({
    ...{| __typename: 'Product' |},
  ...$Pick<Product, { notificationUnseenCount: * }>,
  ...{ notificationUnseen: Array<({
      ...{| __typename: 'Notification' |},
    ...$Pick<Notification, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type NotificationUnseen_Order_Fragment = ({
    ...{| __typename: 'Order' |},
  ...$Pick<Order, { notificationUnseenCount: * }>,
  ...{ notificationUnseen: Array<({
      ...{| __typename: 'Notification' |},
    ...$Pick<Notification, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type NotificationUnseen_Shipment_Fragment = ({
    ...{| __typename: 'Shipment' |},
  ...$Pick<Shipment, { notificationUnseenCount: * }>,
  ...{ notificationUnseen: Array<({
      ...{| __typename: 'Notification' |},
    ...$Pick<Notification, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type NotificationUnseen_Warehouse_Fragment = ({
    ...{| __typename: 'Warehouse' |},
  ...$Pick<Warehouse, { notificationUnseenCount: * }>,
  ...{ notificationUnseen: Array<({
      ...{| __typename: 'Notification' |},
    ...$Pick<Notification, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type NotificationUnseenFragment = NotificationUnseen_Product_Fragment | NotificationUnseen_Order_Fragment | NotificationUnseen_Shipment_Fragment | NotificationUnseen_Warehouse_Fragment;

export type ImportMutationVariables = {
  file: $ElementType<Scalars, 'Upload'>
};


export type ImportMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ import: ({
      ...{| __typename: 'Import' |},
    ...$Pick<Import, { id: * }>
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |} }
});

export type ItemCardFragment = ({
    ...{| __typename: 'OrderItem' |},
  ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
  ...{ price: ({
      ...{| __typename: 'NewPrice' |},
    ...$Pick<NewPrice, { amount: *, currency: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, order: ({
      ...{| __typename: 'Order' |},
    ...$Pick<Order, { id: *, poNo: * }>,
    ...{ importer: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>,
      ...{ partner?: ?({
          ...{| __typename: 'Partner' |},
        ...$Pick<Partner, { id: *, name?: *, code?: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>,
      ...{ partner?: ?({
          ...{| __typename: 'Partner' |},
        ...$Pick<Partner, { id: *, name?: *, code?: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
      ...{| __typename: 'ProductProvider' |},
    ...$Pick<ProductProvider, { id: *, name: * }>,
    ...{ product: ({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: *, name: *, serial: * }>,
      ...{ tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, files: Array<({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: *, path: * }>,
        ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type ItemCardWithOwnedFragment = ({
    ...{| __typename: 'OrderItem' |},
  ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
  ...{ price: ({
      ...{| __typename: 'NewPrice' |},
    ...$Pick<NewPrice, { amount: *, currency: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, order: ({
      ...{| __typename: 'Order' |},
    ...$Pick<Order, { id: *, poNo: * }>,
    ...{ importer: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>,
      ...{ partner?: ?({
          ...{| __typename: 'Partner' |},
        ...$Pick<Partner, { id: *, name?: *, code?: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>,
      ...{ partner?: ?({
          ...{| __typename: 'Partner' |},
        ...$Pick<Partner, { id: *, name?: *, code?: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
      ...{| __typename: 'ProductProvider' |},
    ...$Pick<ProductProvider, { id: *, name: * }>,
    ...{ product: ({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: *, name: *, serial: * }>,
      ...{ tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, files: Array<({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: *, path: * }>,
        ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type ItemEntityCardFragment = ({
    ...{| __typename: 'OrderItem' |},
  ...$Pick<OrderItem, { id: *, updatedAt: *, createdAt: *, archived: *, no: *, deliveryDate?: *, quantity: * }>,
  ...{ productProvider: ({
      ...{| __typename: 'ProductProvider' |},
    ...$Pick<ProductProvider, { id: *, name: * }>,
    ...{ supplier?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, product: ({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: *, name: *, serial: * }>,
      ...{ files: Array<({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: * }>,
        ...{ pathSmall: $ElementType<File, 'path'> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, price: ({
      ...{| __typename: 'NewPrice' |},
    ...$Pick<NewPrice, { amount: *, currency: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type ItemFormFragment = ({
    ...{| __typename: 'OrderItem' |},
  ...$Pick<OrderItem, { id: *, archived: *, updatedAt: *, no: *, quantity: *, deliveryDate?: *, memo?: * }>,
  ...{ updatedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, price: ({
      ...{| __typename: 'NewPrice' |},
    ...$Pick<NewPrice, { amount: *, currency: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
      ...{| __typename: 'CustomFields' |},
    ...{ fieldValues: Array<({
        ...{| __typename: 'FieldValue' |},
      ...{ value: ({
          ...{| __typename: 'StringValue' |},
        ...$Pick<StringValue, { string: * }>
      }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }), tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, order: ({
      ...{| __typename: 'Order' |},
    ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
    ...{ totalPrice: ({
        ...{| __typename: 'NewPrice' |},
      ...$Pick<NewPrice, { amount: *, currency: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>,
      ...{ partner?: ?({
          ...{| __typename: 'Partner' |},
        ...$Pick<Partner, { id: *, name?: *, code?: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>,
      ...{ partner?: ?({
          ...{| __typename: 'Partner' |},
        ...$Pick<Partner, { id: *, name?: *, code?: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, timeline: ({
        ...{| __typename: 'Timeline' |},
      ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
    }), ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
        ...{| __typename: 'Notification' |},
      ...$Pick<Notification, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
      ...{| __typename: 'ProductProvider' |},
    ...$Pick<ProductProvider, { id: *, archived: *, name: * }>,
    ...{ exporter: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>,
      ...{ partner?: ?({
          ...{| __typename: 'Partner' |},
        ...$Pick<Partner, { id: *, name?: *, code?: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>,
      ...{ partner?: ?({
          ...{| __typename: 'Partner' |},
        ...$Pick<Partner, { id: *, name?: *, code?: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, unitPrice?: ?({
        ...{| __typename: 'NewPrice' |},
      ...$Pick<NewPrice, { amount: *, currency: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, product: ({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: *, name: *, serial: * }>,
      ...{ tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, files: Array<({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: *, path: * }>,
        ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
      ...{ grossWeight?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), volume?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), size?: ?({
          ...{| __typename: 'Size' |},
        ...{ length: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), width: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), height: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }) }
      }) }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
      ...{ grossWeight?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), volume?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), size?: ?({
          ...{| __typename: 'Size' |},
        ...{ length: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), width: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), height: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }) }
      }) }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, batches: Array<({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { id: *, sort: *, shipmentSort: *, archived: *, autoCalculatePackageQuantity: *, autoCalculatePackageVolume: *, updatedAt: *, memo?: *, no: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: *, latestQuantityField: *, producedAt?: *, deliveredAt?: *, desiredAt?: *, expiredAt?: *, packageName?: *, packageCapacity?: *, packageQuantity?: * }>,
    ...{ updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), totalPrice?: ?({
        ...{| __typename: 'NewPrice' |},
      ...$Pick<NewPrice, { amount: *, currency: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
        ...{| __typename: 'CustomFields' |},
      ...{ fieldValues: Array<({
          ...{| __typename: 'FieldValue' |},
        ...{ value: ({
            ...{| __typename: 'StringValue' |},
          ...$Pick<StringValue, { string: * }>
        }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }), packageGrossWeight?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), packageVolume?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), packageSize?: ?({
        ...{| __typename: 'Size' |},
      ...{ length: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), width: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), height: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }) }
    }), tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, orderItem: ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
      ...{ price: ({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, order: ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
        ...{ totalPrice: ({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, timeline: ({
            ...{| __typename: 'Timeline' |},
          ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
        }), ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
            ...{| __typename: 'Notification' |},
          ...$Pick<Notification, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ exporter: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
          ...{ grossWeight?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), volume?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), size?: ?({
              ...{| __typename: 'Size' |},
            ...{ length: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), width: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), height: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }) }
          }) }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
          ...{ grossWeight?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), volume?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), size?: ?({
              ...{| __typename: 'Size' |},
            ...{ length: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), width: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), height: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }) }
          }) }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: *, serial: * }>,
          ...{ tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, files: Array<({
              ...{| __typename: 'File' |},
            ...$Pick<File, { id: *, path: * }>,
            ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment?: ?({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
      ...{ forwarders: Array<({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
          ...{ tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalVolumeOverride?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), timeline: ({
          ...{| __typename: 'Timeline' |},
        ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
      }), containerTypeCounts: Array<({
          ...{| __typename: 'ContainerTypeCount' |},
        ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
      })>, importer?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, voyages: Array<({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
        ...{ departurePort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), arrivalPort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), departure: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ customClearance: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
        ...{ warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>,
        ...{ orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>,
          ...{ productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>,
            ...{ exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
          ...{| __typename: 'Notification' |},
        ...$Pick<Notification, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: *, no: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: * }>,
      ...{ representativeBatch?: ?({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>,
        ...{ orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>,
          ...{ productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>,
            ...{ product: ({
                ...{| __typename: 'Product' |},
              ...$Pick<Product, { id: *, name: *, serial: * }>,
              ...{ files: Array<({
                  ...{| __typename: 'File' |},
                ...$Pick<File, { id: *, path: * }>,
                ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, files: Array<({
      ...{| __typename: 'File' |},
    ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
    ...{ tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, entity?: ?({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: *, name: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: *, name: * }>,
      ...{ product: ({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: *, poNo: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: * }>
    }) | ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | ({
        ...{| __typename: 'Partnership' |},
      ...$Pick<Partnership, { id: * }>
    }) | ({
        ...{| __typename: 'Role' |},
      ...$Pick<Role, { id: * }>
    }) | ({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Mask' |},
      ...$Pick<Mask, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'MaskEdit' |},
      ...$Pick<MaskEdit, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'FieldDefinition' |},
      ...$Pick<FieldDefinition, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, shipments: Array<({
      ...{| __typename: 'Shipment' |},
    ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
    ...{ totalVolume: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), totalVolumeOverride?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), timeline: ({
        ...{| __typename: 'Timeline' |},
      ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
    }), containerTypeCounts: Array<({
        ...{| __typename: 'ContainerTypeCount' |},
      ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
    })>, importer?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>,
      ...{ partner?: ?({
          ...{| __typename: 'Partner' |},
        ...$Pick<Partner, { id: *, name?: *, code?: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>,
      ...{ partner?: ?({
          ...{| __typename: 'Partner' |},
        ...$Pick<Partner, { id: *, name?: *, code?: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, date: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, voyages: Array<({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
      ...{ departurePort?: ?({
          ...{| __typename: 'Port' |},
        ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
      }), arrivalPort?: ?({
          ...{| __typename: 'Port' |},
        ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
      }), departure: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>,
      ...{ customClearance: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
      ...{ warehouse?: ?({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: * }>,
      ...{ orderItem: ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: * }>,
        ...{ productProvider: ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: * }>,
          ...{ exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
        ...{| __typename: 'Notification' |},
      ...$Pick<Notification, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type ItemInBatchFormFragment = ({
    ...{| __typename: 'OrderItem' |},
  ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
  ...{ price: ({
      ...{| __typename: 'NewPrice' |},
    ...$Pick<NewPrice, { amount: *, currency: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, order: ({
      ...{| __typename: 'Order' |},
    ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
    ...{ totalPrice: ({
        ...{| __typename: 'NewPrice' |},
      ...$Pick<NewPrice, { amount: *, currency: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>,
      ...{ partner?: ?({
          ...{| __typename: 'Partner' |},
        ...$Pick<Partner, { id: *, name?: *, code?: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>,
      ...{ partner?: ?({
          ...{| __typename: 'Partner' |},
        ...$Pick<Partner, { id: *, name?: *, code?: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, timeline: ({
        ...{| __typename: 'Timeline' |},
      ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
    }), ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
        ...{| __typename: 'Notification' |},
      ...$Pick<Notification, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
      ...{| __typename: 'ProductProvider' |},
    ...$Pick<ProductProvider, { id: *, name: * }>,
    ...{ exporter: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>,
      ...{ partner?: ?({
          ...{| __typename: 'Partner' |},
        ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
        ...{ tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>,
      ...{ partner?: ?({
          ...{| __typename: 'Partner' |},
        ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
        ...{ tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
      ...{ grossWeight?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), volume?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), size?: ?({
          ...{| __typename: 'Size' |},
        ...{ length: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), width: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), height: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }) }
      }) }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
      ...{ grossWeight?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), volume?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), size?: ?({
          ...{| __typename: 'Size' |},
        ...{ length: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), width: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), height: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }) }
      }) }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, product: ({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: *, name: *, serial: * }>,
      ...{ tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, files: Array<({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: *, path: * }>,
        ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type ItemInOrderFormFragment = ({
    ...{| __typename: 'OrderItem' |},
  ...$Pick<OrderItem, { id: *, updatedAt: *, archived: *, no: *, quantity: *, deliveryDate?: *, memo?: * }>,
  ...{ price: ({
      ...{| __typename: 'NewPrice' |},
    ...$Pick<NewPrice, { amount: *, currency: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
      ...{| __typename: 'CustomFields' |},
    ...{ fieldValues: Array<({
        ...{| __typename: 'FieldValue' |},
      ...{ value: ({
          ...{| __typename: 'StringValue' |},
        ...$Pick<StringValue, { string: * }>
      }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }), tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, productProvider: ({
      ...{| __typename: 'ProductProvider' |},
    ...$Pick<ProductProvider, { id: *, name: * }>,
    ...{ exporter: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>,
      ...{ partner?: ?({
          ...{| __typename: 'Partner' |},
        ...$Pick<Partner, { id: *, name?: *, code?: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>,
      ...{ partner?: ?({
          ...{| __typename: 'Partner' |},
        ...$Pick<Partner, { id: *, name?: *, code?: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
      ...{ grossWeight?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), volume?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), size?: ?({
          ...{| __typename: 'Size' |},
        ...{ length: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), width: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), height: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }) }
      }) }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
      ...{ grossWeight?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), volume?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), size?: ?({
          ...{| __typename: 'Size' |},
        ...{ length: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), width: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), height: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }) }
      }) }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, unitPrice?: ?({
        ...{| __typename: 'NewPrice' |},
      ...$Pick<NewPrice, { amount: *, currency: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, product: ({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: *, name: *, serial: * }>,
      ...{ tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, files: Array<({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: *, path: * }>,
        ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, batches: Array<({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { id: *, sort: *, shipmentSort: *, archived: *, autoCalculatePackageQuantity: *, autoCalculatePackageVolume: *, updatedAt: *, memo?: *, no: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: *, latestQuantityField: *, producedAt?: *, deliveredAt?: *, desiredAt?: *, expiredAt?: *, packageName?: *, packageCapacity?: *, packageQuantity?: * }>,
    ...{ updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), totalPrice?: ?({
        ...{| __typename: 'NewPrice' |},
      ...$Pick<NewPrice, { amount: *, currency: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
        ...{| __typename: 'CustomFields' |},
      ...{ fieldValues: Array<({
          ...{| __typename: 'FieldValue' |},
        ...{ value: ({
            ...{| __typename: 'StringValue' |},
          ...$Pick<StringValue, { string: * }>
        }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }), packageGrossWeight?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), packageVolume?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), packageSize?: ?({
        ...{| __typename: 'Size' |},
      ...{ length: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), width: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), height: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }) }
    }), tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, orderItem: ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
      ...{ price: ({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, order: ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
        ...{ totalPrice: ({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, timeline: ({
            ...{| __typename: 'Timeline' |},
          ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
        }), ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
            ...{| __typename: 'Notification' |},
          ...$Pick<Notification, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ exporter: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
          ...{ grossWeight?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), volume?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), size?: ?({
              ...{| __typename: 'Size' |},
            ...{ length: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), width: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), height: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }) }
          }) }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
          ...{ grossWeight?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), volume?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), size?: ?({
              ...{| __typename: 'Size' |},
            ...{ length: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), width: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), height: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }) }
          }) }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: *, serial: * }>,
          ...{ tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, files: Array<({
              ...{| __typename: 'File' |},
            ...$Pick<File, { id: *, path: * }>,
            ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment?: ?({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
      ...{ forwarders: Array<({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
          ...{ tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalVolumeOverride?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), timeline: ({
          ...{| __typename: 'Timeline' |},
        ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
      }), containerTypeCounts: Array<({
          ...{| __typename: 'ContainerTypeCount' |},
        ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
      })>, importer?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, voyages: Array<({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
        ...{ departurePort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), arrivalPort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), departure: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ customClearance: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
        ...{ warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>,
        ...{ orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>,
          ...{ productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>,
            ...{ exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
          ...{| __typename: 'Notification' |},
        ...$Pick<Notification, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: *, no: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: * }>,
      ...{ representativeBatch?: ?({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>,
        ...{ orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>,
          ...{ productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>,
            ...{ product: ({
                ...{| __typename: 'Product' |},
              ...$Pick<Product, { id: *, name: *, serial: * }>,
              ...{ files: Array<({
                  ...{| __typename: 'File' |},
                ...$Pick<File, { id: *, path: * }>,
                ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, files: Array<({
      ...{| __typename: 'File' |},
    ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
    ...{ tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, entity?: ?({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: *, name: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: *, name: * }>,
      ...{ product: ({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: *, poNo: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: * }>
    }) | ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | ({
        ...{| __typename: 'Partnership' |},
      ...$Pick<Partnership, { id: * }>
    }) | ({
        ...{| __typename: 'Role' |},
      ...$Pick<Role, { id: * }>
    }) | ({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Mask' |},
      ...$Pick<Mask, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'MaskEdit' |},
      ...$Pick<MaskEdit, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'FieldDefinition' |},
      ...$Pick<FieldDefinition, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type ItemInSelectorFragment = ({
    ...{| __typename: 'OrderItem' |},
  ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
  ...{ price: ({
      ...{| __typename: 'NewPrice' |},
    ...$Pick<NewPrice, { amount: *, currency: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, order: ({
      ...{| __typename: 'Order' |},
    ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
    ...{ totalPrice: ({
        ...{| __typename: 'NewPrice' |},
      ...$Pick<NewPrice, { amount: *, currency: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>,
      ...{ partner?: ?({
          ...{| __typename: 'Partner' |},
        ...$Pick<Partner, { id: *, name?: *, code?: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>,
      ...{ partner?: ?({
          ...{| __typename: 'Partner' |},
        ...$Pick<Partner, { id: *, name?: *, code?: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, timeline: ({
        ...{| __typename: 'Timeline' |},
      ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
    }), ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
        ...{| __typename: 'Notification' |},
      ...$Pick<Notification, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
      ...{| __typename: 'ProductProvider' |},
    ...$Pick<ProductProvider, { id: *, name: * }>,
    ...{ exporter: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>,
      ...{ partner?: ?({
          ...{| __typename: 'Partner' |},
        ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
        ...{ tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
      ...{ grossWeight?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), volume?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), size?: ?({
          ...{| __typename: 'Size' |},
        ...{ length: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), width: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), height: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }) }
      }) }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
      ...{ grossWeight?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), volume?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), size?: ?({
          ...{| __typename: 'Size' |},
        ...{ length: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), width: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), height: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }) }
      }) }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, product: ({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: *, name: *, serial: * }>,
      ...{ tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, files: Array<({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: *, path: * }>,
        ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type OrdersAndShipmentsQueryVariables = {
  orderIds: Array<$ElementType<Scalars, 'ID'>>,
  shipmentIds: Array<$ElementType<Scalars, 'ID'>>
};


export type OrdersAndShipmentsQuery = ({
    ...{| __typename: 'Query' |},
  ...{ ordersByIDs: Array<({
      ...{| __typename: 'Order' |},
    ...$Pick<Order, { id: *, archived: *, updatedAt: *, memo?: *, poNo: *, currency: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, shipmentCount: *, issuedAt?: *, deliveryDate?: *, piNo?: *, incoterm?: *, deliveryPlace?: *, notificationUnseenCount: * }>,
    ...{ updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, followers: Array<({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, email: *, firstName: *, lastName: * }>,
      ...{ roles: Array<({
          ...{| __typename: 'Role' |},
        ...$Pick<Role, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organizations: Array<({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalPrice: ({
        ...{| __typename: 'NewPrice' |},
      ...$Pick<NewPrice, { amount: *, currency: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
        ...{| __typename: 'CustomFields' |},
      ...{ fieldValues: Array<({
          ...{| __typename: 'FieldValue' |},
        ...{ value: ({
            ...{| __typename: 'StringValue' |},
          ...$Pick<StringValue, { string: * }>
        }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }), importer: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'BadRequest' |},
      ...{ violations: Array<({
          ...{| __typename: 'Violation' |},
        ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
        ...{ parameters: Array<({
            ...{| __typename: 'ViolationParameter' |},
          ...$Pick<ViolationParameter, { key: *, value: * }>
        })> }
      })> }
    }) | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, files: Array<({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
      ...{ tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, entity?: ?({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, poNo: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | ({
          ...{| __typename: 'Partnership' |},
        ...$Pick<Partnership, { id: * }>
      }) | ({
          ...{| __typename: 'Role' |},
        ...$Pick<Role, { id: * }>
      }) | ({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Mask' |},
        ...$Pick<Mask, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'MaskEdit' |},
        ...$Pick<MaskEdit, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, orderItems: Array<({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: *, updatedAt: *, archived: *, no: *, quantity: *, deliveryDate?: *, memo?: * }>,
      ...{ price: ({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
          ...{| __typename: 'CustomFields' |},
        ...{ fieldValues: Array<({
            ...{| __typename: 'FieldValue' |},
          ...{ value: ({
              ...{| __typename: 'StringValue' |},
            ...$Pick<StringValue, { string: * }>
          }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }), tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, productProvider: ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ exporter: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
          ...{ grossWeight?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), volume?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), size?: ?({
              ...{| __typename: 'Size' |},
            ...{ length: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), width: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), height: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }) }
          }) }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
          ...{ grossWeight?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), volume?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), size?: ?({
              ...{| __typename: 'Size' |},
            ...{ length: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), width: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), height: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }) }
          }) }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, unitPrice?: ?({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: *, serial: * }>,
          ...{ tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, files: Array<({
              ...{| __typename: 'File' |},
            ...$Pick<File, { id: *, path: * }>,
            ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: *, sort: *, shipmentSort: *, archived: *, autoCalculatePackageQuantity: *, autoCalculatePackageVolume: *, updatedAt: *, memo?: *, no: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: *, latestQuantityField: *, producedAt?: *, deliveredAt?: *, desiredAt?: *, expiredAt?: *, packageName?: *, packageCapacity?: *, packageQuantity?: * }>,
        ...{ updatedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), totalPrice?: ?({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
            ...{| __typename: 'CustomFields' |},
          ...{ fieldValues: Array<({
              ...{| __typename: 'FieldValue' |},
            ...{ value: ({
                ...{| __typename: 'StringValue' |},
              ...$Pick<StringValue, { string: * }>
            }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
                ...{| __typename: 'FieldDefinition' |},
              ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: *, types: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }), packageGrossWeight?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), packageVolume?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), packageSize?: ?({
            ...{| __typename: 'Size' |},
          ...{ length: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), width: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), height: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }) }
        }), tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
          ...{ price: ({
              ...{| __typename: 'NewPrice' |},
            ...$Pick<NewPrice, { amount: *, currency: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, order: ({
              ...{| __typename: 'Order' |},
            ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
            ...{ totalPrice: ({
                ...{| __typename: 'NewPrice' |},
              ...$Pick<NewPrice, { amount: *, currency: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>,
              ...{ partner?: ?({
                  ...{| __typename: 'Partner' |},
                ...$Pick<Partner, { id: *, name?: *, code?: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>,
              ...{ partner?: ?({
                  ...{| __typename: 'Partner' |},
                ...$Pick<Partner, { id: *, name?: *, code?: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | ({
                ...{| __typename: 'Forbidden' |},
              ...$Pick<Forbidden, { reason?: * }>,
              ...{ reference?: ?({
                  ...{| __typename: 'Reference' |},
                ...$Pick<Reference, { id: * }>
              }) }
            }) | {| __typename: 'NotFound' |}>, timeline: ({
                ...{| __typename: 'Timeline' |},
              ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
            }), ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
                ...{| __typename: 'Notification' |},
              ...$Pick<Notification, { id: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: *, name: * }>,
            ...{ exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>,
              ...{ partner?: ?({
                  ...{| __typename: 'Partner' |},
                ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
                ...{ tags: Array<({
                    ...{| __typename: 'Tag' |},
                  ...$Pick<Tag, { id: *, name: *, color: * }>,
                  ...{ ownedBy: ({
                      ...{| __typename: 'Organization' |},
                    ...$Pick<Organization, { id: *, name: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                      ...{| __typename: 'Connection' |},
                    ...$Pick<Connection, { id: *, displayName: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>,
              ...{ partner?: ?({
                  ...{| __typename: 'Partner' |},
                ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
                ...{ tags: Array<({
                    ...{| __typename: 'Tag' |},
                  ...$Pick<Tag, { id: *, name: *, color: * }>,
                  ...{ ownedBy: ({
                      ...{| __typename: 'Organization' |},
                    ...$Pick<Organization, { id: *, name: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                      ...{| __typename: 'Connection' |},
                    ...$Pick<Connection, { id: *, displayName: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
                ...{| __typename: 'ProductProviderPackage' |},
              ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
              ...{ grossWeight?: ?({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), volume?: ?({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), size?: ?({
                  ...{| __typename: 'Size' |},
                ...{ length: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }), width: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }), height: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }) }
              }) }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
                ...{| __typename: 'ProductProviderPackage' |},
              ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
              ...{ grossWeight?: ?({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), volume?: ?({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), size?: ?({
                  ...{| __typename: 'Size' |},
                ...{ length: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }), width: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }), height: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }) }
              }) }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, product: ({
                ...{| __typename: 'Product' |},
              ...$Pick<Product, { id: *, name: *, serial: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | ({
                  ...{| __typename: 'Forbidden' |},
                ...$Pick<Forbidden, { reason?: * }>,
                ...{ reference?: ?({
                    ...{| __typename: 'Reference' |},
                  ...$Pick<Reference, { id: * }>
                }) }
              }) | {| __typename: 'NotFound' |}>, files: Array<({
                  ...{| __typename: 'File' |},
                ...$Pick<File, { id: *, path: * }>,
                ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment?: ?({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
          ...{ forwarders: Array<({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolume: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), totalVolumeOverride?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), timeline: ({
              ...{| __typename: 'Timeline' |},
            ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
          }), containerTypeCounts: Array<({
              ...{| __typename: 'ContainerTypeCount' |},
            ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
          })>, importer?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, voyages: Array<({
              ...{| __typename: 'Voyage' |},
            ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
            ...{ departurePort?: ?({
                ...{| __typename: 'Port' |},
              ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
            }), arrivalPort?: ?({
                ...{| __typename: 'Port' |},
              ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
            }), departure: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
              ...{| __typename: 'ContainerGroup' |},
            ...$Pick<ContainerGroup, { id: * }>,
            ...{ customClearance: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
                ...{| __typename: 'Warehouse' |},
              ...$Pick<Warehouse, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
              ...{| __typename: 'Container' |},
            ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
            ...{ warehouse?: ?({
                ...{| __typename: 'Warehouse' |},
              ...$Pick<Warehouse, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: * }>,
            ...{ orderItem: ({
                ...{| __typename: 'OrderItem' |},
              ...$Pick<OrderItem, { id: * }>,
              ...{ productProvider: ({
                  ...{| __typename: 'ProductProvider' |},
                ...$Pick<ProductProvider, { id: * }>,
                ...{ exporter: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
              ...{| __typename: 'Notification' |},
            ...$Pick<Notification, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: *, no: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: * }>,
          ...{ representativeBatch?: ?({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: * }>,
            ...{ orderItem: ({
                ...{| __typename: 'OrderItem' |},
              ...$Pick<OrderItem, { id: * }>,
              ...{ productProvider: ({
                  ...{| __typename: 'ProductProvider' |},
                ...$Pick<ProductProvider, { id: * }>,
                ...{ product: ({
                    ...{| __typename: 'Product' |},
                  ...$Pick<Product, { id: *, name: *, serial: * }>,
                  ...{ files: Array<({
                      ...{| __typename: 'File' |},
                    ...$Pick<File, { id: *, path: * }>,
                    ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), batches: Array<({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
              ...{| __typename: 'Shipment' |},
            ...$Pick<Shipment, { id: *, no: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, files: Array<({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
        ...{ tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, entity?: ?({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: *, name: * }>,
          ...{ product: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: *, poNo: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | ({
            ...{| __typename: 'Partnership' |},
          ...$Pick<Partnership, { id: * }>
        }) | ({
            ...{| __typename: 'Role' |},
          ...$Pick<Role, { id: * }>
        }) | ({
            ...{| __typename: 'File' |},
          ...$Pick<File, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Mask' |},
          ...$Pick<Mask, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'MaskEdit' |},
          ...$Pick<MaskEdit, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, shipments: Array<({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
      ...{ totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalVolumeOverride?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), timeline: ({
          ...{| __typename: 'Timeline' |},
        ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
      }), containerTypeCounts: Array<({
          ...{| __typename: 'ContainerTypeCount' |},
        ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
      })>, importer?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, voyages: Array<({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
        ...{ departurePort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), arrivalPort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), departure: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ customClearance: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
        ...{ warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>,
        ...{ orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>,
          ...{ productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>,
            ...{ exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
          ...{| __typename: 'Notification' |},
        ...$Pick<Notification, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: *, archived: *, no: *, containerType?: *, containerOption?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: *, freeTimeStartDate?: *, freeTimeDuration?: * }>,
      ...{ representativeBatch?: ?({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>,
        ...{ orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>,
          ...{ productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>,
            ...{ product: ({
                ...{| __typename: 'Product' |},
              ...$Pick<Product, { id: *, name: *, serial: * }>,
              ...{ files: Array<({
                  ...{| __typename: 'File' |},
                ...$Pick<File, { id: *, path: * }>,
                ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>,
        ...{ importer?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
        ...{| __typename: 'Notification' |},
      ...$Pick<Notification, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, shipmentsByIDs: Array<({
      ...{| __typename: 'Shipment' |},
    ...$Pick<Shipment, { id: *, archived: *, updatedAt: *, memo?: *, no: *, blNo?: *, masterBlNo?: *, blDate?: *, booked?: *, bookingNo?: *, bookingDate?: *, invoiceNo?: *, contractNo?: *, autoTracking?: *, autoTrackingBy?: *, incoterm?: *, placeOfReceipt?: *, placeOfDelivery?: *, loadType?: *, carrier?: *, totalPackageQuantityOverride?: *, totalPackageQuantityOverriding: *, totalVolumeOverriding: *, totalWeightOverriding: *, notificationUnseenCount: * }>,
    ...{ updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, followers: Array<({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, email: *, firstName: *, lastName: * }>,
      ...{ roles: Array<({
          ...{| __typename: 'Role' |},
        ...$Pick<Role, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organizations: Array<({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, customFields: ({
        ...{| __typename: 'CustomFields' |},
      ...{ fieldValues: Array<({
          ...{| __typename: 'FieldValue' |},
        ...{ value: ({
            ...{| __typename: 'StringValue' |},
          ...$Pick<StringValue, { string: * }>
        }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }), forwarders: Array<({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, importer?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolumeOverride?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), totalWeightOverride?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, files: Array<({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
      ...{ tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, entity?: ?({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, poNo: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | ({
          ...{| __typename: 'Partnership' |},
        ...$Pick<Partnership, { id: * }>
      }) | ({
          ...{| __typename: 'Role' |},
        ...$Pick<Role, { id: * }>
      }) | ({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Mask' |},
        ...$Pick<Mask, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'MaskEdit' |},
        ...$Pick<MaskEdit, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, cargoReady: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
        ...{ updatedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, voyages: Array<({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: *, no?: *, vesselName?: *, vesselCode?: * }>,
      ...{ departurePort?: ?({
          ...{| __typename: 'Port' |},
        ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
      }), arrivalPort?: ?({
          ...{| __typename: 'Port' |},
        ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
      }), departure: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
          ...{ updatedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
          ...{ updatedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>,
      ...{ warehouse?: ?({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: *, name: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customClearance: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
          ...{ updatedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
          ...{ updatedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
          ...{ updatedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: *, archived: *, updatedAt: *, no: *, containerType?: *, containerOption?: *, memo?: *, vanningDate?: *, vanningDateApprovedAt?: *, vanningActualDate?: *, vanningActualDateApprovedAt?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: *, freeTimeStartDate?: *, freeTimeDuration?: *, autoCalculatedFreeTimeStartDate: *, yardName?: *, departureDate?: *, departureDateApprovedAt?: * }>,
      ...{ updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: *, name: *, archived: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, terminalArrivalPort?: ?({
          ...{| __typename: 'Port' |},
        ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
      }), terminalArrival: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, vanningDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, vanningActualDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, departureDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, customFields: ({
          ...{| __typename: 'CustomFields' |},
        ...{ fieldValues: Array<({
            ...{| __typename: 'FieldValue' |},
          ...{ value: ({
              ...{| __typename: 'StringValue' |},
            ...$Pick<StringValue, { string: * }>
          }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }), shipment: ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
        ...{ totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), totalVolumeOverride?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), timeline: ({
            ...{| __typename: 'Timeline' |},
          ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
        }), containerTypeCounts: Array<({
            ...{| __typename: 'ContainerTypeCount' |},
          ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
        })>, importer?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, voyages: Array<({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
          ...{ departurePort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), arrivalPort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), departure: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>,
          ...{ customClearance: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
          ...{ warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>,
          ...{ orderItem: ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>,
            ...{ productProvider: ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>,
              ...{ exporter: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
            ...{| __typename: 'Notification' |},
          ...$Pick<Notification, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: *, sort: *, shipmentSort: *, archived: *, autoCalculatePackageQuantity: *, autoCalculatePackageVolume: *, updatedAt: *, memo?: *, no: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: *, latestQuantityField: *, producedAt?: *, deliveredAt?: *, desiredAt?: *, expiredAt?: *, packageName?: *, packageCapacity?: *, packageQuantity?: * }>,
        ...{ updatedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), totalPrice?: ?({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
            ...{| __typename: 'CustomFields' |},
          ...{ fieldValues: Array<({
              ...{| __typename: 'FieldValue' |},
            ...{ value: ({
                ...{| __typename: 'StringValue' |},
              ...$Pick<StringValue, { string: * }>
            }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
                ...{| __typename: 'FieldDefinition' |},
              ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: *, types: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }), packageGrossWeight?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), packageVolume?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), packageSize?: ?({
            ...{| __typename: 'Size' |},
          ...{ length: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), width: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), height: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }) }
        }), tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
          ...{ price: ({
              ...{| __typename: 'NewPrice' |},
            ...$Pick<NewPrice, { amount: *, currency: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, order: ({
              ...{| __typename: 'Order' |},
            ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
            ...{ totalPrice: ({
                ...{| __typename: 'NewPrice' |},
              ...$Pick<NewPrice, { amount: *, currency: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>,
              ...{ partner?: ?({
                  ...{| __typename: 'Partner' |},
                ...$Pick<Partner, { id: *, name?: *, code?: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>,
              ...{ partner?: ?({
                  ...{| __typename: 'Partner' |},
                ...$Pick<Partner, { id: *, name?: *, code?: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | ({
                ...{| __typename: 'Forbidden' |},
              ...$Pick<Forbidden, { reason?: * }>,
              ...{ reference?: ?({
                  ...{| __typename: 'Reference' |},
                ...$Pick<Reference, { id: * }>
              }) }
            }) | {| __typename: 'NotFound' |}>, timeline: ({
                ...{| __typename: 'Timeline' |},
              ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
            }), ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
                ...{| __typename: 'Notification' |},
              ...$Pick<Notification, { id: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: *, name: * }>,
            ...{ exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>,
              ...{ partner?: ?({
                  ...{| __typename: 'Partner' |},
                ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
                ...{ tags: Array<({
                    ...{| __typename: 'Tag' |},
                  ...$Pick<Tag, { id: *, name: *, color: * }>,
                  ...{ ownedBy: ({
                      ...{| __typename: 'Organization' |},
                    ...$Pick<Organization, { id: *, name: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                      ...{| __typename: 'Connection' |},
                    ...$Pick<Connection, { id: *, displayName: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>,
              ...{ partner?: ?({
                  ...{| __typename: 'Partner' |},
                ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
                ...{ tags: Array<({
                    ...{| __typename: 'Tag' |},
                  ...$Pick<Tag, { id: *, name: *, color: * }>,
                  ...{ ownedBy: ({
                      ...{| __typename: 'Organization' |},
                    ...$Pick<Organization, { id: *, name: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                      ...{| __typename: 'Connection' |},
                    ...$Pick<Connection, { id: *, displayName: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
                ...{| __typename: 'ProductProviderPackage' |},
              ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
              ...{ grossWeight?: ?({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), volume?: ?({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), size?: ?({
                  ...{| __typename: 'Size' |},
                ...{ length: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }), width: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }), height: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }) }
              }) }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
                ...{| __typename: 'ProductProviderPackage' |},
              ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
              ...{ grossWeight?: ?({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), volume?: ?({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), size?: ?({
                  ...{| __typename: 'Size' |},
                ...{ length: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }), width: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }), height: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }) }
              }) }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, product: ({
                ...{| __typename: 'Product' |},
              ...$Pick<Product, { id: *, name: *, serial: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | ({
                  ...{| __typename: 'Forbidden' |},
                ...$Pick<Forbidden, { reason?: * }>,
                ...{ reference?: ?({
                    ...{| __typename: 'Reference' |},
                  ...$Pick<Reference, { id: * }>
                }) }
              }) | {| __typename: 'NotFound' |}>, files: Array<({
                  ...{| __typename: 'File' |},
                ...$Pick<File, { id: *, path: * }>,
                ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment?: ?({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
          ...{ forwarders: Array<({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolume: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), totalVolumeOverride?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), timeline: ({
              ...{| __typename: 'Timeline' |},
            ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
          }), containerTypeCounts: Array<({
              ...{| __typename: 'ContainerTypeCount' |},
            ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
          })>, importer?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, voyages: Array<({
              ...{| __typename: 'Voyage' |},
            ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
            ...{ departurePort?: ?({
                ...{| __typename: 'Port' |},
              ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
            }), arrivalPort?: ?({
                ...{| __typename: 'Port' |},
              ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
            }), departure: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
              ...{| __typename: 'ContainerGroup' |},
            ...$Pick<ContainerGroup, { id: * }>,
            ...{ customClearance: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
                ...{| __typename: 'Warehouse' |},
              ...$Pick<Warehouse, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
              ...{| __typename: 'Container' |},
            ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
            ...{ warehouse?: ?({
                ...{| __typename: 'Warehouse' |},
              ...$Pick<Warehouse, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: * }>,
            ...{ orderItem: ({
                ...{| __typename: 'OrderItem' |},
              ...$Pick<OrderItem, { id: * }>,
              ...{ productProvider: ({
                  ...{| __typename: 'ProductProvider' |},
                ...$Pick<ProductProvider, { id: * }>,
                ...{ exporter: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
              ...{| __typename: 'Notification' |},
            ...$Pick<Notification, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: *, no: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: * }>,
          ...{ representativeBatch?: ?({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: * }>,
            ...{ orderItem: ({
                ...{| __typename: 'OrderItem' |},
              ...$Pick<OrderItem, { id: * }>,
              ...{ productProvider: ({
                  ...{| __typename: 'ProductProvider' |},
                ...$Pick<ProductProvider, { id: * }>,
                ...{ product: ({
                    ...{| __typename: 'Product' |},
                  ...$Pick<Product, { id: *, name: *, serial: * }>,
                  ...{ files: Array<({
                      ...{| __typename: 'File' |},
                    ...$Pick<File, { id: *, path: * }>,
                    ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), batches: Array<({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
              ...{| __typename: 'Shipment' |},
            ...$Pick<Shipment, { id: *, no: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, representativeBatch?: ?({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: *, sort: *, shipmentSort: *, archived: *, autoCalculatePackageQuantity: *, autoCalculatePackageVolume: *, updatedAt: *, memo?: *, no: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: *, latestQuantityField: *, producedAt?: *, deliveredAt?: *, desiredAt?: *, expiredAt?: *, packageName?: *, packageCapacity?: *, packageQuantity?: * }>,
        ...{ updatedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), totalPrice?: ?({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
            ...{| __typename: 'CustomFields' |},
          ...{ fieldValues: Array<({
              ...{| __typename: 'FieldValue' |},
            ...{ value: ({
                ...{| __typename: 'StringValue' |},
              ...$Pick<StringValue, { string: * }>
            }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
                ...{| __typename: 'FieldDefinition' |},
              ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: *, types: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }), packageGrossWeight?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), packageVolume?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), packageSize?: ?({
            ...{| __typename: 'Size' |},
          ...{ length: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), width: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), height: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }) }
        }), tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
          ...{ price: ({
              ...{| __typename: 'NewPrice' |},
            ...$Pick<NewPrice, { amount: *, currency: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, order: ({
              ...{| __typename: 'Order' |},
            ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
            ...{ totalPrice: ({
                ...{| __typename: 'NewPrice' |},
              ...$Pick<NewPrice, { amount: *, currency: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>,
              ...{ partner?: ?({
                  ...{| __typename: 'Partner' |},
                ...$Pick<Partner, { id: *, name?: *, code?: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>,
              ...{ partner?: ?({
                  ...{| __typename: 'Partner' |},
                ...$Pick<Partner, { id: *, name?: *, code?: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | ({
                ...{| __typename: 'Forbidden' |},
              ...$Pick<Forbidden, { reason?: * }>,
              ...{ reference?: ?({
                  ...{| __typename: 'Reference' |},
                ...$Pick<Reference, { id: * }>
              }) }
            }) | {| __typename: 'NotFound' |}>, timeline: ({
                ...{| __typename: 'Timeline' |},
              ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
            }), ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
                ...{| __typename: 'Notification' |},
              ...$Pick<Notification, { id: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: *, name: * }>,
            ...{ exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>,
              ...{ partner?: ?({
                  ...{| __typename: 'Partner' |},
                ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
                ...{ tags: Array<({
                    ...{| __typename: 'Tag' |},
                  ...$Pick<Tag, { id: *, name: *, color: * }>,
                  ...{ ownedBy: ({
                      ...{| __typename: 'Organization' |},
                    ...$Pick<Organization, { id: *, name: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                      ...{| __typename: 'Connection' |},
                    ...$Pick<Connection, { id: *, displayName: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>,
              ...{ partner?: ?({
                  ...{| __typename: 'Partner' |},
                ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
                ...{ tags: Array<({
                    ...{| __typename: 'Tag' |},
                  ...$Pick<Tag, { id: *, name: *, color: * }>,
                  ...{ ownedBy: ({
                      ...{| __typename: 'Organization' |},
                    ...$Pick<Organization, { id: *, name: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                      ...{| __typename: 'Connection' |},
                    ...$Pick<Connection, { id: *, displayName: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
                ...{| __typename: 'ProductProviderPackage' |},
              ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
              ...{ grossWeight?: ?({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), volume?: ?({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), size?: ?({
                  ...{| __typename: 'Size' |},
                ...{ length: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }), width: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }), height: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }) }
              }) }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
                ...{| __typename: 'ProductProviderPackage' |},
              ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
              ...{ grossWeight?: ?({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), volume?: ?({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), size?: ?({
                  ...{| __typename: 'Size' |},
                ...{ length: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }), width: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }), height: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }) }
              }) }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, product: ({
                ...{| __typename: 'Product' |},
              ...$Pick<Product, { id: *, name: *, serial: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | ({
                  ...{| __typename: 'Forbidden' |},
                ...$Pick<Forbidden, { reason?: * }>,
                ...{ reference?: ?({
                    ...{| __typename: 'Reference' |},
                  ...$Pick<Reference, { id: * }>
                }) }
              }) | {| __typename: 'NotFound' |}>, files: Array<({
                  ...{| __typename: 'File' |},
                ...$Pick<File, { id: *, path: * }>,
                ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment?: ?({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
          ...{ forwarders: Array<({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolume: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), totalVolumeOverride?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), timeline: ({
              ...{| __typename: 'Timeline' |},
            ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
          }), containerTypeCounts: Array<({
              ...{| __typename: 'ContainerTypeCount' |},
            ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
          })>, importer?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, voyages: Array<({
              ...{| __typename: 'Voyage' |},
            ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
            ...{ departurePort?: ?({
                ...{| __typename: 'Port' |},
              ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
            }), arrivalPort?: ?({
                ...{| __typename: 'Port' |},
              ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
            }), departure: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
              ...{| __typename: 'ContainerGroup' |},
            ...$Pick<ContainerGroup, { id: * }>,
            ...{ customClearance: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
                ...{| __typename: 'Warehouse' |},
              ...$Pick<Warehouse, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
              ...{| __typename: 'Container' |},
            ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
            ...{ warehouse?: ?({
                ...{| __typename: 'Warehouse' |},
              ...$Pick<Warehouse, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: * }>,
            ...{ orderItem: ({
                ...{| __typename: 'OrderItem' |},
              ...$Pick<OrderItem, { id: * }>,
              ...{ productProvider: ({
                  ...{| __typename: 'ProductProvider' |},
                ...$Pick<ProductProvider, { id: * }>,
                ...{ exporter: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
              ...{| __typename: 'Notification' |},
            ...$Pick<Notification, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: *, no: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: * }>,
          ...{ representativeBatch?: ?({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: * }>,
            ...{ orderItem: ({
                ...{| __typename: 'OrderItem' |},
              ...$Pick<OrderItem, { id: * }>,
              ...{ productProvider: ({
                  ...{| __typename: 'ProductProvider' |},
                ...$Pick<ProductProvider, { id: * }>,
                ...{ product: ({
                    ...{| __typename: 'Product' |},
                  ...$Pick<Product, { id: *, name: *, serial: * }>,
                  ...{ files: Array<({
                      ...{| __typename: 'File' |},
                    ...$Pick<File, { id: *, path: * }>,
                    ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), batches: Array<({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
              ...{| __typename: 'Shipment' |},
            ...$Pick<Shipment, { id: *, no: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolume: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), batches: Array<({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: *, sort: *, shipmentSort: *, archived: *, autoCalculatePackageQuantity: *, autoCalculatePackageVolume: *, updatedAt: *, memo?: *, no: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: *, latestQuantityField: *, producedAt?: *, deliveredAt?: *, desiredAt?: *, expiredAt?: *, packageName?: *, packageCapacity?: *, packageQuantity?: * }>,
      ...{ updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalPrice?: ?({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
          ...{| __typename: 'CustomFields' |},
        ...{ fieldValues: Array<({
            ...{| __typename: 'FieldValue' |},
          ...{ value: ({
              ...{| __typename: 'StringValue' |},
            ...$Pick<StringValue, { string: * }>
          }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }), packageGrossWeight?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), packageVolume?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), packageSize?: ?({
          ...{| __typename: 'Size' |},
        ...{ length: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), width: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), height: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }) }
      }), tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, orderItem: ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
        ...{ price: ({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, order: ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
          ...{ totalPrice: ({
              ...{| __typename: 'NewPrice' |},
            ...$Pick<NewPrice, { amount: *, currency: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, timeline: ({
              ...{| __typename: 'Timeline' |},
            ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
          }), ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
              ...{| __typename: 'Notification' |},
            ...$Pick<Notification, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: *, name: * }>,
          ...{ exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
            ...{ grossWeight?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), volume?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), size?: ?({
                ...{| __typename: 'Size' |},
              ...{ length: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), width: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), height: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }) }
            }) }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
            ...{ grossWeight?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), volume?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), size?: ?({
                ...{| __typename: 'Size' |},
              ...{ length: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), width: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), height: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }) }
            }) }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, product: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: *, serial: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | ({
                ...{| __typename: 'Forbidden' |},
              ...$Pick<Forbidden, { reason?: * }>,
              ...{ reference?: ?({
                  ...{| __typename: 'Reference' |},
                ...$Pick<Reference, { id: * }>
              }) }
            }) | {| __typename: 'NotFound' |}>, files: Array<({
                ...{| __typename: 'File' |},
              ...$Pick<File, { id: *, path: * }>,
              ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment?: ?({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
        ...{ forwarders: Array<({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), totalVolumeOverride?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), timeline: ({
            ...{| __typename: 'Timeline' |},
          ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
        }), containerTypeCounts: Array<({
            ...{| __typename: 'ContainerTypeCount' |},
          ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
        })>, importer?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, voyages: Array<({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
          ...{ departurePort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), arrivalPort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), departure: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>,
          ...{ customClearance: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
          ...{ warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>,
          ...{ orderItem: ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>,
            ...{ productProvider: ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>,
              ...{ exporter: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
            ...{| __typename: 'Notification' |},
          ...$Pick<Notification, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: * }>,
        ...{ representativeBatch?: ?({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>,
          ...{ orderItem: ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>,
            ...{ productProvider: ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>,
              ...{ product: ({
                  ...{| __typename: 'Product' |},
                ...$Pick<Product, { id: *, name: *, serial: * }>,
                ...{ files: Array<({
                    ...{| __typename: 'File' |},
                  ...$Pick<File, { id: *, path: * }>,
                  ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), batches: Array<({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, no: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
        ...{| __typename: 'Notification' |},
      ...$Pick<Notification, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}> }
});

export type FieldDefinitionsUpdateMutationVariables = {
  input: FieldDefinitionsInput
};


export type FieldDefinitionsUpdateMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ fieldDefinitionsUpdate: ({
      ...{| __typename: 'FieldDefinitions' |},
    ...{ fieldDefinitions: Array<({
        ...{| __typename: 'FieldDefinition' |},
      ...$Pick<FieldDefinition, { id: *, sort: * }>
    })> }
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type MaskCreateMutationVariables = {
  input: MaskCreateInput
};


export type MaskCreateMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ maskCreate: ({
      ...{| __typename: 'Mask' |},
    ...$Pick<Mask, { id: * }>
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type MaskUpdateMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  input: MaskUpdateInput
};


export type MaskUpdateMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ maskUpdate: ({
      ...{| __typename: 'Mask' |},
    ...$Pick<Mask, { id: * }>
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type CustomFieldsQueryVariables = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy: CustomFieldFilterInput
};


export type CustomFieldsQuery = ({
    ...{| __typename: 'Query' |},
  ...{ customFields: ({
      ...{| __typename: 'FieldDefinitionPayloadPaginatedSearch' |},
    ...$Pick<FieldDefinitionPayloadPaginatedSearch, { page: *, totalPage: * }>,
    ...{ nodes: Array<({
        ...{| __typename: 'FieldDefinition' |},
      ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}> }
  }) }
});

export type FieldDefinitionQueryVariables = {
  id: $ElementType<Scalars, 'ID'>
};


export type FieldDefinitionQuery = ({
    ...{| __typename: 'Query' |},
  ...{ fieldDefinition: ({
      ...{| __typename: 'FieldDefinition' |},
    ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type FieldDefinitionsQueryVariables = {
  entityType: CustomizableEntityType
};


export type FieldDefinitionsQuery = ({
    ...{| __typename: 'Query' |},
  ...{ fieldDefinitions: Array<({
      ...{| __typename: 'FieldDefinition' |},
    ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type MaskQueryVariables = {
  id: $ElementType<Scalars, 'ID'>
};


export type MaskQuery = ({
    ...{| __typename: 'Query' |},
  ...{ mask: ({
      ...{| __typename: 'Mask' |},
    ...$Pick<Mask, { id: *, name: *, memo?: *, entityType: * }>,
    ...{ fieldDefinitions: Array<({
        ...{| __typename: 'FieldDefinition' |},
      ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type MasksQueryVariables = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?MaskFilterInput,
  sortBy?: ?MaskSortInput
};


export type MasksQuery = ({
    ...{| __typename: 'Query' |},
  ...{ masks: ({
      ...{| __typename: 'MaskPayloadPaginatedSearch' |},
    ...$Pick<MaskPayloadPaginatedSearch, { page: *, totalPage: * }>,
    ...{ nodes: Array<({
        ...{| __typename: 'Mask' |},
      ...$Pick<Mask, { id: *, name: *, memo?: *, entityType: * }>,
      ...{ fieldDefinitions: Array<({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) }
});

export type NotificationFragment = ({
    ...{| __typename: 'Notification' |},
  ...$Pick<Notification, { id: *, type: *, createdAt: *, body: *, seen: *, archived: * }>,
  ...{ reminder?: ?({
      ...{| __typename: 'Reminder' |},
    ...$Pick<Reminder, { id: * }>,
    ...{ section: ({
        ...{| __typename: 'ReminderSection' |},
      ...$Pick<ReminderSection, { view: * }>,
      ...{ formAction: ({
          ...{| __typename: 'ReminderSectionFormActionType' |},
        ...$Pick<ReminderSectionFormActionType, { fileTypes: * }>
      }), tableAction: ({
          ...{| __typename: 'ReminderSectionTableActionType' |},
        ...{ maskEdit?: ?({
            ...{| __typename: 'MaskEdit' |},
          ...$Pick<MaskEdit, { id: *, name: *, type: *, memo?: *, filterSort?: *, collapses: *, updatedAt: * }>,
          ...{ columns: Array<({
              ...{| __typename: 'MaskEditColumn' |},
            ...$Pick<MaskEditColumn, { key: *, hidden: *, width?: *, pinned?: *, highlight?: *, group?: * }>
          })>, updatedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'BadRequest' |},
          ...{ violations: Array<({
              ...{| __typename: 'Violation' |},
            ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
            ...{ parameters: Array<({
                ...{| __typename: 'ViolationParameter' |},
              ...$Pick<ViolationParameter, { key: *, value: * }>
            })> }
          })> }
        }) | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |} }
      }) }
    }) }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, relatedReferences: Array<({
      ...{| __typename: 'Reference' |},
    ...$Pick<Reference, { id: *, type: *, service: * }>
  })>, sender: ({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, receiver: ({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: ({
      ...{| __typename: 'Product' |},
    ...$Pick<Product, { id: * }>
  }) | ({
      ...{| __typename: 'ProductProvider' |},
    ...$Pick<ProductProvider, { id: * }>,
    ...{ product: ({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | ({
      ...{| __typename: 'ProductProviderPackage' |},
    ...$Pick<ProductProviderPackage, { id: * }>
  }) | ({
      ...{| __typename: 'Order' |},
    ...$Pick<Order, { id: * }>
  }) | ({
      ...{| __typename: 'OrderItem' |},
    ...$Pick<OrderItem, { id: * }>
  }) | ({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { id: * }>
  }) | ({
      ...{| __typename: 'Shipment' |},
    ...$Pick<Shipment, { id: * }>
  }) | ({
      ...{| __typename: 'Voyage' |},
    ...$Pick<Voyage, { id: * }>
  }) | ({
      ...{| __typename: 'Container' |},
    ...$Pick<Container, { id: * }>
  }) | ({
      ...{| __typename: 'ContainerGroup' |},
    ...$Pick<ContainerGroup, { id: * }>
  }) | ({
      ...{| __typename: 'TimelineDate' |},
    ...$Pick<TimelineDate, { id: * }>
  }) | ({
      ...{| __typename: 'TimelineDateRevision' |},
    ...$Pick<TimelineDateRevision, { id: * }>
  }) | ({
      ...{| __typename: 'Warehouse' |},
    ...$Pick<Warehouse, { id: * }>
  }) | ({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: * }>
  }) | ({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: * }>
  }) | ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | ({
      ...{| __typename: 'Partnership' |},
    ...$Pick<Partnership, { id: * }>
  }) | ({
      ...{| __typename: 'Role' |},
    ...$Pick<Role, { id: * }>
  }) | ({
      ...{| __typename: 'File' |},
    ...$Pick<File, { id: * }>
  }) | ({
      ...{| __typename: 'Mask' |},
    ...$Pick<Mask, { id: * }>
  }) | ({
      ...{| __typename: 'MaskEdit' |},
    ...$Pick<MaskEdit, { id: * }>
  }) | ({
      ...{| __typename: 'FieldDefinition' |},
    ...$Pick<FieldDefinition, { id: * }>
  }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type NotificationActiveMutationVariables = {
  id: $ElementType<Scalars, 'ID'>
};


export type NotificationActiveMutation = ({
    ...{| __typename: 'Mutation' |},
  ...$Pick<Mutation, { notificationActive: * }>
});

export type NotificationArchiveMutationVariables = {
  id: $ElementType<Scalars, 'ID'>
};


export type NotificationArchiveMutation = ({
    ...{| __typename: 'Mutation' |},
  ...$Pick<Mutation, { notificationArchive: * }>
});

export type NotificationArchiveAllMutationVariables = {};


export type NotificationArchiveAllMutation = ({
    ...{| __typename: 'Mutation' |},
  ...$Pick<Mutation, { notificationArchiveAll: * }>
});

export type NotificationPreferencesUpdateMutationVariables = {
  input: NotificationPreferencesInput
};


export type NotificationPreferencesUpdateMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ notificationPreferencesUpdate: {| __typename: 'NotificationPreferences' |} | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |} }
});

export type NotificationSeeAllMutationVariables = {};


export type NotificationSeeAllMutation = ({
    ...{| __typename: 'Mutation' |},
  ...$Pick<Mutation, { notificationSeeAll: * }>
});

export type NotificationSeeByEntitiesMutationVariables = {
  entities: Array<EntityInput>
};


export type NotificationSeeByEntitiesMutation = ({
    ...{| __typename: 'Mutation' |},
  ...$Pick<Mutation, { notificationSeeByEntities: * }>
});

export type CountNotificationQueryVariables = {};


export type CountNotificationQuery = ({
    ...{| __typename: 'Query' |},
  ...{ viewer: ({
      ...{| __typename: 'Viewer' |},
    ...$Pick<Viewer, { notificationUnseenCount: * }>
  }) }
});

export type IncomingNotificationListQueryVariables = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?NotificationFilterInput
};


export type IncomingNotificationListQuery = ({
    ...{| __typename: 'Query' |},
  ...{ notifications: ({
      ...{| __typename: 'NotificationPayloadPaginatedSearch' |},
    ...$Pick<NotificationPayloadPaginatedSearch, { page: *, totalPage: *, count: *, totalCount: * }>,
    ...{ nodes: Array<({
        ...{| __typename: 'Notification' |},
      ...$Pick<Notification, { id: *, type: *, createdAt: *, body: *, seen: *, archived: * }>,
      ...{ reminder?: ?({
          ...{| __typename: 'Reminder' |},
        ...$Pick<Reminder, { id: * }>,
        ...{ section: ({
            ...{| __typename: 'ReminderSection' |},
          ...$Pick<ReminderSection, { view: * }>,
          ...{ formAction: ({
              ...{| __typename: 'ReminderSectionFormActionType' |},
            ...$Pick<ReminderSectionFormActionType, { fileTypes: * }>
          }), tableAction: ({
              ...{| __typename: 'ReminderSectionTableActionType' |},
            ...{ maskEdit?: ?({
                ...{| __typename: 'MaskEdit' |},
              ...$Pick<MaskEdit, { id: *, name: *, type: *, memo?: *, filterSort?: *, collapses: *, updatedAt: * }>,
              ...{ columns: Array<({
                  ...{| __typename: 'MaskEditColumn' |},
                ...$Pick<MaskEditColumn, { key: *, hidden: *, width?: *, pinned?: *, highlight?: *, group?: * }>
              })>, updatedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | ({
                ...{| __typename: 'BadRequest' |},
              ...{ violations: Array<({
                  ...{| __typename: 'Violation' |},
                ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
                ...{ parameters: Array<({
                    ...{| __typename: 'ViolationParameter' |},
                  ...$Pick<ViolationParameter, { key: *, value: * }>
                })> }
              })> }
            }) | ({
                ...{| __typename: 'Forbidden' |},
              ...$Pick<Forbidden, { reason?: * }>,
              ...{ reference?: ?({
                  ...{| __typename: 'Reference' |},
                ...$Pick<Reference, { id: * }>
              }) }
            }) | {| __typename: 'NotFound' |} }
          }) }
        }) }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, relatedReferences: Array<({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: *, type: *, service: * }>
      })>, sender: ({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, receiver: ({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: ({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: * }>
      }) | ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: * }>,
        ...{ product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: * }>
      }) | ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: * }>
      }) | ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: * }>
      }) | ({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>
      }) | ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: * }>
      }) | ({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: * }>
      }) | ({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: * }>
      }) | ({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>
      }) | ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: * }>
      }) | ({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: * }>
      }) | ({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: * }>
      }) | ({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: * }>
      }) | ({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | ({
          ...{| __typename: 'Partnership' |},
        ...$Pick<Partnership, { id: * }>
      }) | ({
          ...{| __typename: 'Role' |},
        ...$Pick<Role, { id: * }>
      }) | ({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: * }>
      }) | ({
          ...{| __typename: 'Mask' |},
        ...$Pick<Mask, { id: * }>
      }) | ({
          ...{| __typename: 'MaskEdit' |},
        ...$Pick<MaskEdit, { id: * }>
      }) | ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>
      }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) }
});

export type NotificationByIdQueryVariables = {
  id: $ElementType<Scalars, 'ID'>
};


export type NotificationByIdQuery = ({
    ...{| __typename: 'Query' |},
  ...{ notification: ({
      ...{| __typename: 'Notification' |},
    ...$Pick<Notification, { id: *, type: *, createdAt: *, body: *, seen: *, archived: * }>,
    ...{ reminder?: ?({
        ...{| __typename: 'Reminder' |},
      ...$Pick<Reminder, { id: * }>,
      ...{ section: ({
          ...{| __typename: 'ReminderSection' |},
        ...$Pick<ReminderSection, { view: * }>,
        ...{ formAction: ({
            ...{| __typename: 'ReminderSectionFormActionType' |},
          ...$Pick<ReminderSectionFormActionType, { fileTypes: * }>
        }), tableAction: ({
            ...{| __typename: 'ReminderSectionTableActionType' |},
          ...{ maskEdit?: ?({
              ...{| __typename: 'MaskEdit' |},
            ...$Pick<MaskEdit, { id: *, name: *, type: *, memo?: *, filterSort?: *, collapses: *, updatedAt: * }>,
            ...{ columns: Array<({
                ...{| __typename: 'MaskEditColumn' |},
              ...$Pick<MaskEditColumn, { key: *, hidden: *, width?: *, pinned?: *, highlight?: *, group?: * }>
            })>, updatedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'BadRequest' |},
            ...{ violations: Array<({
                ...{| __typename: 'Violation' |},
              ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
              ...{ parameters: Array<({
                  ...{| __typename: 'ViolationParameter' |},
                ...$Pick<ViolationParameter, { key: *, value: * }>
              })> }
            })> }
          }) | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |} }
        }) }
      }) }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, relatedReferences: Array<({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: *, type: *, service: * }>
    })>, sender: ({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, receiver: ({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: ({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: * }>
    }) | ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: * }>,
      ...{ product: ({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: * }>
    }) | ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: * }>
    }) | ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: * }>
    }) | ({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: * }>
    }) | ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: * }>
    }) | ({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: * }>
    }) | ({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: * }>
    }) | ({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>
    }) | ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: * }>
    }) | ({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: * }>
    }) | ({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: * }>
    }) | ({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: * }>
    }) | ({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: * }>
    }) | ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | ({
        ...{| __typename: 'Partnership' |},
      ...$Pick<Partnership, { id: * }>
    }) | ({
        ...{| __typename: 'Role' |},
      ...$Pick<Role, { id: * }>
    }) | ({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: * }>
    }) | ({
        ...{| __typename: 'Mask' |},
      ...$Pick<Mask, { id: * }>
    }) | ({
        ...{| __typename: 'MaskEdit' |},
      ...$Pick<MaskEdit, { id: * }>
    }) | ({
        ...{| __typename: 'FieldDefinition' |},
      ...$Pick<FieldDefinition, { id: * }>
    }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type NotificationPreferencesQueryVariables = {};


export type NotificationPreferencesQuery = ({
    ...{| __typename: 'Query' |},
  ...{ viewer: ({
      ...{| __typename: 'Viewer' |},
    ...{ notificationPreferences: ({
        ...{| __typename: 'NotificationPreferences' |},
      ...$Pick<NotificationPreferences, { allowedEmail: * }>,
      ...{ emailInterval?: ?({
          ...{| __typename: 'Interval' |},
        ...$Pick<Interval, { hours: *, minutes: * }>
      }), notifications: Array<({
          ...{| __typename: 'NotificationPreference' |},
        ...$Pick<NotificationPreference, { type: *, enabled: * }>
      })> }
    }) }
  }) }
});

export type OutgoingNotificationListQueryVariables = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?NotificationFilterInput
};


export type OutgoingNotificationListQuery = ({
    ...{| __typename: 'Query' |},
  ...{ notifications: ({
      ...{| __typename: 'NotificationPayloadPaginatedSearch' |},
    ...$Pick<NotificationPayloadPaginatedSearch, { page: *, totalPage: *, count: *, totalCount: * }>,
    ...{ nodes: Array<({
        ...{| __typename: 'Notification' |},
      ...$Pick<Notification, { id: *, type: *, createdAt: *, body: *, seen: *, archived: * }>,
      ...{ reminder?: ?({
          ...{| __typename: 'Reminder' |},
        ...$Pick<Reminder, { id: * }>,
        ...{ section: ({
            ...{| __typename: 'ReminderSection' |},
          ...$Pick<ReminderSection, { view: * }>,
          ...{ formAction: ({
              ...{| __typename: 'ReminderSectionFormActionType' |},
            ...$Pick<ReminderSectionFormActionType, { fileTypes: * }>
          }), tableAction: ({
              ...{| __typename: 'ReminderSectionTableActionType' |},
            ...{ maskEdit?: ?({
                ...{| __typename: 'MaskEdit' |},
              ...$Pick<MaskEdit, { id: *, name: *, type: *, memo?: *, filterSort?: *, collapses: *, updatedAt: * }>,
              ...{ columns: Array<({
                  ...{| __typename: 'MaskEditColumn' |},
                ...$Pick<MaskEditColumn, { key: *, hidden: *, width?: *, pinned?: *, highlight?: *, group?: * }>
              })>, updatedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | ({
                ...{| __typename: 'BadRequest' |},
              ...{ violations: Array<({
                  ...{| __typename: 'Violation' |},
                ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
                ...{ parameters: Array<({
                    ...{| __typename: 'ViolationParameter' |},
                  ...$Pick<ViolationParameter, { key: *, value: * }>
                })> }
              })> }
            }) | ({
                ...{| __typename: 'Forbidden' |},
              ...$Pick<Forbidden, { reason?: * }>,
              ...{ reference?: ?({
                  ...{| __typename: 'Reference' |},
                ...$Pick<Reference, { id: * }>
              }) }
            }) | {| __typename: 'NotFound' |} }
          }) }
        }) }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, relatedReferences: Array<({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: *, type: *, service: * }>
      })>, sender: ({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, receiver: ({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: ({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: * }>
      }) | ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: * }>,
        ...{ product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: * }>
      }) | ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: * }>
      }) | ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: * }>
      }) | ({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>
      }) | ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: * }>
      }) | ({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: * }>
      }) | ({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: * }>
      }) | ({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>
      }) | ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: * }>
      }) | ({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: * }>
      }) | ({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: * }>
      }) | ({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: * }>
      }) | ({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | ({
          ...{| __typename: 'Partnership' |},
        ...$Pick<Partnership, { id: * }>
      }) | ({
          ...{| __typename: 'Role' |},
        ...$Pick<Role, { id: * }>
      }) | ({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: * }>
      }) | ({
          ...{| __typename: 'Mask' |},
        ...$Pick<Mask, { id: * }>
      }) | ({
          ...{| __typename: 'MaskEdit' |},
        ...$Pick<MaskEdit, { id: * }>
      }) | ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>
      }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) }
});

export type NotificationNewSubscriptionVariables = {};


export type NotificationNewSubscription = ({
    ...{| __typename: 'Subscription' |},
  ...{ notificationNew: ({
      ...{| __typename: 'Notification' |},
    ...$Pick<Notification, { id: *, type: *, createdAt: *, body: *, seen: *, archived: * }>,
    ...{ reminder?: ?({
        ...{| __typename: 'Reminder' |},
      ...$Pick<Reminder, { id: * }>,
      ...{ section: ({
          ...{| __typename: 'ReminderSection' |},
        ...$Pick<ReminderSection, { view: * }>,
        ...{ formAction: ({
            ...{| __typename: 'ReminderSectionFormActionType' |},
          ...$Pick<ReminderSectionFormActionType, { fileTypes: * }>
        }), tableAction: ({
            ...{| __typename: 'ReminderSectionTableActionType' |},
          ...{ maskEdit?: ?({
              ...{| __typename: 'MaskEdit' |},
            ...$Pick<MaskEdit, { id: *, name: *, type: *, memo?: *, filterSort?: *, collapses: *, updatedAt: * }>,
            ...{ columns: Array<({
                ...{| __typename: 'MaskEditColumn' |},
              ...$Pick<MaskEditColumn, { key: *, hidden: *, width?: *, pinned?: *, highlight?: *, group?: * }>
            })>, updatedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'BadRequest' |},
            ...{ violations: Array<({
                ...{| __typename: 'Violation' |},
              ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
              ...{ parameters: Array<({
                  ...{| __typename: 'ViolationParameter' |},
                ...$Pick<ViolationParameter, { key: *, value: * }>
              })> }
            })> }
          }) | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |} }
        }) }
      }) }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, relatedReferences: Array<({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: *, type: *, service: * }>
    })>, sender: ({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, receiver: ({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: ({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: * }>
    }) | ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: * }>,
      ...{ product: ({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: * }>
    }) | ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: * }>
    }) | ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: * }>
    }) | ({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: * }>
    }) | ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: * }>
    }) | ({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: * }>
    }) | ({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: * }>
    }) | ({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>
    }) | ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: * }>
    }) | ({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: * }>
    }) | ({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: * }>
    }) | ({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: * }>
    }) | ({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: * }>
    }) | ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | ({
        ...{| __typename: 'Partnership' |},
      ...$Pick<Partnership, { id: * }>
    }) | ({
        ...{| __typename: 'Role' |},
      ...$Pick<Role, { id: * }>
    }) | ({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: * }>
    }) | ({
        ...{| __typename: 'Mask' |},
      ...$Pick<Mask, { id: * }>
    }) | ({
        ...{| __typename: 'MaskEdit' |},
      ...$Pick<MaskEdit, { id: * }>
    }) | ({
        ...{| __typename: 'FieldDefinition' |},
      ...$Pick<FieldDefinition, { id: * }>
    }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) }
});

export type OrderCardFragment = ({
    ...{| __typename: 'Order' |},
  ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
  ...{ totalPrice: ({
      ...{| __typename: 'NewPrice' |},
    ...$Pick<NewPrice, { amount: *, currency: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>,
    ...{ partner?: ?({
        ...{| __typename: 'Partner' |},
      ...$Pick<Partner, { id: *, name?: *, code?: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>,
    ...{ partner?: ?({
        ...{| __typename: 'Partner' |},
      ...$Pick<Partner, { id: *, name?: *, code?: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, timeline: ({
      ...{| __typename: 'Timeline' |},
    ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
  }), ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
      ...{| __typename: 'Notification' |},
    ...$Pick<Notification, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type OrderCardFullFragment = ({
    ...{| __typename: 'Order' |},
  ...$MakeOptional<$Pick<Order, { id: *, archived: *, currency: *, poNo: *, incoterm?: *, batchCount: *, containerCount: *, shipmentCount: *, filesUnreadCount: *, notificationUnseenCount: * }>, { batchCount: * } | { containerCount: * } | { shipmentCount: * }>,
  ...{ orderItems: Array<({
      ...{| __typename: 'OrderItem' |},
    ...$Pick<OrderItem, { id: *, updatedAt: *, createdAt: *, archived: *, no: *, deliveryDate?: *, quantity: * }>,
    ...{ batches: Array<({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: *, updatedAt: *, createdAt: *, deliveredAt?: *, expiredAt?: *, desiredAt?: *, archived: *, no: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: * }>,
      ...{ totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, shipment?: ?({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, productProvider: ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: *, name: * }>,
      ...{ supplier?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, product: ({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: *, serial: * }>,
        ...{ files: Array<({
            ...{| __typename: 'File' |},
          ...$Pick<File, { id: * }>,
          ...{ pathSmall: $ElementType<File, 'path'> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, price: ({
        ...{| __typename: 'NewPrice' |},
      ...$Pick<NewPrice, { amount: *, currency: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
      ...{| __typename: 'Container' |},
    ...$Pick<Container, { id: *, no: *, archived: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: *, containerType?: * }>,
    ...{ tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, terminalArrivalPort?: ?({
        ...{| __typename: 'Port' |},
      ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
    }), terminalArrival: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, date: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, shipments: Array<({
      ...{| __typename: 'Shipment' |},
    ...$Pick<Shipment, { id: *, orderCount: *, totalVolumeOverriding: *, filesUnreadCount: *, incoterm?: *, archived: *, no: *, blNo?: *, earliestWarehouseAgreedArrival?: *, latestWarehouseAgreedArrival?: *, earliestWarehouseActualArrival?: *, latestWarehouseActualArrival?: *, notificationUnseenCount: * }>,
    ...{ totalVolumeOverride?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), totalVolume: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), timeline: ({
        ...{| __typename: 'Timeline' |},
      ...$Pick<Timeline, { unreadMessageCount: * }>
    }), messageGroups: Array<({
        ...{| __typename: 'MessageGroup' |},
      ...$Pick<MessageGroup, { unreadMessageCount: *, filesUnreadCount: * }>
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, exporter?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, cargoReady: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, date: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, voyages: Array<({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: * }>,
      ...{ departurePort?: ?({
          ...{| __typename: 'Port' |},
        ...$Pick<Port, { seaportName?: *, seaport?: *, airportName?: *, airport?: *, roadName?: *, road?: *, railName?: *, rail?: *, dryportName?: *, dryport?: * }>
      }), arrivalPort?: ?({
          ...{| __typename: 'Port' |},
        ...$Pick<Port, { seaportName?: *, seaport?: *, airportName?: *, airport?: *, roadName?: *, road?: *, railName?: *, rail?: *, dryportName?: *, dryport?: * }>
      }), departure: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>,
      ...{ warehouse?: ?({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customClearance: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDateApprovedAt?: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
        ...{| __typename: 'Notification' |},
      ...$Pick<Notification, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, timeline: ({
      ...{| __typename: 'Timeline' |},
    ...$Pick<Timeline, { unreadMessageCount: * }>
  }), tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, exporter: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
      ...{| __typename: 'Notification' |},
    ...$Pick<Notification, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type OrderCardWithOwnedFragment = ({
    ...{| __typename: 'Order' |},
  ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
  ...{ totalPrice: ({
      ...{| __typename: 'NewPrice' |},
    ...$Pick<NewPrice, { amount: *, currency: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>,
    ...{ partner?: ?({
        ...{| __typename: 'Partner' |},
      ...$Pick<Partner, { id: *, name?: *, code?: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>,
    ...{ partner?: ?({
        ...{| __typename: 'Partner' |},
      ...$Pick<Partner, { id: *, name?: *, code?: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, timeline: ({
      ...{| __typename: 'Timeline' |},
    ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
  }), ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
      ...{| __typename: 'Notification' |},
    ...$Pick<Notification, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type OrderEntityCardFragment = ({
    ...{| __typename: 'Order' |},
  ...$MakeOptional<$Pick<Order, { id: *, archived: *, currency: *, poNo: *, incoterm?: *, batchCount: *, containerCount: *, shipmentCount: *, filesUnreadCount: *, notificationUnseenCount: * }>, { batchCount: * } | { containerCount: * } | { shipmentCount: * }>,
  ...{ timeline: ({
      ...{| __typename: 'Timeline' |},
    ...$Pick<Timeline, { unreadMessageCount: * }>
  }), tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, exporter: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
      ...{| __typename: 'Notification' |},
    ...$Pick<Notification, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type OrderFormFragment = ({
    ...{| __typename: 'Order' |},
  ...$Pick<Order, { id: *, archived: *, updatedAt: *, memo?: *, poNo: *, currency: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, shipmentCount: *, issuedAt?: *, deliveryDate?: *, piNo?: *, incoterm?: *, deliveryPlace?: *, notificationUnseenCount: * }>,
  ...{ updatedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, followers: Array<({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, email: *, firstName: *, lastName: * }>,
    ...{ roles: Array<({
        ...{| __typename: 'Role' |},
      ...$Pick<Role, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organizations: Array<({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalPrice: ({
      ...{| __typename: 'NewPrice' |},
    ...$Pick<NewPrice, { amount: *, currency: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
      ...{| __typename: 'CustomFields' |},
    ...{ fieldValues: Array<({
        ...{| __typename: 'FieldValue' |},
      ...{ value: ({
          ...{| __typename: 'StringValue' |},
        ...$Pick<StringValue, { string: * }>
      }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }), importer: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, files: Array<({
      ...{| __typename: 'File' |},
    ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
    ...{ tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, entity?: ?({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: *, name: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: *, name: * }>,
      ...{ product: ({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: *, poNo: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: * }>
    }) | ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | ({
        ...{| __typename: 'Partnership' |},
      ...$Pick<Partnership, { id: * }>
    }) | ({
        ...{| __typename: 'Role' |},
      ...$Pick<Role, { id: * }>
    }) | ({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Mask' |},
      ...$Pick<Mask, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'MaskEdit' |},
      ...$Pick<MaskEdit, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'FieldDefinition' |},
      ...$Pick<FieldDefinition, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, orderItems: Array<({
      ...{| __typename: 'OrderItem' |},
    ...$Pick<OrderItem, { id: *, updatedAt: *, archived: *, no: *, quantity: *, deliveryDate?: *, memo?: * }>,
    ...{ price: ({
        ...{| __typename: 'NewPrice' |},
      ...$Pick<NewPrice, { amount: *, currency: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
        ...{| __typename: 'CustomFields' |},
      ...{ fieldValues: Array<({
          ...{| __typename: 'FieldValue' |},
        ...{ value: ({
            ...{| __typename: 'StringValue' |},
          ...$Pick<StringValue, { string: * }>
        }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }), tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, productProvider: ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: *, name: * }>,
      ...{ exporter: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
        ...{ grossWeight?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), volume?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), size?: ?({
            ...{| __typename: 'Size' |},
          ...{ length: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), width: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), height: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }) }
        }) }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
        ...{ grossWeight?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), volume?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), size?: ?({
            ...{| __typename: 'Size' |},
          ...{ length: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), width: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), height: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }) }
        }) }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, unitPrice?: ?({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, product: ({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: *, serial: * }>,
        ...{ tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, files: Array<({
            ...{| __typename: 'File' |},
          ...$Pick<File, { id: *, path: * }>,
          ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, batches: Array<({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: *, sort: *, shipmentSort: *, archived: *, autoCalculatePackageQuantity: *, autoCalculatePackageVolume: *, updatedAt: *, memo?: *, no: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: *, latestQuantityField: *, producedAt?: *, deliveredAt?: *, desiredAt?: *, expiredAt?: *, packageName?: *, packageCapacity?: *, packageQuantity?: * }>,
      ...{ updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalPrice?: ?({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
          ...{| __typename: 'CustomFields' |},
        ...{ fieldValues: Array<({
            ...{| __typename: 'FieldValue' |},
          ...{ value: ({
              ...{| __typename: 'StringValue' |},
            ...$Pick<StringValue, { string: * }>
          }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }), packageGrossWeight?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), packageVolume?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), packageSize?: ?({
          ...{| __typename: 'Size' |},
        ...{ length: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), width: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), height: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }) }
      }), tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, orderItem: ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
        ...{ price: ({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, order: ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
          ...{ totalPrice: ({
              ...{| __typename: 'NewPrice' |},
            ...$Pick<NewPrice, { amount: *, currency: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, timeline: ({
              ...{| __typename: 'Timeline' |},
            ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
          }), ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
              ...{| __typename: 'Notification' |},
            ...$Pick<Notification, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: *, name: * }>,
          ...{ exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
            ...{ grossWeight?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), volume?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), size?: ?({
                ...{| __typename: 'Size' |},
              ...{ length: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), width: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), height: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }) }
            }) }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
            ...{ grossWeight?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), volume?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), size?: ?({
                ...{| __typename: 'Size' |},
              ...{ length: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), width: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), height: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }) }
            }) }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, product: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: *, serial: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | ({
                ...{| __typename: 'Forbidden' |},
              ...$Pick<Forbidden, { reason?: * }>,
              ...{ reference?: ?({
                  ...{| __typename: 'Reference' |},
                ...$Pick<Reference, { id: * }>
              }) }
            }) | {| __typename: 'NotFound' |}>, files: Array<({
                ...{| __typename: 'File' |},
              ...$Pick<File, { id: *, path: * }>,
              ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment?: ?({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
        ...{ forwarders: Array<({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), totalVolumeOverride?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), timeline: ({
            ...{| __typename: 'Timeline' |},
          ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
        }), containerTypeCounts: Array<({
            ...{| __typename: 'ContainerTypeCount' |},
          ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
        })>, importer?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, voyages: Array<({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
          ...{ departurePort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), arrivalPort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), departure: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>,
          ...{ customClearance: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
          ...{ warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>,
          ...{ orderItem: ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>,
            ...{ productProvider: ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>,
              ...{ exporter: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
            ...{| __typename: 'Notification' |},
          ...$Pick<Notification, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: * }>,
        ...{ representativeBatch?: ?({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>,
          ...{ orderItem: ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>,
            ...{ productProvider: ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>,
              ...{ product: ({
                  ...{| __typename: 'Product' |},
                ...$Pick<Product, { id: *, name: *, serial: * }>,
                ...{ files: Array<({
                    ...{| __typename: 'File' |},
                  ...$Pick<File, { id: *, path: * }>,
                  ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), batches: Array<({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, no: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, files: Array<({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
      ...{ tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, entity?: ?({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, poNo: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | ({
          ...{| __typename: 'Partnership' |},
        ...$Pick<Partnership, { id: * }>
      }) | ({
          ...{| __typename: 'Role' |},
        ...$Pick<Role, { id: * }>
      }) | ({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Mask' |},
        ...$Pick<Mask, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'MaskEdit' |},
        ...$Pick<MaskEdit, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, shipments: Array<({
      ...{| __typename: 'Shipment' |},
    ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
    ...{ totalVolume: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), totalVolumeOverride?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), timeline: ({
        ...{| __typename: 'Timeline' |},
      ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
    }), containerTypeCounts: Array<({
        ...{| __typename: 'ContainerTypeCount' |},
      ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
    })>, importer?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>,
      ...{ partner?: ?({
          ...{| __typename: 'Partner' |},
        ...$Pick<Partner, { id: *, name?: *, code?: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>,
      ...{ partner?: ?({
          ...{| __typename: 'Partner' |},
        ...$Pick<Partner, { id: *, name?: *, code?: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, date: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, voyages: Array<({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
      ...{ departurePort?: ?({
          ...{| __typename: 'Port' |},
        ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
      }), arrivalPort?: ?({
          ...{| __typename: 'Port' |},
        ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
      }), departure: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>,
      ...{ customClearance: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
      ...{ warehouse?: ?({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: * }>,
      ...{ orderItem: ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: * }>,
        ...{ productProvider: ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: * }>,
          ...{ exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
        ...{| __typename: 'Notification' |},
      ...$Pick<Notification, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
      ...{| __typename: 'Container' |},
    ...$Pick<Container, { id: *, archived: *, no: *, containerType?: *, containerOption?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: *, freeTimeStartDate?: *, freeTimeDuration?: * }>,
    ...{ representativeBatch?: ?({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: * }>,
      ...{ orderItem: ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: * }>,
        ...{ productProvider: ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: * }>,
          ...{ product: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: *, serial: * }>,
            ...{ files: Array<({
                ...{| __typename: 'File' |},
              ...$Pick<File, { id: *, path: * }>,
              ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), batches: Array<({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, no: * }>,
      ...{ importer?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
      ...{| __typename: 'Notification' |},
    ...$Pick<Notification, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type OrderListFragment = ({
    ...{| __typename: 'Order' |},
  ...$Pick<Order, { notificationUnseenCount: *, id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: * }>,
  ...{ orderItems: Array<({
      ...{| __typename: 'OrderItem' |},
    ...$Pick<OrderItem, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, notificationUnseen: Array<({
      ...{| __typename: 'Notification' |},
    ...$Pick<Notification, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalPrice: ({
      ...{| __typename: 'NewPrice' |},
    ...$Pick<NewPrice, { amount: *, currency: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>,
    ...{ partner?: ?({
        ...{| __typename: 'Partner' |},
      ...$Pick<Partner, { id: *, name?: *, code?: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>,
    ...{ partner?: ?({
        ...{| __typename: 'Partner' |},
      ...$Pick<Partner, { id: *, name?: *, code?: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, timeline: ({
      ...{| __typename: 'Timeline' |},
    ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
  }), ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type OrderCloneManyMutationVariables = {
  orders: Array<OrderUpdateWrapperInput>
};


export type OrderCloneManyMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ orderCloneMany: Array<?({
      ...{| __typename: 'Order' |},
    ...$Pick<Order, { id: * }>,
    ...{ orderItems: Array<({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: * }>,
      ...{ batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}> }
});

export type OrderCreateMutationVariables = {
  input: OrderCreateInput
};


export type OrderCreateMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ orderCreate: ({
      ...{| __typename: 'Order' |},
    ...$Pick<Order, { id: * }>
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |} }
});

export type OrderUpdateMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  input: OrderUpdateInput
};


export type OrderUpdateMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ orderUpdate: ({
      ...{| __typename: 'Order' |},
    ...$Pick<Order, { id: *, archived: *, updatedAt: *, memo?: *, poNo: *, currency: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, shipmentCount: *, issuedAt?: *, deliveryDate?: *, piNo?: *, incoterm?: *, deliveryPlace?: *, notificationUnseenCount: * }>,
    ...{ updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, followers: Array<({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, email: *, firstName: *, lastName: * }>,
      ...{ roles: Array<({
          ...{| __typename: 'Role' |},
        ...$Pick<Role, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organizations: Array<({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalPrice: ({
        ...{| __typename: 'NewPrice' |},
      ...$Pick<NewPrice, { amount: *, currency: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
        ...{| __typename: 'CustomFields' |},
      ...{ fieldValues: Array<({
          ...{| __typename: 'FieldValue' |},
        ...{ value: ({
            ...{| __typename: 'StringValue' |},
          ...$Pick<StringValue, { string: * }>
        }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }), importer: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'BadRequest' |},
      ...{ violations: Array<({
          ...{| __typename: 'Violation' |},
        ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
        ...{ parameters: Array<({
            ...{| __typename: 'ViolationParameter' |},
          ...$Pick<ViolationParameter, { key: *, value: * }>
        })> }
      })> }
    }) | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, files: Array<({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
      ...{ tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, entity?: ?({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, poNo: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | ({
          ...{| __typename: 'Partnership' |},
        ...$Pick<Partnership, { id: * }>
      }) | ({
          ...{| __typename: 'Role' |},
        ...$Pick<Role, { id: * }>
      }) | ({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Mask' |},
        ...$Pick<Mask, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'MaskEdit' |},
        ...$Pick<MaskEdit, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, orderItems: Array<({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: *, updatedAt: *, archived: *, no: *, quantity: *, deliveryDate?: *, memo?: * }>,
      ...{ price: ({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
          ...{| __typename: 'CustomFields' |},
        ...{ fieldValues: Array<({
            ...{| __typename: 'FieldValue' |},
          ...{ value: ({
              ...{| __typename: 'StringValue' |},
            ...$Pick<StringValue, { string: * }>
          }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }), tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, productProvider: ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ exporter: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
          ...{ grossWeight?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), volume?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), size?: ?({
              ...{| __typename: 'Size' |},
            ...{ length: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), width: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), height: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }) }
          }) }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
          ...{ grossWeight?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), volume?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), size?: ?({
              ...{| __typename: 'Size' |},
            ...{ length: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), width: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), height: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }) }
          }) }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, unitPrice?: ?({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: *, serial: * }>,
          ...{ tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, files: Array<({
              ...{| __typename: 'File' |},
            ...$Pick<File, { id: *, path: * }>,
            ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: *, sort: *, shipmentSort: *, archived: *, autoCalculatePackageQuantity: *, autoCalculatePackageVolume: *, updatedAt: *, memo?: *, no: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: *, latestQuantityField: *, producedAt?: *, deliveredAt?: *, desiredAt?: *, expiredAt?: *, packageName?: *, packageCapacity?: *, packageQuantity?: * }>,
        ...{ updatedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), totalPrice?: ?({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
            ...{| __typename: 'CustomFields' |},
          ...{ fieldValues: Array<({
              ...{| __typename: 'FieldValue' |},
            ...{ value: ({
                ...{| __typename: 'StringValue' |},
              ...$Pick<StringValue, { string: * }>
            }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
                ...{| __typename: 'FieldDefinition' |},
              ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: *, types: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }), packageGrossWeight?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), packageVolume?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), packageSize?: ?({
            ...{| __typename: 'Size' |},
          ...{ length: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), width: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), height: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }) }
        }), tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
          ...{ price: ({
              ...{| __typename: 'NewPrice' |},
            ...$Pick<NewPrice, { amount: *, currency: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, order: ({
              ...{| __typename: 'Order' |},
            ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
            ...{ totalPrice: ({
                ...{| __typename: 'NewPrice' |},
              ...$Pick<NewPrice, { amount: *, currency: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>,
              ...{ partner?: ?({
                  ...{| __typename: 'Partner' |},
                ...$Pick<Partner, { id: *, name?: *, code?: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>,
              ...{ partner?: ?({
                  ...{| __typename: 'Partner' |},
                ...$Pick<Partner, { id: *, name?: *, code?: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | ({
                ...{| __typename: 'Forbidden' |},
              ...$Pick<Forbidden, { reason?: * }>,
              ...{ reference?: ?({
                  ...{| __typename: 'Reference' |},
                ...$Pick<Reference, { id: * }>
              }) }
            }) | {| __typename: 'NotFound' |}>, timeline: ({
                ...{| __typename: 'Timeline' |},
              ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
            }), ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
                ...{| __typename: 'Notification' |},
              ...$Pick<Notification, { id: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: *, name: * }>,
            ...{ exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>,
              ...{ partner?: ?({
                  ...{| __typename: 'Partner' |},
                ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
                ...{ tags: Array<({
                    ...{| __typename: 'Tag' |},
                  ...$Pick<Tag, { id: *, name: *, color: * }>,
                  ...{ ownedBy: ({
                      ...{| __typename: 'Organization' |},
                    ...$Pick<Organization, { id: *, name: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                      ...{| __typename: 'Connection' |},
                    ...$Pick<Connection, { id: *, displayName: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>,
              ...{ partner?: ?({
                  ...{| __typename: 'Partner' |},
                ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
                ...{ tags: Array<({
                    ...{| __typename: 'Tag' |},
                  ...$Pick<Tag, { id: *, name: *, color: * }>,
                  ...{ ownedBy: ({
                      ...{| __typename: 'Organization' |},
                    ...$Pick<Organization, { id: *, name: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                      ...{| __typename: 'Connection' |},
                    ...$Pick<Connection, { id: *, displayName: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
                ...{| __typename: 'ProductProviderPackage' |},
              ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
              ...{ grossWeight?: ?({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), volume?: ?({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), size?: ?({
                  ...{| __typename: 'Size' |},
                ...{ length: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }), width: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }), height: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }) }
              }) }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
                ...{| __typename: 'ProductProviderPackage' |},
              ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
              ...{ grossWeight?: ?({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), volume?: ?({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), size?: ?({
                  ...{| __typename: 'Size' |},
                ...{ length: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }), width: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }), height: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }) }
              }) }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, product: ({
                ...{| __typename: 'Product' |},
              ...$Pick<Product, { id: *, name: *, serial: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | ({
                  ...{| __typename: 'Forbidden' |},
                ...$Pick<Forbidden, { reason?: * }>,
                ...{ reference?: ?({
                    ...{| __typename: 'Reference' |},
                  ...$Pick<Reference, { id: * }>
                }) }
              }) | {| __typename: 'NotFound' |}>, files: Array<({
                  ...{| __typename: 'File' |},
                ...$Pick<File, { id: *, path: * }>,
                ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment?: ?({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
          ...{ forwarders: Array<({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolume: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), totalVolumeOverride?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), timeline: ({
              ...{| __typename: 'Timeline' |},
            ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
          }), containerTypeCounts: Array<({
              ...{| __typename: 'ContainerTypeCount' |},
            ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
          })>, importer?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, voyages: Array<({
              ...{| __typename: 'Voyage' |},
            ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
            ...{ departurePort?: ?({
                ...{| __typename: 'Port' |},
              ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
            }), arrivalPort?: ?({
                ...{| __typename: 'Port' |},
              ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
            }), departure: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
              ...{| __typename: 'ContainerGroup' |},
            ...$Pick<ContainerGroup, { id: * }>,
            ...{ customClearance: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
                ...{| __typename: 'Warehouse' |},
              ...$Pick<Warehouse, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
              ...{| __typename: 'Container' |},
            ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
            ...{ warehouse?: ?({
                ...{| __typename: 'Warehouse' |},
              ...$Pick<Warehouse, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: * }>,
            ...{ orderItem: ({
                ...{| __typename: 'OrderItem' |},
              ...$Pick<OrderItem, { id: * }>,
              ...{ productProvider: ({
                  ...{| __typename: 'ProductProvider' |},
                ...$Pick<ProductProvider, { id: * }>,
                ...{ exporter: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
              ...{| __typename: 'Notification' |},
            ...$Pick<Notification, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: *, no: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: * }>,
          ...{ representativeBatch?: ?({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: * }>,
            ...{ orderItem: ({
                ...{| __typename: 'OrderItem' |},
              ...$Pick<OrderItem, { id: * }>,
              ...{ productProvider: ({
                  ...{| __typename: 'ProductProvider' |},
                ...$Pick<ProductProvider, { id: * }>,
                ...{ product: ({
                    ...{| __typename: 'Product' |},
                  ...$Pick<Product, { id: *, name: *, serial: * }>,
                  ...{ files: Array<({
                      ...{| __typename: 'File' |},
                    ...$Pick<File, { id: *, path: * }>,
                    ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), batches: Array<({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
              ...{| __typename: 'Shipment' |},
            ...$Pick<Shipment, { id: *, no: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, files: Array<({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
        ...{ tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, entity?: ?({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: *, name: * }>,
          ...{ product: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: *, poNo: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | ({
            ...{| __typename: 'Partnership' |},
          ...$Pick<Partnership, { id: * }>
        }) | ({
            ...{| __typename: 'Role' |},
          ...$Pick<Role, { id: * }>
        }) | ({
            ...{| __typename: 'File' |},
          ...$Pick<File, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Mask' |},
          ...$Pick<Mask, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'MaskEdit' |},
          ...$Pick<MaskEdit, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, shipments: Array<({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
      ...{ totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalVolumeOverride?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), timeline: ({
          ...{| __typename: 'Timeline' |},
        ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
      }), containerTypeCounts: Array<({
          ...{| __typename: 'ContainerTypeCount' |},
        ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
      })>, importer?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, voyages: Array<({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
        ...{ departurePort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), arrivalPort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), departure: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ customClearance: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
        ...{ warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>,
        ...{ orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>,
          ...{ productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>,
            ...{ exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
          ...{| __typename: 'Notification' |},
        ...$Pick<Notification, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: *, archived: *, no: *, containerType?: *, containerOption?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: *, freeTimeStartDate?: *, freeTimeDuration?: * }>,
      ...{ representativeBatch?: ?({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>,
        ...{ orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>,
          ...{ productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>,
            ...{ product: ({
                ...{| __typename: 'Product' |},
              ...$Pick<Product, { id: *, name: *, serial: * }>,
              ...{ files: Array<({
                  ...{| __typename: 'File' |},
                ...$Pick<File, { id: *, path: * }>,
                ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>,
        ...{ importer?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
        ...{| __typename: 'Notification' |},
      ...$Pick<Notification, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |} }
});

export type OrderUpdateManyMutationVariables = {
  orders: Array<OrderUpdateWrapperInput>
};


export type OrderUpdateManyMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ orderUpdateMany: Array<?({
      ...{| __typename: 'Order' |},
    ...$Pick<Order, { id: * }>
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}> }
});

export type OrderExportQueryVariables = {
  id: $ElementType<Scalars, 'ID'>,
  templateId: $ElementType<Scalars, 'ID'>
};


export type OrderExportQuery = ({
    ...{| __typename: 'Query' |},
  ...{ orderExport: ({
      ...{| __typename: 'Export' |},
    ...$Pick<Export, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type OrderFocusedListQueryVariables = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?OrderFilterInput,
  sortBy?: ?OrderSortInput,
  skipOrderCounts: $ElementType<Scalars, 'Boolean'>
};


export type OrderFocusedListQuery = ({
    ...{| __typename: 'Query' |},
  ...{ orders: ({
      ...{| __typename: 'OrderPayloadPaginatedSearch' |},
    ...$Pick<OrderPayloadPaginatedSearch, { page: *, totalPage: * }>,
    ...{ nodes: Array<({
        ...{| __typename: 'Order' |},
      ...$MakeOptional<$Pick<Order, { id: *, archived: *, currency: *, poNo: *, incoterm?: *, batchCount: *, containerCount: *, shipmentCount: *, filesUnreadCount: *, notificationUnseenCount: * }>, { batchCount: * } | { containerCount: * } | { shipmentCount: * }>,
      ...{ orderItems: Array<({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, updatedAt: *, createdAt: *, archived: *, no: *, deliveryDate?: *, quantity: * }>,
        ...{ batches: Array<({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: *, updatedAt: *, createdAt: *, deliveredAt?: *, expiredAt?: *, desiredAt?: *, archived: *, no: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: * }>,
          ...{ totalVolume: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, shipment?: ?({
              ...{| __typename: 'Shipment' |},
            ...$Pick<Shipment, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
              ...{| __typename: 'Container' |},
            ...$Pick<Container, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, productProvider: ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: *, name: * }>,
          ...{ supplier?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, product: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: *, serial: * }>,
            ...{ files: Array<({
                ...{| __typename: 'File' |},
              ...$Pick<File, { id: * }>,
              ...{ pathSmall: $ElementType<File, 'path'> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, price: ({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, archived: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: *, containerType?: * }>,
        ...{ tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, terminalArrivalPort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), terminalArrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, shipments: Array<({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, orderCount: *, totalVolumeOverriding: *, filesUnreadCount: *, incoterm?: *, archived: *, no: *, blNo?: *, earliestWarehouseAgreedArrival?: *, latestWarehouseAgreedArrival?: *, earliestWarehouseActualArrival?: *, latestWarehouseActualArrival?: *, notificationUnseenCount: * }>,
        ...{ totalVolumeOverride?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), timeline: ({
            ...{| __typename: 'Timeline' |},
          ...$Pick<Timeline, { unreadMessageCount: * }>
        }), messageGroups: Array<({
            ...{| __typename: 'MessageGroup' |},
          ...$Pick<MessageGroup, { unreadMessageCount: *, filesUnreadCount: * }>
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, exporter?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, cargoReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, voyages: Array<({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: * }>,
          ...{ departurePort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { seaportName?: *, seaport?: *, airportName?: *, airport?: *, roadName?: *, road?: *, railName?: *, rail?: *, dryportName?: *, dryport?: * }>
          }), arrivalPort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { seaportName?: *, seaport?: *, airportName?: *, airport?: *, roadName?: *, road?: *, railName?: *, rail?: *, dryportName?: *, dryport?: * }>
          }), departure: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>,
          ...{ warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customClearance: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDateApprovedAt?: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
            ...{| __typename: 'Notification' |},
          ...$Pick<Notification, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, timeline: ({
          ...{| __typename: 'Timeline' |},
        ...$Pick<Timeline, { unreadMessageCount: * }>
      }), tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, exporter: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
          ...{| __typename: 'Notification' |},
        ...$Pick<Notification, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, hits: Array<({
        ...{| __typename: 'Hit' |},
      ...{ entityHits: Array<({
          ...{| __typename: 'EntityHit' |},
        ...$Pick<EntityHit, { field?: * }>,
        ...{ entity: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: * }>
        }) | ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: * }>
        }) | ({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: * }>
        }) | ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: * }>
        }) | ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>
        }) | ({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>
        }) | ({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: * }>
        }) | ({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: * }>
        }) | ({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: * }>
        }) | ({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>
        }) | ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: * }>
        }) | ({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: * }>
        }) | ({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: * }>
        }) | ({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: * }>
        }) | ({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | ({
            ...{| __typename: 'Partnership' |},
          ...$Pick<Partnership, { id: * }>
        }) | ({
            ...{| __typename: 'Role' |},
          ...$Pick<Role, { id: * }>
        }) | ({
            ...{| __typename: 'File' |},
          ...$Pick<File, { id: * }>
        }) | ({
            ...{| __typename: 'Mask' |},
          ...$Pick<Mask, { id: * }>
        }) | ({
            ...{| __typename: 'MaskEdit' |},
          ...$Pick<MaskEdit, { id: * }>
        }) | ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: * }>
        }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      })> }
    })> }
  }) }
});

export type OrderFormQueryVariables = {
  id: $ElementType<Scalars, 'ID'>
};


export type OrderFormQuery = ({
    ...{| __typename: 'Query' |},
  ...{ order: ({
      ...{| __typename: 'Order' |},
    ...$Pick<Order, { id: *, archived: *, updatedAt: *, memo?: *, poNo: *, currency: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, shipmentCount: *, issuedAt?: *, deliveryDate?: *, piNo?: *, incoterm?: *, deliveryPlace?: *, notificationUnseenCount: * }>,
    ...{ updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, followers: Array<({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, email: *, firstName: *, lastName: * }>,
      ...{ roles: Array<({
          ...{| __typename: 'Role' |},
        ...$Pick<Role, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organizations: Array<({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalPrice: ({
        ...{| __typename: 'NewPrice' |},
      ...$Pick<NewPrice, { amount: *, currency: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
        ...{| __typename: 'CustomFields' |},
      ...{ fieldValues: Array<({
          ...{| __typename: 'FieldValue' |},
        ...{ value: ({
            ...{| __typename: 'StringValue' |},
          ...$Pick<StringValue, { string: * }>
        }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }), importer: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'BadRequest' |},
      ...{ violations: Array<({
          ...{| __typename: 'Violation' |},
        ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
        ...{ parameters: Array<({
            ...{| __typename: 'ViolationParameter' |},
          ...$Pick<ViolationParameter, { key: *, value: * }>
        })> }
      })> }
    }) | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, files: Array<({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
      ...{ tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, entity?: ?({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, poNo: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | ({
          ...{| __typename: 'Partnership' |},
        ...$Pick<Partnership, { id: * }>
      }) | ({
          ...{| __typename: 'Role' |},
        ...$Pick<Role, { id: * }>
      }) | ({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Mask' |},
        ...$Pick<Mask, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'MaskEdit' |},
        ...$Pick<MaskEdit, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, orderItems: Array<({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: *, updatedAt: *, archived: *, no: *, quantity: *, deliveryDate?: *, memo?: * }>,
      ...{ price: ({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
          ...{| __typename: 'CustomFields' |},
        ...{ fieldValues: Array<({
            ...{| __typename: 'FieldValue' |},
          ...{ value: ({
              ...{| __typename: 'StringValue' |},
            ...$Pick<StringValue, { string: * }>
          }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }), tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, productProvider: ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ exporter: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
          ...{ grossWeight?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), volume?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), size?: ?({
              ...{| __typename: 'Size' |},
            ...{ length: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), width: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), height: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }) }
          }) }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
          ...{ grossWeight?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), volume?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), size?: ?({
              ...{| __typename: 'Size' |},
            ...{ length: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), width: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), height: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }) }
          }) }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, unitPrice?: ?({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: *, serial: * }>,
          ...{ tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, files: Array<({
              ...{| __typename: 'File' |},
            ...$Pick<File, { id: *, path: * }>,
            ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: *, sort: *, shipmentSort: *, archived: *, autoCalculatePackageQuantity: *, autoCalculatePackageVolume: *, updatedAt: *, memo?: *, no: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: *, latestQuantityField: *, producedAt?: *, deliveredAt?: *, desiredAt?: *, expiredAt?: *, packageName?: *, packageCapacity?: *, packageQuantity?: * }>,
        ...{ updatedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), totalPrice?: ?({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
            ...{| __typename: 'CustomFields' |},
          ...{ fieldValues: Array<({
              ...{| __typename: 'FieldValue' |},
            ...{ value: ({
                ...{| __typename: 'StringValue' |},
              ...$Pick<StringValue, { string: * }>
            }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
                ...{| __typename: 'FieldDefinition' |},
              ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: *, types: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }), packageGrossWeight?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), packageVolume?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), packageSize?: ?({
            ...{| __typename: 'Size' |},
          ...{ length: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), width: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), height: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }) }
        }), tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
          ...{ price: ({
              ...{| __typename: 'NewPrice' |},
            ...$Pick<NewPrice, { amount: *, currency: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, order: ({
              ...{| __typename: 'Order' |},
            ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
            ...{ totalPrice: ({
                ...{| __typename: 'NewPrice' |},
              ...$Pick<NewPrice, { amount: *, currency: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>,
              ...{ partner?: ?({
                  ...{| __typename: 'Partner' |},
                ...$Pick<Partner, { id: *, name?: *, code?: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>,
              ...{ partner?: ?({
                  ...{| __typename: 'Partner' |},
                ...$Pick<Partner, { id: *, name?: *, code?: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | ({
                ...{| __typename: 'Forbidden' |},
              ...$Pick<Forbidden, { reason?: * }>,
              ...{ reference?: ?({
                  ...{| __typename: 'Reference' |},
                ...$Pick<Reference, { id: * }>
              }) }
            }) | {| __typename: 'NotFound' |}>, timeline: ({
                ...{| __typename: 'Timeline' |},
              ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
            }), ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
                ...{| __typename: 'Notification' |},
              ...$Pick<Notification, { id: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: *, name: * }>,
            ...{ exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>,
              ...{ partner?: ?({
                  ...{| __typename: 'Partner' |},
                ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
                ...{ tags: Array<({
                    ...{| __typename: 'Tag' |},
                  ...$Pick<Tag, { id: *, name: *, color: * }>,
                  ...{ ownedBy: ({
                      ...{| __typename: 'Organization' |},
                    ...$Pick<Organization, { id: *, name: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                      ...{| __typename: 'Connection' |},
                    ...$Pick<Connection, { id: *, displayName: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>,
              ...{ partner?: ?({
                  ...{| __typename: 'Partner' |},
                ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
                ...{ tags: Array<({
                    ...{| __typename: 'Tag' |},
                  ...$Pick<Tag, { id: *, name: *, color: * }>,
                  ...{ ownedBy: ({
                      ...{| __typename: 'Organization' |},
                    ...$Pick<Organization, { id: *, name: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                      ...{| __typename: 'Connection' |},
                    ...$Pick<Connection, { id: *, displayName: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
                ...{| __typename: 'ProductProviderPackage' |},
              ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
              ...{ grossWeight?: ?({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), volume?: ?({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), size?: ?({
                  ...{| __typename: 'Size' |},
                ...{ length: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }), width: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }), height: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }) }
              }) }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
                ...{| __typename: 'ProductProviderPackage' |},
              ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
              ...{ grossWeight?: ?({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), volume?: ?({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), size?: ?({
                  ...{| __typename: 'Size' |},
                ...{ length: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }), width: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }), height: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }) }
              }) }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, product: ({
                ...{| __typename: 'Product' |},
              ...$Pick<Product, { id: *, name: *, serial: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | ({
                  ...{| __typename: 'Forbidden' |},
                ...$Pick<Forbidden, { reason?: * }>,
                ...{ reference?: ?({
                    ...{| __typename: 'Reference' |},
                  ...$Pick<Reference, { id: * }>
                }) }
              }) | {| __typename: 'NotFound' |}>, files: Array<({
                  ...{| __typename: 'File' |},
                ...$Pick<File, { id: *, path: * }>,
                ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment?: ?({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
          ...{ forwarders: Array<({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolume: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), totalVolumeOverride?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), timeline: ({
              ...{| __typename: 'Timeline' |},
            ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
          }), containerTypeCounts: Array<({
              ...{| __typename: 'ContainerTypeCount' |},
            ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
          })>, importer?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, voyages: Array<({
              ...{| __typename: 'Voyage' |},
            ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
            ...{ departurePort?: ?({
                ...{| __typename: 'Port' |},
              ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
            }), arrivalPort?: ?({
                ...{| __typename: 'Port' |},
              ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
            }), departure: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
              ...{| __typename: 'ContainerGroup' |},
            ...$Pick<ContainerGroup, { id: * }>,
            ...{ customClearance: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
                ...{| __typename: 'Warehouse' |},
              ...$Pick<Warehouse, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
              ...{| __typename: 'Container' |},
            ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
            ...{ warehouse?: ?({
                ...{| __typename: 'Warehouse' |},
              ...$Pick<Warehouse, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: * }>,
            ...{ orderItem: ({
                ...{| __typename: 'OrderItem' |},
              ...$Pick<OrderItem, { id: * }>,
              ...{ productProvider: ({
                  ...{| __typename: 'ProductProvider' |},
                ...$Pick<ProductProvider, { id: * }>,
                ...{ exporter: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
              ...{| __typename: 'Notification' |},
            ...$Pick<Notification, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: *, no: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: * }>,
          ...{ representativeBatch?: ?({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: * }>,
            ...{ orderItem: ({
                ...{| __typename: 'OrderItem' |},
              ...$Pick<OrderItem, { id: * }>,
              ...{ productProvider: ({
                  ...{| __typename: 'ProductProvider' |},
                ...$Pick<ProductProvider, { id: * }>,
                ...{ product: ({
                    ...{| __typename: 'Product' |},
                  ...$Pick<Product, { id: *, name: *, serial: * }>,
                  ...{ files: Array<({
                      ...{| __typename: 'File' |},
                    ...$Pick<File, { id: *, path: * }>,
                    ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), batches: Array<({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
              ...{| __typename: 'Shipment' |},
            ...$Pick<Shipment, { id: *, no: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, files: Array<({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
        ...{ tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, entity?: ?({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: *, name: * }>,
          ...{ product: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: *, poNo: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | ({
            ...{| __typename: 'Partnership' |},
          ...$Pick<Partnership, { id: * }>
        }) | ({
            ...{| __typename: 'Role' |},
          ...$Pick<Role, { id: * }>
        }) | ({
            ...{| __typename: 'File' |},
          ...$Pick<File, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Mask' |},
          ...$Pick<Mask, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'MaskEdit' |},
          ...$Pick<MaskEdit, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, shipments: Array<({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
      ...{ totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalVolumeOverride?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), timeline: ({
          ...{| __typename: 'Timeline' |},
        ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
      }), containerTypeCounts: Array<({
          ...{| __typename: 'ContainerTypeCount' |},
        ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
      })>, importer?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, voyages: Array<({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
        ...{ departurePort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), arrivalPort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), departure: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ customClearance: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
        ...{ warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>,
        ...{ orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>,
          ...{ productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>,
            ...{ exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
          ...{| __typename: 'Notification' |},
        ...$Pick<Notification, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: *, archived: *, no: *, containerType?: *, containerOption?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: *, freeTimeStartDate?: *, freeTimeDuration?: * }>,
      ...{ representativeBatch?: ?({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>,
        ...{ orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>,
          ...{ productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>,
            ...{ product: ({
                ...{| __typename: 'Product' |},
              ...$Pick<Product, { id: *, name: *, serial: * }>,
              ...{ files: Array<({
                  ...{| __typename: 'File' |},
                ...$Pick<File, { id: *, path: * }>,
                ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>,
        ...{ importer?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
        ...{| __typename: 'Notification' |},
      ...$Pick<Notification, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type OrderFormFilesQueryVariables = {
  id: $ElementType<Scalars, 'ID'>
};


export type OrderFormFilesQuery = ({
    ...{| __typename: 'Query' |},
  ...{ order: ({
      ...{| __typename: 'Order' |},
    ...$Pick<Order, { id: * }>,
    ...{ files: Array<({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
      ...{ tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, entity?: ?({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, poNo: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | ({
          ...{| __typename: 'Partnership' |},
        ...$Pick<Partnership, { id: * }>
      }) | ({
          ...{| __typename: 'Role' |},
        ...$Pick<Role, { id: * }>
      }) | ({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Mask' |},
        ...$Pick<Mask, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'MaskEdit' |},
        ...$Pick<MaskEdit, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type OrderFormItemsQueryVariables = {
  id: $ElementType<Scalars, 'ID'>
};


export type OrderFormItemsQuery = ({
    ...{| __typename: 'Query' |},
  ...{ order: ({
      ...{| __typename: 'Order' |},
    ...$Pick<Order, { id: * }>,
    ...{ orderItems: Array<({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: *, updatedAt: *, archived: *, no: *, quantity: *, deliveryDate?: *, memo?: * }>,
      ...{ price: ({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
          ...{| __typename: 'CustomFields' |},
        ...{ fieldValues: Array<({
            ...{| __typename: 'FieldValue' |},
          ...{ value: ({
              ...{| __typename: 'StringValue' |},
            ...$Pick<StringValue, { string: * }>
          }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }), tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, productProvider: ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ exporter: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
          ...{ grossWeight?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), volume?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), size?: ?({
              ...{| __typename: 'Size' |},
            ...{ length: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), width: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), height: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }) }
          }) }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
          ...{ grossWeight?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), volume?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), size?: ?({
              ...{| __typename: 'Size' |},
            ...{ length: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), width: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), height: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }) }
          }) }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, unitPrice?: ?({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: *, serial: * }>,
          ...{ tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, files: Array<({
              ...{| __typename: 'File' |},
            ...$Pick<File, { id: *, path: * }>,
            ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: *, sort: *, shipmentSort: *, archived: *, autoCalculatePackageQuantity: *, autoCalculatePackageVolume: *, updatedAt: *, memo?: *, no: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: *, latestQuantityField: *, producedAt?: *, deliveredAt?: *, desiredAt?: *, expiredAt?: *, packageName?: *, packageCapacity?: *, packageQuantity?: * }>,
        ...{ updatedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), totalPrice?: ?({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
            ...{| __typename: 'CustomFields' |},
          ...{ fieldValues: Array<({
              ...{| __typename: 'FieldValue' |},
            ...{ value: ({
                ...{| __typename: 'StringValue' |},
              ...$Pick<StringValue, { string: * }>
            }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
                ...{| __typename: 'FieldDefinition' |},
              ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: *, types: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }), packageGrossWeight?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), packageVolume?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), packageSize?: ?({
            ...{| __typename: 'Size' |},
          ...{ length: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), width: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), height: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }) }
        }), tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
          ...{ price: ({
              ...{| __typename: 'NewPrice' |},
            ...$Pick<NewPrice, { amount: *, currency: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, order: ({
              ...{| __typename: 'Order' |},
            ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
            ...{ totalPrice: ({
                ...{| __typename: 'NewPrice' |},
              ...$Pick<NewPrice, { amount: *, currency: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>,
              ...{ partner?: ?({
                  ...{| __typename: 'Partner' |},
                ...$Pick<Partner, { id: *, name?: *, code?: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>,
              ...{ partner?: ?({
                  ...{| __typename: 'Partner' |},
                ...$Pick<Partner, { id: *, name?: *, code?: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | ({
                ...{| __typename: 'Forbidden' |},
              ...$Pick<Forbidden, { reason?: * }>,
              ...{ reference?: ?({
                  ...{| __typename: 'Reference' |},
                ...$Pick<Reference, { id: * }>
              }) }
            }) | {| __typename: 'NotFound' |}>, timeline: ({
                ...{| __typename: 'Timeline' |},
              ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
            }), ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
                ...{| __typename: 'Notification' |},
              ...$Pick<Notification, { id: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: *, name: * }>,
            ...{ exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>,
              ...{ partner?: ?({
                  ...{| __typename: 'Partner' |},
                ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
                ...{ tags: Array<({
                    ...{| __typename: 'Tag' |},
                  ...$Pick<Tag, { id: *, name: *, color: * }>,
                  ...{ ownedBy: ({
                      ...{| __typename: 'Organization' |},
                    ...$Pick<Organization, { id: *, name: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                      ...{| __typename: 'Connection' |},
                    ...$Pick<Connection, { id: *, displayName: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>,
              ...{ partner?: ?({
                  ...{| __typename: 'Partner' |},
                ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
                ...{ tags: Array<({
                    ...{| __typename: 'Tag' |},
                  ...$Pick<Tag, { id: *, name: *, color: * }>,
                  ...{ ownedBy: ({
                      ...{| __typename: 'Organization' |},
                    ...$Pick<Organization, { id: *, name: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                      ...{| __typename: 'Connection' |},
                    ...$Pick<Connection, { id: *, displayName: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
                ...{| __typename: 'ProductProviderPackage' |},
              ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
              ...{ grossWeight?: ?({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), volume?: ?({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), size?: ?({
                  ...{| __typename: 'Size' |},
                ...{ length: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }), width: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }), height: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }) }
              }) }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
                ...{| __typename: 'ProductProviderPackage' |},
              ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
              ...{ grossWeight?: ?({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), volume?: ?({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), size?: ?({
                  ...{| __typename: 'Size' |},
                ...{ length: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }), width: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }), height: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }) }
              }) }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, product: ({
                ...{| __typename: 'Product' |},
              ...$Pick<Product, { id: *, name: *, serial: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | ({
                  ...{| __typename: 'Forbidden' |},
                ...$Pick<Forbidden, { reason?: * }>,
                ...{ reference?: ?({
                    ...{| __typename: 'Reference' |},
                  ...$Pick<Reference, { id: * }>
                }) }
              }) | {| __typename: 'NotFound' |}>, files: Array<({
                  ...{| __typename: 'File' |},
                ...$Pick<File, { id: *, path: * }>,
                ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment?: ?({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
          ...{ forwarders: Array<({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolume: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), totalVolumeOverride?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), timeline: ({
              ...{| __typename: 'Timeline' |},
            ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
          }), containerTypeCounts: Array<({
              ...{| __typename: 'ContainerTypeCount' |},
            ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
          })>, importer?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, voyages: Array<({
              ...{| __typename: 'Voyage' |},
            ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
            ...{ departurePort?: ?({
                ...{| __typename: 'Port' |},
              ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
            }), arrivalPort?: ?({
                ...{| __typename: 'Port' |},
              ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
            }), departure: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
              ...{| __typename: 'ContainerGroup' |},
            ...$Pick<ContainerGroup, { id: * }>,
            ...{ customClearance: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
                ...{| __typename: 'Warehouse' |},
              ...$Pick<Warehouse, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
              ...{| __typename: 'Container' |},
            ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
            ...{ warehouse?: ?({
                ...{| __typename: 'Warehouse' |},
              ...$Pick<Warehouse, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: * }>,
            ...{ orderItem: ({
                ...{| __typename: 'OrderItem' |},
              ...$Pick<OrderItem, { id: * }>,
              ...{ productProvider: ({
                  ...{| __typename: 'ProductProvider' |},
                ...$Pick<ProductProvider, { id: * }>,
                ...{ exporter: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
              ...{| __typename: 'Notification' |},
            ...$Pick<Notification, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: *, no: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: * }>,
          ...{ representativeBatch?: ?({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: * }>,
            ...{ orderItem: ({
                ...{| __typename: 'OrderItem' |},
              ...$Pick<OrderItem, { id: * }>,
              ...{ productProvider: ({
                  ...{| __typename: 'ProductProvider' |},
                ...$Pick<ProductProvider, { id: * }>,
                ...{ product: ({
                    ...{| __typename: 'Product' |},
                  ...$Pick<Product, { id: *, name: *, serial: * }>,
                  ...{ files: Array<({
                      ...{| __typename: 'File' |},
                    ...$Pick<File, { id: *, path: * }>,
                    ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), batches: Array<({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
              ...{| __typename: 'Shipment' |},
            ...$Pick<Shipment, { id: *, no: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, files: Array<({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
        ...{ tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, entity?: ?({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: *, name: * }>,
          ...{ product: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: *, poNo: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | ({
            ...{| __typename: 'Partnership' |},
          ...$Pick<Partnership, { id: * }>
        }) | ({
            ...{| __typename: 'Role' |},
          ...$Pick<Role, { id: * }>
        }) | ({
            ...{| __typename: 'File' |},
          ...$Pick<File, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Mask' |},
          ...$Pick<Mask, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'MaskEdit' |},
          ...$Pick<MaskEdit, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type OrderFullFocusDetailQueryVariables = {
  ids: Array<$ElementType<Scalars, 'ID'>>,
  skipOrderCounts: $ElementType<Scalars, 'Boolean'>
};


export type OrderFullFocusDetailQuery = ({
    ...{| __typename: 'Query' |},
  ...{ ordersByIDs: Array<({
      ...{| __typename: 'Order' |},
    ...$MakeOptional<$Pick<Order, { id: *, archived: *, currency: *, poNo: *, incoterm?: *, batchCount: *, containerCount: *, shipmentCount: *, filesUnreadCount: *, notificationUnseenCount: * }>, { batchCount: * } | { containerCount: * } | { shipmentCount: * }>,
    ...{ orderItems: Array<({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: *, updatedAt: *, createdAt: *, archived: *, no: *, deliveryDate?: *, quantity: * }>,
      ...{ batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: *, updatedAt: *, createdAt: *, deliveredAt?: *, expiredAt?: *, desiredAt?: *, archived: *, no: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: * }>,
        ...{ totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, shipment?: ?({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, productProvider: ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ supplier?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: *, serial: * }>,
          ...{ files: Array<({
              ...{| __typename: 'File' |},
            ...$Pick<File, { id: * }>,
            ...{ pathSmall: $ElementType<File, 'path'> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, price: ({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: *, no: *, archived: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: *, containerType?: * }>,
      ...{ tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, terminalArrivalPort?: ?({
          ...{| __typename: 'Port' |},
        ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
      }), terminalArrival: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, shipments: Array<({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, orderCount: *, totalVolumeOverriding: *, filesUnreadCount: *, incoterm?: *, archived: *, no: *, blNo?: *, earliestWarehouseAgreedArrival?: *, latestWarehouseAgreedArrival?: *, earliestWarehouseActualArrival?: *, latestWarehouseActualArrival?: *, notificationUnseenCount: * }>,
      ...{ totalVolumeOverride?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), timeline: ({
          ...{| __typename: 'Timeline' |},
        ...$Pick<Timeline, { unreadMessageCount: * }>
      }), messageGroups: Array<({
          ...{| __typename: 'MessageGroup' |},
        ...$Pick<MessageGroup, { unreadMessageCount: *, filesUnreadCount: * }>
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, exporter?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, cargoReady: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, voyages: Array<({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: * }>,
        ...{ departurePort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { seaportName?: *, seaport?: *, airportName?: *, airport?: *, roadName?: *, road?: *, railName?: *, rail?: *, dryportName?: *, dryport?: * }>
        }), arrivalPort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { seaportName?: *, seaport?: *, airportName?: *, airport?: *, roadName?: *, road?: *, railName?: *, rail?: *, dryportName?: *, dryport?: * }>
        }), departure: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customClearance: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDateApprovedAt?: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
          ...{| __typename: 'Notification' |},
        ...$Pick<Notification, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, timeline: ({
        ...{| __typename: 'Timeline' |},
      ...$Pick<Timeline, { unreadMessageCount: * }>
    }), tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, exporter: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
        ...{| __typename: 'Notification' |},
      ...$Pick<Notification, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type OrderListQueryVariables = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?OrderFilterInput,
  sortBy?: ?OrderSortInput
};


export type OrderListQuery = ({
    ...{| __typename: 'Query' |},
  ...{ orders: ({
      ...{| __typename: 'OrderPayloadPaginatedSearch' |},
    ...$Pick<OrderPayloadPaginatedSearch, { page: *, totalPage: * }>,
    ...{ nodes: Array<({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { notificationUnseenCount: *, id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: * }>,
      ...{ orderItems: Array<({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, notificationUnseen: Array<({
          ...{| __typename: 'Notification' |},
        ...$Pick<Notification, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalPrice: ({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, timeline: ({
          ...{| __typename: 'Timeline' |},
        ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
      }), ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) }
});

export type OrderTimelineQueryVariables = {
  id: $ElementType<Scalars, 'ID'>,
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>
};


export type OrderTimelineQuery = ({
    ...{| __typename: 'Query' |},
  ...{ order: ({
      ...{| __typename: 'Order' |},
    ...$Pick<Order, { id: * }>,
    ...{ timeline: ({
        ...{| __typename: 'Timeline' |},
      ...{ entries: ({
          ...{| __typename: 'TimelineEntryPaginatedList' |},
        ...$Pick<TimelineEntryPaginatedList, { page: *, totalPage: * }>,
        ...{ nodes: Array<({
            ...{| __typename: 'TimelineEvent' |},
          ...$Pick<TimelineEvent, { id: *, createdAt: * }>,
          ...{ entity: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: * }>
          }) | ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>
          }) | ({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: * }>
          }) | ({
              ...{| __typename: 'Order' |},
            ...$Pick<Order, { id: * }>
          }) | ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>
          }) | ({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: * }>
          }) | ({
              ...{| __typename: 'Shipment' |},
            ...$Pick<Shipment, { id: * }>
          }) | ({
              ...{| __typename: 'Voyage' |},
            ...$Pick<Voyage, { id: * }>
          }) | ({
              ...{| __typename: 'Container' |},
            ...$Pick<Container, { id: * }>
          }) | ({
              ...{| __typename: 'ContainerGroup' |},
            ...$Pick<ContainerGroup, { id: * }>
          }) | ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: * }>
          }) | ({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: * }>
          }) | ({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: * }>
          }) | ({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: * }>
          }) | ({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: * }>
          }) | ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: * }>
          }) | ({
              ...{| __typename: 'Partnership' |},
            ...$Pick<Partnership, { id: * }>
          }) | ({
              ...{| __typename: 'Role' |},
            ...$Pick<Role, { id: * }>
          }) | ({
              ...{| __typename: 'File' |},
            ...$Pick<File, { id: * }>
          }) | ({
              ...{| __typename: 'Mask' |},
            ...$Pick<Mask, { id: * }>
          }) | ({
              ...{| __typename: 'MaskEdit' |},
            ...$Pick<MaskEdit, { id: * }>
          }) | ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: * }>
          }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, createdBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, logs: Array<({
              ...{| __typename: 'Log' |},
            ...$Pick<Log, { id: *, translationKey: * }>,
            ...{ entity: ({
                ...{| __typename: 'Product' |},
              ...$Pick<Product, { id: * }>
            }) | ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>
            }) | ({
                ...{| __typename: 'ProductProviderPackage' |},
              ...$Pick<ProductProviderPackage, { id: * }>
            }) | ({
                ...{| __typename: 'Order' |},
              ...$Pick<Order, { id: * }>
            }) | ({
                ...{| __typename: 'OrderItem' |},
              ...$Pick<OrderItem, { id: * }>
            }) | ({
                ...{| __typename: 'Batch' |},
              ...$Pick<Batch, { id: * }>
            }) | ({
                ...{| __typename: 'Shipment' |},
              ...$Pick<Shipment, { id: * }>
            }) | ({
                ...{| __typename: 'Voyage' |},
              ...$Pick<Voyage, { id: * }>
            }) | ({
                ...{| __typename: 'Container' |},
              ...$Pick<Container, { id: * }>
            }) | ({
                ...{| __typename: 'ContainerGroup' |},
              ...$Pick<ContainerGroup, { id: * }>
            }) | ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: * }>
            }) | ({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: * }>
            }) | ({
                ...{| __typename: 'Warehouse' |},
              ...$Pick<Warehouse, { id: * }>
            }) | ({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: * }>
            }) | ({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: * }>
            }) | ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: * }>
            }) | ({
                ...{| __typename: 'Partnership' |},
              ...$Pick<Partnership, { id: * }>
            }) | ({
                ...{| __typename: 'Role' |},
              ...$Pick<Role, { id: * }>
            }) | ({
                ...{| __typename: 'File' |},
              ...$Pick<File, { id: *, type: * }>
            }) | ({
                ...{| __typename: 'Mask' |},
              ...$Pick<Mask, { id: * }>
            }) | ({
                ...{| __typename: 'MaskEdit' |},
              ...$Pick<MaskEdit, { id: * }>
            }) | ({
                ...{| __typename: 'FieldDefinition' |},
              ...$Pick<FieldDefinition, { id: * }>
            }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, parameters: Array<({
                ...{| __typename: 'Parameter' |},
              ...$Pick<Parameter, { key: * }>,
              ...{ value?: ?({
                  ...{| __typename: 'StringValue' |},
                ...$Pick<StringValue, { string: * }>
              }) | ({
                  ...{| __typename: 'IntValue' |},
                ...$Pick<IntValue, { int: * }>
              }) | ({
                  ...{| __typename: 'FloatValue' |},
                ...$Pick<FloatValue, { float: * }>
              }) | ({
                  ...{| __typename: 'BooleanValue' |},
                ...$Pick<BooleanValue, { boolean: * }>
              }) | ({
                  ...{| __typename: 'DateTimeValue' |},
                ...$Pick<DateTimeValue, { datetime: * }>
              }) | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | ({
                  ...{| __typename: 'MetricValueValue' |},
                ...{ metricValue: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }) }
              }) | ({
                  ...{| __typename: 'PriceValue' |},
                ...{ priceValue: ({
                    ...{| __typename: 'NewPrice' |},
                  ...$Pick<NewPrice, { amount: *, currency: * }>
                }) }
              }) | ({
                  ...{| __typename: 'SizeValue' |},
                ...{ size: ({
                    ...{| __typename: 'Size' |},
                  ...{ height: ({
                      ...{| __typename: 'MetricValue' |},
                    ...$Pick<MetricValue, { value: *, metric: * }>
                  }), length: ({
                      ...{| __typename: 'MetricValue' |},
                    ...$Pick<MetricValue, { value: *, metric: * }>
                  }), width: ({
                      ...{| __typename: 'MetricValue' |},
                    ...$Pick<MetricValue, { value: *, metric: * }>
                  }) }
                }) }
              }) | ({
                  ...{| __typename: 'EntityValue' |},
                ...{ entity?: ?({
                    ...{| __typename: 'Product' |},
                  ...$Pick<Product, { id: * }>
                }) | ({
                    ...{| __typename: 'ProductProvider' |},
                  ...$Pick<ProductProvider, { id: * }>
                }) | ({
                    ...{| __typename: 'ProductProviderPackage' |},
                  ...$Pick<ProductProviderPackage, { id: * }>
                }) | ({
                    ...{| __typename: 'Order' |},
                  ...$Pick<Order, { id: * }>
                }) | ({
                    ...{| __typename: 'OrderItem' |},
                  ...$Pick<OrderItem, { id: * }>
                }) | ({
                    ...{| __typename: 'Batch' |},
                  ...$Pick<Batch, { id: * }>
                }) | ({
                    ...{| __typename: 'Shipment' |},
                  ...$Pick<Shipment, { id: * }>
                }) | ({
                    ...{| __typename: 'Voyage' |},
                  ...$Pick<Voyage, { id: * }>
                }) | ({
                    ...{| __typename: 'Container' |},
                  ...$Pick<Container, { id: * }>
                }) | ({
                    ...{| __typename: 'ContainerGroup' |},
                  ...$Pick<ContainerGroup, { id: * }>
                }) | ({
                    ...{| __typename: 'TimelineDate' |},
                  ...$Pick<TimelineDate, { id: * }>
                }) | ({
                    ...{| __typename: 'TimelineDateRevision' |},
                  ...$Pick<TimelineDateRevision, { id: * }>
                }) | ({
                    ...{| __typename: 'Warehouse' |},
                  ...$Pick<Warehouse, { id: * }>
                }) | ({
                    ...{| __typename: 'Tag' |},
                  ...$Pick<Tag, { id: *, name: *, color: * }>
                }) | ({
                    ...{| __typename: 'User' |},
                  ...$Pick<User, { id: *, firstName: *, lastName: * }>
                }) | ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: *, types: * }>,
                  ...{ partner?: ?({
                      ...{| __typename: 'Partner' |},
                    ...$Pick<Partner, { id: *, name?: *, code?: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
                }) | ({
                    ...{| __typename: 'Partnership' |},
                  ...$Pick<Partnership, { id: * }>
                }) | ({
                    ...{| __typename: 'Role' |},
                  ...$Pick<Role, { id: * }>
                }) | ({
                    ...{| __typename: 'File' |},
                  ...$Pick<File, { id: * }>
                }) | ({
                    ...{| __typename: 'Mask' |},
                  ...$Pick<Mask, { id: * }>
                }) | ({
                    ...{| __typename: 'MaskEdit' |},
                  ...$Pick<MaskEdit, { id: * }>
                }) | ({
                    ...{| __typename: 'FieldDefinition' |},
                  ...$Pick<FieldDefinition, { id: * }>
                }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | ({
                  ...{| __typename: 'Values' |},
                ...{ values: Array<?({
                    ...{| __typename: 'StringValue' |},
                  ...$Pick<StringValue, { string: * }>
                }) | ({
                    ...{| __typename: 'IntValue' |},
                  ...$Pick<IntValue, { int: * }>
                }) | ({
                    ...{| __typename: 'FloatValue' |},
                  ...$Pick<FloatValue, { float: * }>
                }) | ({
                    ...{| __typename: 'BooleanValue' |},
                  ...$Pick<BooleanValue, { boolean: * }>
                }) | ({
                    ...{| __typename: 'DateTimeValue' |},
                  ...$Pick<DateTimeValue, { datetime: * }>
                }) | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | ({
                    ...{| __typename: 'MetricValueValue' |},
                  ...{ metricValue: ({
                      ...{| __typename: 'MetricValue' |},
                    ...$Pick<MetricValue, { value: *, metric: * }>
                  }) }
                }) | ({
                    ...{| __typename: 'PriceValue' |},
                  ...{ priceValue: ({
                      ...{| __typename: 'NewPrice' |},
                    ...$Pick<NewPrice, { amount: *, currency: * }>
                  }) }
                }) | ({
                    ...{| __typename: 'SizeValue' |},
                  ...{ size: ({
                      ...{| __typename: 'Size' |},
                    ...{ height: ({
                        ...{| __typename: 'MetricValue' |},
                      ...$Pick<MetricValue, { value: *, metric: * }>
                    }), length: ({
                        ...{| __typename: 'MetricValue' |},
                      ...$Pick<MetricValue, { value: *, metric: * }>
                    }), width: ({
                        ...{| __typename: 'MetricValue' |},
                      ...$Pick<MetricValue, { value: *, metric: * }>
                    }) }
                  }) }
                }) | ({
                    ...{| __typename: 'EntityValue' |},
                  ...{ entity?: ?({
                      ...{| __typename: 'Product' |},
                    ...$Pick<Product, { id: * }>
                  }) | ({
                      ...{| __typename: 'ProductProvider' |},
                    ...$Pick<ProductProvider, { id: * }>
                  }) | ({
                      ...{| __typename: 'ProductProviderPackage' |},
                    ...$Pick<ProductProviderPackage, { id: * }>
                  }) | ({
                      ...{| __typename: 'Order' |},
                    ...$Pick<Order, { id: * }>
                  }) | ({
                      ...{| __typename: 'OrderItem' |},
                    ...$Pick<OrderItem, { id: * }>
                  }) | ({
                      ...{| __typename: 'Batch' |},
                    ...$Pick<Batch, { id: * }>
                  }) | ({
                      ...{| __typename: 'Shipment' |},
                    ...$Pick<Shipment, { id: * }>
                  }) | ({
                      ...{| __typename: 'Voyage' |},
                    ...$Pick<Voyage, { id: * }>
                  }) | ({
                      ...{| __typename: 'Container' |},
                    ...$Pick<Container, { id: * }>
                  }) | ({
                      ...{| __typename: 'ContainerGroup' |},
                    ...$Pick<ContainerGroup, { id: * }>
                  }) | ({
                      ...{| __typename: 'TimelineDate' |},
                    ...$Pick<TimelineDate, { id: * }>
                  }) | ({
                      ...{| __typename: 'TimelineDateRevision' |},
                    ...$Pick<TimelineDateRevision, { id: * }>
                  }) | ({
                      ...{| __typename: 'Warehouse' |},
                    ...$Pick<Warehouse, { id: * }>
                  }) | ({
                      ...{| __typename: 'Tag' |},
                    ...$Pick<Tag, { id: *, name: *, color: * }>
                  }) | ({
                      ...{| __typename: 'User' |},
                    ...$Pick<User, { id: *, firstName: *, lastName: * }>
                  }) | ({
                      ...{| __typename: 'Organization' |},
                    ...$Pick<Organization, { id: *, name: *, types: * }>,
                    ...{ partner?: ?({
                        ...{| __typename: 'Partner' |},
                      ...$Pick<Partner, { id: *, name?: *, code?: * }>
                    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
                  }) | ({
                      ...{| __typename: 'Partnership' |},
                    ...$Pick<Partnership, { id: * }>
                  }) | ({
                      ...{| __typename: 'Role' |},
                    ...$Pick<Role, { id: * }>
                  }) | ({
                      ...{| __typename: 'File' |},
                    ...$Pick<File, { id: * }>
                  }) | ({
                      ...{| __typename: 'Mask' |},
                    ...$Pick<Mask, { id: * }>
                  }) | ({
                      ...{| __typename: 'MaskEdit' |},
                    ...$Pick<MaskEdit, { id: * }>
                  }) | ({
                      ...{| __typename: 'FieldDefinition' |},
                    ...$Pick<FieldDefinition, { id: * }>
                  }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
                }) | {| __typename: 'Values' |}> }
              }) }
            })> }
          })> }
        }) | ({
            ...{| __typename: 'TimelineComment' |},
          ...$Pick<TimelineComment, { id: *, content: *, createdAt: *, updatedAt: * }>,
          ...{ createdBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) }
    }) }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type OrdersByIDsQueryVariables = {
  ids: Array<$ElementType<Scalars, 'ID'>>
};


export type OrdersByIDsQuery = ({
    ...{| __typename: 'Query' |},
  ...{ ordersByIDs: Array<({
      ...{| __typename: 'Order' |},
    ...$Pick<Order, { id: *, poNo: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type OrdersByIDsDocumentsQueryVariables = {
  ids: Array<$ElementType<Scalars, 'ID'>>
};


export type OrdersByIDsDocumentsQuery = ({
    ...{| __typename: 'Query' |},
  ...{ ordersByIDs: Array<({
      ...{| __typename: 'Order' |},
    ...$Pick<Order, { id: * }>,
    ...{ files: Array<({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
      ...{ tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, entity?: ?({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, poNo: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | ({
          ...{| __typename: 'Partnership' |},
        ...$Pick<Partnership, { id: * }>
      }) | ({
          ...{| __typename: 'Role' |},
        ...$Pick<Role, { id: * }>
      }) | ({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Mask' |},
        ...$Pick<Mask, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'MaskEdit' |},
        ...$Pick<MaskEdit, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type OrdersCardQueryVariables = {
  filterBy?: ?OrderFilterInput,
  sortBy?: ?OrderSortInput,
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>
};


export type OrdersCardQuery = ({
    ...{| __typename: 'Query' |},
  ...{ orders: ({
      ...{| __typename: 'OrderPayloadPaginatedSearch' |},
    ...$Pick<OrderPayloadPaginatedSearch, { page: *, totalPage: * }>,
    ...{ nodes: Array<({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
      ...{ totalPrice: ({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, timeline: ({
          ...{| __typename: 'Timeline' |},
        ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
      }), ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
          ...{| __typename: 'Notification' |},
        ...$Pick<Notification, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}> }
  }) }
});

export type OrdersDocumentFolderViewQueryVariables = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?OrderFilterInput
};


export type OrdersDocumentFolderViewQuery = ({
    ...{| __typename: 'Query' |},
  ...{ orders: ({
      ...{| __typename: 'OrderPayloadPaginatedSearch' |},
    ...$Pick<OrderPayloadPaginatedSearch, { page: *, totalPage: * }>,
    ...{ nodes: Array<({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { poNo: *, id: * }>,
      ...{ fileCount: ({
          ...{| __typename: 'OrderFileCount' |},
        ...$Pick<OrderFileCount, { count: * }>
      }) }
    }) | ({
        ...{| __typename: 'BadRequest' |},
      ...{ violations: Array<({
          ...{| __typename: 'Violation' |},
        ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
        ...{ parameters: Array<({
            ...{| __typename: 'ViolationParameter' |},
          ...$Pick<ViolationParameter, { key: *, value: * }>
        })> }
      })> }
    }) | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | ({
        ...{| __typename: 'NotFound' |},
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: *, type: *, service: * }>
      }) }
    })> }
  }) }
});

export type OrdersExportQueryVariables = {
  templateId: $ElementType<Scalars, 'ID'>,
  filterBy?: ?OrderFilterInput,
  sortBy?: ?OrderSortInput,
  localSortBy?: ?Array<GenericSortInput>,
  columns?: ?Array<$ElementType<Scalars, 'String'>>
};


export type OrdersExportQuery = ({
    ...{| __typename: 'Query' |},
  ...{ ordersExport: ({
      ...{| __typename: 'Export' |},
    ...$Pick<Export, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type OrderItemCloneManyMutationVariables = {
  orderItems: Array<OrderItemUpdateWrapperInput>
};


export type OrderItemCloneManyMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ orderItemCloneMany: Array<?({
      ...{| __typename: 'OrderItem' |},
    ...$Pick<OrderItem, { id: * }>,
    ...{ batches: Array<({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}> }
});

export type OrderItemUpdateMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  input: OrderItemUpdateInput
};


export type OrderItemUpdateMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ orderItemUpdate: ({
      ...{| __typename: 'OrderItem' |},
    ...$Pick<OrderItem, { id: *, archived: *, updatedAt: *, no: *, quantity: *, deliveryDate?: *, memo?: * }>,
    ...{ updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, price: ({
        ...{| __typename: 'NewPrice' |},
      ...$Pick<NewPrice, { amount: *, currency: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
        ...{| __typename: 'CustomFields' |},
      ...{ fieldValues: Array<({
          ...{| __typename: 'FieldValue' |},
        ...{ value: ({
            ...{| __typename: 'StringValue' |},
          ...$Pick<StringValue, { string: * }>
        }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }), tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, order: ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
      ...{ totalPrice: ({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, timeline: ({
          ...{| __typename: 'Timeline' |},
        ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
      }), ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
          ...{| __typename: 'Notification' |},
        ...$Pick<Notification, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: *, archived: *, name: * }>,
      ...{ exporter: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, unitPrice?: ?({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, product: ({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: *, serial: * }>,
        ...{ tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, files: Array<({
            ...{| __typename: 'File' |},
          ...$Pick<File, { id: *, path: * }>,
          ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
        ...{ grossWeight?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), volume?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), size?: ?({
            ...{| __typename: 'Size' |},
          ...{ length: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), width: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), height: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }) }
        }) }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
        ...{ grossWeight?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), volume?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), size?: ?({
            ...{| __typename: 'Size' |},
          ...{ length: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), width: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), height: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }) }
        }) }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, batches: Array<({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: *, sort: *, shipmentSort: *, archived: *, autoCalculatePackageQuantity: *, autoCalculatePackageVolume: *, updatedAt: *, memo?: *, no: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: *, latestQuantityField: *, producedAt?: *, deliveredAt?: *, desiredAt?: *, expiredAt?: *, packageName?: *, packageCapacity?: *, packageQuantity?: * }>,
      ...{ updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalPrice?: ?({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
          ...{| __typename: 'CustomFields' |},
        ...{ fieldValues: Array<({
            ...{| __typename: 'FieldValue' |},
          ...{ value: ({
              ...{| __typename: 'StringValue' |},
            ...$Pick<StringValue, { string: * }>
          }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }), packageGrossWeight?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), packageVolume?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), packageSize?: ?({
          ...{| __typename: 'Size' |},
        ...{ length: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), width: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), height: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }) }
      }), tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, orderItem: ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
        ...{ price: ({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, order: ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
          ...{ totalPrice: ({
              ...{| __typename: 'NewPrice' |},
            ...$Pick<NewPrice, { amount: *, currency: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, timeline: ({
              ...{| __typename: 'Timeline' |},
            ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
          }), ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
              ...{| __typename: 'Notification' |},
            ...$Pick<Notification, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: *, name: * }>,
          ...{ exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
            ...{ grossWeight?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), volume?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), size?: ?({
                ...{| __typename: 'Size' |},
              ...{ length: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), width: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), height: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }) }
            }) }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
            ...{ grossWeight?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), volume?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), size?: ?({
                ...{| __typename: 'Size' |},
              ...{ length: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), width: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), height: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }) }
            }) }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, product: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: *, serial: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | ({
                ...{| __typename: 'Forbidden' |},
              ...$Pick<Forbidden, { reason?: * }>,
              ...{ reference?: ?({
                  ...{| __typename: 'Reference' |},
                ...$Pick<Reference, { id: * }>
              }) }
            }) | {| __typename: 'NotFound' |}>, files: Array<({
                ...{| __typename: 'File' |},
              ...$Pick<File, { id: *, path: * }>,
              ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment?: ?({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
        ...{ forwarders: Array<({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), totalVolumeOverride?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), timeline: ({
            ...{| __typename: 'Timeline' |},
          ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
        }), containerTypeCounts: Array<({
            ...{| __typename: 'ContainerTypeCount' |},
          ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
        })>, importer?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, voyages: Array<({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
          ...{ departurePort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), arrivalPort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), departure: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>,
          ...{ customClearance: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
          ...{ warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>,
          ...{ orderItem: ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>,
            ...{ productProvider: ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>,
              ...{ exporter: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
            ...{| __typename: 'Notification' |},
          ...$Pick<Notification, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: * }>,
        ...{ representativeBatch?: ?({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>,
          ...{ orderItem: ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>,
            ...{ productProvider: ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>,
              ...{ product: ({
                  ...{| __typename: 'Product' |},
                ...$Pick<Product, { id: *, name: *, serial: * }>,
                ...{ files: Array<({
                    ...{| __typename: 'File' |},
                  ...$Pick<File, { id: *, path: * }>,
                  ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), batches: Array<({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, no: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, files: Array<({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
      ...{ tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, entity?: ?({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, poNo: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | ({
          ...{| __typename: 'Partnership' |},
        ...$Pick<Partnership, { id: * }>
      }) | ({
          ...{| __typename: 'Role' |},
        ...$Pick<Role, { id: * }>
      }) | ({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Mask' |},
        ...$Pick<Mask, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'MaskEdit' |},
        ...$Pick<MaskEdit, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, shipments: Array<({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
      ...{ totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalVolumeOverride?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), timeline: ({
          ...{| __typename: 'Timeline' |},
        ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
      }), containerTypeCounts: Array<({
          ...{| __typename: 'ContainerTypeCount' |},
        ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
      })>, importer?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, voyages: Array<({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
        ...{ departurePort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), arrivalPort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), departure: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ customClearance: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
        ...{ warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>,
        ...{ orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>,
          ...{ productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>,
            ...{ exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
          ...{| __typename: 'Notification' |},
        ...$Pick<Notification, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |} }
});

export type OrderItemBatchesSplitQueryVariables = {
  ids: Array<$ElementType<Scalars, 'ID'>>
};


export type OrderItemBatchesSplitQuery = ({
    ...{| __typename: 'Query' |},
  ...{ orderItemsByIDs: Array<({
      ...{| __typename: 'OrderItem' |},
    ...$Pick<OrderItem, { id: *, quantity: * }>,
    ...{ batches: Array<({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: *, no: *, quantity: *, latestQuantity: *, latestQuantityField: * }>,
      ...{ orderItem: ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, no: * }>,
        ...{ order: ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: *, poNo: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: *, name: * }>,
          ...{ product: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: *, serial: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type OrderItemFileQueryVariables = {
  id: $ElementType<Scalars, 'ID'>
};


export type OrderItemFileQuery = ({
    ...{| __typename: 'Query' |},
  ...{ orderItem: ({
      ...{| __typename: 'OrderItem' |},
    ...$Pick<OrderItem, { id: * }>,
    ...{ files: Array<({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |} }
});

export type OrderItemFormQueryVariables = {
  id: $ElementType<Scalars, 'ID'>
};


export type OrderItemFormQuery = ({
    ...{| __typename: 'Query' |},
  ...{ orderItem: ({
      ...{| __typename: 'OrderItem' |},
    ...$Pick<OrderItem, { id: *, archived: *, updatedAt: *, no: *, quantity: *, deliveryDate?: *, memo?: * }>,
    ...{ updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, price: ({
        ...{| __typename: 'NewPrice' |},
      ...$Pick<NewPrice, { amount: *, currency: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
        ...{| __typename: 'CustomFields' |},
      ...{ fieldValues: Array<({
          ...{| __typename: 'FieldValue' |},
        ...{ value: ({
            ...{| __typename: 'StringValue' |},
          ...$Pick<StringValue, { string: * }>
        }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }), tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, order: ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
      ...{ totalPrice: ({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, timeline: ({
          ...{| __typename: 'Timeline' |},
        ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
      }), ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
          ...{| __typename: 'Notification' |},
        ...$Pick<Notification, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: *, archived: *, name: * }>,
      ...{ exporter: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, unitPrice?: ?({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, product: ({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: *, serial: * }>,
        ...{ tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, files: Array<({
            ...{| __typename: 'File' |},
          ...$Pick<File, { id: *, path: * }>,
          ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
        ...{ grossWeight?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), volume?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), size?: ?({
            ...{| __typename: 'Size' |},
          ...{ length: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), width: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), height: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }) }
        }) }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
        ...{ grossWeight?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), volume?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), size?: ?({
            ...{| __typename: 'Size' |},
          ...{ length: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), width: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), height: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }) }
        }) }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, batches: Array<({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: *, sort: *, shipmentSort: *, archived: *, autoCalculatePackageQuantity: *, autoCalculatePackageVolume: *, updatedAt: *, memo?: *, no: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: *, latestQuantityField: *, producedAt?: *, deliveredAt?: *, desiredAt?: *, expiredAt?: *, packageName?: *, packageCapacity?: *, packageQuantity?: * }>,
      ...{ updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalPrice?: ?({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
          ...{| __typename: 'CustomFields' |},
        ...{ fieldValues: Array<({
            ...{| __typename: 'FieldValue' |},
          ...{ value: ({
              ...{| __typename: 'StringValue' |},
            ...$Pick<StringValue, { string: * }>
          }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }), packageGrossWeight?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), packageVolume?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), packageSize?: ?({
          ...{| __typename: 'Size' |},
        ...{ length: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), width: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), height: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }) }
      }), tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, orderItem: ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
        ...{ price: ({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, order: ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
          ...{ totalPrice: ({
              ...{| __typename: 'NewPrice' |},
            ...$Pick<NewPrice, { amount: *, currency: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, timeline: ({
              ...{| __typename: 'Timeline' |},
            ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
          }), ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
              ...{| __typename: 'Notification' |},
            ...$Pick<Notification, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: *, name: * }>,
          ...{ exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
            ...{ grossWeight?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), volume?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), size?: ?({
                ...{| __typename: 'Size' |},
              ...{ length: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), width: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), height: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }) }
            }) }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
            ...{ grossWeight?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), volume?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), size?: ?({
                ...{| __typename: 'Size' |},
              ...{ length: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), width: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), height: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }) }
            }) }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, product: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: *, serial: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | ({
                ...{| __typename: 'Forbidden' |},
              ...$Pick<Forbidden, { reason?: * }>,
              ...{ reference?: ?({
                  ...{| __typename: 'Reference' |},
                ...$Pick<Reference, { id: * }>
              }) }
            }) | {| __typename: 'NotFound' |}>, files: Array<({
                ...{| __typename: 'File' |},
              ...$Pick<File, { id: *, path: * }>,
              ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment?: ?({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
        ...{ forwarders: Array<({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), totalVolumeOverride?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), timeline: ({
            ...{| __typename: 'Timeline' |},
          ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
        }), containerTypeCounts: Array<({
            ...{| __typename: 'ContainerTypeCount' |},
          ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
        })>, importer?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, voyages: Array<({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
          ...{ departurePort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), arrivalPort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), departure: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>,
          ...{ customClearance: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
          ...{ warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>,
          ...{ orderItem: ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>,
            ...{ productProvider: ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>,
              ...{ exporter: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
            ...{| __typename: 'Notification' |},
          ...$Pick<Notification, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: * }>,
        ...{ representativeBatch?: ?({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>,
          ...{ orderItem: ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>,
            ...{ productProvider: ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>,
              ...{ product: ({
                  ...{| __typename: 'Product' |},
                ...$Pick<Product, { id: *, name: *, serial: * }>,
                ...{ files: Array<({
                    ...{| __typename: 'File' |},
                  ...$Pick<File, { id: *, path: * }>,
                  ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), batches: Array<({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, no: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, files: Array<({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
      ...{ tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, entity?: ?({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, poNo: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | ({
          ...{| __typename: 'Partnership' |},
        ...$Pick<Partnership, { id: * }>
      }) | ({
          ...{| __typename: 'Role' |},
        ...$Pick<Role, { id: * }>
      }) | ({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Mask' |},
        ...$Pick<Mask, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'MaskEdit' |},
        ...$Pick<MaskEdit, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, shipments: Array<({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
      ...{ totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalVolumeOverride?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), timeline: ({
          ...{| __typename: 'Timeline' |},
        ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
      }), containerTypeCounts: Array<({
          ...{| __typename: 'ContainerTypeCount' |},
        ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
      })>, importer?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, voyages: Array<({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
        ...{ departurePort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), arrivalPort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), departure: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ customClearance: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
        ...{ warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>,
        ...{ orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>,
          ...{ productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>,
            ...{ exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
          ...{| __typename: 'Notification' |},
        ...$Pick<Notification, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |} }
});

export type OrderItemListQueryVariables = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?OrderItemFilterInput,
  sortBy?: ?OrderItemSortInput
};


export type OrderItemListQuery = ({
    ...{| __typename: 'Query' |},
  ...{ orderItems: ({
      ...{| __typename: 'OrderItemPayloadPaginatedSearch' |},
    ...$Pick<OrderItemPayloadPaginatedSearch, { page: *, totalPage: * }>,
    ...{ nodes: Array<({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
      ...{ price: ({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, order: ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, poNo: * }>,
        ...{ importer: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: *, serial: * }>,
          ...{ tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, files: Array<({
              ...{| __typename: 'File' |},
            ...$Pick<File, { id: *, path: * }>,
            ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}> }
  }) }
});

export type OrderItemListSelectorQueryVariables = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?OrderItemFilterInput,
  sortBy?: ?OrderItemSortInput
};


export type OrderItemListSelectorQuery = ({
    ...{| __typename: 'Query' |},
  ...{ orderItems: ({
      ...{| __typename: 'OrderItemPayloadPaginatedSearch' |},
    ...$Pick<OrderItemPayloadPaginatedSearch, { page: *, totalPage: * }>,
    ...{ nodes: Array<({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
      ...{ price: ({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, order: ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
        ...{ totalPrice: ({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, timeline: ({
            ...{| __typename: 'Timeline' |},
          ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
        }), ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
            ...{| __typename: 'Notification' |},
          ...$Pick<Notification, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ exporter: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
          ...{ grossWeight?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), volume?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), size?: ?({
              ...{| __typename: 'Size' |},
            ...{ length: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), width: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), height: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }) }
          }) }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
          ...{ grossWeight?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), volume?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), size?: ?({
              ...{| __typename: 'Size' |},
            ...{ length: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), width: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), height: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }) }
          }) }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: *, serial: * }>,
          ...{ tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, files: Array<({
              ...{| __typename: 'File' |},
            ...$Pick<File, { id: *, path: * }>,
            ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) }
});

export type OrderItemTimelineQueryVariables = {
  id: $ElementType<Scalars, 'ID'>,
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>
};


export type OrderItemTimelineQuery = ({
    ...{| __typename: 'Query' |},
  ...{ orderItem: ({
      ...{| __typename: 'OrderItem' |},
    ...$Pick<OrderItem, { id: * }>,
    ...{ timeline: ({
        ...{| __typename: 'Timeline' |},
      ...{ entries: ({
          ...{| __typename: 'TimelineEntryPaginatedList' |},
        ...$Pick<TimelineEntryPaginatedList, { page: *, totalPage: * }>,
        ...{ nodes: Array<({
            ...{| __typename: 'TimelineEvent' |},
          ...$Pick<TimelineEvent, { id: *, createdAt: * }>,
          ...{ entity: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: * }>
          }) | ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>
          }) | ({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: * }>
          }) | ({
              ...{| __typename: 'Order' |},
            ...$Pick<Order, { id: * }>
          }) | ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>
          }) | ({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: * }>
          }) | ({
              ...{| __typename: 'Shipment' |},
            ...$Pick<Shipment, { id: * }>
          }) | ({
              ...{| __typename: 'Voyage' |},
            ...$Pick<Voyage, { id: * }>
          }) | ({
              ...{| __typename: 'Container' |},
            ...$Pick<Container, { id: * }>
          }) | ({
              ...{| __typename: 'ContainerGroup' |},
            ...$Pick<ContainerGroup, { id: * }>
          }) | ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: * }>
          }) | ({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: * }>
          }) | ({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: * }>
          }) | ({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: * }>
          }) | ({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: * }>
          }) | ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: * }>
          }) | ({
              ...{| __typename: 'Partnership' |},
            ...$Pick<Partnership, { id: * }>
          }) | ({
              ...{| __typename: 'Role' |},
            ...$Pick<Role, { id: * }>
          }) | ({
              ...{| __typename: 'File' |},
            ...$Pick<File, { id: * }>
          }) | ({
              ...{| __typename: 'Mask' |},
            ...$Pick<Mask, { id: * }>
          }) | ({
              ...{| __typename: 'MaskEdit' |},
            ...$Pick<MaskEdit, { id: * }>
          }) | ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: * }>
          }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, createdBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, logs: Array<({
              ...{| __typename: 'Log' |},
            ...$Pick<Log, { id: *, translationKey: * }>,
            ...{ entity: ({
                ...{| __typename: 'Product' |},
              ...$Pick<Product, { id: * }>
            }) | ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>
            }) | ({
                ...{| __typename: 'ProductProviderPackage' |},
              ...$Pick<ProductProviderPackage, { id: * }>
            }) | ({
                ...{| __typename: 'Order' |},
              ...$Pick<Order, { id: * }>
            }) | ({
                ...{| __typename: 'OrderItem' |},
              ...$Pick<OrderItem, { id: * }>
            }) | ({
                ...{| __typename: 'Batch' |},
              ...$Pick<Batch, { id: * }>
            }) | ({
                ...{| __typename: 'Shipment' |},
              ...$Pick<Shipment, { id: * }>
            }) | ({
                ...{| __typename: 'Voyage' |},
              ...$Pick<Voyage, { id: * }>
            }) | ({
                ...{| __typename: 'Container' |},
              ...$Pick<Container, { id: * }>
            }) | ({
                ...{| __typename: 'ContainerGroup' |},
              ...$Pick<ContainerGroup, { id: * }>
            }) | ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: * }>
            }) | ({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: * }>
            }) | ({
                ...{| __typename: 'Warehouse' |},
              ...$Pick<Warehouse, { id: * }>
            }) | ({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: * }>
            }) | ({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: * }>
            }) | ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: * }>
            }) | ({
                ...{| __typename: 'Partnership' |},
              ...$Pick<Partnership, { id: * }>
            }) | ({
                ...{| __typename: 'Role' |},
              ...$Pick<Role, { id: * }>
            }) | ({
                ...{| __typename: 'File' |},
              ...$Pick<File, { id: *, type: * }>
            }) | ({
                ...{| __typename: 'Mask' |},
              ...$Pick<Mask, { id: * }>
            }) | ({
                ...{| __typename: 'MaskEdit' |},
              ...$Pick<MaskEdit, { id: * }>
            }) | ({
                ...{| __typename: 'FieldDefinition' |},
              ...$Pick<FieldDefinition, { id: * }>
            }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, parameters: Array<({
                ...{| __typename: 'Parameter' |},
              ...$Pick<Parameter, { key: * }>,
              ...{ value?: ?({
                  ...{| __typename: 'StringValue' |},
                ...$Pick<StringValue, { string: * }>
              }) | ({
                  ...{| __typename: 'IntValue' |},
                ...$Pick<IntValue, { int: * }>
              }) | ({
                  ...{| __typename: 'FloatValue' |},
                ...$Pick<FloatValue, { float: * }>
              }) | ({
                  ...{| __typename: 'BooleanValue' |},
                ...$Pick<BooleanValue, { boolean: * }>
              }) | ({
                  ...{| __typename: 'DateTimeValue' |},
                ...$Pick<DateTimeValue, { datetime: * }>
              }) | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | ({
                  ...{| __typename: 'MetricValueValue' |},
                ...{ metricValue: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }) }
              }) | ({
                  ...{| __typename: 'PriceValue' |},
                ...{ priceValue: ({
                    ...{| __typename: 'NewPrice' |},
                  ...$Pick<NewPrice, { amount: *, currency: * }>
                }) }
              }) | ({
                  ...{| __typename: 'SizeValue' |},
                ...{ size: ({
                    ...{| __typename: 'Size' |},
                  ...{ height: ({
                      ...{| __typename: 'MetricValue' |},
                    ...$Pick<MetricValue, { value: *, metric: * }>
                  }), length: ({
                      ...{| __typename: 'MetricValue' |},
                    ...$Pick<MetricValue, { value: *, metric: * }>
                  }), width: ({
                      ...{| __typename: 'MetricValue' |},
                    ...$Pick<MetricValue, { value: *, metric: * }>
                  }) }
                }) }
              }) | ({
                  ...{| __typename: 'EntityValue' |},
                ...{ entity?: ?({
                    ...{| __typename: 'Product' |},
                  ...$Pick<Product, { id: * }>
                }) | ({
                    ...{| __typename: 'ProductProvider' |},
                  ...$Pick<ProductProvider, { id: * }>
                }) | ({
                    ...{| __typename: 'ProductProviderPackage' |},
                  ...$Pick<ProductProviderPackage, { id: * }>
                }) | ({
                    ...{| __typename: 'Order' |},
                  ...$Pick<Order, { id: * }>
                }) | ({
                    ...{| __typename: 'OrderItem' |},
                  ...$Pick<OrderItem, { id: * }>
                }) | ({
                    ...{| __typename: 'Batch' |},
                  ...$Pick<Batch, { id: * }>
                }) | ({
                    ...{| __typename: 'Shipment' |},
                  ...$Pick<Shipment, { id: * }>
                }) | ({
                    ...{| __typename: 'Voyage' |},
                  ...$Pick<Voyage, { id: * }>
                }) | ({
                    ...{| __typename: 'Container' |},
                  ...$Pick<Container, { id: * }>
                }) | ({
                    ...{| __typename: 'ContainerGroup' |},
                  ...$Pick<ContainerGroup, { id: * }>
                }) | ({
                    ...{| __typename: 'TimelineDate' |},
                  ...$Pick<TimelineDate, { id: * }>
                }) | ({
                    ...{| __typename: 'TimelineDateRevision' |},
                  ...$Pick<TimelineDateRevision, { id: * }>
                }) | ({
                    ...{| __typename: 'Warehouse' |},
                  ...$Pick<Warehouse, { id: * }>
                }) | ({
                    ...{| __typename: 'Tag' |},
                  ...$Pick<Tag, { id: *, name: *, color: * }>
                }) | ({
                    ...{| __typename: 'User' |},
                  ...$Pick<User, { id: *, firstName: *, lastName: * }>
                }) | ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: *, types: * }>,
                  ...{ partner?: ?({
                      ...{| __typename: 'Partner' |},
                    ...$Pick<Partner, { id: *, name?: *, code?: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
                }) | ({
                    ...{| __typename: 'Partnership' |},
                  ...$Pick<Partnership, { id: * }>
                }) | ({
                    ...{| __typename: 'Role' |},
                  ...$Pick<Role, { id: * }>
                }) | ({
                    ...{| __typename: 'File' |},
                  ...$Pick<File, { id: * }>
                }) | ({
                    ...{| __typename: 'Mask' |},
                  ...$Pick<Mask, { id: * }>
                }) | ({
                    ...{| __typename: 'MaskEdit' |},
                  ...$Pick<MaskEdit, { id: * }>
                }) | ({
                    ...{| __typename: 'FieldDefinition' |},
                  ...$Pick<FieldDefinition, { id: * }>
                }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | ({
                  ...{| __typename: 'Values' |},
                ...{ values: Array<?({
                    ...{| __typename: 'StringValue' |},
                  ...$Pick<StringValue, { string: * }>
                }) | ({
                    ...{| __typename: 'IntValue' |},
                  ...$Pick<IntValue, { int: * }>
                }) | ({
                    ...{| __typename: 'FloatValue' |},
                  ...$Pick<FloatValue, { float: * }>
                }) | ({
                    ...{| __typename: 'BooleanValue' |},
                  ...$Pick<BooleanValue, { boolean: * }>
                }) | ({
                    ...{| __typename: 'DateTimeValue' |},
                  ...$Pick<DateTimeValue, { datetime: * }>
                }) | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | ({
                    ...{| __typename: 'MetricValueValue' |},
                  ...{ metricValue: ({
                      ...{| __typename: 'MetricValue' |},
                    ...$Pick<MetricValue, { value: *, metric: * }>
                  }) }
                }) | ({
                    ...{| __typename: 'PriceValue' |},
                  ...{ priceValue: ({
                      ...{| __typename: 'NewPrice' |},
                    ...$Pick<NewPrice, { amount: *, currency: * }>
                  }) }
                }) | ({
                    ...{| __typename: 'SizeValue' |},
                  ...{ size: ({
                      ...{| __typename: 'Size' |},
                    ...{ height: ({
                        ...{| __typename: 'MetricValue' |},
                      ...$Pick<MetricValue, { value: *, metric: * }>
                    }), length: ({
                        ...{| __typename: 'MetricValue' |},
                      ...$Pick<MetricValue, { value: *, metric: * }>
                    }), width: ({
                        ...{| __typename: 'MetricValue' |},
                      ...$Pick<MetricValue, { value: *, metric: * }>
                    }) }
                  }) }
                }) | ({
                    ...{| __typename: 'EntityValue' |},
                  ...{ entity?: ?({
                      ...{| __typename: 'Product' |},
                    ...$Pick<Product, { id: * }>
                  }) | ({
                      ...{| __typename: 'ProductProvider' |},
                    ...$Pick<ProductProvider, { id: * }>
                  }) | ({
                      ...{| __typename: 'ProductProviderPackage' |},
                    ...$Pick<ProductProviderPackage, { id: * }>
                  }) | ({
                      ...{| __typename: 'Order' |},
                    ...$Pick<Order, { id: * }>
                  }) | ({
                      ...{| __typename: 'OrderItem' |},
                    ...$Pick<OrderItem, { id: * }>
                  }) | ({
                      ...{| __typename: 'Batch' |},
                    ...$Pick<Batch, { id: * }>
                  }) | ({
                      ...{| __typename: 'Shipment' |},
                    ...$Pick<Shipment, { id: * }>
                  }) | ({
                      ...{| __typename: 'Voyage' |},
                    ...$Pick<Voyage, { id: * }>
                  }) | ({
                      ...{| __typename: 'Container' |},
                    ...$Pick<Container, { id: * }>
                  }) | ({
                      ...{| __typename: 'ContainerGroup' |},
                    ...$Pick<ContainerGroup, { id: * }>
                  }) | ({
                      ...{| __typename: 'TimelineDate' |},
                    ...$Pick<TimelineDate, { id: * }>
                  }) | ({
                      ...{| __typename: 'TimelineDateRevision' |},
                    ...$Pick<TimelineDateRevision, { id: * }>
                  }) | ({
                      ...{| __typename: 'Warehouse' |},
                    ...$Pick<Warehouse, { id: * }>
                  }) | ({
                      ...{| __typename: 'Tag' |},
                    ...$Pick<Tag, { id: *, name: *, color: * }>
                  }) | ({
                      ...{| __typename: 'User' |},
                    ...$Pick<User, { id: *, firstName: *, lastName: * }>
                  }) | ({
                      ...{| __typename: 'Organization' |},
                    ...$Pick<Organization, { id: *, name: *, types: * }>,
                    ...{ partner?: ?({
                        ...{| __typename: 'Partner' |},
                      ...$Pick<Partner, { id: *, name?: *, code?: * }>
                    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
                  }) | ({
                      ...{| __typename: 'Partnership' |},
                    ...$Pick<Partnership, { id: * }>
                  }) | ({
                      ...{| __typename: 'Role' |},
                    ...$Pick<Role, { id: * }>
                  }) | ({
                      ...{| __typename: 'File' |},
                    ...$Pick<File, { id: * }>
                  }) | ({
                      ...{| __typename: 'Mask' |},
                    ...$Pick<Mask, { id: * }>
                  }) | ({
                      ...{| __typename: 'MaskEdit' |},
                    ...$Pick<MaskEdit, { id: * }>
                  }) | ({
                      ...{| __typename: 'FieldDefinition' |},
                    ...$Pick<FieldDefinition, { id: * }>
                  }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
                }) | {| __typename: 'Values' |}> }
              }) }
            })> }
          })> }
        }) | ({
            ...{| __typename: 'TimelineComment' |},
          ...$Pick<TimelineComment, { id: *, content: *, createdAt: *, updatedAt: * }>,
          ...{ createdBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) }
    }) }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type OrderItemsByIDsDocumentsQueryVariables = {
  ids: Array<$ElementType<Scalars, 'ID'>>
};


export type OrderItemsByIDsDocumentsQuery = ({
    ...{| __typename: 'Query' |},
  ...{ orderItemsByIDs: Array<({
      ...{| __typename: 'OrderItem' |},
    ...{ files: Array<({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
      ...{ tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, entity?: ?({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, poNo: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | ({
          ...{| __typename: 'Partnership' |},
        ...$Pick<Partnership, { id: * }>
      }) | ({
          ...{| __typename: 'Role' |},
        ...$Pick<Role, { id: * }>
      }) | ({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Mask' |},
        ...$Pick<Mask, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'MaskEdit' |},
        ...$Pick<MaskEdit, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type OrderItemsByIDsFileQueryVariables = {
  ids: Array<$ElementType<Scalars, 'ID'>>
};


export type OrderItemsByIDsFileQuery = ({
    ...{| __typename: 'Query' |},
  ...{ orderItemsByIDs: Array<({
      ...{| __typename: 'OrderItem' |},
    ...$Pick<OrderItem, { id: * }>,
    ...{ files: Array<({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}> }
});

export type OrderItemsByIDsInOrderFormQueryVariables = {
  ids: Array<$ElementType<Scalars, 'ID'>>
};


export type OrderItemsByIDsInOrderFormQuery = ({
    ...{| __typename: 'Query' |},
  ...{ orderItemsByIDs: Array<({
      ...{| __typename: 'OrderItem' |},
    ...$Pick<OrderItem, { id: *, updatedAt: *, archived: *, no: *, quantity: *, deliveryDate?: *, memo?: * }>,
    ...{ order: ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: * }>,
      ...{ exporter: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, price: ({
        ...{| __typename: 'NewPrice' |},
      ...$Pick<NewPrice, { amount: *, currency: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
        ...{| __typename: 'CustomFields' |},
      ...{ fieldValues: Array<({
          ...{| __typename: 'FieldValue' |},
        ...{ value: ({
            ...{| __typename: 'StringValue' |},
          ...$Pick<StringValue, { string: * }>
        }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }), tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, productProvider: ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: *, name: * }>,
      ...{ exporter: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
        ...{ grossWeight?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), volume?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), size?: ?({
            ...{| __typename: 'Size' |},
          ...{ length: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), width: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), height: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }) }
        }) }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
        ...{ grossWeight?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), volume?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), size?: ?({
            ...{| __typename: 'Size' |},
          ...{ length: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), width: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), height: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }) }
        }) }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, unitPrice?: ?({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, product: ({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: *, serial: * }>,
        ...{ tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, files: Array<({
            ...{| __typename: 'File' |},
          ...$Pick<File, { id: *, path: * }>,
          ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, batches: Array<({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: *, sort: *, shipmentSort: *, archived: *, autoCalculatePackageQuantity: *, autoCalculatePackageVolume: *, updatedAt: *, memo?: *, no: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: *, latestQuantityField: *, producedAt?: *, deliveredAt?: *, desiredAt?: *, expiredAt?: *, packageName?: *, packageCapacity?: *, packageQuantity?: * }>,
      ...{ updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalPrice?: ?({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
          ...{| __typename: 'CustomFields' |},
        ...{ fieldValues: Array<({
            ...{| __typename: 'FieldValue' |},
          ...{ value: ({
              ...{| __typename: 'StringValue' |},
            ...$Pick<StringValue, { string: * }>
          }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }), packageGrossWeight?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), packageVolume?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), packageSize?: ?({
          ...{| __typename: 'Size' |},
        ...{ length: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), width: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), height: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }) }
      }), tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, orderItem: ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
        ...{ price: ({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, order: ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
          ...{ totalPrice: ({
              ...{| __typename: 'NewPrice' |},
            ...$Pick<NewPrice, { amount: *, currency: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, timeline: ({
              ...{| __typename: 'Timeline' |},
            ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
          }), ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
              ...{| __typename: 'Notification' |},
            ...$Pick<Notification, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: *, name: * }>,
          ...{ exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
            ...{ grossWeight?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), volume?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), size?: ?({
                ...{| __typename: 'Size' |},
              ...{ length: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), width: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), height: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }) }
            }) }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
            ...{ grossWeight?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), volume?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), size?: ?({
                ...{| __typename: 'Size' |},
              ...{ length: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), width: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), height: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }) }
            }) }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, product: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: *, serial: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | ({
                ...{| __typename: 'Forbidden' |},
              ...$Pick<Forbidden, { reason?: * }>,
              ...{ reference?: ?({
                  ...{| __typename: 'Reference' |},
                ...$Pick<Reference, { id: * }>
              }) }
            }) | {| __typename: 'NotFound' |}>, files: Array<({
                ...{| __typename: 'File' |},
              ...$Pick<File, { id: *, path: * }>,
              ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment?: ?({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
        ...{ forwarders: Array<({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), totalVolumeOverride?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), timeline: ({
            ...{| __typename: 'Timeline' |},
          ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
        }), containerTypeCounts: Array<({
            ...{| __typename: 'ContainerTypeCount' |},
          ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
        })>, importer?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, voyages: Array<({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
          ...{ departurePort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), arrivalPort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), departure: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>,
          ...{ customClearance: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
          ...{ warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>,
          ...{ orderItem: ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>,
            ...{ productProvider: ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>,
              ...{ exporter: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
            ...{| __typename: 'Notification' |},
          ...$Pick<Notification, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: * }>,
        ...{ representativeBatch?: ?({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>,
          ...{ orderItem: ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>,
            ...{ productProvider: ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>,
              ...{ product: ({
                  ...{| __typename: 'Product' |},
                ...$Pick<Product, { id: *, name: *, serial: * }>,
                ...{ files: Array<({
                    ...{| __typename: 'File' |},
                  ...$Pick<File, { id: *, path: * }>,
                  ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), batches: Array<({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, no: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, files: Array<({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
      ...{ tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, entity?: ?({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, poNo: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | ({
          ...{| __typename: 'Partnership' |},
        ...$Pick<Partnership, { id: * }>
      }) | ({
          ...{| __typename: 'Role' |},
        ...$Pick<Role, { id: * }>
      }) | ({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Mask' |},
        ...$Pick<Mask, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'MaskEdit' |},
        ...$Pick<MaskEdit, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type Owned_Tag_Fragment = ({
    ...{| __typename: 'Tag' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type Owned_Product_Fragment = ({
    ...{| __typename: 'Product' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type Owned_Reminder_Fragment = ({
    ...{| __typename: 'Reminder' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type Owned_MaskEdit_Fragment = ({
    ...{| __typename: 'MaskEdit' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type Owned_File_Fragment = ({
    ...{| __typename: 'File' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type Owned_TimelineEvent_Fragment = ({
    ...{| __typename: 'TimelineEvent' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type Owned_TimelineComment_Fragment = ({
    ...{| __typename: 'TimelineComment' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type Owned_Mask_Fragment = ({
    ...{| __typename: 'Mask' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type Owned_FieldDefinition_Fragment = ({
    ...{| __typename: 'FieldDefinition' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type Owned_ProductProvider_Fragment = ({
    ...{| __typename: 'ProductProvider' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type Owned_ProductProviderPackage_Fragment = ({
    ...{| __typename: 'ProductProviderPackage' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type Owned_Batch_Fragment = ({
    ...{| __typename: 'Batch' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type Owned_OrderItem_Fragment = ({
    ...{| __typename: 'OrderItem' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type Owned_Order_Fragment = ({
    ...{| __typename: 'Order' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type Owned_Shipment_Fragment = ({
    ...{| __typename: 'Shipment' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type Owned_TimelineDate_Fragment = ({
    ...{| __typename: 'TimelineDate' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type Owned_TimelineDateRevision_Fragment = ({
    ...{| __typename: 'TimelineDateRevision' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type Owned_Warehouse_Fragment = ({
    ...{| __typename: 'Warehouse' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type Owned_Voyage_Fragment = ({
    ...{| __typename: 'Voyage' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type Owned_ContainerGroup_Fragment = ({
    ...{| __typename: 'ContainerGroup' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type Owned_Container_Fragment = ({
    ...{| __typename: 'Container' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type Owned_TagGroup_Fragment = ({
    ...{| __typename: 'TagGroup' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type Owned_OrganizationGroup_Fragment = ({
    ...{| __typename: 'OrganizationGroup' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type Owned_ViewState_Fragment = ({
    ...{| __typename: 'ViewState' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type Owned_Integration_Fragment = ({
    ...{| __typename: 'Integration' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type Owned_IntegrationConfiguration_Fragment = ({
    ...{| __typename: 'IntegrationConfiguration' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type Owned_ActivityLog_Fragment = ({
    ...{| __typename: 'ActivityLog' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type OwnedFragment = Owned_Tag_Fragment | Owned_Product_Fragment | Owned_Reminder_Fragment | Owned_MaskEdit_Fragment | Owned_File_Fragment | Owned_TimelineEvent_Fragment | Owned_TimelineComment_Fragment | Owned_Mask_Fragment | Owned_FieldDefinition_Fragment | Owned_ProductProvider_Fragment | Owned_ProductProviderPackage_Fragment | Owned_Batch_Fragment | Owned_OrderItem_Fragment | Owned_Order_Fragment | Owned_Shipment_Fragment | Owned_TimelineDate_Fragment | Owned_TimelineDateRevision_Fragment | Owned_Warehouse_Fragment | Owned_Voyage_Fragment | Owned_ContainerGroup_Fragment | Owned_Container_Fragment | Owned_TagGroup_Fragment | Owned_OrganizationGroup_Fragment | Owned_ViewState_Fragment | Owned_Integration_Fragment | Owned_IntegrationConfiguration_Fragment | Owned_ActivityLog_Fragment;

export type PartnerCardFragment = ({
    ...{| __typename: 'Partner' |},
  ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
  ...{ tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type PartnerFormFragment = ({
    ...{| __typename: 'Partner' |},
  ...$Pick<Partner, { id: *, updatedAt: *, name?: *, code?: *, types: * }>,
  ...{ updatedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, tel?: *, country?: *, region?: *, locality?: *, street?: *, postalCode?: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type RelationPartnerCardFragment = ({
    ...{| __typename: 'Organization' |},
  ...$Pick<Organization, { id: *, name: *, types: * }>
});

export type PartnerUpdateMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  input: PartnerUpdateInput
};


export type PartnerUpdateMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ partnerUpdate: ({
      ...{| __typename: 'Partner' |},
    ...$Pick<Partner, { id: *, updatedAt: *, name?: *, code?: *, types: * }>,
    ...{ updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, tel?: *, country?: *, region?: *, locality?: *, street?: *, postalCode?: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |} }
});

export type PartnerQueryVariables = {
  id: $ElementType<Scalars, 'ID'>
};


export type PartnerQuery = ({
    ...{| __typename: 'Query' |},
  ...{ partner: ({
      ...{| __typename: 'Partner' |},
    ...$Pick<Partner, { id: *, updatedAt: *, name?: *, code?: *, types: * }>,
    ...{ updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, tel?: *, country?: *, region?: *, locality?: *, street?: *, postalCode?: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |} }
});

export type PartnerListQueryVariables = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?PartnerFilterInput,
  sortBy?: ?PartnerSortInput
};


export type PartnerListQuery = ({
    ...{| __typename: 'Query' |},
  ...{ viewer: ({
      ...{| __typename: 'Viewer' |},
    ...{ user: ({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, types: * }>,
        ...{ partners: ({
            ...{| __typename: 'PartnerPayloadPaginatedSearch' |},
          ...$Pick<PartnerPayloadPaginatedSearch, { page: *, totalPage: * }>,
          ...{ nodes: Array<({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) }
});

export type PartnersExportQueryVariables = {
  templateId: $ElementType<Scalars, 'ID'>,
  filterBy?: ?PartnerFilterInput,
  sortBy?: ?PartnerSortInput,
  localSortBy?: ?Array<GenericSortInput>,
  columns?: ?Array<$ElementType<Scalars, 'String'>>
};


export type PartnersExportQuery = ({
    ...{| __typename: 'Query' |},
  ...{ partnersExport: ({
      ...{| __typename: 'Export' |},
    ...$Pick<Export, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type RelationPartnersQueryVariables = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?OrganizationFilterInput
};


export type RelationPartnersQuery = ({
    ...{| __typename: 'Query' |},
  ...{ viewer: ({
      ...{| __typename: 'Viewer' |},
    ...{ user: ({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>,
        ...{ relationPartners: ({
            ...{| __typename: 'OrganizationPayloadPaginatedList' |},
          ...$Pick<OrganizationPayloadPaginatedList, { page: *, totalPage: * }>,
          ...{ nodes: Array<({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) }
});

export type ProductCardFragment = ({
    ...{| __typename: 'Product' |},
  ...$Pick<Product, { id: *, archived: *, name: *, serial: * }>,
  ...{ productProviders: Array<({
      ...{| __typename: 'ProductProvider' |},
    ...$Pick<ProductProvider, { id: *, referenced: * }>,
    ...{ exporter: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>,
      ...{ partner?: ?({
          ...{| __typename: 'Partner' |},
        ...$Pick<Partner, { id: *, name?: *, code?: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>,
      ...{ partner?: ?({
          ...{| __typename: 'Partner' |},
        ...$Pick<Partner, { id: *, name?: *, code?: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, files: Array<({
      ...{| __typename: 'File' |},
    ...$Pick<File, { id: *, path: * }>,
    ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type ProductCardWithOwnedFragment = ({
    ...{| __typename: 'Product' |},
  ...$Pick<Product, { id: *, archived: *, name: *, serial: * }>,
  ...{ productProviders: Array<({
      ...{| __typename: 'ProductProvider' |},
    ...$Pick<ProductProvider, { id: *, referenced: * }>,
    ...{ exporter: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>,
      ...{ partner?: ?({
          ...{| __typename: 'Partner' |},
        ...$Pick<Partner, { id: *, name?: *, code?: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>,
      ...{ partner?: ?({
          ...{| __typename: 'Partner' |},
        ...$Pick<Partner, { id: *, name?: *, code?: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, files: Array<({
      ...{| __typename: 'File' |},
    ...$Pick<File, { id: *, path: * }>,
    ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type ProductFormFragment = ({
    ...{| __typename: 'Product' |},
  ...$Pick<Product, { id: *, archived: *, updatedAt: *, name: *, serial: *, hsCode?: *, janCode?: *, material?: *, memo?: *, notificationUnseenCount: * }>,
  ...{ updatedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
      ...{| __typename: 'CustomFields' |},
    ...{ fieldValues: Array<({
        ...{| __typename: 'FieldValue' |},
      ...{ value: ({
          ...{| __typename: 'StringValue' |},
        ...$Pick<StringValue, { string: * }>
      }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }), tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, files: Array<({
      ...{| __typename: 'File' |},
    ...$Pick<File, { id: *, path: * }>,
    ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, productProviders: Array<({
      ...{| __typename: 'ProductProvider' |},
    ...$Pick<ProductProvider, { id: *, name: *, archived: *, updatedAt: *, referenced: *, origin?: *, unitType?: *, autoCalculateUnitVolume: *, memo?: * }>,
    ...{ updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, organizations: Array<({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, importers: Array<({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, exporter: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, unitPrice?: ?({
        ...{| __typename: 'NewPrice' |},
      ...$Pick<NewPrice, { amount: *, currency: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, unitWeight?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), unitVolume?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), unitSize?: ?({
        ...{| __typename: 'Size' |},
      ...{ length: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), width: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), height: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }) }
    }), defaultPackage?: ?({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
      ...{ grossWeight?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), volume?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), size?: ?({
          ...{| __typename: 'Size' |},
        ...{ length: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), width: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), height: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }) }
      }) }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
      ...{ grossWeight?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), volume?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), size?: ?({
          ...{| __typename: 'Size' |},
        ...{ length: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), width: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), height: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }) }
      }) }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, customFields: ({
        ...{| __typename: 'CustomFields' |},
      ...{ fieldValues: Array<({
          ...{| __typename: 'FieldValue' |},
        ...{ value: ({
            ...{| __typename: 'StringValue' |},
          ...$Pick<StringValue, { string: * }>
        }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }), files: Array<({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
      ...{ tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, entity?: ?({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, poNo: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | ({
          ...{| __typename: 'Partnership' |},
        ...$Pick<Partnership, { id: * }>
      }) | ({
          ...{| __typename: 'Role' |},
        ...$Pick<Role, { id: * }>
      }) | ({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Mask' |},
        ...$Pick<Mask, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'MaskEdit' |},
        ...$Pick<MaskEdit, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, notificationUnseen: Array<({
      ...{| __typename: 'Notification' |},
    ...$Pick<Notification, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type ProductCreateMutationVariables = {
  input: ProductCreateInput
};


export type ProductCreateMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ productCreate: ({
      ...{| __typename: 'Product' |},
    ...$Pick<Product, { id: * }>
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |} }
});

export type ProductUpdateMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  input: ProductUpdateInput
};


export type ProductUpdateMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ productUpdate: ({
      ...{| __typename: 'Product' |},
    ...$Pick<Product, { id: *, archived: *, updatedAt: *, name: *, serial: *, hsCode?: *, janCode?: *, material?: *, memo?: *, notificationUnseenCount: * }>,
    ...{ updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
        ...{| __typename: 'CustomFields' |},
      ...{ fieldValues: Array<({
          ...{| __typename: 'FieldValue' |},
        ...{ value: ({
            ...{| __typename: 'StringValue' |},
          ...$Pick<StringValue, { string: * }>
        }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }), tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, files: Array<({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: *, path: * }>,
      ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, productProviders: Array<({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: *, name: *, archived: *, updatedAt: *, referenced: *, origin?: *, unitType?: *, autoCalculateUnitVolume: *, memo?: * }>,
      ...{ updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, organizations: Array<({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, importers: Array<({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, exporter: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, unitPrice?: ?({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, unitWeight?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), unitVolume?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), unitSize?: ?({
          ...{| __typename: 'Size' |},
        ...{ length: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), width: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), height: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }) }
      }), defaultPackage?: ?({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
        ...{ grossWeight?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), volume?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), size?: ?({
            ...{| __typename: 'Size' |},
          ...{ length: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), width: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), height: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }) }
        }) }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
        ...{ grossWeight?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), volume?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), size?: ?({
            ...{| __typename: 'Size' |},
          ...{ length: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), width: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), height: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }) }
        }) }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, customFields: ({
          ...{| __typename: 'CustomFields' |},
        ...{ fieldValues: Array<({
            ...{| __typename: 'FieldValue' |},
          ...{ value: ({
              ...{| __typename: 'StringValue' |},
            ...$Pick<StringValue, { string: * }>
          }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }), files: Array<({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
        ...{ tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, entity?: ?({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: *, name: * }>,
          ...{ product: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: *, poNo: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | ({
            ...{| __typename: 'Partnership' |},
          ...$Pick<Partnership, { id: * }>
        }) | ({
            ...{| __typename: 'Role' |},
          ...$Pick<Role, { id: * }>
        }) | ({
            ...{| __typename: 'File' |},
          ...$Pick<File, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Mask' |},
          ...$Pick<Mask, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'MaskEdit' |},
          ...$Pick<MaskEdit, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, notificationUnseen: Array<({
        ...{| __typename: 'Notification' |},
      ...$Pick<Notification, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |} }
});

export type ProductBatchesQueryVariables = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?BatchFilterInput,
  sortBy?: ?BatchSortInput
};


export type ProductBatchesQuery = ({
    ...{| __typename: 'Query' |},
  ...{ batches: ({
      ...{| __typename: 'BatchPayloadPaginatedSearch' |},
    ...$Pick<BatchPayloadPaginatedSearch, { totalCount: *, page: *, totalPage: * }>,
    ...{ nodes: Array<({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: *, no: *, archived: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: *, deliveredAt?: *, desiredAt?: *, packageQuantity?: * }>,
      ...{ totalPrice?: ?({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), packageVolume?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, shipment?: ?({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, orderItem: ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: * }>,
        ...{ price: ({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, order: ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: *, poNo: *, currency: * }>,
          ...{ importer: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: *, name: * }>,
          ...{ product: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: *, serial: * }>,
            ...{ files: Array<({
                ...{| __typename: 'File' |},
              ...$Pick<File, { id: *, path: * }>,
              ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) }
});

export type ProductContainersQueryVariables = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?ContainerFilterInput,
  sortBy?: ?ContainerSortInput
};


export type ProductContainersQuery = ({
    ...{| __typename: 'Query' |},
  ...{ containers: ({
      ...{| __typename: 'ContainerPayloadPaginatedSearch' |},
    ...$Pick<ContainerPayloadPaginatedSearch, { totalCount: *, page: *, totalPage: * }>,
    ...{ nodes: Array<({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: *, archived: *, no: *, containerType?: *, containerOption?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: *, freeTimeStartDate?: *, freeTimeDuration?: * }>,
      ...{ representativeBatch?: ?({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>,
        ...{ orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>,
          ...{ productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>,
            ...{ product: ({
                ...{| __typename: 'Product' |},
              ...$Pick<Product, { id: *, name: *, serial: * }>,
              ...{ files: Array<({
                  ...{| __typename: 'File' |},
                ...$Pick<File, { id: *, path: * }>,
                ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>,
        ...{ importer?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) }
});

export type ProductExportQueryVariables = {
  id: $ElementType<Scalars, 'ID'>,
  templateId: $ElementType<Scalars, 'ID'>
};


export type ProductExportQuery = ({
    ...{| __typename: 'Query' |},
  ...{ productExport: ({
      ...{| __typename: 'Export' |},
    ...$Pick<Export, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type ProductFormQueryVariables = {
  id: $ElementType<Scalars, 'ID'>
};


export type ProductFormQuery = ({
    ...{| __typename: 'Query' |},
  ...{ product: ({
      ...{| __typename: 'Product' |},
    ...$Pick<Product, { id: *, archived: *, updatedAt: *, name: *, serial: *, hsCode?: *, janCode?: *, material?: *, memo?: *, notificationUnseenCount: * }>,
    ...{ updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
        ...{| __typename: 'CustomFields' |},
      ...{ fieldValues: Array<({
          ...{| __typename: 'FieldValue' |},
        ...{ value: ({
            ...{| __typename: 'StringValue' |},
          ...$Pick<StringValue, { string: * }>
        }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }), tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, files: Array<({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: *, path: * }>,
      ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, productProviders: Array<({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: *, name: *, archived: *, updatedAt: *, referenced: *, origin?: *, unitType?: *, autoCalculateUnitVolume: *, memo?: * }>,
      ...{ updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, organizations: Array<({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, importers: Array<({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, exporter: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, unitPrice?: ?({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, unitWeight?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), unitVolume?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), unitSize?: ?({
          ...{| __typename: 'Size' |},
        ...{ length: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), width: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), height: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }) }
      }), defaultPackage?: ?({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
        ...{ grossWeight?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), volume?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), size?: ?({
            ...{| __typename: 'Size' |},
          ...{ length: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), width: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), height: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }) }
        }) }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
        ...{ grossWeight?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), volume?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), size?: ?({
            ...{| __typename: 'Size' |},
          ...{ length: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), width: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), height: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }) }
        }) }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, customFields: ({
          ...{| __typename: 'CustomFields' |},
        ...{ fieldValues: Array<({
            ...{| __typename: 'FieldValue' |},
          ...{ value: ({
              ...{| __typename: 'StringValue' |},
            ...$Pick<StringValue, { string: * }>
          }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }), files: Array<({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
        ...{ tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, entity?: ?({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: *, name: * }>,
          ...{ product: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: *, poNo: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | ({
            ...{| __typename: 'Partnership' |},
          ...$Pick<Partnership, { id: * }>
        }) | ({
            ...{| __typename: 'Role' |},
          ...$Pick<Role, { id: * }>
        }) | ({
            ...{| __typename: 'File' |},
          ...$Pick<File, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Mask' |},
          ...$Pick<Mask, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'MaskEdit' |},
          ...$Pick<MaskEdit, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, notificationUnseen: Array<({
        ...{| __typename: 'Notification' |},
      ...$Pick<Notification, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |} }
});

export type ProductListQueryVariables = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?ProductFilterInput,
  sortBy?: ?ProductSortInput
};


export type ProductListQuery = ({
    ...{| __typename: 'Query' |},
  ...{ products: ({
      ...{| __typename: 'ProductPayloadPaginatedSearch' |},
    ...$Pick<ProductPayloadPaginatedSearch, { page: *, totalPage: * }>,
    ...{ nodes: Array<({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { notificationUnseenCount: *, id: *, archived: *, name: *, serial: * }>,
      ...{ notificationUnseen: Array<({
          ...{| __typename: 'Notification' |},
        ...$Pick<Notification, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, productProviders: Array<({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, referenced: * }>,
        ...{ exporter: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, files: Array<({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: *, path: * }>,
        ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | ({
        ...{| __typename: 'NotFound' |},
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: *, type: *, service: * }>
      }) }
    })> }
  }) }
});

export type ProductOrderItemsQueryVariables = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?OrderItemFilterInput,
  sortBy?: ?OrderItemSortInput
};


export type ProductOrderItemsQuery = ({
    ...{| __typename: 'Query' |},
  ...{ orderItems: ({
      ...{| __typename: 'OrderItemPayloadPaginatedSearch' |},
    ...$Pick<OrderItemPayloadPaginatedSearch, { totalCount: *, page: *, totalPage: * }>,
    ...{ nodes: Array<({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
      ...{ price: ({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, order: ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, poNo: * }>,
        ...{ importer: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: *, serial: * }>,
          ...{ tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, files: Array<({
              ...{| __typename: 'File' |},
            ...$Pick<File, { id: *, path: * }>,
            ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) }
});

export type ProductOrdersQueryVariables = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?OrderFilterInput,
  sortBy?: ?OrderSortInput
};


export type ProductOrdersQuery = ({
    ...{| __typename: 'Query' |},
  ...{ orders: ({
      ...{| __typename: 'OrderPayloadPaginatedSearch' |},
    ...$Pick<OrderPayloadPaginatedSearch, { totalCount: *, page: *, totalPage: * }>,
    ...{ nodes: Array<({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
      ...{ totalPrice: ({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, timeline: ({
          ...{| __typename: 'Timeline' |},
        ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
      }), ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
          ...{| __typename: 'Notification' |},
        ...$Pick<Notification, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) }
});

export type ProductShipmentsQueryVariables = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?ShipmentFilterInput,
  sortBy?: ?ShipmentSortInput
};


export type ProductShipmentsQuery = ({
    ...{| __typename: 'Query' |},
  ...{ shipments: ({
      ...{| __typename: 'ShipmentPayloadPaginatedSearch' |},
    ...$Pick<ShipmentPayloadPaginatedSearch, { totalCount: *, page: *, totalPage: * }>,
    ...{ nodes: Array<({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
      ...{ totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalVolumeOverride?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), timeline: ({
          ...{| __typename: 'Timeline' |},
        ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
      }), containerTypeCounts: Array<({
          ...{| __typename: 'ContainerTypeCount' |},
        ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
      })>, importer?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, voyages: Array<({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
        ...{ departurePort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), arrivalPort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), departure: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ customClearance: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
        ...{ warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>,
        ...{ orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>,
          ...{ productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>,
            ...{ exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
          ...{| __typename: 'Notification' |},
        ...$Pick<Notification, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) }
});

export type ProductsQueryVariables = {
  filterBy?: ?ProductFilterInput,
  sortBy?: ?ProductSortInput,
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>
};


export type ProductsQuery = ({
    ...{| __typename: 'Query' |},
  ...{ products: ({
      ...{| __typename: 'ProductPayloadPaginatedSearch' |},
    ...$Pick<ProductPayloadPaginatedSearch, { page: *, totalPage: * }>,
    ...{ nodes: Array<({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: *, name: *, serial: * }>,
      ...{ tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, files: Array<({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: *, path: * }>,
        ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, productProviders: Array<({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: *, archived: *, referenced: * }>,
        ...{ exporter: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, unitPrice?: ?({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}> }
  }) }
});

export type ProductsByIDsQueryVariables = {
  ids: Array<$ElementType<Scalars, 'ID'>>
};


export type ProductsByIDsQuery = ({
    ...{| __typename: 'Query' |},
  ...{ productsByIDs: Array<({
      ...{| __typename: 'Product' |},
    ...$Pick<Product, { id: *, name: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type ProductsDocumentFolderViewQueryVariables = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?ProductFilterInput
};


export type ProductsDocumentFolderViewQuery = ({
    ...{| __typename: 'Query' |},
  ...{ products: ({
      ...{| __typename: 'ProductPayloadPaginatedSearch' |},
    ...$Pick<ProductPayloadPaginatedSearch, { page: *, totalPage: * }>,
    ...{ nodes: Array<({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: *, name: * }>
    }) | ({
        ...{| __typename: 'BadRequest' |},
      ...{ violations: Array<({
          ...{| __typename: 'Violation' |},
        ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
        ...{ parameters: Array<({
            ...{| __typename: 'ViolationParameter' |},
          ...$Pick<ViolationParameter, { key: *, value: * }>
        })> }
      })> }
    }) | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | ({
        ...{| __typename: 'NotFound' |},
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: *, type: *, service: * }>
      }) }
    })> }
  }) }
});

export type ProductsExportQueryVariables = {
  filterBy?: ?ProductFilterInput,
  sortBy?: ?ProductSortInput,
  templateId: $ElementType<Scalars, 'ID'>
};


export type ProductsExportQuery = ({
    ...{| __typename: 'Query' |},
  ...{ productsExport: ({
      ...{| __typename: 'Export' |},
    ...$Pick<Export, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type ProductsListQueryVariables = {
  filterBy?: ?ProductFilterInput,
  sortBy?: ?ProductSortInput,
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>
};


export type ProductsListQuery = ({
    ...{| __typename: 'Query' |},
  ...{ products: ({
      ...{| __typename: 'ProductPayloadPaginatedSearch' |},
    ...$Pick<ProductPayloadPaginatedSearch, { page: *, totalPage: * }>,
    ...{ nodes: Array<({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: *, archived: *, name: *, serial: * }>,
      ...{ productProviders: Array<({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, referenced: * }>,
        ...{ exporter: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, files: Array<({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: *, path: * }>,
        ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}> }
  }) }
});

export type ProductProviderCardFragment = ({
    ...{| __typename: 'ProductProvider' |},
  ...$Pick<ProductProvider, { id: *, archived: *, name: * }>,
  ...{ exporter: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>,
    ...{ partner?: ?({
        ...{| __typename: 'Partner' |},
      ...$Pick<Partner, { id: *, name?: *, code?: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>,
    ...{ partner?: ?({
        ...{| __typename: 'Partner' |},
      ...$Pick<Partner, { id: *, name?: *, code?: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, unitPrice?: ?({
      ...{| __typename: 'NewPrice' |},
    ...$Pick<NewPrice, { amount: *, currency: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, product: ({
      ...{| __typename: 'Product' |},
    ...$Pick<Product, { id: *, name: *, serial: * }>,
    ...{ tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, files: Array<({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: *, path: * }>,
      ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
      ...{| __typename: 'ProductProviderPackage' |},
    ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
    ...{ grossWeight?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), volume?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), size?: ?({
        ...{| __typename: 'Size' |},
      ...{ length: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), width: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), height: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }) }
    }) }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
      ...{| __typename: 'ProductProviderPackage' |},
    ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
    ...{ grossWeight?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), volume?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), size?: ?({
        ...{| __typename: 'Size' |},
      ...{ length: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), width: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), height: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }) }
    }) }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type ProductProviderFormFragment = ({
    ...{| __typename: 'ProductProvider' |},
  ...$Pick<ProductProvider, { id: *, name: *, archived: *, updatedAt: *, referenced: *, origin?: *, unitType?: *, autoCalculateUnitVolume: *, memo?: * }>,
  ...{ updatedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, organizations: Array<({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, importers: Array<({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, exporter: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, unitPrice?: ?({
      ...{| __typename: 'NewPrice' |},
    ...$Pick<NewPrice, { amount: *, currency: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, unitWeight?: ?({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), unitVolume?: ?({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), unitSize?: ?({
      ...{| __typename: 'Size' |},
    ...{ length: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), width: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), height: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }) }
  }), defaultPackage?: ?({
      ...{| __typename: 'ProductProviderPackage' |},
    ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
    ...{ grossWeight?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), volume?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), size?: ?({
        ...{| __typename: 'Size' |},
      ...{ length: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), width: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), height: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }) }
    }) }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
      ...{| __typename: 'ProductProviderPackage' |},
    ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
    ...{ grossWeight?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), volume?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), size?: ?({
        ...{| __typename: 'Size' |},
      ...{ length: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), width: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), height: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }) }
    }) }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, customFields: ({
      ...{| __typename: 'CustomFields' |},
    ...{ fieldValues: Array<({
        ...{| __typename: 'FieldValue' |},
      ...{ value: ({
          ...{| __typename: 'StringValue' |},
        ...$Pick<StringValue, { string: * }>
      }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }), files: Array<({
      ...{| __typename: 'File' |},
    ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
    ...{ tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, entity?: ?({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: *, name: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: *, name: * }>,
      ...{ product: ({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: *, poNo: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: * }>
    }) | ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | ({
        ...{| __typename: 'Partnership' |},
      ...$Pick<Partnership, { id: * }>
    }) | ({
        ...{| __typename: 'Role' |},
      ...$Pick<Role, { id: * }>
    }) | ({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Mask' |},
      ...$Pick<Mask, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'MaskEdit' |},
      ...$Pick<MaskEdit, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'FieldDefinition' |},
      ...$Pick<FieldDefinition, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type ProductProviderPackagingFragment = ({
    ...{| __typename: 'ProductProviderPackage' |},
  ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
  ...{ grossWeight?: ?({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), volume?: ?({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), size?: ?({
      ...{| __typename: 'Size' |},
    ...{ length: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), width: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), height: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }) }
  }) }
});

export type ProductProviderUpdateMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  input: ProductProviderUpdateInput
};


export type ProductProviderUpdateMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ productProviderUpdate: ({
      ...{| __typename: 'ProductProvider' |},
    ...$Pick<ProductProvider, { id: *, name: *, archived: *, updatedAt: *, referenced: *, origin?: *, unitType?: *, autoCalculateUnitVolume: *, memo?: * }>,
    ...{ updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, organizations: Array<({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, importers: Array<({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, exporter: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, unitPrice?: ?({
        ...{| __typename: 'NewPrice' |},
      ...$Pick<NewPrice, { amount: *, currency: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, unitWeight?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), unitVolume?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), unitSize?: ?({
        ...{| __typename: 'Size' |},
      ...{ length: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), width: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), height: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }) }
    }), defaultPackage?: ?({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
      ...{ grossWeight?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), volume?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), size?: ?({
          ...{| __typename: 'Size' |},
        ...{ length: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), width: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), height: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }) }
      }) }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
      ...{ grossWeight?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), volume?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), size?: ?({
          ...{| __typename: 'Size' |},
        ...{ length: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), width: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), height: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }) }
      }) }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, customFields: ({
        ...{| __typename: 'CustomFields' |},
      ...{ fieldValues: Array<({
          ...{| __typename: 'FieldValue' |},
        ...{ value: ({
            ...{| __typename: 'StringValue' |},
          ...$Pick<StringValue, { string: * }>
        }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }), files: Array<({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
      ...{ tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, entity?: ?({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, poNo: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | ({
          ...{| __typename: 'Partnership' |},
        ...$Pick<Partnership, { id: * }>
      }) | ({
          ...{| __typename: 'Role' |},
        ...$Pick<Role, { id: * }>
      }) | ({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Mask' |},
        ...$Pick<Mask, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'MaskEdit' |},
        ...$Pick<MaskEdit, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |} }
});

export type ProductProviderTimelineQueryVariables = {
  id: $ElementType<Scalars, 'ID'>,
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>
};


export type ProductProviderTimelineQuery = ({
    ...{| __typename: 'Query' |},
  ...{ productProvider: ({
      ...{| __typename: 'ProductProvider' |},
    ...$Pick<ProductProvider, { id: * }>,
    ...{ timeline: ({
        ...{| __typename: 'Timeline' |},
      ...{ entries: ({
          ...{| __typename: 'TimelineEntryPaginatedList' |},
        ...$Pick<TimelineEntryPaginatedList, { page: *, totalPage: * }>,
        ...{ nodes: Array<({
            ...{| __typename: 'TimelineEvent' |},
          ...$Pick<TimelineEvent, { id: *, createdAt: * }>,
          ...{ entity: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: * }>
          }) | ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>
          }) | ({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: * }>
          }) | ({
              ...{| __typename: 'Order' |},
            ...$Pick<Order, { id: * }>
          }) | ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>
          }) | ({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: * }>
          }) | ({
              ...{| __typename: 'Shipment' |},
            ...$Pick<Shipment, { id: * }>
          }) | ({
              ...{| __typename: 'Voyage' |},
            ...$Pick<Voyage, { id: * }>
          }) | ({
              ...{| __typename: 'Container' |},
            ...$Pick<Container, { id: * }>
          }) | ({
              ...{| __typename: 'ContainerGroup' |},
            ...$Pick<ContainerGroup, { id: * }>
          }) | ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: * }>
          }) | ({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: * }>
          }) | ({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: * }>
          }) | ({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: * }>
          }) | ({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: * }>
          }) | ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: * }>
          }) | ({
              ...{| __typename: 'Partnership' |},
            ...$Pick<Partnership, { id: * }>
          }) | ({
              ...{| __typename: 'Role' |},
            ...$Pick<Role, { id: * }>
          }) | ({
              ...{| __typename: 'File' |},
            ...$Pick<File, { id: * }>
          }) | ({
              ...{| __typename: 'Mask' |},
            ...$Pick<Mask, { id: * }>
          }) | ({
              ...{| __typename: 'MaskEdit' |},
            ...$Pick<MaskEdit, { id: * }>
          }) | ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: * }>
          }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, createdBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, logs: Array<({
              ...{| __typename: 'Log' |},
            ...$Pick<Log, { id: *, translationKey: * }>,
            ...{ entity: ({
                ...{| __typename: 'Product' |},
              ...$Pick<Product, { id: * }>
            }) | ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>
            }) | ({
                ...{| __typename: 'ProductProviderPackage' |},
              ...$Pick<ProductProviderPackage, { id: * }>
            }) | ({
                ...{| __typename: 'Order' |},
              ...$Pick<Order, { id: * }>
            }) | ({
                ...{| __typename: 'OrderItem' |},
              ...$Pick<OrderItem, { id: * }>
            }) | ({
                ...{| __typename: 'Batch' |},
              ...$Pick<Batch, { id: * }>
            }) | ({
                ...{| __typename: 'Shipment' |},
              ...$Pick<Shipment, { id: * }>
            }) | ({
                ...{| __typename: 'Voyage' |},
              ...$Pick<Voyage, { id: * }>
            }) | ({
                ...{| __typename: 'Container' |},
              ...$Pick<Container, { id: * }>
            }) | ({
                ...{| __typename: 'ContainerGroup' |},
              ...$Pick<ContainerGroup, { id: * }>
            }) | ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: * }>
            }) | ({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: * }>
            }) | ({
                ...{| __typename: 'Warehouse' |},
              ...$Pick<Warehouse, { id: * }>
            }) | ({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: * }>
            }) | ({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: * }>
            }) | ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: * }>
            }) | ({
                ...{| __typename: 'Partnership' |},
              ...$Pick<Partnership, { id: * }>
            }) | ({
                ...{| __typename: 'Role' |},
              ...$Pick<Role, { id: * }>
            }) | ({
                ...{| __typename: 'File' |},
              ...$Pick<File, { id: *, type: * }>
            }) | ({
                ...{| __typename: 'Mask' |},
              ...$Pick<Mask, { id: * }>
            }) | ({
                ...{| __typename: 'MaskEdit' |},
              ...$Pick<MaskEdit, { id: * }>
            }) | ({
                ...{| __typename: 'FieldDefinition' |},
              ...$Pick<FieldDefinition, { id: * }>
            }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, parameters: Array<({
                ...{| __typename: 'Parameter' |},
              ...$Pick<Parameter, { key: * }>,
              ...{ value?: ?({
                  ...{| __typename: 'StringValue' |},
                ...$Pick<StringValue, { string: * }>
              }) | ({
                  ...{| __typename: 'IntValue' |},
                ...$Pick<IntValue, { int: * }>
              }) | ({
                  ...{| __typename: 'FloatValue' |},
                ...$Pick<FloatValue, { float: * }>
              }) | ({
                  ...{| __typename: 'BooleanValue' |},
                ...$Pick<BooleanValue, { boolean: * }>
              }) | ({
                  ...{| __typename: 'DateTimeValue' |},
                ...$Pick<DateTimeValue, { datetime: * }>
              }) | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | ({
                  ...{| __typename: 'MetricValueValue' |},
                ...{ metricValue: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }) }
              }) | ({
                  ...{| __typename: 'PriceValue' |},
                ...{ priceValue: ({
                    ...{| __typename: 'NewPrice' |},
                  ...$Pick<NewPrice, { amount: *, currency: * }>
                }) }
              }) | ({
                  ...{| __typename: 'SizeValue' |},
                ...{ size: ({
                    ...{| __typename: 'Size' |},
                  ...{ height: ({
                      ...{| __typename: 'MetricValue' |},
                    ...$Pick<MetricValue, { value: *, metric: * }>
                  }), length: ({
                      ...{| __typename: 'MetricValue' |},
                    ...$Pick<MetricValue, { value: *, metric: * }>
                  }), width: ({
                      ...{| __typename: 'MetricValue' |},
                    ...$Pick<MetricValue, { value: *, metric: * }>
                  }) }
                }) }
              }) | ({
                  ...{| __typename: 'EntityValue' |},
                ...{ entity?: ?({
                    ...{| __typename: 'Product' |},
                  ...$Pick<Product, { id: * }>
                }) | ({
                    ...{| __typename: 'ProductProvider' |},
                  ...$Pick<ProductProvider, { id: * }>
                }) | ({
                    ...{| __typename: 'ProductProviderPackage' |},
                  ...$Pick<ProductProviderPackage, { id: * }>
                }) | ({
                    ...{| __typename: 'Order' |},
                  ...$Pick<Order, { id: * }>
                }) | ({
                    ...{| __typename: 'OrderItem' |},
                  ...$Pick<OrderItem, { id: * }>
                }) | ({
                    ...{| __typename: 'Batch' |},
                  ...$Pick<Batch, { id: * }>
                }) | ({
                    ...{| __typename: 'Shipment' |},
                  ...$Pick<Shipment, { id: * }>
                }) | ({
                    ...{| __typename: 'Voyage' |},
                  ...$Pick<Voyage, { id: * }>
                }) | ({
                    ...{| __typename: 'Container' |},
                  ...$Pick<Container, { id: * }>
                }) | ({
                    ...{| __typename: 'ContainerGroup' |},
                  ...$Pick<ContainerGroup, { id: * }>
                }) | ({
                    ...{| __typename: 'TimelineDate' |},
                  ...$Pick<TimelineDate, { id: * }>
                }) | ({
                    ...{| __typename: 'TimelineDateRevision' |},
                  ...$Pick<TimelineDateRevision, { id: * }>
                }) | ({
                    ...{| __typename: 'Warehouse' |},
                  ...$Pick<Warehouse, { id: * }>
                }) | ({
                    ...{| __typename: 'Tag' |},
                  ...$Pick<Tag, { id: *, name: *, color: * }>
                }) | ({
                    ...{| __typename: 'User' |},
                  ...$Pick<User, { id: *, firstName: *, lastName: * }>
                }) | ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: *, types: * }>,
                  ...{ partner?: ?({
                      ...{| __typename: 'Partner' |},
                    ...$Pick<Partner, { id: *, name?: *, code?: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
                }) | ({
                    ...{| __typename: 'Partnership' |},
                  ...$Pick<Partnership, { id: * }>
                }) | ({
                    ...{| __typename: 'Role' |},
                  ...$Pick<Role, { id: * }>
                }) | ({
                    ...{| __typename: 'File' |},
                  ...$Pick<File, { id: * }>
                }) | ({
                    ...{| __typename: 'Mask' |},
                  ...$Pick<Mask, { id: * }>
                }) | ({
                    ...{| __typename: 'MaskEdit' |},
                  ...$Pick<MaskEdit, { id: * }>
                }) | ({
                    ...{| __typename: 'FieldDefinition' |},
                  ...$Pick<FieldDefinition, { id: * }>
                }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | ({
                  ...{| __typename: 'Values' |},
                ...{ values: Array<?({
                    ...{| __typename: 'StringValue' |},
                  ...$Pick<StringValue, { string: * }>
                }) | ({
                    ...{| __typename: 'IntValue' |},
                  ...$Pick<IntValue, { int: * }>
                }) | ({
                    ...{| __typename: 'FloatValue' |},
                  ...$Pick<FloatValue, { float: * }>
                }) | ({
                    ...{| __typename: 'BooleanValue' |},
                  ...$Pick<BooleanValue, { boolean: * }>
                }) | ({
                    ...{| __typename: 'DateTimeValue' |},
                  ...$Pick<DateTimeValue, { datetime: * }>
                }) | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | ({
                    ...{| __typename: 'MetricValueValue' |},
                  ...{ metricValue: ({
                      ...{| __typename: 'MetricValue' |},
                    ...$Pick<MetricValue, { value: *, metric: * }>
                  }) }
                }) | ({
                    ...{| __typename: 'PriceValue' |},
                  ...{ priceValue: ({
                      ...{| __typename: 'NewPrice' |},
                    ...$Pick<NewPrice, { amount: *, currency: * }>
                  }) }
                }) | ({
                    ...{| __typename: 'SizeValue' |},
                  ...{ size: ({
                      ...{| __typename: 'Size' |},
                    ...{ height: ({
                        ...{| __typename: 'MetricValue' |},
                      ...$Pick<MetricValue, { value: *, metric: * }>
                    }), length: ({
                        ...{| __typename: 'MetricValue' |},
                      ...$Pick<MetricValue, { value: *, metric: * }>
                    }), width: ({
                        ...{| __typename: 'MetricValue' |},
                      ...$Pick<MetricValue, { value: *, metric: * }>
                    }) }
                  }) }
                }) | ({
                    ...{| __typename: 'EntityValue' |},
                  ...{ entity?: ?({
                      ...{| __typename: 'Product' |},
                    ...$Pick<Product, { id: * }>
                  }) | ({
                      ...{| __typename: 'ProductProvider' |},
                    ...$Pick<ProductProvider, { id: * }>
                  }) | ({
                      ...{| __typename: 'ProductProviderPackage' |},
                    ...$Pick<ProductProviderPackage, { id: * }>
                  }) | ({
                      ...{| __typename: 'Order' |},
                    ...$Pick<Order, { id: * }>
                  }) | ({
                      ...{| __typename: 'OrderItem' |},
                    ...$Pick<OrderItem, { id: * }>
                  }) | ({
                      ...{| __typename: 'Batch' |},
                    ...$Pick<Batch, { id: * }>
                  }) | ({
                      ...{| __typename: 'Shipment' |},
                    ...$Pick<Shipment, { id: * }>
                  }) | ({
                      ...{| __typename: 'Voyage' |},
                    ...$Pick<Voyage, { id: * }>
                  }) | ({
                      ...{| __typename: 'Container' |},
                    ...$Pick<Container, { id: * }>
                  }) | ({
                      ...{| __typename: 'ContainerGroup' |},
                    ...$Pick<ContainerGroup, { id: * }>
                  }) | ({
                      ...{| __typename: 'TimelineDate' |},
                    ...$Pick<TimelineDate, { id: * }>
                  }) | ({
                      ...{| __typename: 'TimelineDateRevision' |},
                    ...$Pick<TimelineDateRevision, { id: * }>
                  }) | ({
                      ...{| __typename: 'Warehouse' |},
                    ...$Pick<Warehouse, { id: * }>
                  }) | ({
                      ...{| __typename: 'Tag' |},
                    ...$Pick<Tag, { id: *, name: *, color: * }>
                  }) | ({
                      ...{| __typename: 'User' |},
                    ...$Pick<User, { id: *, firstName: *, lastName: * }>
                  }) | ({
                      ...{| __typename: 'Organization' |},
                    ...$Pick<Organization, { id: *, name: *, types: * }>,
                    ...{ partner?: ?({
                        ...{| __typename: 'Partner' |},
                      ...$Pick<Partner, { id: *, name?: *, code?: * }>
                    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
                  }) | ({
                      ...{| __typename: 'Partnership' |},
                    ...$Pick<Partnership, { id: * }>
                  }) | ({
                      ...{| __typename: 'Role' |},
                    ...$Pick<Role, { id: * }>
                  }) | ({
                      ...{| __typename: 'File' |},
                    ...$Pick<File, { id: * }>
                  }) | ({
                      ...{| __typename: 'Mask' |},
                    ...$Pick<Mask, { id: * }>
                  }) | ({
                      ...{| __typename: 'MaskEdit' |},
                    ...$Pick<MaskEdit, { id: * }>
                  }) | ({
                      ...{| __typename: 'FieldDefinition' |},
                    ...$Pick<FieldDefinition, { id: * }>
                  }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
                }) | {| __typename: 'Values' |}> }
              }) }
            })> }
          })> }
        }) | ({
            ...{| __typename: 'TimelineComment' |},
          ...$Pick<TimelineComment, { id: *, content: *, createdAt: *, updatedAt: * }>,
          ...{ createdBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) }
    }) }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type ProductProvidersByIDsQueryVariables = {
  ids: Array<$ElementType<Scalars, 'ID'>>
};


export type ProductProvidersByIDsQuery = ({
    ...{| __typename: 'Query' |},
  ...{ productProvidersByIDs: Array<({
      ...{| __typename: 'ProductProvider' |},
    ...$Pick<ProductProvider, { id: *, name: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type ProductProvidersByIDsDocumentsQueryVariables = {
  ids: Array<$ElementType<Scalars, 'ID'>>
};


export type ProductProvidersByIDsDocumentsQuery = ({
    ...{| __typename: 'Query' |},
  ...{ productProvidersByIDs: Array<({
      ...{| __typename: 'ProductProvider' |},
    ...{ files: Array<({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
      ...{ tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, entity?: ?({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, poNo: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | ({
          ...{| __typename: 'Partnership' |},
        ...$Pick<Partnership, { id: * }>
      }) | ({
          ...{| __typename: 'Role' |},
        ...$Pick<Role, { id: * }>
      }) | ({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Mask' |},
        ...$Pick<Mask, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'MaskEdit' |},
        ...$Pick<MaskEdit, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type ProductProvidersListQueryVariables = {
  filterBy?: ?ProductProviderFilterInput,
  sortBy?: ?ProductProviderSortInput,
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>
};


export type ProductProvidersListQuery = ({
    ...{| __typename: 'Query' |},
  ...{ productProviders: ({
      ...{| __typename: 'ProductProviderPayloadPaginatedSearch' |},
    ...$Pick<ProductProviderPayloadPaginatedSearch, { page: *, totalPage: * }>,
    ...{ nodes: Array<({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: *, archived: *, name: * }>,
      ...{ exporter: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, unitPrice?: ?({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, product: ({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: *, serial: * }>,
        ...{ tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, files: Array<({
            ...{| __typename: 'File' |},
          ...$Pick<File, { id: *, path: * }>,
          ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
        ...{ grossWeight?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), volume?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), size?: ?({
            ...{| __typename: 'Size' |},
          ...{ length: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), width: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), height: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }) }
        }) }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
        ...{ grossWeight?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), volume?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), size?: ?({
            ...{| __typename: 'Size' |},
          ...{ length: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), width: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), height: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }) }
        }) }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}> }
  }) }
});

export type ProductProvidersListFullQueryVariables = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?ProductProviderFilterInput,
  sortBy?: ?ProductProviderSortInput
};


export type ProductProvidersListFullQuery = ({
    ...{| __typename: 'Query' |},
  ...{ productProviders: ({
      ...{| __typename: 'ProductProviderPayloadPaginatedSearch' |},
    ...$Pick<ProductProviderPayloadPaginatedSearch, { page: *, totalPage: * }>,
    ...{ nodes: Array<({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: *, archived: *, name: * }>,
      ...{ product: ({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: *, serial: * }>,
        ...{ tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: *, entityTypes: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, files: Array<({
            ...{| __typename: 'File' |},
          ...$Pick<File, { id: *, path: * }>,
          ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, unitPrice?: ?({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
        ...{ grossWeight?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), volume?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), size?: ?({
            ...{| __typename: 'Size' |},
          ...{ length: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), width: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), height: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }) }
        }) }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
        ...{ grossWeight?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), volume?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), size?: ?({
            ...{| __typename: 'Size' |},
          ...{ length: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), width: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), height: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }) }
        }) }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) }
});

export type ReminderFormFragment = ({
    ...{| __typename: 'Reminder' |},
  ...$Pick<Reminder, { id: *, archived: *, createdAt: *, updatedAt: * }>,
  ...{ updatedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, rule: ({
      ...{| __typename: 'ReminderRule' |},
    ...$Pick<ReminderRule, { template: *, beforeAfter: *, entityType: *, name: *, period: *, periodNumbers: *, triggerField: * }>
  }), receiver: ({
      ...{| __typename: 'ReminderReceiver' |},
    ...$Pick<ReminderReceiver, { organizationTypes: * }>,
    ...{ users: Array<({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organizations: Array<({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }), section: ({
      ...{| __typename: 'ReminderSection' |},
    ...$Pick<ReminderSection, { entityType: *, view: * }>,
    ...{ formAction: ({
        ...{| __typename: 'ReminderSectionFormActionType' |},
      ...$Pick<ReminderSectionFormActionType, { fileTypes: * }>
    }), tableAction: ({
        ...{| __typename: 'ReminderSectionTableActionType' |},
      ...{ maskEdit?: ?({
          ...{| __typename: 'MaskEdit' |},
        ...$Pick<MaskEdit, { id: *, name: *, type: *, memo?: *, filterSort?: *, collapses: *, updatedAt: * }>,
        ...{ columns: Array<({
            ...{| __typename: 'MaskEditColumn' |},
          ...$Pick<MaskEditColumn, { key: *, hidden: *, width?: *, pinned?: *, highlight?: *, group?: * }>
        })>, updatedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) }
  }) }
});

export type ReminderFormActionFragment = ({
    ...{| __typename: 'Reminder' |},
  ...{ section: ({
      ...{| __typename: 'ReminderSection' |},
    ...{ formAction: ({
        ...{| __typename: 'ReminderSectionFormActionType' |},
      ...$Pick<ReminderSectionFormActionType, { fileTypes: * }>
    }) }
  }) }
});

export type ReminderCreateMutationVariables = {
  input: ReminderCreateInput
};


export type ReminderCreateMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ reminderCreate: ({
      ...{| __typename: 'Reminder' |},
    ...$Pick<Reminder, { id: *, archived: *, createdAt: *, updatedAt: * }>,
    ...{ updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, rule: ({
        ...{| __typename: 'ReminderRule' |},
      ...$Pick<ReminderRule, { template: *, beforeAfter: *, entityType: *, name: *, period: *, periodNumbers: *, triggerField: * }>
    }), receiver: ({
        ...{| __typename: 'ReminderReceiver' |},
      ...$Pick<ReminderReceiver, { organizationTypes: * }>,
      ...{ users: Array<({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organizations: Array<({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }), section: ({
        ...{| __typename: 'ReminderSection' |},
      ...$Pick<ReminderSection, { entityType: *, view: * }>,
      ...{ formAction: ({
          ...{| __typename: 'ReminderSectionFormActionType' |},
        ...$Pick<ReminderSectionFormActionType, { fileTypes: * }>
      }), tableAction: ({
          ...{| __typename: 'ReminderSectionTableActionType' |},
        ...{ maskEdit?: ?({
            ...{| __typename: 'MaskEdit' |},
          ...$Pick<MaskEdit, { id: *, name: *, type: *, memo?: *, filterSort?: *, collapses: *, updatedAt: * }>,
          ...{ columns: Array<({
              ...{| __typename: 'MaskEditColumn' |},
            ...$Pick<MaskEditColumn, { key: *, hidden: *, width?: *, pinned?: *, highlight?: *, group?: * }>
          })>, updatedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) }
    }) }
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |} }
});

export type ReminderDeleteMutationVariables = {
  id: $ElementType<Scalars, 'ID'>
};


export type ReminderDeleteMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ reminderDelete?: ?({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |} }
});

export type ReminderUpdateMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  input: ReminderUpdateInput
};


export type ReminderUpdateMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ reminderUpdate: ({
      ...{| __typename: 'Reminder' |},
    ...$Pick<Reminder, { id: *, archived: *, createdAt: *, updatedAt: * }>,
    ...{ updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, rule: ({
        ...{| __typename: 'ReminderRule' |},
      ...$Pick<ReminderRule, { template: *, beforeAfter: *, entityType: *, name: *, period: *, periodNumbers: *, triggerField: * }>
    }), receiver: ({
        ...{| __typename: 'ReminderReceiver' |},
      ...$Pick<ReminderReceiver, { organizationTypes: * }>,
      ...{ users: Array<({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organizations: Array<({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }), section: ({
        ...{| __typename: 'ReminderSection' |},
      ...$Pick<ReminderSection, { entityType: *, view: * }>,
      ...{ formAction: ({
          ...{| __typename: 'ReminderSectionFormActionType' |},
        ...$Pick<ReminderSectionFormActionType, { fileTypes: * }>
      }), tableAction: ({
          ...{| __typename: 'ReminderSectionTableActionType' |},
        ...{ maskEdit?: ?({
            ...{| __typename: 'MaskEdit' |},
          ...$Pick<MaskEdit, { id: *, name: *, type: *, memo?: *, filterSort?: *, collapses: *, updatedAt: * }>,
          ...{ columns: Array<({
              ...{| __typename: 'MaskEditColumn' |},
            ...$Pick<MaskEditColumn, { key: *, hidden: *, width?: *, pinned?: *, highlight?: *, group?: * }>
          })>, updatedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) }
    }) }
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |} }
});

export type ReminderListQueryVariables = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?ReminderFilterInput,
  sortBy?: ?ReminderSortInput
};


export type ReminderListQuery = ({
    ...{| __typename: 'Query' |},
  ...{ reminders: ({
      ...{| __typename: 'ReminderPayloadPaginatedSearch' |},
    ...$Pick<ReminderPayloadPaginatedSearch, { page: *, totalPage: * }>,
    ...{ nodes: Array<({
        ...{| __typename: 'Reminder' |},
      ...$Pick<Reminder, { id: *, archived: *, createdAt: *, updatedAt: * }>,
      ...{ updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, rule: ({
          ...{| __typename: 'ReminderRule' |},
        ...$Pick<ReminderRule, { template: *, beforeAfter: *, entityType: *, name: *, period: *, periodNumbers: *, triggerField: * }>
      }), receiver: ({
          ...{| __typename: 'ReminderReceiver' |},
        ...$Pick<ReminderReceiver, { organizationTypes: * }>,
        ...{ users: Array<({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organizations: Array<({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }), section: ({
          ...{| __typename: 'ReminderSection' |},
        ...$Pick<ReminderSection, { entityType: *, view: * }>,
        ...{ formAction: ({
            ...{| __typename: 'ReminderSectionFormActionType' |},
          ...$Pick<ReminderSectionFormActionType, { fileTypes: * }>
        }), tableAction: ({
            ...{| __typename: 'ReminderSectionTableActionType' |},
          ...{ maskEdit?: ?({
              ...{| __typename: 'MaskEdit' |},
            ...$Pick<MaskEdit, { id: *, name: *, type: *, memo?: *, filterSort?: *, collapses: *, updatedAt: * }>,
            ...{ columns: Array<({
                ...{| __typename: 'MaskEditColumn' |},
              ...$Pick<MaskEditColumn, { key: *, hidden: *, width?: *, pinned?: *, highlight?: *, group?: * }>
            })>, updatedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) }
      }) }
    }) | ({
        ...{| __typename: 'BadRequest' |},
      ...{ violations: Array<({
          ...{| __typename: 'Violation' |},
        ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
        ...{ parameters: Array<({
            ...{| __typename: 'ViolationParameter' |},
          ...$Pick<ViolationParameter, { key: *, value: * }>
        })> }
      })> }
    }) | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}> }
  }) }
});

export type RemindersByIdsQueryVariables = {
  ids: Array<$ElementType<Scalars, 'ID'>>
};


export type RemindersByIdsQuery = ({
    ...{| __typename: 'Query' |},
  ...{ remindersByIDs: Array<({
      ...{| __typename: 'Reminder' |},
    ...{ section: ({
        ...{| __typename: 'ReminderSection' |},
      ...{ formAction: ({
          ...{| __typename: 'ReminderSectionFormActionType' |},
        ...$Pick<ReminderSectionFormActionType, { fileTypes: * }>
      }) }
    }) }
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}> }
});

export type BatchTableFragment = ({
    ...{| __typename: 'Batch' |},
  ...$Pick<Batch, { createdAt: *, updatedAt: *, no: *, quantity: *, quantityUnit: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, deliveredAt?: *, desiredAt?: *, totalVolumeOverriding: *, expiredAt?: *, producedAt?: *, packageName?: *, packageCapacity?: *, packageQuantity?: *, autoCalculatePackageQuantity: *, latestQuantity: *, archived: *, autoCalculatePackageVolume: *, memo?: *, sort: *, containerSort: *, shipmentSort: *, id: * }>,
  ...{ createdBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { displayName: *, id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalPrice?: ?({
      ...{| __typename: 'NewPrice' |},
    ...$Pick<NewPrice, { amount: *, currency: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), totalVolumeOverride?: ?({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), packageGrossWeight?: ?({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), packageVolume?: ?({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), packageSize?: ?({
      ...{| __typename: 'Size' |},
    ...{ length: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), width: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), height: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }) }
  }), tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, shipment?: ?({
      ...{| __typename: 'Shipment' |},
    ...$Pick<Shipment, { archived: *, no: *, blNo?: *, masterBlNo?: *, blDate?: *, autoTracking?: *, autoTrackingTransportType?: *, autoTrackingIncludeContainers?: *, containerCount: *, booked?: *, bookingNo?: *, bookingDate?: *, invoiceNo?: *, contractNo?: *, loadType?: *, incoterm?: *, carrier?: *, memo?: *, totalWeightOverriding: *, totalWeightDisplayMetric: *, totalVolumeOverriding: *, totalVolumeDisplayMetric: *, totalPackageQuantity: *, totalPackageQuantityOverride?: *, totalPackageQuantityOverriding: *, filesUnreadCount: *, hasFile: *, id: *, notificationUnseenCount: * }>,
    ...{ followers: Array<({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batchQuantity: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), totalWeight: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), totalWeightOverride?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), totalVolume: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), totalVolumeOverride?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), importer?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, forwarders: Array<({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organizations: Array<({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, cargoReady: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
        ...{ updatedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timeline: ({
        ...{| __typename: 'Timeline' |},
      ...$Pick<Timeline, { unreadMessageCount: * }>
    }), voyages: Array<({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: *, no?: *, vesselName?: *, vesselCode?: * }>,
      ...{ departurePort?: ?({
          ...{| __typename: 'Port' |},
        ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
      }), arrivalPort?: ?({
          ...{| __typename: 'Port' |},
        ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
      }), departure: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
          ...{ updatedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
          ...{ updatedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>,
      ...{ warehouse?: ?({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: *, name: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customClearance: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
          ...{ updatedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
          ...{ updatedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
          ...{ updatedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, messageGroups: Array<({
        ...{| __typename: 'MessageGroup' |},
      ...$Pick<MessageGroup, { unreadMessageCount: *, filesUnreadCount: * }>
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, files: Array<({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
      ...{ tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, entity?: ?({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, poNo: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | ({
          ...{| __typename: 'Partnership' |},
        ...$Pick<Partnership, { id: * }>
      }) | ({
          ...{| __typename: 'Role' |},
        ...$Pick<Role, { id: * }>
      }) | ({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Mask' |},
        ...$Pick<Mask, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'MaskEdit' |},
        ...$Pick<MaskEdit, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { displayName: *, id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
        ...{| __typename: 'CustomFields' |},
      ...{ fieldValues: Array<({
          ...{| __typename: 'FieldValue' |},
        ...{ value: ({
            ...{| __typename: 'StringValue' |},
          ...$Pick<StringValue, { string: * }>
        }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }), notificationUnseen: Array<({
        ...{| __typename: 'Notification' |},
      ...$Pick<Notification, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
      ...{| __typename: 'Container' |},
    ...$Pick<Container, { no: *, archived: *, autoCalculatedFreeTimeStartDate: *, freeTimeStartDate?: *, freeTimeDuration?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDateApprovedAt?: *, yardName?: *, departureDate?: *, departureDateApprovedAt?: *, containerType?: *, containerOption?: *, memo?: *, sort: *, id: * }>,
    ...{ connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { displayName: *, id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, representativeBatch?: ?({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, terminalArrivalPort?: ?({
        ...{| __typename: 'Port' |},
      ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
    }), terminalArrival: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
        ...{ updatedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, batchQuantity: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), totalVolume: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), totalWeight: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), totalPrice?: ?({
        ...{| __typename: 'NewPrice' |},
      ...$Pick<NewPrice, { amount: *, currency: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, departureDateApprovedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
        ...{| __typename: 'CustomFields' |},
      ...{ fieldValues: Array<({
          ...{| __typename: 'FieldValue' |},
        ...{ value: ({
            ...{| __typename: 'StringValue' |},
          ...$Pick<StringValue, { string: * }>
        }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, orderItem: ({
      ...{| __typename: 'OrderItem' |},
    ...$Pick<OrderItem, { no: *, quantity: *, priceUnit: *, calculatedUnit: *, archived: *, deliveryDate?: *, memo?: *, hasFile: *, sort: *, id: * }>,
    ...{ order: ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { createdAt: *, updatedAt: *, archived: *, poNo: *, memo?: *, issuedAt?: *, piNo?: *, currency: *, calculatedOrderItemUnit: *, totalOrdered: *, incoterm?: *, deliveryPlace?: *, deliveryDate?: *, hasFile: *, id: * }>,
      ...{ createdBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { displayName: *, id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, followers: Array<({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, importer: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, files: Array<({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
        ...{ tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, entity?: ?({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: *, name: * }>,
          ...{ product: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: *, poNo: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | ({
            ...{| __typename: 'Partnership' |},
          ...$Pick<Partnership, { id: * }>
        }) | ({
            ...{| __typename: 'Role' |},
          ...$Pick<Role, { id: * }>
        }) | ({
            ...{| __typename: 'File' |},
          ...$Pick<File, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Mask' |},
          ...$Pick<Mask, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'MaskEdit' |},
          ...$Pick<MaskEdit, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, organizations: Array<({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
          ...{| __typename: 'CustomFields' |},
        ...{ fieldValues: Array<({
            ...{| __typename: 'FieldValue' |},
          ...{ value: ({
              ...{| __typename: 'StringValue' |},
            ...$Pick<StringValue, { string: * }>
          }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { createdAt: *, updatedAt: *, id: *, name: *, hasFile: * }>,
      ...{ product: ({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { createdAt: *, updatedAt: *, name: *, serial: *, material?: *, id: * }>,
        ...{ createdBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, files: Array<({
            ...{| __typename: 'File' |},
          ...$Pick<File, { id: *, path: * }>,
          ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, productProviders: Array<({
            ...{| __typename: 'ProductProvider' |},
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
            ...{| __typename: 'CustomFields' |},
          ...{ fieldValues: Array<({
              ...{| __typename: 'FieldValue' |},
            ...{ value: ({
                ...{| __typename: 'StringValue' |},
              ...$Pick<StringValue, { string: * }>
            }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
                ...{| __typename: 'FieldDefinition' |},
              ...$Pick<FieldDefinition, { id: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, createdBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { displayName: *, id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, unitPrice?: ?({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, files: Array<({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
        ...{ tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, entity?: ?({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: *, name: * }>,
          ...{ product: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: *, poNo: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | ({
            ...{| __typename: 'Partnership' |},
          ...$Pick<Partnership, { id: * }>
        }) | ({
            ...{| __typename: 'Role' |},
          ...$Pick<Role, { id: * }>
        }) | ({
            ...{| __typename: 'File' |},
          ...$Pick<File, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Mask' |},
          ...$Pick<Mask, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'MaskEdit' |},
          ...$Pick<MaskEdit, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
          ...{| __typename: 'CustomFields' |},
        ...{ fieldValues: Array<({
            ...{| __typename: 'FieldValue' |},
          ...{ value: ({
              ...{| __typename: 'StringValue' |},
            ...$Pick<StringValue, { string: * }>
          }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, price: ({
        ...{| __typename: 'NewPrice' |},
      ...$Pick<NewPrice, { amount: *, currency: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalPrice: ({
        ...{| __typename: 'NewPrice' |},
      ...$Pick<NewPrice, { amount: *, currency: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, files: Array<({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
      ...{ tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, entity?: ?({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, poNo: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | ({
          ...{| __typename: 'Partnership' |},
        ...$Pick<Partnership, { id: * }>
      }) | ({
          ...{| __typename: 'Role' |},
        ...$Pick<Role, { id: * }>
      }) | ({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Mask' |},
        ...$Pick<Mask, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'MaskEdit' |},
        ...$Pick<MaskEdit, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
        ...{| __typename: 'CustomFields' |},
      ...{ fieldValues: Array<({
          ...{| __typename: 'FieldValue' |},
        ...{ value: ({
            ...{| __typename: 'StringValue' |},
          ...$Pick<StringValue, { string: * }>
        }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
      ...{| __typename: 'CustomFields' |},
    ...{ fieldValues: Array<({
        ...{| __typename: 'FieldValue' |},
      ...{ value: ({
          ...{| __typename: 'StringValue' |},
        ...$Pick<StringValue, { string: * }>
      }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) }
});

export type BatchTableOrderItemFragment = ({
    ...{| __typename: 'OrderItem' |},
  ...$Pick<OrderItem, { no: *, quantity: *, priceUnit: *, calculatedUnit: *, archived: *, deliveryDate?: *, memo?: *, hasFile: *, sort: *, id: * }>,
  ...{ order: ({
      ...{| __typename: 'Order' |},
    ...$Pick<Order, { createdAt: *, updatedAt: *, archived: *, poNo: *, memo?: *, issuedAt?: *, piNo?: *, currency: *, calculatedOrderItemUnit: *, totalOrdered: *, incoterm?: *, deliveryPlace?: *, deliveryDate?: *, hasFile: *, id: * }>,
    ...{ createdBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { displayName: *, id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, followers: Array<({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, importer: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, files: Array<({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
      ...{ tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, entity?: ?({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, poNo: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | ({
          ...{| __typename: 'Partnership' |},
        ...$Pick<Partnership, { id: * }>
      }) | ({
          ...{| __typename: 'Role' |},
        ...$Pick<Role, { id: * }>
      }) | ({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Mask' |},
        ...$Pick<Mask, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'MaskEdit' |},
        ...$Pick<MaskEdit, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, organizations: Array<({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
        ...{| __typename: 'CustomFields' |},
      ...{ fieldValues: Array<({
          ...{| __typename: 'FieldValue' |},
        ...{ value: ({
            ...{| __typename: 'StringValue' |},
          ...$Pick<StringValue, { string: * }>
        }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
      ...{| __typename: 'ProductProvider' |},
    ...$Pick<ProductProvider, { createdAt: *, updatedAt: *, id: *, name: *, hasFile: * }>,
    ...{ product: ({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { createdAt: *, updatedAt: *, name: *, serial: *, material?: *, id: * }>,
      ...{ createdBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, files: Array<({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: *, path: * }>,
        ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, productProviders: Array<({
          ...{| __typename: 'ProductProvider' |},
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
          ...{| __typename: 'CustomFields' |},
        ...{ fieldValues: Array<({
            ...{| __typename: 'FieldValue' |},
          ...{ value: ({
              ...{| __typename: 'StringValue' |},
            ...$Pick<StringValue, { string: * }>
          }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, createdBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { displayName: *, id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, unitPrice?: ?({
        ...{| __typename: 'NewPrice' |},
      ...$Pick<NewPrice, { amount: *, currency: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, files: Array<({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
      ...{ tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, entity?: ?({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, poNo: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | ({
          ...{| __typename: 'Partnership' |},
        ...$Pick<Partnership, { id: * }>
      }) | ({
          ...{| __typename: 'Role' |},
        ...$Pick<Role, { id: * }>
      }) | ({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Mask' |},
        ...$Pick<Mask, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'MaskEdit' |},
        ...$Pick<MaskEdit, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
        ...{| __typename: 'CustomFields' |},
      ...{ fieldValues: Array<({
          ...{| __typename: 'FieldValue' |},
        ...{ value: ({
            ...{| __typename: 'StringValue' |},
          ...$Pick<StringValue, { string: * }>
        }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, price: ({
      ...{| __typename: 'NewPrice' |},
    ...$Pick<NewPrice, { amount: *, currency: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalPrice: ({
      ...{| __typename: 'NewPrice' |},
    ...$Pick<NewPrice, { amount: *, currency: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, files: Array<({
      ...{| __typename: 'File' |},
    ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
    ...{ tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, entity?: ?({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: *, name: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: *, name: * }>,
      ...{ product: ({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: *, poNo: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: * }>
    }) | ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | ({
        ...{| __typename: 'Partnership' |},
      ...$Pick<Partnership, { id: * }>
    }) | ({
        ...{| __typename: 'Role' |},
      ...$Pick<Role, { id: * }>
    }) | ({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Mask' |},
      ...$Pick<Mask, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'MaskEdit' |},
      ...$Pick<MaskEdit, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'FieldDefinition' |},
      ...$Pick<FieldDefinition, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
      ...{| __typename: 'CustomFields' |},
    ...{ fieldValues: Array<({
        ...{| __typename: 'FieldValue' |},
      ...{ value: ({
          ...{| __typename: 'StringValue' |},
        ...$Pick<StringValue, { string: * }>
      }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) }
});

export type SheetBatchFragment = ({
    ...{| __typename: 'Batch' |},
  ...$Pick<Batch, { createdAt: *, updatedAt: *, no: *, quantity: *, quantityUnit: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, deliveredAt?: *, desiredAt?: *, totalVolumeOverriding: *, expiredAt?: *, producedAt?: *, packageName?: *, packageCapacity?: *, packageQuantity?: *, autoCalculatePackageQuantity: *, latestQuantity: *, archived: *, autoCalculatePackageVolume: *, memo?: *, sort: *, containerSort: *, shipmentSort: * }>,
  ...{ createdBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalPrice?: ?({
      ...{| __typename: 'NewPrice' |},
    ...$Pick<NewPrice, { amount: *, currency: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), totalVolumeOverride?: ?({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), packageGrossWeight?: ?({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), packageVolume?: ?({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), packageSize?: ?({
      ...{| __typename: 'Size' |},
    ...{ length: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), width: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), height: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }) }
  }), tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, container?: ?({
      ...{| __typename: 'Container' |},
    ...$Pick<Container, { warehouseArrivalActualDate?: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment?: ?({
      ...{| __typename: 'Shipment' |},
    ...{ containerGroups: Array<({
        ...{| __typename: 'ContainerGroup' |},
      ...{ warehouseArrival: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { date?: * }>,
        ...{ timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, voyages: Array<({
        ...{| __typename: 'Voyage' |},
      ...{ departure: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { date?: * }>,
        ...{ timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { warehouseArrivalActualDate?: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type SheetBatchMergedFragment = ({
    ...{| __typename: 'Batch' |},
  ...$Pick<Batch, { createdAt: *, updatedAt: *, no: *, quantity: *, quantityUnit: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, deliveredAt?: *, desiredAt?: *, totalVolumeOverriding: *, expiredAt?: *, producedAt?: *, packageName?: *, packageCapacity?: *, packageQuantity?: *, autoCalculatePackageQuantity: *, latestQuantity: *, archived: *, autoCalculatePackageVolume: *, memo?: *, sort: *, containerSort: *, shipmentSort: *, id: * }>,
  ...{ createdBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { displayName: *, id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalPrice?: ?({
      ...{| __typename: 'NewPrice' |},
    ...$Pick<NewPrice, { amount: *, currency: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), totalVolumeOverride?: ?({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), packageGrossWeight?: ?({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), packageVolume?: ?({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), packageSize?: ?({
      ...{| __typename: 'Size' |},
    ...{ length: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), width: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), height: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }) }
  }), tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, container?: ?({
      ...{| __typename: 'Container' |},
    ...$Pick<Container, { warehouseArrivalActualDate?: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment?: ?({
      ...{| __typename: 'Shipment' |},
    ...{ containerGroups: Array<({
        ...{| __typename: 'ContainerGroup' |},
      ...{ warehouseArrival: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { date?: * }>,
        ...{ timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, voyages: Array<({
        ...{| __typename: 'Voyage' |},
      ...{ departure: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { date?: * }>,
        ...{ timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { warehouseArrivalActualDate?: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
      ...{| __typename: 'CustomFields' |},
    ...{ fieldValues: Array<({
        ...{| __typename: 'FieldValue' |},
      ...{ value: ({
          ...{| __typename: 'StringValue' |},
        ...$Pick<StringValue, { string: * }>
      }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) }
});

export type SheetShipmentExtraFragment = ({
    ...{| __typename: 'Shipment' |},
  ...$Pick<Shipment, { containerCount: * }>,
  ...{ totalVolume: ({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }) }
});

export type SheetBatchMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  input: BatchUpdateInput
};


export type SheetBatchMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ batchUpdate: {| __typename: 'Batch' |} | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |} }
});

export type SheetConnection_Tag_Fragment = ({
    ...{| __typename: 'Tag' |},
  ...{ connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type SheetConnection_FieldDefinition_Fragment = ({
    ...{| __typename: 'FieldDefinition' |},
  ...{ connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type SheetConnection_ProductProvider_Fragment = ({
    ...{| __typename: 'ProductProvider' |},
  ...{ connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type SheetConnection_ProductProviderPackage_Fragment = ({
    ...{| __typename: 'ProductProviderPackage' |},
  ...{ connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type SheetConnection_Batch_Fragment = ({
    ...{| __typename: 'Batch' |},
  ...{ connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type SheetConnection_OrderItem_Fragment = ({
    ...{| __typename: 'OrderItem' |},
  ...{ connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type SheetConnection_Order_Fragment = ({
    ...{| __typename: 'Order' |},
  ...{ connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type SheetConnection_Shipment_Fragment = ({
    ...{| __typename: 'Shipment' |},
  ...{ connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type SheetConnection_TimelineDate_Fragment = ({
    ...{| __typename: 'TimelineDate' |},
  ...{ connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type SheetConnection_TimelineDateRevision_Fragment = ({
    ...{| __typename: 'TimelineDateRevision' |},
  ...{ connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type SheetConnection_Voyage_Fragment = ({
    ...{| __typename: 'Voyage' |},
  ...{ connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type SheetConnection_ContainerGroup_Fragment = ({
    ...{| __typename: 'ContainerGroup' |},
  ...{ connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type SheetConnection_Container_Fragment = ({
    ...{| __typename: 'Container' |},
  ...{ connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type SheetConnectionFragment = SheetConnection_Tag_Fragment | SheetConnection_FieldDefinition_Fragment | SheetConnection_ProductProvider_Fragment | SheetConnection_ProductProviderPackage_Fragment | SheetConnection_Batch_Fragment | SheetConnection_OrderItem_Fragment | SheetConnection_Order_Fragment | SheetConnection_Shipment_Fragment | SheetConnection_TimelineDate_Fragment | SheetConnection_TimelineDateRevision_Fragment | SheetConnection_Voyage_Fragment | SheetConnection_ContainerGroup_Fragment | SheetConnection_Container_Fragment;

export type SheetCustomizable_Product_Fragment = ({
    ...{| __typename: 'Product' |},
  ...{ customFields: ({
      ...{| __typename: 'CustomFields' |},
    ...{ fieldValues: Array<({
        ...{| __typename: 'FieldValue' |},
      ...{ value: ({
          ...{| __typename: 'StringValue' |},
        ...$Pick<StringValue, { string: * }>
      }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) }
});

export type SheetCustomizable_ProductProvider_Fragment = ({
    ...{| __typename: 'ProductProvider' |},
  ...{ customFields: ({
      ...{| __typename: 'CustomFields' |},
    ...{ fieldValues: Array<({
        ...{| __typename: 'FieldValue' |},
      ...{ value: ({
          ...{| __typename: 'StringValue' |},
        ...$Pick<StringValue, { string: * }>
      }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) }
});

export type SheetCustomizable_Batch_Fragment = ({
    ...{| __typename: 'Batch' |},
  ...{ customFields: ({
      ...{| __typename: 'CustomFields' |},
    ...{ fieldValues: Array<({
        ...{| __typename: 'FieldValue' |},
      ...{ value: ({
          ...{| __typename: 'StringValue' |},
        ...$Pick<StringValue, { string: * }>
      }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) }
});

export type SheetCustomizable_OrderItem_Fragment = ({
    ...{| __typename: 'OrderItem' |},
  ...{ customFields: ({
      ...{| __typename: 'CustomFields' |},
    ...{ fieldValues: Array<({
        ...{| __typename: 'FieldValue' |},
      ...{ value: ({
          ...{| __typename: 'StringValue' |},
        ...$Pick<StringValue, { string: * }>
      }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) }
});

export type SheetCustomizable_Order_Fragment = ({
    ...{| __typename: 'Order' |},
  ...{ customFields: ({
      ...{| __typename: 'CustomFields' |},
    ...{ fieldValues: Array<({
        ...{| __typename: 'FieldValue' |},
      ...{ value: ({
          ...{| __typename: 'StringValue' |},
        ...$Pick<StringValue, { string: * }>
      }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) }
});

export type SheetCustomizable_Shipment_Fragment = ({
    ...{| __typename: 'Shipment' |},
  ...{ customFields: ({
      ...{| __typename: 'CustomFields' |},
    ...{ fieldValues: Array<({
        ...{| __typename: 'FieldValue' |},
      ...{ value: ({
          ...{| __typename: 'StringValue' |},
        ...$Pick<StringValue, { string: * }>
      }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) }
});

export type SheetCustomizable_Warehouse_Fragment = ({
    ...{| __typename: 'Warehouse' |},
  ...{ customFields: ({
      ...{| __typename: 'CustomFields' |},
    ...{ fieldValues: Array<({
        ...{| __typename: 'FieldValue' |},
      ...{ value: ({
          ...{| __typename: 'StringValue' |},
        ...$Pick<StringValue, { string: * }>
      }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) }
});

export type SheetCustomizable_Container_Fragment = ({
    ...{| __typename: 'Container' |},
  ...{ customFields: ({
      ...{| __typename: 'CustomFields' |},
    ...{ fieldValues: Array<({
        ...{| __typename: 'FieldValue' |},
      ...{ value: ({
          ...{| __typename: 'StringValue' |},
        ...$Pick<StringValue, { string: * }>
      }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) }
});

export type SheetCustomizableFragment = SheetCustomizable_Product_Fragment | SheetCustomizable_ProductProvider_Fragment | SheetCustomizable_Batch_Fragment | SheetCustomizable_OrderItem_Fragment | SheetCustomizable_Order_Fragment | SheetCustomizable_Shipment_Fragment | SheetCustomizable_Warehouse_Fragment | SheetCustomizable_Container_Fragment;

export type SheetMaskFragment = ({
    ...{| __typename: 'Mask' |},
  ...$Pick<Mask, { id: *, name: * }>,
  ...{ fieldDefinitions: Array<({
      ...{| __typename: 'FieldDefinition' |},
    ...$Pick<FieldDefinition, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type SheetModel_User_Fragment = ({
    ...{| __typename: 'User' |},
  ...$Pick<User, { id: * }>
});

export type SheetModel_Tag_Fragment = ({
    ...{| __typename: 'Tag' |},
  ...$Pick<Tag, { id: * }>
});

export type SheetModel_Connection_Fragment = ({
    ...{| __typename: 'Connection' |},
  ...$Pick<Connection, { id: * }>
});

export type SheetModel_Organization_Fragment = ({
    ...{| __typename: 'Organization' |},
  ...$Pick<Organization, { id: * }>
});

export type SheetModel_InternalPartnerGroup_Fragment = ({
    ...{| __typename: 'InternalPartnerGroup' |},
  ...$Pick<InternalPartnerGroup, { id: * }>
});

export type SheetModel_Product_Fragment = ({
    ...{| __typename: 'Product' |},
  ...$Pick<Product, { id: * }>
});

export type SheetModel_Reminder_Fragment = ({
    ...{| __typename: 'Reminder' |},
  ...$Pick<Reminder, { id: * }>
});

export type SheetModel_MaskEdit_Fragment = ({
    ...{| __typename: 'MaskEdit' |},
  ...$Pick<MaskEdit, { id: * }>
});

export type SheetModel_File_Fragment = ({
    ...{| __typename: 'File' |},
  ...$Pick<File, { id: * }>
});

export type SheetModel_TimelineEvent_Fragment = ({
    ...{| __typename: 'TimelineEvent' |},
  ...$Pick<TimelineEvent, { id: * }>
});

export type SheetModel_TimelineComment_Fragment = ({
    ...{| __typename: 'TimelineComment' |},
  ...$Pick<TimelineComment, { id: * }>
});

export type SheetModel_MessageGroup_Fragment = ({
    ...{| __typename: 'MessageGroup' |},
  ...$Pick<MessageGroup, { id: * }>
});

export type SheetModel_Mask_Fragment = ({
    ...{| __typename: 'Mask' |},
  ...$Pick<Mask, { id: * }>
});

export type SheetModel_FieldDefinition_Fragment = ({
    ...{| __typename: 'FieldDefinition' |},
  ...$Pick<FieldDefinition, { id: * }>
});

export type SheetModel_ProductProvider_Fragment = ({
    ...{| __typename: 'ProductProvider' |},
  ...$Pick<ProductProvider, { id: * }>
});

export type SheetModel_ProductProviderPackage_Fragment = ({
    ...{| __typename: 'ProductProviderPackage' |},
  ...$Pick<ProductProviderPackage, { id: * }>
});

export type SheetModel_Batch_Fragment = ({
    ...{| __typename: 'Batch' |},
  ...$Pick<Batch, { id: * }>
});

export type SheetModel_OrderItem_Fragment = ({
    ...{| __typename: 'OrderItem' |},
  ...$Pick<OrderItem, { id: * }>
});

export type SheetModel_Order_Fragment = ({
    ...{| __typename: 'Order' |},
  ...$Pick<Order, { id: * }>
});

export type SheetModel_Shipment_Fragment = ({
    ...{| __typename: 'Shipment' |},
  ...$Pick<Shipment, { id: * }>
});

export type SheetModel_TimelineDate_Fragment = ({
    ...{| __typename: 'TimelineDate' |},
  ...$Pick<TimelineDate, { id: * }>
});

export type SheetModel_TimelineDateRevision_Fragment = ({
    ...{| __typename: 'TimelineDateRevision' |},
  ...$Pick<TimelineDateRevision, { id: * }>
});

export type SheetModel_Warehouse_Fragment = ({
    ...{| __typename: 'Warehouse' |},
  ...$Pick<Warehouse, { id: * }>
});

export type SheetModel_Voyage_Fragment = ({
    ...{| __typename: 'Voyage' |},
  ...$Pick<Voyage, { id: * }>
});

export type SheetModel_ContainerGroup_Fragment = ({
    ...{| __typename: 'ContainerGroup' |},
  ...$Pick<ContainerGroup, { id: * }>
});

export type SheetModel_Container_Fragment = ({
    ...{| __typename: 'Container' |},
  ...$Pick<Container, { id: * }>
});

export type SheetModel_Partnership_Fragment = ({
    ...{| __typename: 'Partnership' |},
  ...$Pick<Partnership, { id: * }>
});

export type SheetModel_Role_Fragment = ({
    ...{| __typename: 'Role' |},
  ...$Pick<Role, { id: * }>
});

export type SheetModel_Team_Fragment = ({
    ...{| __typename: 'Team' |},
  ...$Pick<Team, { id: * }>
});

export type SheetModel_Partner_Fragment = ({
    ...{| __typename: 'Partner' |},
  ...$Pick<Partner, { id: * }>
});

export type SheetModel_TagGroup_Fragment = ({
    ...{| __typename: 'TagGroup' |},
  ...$Pick<TagGroup, { id: * }>
});

export type SheetModel_OrganizationGroup_Fragment = ({
    ...{| __typename: 'OrganizationGroup' |},
  ...$Pick<OrganizationGroup, { id: * }>
});

export type SheetModel_ViewState_Fragment = ({
    ...{| __typename: 'ViewState' |},
  ...$Pick<ViewState, { id: * }>
});

export type SheetModel_Integration_Fragment = ({
    ...{| __typename: 'Integration' |},
  ...$Pick<Integration, { id: * }>
});

export type SheetModel_IntegrationConfiguration_Fragment = ({
    ...{| __typename: 'IntegrationConfiguration' |},
  ...$Pick<IntegrationConfiguration, { id: * }>
});

export type SheetModel_ExportTemplate_Fragment = ({
    ...{| __typename: 'ExportTemplate' |},
  ...$Pick<ExportTemplate, { id: * }>
});

export type SheetModel_ActivityLog_Fragment = ({
    ...{| __typename: 'ActivityLog' |},
  ...$Pick<ActivityLog, { id: * }>
});

export type SheetModel_Message_Fragment = ({
    ...{| __typename: 'Message' |},
  ...$Pick<Message, { id: * }>
});

export type SheetModel_MessageEmoji_Fragment = ({
    ...{| __typename: 'MessageEmoji' |},
  ...$Pick<MessageEmoji, { id: * }>
});

export type SheetModel_DraftMessage_Fragment = ({
    ...{| __typename: 'DraftMessage' |},
  ...$Pick<DraftMessage, { id: * }>
});

export type SheetModelFragment = SheetModel_User_Fragment | SheetModel_Tag_Fragment | SheetModel_Connection_Fragment | SheetModel_Organization_Fragment | SheetModel_InternalPartnerGroup_Fragment | SheetModel_Product_Fragment | SheetModel_Reminder_Fragment | SheetModel_MaskEdit_Fragment | SheetModel_File_Fragment | SheetModel_TimelineEvent_Fragment | SheetModel_TimelineComment_Fragment | SheetModel_MessageGroup_Fragment | SheetModel_Mask_Fragment | SheetModel_FieldDefinition_Fragment | SheetModel_ProductProvider_Fragment | SheetModel_ProductProviderPackage_Fragment | SheetModel_Batch_Fragment | SheetModel_OrderItem_Fragment | SheetModel_Order_Fragment | SheetModel_Shipment_Fragment | SheetModel_TimelineDate_Fragment | SheetModel_TimelineDateRevision_Fragment | SheetModel_Warehouse_Fragment | SheetModel_Voyage_Fragment | SheetModel_ContainerGroup_Fragment | SheetModel_Container_Fragment | SheetModel_Partnership_Fragment | SheetModel_Role_Fragment | SheetModel_Team_Fragment | SheetModel_Partner_Fragment | SheetModel_TagGroup_Fragment | SheetModel_OrganizationGroup_Fragment | SheetModel_ViewState_Fragment | SheetModel_Integration_Fragment | SheetModel_IntegrationConfiguration_Fragment | SheetModel_ExportTemplate_Fragment | SheetModel_ActivityLog_Fragment | SheetModel_Message_Fragment | SheetModel_MessageEmoji_Fragment | SheetModel_DraftMessage_Fragment;

export type SheetOwnedBy_Tag_Fragment = ({
    ...{| __typename: 'Tag' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type SheetOwnedBy_Product_Fragment = ({
    ...{| __typename: 'Product' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type SheetOwnedBy_Reminder_Fragment = ({
    ...{| __typename: 'Reminder' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type SheetOwnedBy_MaskEdit_Fragment = ({
    ...{| __typename: 'MaskEdit' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type SheetOwnedBy_File_Fragment = ({
    ...{| __typename: 'File' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type SheetOwnedBy_TimelineEvent_Fragment = ({
    ...{| __typename: 'TimelineEvent' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type SheetOwnedBy_TimelineComment_Fragment = ({
    ...{| __typename: 'TimelineComment' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type SheetOwnedBy_Mask_Fragment = ({
    ...{| __typename: 'Mask' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type SheetOwnedBy_FieldDefinition_Fragment = ({
    ...{| __typename: 'FieldDefinition' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type SheetOwnedBy_ProductProvider_Fragment = ({
    ...{| __typename: 'ProductProvider' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type SheetOwnedBy_ProductProviderPackage_Fragment = ({
    ...{| __typename: 'ProductProviderPackage' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type SheetOwnedBy_Batch_Fragment = ({
    ...{| __typename: 'Batch' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type SheetOwnedBy_OrderItem_Fragment = ({
    ...{| __typename: 'OrderItem' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type SheetOwnedBy_Order_Fragment = ({
    ...{| __typename: 'Order' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type SheetOwnedBy_Shipment_Fragment = ({
    ...{| __typename: 'Shipment' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type SheetOwnedBy_TimelineDate_Fragment = ({
    ...{| __typename: 'TimelineDate' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type SheetOwnedBy_TimelineDateRevision_Fragment = ({
    ...{| __typename: 'TimelineDateRevision' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type SheetOwnedBy_Warehouse_Fragment = ({
    ...{| __typename: 'Warehouse' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type SheetOwnedBy_Voyage_Fragment = ({
    ...{| __typename: 'Voyage' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type SheetOwnedBy_ContainerGroup_Fragment = ({
    ...{| __typename: 'ContainerGroup' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type SheetOwnedBy_Container_Fragment = ({
    ...{| __typename: 'Container' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type SheetOwnedBy_TagGroup_Fragment = ({
    ...{| __typename: 'TagGroup' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type SheetOwnedBy_OrganizationGroup_Fragment = ({
    ...{| __typename: 'OrganizationGroup' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type SheetOwnedBy_ViewState_Fragment = ({
    ...{| __typename: 'ViewState' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type SheetOwnedBy_Integration_Fragment = ({
    ...{| __typename: 'Integration' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type SheetOwnedBy_IntegrationConfiguration_Fragment = ({
    ...{| __typename: 'IntegrationConfiguration' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type SheetOwnedBy_ActivityLog_Fragment = ({
    ...{| __typename: 'ActivityLog' |},
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type SheetOwnedByFragment = SheetOwnedBy_Tag_Fragment | SheetOwnedBy_Product_Fragment | SheetOwnedBy_Reminder_Fragment | SheetOwnedBy_MaskEdit_Fragment | SheetOwnedBy_File_Fragment | SheetOwnedBy_TimelineEvent_Fragment | SheetOwnedBy_TimelineComment_Fragment | SheetOwnedBy_Mask_Fragment | SheetOwnedBy_FieldDefinition_Fragment | SheetOwnedBy_ProductProvider_Fragment | SheetOwnedBy_ProductProviderPackage_Fragment | SheetOwnedBy_Batch_Fragment | SheetOwnedBy_OrderItem_Fragment | SheetOwnedBy_Order_Fragment | SheetOwnedBy_Shipment_Fragment | SheetOwnedBy_TimelineDate_Fragment | SheetOwnedBy_TimelineDateRevision_Fragment | SheetOwnedBy_Warehouse_Fragment | SheetOwnedBy_Voyage_Fragment | SheetOwnedBy_ContainerGroup_Fragment | SheetOwnedBy_Container_Fragment | SheetOwnedBy_TagGroup_Fragment | SheetOwnedBy_OrganizationGroup_Fragment | SheetOwnedBy_ViewState_Fragment | SheetOwnedBy_Integration_Fragment | SheetOwnedBy_IntegrationConfiguration_Fragment | SheetOwnedBy_ActivityLog_Fragment;

export type SheetSortable_FieldDefinition_Fragment = ({
    ...{| __typename: 'FieldDefinition' |},
  ...$Pick<FieldDefinition, { sort: * }>
});

export type SheetSortable_ProductProvider_Fragment = ({
    ...{| __typename: 'ProductProvider' |},
  ...$Pick<ProductProvider, { sort: * }>
});

export type SheetSortable_ProductProviderPackage_Fragment = ({
    ...{| __typename: 'ProductProviderPackage' |},
  ...$Pick<ProductProviderPackage, { sort: * }>
});

export type SheetSortable_Batch_Fragment = ({
    ...{| __typename: 'Batch' |},
  ...$Pick<Batch, { sort: * }>
});

export type SheetSortable_OrderItem_Fragment = ({
    ...{| __typename: 'OrderItem' |},
  ...$Pick<OrderItem, { sort: * }>
});

export type SheetSortable_TimelineDateRevision_Fragment = ({
    ...{| __typename: 'TimelineDateRevision' |},
  ...$Pick<TimelineDateRevision, { sort: * }>
});

export type SheetSortable_Voyage_Fragment = ({
    ...{| __typename: 'Voyage' |},
  ...$Pick<Voyage, { sort: * }>
});

export type SheetSortable_ContainerGroup_Fragment = ({
    ...{| __typename: 'ContainerGroup' |},
  ...$Pick<ContainerGroup, { sort: * }>
});

export type SheetSortable_Container_Fragment = ({
    ...{| __typename: 'Container' |},
  ...$Pick<Container, { sort: * }>
});

export type SheetSortableFragment = SheetSortable_FieldDefinition_Fragment | SheetSortable_ProductProvider_Fragment | SheetSortable_ProductProviderPackage_Fragment | SheetSortable_Batch_Fragment | SheetSortable_OrderItem_Fragment | SheetSortable_TimelineDateRevision_Fragment | SheetSortable_Voyage_Fragment | SheetSortable_ContainerGroup_Fragment | SheetSortable_Container_Fragment;

export type SheetEntitiesMutationVariables = {
  products?: ?Array<ProductUpdateWrapperInput>,
  productProviders?: ?Array<ProductProviderUpdateWrapperInput>,
  productProviderPackages?: ?Array<ProductProviderPackageUpdateWrapperInput>,
  orders?: ?Array<OrderUpdateWrapperInput>,
  orderItems?: ?Array<OrderItemUpdateWrapperInput>,
  batches?: ?Array<BatchUpdateWrapperInput>,
  shipments?: ?Array<ShipmentUpdateWrapperInput>,
  containers?: ?Array<ContainerUpdateWrapperInput>
};


export type SheetEntitiesMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ entitiesUpdateMany: ({
      ...{| __typename: 'EntitiesMany' |},
    ...{ products?: ?Array<?{| __typename: 'Product' |} | ({
        ...{| __typename: 'BadRequest' |},
      ...{ violations: Array<({
          ...{| __typename: 'Violation' |},
        ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
        ...{ parameters: Array<({
            ...{| __typename: 'ViolationParameter' |},
          ...$Pick<ViolationParameter, { key: *, value: * }>
        })> }
      })> }
    }) | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, productProviders?: ?Array<?{| __typename: 'ProductProvider' |} | ({
        ...{| __typename: 'BadRequest' |},
      ...{ violations: Array<({
          ...{| __typename: 'Violation' |},
        ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
        ...{ parameters: Array<({
            ...{| __typename: 'ViolationParameter' |},
          ...$Pick<ViolationParameter, { key: *, value: * }>
        })> }
      })> }
    }) | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, productProviderPackages?: ?Array<?{| __typename: 'ProductProviderPackage' |} | ({
        ...{| __typename: 'BadRequest' |},
      ...{ violations: Array<({
          ...{| __typename: 'Violation' |},
        ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
        ...{ parameters: Array<({
            ...{| __typename: 'ViolationParameter' |},
          ...$Pick<ViolationParameter, { key: *, value: * }>
        })> }
      })> }
    }) | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, orders?: ?Array<?{| __typename: 'Order' |} | ({
        ...{| __typename: 'BadRequest' |},
      ...{ violations: Array<({
          ...{| __typename: 'Violation' |},
        ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
        ...{ parameters: Array<({
            ...{| __typename: 'ViolationParameter' |},
          ...$Pick<ViolationParameter, { key: *, value: * }>
        })> }
      })> }
    }) | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, orderItems?: ?Array<?{| __typename: 'OrderItem' |} | ({
        ...{| __typename: 'BadRequest' |},
      ...{ violations: Array<({
          ...{| __typename: 'Violation' |},
        ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
        ...{ parameters: Array<({
            ...{| __typename: 'ViolationParameter' |},
          ...$Pick<ViolationParameter, { key: *, value: * }>
        })> }
      })> }
    }) | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, batches?: ?Array<?{| __typename: 'Batch' |} | ({
        ...{| __typename: 'BadRequest' |},
      ...{ violations: Array<({
          ...{| __typename: 'Violation' |},
        ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
        ...{ parameters: Array<({
            ...{| __typename: 'ViolationParameter' |},
          ...$Pick<ViolationParameter, { key: *, value: * }>
        })> }
      })> }
    }) | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, shipments?: ?Array<?{| __typename: 'Shipment' |} | ({
        ...{| __typename: 'BadRequest' |},
      ...{ violations: Array<({
          ...{| __typename: 'Violation' |},
        ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
        ...{ parameters: Array<({
            ...{| __typename: 'ViolationParameter' |},
          ...$Pick<ViolationParameter, { key: *, value: * }>
        })> }
      })> }
    }) | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, containers?: ?Array<?{| __typename: 'Container' |} | ({
        ...{| __typename: 'BadRequest' |},
      ...{ violations: Array<({
          ...{| __typename: 'Violation' |},
        ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
        ...{ parameters: Array<({
            ...{| __typename: 'ViolationParameter' |},
          ...$Pick<ViolationParameter, { key: *, value: * }>
        })> }
      })> }
    }) | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}> }
  }) }
});

export type FilesByIDsQueryVariables = {
  ids: Array<$ElementType<Scalars, 'ID'>>
};


export type FilesByIDsQuery = ({
    ...{| __typename: 'Query' |},
  ...{ filesByIDs: Array<({
      ...{| __typename: 'File' |},
    ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
    ...{ tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, entity?: ?({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: *, name: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: *, name: * }>,
      ...{ product: ({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: *, poNo: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: * }>
    }) | ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | ({
        ...{| __typename: 'Partnership' |},
      ...$Pick<Partnership, { id: * }>
    }) | ({
        ...{| __typename: 'Role' |},
      ...$Pick<Role, { id: * }>
    }) | ({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Mask' |},
      ...$Pick<Mask, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'MaskEdit' |},
      ...$Pick<MaskEdit, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'FieldDefinition' |},
      ...$Pick<FieldDefinition, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type MaskByIdQueryVariables = {
  id: $ElementType<Scalars, 'ID'>
};


export type MaskByIdQuery = ({
    ...{| __typename: 'Query' |},
  ...{ mask: ({
      ...{| __typename: 'Mask' |},
    ...$Pick<Mask, { id: *, name: * }>,
    ...{ fieldDefinitions: Array<({
        ...{| __typename: 'FieldDefinition' |},
      ...$Pick<FieldDefinition, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type OrganizationByIdQueryVariables = {
  id: $ElementType<Scalars, 'ID'>
};


export type OrganizationByIdQuery = ({
    ...{| __typename: 'Query' |},
  ...{ organization: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>,
    ...{ partner?: ?({
        ...{| __typename: 'Partner' |},
      ...$Pick<Partner, { id: *, name?: *, code?: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type UserByIdQueryVariables = {
  id: $ElementType<Scalars, 'ID'>
};


export type UserByIdQuery = ({
    ...{| __typename: 'Query' |},
  ...{ user: ({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type UsersByIDsQueryVariables = {
  ids: Array<$ElementType<Scalars, 'ID'>>
};


export type UsersByIDsQuery = ({
    ...{| __typename: 'Query' |},
  ...{ usersByIDs: Array<({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type SheetContainerFragment = ({
    ...{| __typename: 'Container' |},
  ...$Pick<Container, { no: *, archived: *, autoCalculatedFreeTimeStartDate: *, freeTimeStartDate?: *, freeTimeDuration?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDateApprovedAt?: *, yardName?: *, departureDate?: *, departureDateApprovedAt?: *, containerType?: *, containerOption?: *, memo?: *, sort: * }>,
  ...{ connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, representativeBatch?: ?({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
      ...{| __typename: 'Warehouse' |},
    ...$Pick<Warehouse, { id: *, name: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, terminalArrivalPort?: ?({
      ...{| __typename: 'Port' |},
    ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
  }), terminalArrival: ({
      ...{| __typename: 'TimelineDate' |},
    ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
    ...{ connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
      ...{ updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, batchQuantity: ({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), totalVolume: ({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), totalWeight: ({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), totalPrice?: ?({
      ...{| __typename: 'NewPrice' |},
    ...$Pick<NewPrice, { amount: *, currency: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, departureDateApprovedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, batches: Array<({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { autoCalculatePackageQuantity: *, quantity: *, deliveredQuantity?: *, postShippedQuantity?: *, shippedQuantity?: *, preShippedQuantity?: *, producedQuantity?: *, packageQuantity?: * }>,
    ...{ orderItem: ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, shipment: ({
      ...{| __typename: 'Shipment' |},
    ...{ voyages: Array<({
        ...{| __typename: 'Voyage' |},
      ...{ arrival: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { currentDate?: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type SheetContainerMergedFragment = ({
    ...{| __typename: 'Container' |},
  ...$Pick<Container, { no: *, archived: *, autoCalculatedFreeTimeStartDate: *, freeTimeStartDate?: *, freeTimeDuration?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDateApprovedAt?: *, yardName?: *, departureDate?: *, departureDateApprovedAt?: *, containerType?: *, containerOption?: *, memo?: *, sort: *, id: * }>,
  ...{ connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { displayName: *, id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, representativeBatch?: ?({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
      ...{| __typename: 'Warehouse' |},
    ...$Pick<Warehouse, { id: *, name: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, terminalArrivalPort?: ?({
      ...{| __typename: 'Port' |},
    ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
  }), terminalArrival: ({
      ...{| __typename: 'TimelineDate' |},
    ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
    ...{ connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
      ...{ updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, batchQuantity: ({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), totalVolume: ({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), totalWeight: ({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), totalPrice?: ?({
      ...{| __typename: 'NewPrice' |},
    ...$Pick<NewPrice, { amount: *, currency: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, departureDateApprovedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, batches: Array<({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { autoCalculatePackageQuantity: *, quantity: *, deliveredQuantity?: *, postShippedQuantity?: *, shippedQuantity?: *, preShippedQuantity?: *, producedQuantity?: *, packageQuantity?: * }>,
    ...{ orderItem: ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, shipment: ({
      ...{| __typename: 'Shipment' |},
    ...{ voyages: Array<({
        ...{| __typename: 'Voyage' |},
      ...{ arrival: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { currentDate?: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
      ...{| __typename: 'CustomFields' |},
    ...{ fieldValues: Array<({
        ...{| __typename: 'FieldValue' |},
      ...{ value: ({
          ...{| __typename: 'StringValue' |},
        ...$Pick<StringValue, { string: * }>
      }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) }
});

export type SheetContainerMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  input: ContainerUpdateInput
};


export type SheetContainerMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ containerUpdate: {| __typename: 'Container' |} | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |} }
});

export type SheetOrderFragment = ({
    ...{| __typename: 'Order' |},
  ...$Pick<Order, { createdAt: *, updatedAt: *, archived: *, poNo: *, memo?: *, issuedAt?: *, piNo?: *, currency: *, calculatedOrderItemUnit: *, totalOrdered: *, incoterm?: *, deliveryPlace?: *, deliveryDate?: *, hasFile: * }>,
  ...{ createdBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, followers: Array<({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, importer: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, files: Array<({
      ...{| __typename: 'File' |},
    ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
    ...{ tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, entity?: ?({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: *, name: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: *, name: * }>,
      ...{ product: ({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: *, poNo: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: * }>
    }) | ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | ({
        ...{| __typename: 'Partnership' |},
      ...$Pick<Partnership, { id: * }>
    }) | ({
        ...{| __typename: 'Role' |},
      ...$Pick<Role, { id: * }>
    }) | ({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Mask' |},
      ...$Pick<Mask, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'MaskEdit' |},
      ...$Pick<MaskEdit, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'FieldDefinition' |},
      ...$Pick<FieldDefinition, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, organizations: Array<({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type SheetOrderMergedFragment = ({
    ...{| __typename: 'Order' |},
  ...$Pick<Order, { createdAt: *, updatedAt: *, archived: *, poNo: *, memo?: *, issuedAt?: *, piNo?: *, currency: *, calculatedOrderItemUnit: *, totalOrdered: *, incoterm?: *, deliveryPlace?: *, deliveryDate?: *, hasFile: *, id: * }>,
  ...{ createdBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { displayName: *, id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, followers: Array<({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, importer: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, files: Array<({
      ...{| __typename: 'File' |},
    ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
    ...{ tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, entity?: ?({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: *, name: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: *, name: * }>,
      ...{ product: ({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: *, poNo: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: * }>
    }) | ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | ({
        ...{| __typename: 'Partnership' |},
      ...$Pick<Partnership, { id: * }>
    }) | ({
        ...{| __typename: 'Role' |},
      ...$Pick<Role, { id: * }>
    }) | ({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Mask' |},
      ...$Pick<Mask, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'MaskEdit' |},
      ...$Pick<MaskEdit, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'FieldDefinition' |},
      ...$Pick<FieldDefinition, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, organizations: Array<({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
      ...{| __typename: 'CustomFields' |},
    ...{ fieldValues: Array<({
        ...{| __typename: 'FieldValue' |},
      ...{ value: ({
          ...{| __typename: 'StringValue' |},
        ...$Pick<StringValue, { string: * }>
      }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) }
});

export type SheetOrderMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  input: OrderUpdateInput
};


export type SheetOrderMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ orderUpdate: {| __typename: 'Order' |} | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |} }
});

export type SheetOrderItemFragment = ({
    ...{| __typename: 'OrderItem' |},
  ...$Pick<OrderItem, { createdAt: *, updatedAt: *, no: *, quantity: *, priceUnit: *, calculatedUnit: *, archived: *, deliveryDate?: *, memo?: *, hasFile: *, sort: * }>,
  ...{ createdBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, price: ({
      ...{| __typename: 'NewPrice' |},
    ...$Pick<NewPrice, { amount: *, currency: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalPrice: ({
      ...{| __typename: 'NewPrice' |},
    ...$Pick<NewPrice, { amount: *, currency: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, batches: Array<({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { id: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: * }>,
    ...{ shipment?: ?{| __typename: 'Shipment' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, files: Array<({
      ...{| __typename: 'File' |},
    ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
    ...{ tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, entity?: ?({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: *, name: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: *, name: * }>,
      ...{ product: ({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: *, poNo: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: * }>
    }) | ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | ({
        ...{| __typename: 'Partnership' |},
      ...$Pick<Partnership, { id: * }>
    }) | ({
        ...{| __typename: 'Role' |},
      ...$Pick<Role, { id: * }>
    }) | ({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Mask' |},
      ...$Pick<Mask, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'MaskEdit' |},
      ...$Pick<MaskEdit, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'FieldDefinition' |},
      ...$Pick<FieldDefinition, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}> }
});

export type SheetOrderItemMergedFragment = ({
    ...{| __typename: 'OrderItem' |},
  ...$Pick<OrderItem, { createdAt: *, updatedAt: *, no: *, quantity: *, priceUnit: *, calculatedUnit: *, archived: *, deliveryDate?: *, memo?: *, hasFile: *, sort: *, id: * }>,
  ...{ createdBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, price: ({
      ...{| __typename: 'NewPrice' |},
    ...$Pick<NewPrice, { amount: *, currency: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalPrice: ({
      ...{| __typename: 'NewPrice' |},
    ...$Pick<NewPrice, { amount: *, currency: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, batches: Array<({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { id: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: * }>,
    ...{ shipment?: ?{| __typename: 'Shipment' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, files: Array<({
      ...{| __typename: 'File' |},
    ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
    ...{ tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, entity?: ?({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: *, name: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: *, name: * }>,
      ...{ product: ({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: *, poNo: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: * }>
    }) | ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | ({
        ...{| __typename: 'Partnership' |},
      ...$Pick<Partnership, { id: * }>
    }) | ({
        ...{| __typename: 'Role' |},
      ...$Pick<Role, { id: * }>
    }) | ({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Mask' |},
      ...$Pick<Mask, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'MaskEdit' |},
      ...$Pick<MaskEdit, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'FieldDefinition' |},
      ...$Pick<FieldDefinition, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
      ...{| __typename: 'CustomFields' |},
    ...{ fieldValues: Array<({
        ...{| __typename: 'FieldValue' |},
      ...{ value: ({
          ...{| __typename: 'StringValue' |},
        ...$Pick<StringValue, { string: * }>
      }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) }
});

export type SheetOrderItemMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  input: OrderItemUpdateInput
};


export type SheetOrderItemMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ orderItemUpdate: {| __typename: 'OrderItem' |} | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |} }
});

export type SheetProductFragment = ({
    ...{| __typename: 'Product' |},
  ...$Pick<Product, { createdAt: *, updatedAt: *, name: *, serial: *, material?: * }>,
  ...{ createdBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, files: Array<({
      ...{| __typename: 'File' |},
    ...$Pick<File, { id: *, path: * }>,
    ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, productProviders: Array<({
      ...{| __typename: 'ProductProvider' |},
    ...{ connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type SheetProductMergedFragment = ({
    ...{| __typename: 'Product' |},
  ...$Pick<Product, { createdAt: *, updatedAt: *, name: *, serial: *, material?: *, id: * }>,
  ...{ createdBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, files: Array<({
      ...{| __typename: 'File' |},
    ...$Pick<File, { id: *, path: * }>,
    ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, productProviders: Array<({
      ...{| __typename: 'ProductProvider' |},
    ...{ connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
      ...{| __typename: 'CustomFields' |},
    ...{ fieldValues: Array<({
        ...{| __typename: 'FieldValue' |},
      ...{ value: ({
          ...{| __typename: 'StringValue' |},
        ...$Pick<StringValue, { string: * }>
      }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) }
});

export type SheetProductMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  input: ProductUpdateInput
};


export type SheetProductMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ productUpdate: {| __typename: 'Product' |} | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |} }
});

export type SheetProductProviderFragment = ({
    ...{| __typename: 'ProductProvider' |},
  ...$Pick<ProductProvider, { createdAt: *, updatedAt: *, id: *, name: *, hasFile: * }>,
  ...{ createdBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, unitPrice?: ?({
      ...{| __typename: 'NewPrice' |},
    ...$Pick<NewPrice, { amount: *, currency: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, files: Array<({
      ...{| __typename: 'File' |},
    ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
    ...{ tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, entity?: ?({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: *, name: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: *, name: * }>,
      ...{ product: ({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: *, poNo: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: * }>
    }) | ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | ({
        ...{| __typename: 'Partnership' |},
      ...$Pick<Partnership, { id: * }>
    }) | ({
        ...{| __typename: 'Role' |},
      ...$Pick<Role, { id: * }>
    }) | ({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Mask' |},
      ...$Pick<Mask, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'MaskEdit' |},
      ...$Pick<MaskEdit, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'FieldDefinition' |},
      ...$Pick<FieldDefinition, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}> }
});

export type SheetProductProviderMergedFragment = ({
    ...{| __typename: 'ProductProvider' |},
  ...$Pick<ProductProvider, { createdAt: *, updatedAt: *, id: *, name: *, hasFile: * }>,
  ...{ createdBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { displayName: *, id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, unitPrice?: ?({
      ...{| __typename: 'NewPrice' |},
    ...$Pick<NewPrice, { amount: *, currency: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, files: Array<({
      ...{| __typename: 'File' |},
    ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
    ...{ tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, entity?: ?({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: *, name: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: *, name: * }>,
      ...{ product: ({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: *, poNo: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: * }>
    }) | ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | ({
        ...{| __typename: 'Partnership' |},
      ...$Pick<Partnership, { id: * }>
    }) | ({
        ...{| __typename: 'Role' |},
      ...$Pick<Role, { id: * }>
    }) | ({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Mask' |},
      ...$Pick<Mask, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'MaskEdit' |},
      ...$Pick<MaskEdit, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'FieldDefinition' |},
      ...$Pick<FieldDefinition, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
      ...{| __typename: 'CustomFields' |},
    ...{ fieldValues: Array<({
        ...{| __typename: 'FieldValue' |},
      ...{ value: ({
          ...{| __typename: 'StringValue' |},
        ...$Pick<StringValue, { string: * }>
      }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) }
});

export type SheetProductProviderMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  input: ProductProviderUpdateInput
};


export type SheetProductProviderMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ productProviderUpdate: {| __typename: 'ProductProvider' |} | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |} }
});

export type SheetProductProviderPackageMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  input: ProductProviderPackageUpdateInput
};


export type SheetProductProviderPackageMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ productProviderPackageUpdate: {| __typename: 'ProductProviderPackage' |} | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |} }
});

export type ChangeFragment = ({
    ...{| __typename: 'ChangeType' |},
  ...$Pick<ChangeType, { field: * }>,
  ...{ new?: ?({
      ...{| __typename: 'StringValue' |},
    ...$Pick<StringValue, { string: * }>
  }) | ({
      ...{| __typename: 'IntValue' |},
    ...$Pick<IntValue, { int: * }>
  }) | ({
      ...{| __typename: 'FloatValue' |},
    ...$Pick<FloatValue, { float: * }>
  }) | ({
      ...{| __typename: 'BooleanValue' |},
    ...$Pick<BooleanValue, { boolean: * }>
  }) | ({
      ...{| __typename: 'DateTimeValue' |},
    ...$Pick<DateTimeValue, { datetime: * }>
  }) | ({
      ...{| __typename: 'IntervalValue' |},
    ...{ interval: ({
        ...{| __typename: 'Interval' |},
      ...$Pick<Interval, { days: *, weeks: *, months: * }>
    }) }
  }) | {| __typename: 'DurationValue' |} | ({
      ...{| __typename: 'MetricValueValue' |},
    ...{ metricValue: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }) }
  }) | ({
      ...{| __typename: 'PriceValue' |},
    ...{ priceValue: ({
        ...{| __typename: 'NewPrice' |},
      ...$Pick<NewPrice, { amount: *, currency: * }>
    }) }
  }) | ({
      ...{| __typename: 'SizeValue' |},
    ...{ size: ({
        ...{| __typename: 'Size' |},
      ...{ height: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), width: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), length: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }) }
    }) }
  }) | ({
      ...{| __typename: 'EntityValue' |},
    ...{ entity?: ?({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: * }>
    }) | ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: * }>
    }) | ({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: * }>
    }) | ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: * }>
    }) | ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: * }>
    }) | ({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: * }>
    }) | ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: * }>
    }) | ({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: * }>
    }) | ({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: * }>
    }) | ({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>
    }) | ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: * }>
    }) | ({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: * }>
    }) | ({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: * }>
    }) | ({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: * }>
    }) | ({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: * }>
    }) | ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | ({
        ...{| __typename: 'Partnership' |},
      ...$Pick<Partnership, { id: * }>
    }) | ({
        ...{| __typename: 'Role' |},
      ...$Pick<Role, { id: * }>
    }) | ({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: * }>
    }) | ({
        ...{| __typename: 'Mask' |},
      ...$Pick<Mask, { id: * }>
    }) | ({
        ...{| __typename: 'MaskEdit' |},
      ...$Pick<MaskEdit, { id: * }>
    }) | ({
        ...{| __typename: 'FieldDefinition' |},
      ...$Pick<FieldDefinition, { id: * }>
    }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | ({
      ...{| __typename: 'Values' |},
    ...{ values: Array<?{| __typename: 'StringValue' |} | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | ({
        ...{| __typename: 'EntityValue' |},
      ...{ entity?: ?({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: * }>
      }) | ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: * }>
      }) | ({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: * }>
      }) | ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: * }>
      }) | ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: * }>
      }) | ({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>
      }) | ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: * }>
      }) | ({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: * }>
      }) | ({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: * }>
      }) | ({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>
      }) | ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: * }>
      }) | ({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: * }>
      }) | ({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: * }>
      }) | ({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: * }>
      }) | ({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | ({
          ...{| __typename: 'Partnership' |},
        ...$Pick<Partnership, { id: * }>
      }) | ({
          ...{| __typename: 'Role' |},
        ...$Pick<Role, { id: * }>
      }) | ({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: * }>
      }) | ({
          ...{| __typename: 'Mask' |},
        ...$Pick<Mask, { id: * }>
      }) | ({
          ...{| __typename: 'MaskEdit' |},
        ...$Pick<MaskEdit, { id: * }>
      }) | ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>
      }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'Values' |}> }
  }) }
});

export type FocusFragment = ({
    ...{| __typename: 'Focus' |},
  ...$Pick<Focus, { id: *, field: * }>,
  ...{ entity: ({
      ...{| __typename: 'Product' |},
    ...$Pick<Product, { id: * }>
  }) | ({
      ...{| __typename: 'ProductProvider' |},
    ...$Pick<ProductProvider, { id: * }>
  }) | ({
      ...{| __typename: 'ProductProviderPackage' |},
    ...$Pick<ProductProviderPackage, { id: * }>
  }) | ({
      ...{| __typename: 'Order' |},
    ...$Pick<Order, { id: * }>
  }) | ({
      ...{| __typename: 'OrderItem' |},
    ...$Pick<OrderItem, { id: * }>
  }) | ({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { id: * }>
  }) | ({
      ...{| __typename: 'Shipment' |},
    ...$Pick<Shipment, { id: * }>
  }) | ({
      ...{| __typename: 'Voyage' |},
    ...$Pick<Voyage, { id: * }>
  }) | ({
      ...{| __typename: 'Container' |},
    ...$Pick<Container, { id: * }>
  }) | ({
      ...{| __typename: 'ContainerGroup' |},
    ...$Pick<ContainerGroup, { id: * }>
  }) | ({
      ...{| __typename: 'TimelineDate' |},
    ...$Pick<TimelineDate, { id: * }>
  }) | ({
      ...{| __typename: 'TimelineDateRevision' |},
    ...$Pick<TimelineDateRevision, { id: * }>
  }) | ({
      ...{| __typename: 'Warehouse' |},
    ...$Pick<Warehouse, { id: * }>
  }) | ({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: * }>
  }) | ({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: * }>
  }) | ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | ({
      ...{| __typename: 'Partnership' |},
    ...$Pick<Partnership, { id: * }>
  }) | ({
      ...{| __typename: 'Role' |},
    ...$Pick<Role, { id: * }>
  }) | ({
      ...{| __typename: 'File' |},
    ...$Pick<File, { id: * }>
  }) | ({
      ...{| __typename: 'Mask' |},
    ...$Pick<Mask, { id: * }>
  }) | ({
      ...{| __typename: 'MaskEdit' |},
    ...$Pick<MaskEdit, { id: * }>
  }) | ({
      ...{| __typename: 'FieldDefinition' |},
    ...$Pick<FieldDefinition, { id: * }>
  }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, user: ({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type RealtimeValue_StringValue_Fragment = ({
    ...{| __typename: 'StringValue' |},
  ...$Pick<StringValue, { string: * }>
});

export type RealtimeValue_IntValue_Fragment = ({
    ...{| __typename: 'IntValue' |},
  ...$Pick<IntValue, { int: * }>
});

export type RealtimeValue_FloatValue_Fragment = ({
    ...{| __typename: 'FloatValue' |},
  ...$Pick<FloatValue, { float: * }>
});

export type RealtimeValue_BooleanValue_Fragment = ({
    ...{| __typename: 'BooleanValue' |},
  ...$Pick<BooleanValue, { boolean: * }>
});

export type RealtimeValue_DateTimeValue_Fragment = ({
    ...{| __typename: 'DateTimeValue' |},
  ...$Pick<DateTimeValue, { datetime: * }>
});

export type RealtimeValue_IntervalValue_Fragment = ({
    ...{| __typename: 'IntervalValue' |},
  ...{ interval: ({
      ...{| __typename: 'Interval' |},
    ...$Pick<Interval, { days: *, weeks: *, months: * }>
  }) }
});

export type RealtimeValue_DurationValue_Fragment = {| __typename: 'DurationValue' |};

export type RealtimeValue_MetricValueValue_Fragment = ({
    ...{| __typename: 'MetricValueValue' |},
  ...{ metricValue: ({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }) }
});

export type RealtimeValue_PriceValue_Fragment = ({
    ...{| __typename: 'PriceValue' |},
  ...{ priceValue: ({
      ...{| __typename: 'NewPrice' |},
    ...$Pick<NewPrice, { amount: *, currency: * }>
  }) }
});

export type RealtimeValue_SizeValue_Fragment = ({
    ...{| __typename: 'SizeValue' |},
  ...{ size: ({
      ...{| __typename: 'Size' |},
    ...{ height: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), width: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), length: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }) }
  }) }
});

export type RealtimeValue_EntityValue_Fragment = ({
    ...{| __typename: 'EntityValue' |},
  ...{ entity?: ?({
      ...{| __typename: 'Product' |},
    ...$Pick<Product, { id: * }>
  }) | ({
      ...{| __typename: 'ProductProvider' |},
    ...$Pick<ProductProvider, { id: * }>
  }) | ({
      ...{| __typename: 'ProductProviderPackage' |},
    ...$Pick<ProductProviderPackage, { id: * }>
  }) | ({
      ...{| __typename: 'Order' |},
    ...$Pick<Order, { id: * }>
  }) | ({
      ...{| __typename: 'OrderItem' |},
    ...$Pick<OrderItem, { id: * }>
  }) | ({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { id: * }>
  }) | ({
      ...{| __typename: 'Shipment' |},
    ...$Pick<Shipment, { id: * }>
  }) | ({
      ...{| __typename: 'Voyage' |},
    ...$Pick<Voyage, { id: * }>
  }) | ({
      ...{| __typename: 'Container' |},
    ...$Pick<Container, { id: * }>
  }) | ({
      ...{| __typename: 'ContainerGroup' |},
    ...$Pick<ContainerGroup, { id: * }>
  }) | ({
      ...{| __typename: 'TimelineDate' |},
    ...$Pick<TimelineDate, { id: * }>
  }) | ({
      ...{| __typename: 'TimelineDateRevision' |},
    ...$Pick<TimelineDateRevision, { id: * }>
  }) | ({
      ...{| __typename: 'Warehouse' |},
    ...$Pick<Warehouse, { id: * }>
  }) | ({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: * }>
  }) | ({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: * }>
  }) | ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | ({
      ...{| __typename: 'Partnership' |},
    ...$Pick<Partnership, { id: * }>
  }) | ({
      ...{| __typename: 'Role' |},
    ...$Pick<Role, { id: * }>
  }) | ({
      ...{| __typename: 'File' |},
    ...$Pick<File, { id: * }>
  }) | ({
      ...{| __typename: 'Mask' |},
    ...$Pick<Mask, { id: * }>
  }) | ({
      ...{| __typename: 'MaskEdit' |},
    ...$Pick<MaskEdit, { id: * }>
  }) | ({
      ...{| __typename: 'FieldDefinition' |},
    ...$Pick<FieldDefinition, { id: * }>
  }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type RealtimeValue_Values_Fragment = ({
    ...{| __typename: 'Values' |},
  ...{ values: Array<?{| __typename: 'StringValue' |} | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | ({
      ...{| __typename: 'EntityValue' |},
    ...{ entity?: ?({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: * }>
    }) | ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: * }>
    }) | ({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: * }>
    }) | ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: * }>
    }) | ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: * }>
    }) | ({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: * }>
    }) | ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: * }>
    }) | ({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: * }>
    }) | ({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: * }>
    }) | ({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>
    }) | ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: * }>
    }) | ({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: * }>
    }) | ({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: * }>
    }) | ({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: * }>
    }) | ({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: * }>
    }) | ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | ({
        ...{| __typename: 'Partnership' |},
      ...$Pick<Partnership, { id: * }>
    }) | ({
        ...{| __typename: 'Role' |},
      ...$Pick<Role, { id: * }>
    }) | ({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: * }>
    }) | ({
        ...{| __typename: 'Mask' |},
      ...$Pick<Mask, { id: * }>
    }) | ({
        ...{| __typename: 'MaskEdit' |},
      ...$Pick<MaskEdit, { id: * }>
    }) | ({
        ...{| __typename: 'FieldDefinition' |},
      ...$Pick<FieldDefinition, { id: * }>
    }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'Values' |}> }
});

export type RealtimeValueFragment = RealtimeValue_StringValue_Fragment | RealtimeValue_IntValue_Fragment | RealtimeValue_FloatValue_Fragment | RealtimeValue_BooleanValue_Fragment | RealtimeValue_DateTimeValue_Fragment | RealtimeValue_IntervalValue_Fragment | RealtimeValue_DurationValue_Fragment | RealtimeValue_MetricValueValue_Fragment | RealtimeValue_PriceValue_Fragment | RealtimeValue_SizeValue_Fragment | RealtimeValue_EntityValue_Fragment | RealtimeValue_Values_Fragment;

export type RealtimeValues_StringValue_Fragment = {| __typename: 'StringValue' |};

export type RealtimeValues_IntValue_Fragment = {| __typename: 'IntValue' |};

export type RealtimeValues_FloatValue_Fragment = {| __typename: 'FloatValue' |};

export type RealtimeValues_BooleanValue_Fragment = {| __typename: 'BooleanValue' |};

export type RealtimeValues_DateTimeValue_Fragment = {| __typename: 'DateTimeValue' |};

export type RealtimeValues_IntervalValue_Fragment = {| __typename: 'IntervalValue' |};

export type RealtimeValues_DurationValue_Fragment = {| __typename: 'DurationValue' |};

export type RealtimeValues_MetricValueValue_Fragment = {| __typename: 'MetricValueValue' |};

export type RealtimeValues_PriceValue_Fragment = {| __typename: 'PriceValue' |};

export type RealtimeValues_SizeValue_Fragment = {| __typename: 'SizeValue' |};

export type RealtimeValues_EntityValue_Fragment = {| __typename: 'EntityValue' |};

export type RealtimeValues_Values_Fragment = ({
    ...{| __typename: 'Values' |},
  ...{ values: Array<?{| __typename: 'StringValue' |} | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | ({
      ...{| __typename: 'EntityValue' |},
    ...{ entity?: ?({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: * }>
    }) | ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: * }>
    }) | ({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: * }>
    }) | ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: * }>
    }) | ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: * }>
    }) | ({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: * }>
    }) | ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: * }>
    }) | ({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: * }>
    }) | ({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: * }>
    }) | ({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>
    }) | ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: * }>
    }) | ({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: * }>
    }) | ({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: * }>
    }) | ({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: * }>
    }) | ({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: * }>
    }) | ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | ({
        ...{| __typename: 'Partnership' |},
      ...$Pick<Partnership, { id: * }>
    }) | ({
        ...{| __typename: 'Role' |},
      ...$Pick<Role, { id: * }>
    }) | ({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: * }>
    }) | ({
        ...{| __typename: 'Mask' |},
      ...$Pick<Mask, { id: * }>
    }) | ({
        ...{| __typename: 'MaskEdit' |},
      ...$Pick<MaskEdit, { id: * }>
    }) | ({
        ...{| __typename: 'FieldDefinition' |},
      ...$Pick<FieldDefinition, { id: * }>
    }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'Values' |}> }
});

export type RealtimeValuesFragment = RealtimeValues_StringValue_Fragment | RealtimeValues_IntValue_Fragment | RealtimeValues_FloatValue_Fragment | RealtimeValues_BooleanValue_Fragment | RealtimeValues_DateTimeValue_Fragment | RealtimeValues_IntervalValue_Fragment | RealtimeValues_DurationValue_Fragment | RealtimeValues_MetricValueValue_Fragment | RealtimeValues_PriceValue_Fragment | RealtimeValues_SizeValue_Fragment | RealtimeValues_EntityValue_Fragment | RealtimeValues_Values_Fragment;

export type BlurMutationVariables = {
  id: $ElementType<Scalars, 'ID'>
};


export type BlurMutation = ({
    ...{| __typename: 'Mutation' |},
  ...$Pick<Mutation, { blur: * }>
});

export type EntitySubscribeMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  input: SubscriptionInput
};


export type EntitySubscribeMutation = ({
    ...{| __typename: 'Mutation' |},
  ...$Pick<Mutation, { entitySubscribe: * }>
});

export type FocusMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  input: FocusingInput
};


export type FocusMutation = ({
    ...{| __typename: 'Mutation' |},
  ...$Pick<Mutation, { focus: * }>
});

export type FocusSubscribeMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  input: SubscriptionInput
};


export type FocusSubscribeMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ focusSubscribe: Array<({
      ...{| __typename: 'Focus' |},
    ...$Pick<Focus, { id: *, field: * }>,
    ...{ entity: ({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: * }>
    }) | ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: * }>
    }) | ({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: * }>
    }) | ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: * }>
    }) | ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: * }>
    }) | ({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: * }>
    }) | ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: * }>
    }) | ({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: * }>
    }) | ({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: * }>
    }) | ({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>
    }) | ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: * }>
    }) | ({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: * }>
    }) | ({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: * }>
    }) | ({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: * }>
    }) | ({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: * }>
    }) | ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | ({
        ...{| __typename: 'Partnership' |},
      ...$Pick<Partnership, { id: * }>
    }) | ({
        ...{| __typename: 'Role' |},
      ...$Pick<Role, { id: * }>
    }) | ({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: * }>
    }) | ({
        ...{| __typename: 'Mask' |},
      ...$Pick<Mask, { id: * }>
    }) | ({
        ...{| __typename: 'MaskEdit' |},
      ...$Pick<MaskEdit, { id: * }>
    }) | ({
        ...{| __typename: 'FieldDefinition' |},
      ...$Pick<FieldDefinition, { id: * }>
    }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, user: ({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type EntityEventSubscriptionVariables = {
  id: $ElementType<Scalars, 'ID'>
};


export type EntityEventSubscription = ({
    ...{| __typename: 'Subscription' |},
  ...{ entityEvent: ({
      ...{| __typename: 'EntityEvent' |},
    ...$Pick<EntityEvent, { lifeCycle: * }>,
    ...{ entity: ({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: * }>
    }) | ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: * }>
    }) | ({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: * }>
    }) | ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: * }>
    }) | ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: * }>
    }) | ({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: * }>
    }) | ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: * }>
    }) | ({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: * }>
    }) | ({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: * }>
    }) | ({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>
    }) | ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: * }>
    }) | ({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: * }>
    }) | ({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: * }>
    }) | ({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: * }>
    }) | ({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: * }>
    }) | ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | ({
        ...{| __typename: 'Partnership' |},
      ...$Pick<Partnership, { id: * }>
    }) | ({
        ...{| __typename: 'Role' |},
      ...$Pick<Role, { id: * }>
    }) | ({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: * }>
    }) | ({
        ...{| __typename: 'Mask' |},
      ...$Pick<Mask, { id: * }>
    }) | ({
        ...{| __typename: 'MaskEdit' |},
      ...$Pick<MaskEdit, { id: * }>
    }) | ({
        ...{| __typename: 'FieldDefinition' |},
      ...$Pick<FieldDefinition, { id: * }>
    }) | ({
        ...{| __typename: 'FieldValue' |},
      ...{ fieldDefinition: ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: ({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: * }>
      }) | ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: * }>
      }) | ({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: * }>
      }) | ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: * }>
      }) | ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: * }>
      }) | ({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>
      }) | ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: * }>
      }) | ({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: * }>
      }) | ({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: * }>
      }) | ({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>
      }) | ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: * }>
      }) | ({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: * }>
      }) | ({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: * }>
      }) | ({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: * }>
      }) | ({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | ({
          ...{| __typename: 'Partnership' |},
        ...$Pick<Partnership, { id: * }>
      }) | ({
          ...{| __typename: 'Role' |},
        ...$Pick<Role, { id: * }>
      }) | ({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: * }>
      }) | ({
          ...{| __typename: 'Mask' |},
        ...$Pick<Mask, { id: * }>
      }) | ({
          ...{| __typename: 'MaskEdit' |},
        ...$Pick<MaskEdit, { id: * }>
      }) | ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>
      }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, changes?: ?Array<({
        ...{| __typename: 'ChangeType' |},
      ...$Pick<ChangeType, { field: * }>,
      ...{ new?: ?({
          ...{| __typename: 'StringValue' |},
        ...$Pick<StringValue, { string: * }>
      }) | ({
          ...{| __typename: 'IntValue' |},
        ...$Pick<IntValue, { int: * }>
      }) | ({
          ...{| __typename: 'FloatValue' |},
        ...$Pick<FloatValue, { float: * }>
      }) | ({
          ...{| __typename: 'BooleanValue' |},
        ...$Pick<BooleanValue, { boolean: * }>
      }) | ({
          ...{| __typename: 'DateTimeValue' |},
        ...$Pick<DateTimeValue, { datetime: * }>
      }) | ({
          ...{| __typename: 'IntervalValue' |},
        ...{ interval: ({
            ...{| __typename: 'Interval' |},
          ...$Pick<Interval, { days: *, weeks: *, months: * }>
        }) }
      }) | {| __typename: 'DurationValue' |} | ({
          ...{| __typename: 'MetricValueValue' |},
        ...{ metricValue: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }) }
      }) | ({
          ...{| __typename: 'PriceValue' |},
        ...{ priceValue: ({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) }
      }) | ({
          ...{| __typename: 'SizeValue' |},
        ...{ size: ({
            ...{| __typename: 'Size' |},
          ...{ height: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), width: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), length: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }) }
        }) }
      }) | ({
          ...{| __typename: 'EntityValue' |},
        ...{ entity?: ?({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: * }>
        }) | ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: * }>
        }) | ({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: * }>
        }) | ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: * }>
        }) | ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>
        }) | ({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>
        }) | ({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: * }>
        }) | ({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: * }>
        }) | ({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: * }>
        }) | ({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>
        }) | ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: * }>
        }) | ({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: * }>
        }) | ({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: * }>
        }) | ({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: * }>
        }) | ({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | ({
            ...{| __typename: 'Partnership' |},
          ...$Pick<Partnership, { id: * }>
        }) | ({
            ...{| __typename: 'Role' |},
          ...$Pick<Role, { id: * }>
        }) | ({
            ...{| __typename: 'File' |},
          ...$Pick<File, { id: * }>
        }) | ({
            ...{| __typename: 'Mask' |},
          ...$Pick<Mask, { id: * }>
        }) | ({
            ...{| __typename: 'MaskEdit' |},
          ...$Pick<MaskEdit, { id: * }>
        }) | ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: * }>
        }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Values' |},
        ...{ values: Array<?{| __typename: 'StringValue' |} | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | ({
            ...{| __typename: 'EntityValue' |},
          ...{ entity?: ?({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: * }>
          }) | ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>
          }) | ({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: * }>
          }) | ({
              ...{| __typename: 'Order' |},
            ...$Pick<Order, { id: * }>
          }) | ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>
          }) | ({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: * }>
          }) | ({
              ...{| __typename: 'Shipment' |},
            ...$Pick<Shipment, { id: * }>
          }) | ({
              ...{| __typename: 'Voyage' |},
            ...$Pick<Voyage, { id: * }>
          }) | ({
              ...{| __typename: 'Container' |},
            ...$Pick<Container, { id: * }>
          }) | ({
              ...{| __typename: 'ContainerGroup' |},
            ...$Pick<ContainerGroup, { id: * }>
          }) | ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: * }>
          }) | ({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: * }>
          }) | ({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: * }>
          }) | ({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: * }>
          }) | ({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: * }>
          }) | ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: * }>
          }) | ({
              ...{| __typename: 'Partnership' |},
            ...$Pick<Partnership, { id: * }>
          }) | ({
              ...{| __typename: 'Role' |},
            ...$Pick<Role, { id: * }>
          }) | ({
              ...{| __typename: 'File' |},
            ...$Pick<File, { id: * }>
          }) | ({
              ...{| __typename: 'Mask' |},
            ...$Pick<Mask, { id: * }>
          }) | ({
              ...{| __typename: 'MaskEdit' |},
            ...$Pick<MaskEdit, { id: * }>
          }) | ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: * }>
          }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'Values' |}> }
      }) }
    })> }
  }) }
});

export type FocusEventSubscriptionVariables = {
  id: $ElementType<Scalars, 'ID'>
};


export type FocusEventSubscription = ({
    ...{| __typename: 'Subscription' |},
  ...{ focusEvent: ({
      ...{| __typename: 'Focus' |},
    ...$Pick<Focus, { id: *, field: * }>,
    ...{ entity: ({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: * }>
    }) | ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: * }>
    }) | ({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: * }>
    }) | ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: * }>
    }) | ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: * }>
    }) | ({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: * }>
    }) | ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: * }>
    }) | ({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: * }>
    }) | ({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: * }>
    }) | ({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>
    }) | ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: * }>
    }) | ({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: * }>
    }) | ({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: * }>
    }) | ({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: * }>
    }) | ({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: * }>
    }) | ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | ({
        ...{| __typename: 'Partnership' |},
      ...$Pick<Partnership, { id: * }>
    }) | ({
        ...{| __typename: 'Role' |},
      ...$Pick<Role, { id: * }>
    }) | ({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: * }>
    }) | ({
        ...{| __typename: 'Mask' |},
      ...$Pick<Mask, { id: * }>
    }) | ({
        ...{| __typename: 'MaskEdit' |},
      ...$Pick<MaskEdit, { id: * }>
    }) | ({
        ...{| __typename: 'FieldDefinition' |},
      ...$Pick<FieldDefinition, { id: * }>
    }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, user: ({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | ({
      ...{| __typename: 'Blur' |},
    ...$Pick<Blur, { id: * }>
  }) }
});

export type SheetShipmentBatchFragment = ({
    ...{| __typename: 'Batch' |},
  ...$Pick<Batch, { createdAt: *, updatedAt: *, no: *, quantity: *, quantityUnit: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, deliveredAt?: *, desiredAt?: *, totalVolumeOverriding: *, expiredAt?: *, producedAt?: *, packageName?: *, packageCapacity?: *, packageQuantity?: *, autoCalculatePackageQuantity: *, latestQuantity: *, archived: *, autoCalculatePackageVolume: *, memo?: *, sort: *, containerSort: *, shipmentSort: *, id: * }>,
  ...{ orderItem: ({
      ...{| __typename: 'OrderItem' |},
    ...$Pick<OrderItem, { createdAt: *, updatedAt: *, no: *, quantity: *, priceUnit: *, calculatedUnit: *, archived: *, deliveryDate?: *, memo?: *, hasFile: *, sort: *, id: * }>,
    ...{ order: ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { createdAt: *, updatedAt: *, archived: *, poNo: *, memo?: *, issuedAt?: *, piNo?: *, currency: *, calculatedOrderItemUnit: *, totalOrdered: *, incoterm?: *, deliveryPlace?: *, deliveryDate?: *, hasFile: *, id: * }>,
      ...{ createdBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { displayName: *, id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, followers: Array<({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, importer: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, files: Array<({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
        ...{ tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, entity?: ?({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: *, name: * }>,
          ...{ product: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: *, poNo: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | ({
            ...{| __typename: 'Partnership' |},
          ...$Pick<Partnership, { id: * }>
        }) | ({
            ...{| __typename: 'Role' |},
          ...$Pick<Role, { id: * }>
        }) | ({
            ...{| __typename: 'File' |},
          ...$Pick<File, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Mask' |},
          ...$Pick<Mask, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'MaskEdit' |},
          ...$Pick<MaskEdit, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, organizations: Array<({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
          ...{| __typename: 'CustomFields' |},
        ...{ fieldValues: Array<({
            ...{| __typename: 'FieldValue' |},
          ...{ value: ({
              ...{| __typename: 'StringValue' |},
            ...$Pick<StringValue, { string: * }>
          }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { createdAt: *, updatedAt: *, id: *, name: *, hasFile: * }>,
      ...{ product: ({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { createdAt: *, updatedAt: *, name: *, serial: *, material?: *, id: * }>,
        ...{ createdBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, files: Array<({
            ...{| __typename: 'File' |},
          ...$Pick<File, { id: *, path: * }>,
          ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, productProviders: Array<({
            ...{| __typename: 'ProductProvider' |},
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
            ...{| __typename: 'CustomFields' |},
          ...{ fieldValues: Array<({
              ...{| __typename: 'FieldValue' |},
            ...{ value: ({
                ...{| __typename: 'StringValue' |},
              ...$Pick<StringValue, { string: * }>
            }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
                ...{| __typename: 'FieldDefinition' |},
              ...$Pick<FieldDefinition, { id: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, createdBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { displayName: *, id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, unitPrice?: ?({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, files: Array<({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
        ...{ tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, entity?: ?({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: *, name: * }>,
          ...{ product: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: *, poNo: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | ({
            ...{| __typename: 'Partnership' |},
          ...$Pick<Partnership, { id: * }>
        }) | ({
            ...{| __typename: 'Role' |},
          ...$Pick<Role, { id: * }>
        }) | ({
            ...{| __typename: 'File' |},
          ...$Pick<File, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Mask' |},
          ...$Pick<Mask, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'MaskEdit' |},
          ...$Pick<MaskEdit, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
          ...{| __typename: 'CustomFields' |},
        ...{ fieldValues: Array<({
            ...{| __typename: 'FieldValue' |},
          ...{ value: ({
              ...{| __typename: 'StringValue' |},
            ...$Pick<StringValue, { string: * }>
          }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, createdBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, price: ({
        ...{| __typename: 'NewPrice' |},
      ...$Pick<NewPrice, { amount: *, currency: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalPrice: ({
        ...{| __typename: 'NewPrice' |},
      ...$Pick<NewPrice, { amount: *, currency: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, batches: Array<({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: * }>,
      ...{ shipment?: ?{| __typename: 'Shipment' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, files: Array<({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
      ...{ tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, entity?: ?({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, poNo: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | ({
          ...{| __typename: 'Partnership' |},
        ...$Pick<Partnership, { id: * }>
      }) | ({
          ...{| __typename: 'Role' |},
        ...$Pick<Role, { id: * }>
      }) | ({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Mask' |},
        ...$Pick<Mask, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'MaskEdit' |},
        ...$Pick<MaskEdit, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
        ...{| __typename: 'CustomFields' |},
      ...{ fieldValues: Array<({
          ...{| __typename: 'FieldValue' |},
        ...{ value: ({
            ...{| __typename: 'StringValue' |},
          ...$Pick<StringValue, { string: * }>
        }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, createdBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { displayName: *, id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalPrice?: ?({
      ...{| __typename: 'NewPrice' |},
    ...$Pick<NewPrice, { amount: *, currency: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), totalVolumeOverride?: ?({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), packageGrossWeight?: ?({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), packageVolume?: ?({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), packageSize?: ?({
      ...{| __typename: 'Size' |},
    ...{ length: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), width: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), height: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }) }
  }), tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, container?: ?({
      ...{| __typename: 'Container' |},
    ...$Pick<Container, { warehouseArrivalActualDate?: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment?: ?({
      ...{| __typename: 'Shipment' |},
    ...{ containerGroups: Array<({
        ...{| __typename: 'ContainerGroup' |},
      ...{ warehouseArrival: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { date?: * }>,
        ...{ timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, voyages: Array<({
        ...{| __typename: 'Voyage' |},
      ...{ departure: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { date?: * }>,
        ...{ timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { warehouseArrivalActualDate?: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
      ...{| __typename: 'CustomFields' |},
    ...{ fieldValues: Array<({
        ...{| __typename: 'FieldValue' |},
      ...{ value: ({
          ...{| __typename: 'StringValue' |},
        ...$Pick<StringValue, { string: * }>
      }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) }
});

export type SheetShipmentContainerFragment = ({
    ...{| __typename: 'Container' |},
  ...$Pick<Container, { no: *, archived: *, autoCalculatedFreeTimeStartDate: *, freeTimeStartDate?: *, freeTimeDuration?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDateApprovedAt?: *, yardName?: *, departureDate?: *, departureDateApprovedAt?: *, containerType?: *, containerOption?: *, memo?: *, sort: *, id: * }>,
  ...{ batchQuantity: ({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), batches: Array<({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { autoCalculatePackageQuantity: *, quantity: *, deliveredQuantity?: *, postShippedQuantity?: *, shippedQuantity?: *, preShippedQuantity?: *, producedQuantity?: *, packageQuantity?: *, createdAt: *, updatedAt: *, no: *, quantityUnit: *, deliveredAt?: *, desiredAt?: *, totalVolumeOverriding: *, expiredAt?: *, producedAt?: *, packageName?: *, packageCapacity?: *, latestQuantity: *, archived: *, autoCalculatePackageVolume: *, memo?: *, sort: *, containerSort: *, shipmentSort: *, id: * }>,
    ...{ orderItem: ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: *, createdAt: *, updatedAt: *, no: *, quantity: *, priceUnit: *, calculatedUnit: *, archived: *, deliveryDate?: *, memo?: *, hasFile: *, sort: * }>,
      ...{ order: ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { createdAt: *, updatedAt: *, archived: *, poNo: *, memo?: *, issuedAt?: *, piNo?: *, currency: *, calculatedOrderItemUnit: *, totalOrdered: *, incoterm?: *, deliveryPlace?: *, deliveryDate?: *, hasFile: *, id: * }>,
        ...{ createdBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { displayName: *, id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, followers: Array<({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, importer: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, files: Array<({
            ...{| __typename: 'File' |},
          ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
          ...{ tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, entity?: ?({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: *, name: * }>,
            ...{ product: ({
                ...{| __typename: 'Product' |},
              ...$Pick<Product, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Order' |},
            ...$Pick<Order, { id: *, poNo: * }>,
            ...{ connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: *, no: * }>,
            ...{ connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: *, no: * }>,
            ...{ connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Shipment' |},
            ...$Pick<Shipment, { id: *, no: * }>,
            ...{ connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Voyage' |},
            ...$Pick<Voyage, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Container' |},
            ...$Pick<Container, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'ContainerGroup' |},
            ...$Pick<ContainerGroup, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: * }>
          }) | ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: * }>
          }) | ({
              ...{| __typename: 'Partnership' |},
            ...$Pick<Partnership, { id: * }>
          }) | ({
              ...{| __typename: 'Role' |},
            ...$Pick<Role, { id: * }>
          }) | ({
              ...{| __typename: 'File' |},
            ...$Pick<File, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Mask' |},
            ...$Pick<Mask, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'MaskEdit' |},
            ...$Pick<MaskEdit, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, organizations: Array<({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
            ...{| __typename: 'CustomFields' |},
          ...{ fieldValues: Array<({
              ...{| __typename: 'FieldValue' |},
            ...{ value: ({
                ...{| __typename: 'StringValue' |},
              ...$Pick<StringValue, { string: * }>
            }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
                ...{| __typename: 'FieldDefinition' |},
              ...$Pick<FieldDefinition, { id: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { createdAt: *, updatedAt: *, id: *, name: *, hasFile: * }>,
        ...{ product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { createdAt: *, updatedAt: *, name: *, serial: *, material?: *, id: * }>,
          ...{ createdBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, files: Array<({
              ...{| __typename: 'File' |},
            ...$Pick<File, { id: *, path: * }>,
            ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, productProviders: Array<({
              ...{| __typename: 'ProductProvider' |},
            ...{ connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
              ...{| __typename: 'CustomFields' |},
            ...{ fieldValues: Array<({
                ...{| __typename: 'FieldValue' |},
              ...{ value: ({
                  ...{| __typename: 'StringValue' |},
                ...$Pick<StringValue, { string: * }>
              }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
                  ...{| __typename: 'FieldDefinition' |},
                ...$Pick<FieldDefinition, { id: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, createdBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { displayName: *, id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, unitPrice?: ?({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, files: Array<({
            ...{| __typename: 'File' |},
          ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
          ...{ tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, entity?: ?({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: *, name: * }>,
            ...{ product: ({
                ...{| __typename: 'Product' |},
              ...$Pick<Product, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Order' |},
            ...$Pick<Order, { id: *, poNo: * }>,
            ...{ connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: *, no: * }>,
            ...{ connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: *, no: * }>,
            ...{ connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Shipment' |},
            ...$Pick<Shipment, { id: *, no: * }>,
            ...{ connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Voyage' |},
            ...$Pick<Voyage, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Container' |},
            ...$Pick<Container, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'ContainerGroup' |},
            ...$Pick<ContainerGroup, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: * }>
          }) | ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: * }>
          }) | ({
              ...{| __typename: 'Partnership' |},
            ...$Pick<Partnership, { id: * }>
          }) | ({
              ...{| __typename: 'Role' |},
            ...$Pick<Role, { id: * }>
          }) | ({
              ...{| __typename: 'File' |},
            ...$Pick<File, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'Mask' |},
            ...$Pick<Mask, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'MaskEdit' |},
            ...$Pick<MaskEdit, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
            ...{| __typename: 'CustomFields' |},
          ...{ fieldValues: Array<({
              ...{| __typename: 'FieldValue' |},
            ...{ value: ({
                ...{| __typename: 'StringValue' |},
              ...$Pick<StringValue, { string: * }>
            }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
                ...{| __typename: 'FieldDefinition' |},
              ...$Pick<FieldDefinition, { id: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, createdBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, price: ({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalPrice: ({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: * }>,
        ...{ shipment?: ?{| __typename: 'Shipment' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, files: Array<({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
        ...{ tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, entity?: ?({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: *, name: * }>,
          ...{ product: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: *, poNo: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | ({
            ...{| __typename: 'Partnership' |},
          ...$Pick<Partnership, { id: * }>
        }) | ({
            ...{| __typename: 'Role' |},
          ...$Pick<Role, { id: * }>
        }) | ({
            ...{| __typename: 'File' |},
          ...$Pick<File, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Mask' |},
          ...$Pick<Mask, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'MaskEdit' |},
          ...$Pick<MaskEdit, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
          ...{| __typename: 'CustomFields' |},
        ...{ fieldValues: Array<({
            ...{| __typename: 'FieldValue' |},
          ...{ value: ({
              ...{| __typename: 'StringValue' |},
            ...$Pick<StringValue, { string: * }>
          }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, createdBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { displayName: *, id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalPrice?: ?({
        ...{| __typename: 'NewPrice' |},
      ...$Pick<NewPrice, { amount: *, currency: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), totalVolumeOverride?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), packageGrossWeight?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), packageVolume?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), packageSize?: ?({
        ...{| __typename: 'Size' |},
      ...{ length: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), width: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), height: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }) }
    }), tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, container?: ?({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { warehouseArrivalActualDate?: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment?: ?({
        ...{| __typename: 'Shipment' |},
      ...{ containerGroups: Array<({
          ...{| __typename: 'ContainerGroup' |},
        ...{ warehouseArrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { date?: * }>,
          ...{ timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, voyages: Array<({
          ...{| __typename: 'Voyage' |},
        ...{ departure: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { date?: * }>,
          ...{ timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { warehouseArrivalActualDate?: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
        ...{| __typename: 'CustomFields' |},
      ...{ fieldValues: Array<({
          ...{| __typename: 'FieldValue' |},
        ...{ value: ({
            ...{| __typename: 'StringValue' |},
          ...$Pick<StringValue, { string: * }>
        }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { displayName: *, id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, representativeBatch?: ?({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
      ...{| __typename: 'Warehouse' |},
    ...$Pick<Warehouse, { id: *, name: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, terminalArrivalPort?: ?({
      ...{| __typename: 'Port' |},
    ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
  }), terminalArrival: ({
      ...{| __typename: 'TimelineDate' |},
    ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
    ...{ connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
      ...{ updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), totalWeight: ({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), totalPrice?: ?({
      ...{| __typename: 'NewPrice' |},
    ...$Pick<NewPrice, { amount: *, currency: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, departureDateApprovedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, shipment: ({
      ...{| __typename: 'Shipment' |},
    ...{ voyages: Array<({
        ...{| __typename: 'Voyage' |},
      ...{ arrival: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { currentDate?: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
      ...{| __typename: 'CustomFields' |},
    ...{ fieldValues: Array<({
        ...{| __typename: 'FieldValue' |},
      ...{ value: ({
          ...{| __typename: 'StringValue' |},
        ...$Pick<StringValue, { string: * }>
      }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) }
});

export type SheetShipmentOrderFragment = ({
    ...{| __typename: 'Order' |},
  ...$Pick<Order, { createdAt: *, updatedAt: *, archived: *, poNo: *, memo?: *, issuedAt?: *, piNo?: *, currency: *, calculatedOrderItemUnit: *, totalOrdered: *, incoterm?: *, deliveryPlace?: *, deliveryDate?: *, hasFile: *, id: * }>,
  ...{ createdBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { displayName: *, id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, followers: Array<({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, importer: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, files: Array<({
      ...{| __typename: 'File' |},
    ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
    ...{ tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, entity?: ?({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: *, name: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: *, name: * }>,
      ...{ product: ({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: *, poNo: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: * }>
    }) | ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | ({
        ...{| __typename: 'Partnership' |},
      ...$Pick<Partnership, { id: * }>
    }) | ({
        ...{| __typename: 'Role' |},
      ...$Pick<Role, { id: * }>
    }) | ({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Mask' |},
      ...$Pick<Mask, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'MaskEdit' |},
      ...$Pick<MaskEdit, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'FieldDefinition' |},
      ...$Pick<FieldDefinition, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, organizations: Array<({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
      ...{| __typename: 'CustomFields' |},
    ...{ fieldValues: Array<({
        ...{| __typename: 'FieldValue' |},
      ...{ value: ({
          ...{| __typename: 'StringValue' |},
        ...$Pick<StringValue, { string: * }>
      }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) }
});

export type SheetShipmentOrderItemFragment = ({
    ...{| __typename: 'OrderItem' |},
  ...$Pick<OrderItem, { createdAt: *, updatedAt: *, no: *, quantity: *, priceUnit: *, calculatedUnit: *, archived: *, deliveryDate?: *, memo?: *, hasFile: *, sort: *, id: * }>,
  ...{ order: ({
      ...{| __typename: 'Order' |},
    ...$Pick<Order, { createdAt: *, updatedAt: *, archived: *, poNo: *, memo?: *, issuedAt?: *, piNo?: *, currency: *, calculatedOrderItemUnit: *, totalOrdered: *, incoterm?: *, deliveryPlace?: *, deliveryDate?: *, hasFile: *, id: * }>,
    ...{ createdBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { displayName: *, id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, followers: Array<({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, importer: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, files: Array<({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
      ...{ tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, entity?: ?({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, poNo: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | ({
          ...{| __typename: 'Partnership' |},
        ...$Pick<Partnership, { id: * }>
      }) | ({
          ...{| __typename: 'Role' |},
        ...$Pick<Role, { id: * }>
      }) | ({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Mask' |},
        ...$Pick<Mask, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'MaskEdit' |},
        ...$Pick<MaskEdit, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, organizations: Array<({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
        ...{| __typename: 'CustomFields' |},
      ...{ fieldValues: Array<({
          ...{| __typename: 'FieldValue' |},
        ...{ value: ({
            ...{| __typename: 'StringValue' |},
          ...$Pick<StringValue, { string: * }>
        }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
      ...{| __typename: 'ProductProvider' |},
    ...$Pick<ProductProvider, { createdAt: *, updatedAt: *, id: *, name: *, hasFile: * }>,
    ...{ product: ({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { createdAt: *, updatedAt: *, name: *, serial: *, material?: *, id: * }>,
      ...{ createdBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, files: Array<({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: *, path: * }>,
        ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, productProviders: Array<({
          ...{| __typename: 'ProductProvider' |},
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
          ...{| __typename: 'CustomFields' |},
        ...{ fieldValues: Array<({
            ...{| __typename: 'FieldValue' |},
          ...{ value: ({
              ...{| __typename: 'StringValue' |},
            ...$Pick<StringValue, { string: * }>
          }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, createdBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { displayName: *, id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, unitPrice?: ?({
        ...{| __typename: 'NewPrice' |},
      ...$Pick<NewPrice, { amount: *, currency: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, files: Array<({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
      ...{ tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, entity?: ?({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, poNo: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | ({
          ...{| __typename: 'Partnership' |},
        ...$Pick<Partnership, { id: * }>
      }) | ({
          ...{| __typename: 'Role' |},
        ...$Pick<Role, { id: * }>
      }) | ({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Mask' |},
        ...$Pick<Mask, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'MaskEdit' |},
        ...$Pick<MaskEdit, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
        ...{| __typename: 'CustomFields' |},
      ...{ fieldValues: Array<({
          ...{| __typename: 'FieldValue' |},
        ...{ value: ({
            ...{| __typename: 'StringValue' |},
          ...$Pick<StringValue, { string: * }>
        }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, createdBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, price: ({
      ...{| __typename: 'NewPrice' |},
    ...$Pick<NewPrice, { amount: *, currency: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalPrice: ({
      ...{| __typename: 'NewPrice' |},
    ...$Pick<NewPrice, { amount: *, currency: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, batches: Array<({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { id: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: * }>,
    ...{ shipment?: ?{| __typename: 'Shipment' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, files: Array<({
      ...{| __typename: 'File' |},
    ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
    ...{ tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, entity?: ?({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: *, name: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: *, name: * }>,
      ...{ product: ({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: *, poNo: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: * }>
    }) | ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | ({
        ...{| __typename: 'Partnership' |},
      ...$Pick<Partnership, { id: * }>
    }) | ({
        ...{| __typename: 'Role' |},
      ...$Pick<Role, { id: * }>
    }) | ({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Mask' |},
      ...$Pick<Mask, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'MaskEdit' |},
      ...$Pick<MaskEdit, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'FieldDefinition' |},
      ...$Pick<FieldDefinition, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
      ...{| __typename: 'CustomFields' |},
    ...{ fieldValues: Array<({
        ...{| __typename: 'FieldValue' |},
      ...{ value: ({
          ...{| __typename: 'StringValue' |},
        ...$Pick<StringValue, { string: * }>
      }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) }
});

export type SheetShipmentMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  input: ShipmentUpdateInput
};


export type SheetShipmentMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ shipmentUpdate: {| __typename: 'Shipment' |} | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |} }
});

export type ContainerGroupFragment = ({
    ...{| __typename: 'ContainerGroup' |},
  ...$Pick<ContainerGroup, { id: * }>,
  ...{ warehouse?: ?({
      ...{| __typename: 'Warehouse' |},
    ...$Pick<Warehouse, { id: *, name: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customClearance: ({
      ...{| __typename: 'TimelineDate' |},
    ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
    ...{ connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
      ...{ updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
      ...{| __typename: 'TimelineDate' |},
    ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
    ...{ connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
      ...{ updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
      ...{| __typename: 'TimelineDate' |},
    ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
    ...{ connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
      ...{ updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type ContainerGroupSummaryFragment = ({
    ...{| __typename: 'ContainerGroup' |},
  ...$Pick<ContainerGroup, { id: * }>,
  ...{ warehouse?: ?({
      ...{| __typename: 'Warehouse' |},
    ...$Pick<Warehouse, { id: *, name: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customClearance: ({
      ...{| __typename: 'TimelineDate' |},
    ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
    ...{ approvedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: *, date: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
      ...{| __typename: 'TimelineDate' |},
    ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
    ...{ approvedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: *, date: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
      ...{| __typename: 'TimelineDate' |},
    ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
    ...{ approvedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: *, date: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type ShipmentCardFragment = ({
    ...{| __typename: 'Shipment' |},
  ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
  ...{ totalVolume: ({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), totalVolumeOverride?: ?({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), timeline: ({
      ...{| __typename: 'Timeline' |},
    ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
  }), containerTypeCounts: Array<({
      ...{| __typename: 'ContainerTypeCount' |},
    ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
  })>, importer?: ?({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>,
    ...{ partner?: ?({
        ...{| __typename: 'Partner' |},
      ...$Pick<Partner, { id: *, name?: *, code?: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>,
    ...{ partner?: ?({
        ...{| __typename: 'Partner' |},
      ...$Pick<Partner, { id: *, name?: *, code?: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
      ...{| __typename: 'TimelineDate' |},
    ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
    ...{ approvedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: *, date: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, voyages: Array<({
      ...{| __typename: 'Voyage' |},
    ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
    ...{ departurePort?: ?({
        ...{| __typename: 'Port' |},
      ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
    }), arrivalPort?: ?({
        ...{| __typename: 'Port' |},
      ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
    }), departure: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, date: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, date: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
      ...{| __typename: 'ContainerGroup' |},
    ...$Pick<ContainerGroup, { id: * }>,
    ...{ customClearance: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, date: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, date: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, date: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
      ...{| __typename: 'Container' |},
    ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
    ...{ warehouse?: ?({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { id: * }>,
    ...{ orderItem: ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: * }>,
      ...{ productProvider: ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: * }>,
        ...{ exporter: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
      ...{| __typename: 'Notification' |},
    ...$Pick<Notification, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type ShipmentCardFullFragment = ({
    ...{| __typename: 'Shipment' |},
  ...$Pick<Shipment, { id: *, orderCount: *, totalVolumeOverriding: *, filesUnreadCount: *, incoterm?: *, archived: *, no: *, blNo?: *, earliestWarehouseAgreedArrival?: *, latestWarehouseAgreedArrival?: *, earliestWarehouseActualArrival?: *, latestWarehouseActualArrival?: *, notificationUnseenCount: * }>,
  ...{ containers: Array<({
      ...{| __typename: 'Container' |},
    ...$Pick<Container, { id: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDateApprovedAt?: *, no: *, archived: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: *, containerType?: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, terminalArrivalPort?: ?({
        ...{| __typename: 'Port' |},
      ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
    }), terminalArrival: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, date: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }) }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { id: *, updatedAt: *, createdAt: *, deliveredAt?: *, expiredAt?: *, desiredAt?: *, archived: *, no: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: * }>,
    ...{ orderItem: ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: *, updatedAt: *, createdAt: *, archived: *, no: *, deliveryDate?: *, quantity: * }>,
      ...{ order: ({
          ...{| __typename: 'Order' |},
        ...$MakeOptional<$Pick<Order, { id: *, archived: *, currency: *, poNo: *, incoterm?: *, batchCount: *, containerCount: *, shipmentCount: *, filesUnreadCount: *, notificationUnseenCount: * }>, { batchCount: * } | { containerCount: * } | { shipmentCount: * }>,
        ...{ timeline: ({
            ...{| __typename: 'Timeline' |},
          ...$Pick<Timeline, { unreadMessageCount: * }>
        }), tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, exporter: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
            ...{| __typename: 'Notification' |},
          ...$Pick<Notification, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ supplier?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: *, serial: * }>,
          ...{ files: Array<({
              ...{| __typename: 'File' |},
            ...$Pick<File, { id: * }>,
            ...{ pathSmall: $ElementType<File, 'path'> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, price: ({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, shipment?: ?({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolumeOverride?: ?({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), totalVolume: ({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), timeline: ({
      ...{| __typename: 'Timeline' |},
    ...$Pick<Timeline, { unreadMessageCount: * }>
  }), messageGroups: Array<({
      ...{| __typename: 'MessageGroup' |},
    ...$Pick<MessageGroup, { unreadMessageCount: *, filesUnreadCount: * }>
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, exporter?: ?({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer?: ?({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, cargoReady: ({
      ...{| __typename: 'TimelineDate' |},
    ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
    ...{ approvedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: *, date: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, voyages: Array<({
      ...{| __typename: 'Voyage' |},
    ...$Pick<Voyage, { id: * }>,
    ...{ departurePort?: ?({
        ...{| __typename: 'Port' |},
      ...$Pick<Port, { seaportName?: *, seaport?: *, airportName?: *, airport?: *, roadName?: *, road?: *, railName?: *, rail?: *, dryportName?: *, dryport?: * }>
    }), arrivalPort?: ?({
        ...{| __typename: 'Port' |},
      ...$Pick<Port, { seaportName?: *, seaport?: *, airportName?: *, airport?: *, roadName?: *, road?: *, railName?: *, rail?: *, dryportName?: *, dryport?: * }>
    }), departure: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, date: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, date: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
      ...{| __typename: 'ContainerGroup' |},
    ...$Pick<ContainerGroup, { id: * }>,
    ...{ warehouse?: ?({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customClearance: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, date: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, date: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, date: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
      ...{| __typename: 'Notification' |},
    ...$Pick<Notification, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type ShipmentCardWithOwnedFragment = ({
    ...{| __typename: 'Shipment' |},
  ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
  ...{ totalVolume: ({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), totalVolumeOverride?: ?({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), timeline: ({
      ...{| __typename: 'Timeline' |},
    ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
  }), containerTypeCounts: Array<({
      ...{| __typename: 'ContainerTypeCount' |},
    ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
  })>, importer?: ?({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>,
    ...{ partner?: ?({
        ...{| __typename: 'Partner' |},
      ...$Pick<Partner, { id: *, name?: *, code?: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>,
    ...{ partner?: ?({
        ...{| __typename: 'Partner' |},
      ...$Pick<Partner, { id: *, name?: *, code?: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
      ...{| __typename: 'TimelineDate' |},
    ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
    ...{ approvedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: *, date: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, voyages: Array<({
      ...{| __typename: 'Voyage' |},
    ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
    ...{ departurePort?: ?({
        ...{| __typename: 'Port' |},
      ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
    }), arrivalPort?: ?({
        ...{| __typename: 'Port' |},
      ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
    }), departure: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, date: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, date: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
      ...{| __typename: 'ContainerGroup' |},
    ...$Pick<ContainerGroup, { id: * }>,
    ...{ customClearance: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, date: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, date: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, date: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
      ...{| __typename: 'Container' |},
    ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
    ...{ warehouse?: ?({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { id: * }>,
    ...{ orderItem: ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: * }>,
      ...{ productProvider: ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: * }>,
        ...{ exporter: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
      ...{| __typename: 'Notification' |},
    ...$Pick<Notification, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type ShipmentEntityCardFragment = ({
    ...{| __typename: 'Shipment' |},
  ...$Pick<Shipment, { id: *, orderCount: *, totalVolumeOverriding: *, filesUnreadCount: *, incoterm?: *, archived: *, no: *, blNo?: *, earliestWarehouseAgreedArrival?: *, latestWarehouseAgreedArrival?: *, earliestWarehouseActualArrival?: *, latestWarehouseActualArrival?: *, notificationUnseenCount: * }>,
  ...{ totalVolumeOverride?: ?({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), totalVolume: ({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), timeline: ({
      ...{| __typename: 'Timeline' |},
    ...$Pick<Timeline, { unreadMessageCount: * }>
  }), messageGroups: Array<({
      ...{| __typename: 'MessageGroup' |},
    ...$Pick<MessageGroup, { unreadMessageCount: *, filesUnreadCount: * }>
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, exporter?: ?({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer?: ?({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, cargoReady: ({
      ...{| __typename: 'TimelineDate' |},
    ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
    ...{ approvedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: *, date: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, voyages: Array<({
      ...{| __typename: 'Voyage' |},
    ...$Pick<Voyage, { id: * }>,
    ...{ departurePort?: ?({
        ...{| __typename: 'Port' |},
      ...$Pick<Port, { seaportName?: *, seaport?: *, airportName?: *, airport?: *, roadName?: *, road?: *, railName?: *, rail?: *, dryportName?: *, dryport?: * }>
    }), arrivalPort?: ?({
        ...{| __typename: 'Port' |},
      ...$Pick<Port, { seaportName?: *, seaport?: *, airportName?: *, airport?: *, roadName?: *, road?: *, railName?: *, rail?: *, dryportName?: *, dryport?: * }>
    }), departure: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, date: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, date: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
      ...{| __typename: 'ContainerGroup' |},
    ...$Pick<ContainerGroup, { id: * }>,
    ...{ warehouse?: ?({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customClearance: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, date: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, date: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, date: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
      ...{| __typename: 'Container' |},
    ...$Pick<Container, { id: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDateApprovedAt?: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
      ...{| __typename: 'Notification' |},
    ...$Pick<Notification, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type ShipmentForm1Fragment = ({
    ...{| __typename: 'Shipment' |},
  ...$Pick<Shipment, { id: *, archived: *, updatedAt: *, memo?: *, no: *, blNo?: *, blDate?: *, booked?: *, bookingNo?: *, bookingDate?: *, invoiceNo?: *, contractNo?: *, autoTracking?: *, autoTrackingBy?: *, autoTrackingIncludeContainers?: *, autoTrackingTransportType?: *, masterBlNo?: *, incoterm?: *, placeOfReceipt?: *, placeOfDelivery?: *, loadType?: *, carrier?: *, totalPackageQuantityOverride?: *, totalPackageQuantityOverriding: *, totalVolumeOverriding: *, totalWeightOverriding: *, notificationUnseenCount: * }>,
  ...{ updatedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, followers: Array<({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, email: *, firstName: *, lastName: * }>,
    ...{ roles: Array<({
        ...{| __typename: 'Role' |},
      ...$Pick<Role, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolumeOverride?: ?({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), totalWeightOverride?: ?({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), customFields: ({
      ...{| __typename: 'CustomFields' |},
    ...{ fieldValues: Array<({
        ...{| __typename: 'FieldValue' |},
      ...{ value: ({
          ...{| __typename: 'StringValue' |},
        ...$Pick<StringValue, { string: * }>
      }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }), importer?: ?({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, forwarders: Array<({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organizations: Array<({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, totalVolume: ({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
      ...{| __typename: 'Notification' |},
    ...$Pick<Notification, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type ShipmentForm2Fragment = ({
    ...{| __typename: 'Shipment' |},
  ...$Pick<Shipment, { id: *, archived: *, updatedAt: *, memo?: *, no: *, blNo?: *, masterBlNo?: *, blDate?: *, booked?: *, bookingNo?: *, bookingDate?: *, invoiceNo?: *, contractNo?: *, autoTracking?: *, autoTrackingBy?: *, incoterm?: *, placeOfReceipt?: *, placeOfDelivery?: *, loadType?: *, carrier?: *, totalPackageQuantityOverride?: *, totalPackageQuantityOverriding: *, totalVolumeOverriding: *, totalWeightOverriding: *, notificationUnseenCount: * }>,
  ...{ updatedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, followers: Array<({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, email: *, firstName: *, lastName: * }>,
    ...{ roles: Array<({
        ...{| __typename: 'Role' |},
      ...$Pick<Role, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organizations: Array<({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, customFields: ({
      ...{| __typename: 'CustomFields' |},
    ...{ fieldValues: Array<({
        ...{| __typename: 'FieldValue' |},
      ...{ value: ({
          ...{| __typename: 'StringValue' |},
        ...$Pick<StringValue, { string: * }>
      }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }), forwarders: Array<({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, importer?: ?({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolumeOverride?: ?({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), totalWeightOverride?: ?({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, files: Array<({
      ...{| __typename: 'File' |},
    ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
    ...{ tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, entity?: ?({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: *, name: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: *, name: * }>,
      ...{ product: ({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: *, poNo: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: * }>
    }) | ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | ({
        ...{| __typename: 'Partnership' |},
      ...$Pick<Partnership, { id: * }>
    }) | ({
        ...{| __typename: 'Role' |},
      ...$Pick<Role, { id: * }>
    }) | ({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Mask' |},
      ...$Pick<Mask, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'MaskEdit' |},
      ...$Pick<MaskEdit, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'FieldDefinition' |},
      ...$Pick<FieldDefinition, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, cargoReady: ({
      ...{| __typename: 'TimelineDate' |},
    ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
    ...{ connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
      ...{ updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, voyages: Array<({
      ...{| __typename: 'Voyage' |},
    ...$Pick<Voyage, { id: *, no?: *, vesselName?: *, vesselCode?: * }>,
    ...{ departurePort?: ?({
        ...{| __typename: 'Port' |},
      ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
    }), arrivalPort?: ?({
        ...{| __typename: 'Port' |},
      ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
    }), departure: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
        ...{ updatedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
        ...{ updatedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
      ...{| __typename: 'ContainerGroup' |},
    ...$Pick<ContainerGroup, { id: * }>,
    ...{ warehouse?: ?({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: *, name: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customClearance: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
        ...{ updatedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
        ...{ updatedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
        ...{ updatedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
      ...{| __typename: 'Container' |},
    ...$Pick<Container, { id: *, archived: *, updatedAt: *, no: *, containerType?: *, containerOption?: *, memo?: *, vanningDate?: *, vanningDateApprovedAt?: *, vanningActualDate?: *, vanningActualDateApprovedAt?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: *, freeTimeStartDate?: *, freeTimeDuration?: *, autoCalculatedFreeTimeStartDate: *, yardName?: *, departureDate?: *, departureDateApprovedAt?: * }>,
    ...{ updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: *, name: *, archived: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, terminalArrivalPort?: ?({
        ...{| __typename: 'Port' |},
      ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
    }), terminalArrival: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, date: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, vanningDateApprovedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, vanningActualDateApprovedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, departureDateApprovedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, customFields: ({
        ...{| __typename: 'CustomFields' |},
      ...{ fieldValues: Array<({
          ...{| __typename: 'FieldValue' |},
        ...{ value: ({
            ...{| __typename: 'StringValue' |},
          ...$Pick<StringValue, { string: * }>
        }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }), shipment: ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
      ...{ totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalVolumeOverride?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), timeline: ({
          ...{| __typename: 'Timeline' |},
        ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
      }), containerTypeCounts: Array<({
          ...{| __typename: 'ContainerTypeCount' |},
        ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
      })>, importer?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, voyages: Array<({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
        ...{ departurePort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), arrivalPort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), departure: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ customClearance: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
        ...{ warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>,
        ...{ orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>,
          ...{ productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>,
            ...{ exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
          ...{| __typename: 'Notification' |},
        ...$Pick<Notification, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, batches: Array<({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: *, sort: *, shipmentSort: *, archived: *, autoCalculatePackageQuantity: *, autoCalculatePackageVolume: *, updatedAt: *, memo?: *, no: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: *, latestQuantityField: *, producedAt?: *, deliveredAt?: *, desiredAt?: *, expiredAt?: *, packageName?: *, packageCapacity?: *, packageQuantity?: * }>,
      ...{ updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalPrice?: ?({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
          ...{| __typename: 'CustomFields' |},
        ...{ fieldValues: Array<({
            ...{| __typename: 'FieldValue' |},
          ...{ value: ({
              ...{| __typename: 'StringValue' |},
            ...$Pick<StringValue, { string: * }>
          }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }), packageGrossWeight?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), packageVolume?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), packageSize?: ?({
          ...{| __typename: 'Size' |},
        ...{ length: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), width: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), height: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }) }
      }), tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, orderItem: ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
        ...{ price: ({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, order: ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
          ...{ totalPrice: ({
              ...{| __typename: 'NewPrice' |},
            ...$Pick<NewPrice, { amount: *, currency: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, timeline: ({
              ...{| __typename: 'Timeline' |},
            ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
          }), ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
              ...{| __typename: 'Notification' |},
            ...$Pick<Notification, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: *, name: * }>,
          ...{ exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
            ...{ grossWeight?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), volume?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), size?: ?({
                ...{| __typename: 'Size' |},
              ...{ length: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), width: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), height: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }) }
            }) }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
            ...{ grossWeight?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), volume?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), size?: ?({
                ...{| __typename: 'Size' |},
              ...{ length: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), width: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), height: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }) }
            }) }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, product: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: *, serial: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | ({
                ...{| __typename: 'Forbidden' |},
              ...$Pick<Forbidden, { reason?: * }>,
              ...{ reference?: ?({
                  ...{| __typename: 'Reference' |},
                ...$Pick<Reference, { id: * }>
              }) }
            }) | {| __typename: 'NotFound' |}>, files: Array<({
                ...{| __typename: 'File' |},
              ...$Pick<File, { id: *, path: * }>,
              ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment?: ?({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
        ...{ forwarders: Array<({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), totalVolumeOverride?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), timeline: ({
            ...{| __typename: 'Timeline' |},
          ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
        }), containerTypeCounts: Array<({
            ...{| __typename: 'ContainerTypeCount' |},
          ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
        })>, importer?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, voyages: Array<({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
          ...{ departurePort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), arrivalPort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), departure: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>,
          ...{ customClearance: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
          ...{ warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>,
          ...{ orderItem: ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>,
            ...{ productProvider: ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>,
              ...{ exporter: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
            ...{| __typename: 'Notification' |},
          ...$Pick<Notification, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: * }>,
        ...{ representativeBatch?: ?({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>,
          ...{ orderItem: ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>,
            ...{ productProvider: ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>,
              ...{ product: ({
                  ...{| __typename: 'Product' |},
                ...$Pick<Product, { id: *, name: *, serial: * }>,
                ...{ files: Array<({
                    ...{| __typename: 'File' |},
                  ...$Pick<File, { id: *, path: * }>,
                  ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), batches: Array<({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, no: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, representativeBatch?: ?({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: *, sort: *, shipmentSort: *, archived: *, autoCalculatePackageQuantity: *, autoCalculatePackageVolume: *, updatedAt: *, memo?: *, no: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: *, latestQuantityField: *, producedAt?: *, deliveredAt?: *, desiredAt?: *, expiredAt?: *, packageName?: *, packageCapacity?: *, packageQuantity?: * }>,
      ...{ updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalPrice?: ?({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
          ...{| __typename: 'CustomFields' |},
        ...{ fieldValues: Array<({
            ...{| __typename: 'FieldValue' |},
          ...{ value: ({
              ...{| __typename: 'StringValue' |},
            ...$Pick<StringValue, { string: * }>
          }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }), packageGrossWeight?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), packageVolume?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), packageSize?: ?({
          ...{| __typename: 'Size' |},
        ...{ length: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), width: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), height: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }) }
      }), tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, orderItem: ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
        ...{ price: ({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, order: ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
          ...{ totalPrice: ({
              ...{| __typename: 'NewPrice' |},
            ...$Pick<NewPrice, { amount: *, currency: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, timeline: ({
              ...{| __typename: 'Timeline' |},
            ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
          }), ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
              ...{| __typename: 'Notification' |},
            ...$Pick<Notification, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: *, name: * }>,
          ...{ exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
            ...{ grossWeight?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), volume?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), size?: ?({
                ...{| __typename: 'Size' |},
              ...{ length: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), width: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), height: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }) }
            }) }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
            ...{ grossWeight?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), volume?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), size?: ?({
                ...{| __typename: 'Size' |},
              ...{ length: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), width: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), height: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }) }
            }) }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, product: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: *, serial: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | ({
                ...{| __typename: 'Forbidden' |},
              ...$Pick<Forbidden, { reason?: * }>,
              ...{ reference?: ?({
                  ...{| __typename: 'Reference' |},
                ...$Pick<Reference, { id: * }>
              }) }
            }) | {| __typename: 'NotFound' |}>, files: Array<({
                ...{| __typename: 'File' |},
              ...$Pick<File, { id: *, path: * }>,
              ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment?: ?({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
        ...{ forwarders: Array<({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), totalVolumeOverride?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), timeline: ({
            ...{| __typename: 'Timeline' |},
          ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
        }), containerTypeCounts: Array<({
            ...{| __typename: 'ContainerTypeCount' |},
          ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
        })>, importer?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, voyages: Array<({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
          ...{ departurePort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), arrivalPort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), departure: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>,
          ...{ customClearance: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
          ...{ warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>,
          ...{ orderItem: ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>,
            ...{ productProvider: ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>,
              ...{ exporter: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
            ...{| __typename: 'Notification' |},
          ...$Pick<Notification, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: * }>,
        ...{ representativeBatch?: ?({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>,
          ...{ orderItem: ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>,
            ...{ productProvider: ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>,
              ...{ product: ({
                  ...{| __typename: 'Product' |},
                ...$Pick<Product, { id: *, name: *, serial: * }>,
                ...{ files: Array<({
                    ...{| __typename: 'File' |},
                  ...$Pick<File, { id: *, path: * }>,
                  ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), batches: Array<({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, no: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolume: ({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), batches: Array<({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { id: *, sort: *, shipmentSort: *, archived: *, autoCalculatePackageQuantity: *, autoCalculatePackageVolume: *, updatedAt: *, memo?: *, no: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: *, latestQuantityField: *, producedAt?: *, deliveredAt?: *, desiredAt?: *, expiredAt?: *, packageName?: *, packageCapacity?: *, packageQuantity?: * }>,
    ...{ updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), totalPrice?: ?({
        ...{| __typename: 'NewPrice' |},
      ...$Pick<NewPrice, { amount: *, currency: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
        ...{| __typename: 'CustomFields' |},
      ...{ fieldValues: Array<({
          ...{| __typename: 'FieldValue' |},
        ...{ value: ({
            ...{| __typename: 'StringValue' |},
          ...$Pick<StringValue, { string: * }>
        }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }), packageGrossWeight?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), packageVolume?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), packageSize?: ?({
        ...{| __typename: 'Size' |},
      ...{ length: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), width: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), height: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }) }
    }), tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, orderItem: ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
      ...{ price: ({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, order: ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
        ...{ totalPrice: ({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, timeline: ({
            ...{| __typename: 'Timeline' |},
          ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
        }), ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
            ...{| __typename: 'Notification' |},
          ...$Pick<Notification, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ exporter: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
          ...{ grossWeight?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), volume?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), size?: ?({
              ...{| __typename: 'Size' |},
            ...{ length: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), width: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), height: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }) }
          }) }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
          ...{ grossWeight?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), volume?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), size?: ?({
              ...{| __typename: 'Size' |},
            ...{ length: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), width: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), height: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }) }
          }) }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: *, serial: * }>,
          ...{ tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, files: Array<({
              ...{| __typename: 'File' |},
            ...$Pick<File, { id: *, path: * }>,
            ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment?: ?({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
      ...{ forwarders: Array<({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
          ...{ tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalVolumeOverride?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), timeline: ({
          ...{| __typename: 'Timeline' |},
        ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
      }), containerTypeCounts: Array<({
          ...{| __typename: 'ContainerTypeCount' |},
        ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
      })>, importer?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, voyages: Array<({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
        ...{ departurePort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), arrivalPort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), departure: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ customClearance: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
        ...{ warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>,
        ...{ orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>,
          ...{ productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>,
            ...{ exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
          ...{| __typename: 'Notification' |},
        ...$Pick<Notification, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: *, no: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: * }>,
      ...{ representativeBatch?: ?({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>,
        ...{ orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>,
          ...{ productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>,
            ...{ product: ({
                ...{| __typename: 'Product' |},
              ...$Pick<Product, { id: *, name: *, serial: * }>,
              ...{ files: Array<({
                  ...{| __typename: 'File' |},
                ...$Pick<File, { id: *, path: * }>,
                ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
      ...{| __typename: 'Notification' |},
    ...$Pick<Notification, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type ShipmentFormFilesFragment = ({
    ...{| __typename: 'Shipment' |},
  ...$Pick<Shipment, { id: * }>,
  ...{ files: Array<({
      ...{| __typename: 'File' |},
    ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
    ...{ tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, entity?: ?({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: *, name: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: *, name: * }>,
      ...{ product: ({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: *, poNo: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: * }>
    }) | ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | ({
        ...{| __typename: 'Partnership' |},
      ...$Pick<Partnership, { id: * }>
    }) | ({
        ...{| __typename: 'Role' |},
      ...$Pick<Role, { id: * }>
    }) | ({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Mask' |},
      ...$Pick<Mask, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'MaskEdit' |},
      ...$Pick<MaskEdit, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'FieldDefinition' |},
      ...$Pick<FieldDefinition, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, messageGroups: Array<({
      ...{| __typename: 'MessageGroup' |},
    ...$Pick<MessageGroup, { id: *, unreadMessageCount: *, createdAt: *, updatedAt: *, deletedAt?: *, filesUnreadCount: * }>,
    ...{ organizations: Array<({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, createdBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deletedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, files: Array<({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
      ...{ tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, entity?: ?({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, poNo: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | ({
          ...{| __typename: 'Partnership' |},
        ...$Pick<Partnership, { id: * }>
      }) | ({
          ...{| __typename: 'Role' |},
        ...$Pick<Role, { id: * }>
      }) | ({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Mask' |},
        ...$Pick<Mask, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'MaskEdit' |},
        ...$Pick<MaskEdit, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}> }
});

export type ShipmentListFragment = ({
    ...{| __typename: 'Shipment' |},
  ...$Pick<Shipment, { notificationUnseenCount: *, id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: * }>,
  ...{ messageGroups: Array<({
      ...{| __typename: 'MessageGroup' |},
    ...$Pick<MessageGroup, { unreadMessageCount: *, filesUnreadCount: * }>
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, notificationUnseen: Array<({
      ...{| __typename: 'Notification' |},
    ...$Pick<Notification, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolume: ({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), totalVolumeOverride?: ?({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), timeline: ({
      ...{| __typename: 'Timeline' |},
    ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
  }), containerTypeCounts: Array<({
      ...{| __typename: 'ContainerTypeCount' |},
    ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
  })>, importer?: ?({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>,
    ...{ partner?: ?({
        ...{| __typename: 'Partner' |},
      ...$Pick<Partner, { id: *, name?: *, code?: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>,
    ...{ partner?: ?({
        ...{| __typename: 'Partner' |},
      ...$Pick<Partner, { id: *, name?: *, code?: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
      ...{| __typename: 'TimelineDate' |},
    ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
    ...{ approvedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: *, date: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, voyages: Array<({
      ...{| __typename: 'Voyage' |},
    ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
    ...{ departurePort?: ?({
        ...{| __typename: 'Port' |},
      ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
    }), arrivalPort?: ?({
        ...{| __typename: 'Port' |},
      ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
    }), departure: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, date: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, date: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
      ...{| __typename: 'ContainerGroup' |},
    ...$Pick<ContainerGroup, { id: * }>,
    ...{ customClearance: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, date: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, date: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, date: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
      ...{| __typename: 'Container' |},
    ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
    ...{ warehouse?: ?({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { id: * }>,
    ...{ orderItem: ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: * }>,
      ...{ productProvider: ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: * }>,
        ...{ exporter: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type ShipmentSummaryFragment = ({
    ...{| __typename: 'Shipment' |},
  ...$Pick<Shipment, { id: *, orderCount: *, totalVolumeOverriding: *, filesUnreadCount: *, archived: *, no: *, blNo?: *, earliestWarehouseAgreedArrival?: *, latestWarehouseAgreedArrival?: *, earliestWarehouseActualArrival?: *, latestWarehouseActualArrival?: *, notificationUnseenCount: * }>,
  ...{ totalVolumeOverride?: ?({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), totalVolume: ({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), timeline: ({
      ...{| __typename: 'Timeline' |},
    ...$Pick<Timeline, { unreadMessageCount: * }>
  }), exporter?: ?({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer?: ?({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, cargoReady: ({
      ...{| __typename: 'TimelineDate' |},
    ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
    ...{ approvedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: *, date: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, voyages: Array<({
      ...{| __typename: 'Voyage' |},
    ...$Pick<Voyage, { id: * }>,
    ...{ departurePort?: ?({
        ...{| __typename: 'Port' |},
      ...$Pick<Port, { seaportName?: *, seaport?: *, airportName?: *, airport?: *, roadName?: *, road?: *, railName?: *, rail?: *, dryportName?: *, dryport?: * }>
    }), arrivalPort?: ?({
        ...{| __typename: 'Port' |},
      ...$Pick<Port, { seaportName?: *, seaport?: *, airportName?: *, airport?: *, roadName?: *, road?: *, railName?: *, rail?: *, dryportName?: *, dryport?: * }>
    }), departure: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, date: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, date: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
      ...{| __typename: 'ContainerGroup' |},
    ...$Pick<ContainerGroup, { id: * }>,
    ...{ warehouse?: ?({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customClearance: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, date: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, date: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, date: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
      ...{| __typename: 'Container' |},
    ...$Pick<Container, { id: *, warehouseArrivalActualDate?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDateApprovedAt?: * }>,
    ...{ terminalArrivalPort?: ?({
        ...{| __typename: 'Port' |},
      ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
    }), terminalArrival: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, date: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { id: *, deliveredAt?: *, desiredAt?: * }>,
    ...{ container?: ?({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, orderItem: ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: * }>,
      ...{ order: ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: * }>,
        ...{ product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
      ...{| __typename: 'Notification' |},
    ...$Pick<Notification, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type VoyageFragment = ({
    ...{| __typename: 'Voyage' |},
  ...$Pick<Voyage, { id: *, no?: *, vesselName?: *, vesselCode?: * }>,
  ...{ departurePort?: ?({
      ...{| __typename: 'Port' |},
    ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
  }), arrivalPort?: ?({
      ...{| __typename: 'Port' |},
    ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
  }), departure: ({
      ...{| __typename: 'TimelineDate' |},
    ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
    ...{ connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
      ...{ updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
      ...{| __typename: 'TimelineDate' |},
    ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
    ...{ connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
      ...{ updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type VoyageSummaryFragment = ({
    ...{| __typename: 'Voyage' |},
  ...$Pick<Voyage, { id: * }>,
  ...{ departurePort?: ?({
      ...{| __typename: 'Port' |},
    ...$Pick<Port, { seaportName?: *, seaport?: *, airportName?: *, airport?: *, roadName?: *, road?: *, railName?: *, rail?: *, dryportName?: *, dryport?: * }>
  }), arrivalPort?: ?({
      ...{| __typename: 'Port' |},
    ...$Pick<Port, { seaportName?: *, seaport?: *, airportName?: *, airport?: *, roadName?: *, road?: *, railName?: *, rail?: *, dryportName?: *, dryport?: * }>
  }), departure: ({
      ...{| __typename: 'TimelineDate' |},
    ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
    ...{ approvedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: *, date: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
      ...{| __typename: 'TimelineDate' |},
    ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
    ...{ approvedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: *, date: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type ShipmentCloneManyMutationVariables = {
  shipments: Array<ShipmentUpdateWrapperInput>
};


export type ShipmentCloneManyMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ shipmentCloneMany: Array<?({
      ...{| __typename: 'Shipment' |},
    ...$Pick<Shipment, { id: * }>,
    ...{ batches: Array<({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}> }
});

export type ShipmentCreateMutationVariables = {
  input: ShipmentCreateInput
};


export type ShipmentCreateMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ shipmentCreate: ({
      ...{| __typename: 'Shipment' |},
    ...$Pick<Shipment, { id: * }>,
    ...{ batches: Array<({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: * }>,
      ...{ orderItem: ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: * }>,
        ...{ order: ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | ({
      ...{| __typename: 'NotFound' |},
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: *, type: *, service: * }>
    }) }
  }) }
});

export type ShipmentGeneratePoMutationVariables = {
  input: GenerateShipmentPurchaseOrderInput
};


export type ShipmentGeneratePoMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ generateShipmentPurchaseOrder: ({
      ...{| __typename: 'GenerateShipmentPurchaseOrderResponse' |},
    ...$Pick<GenerateShipmentPurchaseOrderResponse, { fileUrl: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type ShipmentUpdateMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  input: ShipmentUpdateInput
};


export type ShipmentUpdateMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ shipmentUpdate: ({
      ...{| __typename: 'Shipment' |},
    ...{ containers: Array<({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |} }
});

export type ShipmentUpdateManyMutationVariables = {
  shipments: Array<ShipmentUpdateWrapperInput>
};


export type ShipmentUpdateManyMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ shipmentUpdateMany: Array<?({
      ...{| __typename: 'Shipment' |},
    ...$Pick<Shipment, { id: * }>,
    ...{ batches: Array<({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: * }>,
      ...{ orderItem: ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: * }>,
        ...{ order: ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}> }
});

export type UpdateViewStateMutationVariables = {
  input: ViewStateUpdateInput
};


export type UpdateViewStateMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ viewState: ({
      ...{| __typename: 'ViewState' |},
    ...$Pick<ViewState, { id: *, type: *, filterSort?: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type PortsQueryVariables = {
  keyword: $ElementType<Scalars, 'String'>
};


export type PortsQuery = ({
    ...{| __typename: 'Query' |},
  ...{ ports: Array<({
      ...{| __typename: 'Port' |},
    ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
  })> }
});

export type ShipmentExportQueryVariables = {
  id: $ElementType<Scalars, 'ID'>,
  templateId: $ElementType<Scalars, 'ID'>
};


export type ShipmentExportQuery = ({
    ...{| __typename: 'Query' |},
  ...{ shipmentExport: ({
      ...{| __typename: 'Export' |},
    ...$Pick<Export, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type ShipmentFormQueryVariables = {
  id: $ElementType<Scalars, 'ID'>
};


export type ShipmentFormQuery = ({
    ...{| __typename: 'Query' |},
  ...{ shipment: ({
      ...{| __typename: 'Shipment' |},
    ...$Pick<Shipment, { id: *, archived: *, updatedAt: *, memo?: *, no: *, blNo?: *, blDate?: *, booked?: *, bookingNo?: *, bookingDate?: *, invoiceNo?: *, contractNo?: *, autoTracking?: *, autoTrackingBy?: *, autoTrackingIncludeContainers?: *, autoTrackingTransportType?: *, masterBlNo?: *, incoterm?: *, placeOfReceipt?: *, placeOfDelivery?: *, loadType?: *, carrier?: *, totalPackageQuantityOverride?: *, totalPackageQuantityOverriding: *, totalVolumeOverriding: *, totalWeightOverriding: *, notificationUnseenCount: * }>,
    ...{ updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, followers: Array<({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, email: *, firstName: *, lastName: * }>,
      ...{ roles: Array<({
          ...{| __typename: 'Role' |},
        ...$Pick<Role, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolumeOverride?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), totalWeightOverride?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), customFields: ({
        ...{| __typename: 'CustomFields' |},
      ...{ fieldValues: Array<({
          ...{| __typename: 'FieldValue' |},
        ...{ value: ({
            ...{| __typename: 'StringValue' |},
          ...$Pick<StringValue, { string: * }>
        }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }), importer?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, forwarders: Array<({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organizations: Array<({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, totalVolume: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
        ...{| __typename: 'Notification' |},
      ...$Pick<Notification, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |} }
});

export type ShipmentFormFilesQueryVariables = {
  id: $ElementType<Scalars, 'ID'>
};


export type ShipmentFormFilesQuery = ({
    ...{| __typename: 'Query' |},
  ...{ shipment: ({
      ...{| __typename: 'Shipment' |},
    ...$Pick<Shipment, { id: * }>,
    ...{ files: Array<({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
      ...{ tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, entity?: ?({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, poNo: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | ({
          ...{| __typename: 'Partnership' |},
        ...$Pick<Partnership, { id: * }>
      }) | ({
          ...{| __typename: 'Role' |},
        ...$Pick<Role, { id: * }>
      }) | ({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Mask' |},
        ...$Pick<Mask, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'MaskEdit' |},
        ...$Pick<MaskEdit, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, messageGroups: Array<({
        ...{| __typename: 'MessageGroup' |},
      ...$Pick<MessageGroup, { id: *, unreadMessageCount: *, createdAt: *, updatedAt: *, deletedAt?: *, filesUnreadCount: * }>,
      ...{ organizations: Array<({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, createdBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deletedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, files: Array<({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
        ...{ tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, entity?: ?({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: *, name: * }>,
          ...{ product: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: *, poNo: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | ({
            ...{| __typename: 'Partnership' |},
          ...$Pick<Partnership, { id: * }>
        }) | ({
            ...{| __typename: 'Role' |},
          ...$Pick<Role, { id: * }>
        }) | ({
            ...{| __typename: 'File' |},
          ...$Pick<File, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Mask' |},
          ...$Pick<Mask, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'MaskEdit' |},
          ...$Pick<MaskEdit, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type ShipmentFormSummaryQueryVariables = {
  id: $ElementType<Scalars, 'ID'>
};


export type ShipmentFormSummaryQuery = ({
    ...{| __typename: 'Query' |},
  ...{ shipment: ({
      ...{| __typename: 'Shipment' |},
    ...$Pick<Shipment, { id: *, totalPackageQuantity: *, totalPackageQuantityOverride?: *, totalPackageQuantityOverriding: *, totalVolumeOverriding: *, totalWeightOverriding: *, orderCount: *, batchCount: *, containerCount: * }>,
    ...{ totalVolumeOverride?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), totalWeightOverride?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), containerTypeCounts: Array<({
        ...{| __typename: 'ContainerTypeCount' |},
      ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
    })>, totalVolume: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), files: Array<({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: *, quantity: *, latestQuantity: *, packageQuantity?: * }>,
      ...{ packageGrossWeight?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), orderItem: ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: * }>,
        ...{ price: ({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: * }>,
          ...{ product: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, voyages: Array<({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
      ...{ departurePort?: ?({
          ...{| __typename: 'Port' |},
        ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
      }), arrivalPort?: ?({
          ...{| __typename: 'Port' |},
        ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
      }) }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type ShipmentFormWithTimelineQueryVariables = {
  id: $ElementType<Scalars, 'ID'>
};


export type ShipmentFormWithTimelineQuery = ({
    ...{| __typename: 'Query' |},
  ...{ shipment: ({
      ...{| __typename: 'Shipment' |},
    ...$Pick<Shipment, { id: *, archived: *, updatedAt: *, memo?: *, no: *, blNo?: *, blDate?: *, booked?: *, bookingNo?: *, bookingDate?: *, invoiceNo?: *, contractNo?: *, autoTracking?: *, autoTrackingBy?: *, autoTrackingIncludeContainers?: *, autoTrackingTransportType?: *, masterBlNo?: *, incoterm?: *, placeOfReceipt?: *, placeOfDelivery?: *, loadType?: *, carrier?: *, totalPackageQuantityOverride?: *, totalPackageQuantityOverriding: *, totalVolumeOverriding: *, totalWeightOverriding: *, notificationUnseenCount: * }>,
    ...{ updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, followers: Array<({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, email: *, firstName: *, lastName: * }>,
      ...{ roles: Array<({
          ...{| __typename: 'Role' |},
        ...$Pick<Role, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolumeOverride?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), totalWeightOverride?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), customFields: ({
        ...{| __typename: 'CustomFields' |},
      ...{ fieldValues: Array<({
          ...{| __typename: 'FieldValue' |},
        ...{ value: ({
            ...{| __typename: 'StringValue' |},
          ...$Pick<StringValue, { string: * }>
        }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }), importer?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, forwarders: Array<({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organizations: Array<({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, totalVolume: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), cargoReady: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
        ...{ updatedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReadyWarehouse?: ?({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: *, name: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, voyages: Array<({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: *, no?: *, vesselName?: *, vesselCode?: * }>,
      ...{ departurePort?: ?({
          ...{| __typename: 'Port' |},
        ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
      }), arrivalPort?: ?({
          ...{| __typename: 'Port' |},
        ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
      }), departure: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
          ...{ updatedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
          ...{ updatedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>,
      ...{ warehouse?: ?({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: *, name: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customClearance: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
          ...{ updatedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
          ...{ updatedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
          ...{ updatedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
        ...{| __typename: 'Notification' |},
      ...$Pick<Notification, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |} }
});

export type ShipmentFullFocusDetailQueryVariables = {
  ids: Array<$ElementType<Scalars, 'ID'>>,
  skipOrderCounts: $ElementType<Scalars, 'Boolean'>
};


export type ShipmentFullFocusDetailQuery = ({
    ...{| __typename: 'Query' |},
  ...{ shipmentsByIDs: Array<({
      ...{| __typename: 'Shipment' |},
    ...$Pick<Shipment, { id: *, orderCount: *, totalVolumeOverriding: *, filesUnreadCount: *, incoterm?: *, archived: *, no: *, blNo?: *, earliestWarehouseAgreedArrival?: *, latestWarehouseAgreedArrival?: *, earliestWarehouseActualArrival?: *, latestWarehouseActualArrival?: *, notificationUnseenCount: * }>,
    ...{ containers: Array<({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDateApprovedAt?: *, no: *, archived: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: *, containerType?: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, terminalArrivalPort?: ?({
          ...{| __typename: 'Port' |},
        ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
      }), terminalArrival: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }) }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: *, updatedAt: *, createdAt: *, deliveredAt?: *, expiredAt?: *, desiredAt?: *, archived: *, no: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: * }>,
      ...{ orderItem: ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, updatedAt: *, createdAt: *, archived: *, no: *, deliveryDate?: *, quantity: * }>,
        ...{ order: ({
            ...{| __typename: 'Order' |},
          ...$MakeOptional<$Pick<Order, { id: *, archived: *, currency: *, poNo: *, incoterm?: *, batchCount: *, containerCount: *, shipmentCount: *, filesUnreadCount: *, notificationUnseenCount: * }>, { batchCount: * } | { containerCount: * } | { shipmentCount: * }>,
          ...{ timeline: ({
              ...{| __typename: 'Timeline' |},
            ...$Pick<Timeline, { unreadMessageCount: * }>
          }), tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
              ...{| __typename: 'Notification' |},
            ...$Pick<Notification, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: *, name: * }>,
          ...{ supplier?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, product: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: *, serial: * }>,
            ...{ files: Array<({
                ...{| __typename: 'File' |},
              ...$Pick<File, { id: * }>,
              ...{ pathSmall: $ElementType<File, 'path'> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, price: ({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, shipment?: ?({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolumeOverride?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), totalVolume: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), timeline: ({
        ...{| __typename: 'Timeline' |},
      ...$Pick<Timeline, { unreadMessageCount: * }>
    }), messageGroups: Array<({
        ...{| __typename: 'MessageGroup' |},
      ...$Pick<MessageGroup, { unreadMessageCount: *, filesUnreadCount: * }>
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, exporter?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, cargoReady: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, date: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, voyages: Array<({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: * }>,
      ...{ departurePort?: ?({
          ...{| __typename: 'Port' |},
        ...$Pick<Port, { seaportName?: *, seaport?: *, airportName?: *, airport?: *, roadName?: *, road?: *, railName?: *, rail?: *, dryportName?: *, dryport?: * }>
      }), arrivalPort?: ?({
          ...{| __typename: 'Port' |},
        ...$Pick<Port, { seaportName?: *, seaport?: *, airportName?: *, airport?: *, roadName?: *, road?: *, railName?: *, rail?: *, dryportName?: *, dryport?: * }>
      }), departure: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>,
      ...{ warehouse?: ?({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customClearance: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
        ...{| __typename: 'Notification' |},
      ...$Pick<Notification, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type ShipmentListQueryVariables = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?ShipmentFilterInput,
  sortBy?: ?ShipmentSortInput
};


export type ShipmentListQuery = ({
    ...{| __typename: 'Query' |},
  ...{ shipments: ({
      ...{| __typename: 'ShipmentPayloadPaginatedSearch' |},
    ...$Pick<ShipmentPayloadPaginatedSearch, { page: *, totalPage: * }>,
    ...{ nodes: Array<({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { notificationUnseenCount: *, id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: * }>,
      ...{ messageGroups: Array<({
          ...{| __typename: 'MessageGroup' |},
        ...$Pick<MessageGroup, { unreadMessageCount: *, filesUnreadCount: * }>
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, notificationUnseen: Array<({
          ...{| __typename: 'Notification' |},
        ...$Pick<Notification, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalVolumeOverride?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), timeline: ({
          ...{| __typename: 'Timeline' |},
        ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
      }), containerTypeCounts: Array<({
          ...{| __typename: 'ContainerTypeCount' |},
        ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
      })>, importer?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, voyages: Array<({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
        ...{ departurePort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), arrivalPort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), departure: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ customClearance: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
        ...{ warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>,
        ...{ orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>,
          ...{ productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>,
            ...{ exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'BadRequest' |},
      ...{ violations: Array<({
          ...{| __typename: 'Violation' |},
        ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
        ...{ parameters: Array<({
            ...{| __typename: 'ViolationParameter' |},
          ...$Pick<ViolationParameter, { key: *, value: * }>
        })> }
      })> }
    }) | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | ({
        ...{| __typename: 'NotFound' |},
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: *, type: *, service: * }>
      }) }
    })> }
  }) }
});

export type ShipmentSummaryQueryVariables = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?ShipmentFilterInput,
  sortBy?: ?ShipmentSortInput
};


export type ShipmentSummaryQuery = ({
    ...{| __typename: 'Query' |},
  ...{ shipments: ({
      ...{| __typename: 'ShipmentPayloadPaginatedSearch' |},
    ...$Pick<ShipmentPayloadPaginatedSearch, { page: *, totalPage: * }>,
    ...{ nodes: Array<({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, orderCount: *, totalVolumeOverriding: *, filesUnreadCount: *, archived: *, no: *, blNo?: *, earliestWarehouseAgreedArrival?: *, latestWarehouseAgreedArrival?: *, earliestWarehouseActualArrival?: *, latestWarehouseActualArrival?: *, notificationUnseenCount: * }>,
      ...{ totalVolumeOverride?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), timeline: ({
          ...{| __typename: 'Timeline' |},
        ...$Pick<Timeline, { unreadMessageCount: * }>
      }), exporter?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, cargoReady: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, voyages: Array<({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: * }>,
        ...{ departurePort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { seaportName?: *, seaport?: *, airportName?: *, airport?: *, roadName?: *, road?: *, railName?: *, rail?: *, dryportName?: *, dryport?: * }>
        }), arrivalPort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { seaportName?: *, seaport?: *, airportName?: *, airport?: *, roadName?: *, road?: *, railName?: *, rail?: *, dryportName?: *, dryport?: * }>
        }), departure: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customClearance: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, warehouseArrivalActualDate?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDateApprovedAt?: * }>,
        ...{ terminalArrivalPort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), terminalArrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: *, deliveredAt?: *, desiredAt?: * }>,
        ...{ container?: ?({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>,
          ...{ order: ({
              ...{| __typename: 'Order' |},
            ...$Pick<Order, { id: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>,
            ...{ product: ({
                ...{| __typename: 'Product' |},
              ...$Pick<Product, { id: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
          ...{| __typename: 'Notification' |},
        ...$Pick<Notification, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, hits: Array<({
        ...{| __typename: 'Hit' |},
      ...{ entityHits: Array<({
          ...{| __typename: 'EntityHit' |},
        ...$Pick<EntityHit, { field?: * }>,
        ...{ entity: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: * }>
        }) | ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: * }>
        }) | ({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: * }>
        }) | ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: * }>
        }) | ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>
        }) | ({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>
        }) | ({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: * }>
        }) | ({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: * }>
        }) | ({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: * }>
        }) | ({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>
        }) | ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: * }>
        }) | ({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: * }>
        }) | ({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: * }>
        }) | ({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: * }>
        }) | ({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | ({
            ...{| __typename: 'Partnership' |},
          ...$Pick<Partnership, { id: * }>
        }) | ({
            ...{| __typename: 'Role' |},
          ...$Pick<Role, { id: * }>
        }) | ({
            ...{| __typename: 'File' |},
          ...$Pick<File, { id: * }>
        }) | ({
            ...{| __typename: 'Mask' |},
          ...$Pick<Mask, { id: * }>
        }) | ({
            ...{| __typename: 'MaskEdit' |},
          ...$Pick<MaskEdit, { id: * }>
        }) | ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: * }>
        }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      })> }
    })> }
  }) }
});

export type ShipmentTimelineQueryVariables = {
  id: $ElementType<Scalars, 'ID'>,
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>
};


export type ShipmentTimelineQuery = ({
    ...{| __typename: 'Query' |},
  ...{ shipment: ({
      ...{| __typename: 'Shipment' |},
    ...$Pick<Shipment, { id: * }>,
    ...{ timeline: ({
        ...{| __typename: 'Timeline' |},
      ...{ entries: ({
          ...{| __typename: 'TimelineEntryPaginatedList' |},
        ...$Pick<TimelineEntryPaginatedList, { page: *, totalPage: * }>,
        ...{ nodes: Array<({
            ...{| __typename: 'TimelineEvent' |},
          ...$Pick<TimelineEvent, { id: *, createdAt: * }>,
          ...{ entity: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: * }>
          }) | ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>
          }) | ({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: * }>
          }) | ({
              ...{| __typename: 'Order' |},
            ...$Pick<Order, { id: * }>
          }) | ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>
          }) | ({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: * }>
          }) | ({
              ...{| __typename: 'Shipment' |},
            ...$Pick<Shipment, { id: * }>
          }) | ({
              ...{| __typename: 'Voyage' |},
            ...$Pick<Voyage, { id: * }>
          }) | ({
              ...{| __typename: 'Container' |},
            ...$Pick<Container, { id: * }>
          }) | ({
              ...{| __typename: 'ContainerGroup' |},
            ...$Pick<ContainerGroup, { id: * }>
          }) | ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: * }>
          }) | ({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: * }>
          }) | ({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: * }>
          }) | ({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: * }>
          }) | ({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: * }>
          }) | ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: * }>
          }) | ({
              ...{| __typename: 'Partnership' |},
            ...$Pick<Partnership, { id: * }>
          }) | ({
              ...{| __typename: 'Role' |},
            ...$Pick<Role, { id: * }>
          }) | ({
              ...{| __typename: 'File' |},
            ...$Pick<File, { id: * }>
          }) | ({
              ...{| __typename: 'Mask' |},
            ...$Pick<Mask, { id: * }>
          }) | ({
              ...{| __typename: 'MaskEdit' |},
            ...$Pick<MaskEdit, { id: * }>
          }) | ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: * }>
          }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, createdBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, logs: Array<({
              ...{| __typename: 'Log' |},
            ...$Pick<Log, { id: *, translationKey: * }>,
            ...{ entity: ({
                ...{| __typename: 'Product' |},
              ...$Pick<Product, { id: * }>
            }) | ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>
            }) | ({
                ...{| __typename: 'ProductProviderPackage' |},
              ...$Pick<ProductProviderPackage, { id: * }>
            }) | ({
                ...{| __typename: 'Order' |},
              ...$Pick<Order, { id: * }>
            }) | ({
                ...{| __typename: 'OrderItem' |},
              ...$Pick<OrderItem, { id: * }>
            }) | ({
                ...{| __typename: 'Batch' |},
              ...$Pick<Batch, { id: * }>
            }) | ({
                ...{| __typename: 'Shipment' |},
              ...$Pick<Shipment, { id: * }>
            }) | ({
                ...{| __typename: 'Voyage' |},
              ...$Pick<Voyage, { id: * }>
            }) | ({
                ...{| __typename: 'Container' |},
              ...$Pick<Container, { id: * }>
            }) | ({
                ...{| __typename: 'ContainerGroup' |},
              ...$Pick<ContainerGroup, { id: * }>
            }) | ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: * }>
            }) | ({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: * }>
            }) | ({
                ...{| __typename: 'Warehouse' |},
              ...$Pick<Warehouse, { id: * }>
            }) | ({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: * }>
            }) | ({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: * }>
            }) | ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: * }>
            }) | ({
                ...{| __typename: 'Partnership' |},
              ...$Pick<Partnership, { id: * }>
            }) | ({
                ...{| __typename: 'Role' |},
              ...$Pick<Role, { id: * }>
            }) | ({
                ...{| __typename: 'File' |},
              ...$Pick<File, { id: *, type: * }>
            }) | ({
                ...{| __typename: 'Mask' |},
              ...$Pick<Mask, { id: * }>
            }) | ({
                ...{| __typename: 'MaskEdit' |},
              ...$Pick<MaskEdit, { id: * }>
            }) | ({
                ...{| __typename: 'FieldDefinition' |},
              ...$Pick<FieldDefinition, { id: * }>
            }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, parameters: Array<({
                ...{| __typename: 'Parameter' |},
              ...$Pick<Parameter, { key: * }>,
              ...{ value?: ?({
                  ...{| __typename: 'StringValue' |},
                ...$Pick<StringValue, { string: * }>
              }) | ({
                  ...{| __typename: 'IntValue' |},
                ...$Pick<IntValue, { int: * }>
              }) | ({
                  ...{| __typename: 'FloatValue' |},
                ...$Pick<FloatValue, { float: * }>
              }) | ({
                  ...{| __typename: 'BooleanValue' |},
                ...$Pick<BooleanValue, { boolean: * }>
              }) | ({
                  ...{| __typename: 'DateTimeValue' |},
                ...$Pick<DateTimeValue, { datetime: * }>
              }) | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | ({
                  ...{| __typename: 'MetricValueValue' |},
                ...{ metricValue: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }) }
              }) | ({
                  ...{| __typename: 'PriceValue' |},
                ...{ priceValue: ({
                    ...{| __typename: 'NewPrice' |},
                  ...$Pick<NewPrice, { amount: *, currency: * }>
                }) }
              }) | ({
                  ...{| __typename: 'SizeValue' |},
                ...{ size: ({
                    ...{| __typename: 'Size' |},
                  ...{ height: ({
                      ...{| __typename: 'MetricValue' |},
                    ...$Pick<MetricValue, { value: *, metric: * }>
                  }), length: ({
                      ...{| __typename: 'MetricValue' |},
                    ...$Pick<MetricValue, { value: *, metric: * }>
                  }), width: ({
                      ...{| __typename: 'MetricValue' |},
                    ...$Pick<MetricValue, { value: *, metric: * }>
                  }) }
                }) }
              }) | ({
                  ...{| __typename: 'EntityValue' |},
                ...{ entity?: ?({
                    ...{| __typename: 'Product' |},
                  ...$Pick<Product, { id: * }>
                }) | ({
                    ...{| __typename: 'ProductProvider' |},
                  ...$Pick<ProductProvider, { id: * }>
                }) | ({
                    ...{| __typename: 'ProductProviderPackage' |},
                  ...$Pick<ProductProviderPackage, { id: * }>
                }) | ({
                    ...{| __typename: 'Order' |},
                  ...$Pick<Order, { id: * }>
                }) | ({
                    ...{| __typename: 'OrderItem' |},
                  ...$Pick<OrderItem, { id: * }>
                }) | ({
                    ...{| __typename: 'Batch' |},
                  ...$Pick<Batch, { id: * }>
                }) | ({
                    ...{| __typename: 'Shipment' |},
                  ...$Pick<Shipment, { id: * }>
                }) | ({
                    ...{| __typename: 'Voyage' |},
                  ...$Pick<Voyage, { id: * }>
                }) | ({
                    ...{| __typename: 'Container' |},
                  ...$Pick<Container, { id: * }>
                }) | ({
                    ...{| __typename: 'ContainerGroup' |},
                  ...$Pick<ContainerGroup, { id: * }>
                }) | ({
                    ...{| __typename: 'TimelineDate' |},
                  ...$Pick<TimelineDate, { id: * }>
                }) | ({
                    ...{| __typename: 'TimelineDateRevision' |},
                  ...$Pick<TimelineDateRevision, { id: * }>
                }) | ({
                    ...{| __typename: 'Warehouse' |},
                  ...$Pick<Warehouse, { id: * }>
                }) | ({
                    ...{| __typename: 'Tag' |},
                  ...$Pick<Tag, { id: *, name: *, color: * }>
                }) | ({
                    ...{| __typename: 'User' |},
                  ...$Pick<User, { id: *, firstName: *, lastName: * }>
                }) | ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: *, types: * }>,
                  ...{ partner?: ?({
                      ...{| __typename: 'Partner' |},
                    ...$Pick<Partner, { id: *, name?: *, code?: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
                }) | ({
                    ...{| __typename: 'Partnership' |},
                  ...$Pick<Partnership, { id: * }>
                }) | ({
                    ...{| __typename: 'Role' |},
                  ...$Pick<Role, { id: * }>
                }) | ({
                    ...{| __typename: 'File' |},
                  ...$Pick<File, { id: * }>
                }) | ({
                    ...{| __typename: 'Mask' |},
                  ...$Pick<Mask, { id: * }>
                }) | ({
                    ...{| __typename: 'MaskEdit' |},
                  ...$Pick<MaskEdit, { id: * }>
                }) | ({
                    ...{| __typename: 'FieldDefinition' |},
                  ...$Pick<FieldDefinition, { id: * }>
                }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | ({
                  ...{| __typename: 'Values' |},
                ...{ values: Array<?({
                    ...{| __typename: 'StringValue' |},
                  ...$Pick<StringValue, { string: * }>
                }) | ({
                    ...{| __typename: 'IntValue' |},
                  ...$Pick<IntValue, { int: * }>
                }) | ({
                    ...{| __typename: 'FloatValue' |},
                  ...$Pick<FloatValue, { float: * }>
                }) | ({
                    ...{| __typename: 'BooleanValue' |},
                  ...$Pick<BooleanValue, { boolean: * }>
                }) | ({
                    ...{| __typename: 'DateTimeValue' |},
                  ...$Pick<DateTimeValue, { datetime: * }>
                }) | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | ({
                    ...{| __typename: 'MetricValueValue' |},
                  ...{ metricValue: ({
                      ...{| __typename: 'MetricValue' |},
                    ...$Pick<MetricValue, { value: *, metric: * }>
                  }) }
                }) | ({
                    ...{| __typename: 'PriceValue' |},
                  ...{ priceValue: ({
                      ...{| __typename: 'NewPrice' |},
                    ...$Pick<NewPrice, { amount: *, currency: * }>
                  }) }
                }) | ({
                    ...{| __typename: 'SizeValue' |},
                  ...{ size: ({
                      ...{| __typename: 'Size' |},
                    ...{ height: ({
                        ...{| __typename: 'MetricValue' |},
                      ...$Pick<MetricValue, { value: *, metric: * }>
                    }), length: ({
                        ...{| __typename: 'MetricValue' |},
                      ...$Pick<MetricValue, { value: *, metric: * }>
                    }), width: ({
                        ...{| __typename: 'MetricValue' |},
                      ...$Pick<MetricValue, { value: *, metric: * }>
                    }) }
                  }) }
                }) | ({
                    ...{| __typename: 'EntityValue' |},
                  ...{ entity?: ?({
                      ...{| __typename: 'Product' |},
                    ...$Pick<Product, { id: * }>
                  }) | ({
                      ...{| __typename: 'ProductProvider' |},
                    ...$Pick<ProductProvider, { id: * }>
                  }) | ({
                      ...{| __typename: 'ProductProviderPackage' |},
                    ...$Pick<ProductProviderPackage, { id: * }>
                  }) | ({
                      ...{| __typename: 'Order' |},
                    ...$Pick<Order, { id: * }>
                  }) | ({
                      ...{| __typename: 'OrderItem' |},
                    ...$Pick<OrderItem, { id: * }>
                  }) | ({
                      ...{| __typename: 'Batch' |},
                    ...$Pick<Batch, { id: * }>
                  }) | ({
                      ...{| __typename: 'Shipment' |},
                    ...$Pick<Shipment, { id: * }>
                  }) | ({
                      ...{| __typename: 'Voyage' |},
                    ...$Pick<Voyage, { id: * }>
                  }) | ({
                      ...{| __typename: 'Container' |},
                    ...$Pick<Container, { id: * }>
                  }) | ({
                      ...{| __typename: 'ContainerGroup' |},
                    ...$Pick<ContainerGroup, { id: * }>
                  }) | ({
                      ...{| __typename: 'TimelineDate' |},
                    ...$Pick<TimelineDate, { id: * }>
                  }) | ({
                      ...{| __typename: 'TimelineDateRevision' |},
                    ...$Pick<TimelineDateRevision, { id: * }>
                  }) | ({
                      ...{| __typename: 'Warehouse' |},
                    ...$Pick<Warehouse, { id: * }>
                  }) | ({
                      ...{| __typename: 'Tag' |},
                    ...$Pick<Tag, { id: *, name: *, color: * }>
                  }) | ({
                      ...{| __typename: 'User' |},
                    ...$Pick<User, { id: *, firstName: *, lastName: * }>
                  }) | ({
                      ...{| __typename: 'Organization' |},
                    ...$Pick<Organization, { id: *, name: *, types: * }>,
                    ...{ partner?: ?({
                        ...{| __typename: 'Partner' |},
                      ...$Pick<Partner, { id: *, name?: *, code?: * }>
                    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
                  }) | ({
                      ...{| __typename: 'Partnership' |},
                    ...$Pick<Partnership, { id: * }>
                  }) | ({
                      ...{| __typename: 'Role' |},
                    ...$Pick<Role, { id: * }>
                  }) | ({
                      ...{| __typename: 'File' |},
                    ...$Pick<File, { id: * }>
                  }) | ({
                      ...{| __typename: 'Mask' |},
                    ...$Pick<Mask, { id: * }>
                  }) | ({
                      ...{| __typename: 'MaskEdit' |},
                    ...$Pick<MaskEdit, { id: * }>
                  }) | ({
                      ...{| __typename: 'FieldDefinition' |},
                    ...$Pick<FieldDefinition, { id: * }>
                  }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
                }) | {| __typename: 'Values' |}> }
              }) }
            })> }
          })> }
        }) | ({
            ...{| __typename: 'TimelineComment' |},
          ...$Pick<TimelineComment, { id: *, content: *, createdAt: *, updatedAt: * }>,
          ...{ createdBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) }
    }) }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type ShipmentsByIDsQueryVariables = {
  ids: Array<$ElementType<Scalars, 'ID'>>
};


export type ShipmentsByIDsQuery = ({
    ...{| __typename: 'Query' |},
  ...{ shipmentsByIDs: Array<({
      ...{| __typename: 'Shipment' |},
    ...$Pick<Shipment, { id: *, no: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type ShipmentsByIDsDocumentsQueryVariables = {
  ids: Array<$ElementType<Scalars, 'ID'>>
};


export type ShipmentsByIDsDocumentsQuery = ({
    ...{| __typename: 'Query' |},
  ...{ shipmentsByIDs: Array<({
      ...{| __typename: 'Shipment' |},
    ...{ files: Array<({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
      ...{ tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, entity?: ?({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, poNo: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | ({
          ...{| __typename: 'Partnership' |},
        ...$Pick<Partnership, { id: * }>
      }) | ({
          ...{| __typename: 'Role' |},
        ...$Pick<Role, { id: * }>
      }) | ({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Mask' |},
        ...$Pick<Mask, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'MaskEdit' |},
        ...$Pick<MaskEdit, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, messageGroups: Array<({
        ...{| __typename: 'MessageGroup' |},
      ...$Pick<MessageGroup, { id: *, unreadMessageCount: *, createdAt: *, updatedAt: *, deletedAt?: *, filesUnreadCount: * }>,
      ...{ organizations: Array<({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, createdBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deletedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, files: Array<({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
        ...{ tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, entity?: ?({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: *, name: * }>,
          ...{ product: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: *, poNo: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | ({
            ...{| __typename: 'Partnership' |},
          ...$Pick<Partnership, { id: * }>
        }) | ({
            ...{| __typename: 'Role' |},
          ...$Pick<Role, { id: * }>
        }) | ({
            ...{| __typename: 'File' |},
          ...$Pick<File, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Mask' |},
          ...$Pick<Mask, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'MaskEdit' |},
          ...$Pick<MaskEdit, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type ShipmentsCardQueryVariables = {
  filterBy?: ?ShipmentFilterInput,
  sortBy?: ?ShipmentSortInput,
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>
};


export type ShipmentsCardQuery = ({
    ...{| __typename: 'Query' |},
  ...{ shipments: ({
      ...{| __typename: 'ShipmentPayloadPaginatedSearch' |},
    ...$Pick<ShipmentPayloadPaginatedSearch, { page: *, totalPage: * }>,
    ...{ nodes: Array<({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
      ...{ totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalVolumeOverride?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), timeline: ({
          ...{| __typename: 'Timeline' |},
        ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
      }), containerTypeCounts: Array<({
          ...{| __typename: 'ContainerTypeCount' |},
        ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
      })>, importer?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, voyages: Array<({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
        ...{ departurePort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), arrivalPort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), departure: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ customClearance: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
        ...{ warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>,
        ...{ orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>,
          ...{ productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>,
            ...{ exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
          ...{| __typename: 'Notification' |},
        ...$Pick<Notification, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}> }
  }) }
});

export type ShipmentsDocumentFolderViewQueryVariables = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?ShipmentFilterInput
};


export type ShipmentsDocumentFolderViewQuery = ({
    ...{| __typename: 'Query' |},
  ...{ shipments: ({
      ...{| __typename: 'ShipmentPayloadPaginatedSearch' |},
    ...$Pick<ShipmentPayloadPaginatedSearch, { page: *, totalPage: * }>,
    ...{ nodes: Array<({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, no: * }>,
      ...{ fileCount: ({
          ...{| __typename: 'ShipmentFileCount' |},
        ...$Pick<ShipmentFileCount, { count: * }>
      }) }
    }) | ({
        ...{| __typename: 'BadRequest' |},
      ...{ violations: Array<({
          ...{| __typename: 'Violation' |},
        ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
        ...{ parameters: Array<({
            ...{| __typename: 'ViolationParameter' |},
          ...$Pick<ViolationParameter, { key: *, value: * }>
        })> }
      })> }
    }) | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | ({
        ...{| __typename: 'NotFound' |},
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: *, type: *, service: * }>
      }) }
    })> }
  }) }
});

export type ShipmentsExportQueryVariables = {
  templateId: $ElementType<Scalars, 'ID'>,
  filterBy?: ?ShipmentFilterInput,
  sortBy?: ?ShipmentSortInput,
  localSortBy?: ?Array<GenericSortInput>,
  columns?: ?Array<$ElementType<Scalars, 'String'>>
};


export type ShipmentsExportQuery = ({
    ...{| __typename: 'Query' |},
  ...{ shipmentsExport: ({
      ...{| __typename: 'Export' |},
    ...$Pick<Export, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type TableTemplateFragment = ({
    ...{| __typename: 'MaskEdit' |},
  ...$Pick<MaskEdit, { id: *, name: *, type: *, memo?: *, filterSort?: *, collapses: *, updatedAt: * }>,
  ...{ columns: Array<({
      ...{| __typename: 'MaskEditColumn' |},
    ...$Pick<MaskEditColumn, { key: *, hidden: *, width?: *, pinned?: *, highlight?: *, group?: * }>
  })>, updatedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type AllCustomFieldDefinitionsQueryVariables = {};


export type AllCustomFieldDefinitionsQuery = ({
    ...{| __typename: 'Query' |},
  ...{ orderCustomFields: Array<({
      ...{| __typename: 'FieldDefinition' |},
    ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, orderItemCustomFields: Array<({
      ...{| __typename: 'FieldDefinition' |},
    ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batchCustomFields: Array<({
      ...{| __typename: 'FieldDefinition' |},
    ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, shipmentCustomFields: Array<({
      ...{| __typename: 'FieldDefinition' |},
    ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerCustomFields: Array<({
      ...{| __typename: 'FieldDefinition' |},
    ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, productCustomFields: Array<({
      ...{| __typename: 'FieldDefinition' |},
    ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, productProviderCustomFields: Array<({
      ...{| __typename: 'FieldDefinition' |},
    ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type ExportExtensionsQueryVariables = {};


export type ExportExtensionsQuery = ({
    ...{| __typename: 'Query' |},
  ...{ exportExtensions: Array<({
      ...{| __typename: 'ExportExtension' |},
    ...$Pick<ExportExtension, { extension: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type ExportTemplatesQueryVariables = {
  filterBy?: ?ExportTemplateFilterInput
};


export type ExportTemplatesQuery = ({
    ...{| __typename: 'Query' |},
  ...{ exportTemplates: Array<({
      ...{| __typename: 'ExportTemplate' |},
    ...$Pick<ExportTemplate, { id: *, name: *, extension: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type GenericExportQueryVariables = {
  extension: $ElementType<Scalars, 'String'>,
  columns: Array<$ElementType<Scalars, 'String'>>,
  rows: Array<Array<?$ElementType<Scalars, 'String'>>>
};


export type GenericExportQuery = ({
    ...{| __typename: 'Query' |},
  ...{ genericExport: ({
      ...{| __typename: 'Export' |},
    ...$Pick<Export, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type TableTemplateQueryVariables = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?MaskEditFilterInput,
  sortBy?: ?MaskEditSortInput
};


export type TableTemplateQuery = ({
    ...{| __typename: 'Query' |},
  ...{ maskEdits: ({
      ...{| __typename: 'MaskEditPayloadPaginatedSearch' |},
    ...$Pick<MaskEditPayloadPaginatedSearch, { page: *, totalPage: *, perPage: *, count: *, totalCount: * }>,
    ...{ nodes: Array<({
        ...{| __typename: 'MaskEdit' |},
      ...$Pick<MaskEdit, { id: *, name: *, type: *, memo?: *, filterSort?: *, collapses: *, updatedAt: * }>,
      ...{ columns: Array<({
          ...{| __typename: 'MaskEditColumn' |},
        ...$Pick<MaskEditColumn, { key: *, hidden: *, width?: *, pinned?: *, highlight?: *, group?: * }>
      })>, updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'BadRequest' |},
      ...{ violations: Array<({
          ...{| __typename: 'Violation' |},
        ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
        ...{ parameters: Array<({
            ...{| __typename: 'ViolationParameter' |},
          ...$Pick<ViolationParameter, { key: *, value: * }>
        })> }
      })> }
    }) | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}> }
  }) }
});

export type TemporaryTemplateQueryVariables = {
  type: $ElementType<Scalars, 'String'>
};


export type TemporaryTemplateQuery = ({
    ...{| __typename: 'Query' |},
  ...{ maskEditByUser: ({
      ...{| __typename: 'MaskEdit' |},
    ...$Pick<MaskEdit, { id: *, name: *, type: *, memo?: *, filterSort?: *, collapses: *, updatedAt: * }>,
    ...{ columns: Array<({
        ...{| __typename: 'MaskEditColumn' |},
      ...$Pick<MaskEditColumn, { key: *, hidden: *, width?: *, pinned?: *, highlight?: *, group?: * }>
    })>, updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |} }
});

export type ExportReadySubscriptionVariables = {
  exportId: $ElementType<Scalars, 'ID'>
};


export type ExportReadySubscription = ({
    ...{| __typename: 'Subscription' |},
  ...{ exportReady: ({
      ...{| __typename: 'File' |},
    ...$Pick<File, { path: * }>
  }) }
});

export type TagCardFragment = ({
    ...{| __typename: 'Tag' |},
  ...$Pick<Tag, { id: *, name: *, description?: *, color: *, entityTypes: * }>
});

export type TagFormFragment = ({
    ...{| __typename: 'Tag' |},
  ...$Pick<Tag, { id: *, updatedAt: *, name: *, description?: *, color: *, entityTypes: * }>,
  ...{ updatedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type TagBatchesByIDsQueryVariables = {
  ids: Array<$ElementType<Scalars, 'ID'>>
};


export type TagBatchesByIDsQuery = ({
    ...{| __typename: 'Query' |},
  ...{ batchesByIDs: Array<({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { id: * }>,
    ...{ tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}> }
});

export type TagContainersByIDsQueryVariables = {
  ids: Array<$ElementType<Scalars, 'ID'>>
};


export type TagContainersByIDsQuery = ({
    ...{| __typename: 'Query' |},
  ...{ containersByIDs: Array<({
      ...{| __typename: 'Container' |},
    ...$Pick<Container, { id: * }>,
    ...{ tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}> }
});

export type TagExportQueryVariables = {
  id: $ElementType<Scalars, 'ID'>,
  templateId: $ElementType<Scalars, 'ID'>
};


export type TagExportQuery = ({
    ...{| __typename: 'Query' |},
  ...{ tagExport: ({
      ...{| __typename: 'Export' |},
    ...$Pick<Export, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type TagFormQueryVariables = {
  id: $ElementType<Scalars, 'ID'>
};


export type TagFormQuery = ({
    ...{| __typename: 'Query' |},
  ...{ tag: ({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, updatedAt: *, name: *, description?: *, color: *, entityTypes: * }>,
    ...{ updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type TagOrderItemsByIDsQueryVariables = {
  ids: Array<$ElementType<Scalars, 'ID'>>
};


export type TagOrderItemsByIDsQuery = ({
    ...{| __typename: 'Query' |},
  ...{ orderItemsByIDs: Array<({
      ...{| __typename: 'OrderItem' |},
    ...$Pick<OrderItem, { id: * }>,
    ...{ tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}> }
});

export type TagOrdersByIDsQueryVariables = {
  ids: Array<$ElementType<Scalars, 'ID'>>
};


export type TagOrdersByIDsQuery = ({
    ...{| __typename: 'Query' |},
  ...{ ordersByIDs: Array<({
      ...{| __typename: 'Order' |},
    ...$Pick<Order, { id: * }>,
    ...{ tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}> }
});

export type TagShipmentsByIDsQueryVariables = {
  ids: Array<$ElementType<Scalars, 'ID'>>
};


export type TagShipmentsByIDsQuery = ({
    ...{| __typename: 'Query' |},
  ...{ shipmentsByIDs: Array<({
      ...{| __typename: 'Shipment' |},
    ...$Pick<Shipment, { id: * }>,
    ...{ tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}> }
});

export type TagsQueryVariables = {
  filterBy?: ?TagFilterInput,
  sortBy?: ?TagSortInput,
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>
};


export type TagsQuery = ({
    ...{| __typename: 'Query' |},
  ...{ tags: ({
      ...{| __typename: 'TagPayloadPaginatedSearch' |},
    ...$Pick<TagPayloadPaginatedSearch, { page: *, totalPage: *, perPage: *, count: *, totalCount: * }>,
    ...{ nodes: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, description?: *, color: *, entityTypes: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) }
});

export type TagsByIDsQueryVariables = {
  ids: Array<$ElementType<Scalars, 'ID'>>
};


export type TagsByIDsQuery = ({
    ...{| __typename: 'Query' |},
  ...{ tagsByIDs: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}> }
});

export type TagsByIDsMinimalQueryVariables = {
  ids: Array<$ElementType<Scalars, 'ID'>>
};


export type TagsByIDsMinimalQuery = ({
    ...{| __typename: 'Query' |},
  ...{ tagsByIDs: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}> }
});

export type TagsExportQueryVariables = {
  filterBy?: ?TagFilterInput,
  sortBy?: ?TagSortInput,
  templateId: $ElementType<Scalars, 'ID'>
};


export type TagsExportQuery = ({
    ...{| __typename: 'Query' |},
  ...{ tagsExport: ({
      ...{| __typename: 'Export' |},
    ...$Pick<Export, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type CargoFragment = ({
    ...{| __typename: 'Shipment' |},
  ...$Pick<Shipment, { id: * }>,
  ...{ containers: Array<({
      ...{| __typename: 'Container' |},
    ...$Pick<Container, { id: *, archived: *, updatedAt: *, no: *, containerType?: *, containerOption?: *, memo?: *, vanningDate?: *, vanningDateApprovedAt?: *, vanningActualDate?: *, vanningActualDateApprovedAt?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: *, freeTimeStartDate?: *, freeTimeDuration?: *, autoCalculatedFreeTimeStartDate: *, yardName?: *, departureDate?: *, departureDateApprovedAt?: * }>,
    ...{ updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: *, name: *, archived: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, terminalArrivalPort?: ?({
        ...{| __typename: 'Port' |},
      ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
    }), terminalArrival: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, date: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, vanningDateApprovedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, vanningActualDateApprovedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, departureDateApprovedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, customFields: ({
        ...{| __typename: 'CustomFields' |},
      ...{ fieldValues: Array<({
          ...{| __typename: 'FieldValue' |},
        ...{ value: ({
            ...{| __typename: 'StringValue' |},
          ...$Pick<StringValue, { string: * }>
        }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }), shipment: ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
      ...{ totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalVolumeOverride?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), timeline: ({
          ...{| __typename: 'Timeline' |},
        ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
      }), containerTypeCounts: Array<({
          ...{| __typename: 'ContainerTypeCount' |},
        ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
      })>, importer?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, voyages: Array<({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
        ...{ departurePort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), arrivalPort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), departure: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ customClearance: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
        ...{ warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>,
        ...{ orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>,
          ...{ productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>,
            ...{ exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
          ...{| __typename: 'Notification' |},
        ...$Pick<Notification, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, batches: Array<({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: *, sort: *, shipmentSort: *, archived: *, autoCalculatePackageQuantity: *, autoCalculatePackageVolume: *, updatedAt: *, memo?: *, no: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: *, latestQuantityField: *, producedAt?: *, deliveredAt?: *, desiredAt?: *, expiredAt?: *, packageName?: *, packageCapacity?: *, packageQuantity?: * }>,
      ...{ updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalPrice?: ?({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
          ...{| __typename: 'CustomFields' |},
        ...{ fieldValues: Array<({
            ...{| __typename: 'FieldValue' |},
          ...{ value: ({
              ...{| __typename: 'StringValue' |},
            ...$Pick<StringValue, { string: * }>
          }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }), packageGrossWeight?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), packageVolume?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), packageSize?: ?({
          ...{| __typename: 'Size' |},
        ...{ length: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), width: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), height: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }) }
      }), tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, orderItem: ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
        ...{ price: ({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, order: ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
          ...{ totalPrice: ({
              ...{| __typename: 'NewPrice' |},
            ...$Pick<NewPrice, { amount: *, currency: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, timeline: ({
              ...{| __typename: 'Timeline' |},
            ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
          }), ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
              ...{| __typename: 'Notification' |},
            ...$Pick<Notification, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: *, name: * }>,
          ...{ exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
            ...{ grossWeight?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), volume?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), size?: ?({
                ...{| __typename: 'Size' |},
              ...{ length: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), width: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), height: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }) }
            }) }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
            ...{ grossWeight?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), volume?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), size?: ?({
                ...{| __typename: 'Size' |},
              ...{ length: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), width: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), height: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }) }
            }) }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, product: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: *, serial: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | ({
                ...{| __typename: 'Forbidden' |},
              ...$Pick<Forbidden, { reason?: * }>,
              ...{ reference?: ?({
                  ...{| __typename: 'Reference' |},
                ...$Pick<Reference, { id: * }>
              }) }
            }) | {| __typename: 'NotFound' |}>, files: Array<({
                ...{| __typename: 'File' |},
              ...$Pick<File, { id: *, path: * }>,
              ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment?: ?({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
        ...{ forwarders: Array<({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), totalVolumeOverride?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), timeline: ({
            ...{| __typename: 'Timeline' |},
          ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
        }), containerTypeCounts: Array<({
            ...{| __typename: 'ContainerTypeCount' |},
          ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
        })>, importer?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, voyages: Array<({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
          ...{ departurePort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), arrivalPort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), departure: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>,
          ...{ customClearance: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
          ...{ warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>,
          ...{ orderItem: ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>,
            ...{ productProvider: ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>,
              ...{ exporter: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
            ...{| __typename: 'Notification' |},
          ...$Pick<Notification, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: * }>,
        ...{ representativeBatch?: ?({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>,
          ...{ orderItem: ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>,
            ...{ productProvider: ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>,
              ...{ product: ({
                  ...{| __typename: 'Product' |},
                ...$Pick<Product, { id: *, name: *, serial: * }>,
                ...{ files: Array<({
                    ...{| __typename: 'File' |},
                  ...$Pick<File, { id: *, path: * }>,
                  ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), batches: Array<({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, no: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, representativeBatch?: ?({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: *, sort: *, shipmentSort: *, archived: *, autoCalculatePackageQuantity: *, autoCalculatePackageVolume: *, updatedAt: *, memo?: *, no: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: *, latestQuantityField: *, producedAt?: *, deliveredAt?: *, desiredAt?: *, expiredAt?: *, packageName?: *, packageCapacity?: *, packageQuantity?: * }>,
      ...{ updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalPrice?: ?({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
          ...{| __typename: 'CustomFields' |},
        ...{ fieldValues: Array<({
            ...{| __typename: 'FieldValue' |},
          ...{ value: ({
              ...{| __typename: 'StringValue' |},
            ...$Pick<StringValue, { string: * }>
          }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }), packageGrossWeight?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), packageVolume?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), packageSize?: ?({
          ...{| __typename: 'Size' |},
        ...{ length: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), width: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), height: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }) }
      }), tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, orderItem: ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
        ...{ price: ({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, order: ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
          ...{ totalPrice: ({
              ...{| __typename: 'NewPrice' |},
            ...$Pick<NewPrice, { amount: *, currency: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, timeline: ({
              ...{| __typename: 'Timeline' |},
            ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
          }), ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
              ...{| __typename: 'Notification' |},
            ...$Pick<Notification, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: *, name: * }>,
          ...{ exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
            ...{ grossWeight?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), volume?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), size?: ?({
                ...{| __typename: 'Size' |},
              ...{ length: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), width: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), height: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }) }
            }) }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
            ...{ grossWeight?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), volume?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), size?: ?({
                ...{| __typename: 'Size' |},
              ...{ length: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), width: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), height: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }) }
            }) }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, product: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: *, serial: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | ({
                ...{| __typename: 'Forbidden' |},
              ...$Pick<Forbidden, { reason?: * }>,
              ...{ reference?: ?({
                  ...{| __typename: 'Reference' |},
                ...$Pick<Reference, { id: * }>
              }) }
            }) | {| __typename: 'NotFound' |}>, files: Array<({
                ...{| __typename: 'File' |},
              ...$Pick<File, { id: *, path: * }>,
              ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment?: ?({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
        ...{ forwarders: Array<({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), totalVolumeOverride?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), timeline: ({
            ...{| __typename: 'Timeline' |},
          ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
        }), containerTypeCounts: Array<({
            ...{| __typename: 'ContainerTypeCount' |},
          ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
        })>, importer?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, voyages: Array<({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
          ...{ departurePort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), arrivalPort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), departure: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>,
          ...{ customClearance: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
          ...{ warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>,
          ...{ orderItem: ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>,
            ...{ productProvider: ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>,
              ...{ exporter: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
            ...{| __typename: 'Notification' |},
          ...$Pick<Notification, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: * }>,
        ...{ representativeBatch?: ?({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>,
          ...{ orderItem: ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>,
            ...{ productProvider: ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>,
              ...{ product: ({
                  ...{| __typename: 'Product' |},
                ...$Pick<Product, { id: *, name: *, serial: * }>,
                ...{ files: Array<({
                    ...{| __typename: 'File' |},
                  ...$Pick<File, { id: *, path: * }>,
                  ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), batches: Array<({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, no: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { id: *, sort: *, shipmentSort: *, archived: *, autoCalculatePackageQuantity: *, autoCalculatePackageVolume: *, updatedAt: *, memo?: *, no: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: *, latestQuantityField: *, producedAt?: *, deliveredAt?: *, desiredAt?: *, expiredAt?: *, packageName?: *, packageCapacity?: *, packageQuantity?: * }>,
    ...{ updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), totalPrice?: ?({
        ...{| __typename: 'NewPrice' |},
      ...$Pick<NewPrice, { amount: *, currency: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
        ...{| __typename: 'CustomFields' |},
      ...{ fieldValues: Array<({
          ...{| __typename: 'FieldValue' |},
        ...{ value: ({
            ...{| __typename: 'StringValue' |},
          ...$Pick<StringValue, { string: * }>
        }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }), packageGrossWeight?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), packageVolume?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), packageSize?: ?({
        ...{| __typename: 'Size' |},
      ...{ length: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), width: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), height: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }) }
    }), tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, orderItem: ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
      ...{ price: ({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, order: ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
        ...{ totalPrice: ({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, timeline: ({
            ...{| __typename: 'Timeline' |},
          ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
        }), ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
            ...{| __typename: 'Notification' |},
          ...$Pick<Notification, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ exporter: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
          ...{ grossWeight?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), volume?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), size?: ?({
              ...{| __typename: 'Size' |},
            ...{ length: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), width: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), height: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }) }
          }) }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
          ...{ grossWeight?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), volume?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), size?: ?({
              ...{| __typename: 'Size' |},
            ...{ length: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), width: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), height: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }) }
          }) }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: *, serial: * }>,
          ...{ tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, files: Array<({
              ...{| __typename: 'File' |},
            ...$Pick<File, { id: *, path: * }>,
            ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment?: ?({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
      ...{ forwarders: Array<({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
          ...{ tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalVolumeOverride?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), timeline: ({
          ...{| __typename: 'Timeline' |},
        ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
      }), containerTypeCounts: Array<({
          ...{| __typename: 'ContainerTypeCount' |},
        ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
      })>, importer?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, voyages: Array<({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
        ...{ departurePort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), arrivalPort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), departure: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ customClearance: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
        ...{ warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>,
        ...{ orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>,
          ...{ productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>,
            ...{ exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
          ...{| __typename: 'Notification' |},
        ...$Pick<Notification, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: *, no: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: * }>,
      ...{ representativeBatch?: ?({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>,
        ...{ orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>,
          ...{ productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>,
            ...{ product: ({
                ...{| __typename: 'Product' |},
              ...$Pick<Product, { id: *, name: *, serial: * }>,
              ...{ files: Array<({
                  ...{| __typename: 'File' |},
                ...$Pick<File, { id: *, path: * }>,
                ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type TimelineFragment = ({
    ...{| __typename: 'Shipment' |},
  ...$Pick<Shipment, { id: * }>,
  ...{ cargoReady: ({
      ...{| __typename: 'TimelineDate' |},
    ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
    ...{ connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
      ...{ updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReadyWarehouse?: ?({
      ...{| __typename: 'Warehouse' |},
    ...$Pick<Warehouse, { id: *, name: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, voyages: Array<({
      ...{| __typename: 'Voyage' |},
    ...$Pick<Voyage, { id: *, no?: *, vesselName?: *, vesselCode?: * }>,
    ...{ departurePort?: ?({
        ...{| __typename: 'Port' |},
      ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
    }), arrivalPort?: ?({
        ...{| __typename: 'Port' |},
      ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
    }), departure: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
        ...{ updatedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
        ...{ updatedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
      ...{| __typename: 'ContainerGroup' |},
    ...$Pick<ContainerGroup, { id: * }>,
    ...{ warehouse?: ?({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: *, name: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customClearance: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
        ...{ updatedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
        ...{ updatedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
        ...{ updatedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type TimelineAndCargoFragment = ({
    ...{| __typename: 'Shipment' |},
  ...$Pick<Shipment, { id: * }>,
  ...{ cargoReady: ({
      ...{| __typename: 'TimelineDate' |},
    ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
    ...{ connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
      ...{ updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReadyWarehouse?: ?({
      ...{| __typename: 'Warehouse' |},
    ...$Pick<Warehouse, { id: *, name: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, voyages: Array<({
      ...{| __typename: 'Voyage' |},
    ...$Pick<Voyage, { id: *, no?: *, vesselName?: *, vesselCode?: * }>,
    ...{ departurePort?: ?({
        ...{| __typename: 'Port' |},
      ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
    }), arrivalPort?: ?({
        ...{| __typename: 'Port' |},
      ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
    }), departure: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
        ...{ updatedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
        ...{ updatedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
      ...{| __typename: 'ContainerGroup' |},
    ...$Pick<ContainerGroup, { id: * }>,
    ...{ warehouse?: ?({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: *, name: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customClearance: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
        ...{ updatedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
        ...{ updatedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
        ...{ updatedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
      ...{| __typename: 'Container' |},
    ...$Pick<Container, { id: *, archived: *, updatedAt: *, no: *, containerType?: *, containerOption?: *, memo?: *, vanningDate?: *, vanningDateApprovedAt?: *, vanningActualDate?: *, vanningActualDateApprovedAt?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: *, freeTimeStartDate?: *, freeTimeDuration?: *, autoCalculatedFreeTimeStartDate: *, yardName?: *, departureDate?: *, departureDateApprovedAt?: * }>,
    ...{ updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: *, name: *, archived: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, terminalArrivalPort?: ?({
        ...{| __typename: 'Port' |},
      ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
    }), terminalArrival: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, date: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, vanningDateApprovedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, vanningActualDateApprovedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, departureDateApprovedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, customFields: ({
        ...{| __typename: 'CustomFields' |},
      ...{ fieldValues: Array<({
          ...{| __typename: 'FieldValue' |},
        ...{ value: ({
            ...{| __typename: 'StringValue' |},
          ...$Pick<StringValue, { string: * }>
        }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }), shipment: ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
      ...{ totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalVolumeOverride?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), timeline: ({
          ...{| __typename: 'Timeline' |},
        ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
      }), containerTypeCounts: Array<({
          ...{| __typename: 'ContainerTypeCount' |},
        ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
      })>, importer?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, voyages: Array<({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
        ...{ departurePort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), arrivalPort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), departure: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ customClearance: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
        ...{ warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>,
        ...{ orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>,
          ...{ productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>,
            ...{ exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
          ...{| __typename: 'Notification' |},
        ...$Pick<Notification, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, batches: Array<({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: *, sort: *, shipmentSort: *, archived: *, autoCalculatePackageQuantity: *, autoCalculatePackageVolume: *, updatedAt: *, memo?: *, no: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: *, latestQuantityField: *, producedAt?: *, deliveredAt?: *, desiredAt?: *, expiredAt?: *, packageName?: *, packageCapacity?: *, packageQuantity?: * }>,
      ...{ updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalPrice?: ?({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
          ...{| __typename: 'CustomFields' |},
        ...{ fieldValues: Array<({
            ...{| __typename: 'FieldValue' |},
          ...{ value: ({
              ...{| __typename: 'StringValue' |},
            ...$Pick<StringValue, { string: * }>
          }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }), packageGrossWeight?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), packageVolume?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), packageSize?: ?({
          ...{| __typename: 'Size' |},
        ...{ length: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), width: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), height: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }) }
      }), tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, orderItem: ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
        ...{ price: ({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, order: ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
          ...{ totalPrice: ({
              ...{| __typename: 'NewPrice' |},
            ...$Pick<NewPrice, { amount: *, currency: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, timeline: ({
              ...{| __typename: 'Timeline' |},
            ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
          }), ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
              ...{| __typename: 'Notification' |},
            ...$Pick<Notification, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: *, name: * }>,
          ...{ exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
            ...{ grossWeight?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), volume?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), size?: ?({
                ...{| __typename: 'Size' |},
              ...{ length: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), width: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), height: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }) }
            }) }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
            ...{ grossWeight?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), volume?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), size?: ?({
                ...{| __typename: 'Size' |},
              ...{ length: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), width: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), height: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }) }
            }) }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, product: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: *, serial: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | ({
                ...{| __typename: 'Forbidden' |},
              ...$Pick<Forbidden, { reason?: * }>,
              ...{ reference?: ?({
                  ...{| __typename: 'Reference' |},
                ...$Pick<Reference, { id: * }>
              }) }
            }) | {| __typename: 'NotFound' |}>, files: Array<({
                ...{| __typename: 'File' |},
              ...$Pick<File, { id: *, path: * }>,
              ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment?: ?({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
        ...{ forwarders: Array<({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), totalVolumeOverride?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), timeline: ({
            ...{| __typename: 'Timeline' |},
          ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
        }), containerTypeCounts: Array<({
            ...{| __typename: 'ContainerTypeCount' |},
          ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
        })>, importer?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, voyages: Array<({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
          ...{ departurePort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), arrivalPort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), departure: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>,
          ...{ customClearance: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
          ...{ warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>,
          ...{ orderItem: ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>,
            ...{ productProvider: ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>,
              ...{ exporter: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
            ...{| __typename: 'Notification' |},
          ...$Pick<Notification, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: * }>,
        ...{ representativeBatch?: ?({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>,
          ...{ orderItem: ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>,
            ...{ productProvider: ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>,
              ...{ product: ({
                  ...{| __typename: 'Product' |},
                ...$Pick<Product, { id: *, name: *, serial: * }>,
                ...{ files: Array<({
                    ...{| __typename: 'File' |},
                  ...$Pick<File, { id: *, path: * }>,
                  ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), batches: Array<({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, no: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, representativeBatch?: ?({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: *, sort: *, shipmentSort: *, archived: *, autoCalculatePackageQuantity: *, autoCalculatePackageVolume: *, updatedAt: *, memo?: *, no: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: *, latestQuantityField: *, producedAt?: *, deliveredAt?: *, desiredAt?: *, expiredAt?: *, packageName?: *, packageCapacity?: *, packageQuantity?: * }>,
      ...{ updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalPrice?: ?({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
          ...{| __typename: 'CustomFields' |},
        ...{ fieldValues: Array<({
            ...{| __typename: 'FieldValue' |},
          ...{ value: ({
              ...{| __typename: 'StringValue' |},
            ...$Pick<StringValue, { string: * }>
          }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }), packageGrossWeight?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), packageVolume?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), packageSize?: ?({
          ...{| __typename: 'Size' |},
        ...{ length: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), width: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), height: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }) }
      }), tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, orderItem: ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
        ...{ price: ({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, order: ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
          ...{ totalPrice: ({
              ...{| __typename: 'NewPrice' |},
            ...$Pick<NewPrice, { amount: *, currency: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, timeline: ({
              ...{| __typename: 'Timeline' |},
            ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
          }), ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
              ...{| __typename: 'Notification' |},
            ...$Pick<Notification, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: *, name: * }>,
          ...{ exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
            ...{ grossWeight?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), volume?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), size?: ?({
                ...{| __typename: 'Size' |},
              ...{ length: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), width: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), height: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }) }
            }) }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
            ...{ grossWeight?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), volume?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), size?: ?({
                ...{| __typename: 'Size' |},
              ...{ length: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), width: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), height: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }) }
            }) }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, product: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: *, serial: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | ({
                ...{| __typename: 'Forbidden' |},
              ...$Pick<Forbidden, { reason?: * }>,
              ...{ reference?: ?({
                  ...{| __typename: 'Reference' |},
                ...$Pick<Reference, { id: * }>
              }) }
            }) | {| __typename: 'NotFound' |}>, files: Array<({
                ...{| __typename: 'File' |},
              ...$Pick<File, { id: *, path: * }>,
              ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment?: ?({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
        ...{ forwarders: Array<({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), totalVolumeOverride?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), timeline: ({
            ...{| __typename: 'Timeline' |},
          ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
        }), containerTypeCounts: Array<({
            ...{| __typename: 'ContainerTypeCount' |},
          ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
        })>, importer?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, voyages: Array<({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
          ...{ departurePort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), arrivalPort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), departure: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>,
          ...{ customClearance: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
          ...{ warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>,
          ...{ orderItem: ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>,
            ...{ productProvider: ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>,
              ...{ exporter: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
            ...{| __typename: 'Notification' |},
          ...$Pick<Notification, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: * }>,
        ...{ representativeBatch?: ?({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>,
          ...{ orderItem: ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>,
            ...{ productProvider: ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>,
              ...{ product: ({
                  ...{| __typename: 'Product' |},
                ...$Pick<Product, { id: *, name: *, serial: * }>,
                ...{ files: Array<({
                    ...{| __typename: 'File' |},
                  ...$Pick<File, { id: *, path: * }>,
                  ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), batches: Array<({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, no: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { id: *, sort: *, shipmentSort: *, archived: *, autoCalculatePackageQuantity: *, autoCalculatePackageVolume: *, updatedAt: *, memo?: *, no: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: *, latestQuantityField: *, producedAt?: *, deliveredAt?: *, desiredAt?: *, expiredAt?: *, packageName?: *, packageCapacity?: *, packageQuantity?: * }>,
    ...{ updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), totalPrice?: ?({
        ...{| __typename: 'NewPrice' |},
      ...$Pick<NewPrice, { amount: *, currency: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
        ...{| __typename: 'CustomFields' |},
      ...{ fieldValues: Array<({
          ...{| __typename: 'FieldValue' |},
        ...{ value: ({
            ...{| __typename: 'StringValue' |},
          ...$Pick<StringValue, { string: * }>
        }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }), packageGrossWeight?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), packageVolume?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), packageSize?: ?({
        ...{| __typename: 'Size' |},
      ...{ length: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), width: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), height: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }) }
    }), tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, orderItem: ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
      ...{ price: ({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, order: ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
        ...{ totalPrice: ({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, timeline: ({
            ...{| __typename: 'Timeline' |},
          ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
        }), ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
            ...{| __typename: 'Notification' |},
          ...$Pick<Notification, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ exporter: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
          ...{ grossWeight?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), volume?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), size?: ?({
              ...{| __typename: 'Size' |},
            ...{ length: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), width: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), height: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }) }
          }) }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
          ...{ grossWeight?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), volume?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), size?: ?({
              ...{| __typename: 'Size' |},
            ...{ length: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), width: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), height: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }) }
          }) }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: *, serial: * }>,
          ...{ tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, files: Array<({
              ...{| __typename: 'File' |},
            ...$Pick<File, { id: *, path: * }>,
            ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment?: ?({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
      ...{ forwarders: Array<({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
          ...{ tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalVolumeOverride?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), timeline: ({
          ...{| __typename: 'Timeline' |},
        ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
      }), containerTypeCounts: Array<({
          ...{| __typename: 'ContainerTypeCount' |},
        ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
      })>, importer?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, voyages: Array<({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
        ...{ departurePort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), arrivalPort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), departure: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ customClearance: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
        ...{ warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>,
        ...{ orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>,
          ...{ productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>,
            ...{ exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
          ...{| __typename: 'Notification' |},
        ...$Pick<Notification, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: *, no: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: * }>,
      ...{ representativeBatch?: ?({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>,
        ...{ orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>,
          ...{ productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>,
            ...{ product: ({
                ...{| __typename: 'Product' |},
              ...$Pick<Product, { id: *, name: *, serial: * }>,
              ...{ files: Array<({
                  ...{| __typename: 'File' |},
                ...$Pick<File, { id: *, path: * }>,
                ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type ShipmentFormTimelineAndCargoQueryVariables = {
  id: $ElementType<Scalars, 'ID'>
};


export type ShipmentFormTimelineAndCargoQuery = ({
    ...{| __typename: 'Query' |},
  ...{ shipment: ({
      ...{| __typename: 'Shipment' |},
    ...$Pick<Shipment, { id: * }>,
    ...{ cargoReady: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
        ...{ updatedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReadyWarehouse?: ?({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: *, name: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, voyages: Array<({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: *, no?: *, vesselName?: *, vesselCode?: * }>,
      ...{ departurePort?: ?({
          ...{| __typename: 'Port' |},
        ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
      }), arrivalPort?: ?({
          ...{| __typename: 'Port' |},
        ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
      }), departure: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
          ...{ updatedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
          ...{ updatedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>,
      ...{ warehouse?: ?({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: *, name: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customClearance: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
          ...{ updatedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
          ...{ updatedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { createdAt: *, id: *, date?: *, resultDate?: *, currentDate?: *, approvedAt?: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, createdAt: *, date: *, type: *, memo?: *, updatedAt: * }>,
          ...{ updatedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: *, archived: *, updatedAt: *, no: *, containerType?: *, containerOption?: *, memo?: *, vanningDate?: *, vanningDateApprovedAt?: *, vanningActualDate?: *, vanningActualDateApprovedAt?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: *, freeTimeStartDate?: *, freeTimeDuration?: *, autoCalculatedFreeTimeStartDate: *, yardName?: *, departureDate?: *, departureDateApprovedAt?: * }>,
      ...{ updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: *, name: *, archived: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, terminalArrivalPort?: ?({
          ...{| __typename: 'Port' |},
        ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
      }), terminalArrival: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, vanningDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, vanningActualDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, departureDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, customFields: ({
          ...{| __typename: 'CustomFields' |},
        ...{ fieldValues: Array<({
            ...{| __typename: 'FieldValue' |},
          ...{ value: ({
              ...{| __typename: 'StringValue' |},
            ...$Pick<StringValue, { string: * }>
          }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }), shipment: ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
        ...{ totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), totalVolumeOverride?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), timeline: ({
            ...{| __typename: 'Timeline' |},
          ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
        }), containerTypeCounts: Array<({
            ...{| __typename: 'ContainerTypeCount' |},
          ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
        })>, importer?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, voyages: Array<({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
          ...{ departurePort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), arrivalPort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), departure: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>,
          ...{ customClearance: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
          ...{ warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>,
          ...{ orderItem: ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>,
            ...{ productProvider: ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>,
              ...{ exporter: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
            ...{| __typename: 'Notification' |},
          ...$Pick<Notification, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: *, sort: *, shipmentSort: *, archived: *, autoCalculatePackageQuantity: *, autoCalculatePackageVolume: *, updatedAt: *, memo?: *, no: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: *, latestQuantityField: *, producedAt?: *, deliveredAt?: *, desiredAt?: *, expiredAt?: *, packageName?: *, packageCapacity?: *, packageQuantity?: * }>,
        ...{ updatedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), totalPrice?: ?({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
            ...{| __typename: 'CustomFields' |},
          ...{ fieldValues: Array<({
              ...{| __typename: 'FieldValue' |},
            ...{ value: ({
                ...{| __typename: 'StringValue' |},
              ...$Pick<StringValue, { string: * }>
            }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
                ...{| __typename: 'FieldDefinition' |},
              ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: *, types: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }), packageGrossWeight?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), packageVolume?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), packageSize?: ?({
            ...{| __typename: 'Size' |},
          ...{ length: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), width: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), height: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }) }
        }), tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
          ...{ price: ({
              ...{| __typename: 'NewPrice' |},
            ...$Pick<NewPrice, { amount: *, currency: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, order: ({
              ...{| __typename: 'Order' |},
            ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
            ...{ totalPrice: ({
                ...{| __typename: 'NewPrice' |},
              ...$Pick<NewPrice, { amount: *, currency: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>,
              ...{ partner?: ?({
                  ...{| __typename: 'Partner' |},
                ...$Pick<Partner, { id: *, name?: *, code?: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>,
              ...{ partner?: ?({
                  ...{| __typename: 'Partner' |},
                ...$Pick<Partner, { id: *, name?: *, code?: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | ({
                ...{| __typename: 'Forbidden' |},
              ...$Pick<Forbidden, { reason?: * }>,
              ...{ reference?: ?({
                  ...{| __typename: 'Reference' |},
                ...$Pick<Reference, { id: * }>
              }) }
            }) | {| __typename: 'NotFound' |}>, timeline: ({
                ...{| __typename: 'Timeline' |},
              ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
            }), ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
                ...{| __typename: 'Notification' |},
              ...$Pick<Notification, { id: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: *, name: * }>,
            ...{ exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>,
              ...{ partner?: ?({
                  ...{| __typename: 'Partner' |},
                ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
                ...{ tags: Array<({
                    ...{| __typename: 'Tag' |},
                  ...$Pick<Tag, { id: *, name: *, color: * }>,
                  ...{ ownedBy: ({
                      ...{| __typename: 'Organization' |},
                    ...$Pick<Organization, { id: *, name: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                      ...{| __typename: 'Connection' |},
                    ...$Pick<Connection, { id: *, displayName: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>,
              ...{ partner?: ?({
                  ...{| __typename: 'Partner' |},
                ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
                ...{ tags: Array<({
                    ...{| __typename: 'Tag' |},
                  ...$Pick<Tag, { id: *, name: *, color: * }>,
                  ...{ ownedBy: ({
                      ...{| __typename: 'Organization' |},
                    ...$Pick<Organization, { id: *, name: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                      ...{| __typename: 'Connection' |},
                    ...$Pick<Connection, { id: *, displayName: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
                ...{| __typename: 'ProductProviderPackage' |},
              ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
              ...{ grossWeight?: ?({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), volume?: ?({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), size?: ?({
                  ...{| __typename: 'Size' |},
                ...{ length: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }), width: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }), height: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }) }
              }) }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
                ...{| __typename: 'ProductProviderPackage' |},
              ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
              ...{ grossWeight?: ?({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), volume?: ?({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), size?: ?({
                  ...{| __typename: 'Size' |},
                ...{ length: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }), width: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }), height: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }) }
              }) }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, product: ({
                ...{| __typename: 'Product' |},
              ...$Pick<Product, { id: *, name: *, serial: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | ({
                  ...{| __typename: 'Forbidden' |},
                ...$Pick<Forbidden, { reason?: * }>,
                ...{ reference?: ?({
                    ...{| __typename: 'Reference' |},
                  ...$Pick<Reference, { id: * }>
                }) }
              }) | {| __typename: 'NotFound' |}>, files: Array<({
                  ...{| __typename: 'File' |},
                ...$Pick<File, { id: *, path: * }>,
                ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment?: ?({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
          ...{ forwarders: Array<({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolume: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), totalVolumeOverride?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), timeline: ({
              ...{| __typename: 'Timeline' |},
            ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
          }), containerTypeCounts: Array<({
              ...{| __typename: 'ContainerTypeCount' |},
            ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
          })>, importer?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, voyages: Array<({
              ...{| __typename: 'Voyage' |},
            ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
            ...{ departurePort?: ?({
                ...{| __typename: 'Port' |},
              ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
            }), arrivalPort?: ?({
                ...{| __typename: 'Port' |},
              ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
            }), departure: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
              ...{| __typename: 'ContainerGroup' |},
            ...$Pick<ContainerGroup, { id: * }>,
            ...{ customClearance: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
                ...{| __typename: 'Warehouse' |},
              ...$Pick<Warehouse, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
              ...{| __typename: 'Container' |},
            ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
            ...{ warehouse?: ?({
                ...{| __typename: 'Warehouse' |},
              ...$Pick<Warehouse, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: * }>,
            ...{ orderItem: ({
                ...{| __typename: 'OrderItem' |},
              ...$Pick<OrderItem, { id: * }>,
              ...{ productProvider: ({
                  ...{| __typename: 'ProductProvider' |},
                ...$Pick<ProductProvider, { id: * }>,
                ...{ exporter: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
              ...{| __typename: 'Notification' |},
            ...$Pick<Notification, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: *, no: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: * }>,
          ...{ representativeBatch?: ?({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: * }>,
            ...{ orderItem: ({
                ...{| __typename: 'OrderItem' |},
              ...$Pick<OrderItem, { id: * }>,
              ...{ productProvider: ({
                  ...{| __typename: 'ProductProvider' |},
                ...$Pick<ProductProvider, { id: * }>,
                ...{ product: ({
                    ...{| __typename: 'Product' |},
                  ...$Pick<Product, { id: *, name: *, serial: * }>,
                  ...{ files: Array<({
                      ...{| __typename: 'File' |},
                    ...$Pick<File, { id: *, path: * }>,
                    ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), batches: Array<({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
              ...{| __typename: 'Shipment' |},
            ...$Pick<Shipment, { id: *, no: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, representativeBatch?: ?({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: *, sort: *, shipmentSort: *, archived: *, autoCalculatePackageQuantity: *, autoCalculatePackageVolume: *, updatedAt: *, memo?: *, no: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: *, latestQuantityField: *, producedAt?: *, deliveredAt?: *, desiredAt?: *, expiredAt?: *, packageName?: *, packageCapacity?: *, packageQuantity?: * }>,
        ...{ updatedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), totalPrice?: ?({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
            ...{| __typename: 'CustomFields' |},
          ...{ fieldValues: Array<({
              ...{| __typename: 'FieldValue' |},
            ...{ value: ({
                ...{| __typename: 'StringValue' |},
              ...$Pick<StringValue, { string: * }>
            }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
                ...{| __typename: 'FieldDefinition' |},
              ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: *, types: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }), packageGrossWeight?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), packageVolume?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), packageSize?: ?({
            ...{| __typename: 'Size' |},
          ...{ length: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), width: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), height: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }) }
        }), tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
          ...{ price: ({
              ...{| __typename: 'NewPrice' |},
            ...$Pick<NewPrice, { amount: *, currency: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, order: ({
              ...{| __typename: 'Order' |},
            ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
            ...{ totalPrice: ({
                ...{| __typename: 'NewPrice' |},
              ...$Pick<NewPrice, { amount: *, currency: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>,
              ...{ partner?: ?({
                  ...{| __typename: 'Partner' |},
                ...$Pick<Partner, { id: *, name?: *, code?: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>,
              ...{ partner?: ?({
                  ...{| __typename: 'Partner' |},
                ...$Pick<Partner, { id: *, name?: *, code?: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | ({
                ...{| __typename: 'Forbidden' |},
              ...$Pick<Forbidden, { reason?: * }>,
              ...{ reference?: ?({
                  ...{| __typename: 'Reference' |},
                ...$Pick<Reference, { id: * }>
              }) }
            }) | {| __typename: 'NotFound' |}>, timeline: ({
                ...{| __typename: 'Timeline' |},
              ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
            }), ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
                ...{| __typename: 'Notification' |},
              ...$Pick<Notification, { id: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: *, name: * }>,
            ...{ exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>,
              ...{ partner?: ?({
                  ...{| __typename: 'Partner' |},
                ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
                ...{ tags: Array<({
                    ...{| __typename: 'Tag' |},
                  ...$Pick<Tag, { id: *, name: *, color: * }>,
                  ...{ ownedBy: ({
                      ...{| __typename: 'Organization' |},
                    ...$Pick<Organization, { id: *, name: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                      ...{| __typename: 'Connection' |},
                    ...$Pick<Connection, { id: *, displayName: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>,
              ...{ partner?: ?({
                  ...{| __typename: 'Partner' |},
                ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
                ...{ tags: Array<({
                    ...{| __typename: 'Tag' |},
                  ...$Pick<Tag, { id: *, name: *, color: * }>,
                  ...{ ownedBy: ({
                      ...{| __typename: 'Organization' |},
                    ...$Pick<Organization, { id: *, name: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                      ...{| __typename: 'Connection' |},
                    ...$Pick<Connection, { id: *, displayName: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
                ...{| __typename: 'ProductProviderPackage' |},
              ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
              ...{ grossWeight?: ?({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), volume?: ?({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), size?: ?({
                  ...{| __typename: 'Size' |},
                ...{ length: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }), width: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }), height: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }) }
              }) }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
                ...{| __typename: 'ProductProviderPackage' |},
              ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
              ...{ grossWeight?: ?({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), volume?: ?({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), size?: ?({
                  ...{| __typename: 'Size' |},
                ...{ length: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }), width: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }), height: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }) }
              }) }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, product: ({
                ...{| __typename: 'Product' |},
              ...$Pick<Product, { id: *, name: *, serial: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | ({
                  ...{| __typename: 'Forbidden' |},
                ...$Pick<Forbidden, { reason?: * }>,
                ...{ reference?: ?({
                    ...{| __typename: 'Reference' |},
                  ...$Pick<Reference, { id: * }>
                }) }
              }) | {| __typename: 'NotFound' |}>, files: Array<({
                  ...{| __typename: 'File' |},
                ...$Pick<File, { id: *, path: * }>,
                ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment?: ?({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
          ...{ forwarders: Array<({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolume: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), totalVolumeOverride?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), timeline: ({
              ...{| __typename: 'Timeline' |},
            ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
          }), containerTypeCounts: Array<({
              ...{| __typename: 'ContainerTypeCount' |},
            ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
          })>, importer?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, voyages: Array<({
              ...{| __typename: 'Voyage' |},
            ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
            ...{ departurePort?: ?({
                ...{| __typename: 'Port' |},
              ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
            }), arrivalPort?: ?({
                ...{| __typename: 'Port' |},
              ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
            }), departure: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
              ...{| __typename: 'ContainerGroup' |},
            ...$Pick<ContainerGroup, { id: * }>,
            ...{ customClearance: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
                ...{| __typename: 'Warehouse' |},
              ...$Pick<Warehouse, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
              ...{| __typename: 'Container' |},
            ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
            ...{ warehouse?: ?({
                ...{| __typename: 'Warehouse' |},
              ...$Pick<Warehouse, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: * }>,
            ...{ orderItem: ({
                ...{| __typename: 'OrderItem' |},
              ...$Pick<OrderItem, { id: * }>,
              ...{ productProvider: ({
                  ...{| __typename: 'ProductProvider' |},
                ...$Pick<ProductProvider, { id: * }>,
                ...{ exporter: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
              ...{| __typename: 'Notification' |},
            ...$Pick<Notification, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: *, no: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: * }>,
          ...{ representativeBatch?: ?({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: * }>,
            ...{ orderItem: ({
                ...{| __typename: 'OrderItem' |},
              ...$Pick<OrderItem, { id: * }>,
              ...{ productProvider: ({
                  ...{| __typename: 'ProductProvider' |},
                ...$Pick<ProductProvider, { id: * }>,
                ...{ product: ({
                    ...{| __typename: 'Product' |},
                  ...$Pick<Product, { id: *, name: *, serial: * }>,
                  ...{ files: Array<({
                      ...{| __typename: 'File' |},
                    ...$Pick<File, { id: *, path: * }>,
                    ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), batches: Array<({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
              ...{| __typename: 'Shipment' |},
            ...$Pick<Shipment, { id: *, no: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: *, sort: *, shipmentSort: *, archived: *, autoCalculatePackageQuantity: *, autoCalculatePackageVolume: *, updatedAt: *, memo?: *, no: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: *, latestQuantityField: *, producedAt?: *, deliveredAt?: *, desiredAt?: *, expiredAt?: *, packageName?: *, packageCapacity?: *, packageQuantity?: * }>,
      ...{ updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalPrice?: ?({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
          ...{| __typename: 'CustomFields' |},
        ...{ fieldValues: Array<({
            ...{| __typename: 'FieldValue' |},
          ...{ value: ({
              ...{| __typename: 'StringValue' |},
            ...$Pick<StringValue, { string: * }>
          }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }), packageGrossWeight?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), packageVolume?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), packageSize?: ?({
          ...{| __typename: 'Size' |},
        ...{ length: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), width: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), height: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }) }
      }), tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, orderItem: ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
        ...{ price: ({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, order: ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
          ...{ totalPrice: ({
              ...{| __typename: 'NewPrice' |},
            ...$Pick<NewPrice, { amount: *, currency: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, timeline: ({
              ...{| __typename: 'Timeline' |},
            ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
          }), ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
              ...{| __typename: 'Notification' |},
            ...$Pick<Notification, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: *, name: * }>,
          ...{ exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
            ...{ grossWeight?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), volume?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), size?: ?({
                ...{| __typename: 'Size' |},
              ...{ length: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), width: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), height: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }) }
            }) }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
            ...{ grossWeight?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), volume?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), size?: ?({
                ...{| __typename: 'Size' |},
              ...{ length: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), width: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), height: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }) }
            }) }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, product: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: *, serial: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | ({
                ...{| __typename: 'Forbidden' |},
              ...$Pick<Forbidden, { reason?: * }>,
              ...{ reference?: ?({
                  ...{| __typename: 'Reference' |},
                ...$Pick<Reference, { id: * }>
              }) }
            }) | {| __typename: 'NotFound' |}>, files: Array<({
                ...{| __typename: 'File' |},
              ...$Pick<File, { id: *, path: * }>,
              ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment?: ?({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
        ...{ forwarders: Array<({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), totalVolumeOverride?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), timeline: ({
            ...{| __typename: 'Timeline' |},
          ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
        }), containerTypeCounts: Array<({
            ...{| __typename: 'ContainerTypeCount' |},
          ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
        })>, importer?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, voyages: Array<({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
          ...{ departurePort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), arrivalPort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), departure: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>,
          ...{ customClearance: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
          ...{ warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>,
          ...{ orderItem: ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>,
            ...{ productProvider: ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>,
              ...{ exporter: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
            ...{| __typename: 'Notification' |},
          ...$Pick<Notification, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: * }>,
        ...{ representativeBatch?: ?({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>,
          ...{ orderItem: ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>,
            ...{ productProvider: ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>,
              ...{ product: ({
                  ...{| __typename: 'Product' |},
                ...$Pick<Product, { id: *, name: *, serial: * }>,
                ...{ files: Array<({
                    ...{| __typename: 'File' |},
                  ...$Pick<File, { id: *, path: * }>,
                  ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), batches: Array<({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, no: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type UserAvatarFragment = ({
    ...{| __typename: 'User' |},
  ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
  ...{ organization?: ?({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type UserCardFragment = ({
    ...{| __typename: 'User' |},
  ...$Pick<User, { id: *, email: *, firstName: *, lastName: * }>,
  ...{ roles: Array<({
      ...{| __typename: 'Role' |},
    ...$Pick<Role, { id: *, name: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, organization?: ?({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type UserFormFragment = ({
    ...{| __typename: 'User' |},
  ...$Pick<User, { id: * }>
});

export type UserListQueryVariables = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?UserFilterInput,
  sortBy?: ?UserSortInput
};


export type UserListQuery = ({
    ...{| __typename: 'Query' |},
  ...{ users: ({
      ...{| __typename: 'UserPayloadPaginatedSearch' |},
    ...$Pick<UserPayloadPaginatedSearch, { page: *, totalPage: * }>,
    ...{ nodes: Array<({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, email: *, firstName: *, lastName: * }>,
      ...{ roles: Array<({
          ...{| __typename: 'Role' |},
        ...$Pick<Role, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) }
});

export type UserOrdersByIDsQueryVariables = {
  ids: Array<$ElementType<Scalars, 'ID'>>
};


export type UserOrdersByIDsQuery = ({
    ...{| __typename: 'Query' |},
  ...{ ordersByIDs: Array<({
      ...{| __typename: 'Order' |},
    ...$Pick<Order, { id: * }>,
    ...{ followers: Array<({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, exporter: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, organizations: Array<({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}> }
});

export type UserShipmentsByIDsQueryVariables = {
  ids: Array<$ElementType<Scalars, 'ID'>>
};


export type UserShipmentsByIDsQuery = ({
    ...{| __typename: 'Query' |},
  ...{ shipmentsByIDs: Array<({
      ...{| __typename: 'Shipment' |},
    ...$Pick<Shipment, { id: * }>,
    ...{ followers: Array<({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, exporter?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, forwarders: Array<({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organizations: Array<({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}> }
});

export type UsersQueryVariables = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?UserFilterInput,
  sortBy?: ?UserSortInput
};


export type UsersQuery = ({
    ...{| __typename: 'Query' |},
  ...{ users: ({
      ...{| __typename: 'UserPayloadPaginatedSearch' |},
    ...$Pick<UserPayloadPaginatedSearch, { page: *, totalPage: * }>,
    ...{ nodes: Array<({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, email: *, firstName: *, lastName: * }>,
      ...{ roles: Array<({
          ...{| __typename: 'Role' |},
        ...$Pick<Role, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) }
});

export type UsersByIDsMinimalQueryVariables = {
  ids: Array<$ElementType<Scalars, 'ID'>>
};


export type UsersByIDsMinimalQuery = ({
    ...{| __typename: 'Query' |},
  ...{ usersByIDs: Array<({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type ViewStateQueryVariables = {
  type: ViewStateType
};


export type ViewStateQuery = ({
    ...{| __typename: 'Query' |},
  ...{ viewState: ({
      ...{| __typename: 'ViewState' |},
    ...$Pick<ViewState, { id: *, type: *, filterSort?: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type WarehouseFragment = ({
    ...{| __typename: 'Warehouse' |},
  ...$Pick<Warehouse, { id: *, name: * }>
});

export type WarehouseCardFragment = ({
    ...{| __typename: 'Warehouse' |},
  ...$Pick<Warehouse, { id: *, name: *, archived: * }>,
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type WarehouseFormFragment = ({
    ...{| __typename: 'Warehouse' |},
  ...$Pick<Warehouse, { id: *, archived: *, updatedAt: *, name: *, street?: *, locality?: *, region?: *, postalCode?: *, country?: *, notificationUnseenCount: * }>,
  ...{ updatedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>,
    ...{ partner?: ?({
        ...{| __typename: 'Partner' |},
      ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
      ...{ tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, followers: Array<({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organizations: Array<({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>,
    ...{ partner?: ?({
        ...{| __typename: 'Partner' |},
      ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
      ...{ tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, surface?: ?({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), customFields: ({
      ...{| __typename: 'CustomFields' |},
    ...{ fieldValues: Array<({
        ...{| __typename: 'FieldValue' |},
      ...{ value: ({
          ...{| __typename: 'StringValue' |},
        ...$Pick<StringValue, { string: * }>
      }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }), notificationUnseen: Array<({
      ...{| __typename: 'Notification' |},
    ...$Pick<Notification, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type WarehouseCreateMutationVariables = {
  input: WarehouseCreateInput
};


export type WarehouseCreateMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ warehouseCreate: ({
      ...{| __typename: 'Warehouse' |},
    ...$Pick<Warehouse, { id: * }>
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |} }
});

export type WarehouseUpdateMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  input: WarehouseUpdateInput
};


export type WarehouseUpdateMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ warehouseUpdate: ({
      ...{| __typename: 'Warehouse' |},
    ...$Pick<Warehouse, { id: *, archived: *, updatedAt: *, name: *, street?: *, locality?: *, region?: *, postalCode?: *, country?: *, notificationUnseenCount: * }>,
    ...{ updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>,
      ...{ partner?: ?({
          ...{| __typename: 'Partner' |},
        ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
        ...{ tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, followers: Array<({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organizations: Array<({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>,
      ...{ partner?: ?({
          ...{| __typename: 'Partner' |},
        ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
        ...{ tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, surface?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), customFields: ({
        ...{| __typename: 'CustomFields' |},
      ...{ fieldValues: Array<({
          ...{| __typename: 'FieldValue' |},
        ...{ value: ({
            ...{| __typename: 'StringValue' |},
          ...$Pick<StringValue, { string: * }>
        }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }), notificationUnseen: Array<({
        ...{| __typename: 'Notification' |},
      ...$Pick<Notification, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |} }
});

export type WarehouseByIdQueryVariables = {
  id: $ElementType<Scalars, 'ID'>
};


export type WarehouseByIdQuery = ({
    ...{| __typename: 'Query' |},
  ...{ warehouse: ({
      ...{| __typename: 'Warehouse' |},
    ...$Pick<Warehouse, { id: *, name: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type WarehouseFormQueryVariables = {
  id: $ElementType<Scalars, 'ID'>
};


export type WarehouseFormQuery = ({
    ...{| __typename: 'Query' |},
  ...{ warehouse: ({
      ...{| __typename: 'Warehouse' |},
    ...$Pick<Warehouse, { id: *, archived: *, updatedAt: *, name: *, street?: *, locality?: *, region?: *, postalCode?: *, country?: *, notificationUnseenCount: * }>,
    ...{ updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>,
      ...{ partner?: ?({
          ...{| __typename: 'Partner' |},
        ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
        ...{ tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, followers: Array<({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organizations: Array<({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>,
      ...{ partner?: ?({
          ...{| __typename: 'Partner' |},
        ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
        ...{ tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, surface?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), customFields: ({
        ...{| __typename: 'CustomFields' |},
      ...{ fieldValues: Array<({
          ...{| __typename: 'FieldValue' |},
        ...{ value: ({
            ...{| __typename: 'StringValue' |},
          ...$Pick<StringValue, { string: * }>
        }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }), notificationUnseen: Array<({
        ...{| __typename: 'Notification' |},
      ...$Pick<Notification, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type WarehouseListQueryVariables = {
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>,
  filterBy?: ?WarehouseFilterInput,
  sortBy?: ?WarehouseSortInput
};


export type WarehouseListQuery = ({
    ...{| __typename: 'Query' |},
  ...{ warehouses: ({
      ...{| __typename: 'WarehousePayloadPaginatedSearch' |},
    ...$Pick<WarehousePayloadPaginatedSearch, { page: *, totalPage: * }>,
    ...{ nodes: Array<({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: *, name: *, archived: *, notificationUnseenCount: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
          ...{| __typename: 'Notification' |},
        ...$Pick<Notification, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) }
});

export type WarehousesQueryVariables = {
  filterBy?: ?WarehouseFilterInput,
  sortBy?: ?WarehouseSortInput,
  page: $ElementType<Scalars, 'Int'>,
  perPage: $ElementType<Scalars, 'Int'>
};


export type WarehousesQuery = ({
    ...{| __typename: 'Query' |},
  ...{ warehouses: ({
      ...{| __typename: 'WarehousePayloadPaginatedSearch' |},
    ...$Pick<WarehousePayloadPaginatedSearch, { page: *, totalPage: * }>,
    ...{ nodes: Array<({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: *, name: *, archived: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}> }
  }) }
});

export type WarehousesByIDsQueryVariables = {
  ids: Array<$ElementType<Scalars, 'ID'>>
};


export type WarehousesByIDsQuery = ({
    ...{| __typename: 'Query' |},
  ...{ warehousesByIDs: Array<({
      ...{| __typename: 'Warehouse' |},
    ...$Pick<Warehouse, { id: *, name: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type ContainerDeleteMutationVariables = {
  id: $ElementType<Scalars, 'ID'>
};


export type ContainerDeleteMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ containerDelete?: ?({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |} }
});

export type ShipmentBatchCardFragment = ({
    ...{| __typename: 'Batch' |},
  ...$Pick<Batch, { id: *, archived: *, no: *, deliveredAt?: *, desiredAt?: *, quantity: *, packageQuantity?: *, latestQuantity: *, latestQuantityField: * }>,
  ...{ packageVolume?: ?({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), totalPrice?: ?({
      ...{| __typename: 'NewPrice' |},
    ...$Pick<NewPrice, { amount: *, currency: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, container?: ?({
      ...{| __typename: 'Container' |},
    ...$Pick<Container, { id: *, no: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, orderItem: ({
      ...{| __typename: 'OrderItem' |},
    ...$Pick<OrderItem, { id: * }>,
    ...{ order: ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: *, poNo: * }>,
      ...{ importer: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: *, name: * }>,
      ...{ product: ({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: *, serial: * }>,
        ...{ files: Array<{| __typename: 'File' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type ShipmentContainerCardFragment = ({
    ...{| __typename: 'Container' |},
  ...$Pick<Container, { id: *, no: *, archived: *, containerType?: *, containerOption?: *, batchCount: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: *, freeTimeStartDate?: *, freeTimeDuration?: * }>,
  ...{ totalVolume: ({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), warehouse?: ?({
      ...{| __typename: 'Warehouse' |},
    ...$Pick<Warehouse, { id: *, name: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, representativeBatch?: ?({
      ...{| __typename: 'Batch' |},
    ...{ orderItem: ({
        ...{| __typename: 'OrderItem' |},
      ...{ productProvider: ({
          ...{| __typename: 'ProductProvider' |},
        ...{ product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { name: *, serial: * }>,
          ...{ files: Array<({
              ...{| __typename: 'File' |},
            ...$Pick<File, { id: *, path: * }>,
            ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type ShipmentContainerCardUserFragment = ({
    ...{| __typename: 'User' |},
  ...$Pick<User, { id: *, firstName: *, lastName: * }>
});

export type ShipmentContainerCardWarehouseFragment = ({
    ...{| __typename: 'Warehouse' |},
  ...$Pick<Warehouse, { id: *, name: * }>,
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type ShipmentFormBatchFragment = ({
    ...{| __typename: 'Batch' |},
  ...$Pick<Batch, { id: *, archived: *, no: *, deliveredAt?: *, desiredAt?: *, quantity: *, packageQuantity?: *, latestQuantity: *, latestQuantityField: *, sort: *, shipmentSort: *, autoCalculatePackageQuantity: *, autoCalculatePackageVolume: *, updatedAt: *, memo?: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, producedAt?: *, expiredAt?: *, packageName?: *, packageCapacity?: * }>,
  ...{ packageVolume?: ?({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), totalPrice?: ?({
      ...{| __typename: 'NewPrice' |},
    ...$Pick<NewPrice, { amount: *, currency: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, container?: ?({
      ...{| __typename: 'Container' |},
    ...$Pick<Container, { id: *, no: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: * }>,
    ...{ representativeBatch?: ?({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: * }>,
      ...{ orderItem: ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: * }>,
        ...{ productProvider: ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: * }>,
          ...{ product: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: *, serial: * }>,
            ...{ files: Array<({
                ...{| __typename: 'File' |},
              ...$Pick<File, { id: *, path: * }>,
              ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), batches: Array<({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, no: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, orderItem: ({
      ...{| __typename: 'OrderItem' |},
    ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
    ...{ order: ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: *, poNo: *, archived: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
      ...{ importer: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { name: *, id: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { name: *, id: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalPrice: ({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, timeline: ({
          ...{| __typename: 'Timeline' |},
        ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
      }), ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
          ...{| __typename: 'Notification' |},
        ...$Pick<Notification, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: *, name: * }>,
      ...{ product: ({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: *, serial: * }>,
        ...{ files: Array<({
            ...{| __typename: 'File' |},
          ...$Pick<File, { id: *, path: * }>,
          ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
          ...{ tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
          ...{ tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
        ...{ grossWeight?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), volume?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), size?: ?({
            ...{| __typename: 'Size' |},
          ...{ length: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), width: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), height: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }) }
        }) }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
        ...{ grossWeight?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), volume?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), size?: ?({
            ...{| __typename: 'Size' |},
          ...{ length: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), width: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), height: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }) }
        }) }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, price: ({
        ...{| __typename: 'NewPrice' |},
      ...$Pick<NewPrice, { amount: *, currency: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
      ...{| __typename: 'CustomFields' |},
    ...{ fieldValues: Array<({
        ...{| __typename: 'FieldValue' |},
      ...{ value: ({
          ...{| __typename: 'StringValue' |},
        ...$Pick<StringValue, { string: * }>
      }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }), packageGrossWeight?: ?({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), packageSize?: ?({
      ...{| __typename: 'Size' |},
    ...{ length: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), width: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), height: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }) }
  }), shipment?: ?({
      ...{| __typename: 'Shipment' |},
    ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
    ...{ forwarders: Array<({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>,
      ...{ partner?: ?({
          ...{| __typename: 'Partner' |},
        ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
        ...{ tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolume: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), totalVolumeOverride?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), timeline: ({
        ...{| __typename: 'Timeline' |},
      ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
    }), containerTypeCounts: Array<({
        ...{| __typename: 'ContainerTypeCount' |},
      ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
    })>, importer?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>,
      ...{ partner?: ?({
          ...{| __typename: 'Partner' |},
        ...$Pick<Partner, { id: *, name?: *, code?: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>,
      ...{ partner?: ?({
          ...{| __typename: 'Partner' |},
        ...$Pick<Partner, { id: *, name?: *, code?: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, date: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, voyages: Array<({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
      ...{ departurePort?: ?({
          ...{| __typename: 'Port' |},
        ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
      }), arrivalPort?: ?({
          ...{| __typename: 'Port' |},
        ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
      }), departure: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>,
      ...{ customClearance: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
      ...{ warehouse?: ?({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: * }>,
      ...{ orderItem: ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: * }>,
        ...{ productProvider: ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: * }>,
          ...{ exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
        ...{| __typename: 'Notification' |},
      ...$Pick<Notification, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type ShipmentFormContainerFragment = ({
    ...{| __typename: 'Container' |},
  ...$Pick<Container, { id: *, no: *, archived: *, containerType?: *, containerOption?: *, batchCount: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: *, freeTimeStartDate?: *, freeTimeDuration?: *, updatedAt: *, memo?: *, vanningDate?: *, vanningDateApprovedAt?: *, vanningActualDate?: *, vanningActualDateApprovedAt?: *, autoCalculatedFreeTimeStartDate: *, yardName?: *, departureDate?: *, departureDateApprovedAt?: * }>,
  ...{ totalVolume: ({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), warehouse?: ?({
      ...{| __typename: 'Warehouse' |},
    ...$Pick<Warehouse, { id: *, name: *, archived: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, representativeBatch?: ?({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { id: *, sort: *, shipmentSort: *, archived: *, autoCalculatePackageQuantity: *, autoCalculatePackageVolume: *, updatedAt: *, memo?: *, no: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: *, latestQuantityField: *, producedAt?: *, deliveredAt?: *, desiredAt?: *, expiredAt?: *, packageName?: *, packageCapacity?: *, packageQuantity?: * }>,
    ...{ orderItem: ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
      ...{ productProvider: ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { name: *, serial: *, id: * }>,
          ...{ files: Array<({
              ...{| __typename: 'File' |},
            ...$Pick<File, { id: *, path: * }>,
            ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
          ...{ grossWeight?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), volume?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), size?: ?({
              ...{| __typename: 'Size' |},
            ...{ length: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), width: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), height: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }) }
          }) }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
          ...{ grossWeight?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), volume?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), size?: ?({
              ...{| __typename: 'Size' |},
            ...{ length: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), width: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), height: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }) }
          }) }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, price: ({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, order: ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
        ...{ totalPrice: ({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, timeline: ({
            ...{| __typename: 'Timeline' |},
          ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
        }), ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
            ...{| __typename: 'Notification' |},
          ...$Pick<Notification, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), totalPrice?: ?({
        ...{| __typename: 'NewPrice' |},
      ...$Pick<NewPrice, { amount: *, currency: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
        ...{| __typename: 'CustomFields' |},
      ...{ fieldValues: Array<({
          ...{| __typename: 'FieldValue' |},
        ...{ value: ({
            ...{| __typename: 'StringValue' |},
          ...$Pick<StringValue, { string: * }>
        }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }), packageGrossWeight?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), packageVolume?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), packageSize?: ?({
        ...{| __typename: 'Size' |},
      ...{ length: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), width: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), height: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }) }
    }), tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, shipment?: ?({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
      ...{ forwarders: Array<({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
          ...{ tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalVolumeOverride?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), timeline: ({
          ...{| __typename: 'Timeline' |},
        ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
      }), containerTypeCounts: Array<({
          ...{| __typename: 'ContainerTypeCount' |},
        ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
      })>, importer?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, voyages: Array<({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
        ...{ departurePort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), arrivalPort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), departure: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ customClearance: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
        ...{ warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>,
        ...{ orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>,
          ...{ productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>,
            ...{ exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
          ...{| __typename: 'Notification' |},
        ...$Pick<Notification, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: *, no: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: * }>,
      ...{ representativeBatch?: ?({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>,
        ...{ orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>,
          ...{ productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>,
            ...{ product: ({
                ...{| __typename: 'Product' |},
              ...$Pick<Product, { id: *, name: *, serial: * }>,
              ...{ files: Array<({
                  ...{| __typename: 'File' |},
                ...$Pick<File, { id: *, path: * }>,
                ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, terminalArrivalPort?: ?({
      ...{| __typename: 'Port' |},
    ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
  }), terminalArrival: ({
      ...{| __typename: 'TimelineDate' |},
    ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
    ...{ approvedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: *, date: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, vanningDateApprovedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, vanningActualDateApprovedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, departureDateApprovedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
      ...{| __typename: 'CustomFields' |},
    ...{ fieldValues: Array<({
        ...{| __typename: 'FieldValue' |},
      ...{ value: ({
          ...{| __typename: 'StringValue' |},
        ...$Pick<StringValue, { string: * }>
      }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }), shipment: ({
      ...{| __typename: 'Shipment' |},
    ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
    ...{ totalVolume: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), totalVolumeOverride?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), timeline: ({
        ...{| __typename: 'Timeline' |},
      ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
    }), containerTypeCounts: Array<({
        ...{| __typename: 'ContainerTypeCount' |},
      ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
    })>, importer?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>,
      ...{ partner?: ?({
          ...{| __typename: 'Partner' |},
        ...$Pick<Partner, { id: *, name?: *, code?: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>,
      ...{ partner?: ?({
          ...{| __typename: 'Partner' |},
        ...$Pick<Partner, { id: *, name?: *, code?: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, date: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, voyages: Array<({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
      ...{ departurePort?: ?({
          ...{| __typename: 'Port' |},
        ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
      }), arrivalPort?: ?({
          ...{| __typename: 'Port' |},
        ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
      }), departure: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>,
      ...{ customClearance: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
      ...{ warehouse?: ?({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: * }>,
      ...{ orderItem: ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: * }>,
        ...{ productProvider: ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: * }>,
          ...{ exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
        ...{| __typename: 'Notification' |},
      ...$Pick<Notification, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, batches: Array<({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { id: *, sort: *, shipmentSort: *, archived: *, autoCalculatePackageQuantity: *, autoCalculatePackageVolume: *, updatedAt: *, memo?: *, no: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: *, latestQuantityField: *, producedAt?: *, deliveredAt?: *, desiredAt?: *, expiredAt?: *, packageName?: *, packageCapacity?: *, packageQuantity?: * }>,
    ...{ updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), totalPrice?: ?({
        ...{| __typename: 'NewPrice' |},
      ...$Pick<NewPrice, { amount: *, currency: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
        ...{| __typename: 'CustomFields' |},
      ...{ fieldValues: Array<({
          ...{| __typename: 'FieldValue' |},
        ...{ value: ({
            ...{| __typename: 'StringValue' |},
          ...$Pick<StringValue, { string: * }>
        }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }), packageGrossWeight?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), packageVolume?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), packageSize?: ?({
        ...{| __typename: 'Size' |},
      ...{ length: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), width: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), height: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }) }
    }), tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, orderItem: ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
      ...{ price: ({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, order: ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
        ...{ totalPrice: ({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, timeline: ({
            ...{| __typename: 'Timeline' |},
          ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
        }), ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
            ...{| __typename: 'Notification' |},
          ...$Pick<Notification, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ exporter: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
          ...{ grossWeight?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), volume?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), size?: ?({
              ...{| __typename: 'Size' |},
            ...{ length: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), width: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), height: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }) }
          }) }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
          ...{ grossWeight?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), volume?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), size?: ?({
              ...{| __typename: 'Size' |},
            ...{ length: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), width: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), height: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }) }
          }) }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: *, serial: * }>,
          ...{ tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, files: Array<({
              ...{| __typename: 'File' |},
            ...$Pick<File, { id: *, path: * }>,
            ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment?: ?({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
      ...{ forwarders: Array<({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
          ...{ tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalVolumeOverride?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), timeline: ({
          ...{| __typename: 'Timeline' |},
        ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
      }), containerTypeCounts: Array<({
          ...{| __typename: 'ContainerTypeCount' |},
        ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
      })>, importer?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, voyages: Array<({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
        ...{ departurePort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), arrivalPort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), departure: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ customClearance: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
        ...{ warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>,
        ...{ orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>,
          ...{ productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>,
            ...{ exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
          ...{| __typename: 'Notification' |},
        ...$Pick<Notification, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: *, no: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: * }>,
      ...{ representativeBatch?: ?({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>,
        ...{ orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>,
          ...{ productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>,
            ...{ product: ({
                ...{| __typename: 'Product' |},
              ...$Pick<Product, { id: *, name: *, serial: * }>,
              ...{ files: Array<({
                  ...{| __typename: 'File' |},
                ...$Pick<File, { id: *, path: * }>,
                ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type ShipmentFormIDsFragment = ({
    ...{| __typename: 'Shipment' |},
  ...$Pick<Shipment, { id: * }>,
  ...{ containers: Array<({
      ...{| __typename: 'Container' |},
    ...$Pick<Container, { id: * }>,
    ...{ batches: Array<({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { id: * }>,
    ...{ container?: ?({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, cargoReady: ({
      ...{| __typename: 'TimelineDate' |},
    ...$Pick<TimelineDate, { id: * }>,
    ...{ timelineDateRevisions: Array<({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, voyages: Array<({
      ...{| __typename: 'Voyage' |},
    ...$Pick<Voyage, { id: * }>,
    ...{ departure: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: * }>,
      ...{ timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: * }>,
      ...{ timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
      ...{| __typename: 'ContainerGroup' |},
    ...$Pick<ContainerGroup, { id: * }>,
    ...{ customClearance: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: * }>,
      ...{ timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: * }>,
      ...{ timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: * }>,
      ...{ timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type ShipmentFormTimelineDateFragment = ({
    ...{| __typename: 'TimelineDate' |},
  ...$Pick<TimelineDate, { id: *, approvedAt?: *, date?: *, resultDate?: * }>,
  ...{ approvedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
      ...{| __typename: 'TimelineDateRevision' |},
    ...$Pick<TimelineDateRevision, { id: *, updatedAt: *, date: *, type: *, memo?: * }>,
    ...{ updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type ShipmentFormTimelineUserFragment = ({
    ...{| __typename: 'User' |},
  ...$Pick<User, { id: *, firstName: *, lastName: * }>
});

export type ShipmentFormVoyageFragment = ({
    ...{| __typename: 'Voyage' |},
  ...$Pick<Voyage, { id: *, no?: *, vesselName?: *, vesselCode?: * }>,
  ...{ departurePort?: ?({
      ...{| __typename: 'Port' |},
    ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
  }), arrivalPort?: ?({
      ...{| __typename: 'Port' |},
    ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
  }), departure: ({
      ...{| __typename: 'TimelineDate' |},
    ...$Pick<TimelineDate, { id: *, approvedAt?: *, date?: *, resultDate?: * }>,
    ...{ approvedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: *, updatedAt: *, date: *, type: *, memo?: * }>,
      ...{ updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
      ...{| __typename: 'TimelineDate' |},
    ...$Pick<TimelineDate, { id: *, approvedAt?: *, date?: *, resultDate?: * }>,
    ...{ approvedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: *, updatedAt: *, date: *, type: *, memo?: * }>,
      ...{ updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});

export type ShipmentFormCreateMutationVariables = {
  input: ShipmentCreateInput
};


export type ShipmentFormCreateMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ shipmentCreate: ({
      ...{| __typename: 'Shipment' |},
    ...$Pick<Shipment, { id: * }>,
    ...{ containers: Array<({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: * }>,
      ...{ batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: * }>,
      ...{ container?: ?({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, cargoReady: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: * }>,
      ...{ timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, voyages: Array<({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: * }>,
      ...{ departure: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: * }>,
        ...{ timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: * }>,
        ...{ timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>,
      ...{ customClearance: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: * }>,
        ...{ timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: * }>,
        ...{ timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: * }>,
        ...{ timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | ({
      ...{| __typename: 'NotFound' |},
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: *, type: *, service: * }>
    }) }
  }) }
});

export type ShipmentFormUpdateMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  input: ShipmentUpdateInput
};


export type ShipmentFormUpdateMutation = ({
    ...{| __typename: 'Mutation' |},
  ...{ shipmentUpdate: ({
      ...{| __typename: 'Shipment' |},
    ...$Pick<Shipment, { id: * }>,
    ...{ containers: Array<({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: * }>,
      ...{ batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: * }>,
      ...{ container?: ?({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, cargoReady: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: * }>,
      ...{ timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, voyages: Array<({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: * }>,
      ...{ departure: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: * }>,
        ...{ timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: * }>,
        ...{ timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>,
      ...{ customClearance: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: * }>,
        ...{ timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: * }>,
        ...{ timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: * }>,
        ...{ timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | ({
      ...{| __typename: 'NotFound' |},
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: *, type: *, service: * }>
    }) }
  }) }
});

export type ShipmentFormCargoQueryVariables = {
  id: $ElementType<Scalars, 'ID'>
};


export type ShipmentFormCargoQuery = ({
    ...{| __typename: 'Query' |},
  ...{ shipment: ({
      ...{| __typename: 'Shipment' |},
    ...$Pick<Shipment, { id: * }>,
    ...{ batches: Array<({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: *, archived: *, no: *, deliveredAt?: *, desiredAt?: *, quantity: *, packageQuantity?: *, latestQuantity: *, latestQuantityField: *, sort: *, shipmentSort: *, autoCalculatePackageQuantity: *, autoCalculatePackageVolume: *, updatedAt: *, memo?: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, producedAt?: *, expiredAt?: *, packageName?: *, packageCapacity?: * }>,
      ...{ packageVolume?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalPrice?: ?({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, container?: ?({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: * }>,
        ...{ representativeBatch?: ?({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>,
          ...{ orderItem: ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>,
            ...{ productProvider: ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>,
              ...{ product: ({
                  ...{| __typename: 'Product' |},
                ...$Pick<Product, { id: *, name: *, serial: * }>,
                ...{ files: Array<({
                    ...{| __typename: 'File' |},
                  ...$Pick<File, { id: *, path: * }>,
                  ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), batches: Array<({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, no: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, orderItem: ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
        ...{ order: ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: *, poNo: *, archived: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
          ...{ importer: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { name: *, id: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { name: *, id: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalPrice: ({
              ...{| __typename: 'NewPrice' |},
            ...$Pick<NewPrice, { amount: *, currency: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, timeline: ({
              ...{| __typename: 'Timeline' |},
            ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
          }), ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
              ...{| __typename: 'Notification' |},
            ...$Pick<Notification, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: *, name: * }>,
          ...{ product: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: *, serial: * }>,
            ...{ files: Array<({
                ...{| __typename: 'File' |},
              ...$Pick<File, { id: *, path: * }>,
              ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | ({
                ...{| __typename: 'Forbidden' |},
              ...$Pick<Forbidden, { reason?: * }>,
              ...{ reference?: ?({
                  ...{| __typename: 'Reference' |},
                ...$Pick<Reference, { id: * }>
              }) }
            }) | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
            ...{ grossWeight?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), volume?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), size?: ?({
                ...{| __typename: 'Size' |},
              ...{ length: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), width: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), height: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }) }
            }) }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
            ...{ grossWeight?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), volume?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), size?: ?({
                ...{| __typename: 'Size' |},
              ...{ length: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), width: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), height: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }) }
            }) }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, price: ({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
          ...{| __typename: 'CustomFields' |},
        ...{ fieldValues: Array<({
            ...{| __typename: 'FieldValue' |},
          ...{ value: ({
              ...{| __typename: 'StringValue' |},
            ...$Pick<StringValue, { string: * }>
          }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }), packageGrossWeight?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), packageSize?: ?({
          ...{| __typename: 'Size' |},
        ...{ length: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), width: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), height: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }) }
      }), shipment?: ?({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
        ...{ forwarders: Array<({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), totalVolumeOverride?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), timeline: ({
            ...{| __typename: 'Timeline' |},
          ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
        }), containerTypeCounts: Array<({
            ...{| __typename: 'ContainerTypeCount' |},
          ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
        })>, importer?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, voyages: Array<({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
          ...{ departurePort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), arrivalPort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), departure: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>,
          ...{ customClearance: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
          ...{ warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>,
          ...{ orderItem: ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>,
            ...{ productProvider: ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>,
              ...{ exporter: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
            ...{| __typename: 'Notification' |},
          ...$Pick<Notification, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: *, no: *, archived: *, containerType?: *, containerOption?: *, batchCount: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: *, freeTimeStartDate?: *, freeTimeDuration?: *, updatedAt: *, memo?: *, vanningDate?: *, vanningDateApprovedAt?: *, vanningActualDate?: *, vanningActualDateApprovedAt?: *, autoCalculatedFreeTimeStartDate: *, yardName?: *, departureDate?: *, departureDateApprovedAt?: * }>,
      ...{ totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), warehouse?: ?({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: *, name: *, archived: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, representativeBatch?: ?({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: *, sort: *, shipmentSort: *, archived: *, autoCalculatePackageQuantity: *, autoCalculatePackageVolume: *, updatedAt: *, memo?: *, no: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: *, latestQuantityField: *, producedAt?: *, deliveredAt?: *, desiredAt?: *, expiredAt?: *, packageName?: *, packageCapacity?: *, packageQuantity?: * }>,
        ...{ orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
          ...{ productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: *, name: * }>,
            ...{ product: ({
                ...{| __typename: 'Product' |},
              ...$Pick<Product, { name: *, serial: *, id: * }>,
              ...{ files: Array<({
                  ...{| __typename: 'File' |},
                ...$Pick<File, { id: *, path: * }>,
                ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | ({
                  ...{| __typename: 'Forbidden' |},
                ...$Pick<Forbidden, { reason?: * }>,
                ...{ reference?: ?({
                    ...{| __typename: 'Reference' |},
                  ...$Pick<Reference, { id: * }>
                }) }
              }) | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>,
              ...{ partner?: ?({
                  ...{| __typename: 'Partner' |},
                ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
                ...{ tags: Array<({
                    ...{| __typename: 'Tag' |},
                  ...$Pick<Tag, { id: *, name: *, color: * }>,
                  ...{ ownedBy: ({
                      ...{| __typename: 'Organization' |},
                    ...$Pick<Organization, { id: *, name: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                      ...{| __typename: 'Connection' |},
                    ...$Pick<Connection, { id: *, displayName: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>,
              ...{ partner?: ?({
                  ...{| __typename: 'Partner' |},
                ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
                ...{ tags: Array<({
                    ...{| __typename: 'Tag' |},
                  ...$Pick<Tag, { id: *, name: *, color: * }>,
                  ...{ ownedBy: ({
                      ...{| __typename: 'Organization' |},
                    ...$Pick<Organization, { id: *, name: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                      ...{| __typename: 'Connection' |},
                    ...$Pick<Connection, { id: *, displayName: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
                ...{| __typename: 'ProductProviderPackage' |},
              ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
              ...{ grossWeight?: ?({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), volume?: ?({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), size?: ?({
                  ...{| __typename: 'Size' |},
                ...{ length: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }), width: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }), height: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }) }
              }) }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
                ...{| __typename: 'ProductProviderPackage' |},
              ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
              ...{ grossWeight?: ?({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), volume?: ?({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), size?: ?({
                  ...{| __typename: 'Size' |},
                ...{ length: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }), width: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }), height: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }) }
              }) }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, price: ({
              ...{| __typename: 'NewPrice' |},
            ...$Pick<NewPrice, { amount: *, currency: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, order: ({
              ...{| __typename: 'Order' |},
            ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
            ...{ totalPrice: ({
                ...{| __typename: 'NewPrice' |},
              ...$Pick<NewPrice, { amount: *, currency: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>,
              ...{ partner?: ?({
                  ...{| __typename: 'Partner' |},
                ...$Pick<Partner, { id: *, name?: *, code?: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>,
              ...{ partner?: ?({
                  ...{| __typename: 'Partner' |},
                ...$Pick<Partner, { id: *, name?: *, code?: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | ({
                ...{| __typename: 'Forbidden' |},
              ...$Pick<Forbidden, { reason?: * }>,
              ...{ reference?: ?({
                  ...{| __typename: 'Reference' |},
                ...$Pick<Reference, { id: * }>
              }) }
            }) | {| __typename: 'NotFound' |}>, timeline: ({
                ...{| __typename: 'Timeline' |},
              ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
            }), ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
                ...{| __typename: 'Notification' |},
              ...$Pick<Notification, { id: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), totalPrice?: ?({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
            ...{| __typename: 'CustomFields' |},
          ...{ fieldValues: Array<({
              ...{| __typename: 'FieldValue' |},
            ...{ value: ({
                ...{| __typename: 'StringValue' |},
              ...$Pick<StringValue, { string: * }>
            }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
                ...{| __typename: 'FieldDefinition' |},
              ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: *, types: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }), packageGrossWeight?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), packageVolume?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), packageSize?: ?({
            ...{| __typename: 'Size' |},
          ...{ length: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), width: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), height: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }) }
        }), tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, shipment?: ?({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
          ...{ forwarders: Array<({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolume: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), totalVolumeOverride?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), timeline: ({
              ...{| __typename: 'Timeline' |},
            ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
          }), containerTypeCounts: Array<({
              ...{| __typename: 'ContainerTypeCount' |},
            ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
          })>, importer?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, voyages: Array<({
              ...{| __typename: 'Voyage' |},
            ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
            ...{ departurePort?: ?({
                ...{| __typename: 'Port' |},
              ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
            }), arrivalPort?: ?({
                ...{| __typename: 'Port' |},
              ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
            }), departure: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
              ...{| __typename: 'ContainerGroup' |},
            ...$Pick<ContainerGroup, { id: * }>,
            ...{ customClearance: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
                ...{| __typename: 'Warehouse' |},
              ...$Pick<Warehouse, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
              ...{| __typename: 'Container' |},
            ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
            ...{ warehouse?: ?({
                ...{| __typename: 'Warehouse' |},
              ...$Pick<Warehouse, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: * }>,
            ...{ orderItem: ({
                ...{| __typename: 'OrderItem' |},
              ...$Pick<OrderItem, { id: * }>,
              ...{ productProvider: ({
                  ...{| __typename: 'ProductProvider' |},
                ...$Pick<ProductProvider, { id: * }>,
                ...{ exporter: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
              ...{| __typename: 'Notification' |},
            ...$Pick<Notification, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: *, no: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: * }>,
          ...{ representativeBatch?: ?({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: * }>,
            ...{ orderItem: ({
                ...{| __typename: 'OrderItem' |},
              ...$Pick<OrderItem, { id: * }>,
              ...{ productProvider: ({
                  ...{| __typename: 'ProductProvider' |},
                ...$Pick<ProductProvider, { id: * }>,
                ...{ product: ({
                    ...{| __typename: 'Product' |},
                  ...$Pick<Product, { id: *, name: *, serial: * }>,
                  ...{ files: Array<({
                      ...{| __typename: 'File' |},
                    ...$Pick<File, { id: *, path: * }>,
                    ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), batches: Array<({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
              ...{| __typename: 'Shipment' |},
            ...$Pick<Shipment, { id: *, no: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, terminalArrivalPort?: ?({
          ...{| __typename: 'Port' |},
        ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
      }), terminalArrival: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, vanningDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, vanningActualDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, departureDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
          ...{| __typename: 'CustomFields' |},
        ...{ fieldValues: Array<({
            ...{| __typename: 'FieldValue' |},
          ...{ value: ({
              ...{| __typename: 'StringValue' |},
            ...$Pick<StringValue, { string: * }>
          }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }), shipment: ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
        ...{ totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), totalVolumeOverride?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), timeline: ({
            ...{| __typename: 'Timeline' |},
          ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
        }), containerTypeCounts: Array<({
            ...{| __typename: 'ContainerTypeCount' |},
          ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
        })>, importer?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, voyages: Array<({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
          ...{ departurePort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), arrivalPort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), departure: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>,
          ...{ customClearance: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
          ...{ warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>,
          ...{ orderItem: ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>,
            ...{ productProvider: ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>,
              ...{ exporter: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
            ...{| __typename: 'Notification' |},
          ...$Pick<Notification, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: *, sort: *, shipmentSort: *, archived: *, autoCalculatePackageQuantity: *, autoCalculatePackageVolume: *, updatedAt: *, memo?: *, no: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: *, latestQuantityField: *, producedAt?: *, deliveredAt?: *, desiredAt?: *, expiredAt?: *, packageName?: *, packageCapacity?: *, packageQuantity?: * }>,
        ...{ updatedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), totalPrice?: ?({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
            ...{| __typename: 'CustomFields' |},
          ...{ fieldValues: Array<({
              ...{| __typename: 'FieldValue' |},
            ...{ value: ({
                ...{| __typename: 'StringValue' |},
              ...$Pick<StringValue, { string: * }>
            }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
                ...{| __typename: 'FieldDefinition' |},
              ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: *, types: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }), packageGrossWeight?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), packageVolume?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), packageSize?: ?({
            ...{| __typename: 'Size' |},
          ...{ length: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), width: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), height: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }) }
        }), tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
          ...{ price: ({
              ...{| __typename: 'NewPrice' |},
            ...$Pick<NewPrice, { amount: *, currency: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, order: ({
              ...{| __typename: 'Order' |},
            ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
            ...{ totalPrice: ({
                ...{| __typename: 'NewPrice' |},
              ...$Pick<NewPrice, { amount: *, currency: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>,
              ...{ partner?: ?({
                  ...{| __typename: 'Partner' |},
                ...$Pick<Partner, { id: *, name?: *, code?: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>,
              ...{ partner?: ?({
                  ...{| __typename: 'Partner' |},
                ...$Pick<Partner, { id: *, name?: *, code?: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | ({
                ...{| __typename: 'Forbidden' |},
              ...$Pick<Forbidden, { reason?: * }>,
              ...{ reference?: ?({
                  ...{| __typename: 'Reference' |},
                ...$Pick<Reference, { id: * }>
              }) }
            }) | {| __typename: 'NotFound' |}>, timeline: ({
                ...{| __typename: 'Timeline' |},
              ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
            }), ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
                ...{| __typename: 'Notification' |},
              ...$Pick<Notification, { id: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: *, name: * }>,
            ...{ exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>,
              ...{ partner?: ?({
                  ...{| __typename: 'Partner' |},
                ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
                ...{ tags: Array<({
                    ...{| __typename: 'Tag' |},
                  ...$Pick<Tag, { id: *, name: *, color: * }>,
                  ...{ ownedBy: ({
                      ...{| __typename: 'Organization' |},
                    ...$Pick<Organization, { id: *, name: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                      ...{| __typename: 'Connection' |},
                    ...$Pick<Connection, { id: *, displayName: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>,
              ...{ partner?: ?({
                  ...{| __typename: 'Partner' |},
                ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
                ...{ tags: Array<({
                    ...{| __typename: 'Tag' |},
                  ...$Pick<Tag, { id: *, name: *, color: * }>,
                  ...{ ownedBy: ({
                      ...{| __typename: 'Organization' |},
                    ...$Pick<Organization, { id: *, name: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                      ...{| __typename: 'Connection' |},
                    ...$Pick<Connection, { id: *, displayName: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
                ...{| __typename: 'ProductProviderPackage' |},
              ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
              ...{ grossWeight?: ?({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), volume?: ?({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), size?: ?({
                  ...{| __typename: 'Size' |},
                ...{ length: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }), width: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }), height: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }) }
              }) }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
                ...{| __typename: 'ProductProviderPackage' |},
              ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
              ...{ grossWeight?: ?({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), volume?: ?({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), size?: ?({
                  ...{| __typename: 'Size' |},
                ...{ length: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }), width: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }), height: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }) }
              }) }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, product: ({
                ...{| __typename: 'Product' |},
              ...$Pick<Product, { id: *, name: *, serial: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | ({
                  ...{| __typename: 'Forbidden' |},
                ...$Pick<Forbidden, { reason?: * }>,
                ...{ reference?: ?({
                    ...{| __typename: 'Reference' |},
                  ...$Pick<Reference, { id: * }>
                }) }
              }) | {| __typename: 'NotFound' |}>, files: Array<({
                  ...{| __typename: 'File' |},
                ...$Pick<File, { id: *, path: * }>,
                ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment?: ?({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
          ...{ forwarders: Array<({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolume: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), totalVolumeOverride?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), timeline: ({
              ...{| __typename: 'Timeline' |},
            ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
          }), containerTypeCounts: Array<({
              ...{| __typename: 'ContainerTypeCount' |},
            ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
          })>, importer?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, voyages: Array<({
              ...{| __typename: 'Voyage' |},
            ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
            ...{ departurePort?: ?({
                ...{| __typename: 'Port' |},
              ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
            }), arrivalPort?: ?({
                ...{| __typename: 'Port' |},
              ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
            }), departure: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
              ...{| __typename: 'ContainerGroup' |},
            ...$Pick<ContainerGroup, { id: * }>,
            ...{ customClearance: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
                ...{| __typename: 'Warehouse' |},
              ...$Pick<Warehouse, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
              ...{| __typename: 'Container' |},
            ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
            ...{ warehouse?: ?({
                ...{| __typename: 'Warehouse' |},
              ...$Pick<Warehouse, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: * }>,
            ...{ orderItem: ({
                ...{| __typename: 'OrderItem' |},
              ...$Pick<OrderItem, { id: * }>,
              ...{ productProvider: ({
                  ...{| __typename: 'ProductProvider' |},
                ...$Pick<ProductProvider, { id: * }>,
                ...{ exporter: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
              ...{| __typename: 'Notification' |},
            ...$Pick<Notification, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: *, no: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: * }>,
          ...{ representativeBatch?: ?({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: * }>,
            ...{ orderItem: ({
                ...{| __typename: 'OrderItem' |},
              ...$Pick<OrderItem, { id: * }>,
              ...{ productProvider: ({
                  ...{| __typename: 'ProductProvider' |},
                ...$Pick<ProductProvider, { id: * }>,
                ...{ product: ({
                    ...{| __typename: 'Product' |},
                  ...$Pick<Product, { id: *, name: *, serial: * }>,
                  ...{ files: Array<({
                      ...{| __typename: 'File' |},
                    ...$Pick<File, { id: *, path: * }>,
                    ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), batches: Array<({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
              ...{| __typename: 'Shipment' |},
            ...$Pick<Shipment, { id: *, no: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | ({
      ...{| __typename: 'NotFound' |},
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: *, type: *, service: * }>
    }) }
  }) }
});

export type ShipmentFormCargoFragment = ({
    ...{| __typename: 'Shipment' |},
  ...$Pick<Shipment, { id: * }>,
  ...{ batches: Array<({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { id: *, archived: *, no: *, deliveredAt?: *, desiredAt?: *, quantity: *, packageQuantity?: *, latestQuantity: *, latestQuantityField: *, sort: *, shipmentSort: *, autoCalculatePackageQuantity: *, autoCalculatePackageVolume: *, updatedAt: *, memo?: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, producedAt?: *, expiredAt?: *, packageName?: *, packageCapacity?: * }>,
    ...{ packageVolume?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), totalPrice?: ?({
        ...{| __typename: 'NewPrice' |},
      ...$Pick<NewPrice, { amount: *, currency: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, container?: ?({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: *, no: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: * }>,
      ...{ representativeBatch?: ?({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>,
        ...{ orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>,
          ...{ productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>,
            ...{ product: ({
                ...{| __typename: 'Product' |},
              ...$Pick<Product, { id: *, name: *, serial: * }>,
              ...{ files: Array<({
                  ...{| __typename: 'File' |},
                ...$Pick<File, { id: *, path: * }>,
                ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, orderItem: ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
      ...{ order: ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, poNo: *, archived: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
        ...{ importer: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { name: *, id: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { name: *, id: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalPrice: ({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, timeline: ({
            ...{| __typename: 'Timeline' |},
          ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
        }), ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
            ...{| __typename: 'Notification' |},
          ...$Pick<Notification, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: *, serial: * }>,
          ...{ files: Array<({
              ...{| __typename: 'File' |},
            ...$Pick<File, { id: *, path: * }>,
            ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
          ...{ grossWeight?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), volume?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), size?: ?({
              ...{| __typename: 'Size' |},
            ...{ length: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), width: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), height: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }) }
          }) }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
          ...{ grossWeight?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), volume?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), size?: ?({
              ...{| __typename: 'Size' |},
            ...{ length: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), width: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), height: ({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }) }
          }) }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, price: ({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
        ...{| __typename: 'CustomFields' |},
      ...{ fieldValues: Array<({
          ...{| __typename: 'FieldValue' |},
        ...{ value: ({
            ...{| __typename: 'StringValue' |},
          ...$Pick<StringValue, { string: * }>
        }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }), packageGrossWeight?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), packageSize?: ?({
        ...{| __typename: 'Size' |},
      ...{ length: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), width: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), height: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }) }
    }), shipment?: ?({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
      ...{ forwarders: Array<({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
          ...{ tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalVolumeOverride?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), timeline: ({
          ...{| __typename: 'Timeline' |},
        ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
      }), containerTypeCounts: Array<({
          ...{| __typename: 'ContainerTypeCount' |},
        ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
      })>, importer?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, voyages: Array<({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
        ...{ departurePort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), arrivalPort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), departure: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ customClearance: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
        ...{ warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>,
        ...{ orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>,
          ...{ productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>,
            ...{ exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
          ...{| __typename: 'Notification' |},
        ...$Pick<Notification, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
      ...{| __typename: 'Container' |},
    ...$Pick<Container, { id: *, no: *, archived: *, containerType?: *, containerOption?: *, batchCount: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: *, freeTimeStartDate?: *, freeTimeDuration?: *, updatedAt: *, memo?: *, vanningDate?: *, vanningDateApprovedAt?: *, vanningActualDate?: *, vanningActualDateApprovedAt?: *, autoCalculatedFreeTimeStartDate: *, yardName?: *, departureDate?: *, departureDateApprovedAt?: * }>,
    ...{ totalVolume: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), warehouse?: ?({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: *, name: *, archived: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, representativeBatch?: ?({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: *, sort: *, shipmentSort: *, archived: *, autoCalculatePackageQuantity: *, autoCalculatePackageVolume: *, updatedAt: *, memo?: *, no: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: *, latestQuantityField: *, producedAt?: *, deliveredAt?: *, desiredAt?: *, expiredAt?: *, packageName?: *, packageCapacity?: *, packageQuantity?: * }>,
      ...{ orderItem: ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
        ...{ productProvider: ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: *, name: * }>,
          ...{ product: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { name: *, serial: *, id: * }>,
            ...{ files: Array<({
                ...{| __typename: 'File' |},
              ...$Pick<File, { id: *, path: * }>,
              ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | ({
                ...{| __typename: 'Forbidden' |},
              ...$Pick<Forbidden, { reason?: * }>,
              ...{ reference?: ?({
                  ...{| __typename: 'Reference' |},
                ...$Pick<Reference, { id: * }>
              }) }
            }) | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
            ...{ grossWeight?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), volume?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), size?: ?({
                ...{| __typename: 'Size' |},
              ...{ length: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), width: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), height: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }) }
            }) }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
            ...{ grossWeight?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), volume?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), size?: ?({
                ...{| __typename: 'Size' |},
              ...{ length: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), width: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), height: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }) }
            }) }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, price: ({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, order: ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
          ...{ totalPrice: ({
              ...{| __typename: 'NewPrice' |},
            ...$Pick<NewPrice, { amount: *, currency: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, timeline: ({
              ...{| __typename: 'Timeline' |},
            ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
          }), ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
              ...{| __typename: 'Notification' |},
            ...$Pick<Notification, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalPrice?: ?({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
          ...{| __typename: 'CustomFields' |},
        ...{ fieldValues: Array<({
            ...{| __typename: 'FieldValue' |},
          ...{ value: ({
              ...{| __typename: 'StringValue' |},
            ...$Pick<StringValue, { string: * }>
          }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }), packageGrossWeight?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), packageVolume?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), packageSize?: ?({
          ...{| __typename: 'Size' |},
        ...{ length: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), width: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), height: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }) }
      }), tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, shipment?: ?({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
        ...{ forwarders: Array<({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), totalVolumeOverride?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), timeline: ({
            ...{| __typename: 'Timeline' |},
          ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
        }), containerTypeCounts: Array<({
            ...{| __typename: 'ContainerTypeCount' |},
          ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
        })>, importer?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, voyages: Array<({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
          ...{ departurePort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), arrivalPort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), departure: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>,
          ...{ customClearance: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
          ...{ warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>,
          ...{ orderItem: ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>,
            ...{ productProvider: ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>,
              ...{ exporter: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
            ...{| __typename: 'Notification' |},
          ...$Pick<Notification, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: * }>,
        ...{ representativeBatch?: ?({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>,
          ...{ orderItem: ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>,
            ...{ productProvider: ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>,
              ...{ product: ({
                  ...{| __typename: 'Product' |},
                ...$Pick<Product, { id: *, name: *, serial: * }>,
                ...{ files: Array<({
                    ...{| __typename: 'File' |},
                  ...$Pick<File, { id: *, path: * }>,
                  ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), batches: Array<({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, no: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, terminalArrivalPort?: ?({
        ...{| __typename: 'Port' |},
      ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
    }), terminalArrival: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, date: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, vanningDateApprovedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, vanningActualDateApprovedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, departureDateApprovedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
        ...{| __typename: 'CustomFields' |},
      ...{ fieldValues: Array<({
          ...{| __typename: 'FieldValue' |},
        ...{ value: ({
            ...{| __typename: 'StringValue' |},
          ...$Pick<StringValue, { string: * }>
        }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }), shipment: ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
      ...{ totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalVolumeOverride?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), timeline: ({
          ...{| __typename: 'Timeline' |},
        ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
      }), containerTypeCounts: Array<({
          ...{| __typename: 'ContainerTypeCount' |},
        ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
      })>, importer?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, voyages: Array<({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
        ...{ departurePort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), arrivalPort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), departure: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ customClearance: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
        ...{ warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>,
        ...{ orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>,
          ...{ productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>,
            ...{ exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
          ...{| __typename: 'Notification' |},
        ...$Pick<Notification, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, batches: Array<({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: *, sort: *, shipmentSort: *, archived: *, autoCalculatePackageQuantity: *, autoCalculatePackageVolume: *, updatedAt: *, memo?: *, no: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: *, latestQuantityField: *, producedAt?: *, deliveredAt?: *, desiredAt?: *, expiredAt?: *, packageName?: *, packageCapacity?: *, packageQuantity?: * }>,
      ...{ updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalPrice?: ?({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
          ...{| __typename: 'CustomFields' |},
        ...{ fieldValues: Array<({
            ...{| __typename: 'FieldValue' |},
          ...{ value: ({
              ...{| __typename: 'StringValue' |},
            ...$Pick<StringValue, { string: * }>
          }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }), packageGrossWeight?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), packageVolume?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), packageSize?: ?({
          ...{| __typename: 'Size' |},
        ...{ length: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), width: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), height: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }) }
      }), tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, orderItem: ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
        ...{ price: ({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, order: ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
          ...{ totalPrice: ({
              ...{| __typename: 'NewPrice' |},
            ...$Pick<NewPrice, { amount: *, currency: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, timeline: ({
              ...{| __typename: 'Timeline' |},
            ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
          }), ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
              ...{| __typename: 'Notification' |},
            ...$Pick<Notification, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: *, name: * }>,
          ...{ exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
            ...{ grossWeight?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), volume?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), size?: ?({
                ...{| __typename: 'Size' |},
              ...{ length: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), width: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), height: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }) }
            }) }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
            ...{ grossWeight?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), volume?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), size?: ?({
                ...{| __typename: 'Size' |},
              ...{ length: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), width: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), height: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }) }
            }) }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, product: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: *, serial: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | ({
                ...{| __typename: 'Forbidden' |},
              ...$Pick<Forbidden, { reason?: * }>,
              ...{ reference?: ?({
                  ...{| __typename: 'Reference' |},
                ...$Pick<Reference, { id: * }>
              }) }
            }) | {| __typename: 'NotFound' |}>, files: Array<({
                ...{| __typename: 'File' |},
              ...$Pick<File, { id: *, path: * }>,
              ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment?: ?({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
        ...{ forwarders: Array<({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), totalVolumeOverride?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), timeline: ({
            ...{| __typename: 'Timeline' |},
          ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
        }), containerTypeCounts: Array<({
            ...{| __typename: 'ContainerTypeCount' |},
          ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
        })>, importer?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, voyages: Array<({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
          ...{ departurePort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), arrivalPort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), departure: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>,
          ...{ customClearance: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
          ...{ warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>,
          ...{ orderItem: ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>,
            ...{ productProvider: ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>,
              ...{ exporter: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
            ...{| __typename: 'Notification' |},
          ...$Pick<Notification, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: * }>,
        ...{ representativeBatch?: ?({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>,
          ...{ orderItem: ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>,
            ...{ productProvider: ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>,
              ...{ product: ({
                  ...{| __typename: 'Product' |},
                ...$Pick<Product, { id: *, name: *, serial: * }>,
                ...{ files: Array<({
                    ...{| __typename: 'File' |},
                  ...$Pick<File, { id: *, path: * }>,
                  ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), batches: Array<({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, no: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type ShipmentFormFilesNewQueryVariables = {
  id: $ElementType<Scalars, 'ID'>
};


export type ShipmentFormFilesNewQuery = ({
    ...{| __typename: 'Query' |},
  ...{ shipment: ({
      ...{| __typename: 'Shipment' |},
    ...$Pick<Shipment, { id: * }>,
    ...{ files: Array<({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
      ...{ tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, entity?: ?({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, poNo: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | ({
          ...{| __typename: 'Partnership' |},
        ...$Pick<Partnership, { id: * }>
      }) | ({
          ...{| __typename: 'Role' |},
        ...$Pick<Role, { id: * }>
      }) | ({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Mask' |},
        ...$Pick<Mask, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'MaskEdit' |},
        ...$Pick<MaskEdit, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, messageGroups: Array<({
        ...{| __typename: 'MessageGroup' |},
      ...$Pick<MessageGroup, { id: *, unreadMessageCount: *, createdAt: *, updatedAt: *, deletedAt?: *, filesUnreadCount: * }>,
      ...{ organizations: Array<({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, createdBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deletedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, files: Array<({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
        ...{ tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, entity?: ?({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: *, name: * }>,
          ...{ product: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ProductProviderPackage' |},
          ...$Pick<ProductProviderPackage, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: *, poNo: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, no: * }>,
          ...{ connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: * }>
        }) | ({
            ...{| __typename: 'Partnership' |},
          ...$Pick<Partnership, { id: * }>
        }) | ({
            ...{| __typename: 'Role' |},
          ...$Pick<Role, { id: * }>
        }) | ({
            ...{| __typename: 'File' |},
          ...$Pick<File, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'Mask' |},
          ...$Pick<Mask, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'MaskEdit' |},
          ...$Pick<MaskEdit, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}> }
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | ({
      ...{| __typename: 'NotFound' |},
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: *, type: *, service: * }>
    }) }
  }) }
});

export type ShipmentFormFilesNewFragment = ({
    ...{| __typename: 'Shipment' |},
  ...$Pick<Shipment, { id: * }>,
  ...{ files: Array<({
      ...{| __typename: 'File' |},
    ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
    ...{ tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, entity?: ?({
        ...{| __typename: 'Product' |},
      ...$Pick<Product, { id: *, name: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ProductProvider' |},
      ...$Pick<ProductProvider, { id: *, name: * }>,
      ...{ product: ({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ProductProviderPackage' |},
      ...$Pick<ProductProviderPackage, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Order' |},
      ...$Pick<Order, { id: *, poNo: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, no: * }>,
      ...{ connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: * }>
    }) | ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: * }>
    }) | ({
        ...{| __typename: 'Partnership' |},
      ...$Pick<Partnership, { id: * }>
    }) | ({
        ...{| __typename: 'Role' |},
      ...$Pick<Role, { id: * }>
    }) | ({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'Mask' |},
      ...$Pick<Mask, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'MaskEdit' |},
      ...$Pick<MaskEdit, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | ({
        ...{| __typename: 'FieldDefinition' |},
      ...$Pick<FieldDefinition, { id: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, messageGroups: Array<({
      ...{| __typename: 'MessageGroup' |},
    ...$Pick<MessageGroup, { id: *, unreadMessageCount: *, createdAt: *, updatedAt: *, deletedAt?: *, filesUnreadCount: * }>,
    ...{ organizations: Array<({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, createdBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deletedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, files: Array<({
        ...{| __typename: 'File' |},
      ...$Pick<File, { id: *, name: *, path: *, type: *, size: *, mimetype: *, memo?: *, createdAt: * }>,
      ...{ tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, entity?: ?({
          ...{| __typename: 'Product' |},
        ...$Pick<Product, { id: *, name: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProvider' |},
        ...$Pick<ProductProvider, { id: *, name: * }>,
        ...{ product: ({
            ...{| __typename: 'Product' |},
          ...$Pick<Product, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ProductProviderPackage' |},
        ...$Pick<ProductProviderPackage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, poNo: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, no: * }>,
        ...{ connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: * }>
      }) | ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: * }>
      }) | ({
          ...{| __typename: 'Partnership' |},
        ...$Pick<Partnership, { id: * }>
      }) | ({
          ...{| __typename: 'Role' |},
        ...$Pick<Role, { id: * }>
      }) | ({
          ...{| __typename: 'File' |},
        ...$Pick<File, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'Mask' |},
        ...$Pick<Mask, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'MaskEdit' |},
        ...$Pick<MaskEdit, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}> }
});

export type ShipmentFormMetadataQueryVariables = {
  id: $ElementType<Scalars, 'ID'>
};


export type ShipmentFormMetadataQuery = ({
    ...{| __typename: 'Query' |},
  ...{ shipment: ({
      ...{| __typename: 'Shipment' |},
    ...$Pick<Shipment, { id: *, archived: *, updatedAt: *, memo?: *, no: *, blNo?: *, blDate?: *, booked?: *, bookingNo?: *, bookingDate?: *, invoiceNo?: *, contractNo?: *, autoTracking?: *, autoTrackingBy?: *, autoTrackingIncludeContainers?: *, autoTrackingTransportType?: *, masterBlNo?: *, incoterm?: *, placeOfReceipt?: *, placeOfDelivery?: *, loadType?: *, carrier?: *, notificationUnseenCount: * }>,
    ...{ updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, followers: Array<({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, email: *, firstName: *, lastName: * }>,
      ...{ roles: Array<({
          ...{| __typename: 'Role' |},
        ...$Pick<Role, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, customFields: ({
        ...{| __typename: 'CustomFields' |},
      ...{ fieldValues: Array<({
          ...{| __typename: 'FieldValue' |},
        ...{ value: ({
            ...{| __typename: 'StringValue' |},
          ...$Pick<StringValue, { string: * }>
        }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }), importer?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, forwarders: Array<({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organizations: Array<({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
        ...{| __typename: 'Notification' |},
      ...$Pick<Notification, { id: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | ({
      ...{| __typename: 'NotFound' |},
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: *, type: *, service: * }>
    }) }
  }) }
});

export type ShipmentFormMetadataFragment = ({
    ...{| __typename: 'Shipment' |},
  ...$Pick<Shipment, { id: *, archived: *, updatedAt: *, memo?: *, no: *, blNo?: *, blDate?: *, booked?: *, bookingNo?: *, bookingDate?: *, invoiceNo?: *, contractNo?: *, autoTracking?: *, autoTrackingBy?: *, autoTrackingIncludeContainers?: *, autoTrackingTransportType?: *, masterBlNo?: *, incoterm?: *, placeOfReceipt?: *, placeOfDelivery?: *, loadType?: *, carrier?: *, notificationUnseenCount: * }>,
  ...{ updatedBy?: ?({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
    ...{ organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, followers: Array<({
      ...{| __typename: 'User' |},
    ...$Pick<User, { id: *, email: *, firstName: *, lastName: * }>,
    ...{ roles: Array<({
        ...{| __typename: 'Role' |},
      ...$Pick<Role, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, organization?: ?({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, customFields: ({
      ...{| __typename: 'CustomFields' |},
    ...{ fieldValues: Array<({
        ...{| __typename: 'FieldValue' |},
      ...{ value: ({
          ...{| __typename: 'StringValue' |},
        ...$Pick<StringValue, { string: * }>
      }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
          ...{| __typename: 'FieldDefinition' |},
        ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }), importer?: ?({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, forwarders: Array<({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organizations: Array<({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, tags: Array<({
      ...{| __typename: 'Tag' |},
    ...$Pick<Tag, { id: *, name: *, color: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | {| __typename: 'NotFound' |}>, ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: *, types: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
      ...{| __typename: 'Connection' |},
    ...$Pick<Connection, { id: *, displayName: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
      ...{| __typename: 'Notification' |},
    ...$Pick<Notification, { id: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type ShipmentFormSummaryNewQueryVariables = {
  id: $ElementType<Scalars, 'ID'>
};


export type ShipmentFormSummaryNewQuery = ({
    ...{| __typename: 'Query' |},
  ...{ shipment: ({
      ...{| __typename: 'Shipment' |},
    ...$Pick<Shipment, { id: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalWeightOverriding: *, totalVolumeOverriding: *, batchCount: *, containerCount: *, orderCount: *, totalPackageQuantity: * }>,
    ...{ totalWeightOverride?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), totalVolumeOverride?: ?({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), batchQuantity: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), containerTypeCounts: Array<({
        ...{| __typename: 'ContainerTypeCount' |},
      ...$Pick<ContainerTypeCount, { count: *, containerType: * }>
    })>, totalWeight: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), totalVolume: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }) }
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | ({
      ...{| __typename: 'NotFound' |},
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: *, type: *, service: * }>
    }) }
  }) }
});

export type ShipmentFormSummaryNewFragment = ({
    ...{| __typename: 'Shipment' |},
  ...$Pick<Shipment, { id: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalWeightOverriding: *, totalVolumeOverriding: *, batchCount: *, containerCount: *, orderCount: *, totalPackageQuantity: * }>,
  ...{ totalWeightOverride?: ?({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), totalVolumeOverride?: ?({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), batchQuantity: ({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), containerTypeCounts: Array<({
      ...{| __typename: 'ContainerTypeCount' |},
    ...$Pick<ContainerTypeCount, { count: *, containerType: * }>
  })>, totalWeight: ({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }), totalVolume: ({
      ...{| __typename: 'MetricValue' |},
    ...$Pick<MetricValue, { value: *, metric: * }>
  }) }
});

export type ShipmentFormOrderListQueryVariables = {
  id: $ElementType<Scalars, 'ID'>
};


export type ShipmentFormOrderListQuery = ({
    ...{| __typename: 'Query' |},
  ...{ shipment: ({
      ...{| __typename: 'Shipment' |},
    ...$Pick<Shipment, { id: * }>,
    ...{ batches: Array<({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: * }>,
      ...{ orderItem: ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: * }>,
        ...{ order: ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
          ...{ totalPrice: ({
              ...{| __typename: 'NewPrice' |},
            ...$Pick<NewPrice, { amount: *, currency: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, timeline: ({
              ...{| __typename: 'Timeline' |},
            ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
          }), ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
              ...{| __typename: 'Notification' |},
            ...$Pick<Notification, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | ({
      ...{| __typename: 'NotFound' |},
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: *, type: *, service: * }>
    }) }
  }) }
});

export type ShipmentFormOrderListFragment = ({
    ...{| __typename: 'Shipment' |},
  ...$Pick<Shipment, { id: * }>,
  ...{ batches: Array<({
      ...{| __typename: 'Batch' |},
    ...$Pick<Batch, { id: * }>,
    ...{ orderItem: ({
        ...{| __typename: 'OrderItem' |},
      ...$Pick<OrderItem, { id: * }>,
      ...{ order: ({
          ...{| __typename: 'Order' |},
        ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
        ...{ totalPrice: ({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, timeline: ({
            ...{| __typename: 'Timeline' |},
          ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
        }), ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
            ...{| __typename: 'Notification' |},
          ...$Pick<Notification, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type ShipmentFormTimelineQueryVariables = {
  id: $ElementType<Scalars, 'ID'>
};


export type ShipmentFormTimelineQuery = ({
    ...{| __typename: 'Query' |},
  ...{ shipment: ({
      ...{| __typename: 'Shipment' |},
    ...$Pick<Shipment, { id: * }>,
    ...{ cargoReady: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, approvedAt?: *, date?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, updatedAt: *, date: *, type: *, memo?: * }>,
        ...{ updatedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReadyWarehouse?: ?({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: *, name: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, voyages: Array<({
        ...{| __typename: 'Voyage' |},
      ...$Pick<Voyage, { id: *, no?: *, vesselName?: *, vesselCode?: * }>,
      ...{ departurePort?: ?({
          ...{| __typename: 'Port' |},
        ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
      }), arrivalPort?: ?({
          ...{| __typename: 'Port' |},
        ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
      }), departure: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, approvedAt?: *, date?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, updatedAt: *, date: *, type: *, memo?: * }>,
          ...{ updatedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, approvedAt?: *, date?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, updatedAt: *, date: *, type: *, memo?: * }>,
          ...{ updatedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
        ...{| __typename: 'ContainerGroup' |},
      ...$Pick<ContainerGroup, { id: * }>,
      ...{ customClearance: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, approvedAt?: *, date?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, updatedAt: *, date: *, type: *, memo?: * }>,
          ...{ updatedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, approvedAt?: *, date?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, updatedAt: *, date: *, type: *, memo?: * }>,
          ...{ updatedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, approvedAt?: *, date?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, updatedAt: *, date: *, type: *, memo?: * }>,
          ...{ updatedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: *, name: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
        ...{| __typename: 'Container' |},
      ...$Pick<Container, { id: *, no: *, archived: *, containerType?: *, containerOption?: *, batchCount: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: *, freeTimeStartDate?: *, freeTimeDuration?: *, updatedAt: *, memo?: *, vanningDate?: *, vanningDateApprovedAt?: *, vanningActualDate?: *, vanningActualDateApprovedAt?: *, autoCalculatedFreeTimeStartDate: *, yardName?: *, departureDate?: *, departureDateApprovedAt?: * }>,
      ...{ totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), warehouse?: ?({
          ...{| __typename: 'Warehouse' |},
        ...$Pick<Warehouse, { id: *, name: *, archived: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, representativeBatch?: ?({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: *, sort: *, shipmentSort: *, archived: *, autoCalculatePackageQuantity: *, autoCalculatePackageVolume: *, updatedAt: *, memo?: *, no: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: *, latestQuantityField: *, producedAt?: *, deliveredAt?: *, desiredAt?: *, expiredAt?: *, packageName?: *, packageCapacity?: *, packageQuantity?: * }>,
        ...{ orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
          ...{ productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: *, name: * }>,
            ...{ product: ({
                ...{| __typename: 'Product' |},
              ...$Pick<Product, { name: *, serial: *, id: * }>,
              ...{ files: Array<({
                  ...{| __typename: 'File' |},
                ...$Pick<File, { id: *, path: * }>,
                ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | ({
                  ...{| __typename: 'Forbidden' |},
                ...$Pick<Forbidden, { reason?: * }>,
                ...{ reference?: ?({
                    ...{| __typename: 'Reference' |},
                  ...$Pick<Reference, { id: * }>
                }) }
              }) | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>,
              ...{ partner?: ?({
                  ...{| __typename: 'Partner' |},
                ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
                ...{ tags: Array<({
                    ...{| __typename: 'Tag' |},
                  ...$Pick<Tag, { id: *, name: *, color: * }>,
                  ...{ ownedBy: ({
                      ...{| __typename: 'Organization' |},
                    ...$Pick<Organization, { id: *, name: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                      ...{| __typename: 'Connection' |},
                    ...$Pick<Connection, { id: *, displayName: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>,
              ...{ partner?: ?({
                  ...{| __typename: 'Partner' |},
                ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
                ...{ tags: Array<({
                    ...{| __typename: 'Tag' |},
                  ...$Pick<Tag, { id: *, name: *, color: * }>,
                  ...{ ownedBy: ({
                      ...{| __typename: 'Organization' |},
                    ...$Pick<Organization, { id: *, name: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                      ...{| __typename: 'Connection' |},
                    ...$Pick<Connection, { id: *, displayName: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
                ...{| __typename: 'ProductProviderPackage' |},
              ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
              ...{ grossWeight?: ?({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), volume?: ?({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), size?: ?({
                  ...{| __typename: 'Size' |},
                ...{ length: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }), width: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }), height: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }) }
              }) }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
                ...{| __typename: 'ProductProviderPackage' |},
              ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
              ...{ grossWeight?: ?({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), volume?: ?({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), size?: ?({
                  ...{| __typename: 'Size' |},
                ...{ length: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }), width: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }), height: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }) }
              }) }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, price: ({
              ...{| __typename: 'NewPrice' |},
            ...$Pick<NewPrice, { amount: *, currency: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, order: ({
              ...{| __typename: 'Order' |},
            ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
            ...{ totalPrice: ({
                ...{| __typename: 'NewPrice' |},
              ...$Pick<NewPrice, { amount: *, currency: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>,
              ...{ partner?: ?({
                  ...{| __typename: 'Partner' |},
                ...$Pick<Partner, { id: *, name?: *, code?: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>,
              ...{ partner?: ?({
                  ...{| __typename: 'Partner' |},
                ...$Pick<Partner, { id: *, name?: *, code?: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | ({
                ...{| __typename: 'Forbidden' |},
              ...$Pick<Forbidden, { reason?: * }>,
              ...{ reference?: ?({
                  ...{| __typename: 'Reference' |},
                ...$Pick<Reference, { id: * }>
              }) }
            }) | {| __typename: 'NotFound' |}>, timeline: ({
                ...{| __typename: 'Timeline' |},
              ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
            }), ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
                ...{| __typename: 'Notification' |},
              ...$Pick<Notification, { id: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), totalPrice?: ?({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
            ...{| __typename: 'CustomFields' |},
          ...{ fieldValues: Array<({
              ...{| __typename: 'FieldValue' |},
            ...{ value: ({
                ...{| __typename: 'StringValue' |},
              ...$Pick<StringValue, { string: * }>
            }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
                ...{| __typename: 'FieldDefinition' |},
              ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: *, types: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }), packageGrossWeight?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), packageVolume?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), packageSize?: ?({
            ...{| __typename: 'Size' |},
          ...{ length: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), width: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), height: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }) }
        }), tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, shipment?: ?({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
          ...{ forwarders: Array<({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolume: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), totalVolumeOverride?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), timeline: ({
              ...{| __typename: 'Timeline' |},
            ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
          }), containerTypeCounts: Array<({
              ...{| __typename: 'ContainerTypeCount' |},
            ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
          })>, importer?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, voyages: Array<({
              ...{| __typename: 'Voyage' |},
            ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
            ...{ departurePort?: ?({
                ...{| __typename: 'Port' |},
              ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
            }), arrivalPort?: ?({
                ...{| __typename: 'Port' |},
              ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
            }), departure: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
              ...{| __typename: 'ContainerGroup' |},
            ...$Pick<ContainerGroup, { id: * }>,
            ...{ customClearance: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
                ...{| __typename: 'Warehouse' |},
              ...$Pick<Warehouse, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
              ...{| __typename: 'Container' |},
            ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
            ...{ warehouse?: ?({
                ...{| __typename: 'Warehouse' |},
              ...$Pick<Warehouse, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: * }>,
            ...{ orderItem: ({
                ...{| __typename: 'OrderItem' |},
              ...$Pick<OrderItem, { id: * }>,
              ...{ productProvider: ({
                  ...{| __typename: 'ProductProvider' |},
                ...$Pick<ProductProvider, { id: * }>,
                ...{ exporter: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
              ...{| __typename: 'Notification' |},
            ...$Pick<Notification, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: *, no: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: * }>,
          ...{ representativeBatch?: ?({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: * }>,
            ...{ orderItem: ({
                ...{| __typename: 'OrderItem' |},
              ...$Pick<OrderItem, { id: * }>,
              ...{ productProvider: ({
                  ...{| __typename: 'ProductProvider' |},
                ...$Pick<ProductProvider, { id: * }>,
                ...{ product: ({
                    ...{| __typename: 'Product' |},
                  ...$Pick<Product, { id: *, name: *, serial: * }>,
                  ...{ files: Array<({
                      ...{| __typename: 'File' |},
                    ...$Pick<File, { id: *, path: * }>,
                    ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), batches: Array<({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
              ...{| __typename: 'Shipment' |},
            ...$Pick<Shipment, { id: *, no: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, terminalArrivalPort?: ?({
          ...{| __typename: 'Port' |},
        ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
      }), terminalArrival: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, vanningDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, vanningActualDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, departureDateApprovedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
          ...{| __typename: 'CustomFields' |},
        ...{ fieldValues: Array<({
            ...{| __typename: 'FieldValue' |},
          ...{ value: ({
              ...{| __typename: 'StringValue' |},
            ...$Pick<StringValue, { string: * }>
          }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }), shipment: ({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
        ...{ totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), totalVolumeOverride?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), timeline: ({
            ...{| __typename: 'Timeline' |},
          ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
        }), containerTypeCounts: Array<({
            ...{| __typename: 'ContainerTypeCount' |},
          ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
        })>, importer?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, voyages: Array<({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
          ...{ departurePort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), arrivalPort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), departure: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>,
          ...{ customClearance: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
          ...{ warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>,
          ...{ orderItem: ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>,
            ...{ productProvider: ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>,
              ...{ exporter: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
            ...{| __typename: 'Notification' |},
          ...$Pick<Notification, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: *, sort: *, shipmentSort: *, archived: *, autoCalculatePackageQuantity: *, autoCalculatePackageVolume: *, updatedAt: *, memo?: *, no: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: *, latestQuantityField: *, producedAt?: *, deliveredAt?: *, desiredAt?: *, expiredAt?: *, packageName?: *, packageCapacity?: *, packageQuantity?: * }>,
        ...{ updatedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), totalPrice?: ?({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
            ...{| __typename: 'CustomFields' |},
          ...{ fieldValues: Array<({
              ...{| __typename: 'FieldValue' |},
            ...{ value: ({
                ...{| __typename: 'StringValue' |},
              ...$Pick<StringValue, { string: * }>
            }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
                ...{| __typename: 'FieldDefinition' |},
              ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: *, types: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }), packageGrossWeight?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), packageVolume?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), packageSize?: ?({
            ...{| __typename: 'Size' |},
          ...{ length: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), width: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), height: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }) }
        }), tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
          ...{ price: ({
              ...{| __typename: 'NewPrice' |},
            ...$Pick<NewPrice, { amount: *, currency: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, order: ({
              ...{| __typename: 'Order' |},
            ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
            ...{ totalPrice: ({
                ...{| __typename: 'NewPrice' |},
              ...$Pick<NewPrice, { amount: *, currency: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>,
              ...{ partner?: ?({
                  ...{| __typename: 'Partner' |},
                ...$Pick<Partner, { id: *, name?: *, code?: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>,
              ...{ partner?: ?({
                  ...{| __typename: 'Partner' |},
                ...$Pick<Partner, { id: *, name?: *, code?: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | ({
                ...{| __typename: 'Forbidden' |},
              ...$Pick<Forbidden, { reason?: * }>,
              ...{ reference?: ?({
                  ...{| __typename: 'Reference' |},
                ...$Pick<Reference, { id: * }>
              }) }
            }) | {| __typename: 'NotFound' |}>, timeline: ({
                ...{| __typename: 'Timeline' |},
              ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
            }), ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
                ...{| __typename: 'Notification' |},
              ...$Pick<Notification, { id: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: *, name: * }>,
            ...{ exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>,
              ...{ partner?: ?({
                  ...{| __typename: 'Partner' |},
                ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
                ...{ tags: Array<({
                    ...{| __typename: 'Tag' |},
                  ...$Pick<Tag, { id: *, name: *, color: * }>,
                  ...{ ownedBy: ({
                      ...{| __typename: 'Organization' |},
                    ...$Pick<Organization, { id: *, name: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                      ...{| __typename: 'Connection' |},
                    ...$Pick<Connection, { id: *, displayName: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>,
              ...{ partner?: ?({
                  ...{| __typename: 'Partner' |},
                ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
                ...{ tags: Array<({
                    ...{| __typename: 'Tag' |},
                  ...$Pick<Tag, { id: *, name: *, color: * }>,
                  ...{ ownedBy: ({
                      ...{| __typename: 'Organization' |},
                    ...$Pick<Organization, { id: *, name: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                      ...{| __typename: 'Connection' |},
                    ...$Pick<Connection, { id: *, displayName: * }>
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
                ...{| __typename: 'ProductProviderPackage' |},
              ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
              ...{ grossWeight?: ?({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), volume?: ?({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), size?: ?({
                  ...{| __typename: 'Size' |},
                ...{ length: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }), width: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }), height: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }) }
              }) }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
                ...{| __typename: 'ProductProviderPackage' |},
              ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
              ...{ grossWeight?: ?({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), volume?: ?({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), size?: ?({
                  ...{| __typename: 'Size' |},
                ...{ length: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }), width: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }), height: ({
                    ...{| __typename: 'MetricValue' |},
                  ...$Pick<MetricValue, { value: *, metric: * }>
                }) }
              }) }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, product: ({
                ...{| __typename: 'Product' |},
              ...$Pick<Product, { id: *, name: *, serial: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | ({
                  ...{| __typename: 'Forbidden' |},
                ...$Pick<Forbidden, { reason?: * }>,
                ...{ reference?: ?({
                    ...{| __typename: 'Reference' |},
                  ...$Pick<Reference, { id: * }>
                }) }
              }) | {| __typename: 'NotFound' |}>, files: Array<({
                  ...{| __typename: 'File' |},
                ...$Pick<File, { id: *, path: * }>,
                ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment?: ?({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
          ...{ forwarders: Array<({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolume: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), totalVolumeOverride?: ?({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), timeline: ({
              ...{| __typename: 'Timeline' |},
            ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
          }), containerTypeCounts: Array<({
              ...{| __typename: 'ContainerTypeCount' |},
            ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
          })>, importer?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, voyages: Array<({
              ...{| __typename: 'Voyage' |},
            ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
            ...{ departurePort?: ?({
                ...{| __typename: 'Port' |},
              ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
            }), arrivalPort?: ?({
                ...{| __typename: 'Port' |},
              ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
            }), departure: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
              ...{| __typename: 'ContainerGroup' |},
            ...$Pick<ContainerGroup, { id: * }>,
            ...{ customClearance: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
                ...{| __typename: 'TimelineDate' |},
              ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
              ...{ approvedBy?: ?({
                  ...{| __typename: 'User' |},
                ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
                ...{ organization?: ?({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                  ...{| __typename: 'TimelineDateRevision' |},
                ...$Pick<TimelineDateRevision, { id: *, date: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
                ...{| __typename: 'Warehouse' |},
              ...$Pick<Warehouse, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
              ...{| __typename: 'Container' |},
            ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
            ...{ warehouse?: ?({
                ...{| __typename: 'Warehouse' |},
              ...$Pick<Warehouse, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: * }>,
            ...{ orderItem: ({
                ...{| __typename: 'OrderItem' |},
              ...$Pick<OrderItem, { id: * }>,
              ...{ productProvider: ({
                  ...{| __typename: 'ProductProvider' |},
                ...$Pick<ProductProvider, { id: * }>,
                ...{ exporter: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
              ...{| __typename: 'Notification' |},
            ...$Pick<Notification, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: *, no: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: * }>,
          ...{ representativeBatch?: ?({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: * }>,
            ...{ orderItem: ({
                ...{| __typename: 'OrderItem' |},
              ...$Pick<OrderItem, { id: * }>,
              ...{ productProvider: ({
                  ...{| __typename: 'ProductProvider' |},
                ...$Pick<ProductProvider, { id: * }>,
                ...{ product: ({
                    ...{| __typename: 'Product' |},
                  ...$Pick<Product, { id: *, name: *, serial: * }>,
                  ...{ files: Array<({
                      ...{| __typename: 'File' |},
                    ...$Pick<File, { id: *, path: * }>,
                    ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
                  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
              ...{| __typename: 'MetricValue' |},
            ...$Pick<MetricValue, { value: *, metric: * }>
          }), batches: Array<({
              ...{| __typename: 'Batch' |},
            ...$Pick<Batch, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
              ...{| __typename: 'Shipment' |},
            ...$Pick<Shipment, { id: *, no: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | ({
      ...{| __typename: 'BadRequest' |},
    ...{ violations: Array<({
        ...{| __typename: 'Violation' |},
      ...$Pick<Violation, { message: *, error: *, code: *, path: * }>,
      ...{ parameters: Array<({
          ...{| __typename: 'ViolationParameter' |},
        ...$Pick<ViolationParameter, { key: *, value: * }>
      })> }
    })> }
  }) | ({
      ...{| __typename: 'Forbidden' |},
    ...$Pick<Forbidden, { reason?: * }>,
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: * }>
    }) }
  }) | ({
      ...{| __typename: 'NotFound' |},
    ...{ reference?: ?({
        ...{| __typename: 'Reference' |},
      ...$Pick<Reference, { id: *, type: *, service: * }>
    }) }
  }) }
});

export type ShipmentFormTimelineFragment = ({
    ...{| __typename: 'Shipment' |},
  ...$Pick<Shipment, { id: * }>,
  ...{ cargoReady: ({
      ...{| __typename: 'TimelineDate' |},
    ...$Pick<TimelineDate, { id: *, approvedAt?: *, date?: *, resultDate?: * }>,
    ...{ approvedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
        ...{| __typename: 'TimelineDateRevision' |},
      ...$Pick<TimelineDateRevision, { id: *, updatedAt: *, date: *, type: *, memo?: * }>,
      ...{ updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReadyWarehouse?: ?({
      ...{| __typename: 'Warehouse' |},
    ...$Pick<Warehouse, { id: *, name: * }>,
    ...{ ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, voyages: Array<({
      ...{| __typename: 'Voyage' |},
    ...$Pick<Voyage, { id: *, no?: *, vesselName?: *, vesselCode?: * }>,
    ...{ departurePort?: ?({
        ...{| __typename: 'Port' |},
      ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
    }), arrivalPort?: ?({
        ...{| __typename: 'Port' |},
      ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
    }), departure: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, approvedAt?: *, date?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, updatedAt: *, date: *, type: *, memo?: * }>,
        ...{ updatedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, approvedAt?: *, date?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, updatedAt: *, date: *, type: *, memo?: * }>,
        ...{ updatedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
      ...{| __typename: 'ContainerGroup' |},
    ...$Pick<ContainerGroup, { id: * }>,
    ...{ customClearance: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, approvedAt?: *, date?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, updatedAt: *, date: *, type: *, memo?: * }>,
        ...{ updatedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, approvedAt?: *, date?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, updatedAt: *, date: *, type: *, memo?: * }>,
        ...{ updatedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, approvedAt?: *, date?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, updatedAt: *, date: *, type: *, memo?: * }>,
        ...{ updatedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: *, name: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
      ...{| __typename: 'Container' |},
    ...$Pick<Container, { id: *, no: *, archived: *, containerType?: *, containerOption?: *, batchCount: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: *, freeTimeStartDate?: *, freeTimeDuration?: *, updatedAt: *, memo?: *, vanningDate?: *, vanningDateApprovedAt?: *, vanningActualDate?: *, vanningActualDateApprovedAt?: *, autoCalculatedFreeTimeStartDate: *, yardName?: *, departureDate?: *, departureDateApprovedAt?: * }>,
    ...{ totalVolume: ({
        ...{| __typename: 'MetricValue' |},
      ...$Pick<MetricValue, { value: *, metric: * }>
    }), warehouse?: ?({
        ...{| __typename: 'Warehouse' |},
      ...$Pick<Warehouse, { id: *, name: *, archived: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
        ...{| __typename: 'Tag' |},
      ...$Pick<Tag, { id: *, name: *, color: * }>,
      ...{ ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | ({
        ...{| __typename: 'Forbidden' |},
      ...$Pick<Forbidden, { reason?: * }>,
      ...{ reference?: ?({
          ...{| __typename: 'Reference' |},
        ...$Pick<Reference, { id: * }>
      }) }
    }) | {| __typename: 'NotFound' |}>, representativeBatch?: ?({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: *, sort: *, shipmentSort: *, archived: *, autoCalculatePackageQuantity: *, autoCalculatePackageVolume: *, updatedAt: *, memo?: *, no: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: *, latestQuantityField: *, producedAt?: *, deliveredAt?: *, desiredAt?: *, expiredAt?: *, packageName?: *, packageCapacity?: *, packageQuantity?: * }>,
      ...{ orderItem: ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
        ...{ productProvider: ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: *, name: * }>,
          ...{ product: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { name: *, serial: *, id: * }>,
            ...{ files: Array<({
                ...{| __typename: 'File' |},
              ...$Pick<File, { id: *, path: * }>,
              ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | ({
                ...{| __typename: 'Forbidden' |},
              ...$Pick<Forbidden, { reason?: * }>,
              ...{ reference?: ?({
                  ...{| __typename: 'Reference' |},
                ...$Pick<Reference, { id: * }>
              }) }
            }) | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
            ...{ grossWeight?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), volume?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), size?: ?({
                ...{| __typename: 'Size' |},
              ...{ length: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), width: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), height: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }) }
            }) }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
            ...{ grossWeight?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), volume?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), size?: ?({
                ...{| __typename: 'Size' |},
              ...{ length: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), width: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), height: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }) }
            }) }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, price: ({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, order: ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
          ...{ totalPrice: ({
              ...{| __typename: 'NewPrice' |},
            ...$Pick<NewPrice, { amount: *, currency: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, timeline: ({
              ...{| __typename: 'Timeline' |},
            ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
          }), ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
              ...{| __typename: 'Notification' |},
            ...$Pick<Notification, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalPrice?: ?({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
          ...{| __typename: 'CustomFields' |},
        ...{ fieldValues: Array<({
            ...{| __typename: 'FieldValue' |},
          ...{ value: ({
              ...{| __typename: 'StringValue' |},
            ...$Pick<StringValue, { string: * }>
          }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }), packageGrossWeight?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), packageVolume?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), packageSize?: ?({
          ...{| __typename: 'Size' |},
        ...{ length: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), width: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), height: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }) }
      }), tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, shipment?: ?({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
        ...{ forwarders: Array<({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), totalVolumeOverride?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), timeline: ({
            ...{| __typename: 'Timeline' |},
          ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
        }), containerTypeCounts: Array<({
            ...{| __typename: 'ContainerTypeCount' |},
          ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
        })>, importer?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, voyages: Array<({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
          ...{ departurePort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), arrivalPort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), departure: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>,
          ...{ customClearance: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
          ...{ warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>,
          ...{ orderItem: ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>,
            ...{ productProvider: ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>,
              ...{ exporter: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
            ...{| __typename: 'Notification' |},
          ...$Pick<Notification, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: * }>,
        ...{ representativeBatch?: ?({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>,
          ...{ orderItem: ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>,
            ...{ productProvider: ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>,
              ...{ product: ({
                  ...{| __typename: 'Product' |},
                ...$Pick<Product, { id: *, name: *, serial: * }>,
                ...{ files: Array<({
                    ...{| __typename: 'File' |},
                  ...$Pick<File, { id: *, path: * }>,
                  ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), batches: Array<({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, no: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, updatedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, terminalArrivalPort?: ?({
        ...{| __typename: 'Port' |},
      ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
    }), terminalArrival: ({
        ...{| __typename: 'TimelineDate' |},
      ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
      ...{ approvedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
          ...{| __typename: 'TimelineDateRevision' |},
        ...$Pick<TimelineDateRevision, { id: *, date: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, vanningDateApprovedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, vanningActualDateApprovedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, departureDateApprovedBy?: ?({
        ...{| __typename: 'User' |},
      ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
      ...{ organization?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
        ...{| __typename: 'CustomFields' |},
      ...{ fieldValues: Array<({
          ...{| __typename: 'FieldValue' |},
        ...{ value: ({
            ...{| __typename: 'StringValue' |},
          ...$Pick<StringValue, { string: * }>
        }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
            ...{| __typename: 'FieldDefinition' |},
          ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
    }), shipment: ({
        ...{| __typename: 'Shipment' |},
      ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
      ...{ totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalVolumeOverride?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), timeline: ({
          ...{| __typename: 'Timeline' |},
        ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
      }), containerTypeCounts: Array<({
          ...{| __typename: 'ContainerTypeCount' |},
        ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
      })>, importer?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>,
        ...{ partner?: ?({
            ...{| __typename: 'Partner' |},
          ...$Pick<Partner, { id: *, name?: *, code?: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
          ...{| __typename: 'TimelineDate' |},
        ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
        ...{ approvedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
          ...{ organization?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
            ...{| __typename: 'TimelineDateRevision' |},
          ...$Pick<TimelineDateRevision, { id: *, date: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, voyages: Array<({
          ...{| __typename: 'Voyage' |},
        ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
        ...{ departurePort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), arrivalPort?: ?({
            ...{| __typename: 'Port' |},
          ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
        }), departure: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
          ...{| __typename: 'ContainerGroup' |},
        ...$Pick<ContainerGroup, { id: * }>,
        ...{ customClearance: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
        ...{ warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
          ...{| __typename: 'Batch' |},
        ...$Pick<Batch, { id: * }>,
        ...{ orderItem: ({
            ...{| __typename: 'OrderItem' |},
          ...$Pick<OrderItem, { id: * }>,
          ...{ productProvider: ({
              ...{| __typename: 'ProductProvider' |},
            ...$Pick<ProductProvider, { id: * }>,
            ...{ exporter: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
          ...{| __typename: 'Notification' |},
        ...$Pick<Notification, { id: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, batches: Array<({
        ...{| __typename: 'Batch' |},
      ...$Pick<Batch, { id: *, sort: *, shipmentSort: *, archived: *, autoCalculatePackageQuantity: *, autoCalculatePackageVolume: *, updatedAt: *, memo?: *, no: *, quantity: *, producedQuantity?: *, preShippedQuantity?: *, shippedQuantity?: *, postShippedQuantity?: *, deliveredQuantity?: *, latestQuantity: *, latestQuantityField: *, producedAt?: *, deliveredAt?: *, desiredAt?: *, expiredAt?: *, packageName?: *, packageCapacity?: *, packageQuantity?: * }>,
      ...{ updatedBy?: ?({
          ...{| __typename: 'User' |},
        ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
        ...{ organization?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), totalPrice?: ?({
          ...{| __typename: 'NewPrice' |},
        ...$Pick<NewPrice, { amount: *, currency: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, customFields: ({
          ...{| __typename: 'CustomFields' |},
        ...{ fieldValues: Array<({
            ...{| __typename: 'FieldValue' |},
          ...{ value: ({
              ...{| __typename: 'StringValue' |},
            ...$Pick<StringValue, { string: * }>
          }) | {| __typename: 'IntValue' |} | {| __typename: 'FloatValue' |} | {| __typename: 'BooleanValue' |} | {| __typename: 'DateTimeValue' |} | {| __typename: 'IntervalValue' |} | {| __typename: 'DurationValue' |} | {| __typename: 'MetricValueValue' |} | {| __typename: 'PriceValue' |} | {| __typename: 'SizeValue' |} | {| __typename: 'EntityValue' |} | {| __typename: 'Values' |}, fieldDefinition: ({
              ...{| __typename: 'FieldDefinition' |},
            ...$Pick<FieldDefinition, { id: *, name: *, entityType: *, type: *, sort: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: *, types: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, entity: {| __typename: 'Product' |} | {| __typename: 'ProductProvider' |} | {| __typename: 'ProductProviderPackage' |} | {| __typename: 'Order' |} | {| __typename: 'OrderItem' |} | {| __typename: 'Batch' |} | {| __typename: 'Shipment' |} | {| __typename: 'Voyage' |} | {| __typename: 'Container' |} | {| __typename: 'ContainerGroup' |} | {| __typename: 'TimelineDate' |} | {| __typename: 'TimelineDateRevision' |} | {| __typename: 'Warehouse' |} | {| __typename: 'Tag' |} | {| __typename: 'User' |} | {| __typename: 'Organization' |} | {| __typename: 'Partnership' |} | {| __typename: 'Role' |} | {| __typename: 'File' |} | {| __typename: 'Mask' |} | {| __typename: 'MaskEdit' |} | {| __typename: 'FieldDefinition' |} | {| __typename: 'FieldValue' |} | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
      }), packageGrossWeight?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), packageVolume?: ?({
          ...{| __typename: 'MetricValue' |},
        ...$Pick<MetricValue, { value: *, metric: * }>
      }), packageSize?: ?({
          ...{| __typename: 'Size' |},
        ...{ length: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), width: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), height: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }) }
      }), tags: Array<({
          ...{| __typename: 'Tag' |},
        ...$Pick<Tag, { id: *, name: *, color: * }>,
        ...{ ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | ({
          ...{| __typename: 'Forbidden' |},
        ...$Pick<Forbidden, { reason?: * }>,
        ...{ reference?: ?({
            ...{| __typename: 'Reference' |},
          ...$Pick<Reference, { id: * }>
        }) }
      }) | {| __typename: 'NotFound' |}>, orderItem: ({
          ...{| __typename: 'OrderItem' |},
        ...$Pick<OrderItem, { id: *, archived: *, no: *, quantity: *, totalBatched: *, totalShipped: *, batchCount: *, batchShippedCount: * }>,
        ...{ price: ({
            ...{| __typename: 'NewPrice' |},
          ...$Pick<NewPrice, { amount: *, currency: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, order: ({
            ...{| __typename: 'Order' |},
          ...$Pick<Order, { id: *, archived: *, poNo: *, issuedAt?: *, deliveryDate?: *, totalOrdered: *, totalBatched: *, totalShipped: *, orderItemCount: *, batchCount: *, batchShippedCount: *, currency: *, filesUnreadCount: *, notificationUnseenCount: * }>,
          ...{ totalPrice: ({
              ...{| __typename: 'NewPrice' |},
            ...$Pick<NewPrice, { amount: *, currency: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, importer: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
              ...{| __typename: 'Tag' |},
            ...$Pick<Tag, { id: *, name: *, color: * }>,
            ...{ ownedBy: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                ...{| __typename: 'Connection' |},
              ...$Pick<Connection, { id: *, displayName: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | ({
              ...{| __typename: 'Forbidden' |},
            ...$Pick<Forbidden, { reason?: * }>,
            ...{ reference?: ?({
                ...{| __typename: 'Reference' |},
              ...$Pick<Reference, { id: * }>
            }) }
          }) | {| __typename: 'NotFound' |}>, timeline: ({
              ...{| __typename: 'Timeline' |},
            ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
          }), ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
              ...{| __typename: 'Notification' |},
            ...$Pick<Notification, { id: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, productProvider: ({
            ...{| __typename: 'ProductProvider' |},
          ...$Pick<ProductProvider, { id: *, name: * }>,
          ...{ exporter: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, supplier?: ?({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: *, types: * }>,
            ...{ partner?: ?({
                ...{| __typename: 'Partner' |},
              ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
              ...{ tags: Array<({
                  ...{| __typename: 'Tag' |},
                ...$Pick<Tag, { id: *, name: *, color: * }>,
                ...{ ownedBy: ({
                    ...{| __typename: 'Organization' |},
                  ...$Pick<Organization, { id: *, name: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                    ...{| __typename: 'Connection' |},
                  ...$Pick<Connection, { id: *, displayName: * }>
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, defaultPackage?: ?({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
            ...{ grossWeight?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), volume?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), size?: ?({
                ...{| __typename: 'Size' |},
              ...{ length: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), width: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), height: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }) }
            }) }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, packages: Array<({
              ...{| __typename: 'ProductProviderPackage' |},
            ...$Pick<ProductProviderPackage, { id: *, name?: *, capacity?: *, autoCalculateVolume: * }>,
            ...{ grossWeight?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), volume?: ?({
                ...{| __typename: 'MetricValue' |},
              ...$Pick<MetricValue, { value: *, metric: * }>
            }), size?: ?({
                ...{| __typename: 'Size' |},
              ...{ length: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), width: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }), height: ({
                  ...{| __typename: 'MetricValue' |},
                ...$Pick<MetricValue, { value: *, metric: * }>
              }) }
            }) }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, product: ({
              ...{| __typename: 'Product' |},
            ...$Pick<Product, { id: *, name: *, serial: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | ({
                ...{| __typename: 'Forbidden' |},
              ...$Pick<Forbidden, { reason?: * }>,
              ...{ reference?: ?({
                  ...{| __typename: 'Reference' |},
                ...$Pick<Reference, { id: * }>
              }) }
            }) | {| __typename: 'NotFound' |}>, files: Array<({
                ...{| __typename: 'File' |},
              ...$Pick<File, { id: *, path: * }>,
              ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment?: ?({
          ...{| __typename: 'Shipment' |},
        ...$Pick<Shipment, { id: *, archived: *, no: *, blNo?: *, booked?: *, batchCount: *, totalPackageQuantity: *, totalPackageQuantityOverriding: *, totalPackageQuantityOverride?: *, totalVolumeOverriding: *, orderItemCount: *, filesUnreadCount: *, notificationUnseenCount: * }>,
        ...{ forwarders: Array<({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: *, types: * }>,
            ...{ tags: Array<({
                ...{| __typename: 'Tag' |},
              ...$Pick<Tag, { id: *, name: *, color: * }>,
              ...{ ownedBy: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
                  ...{| __typename: 'Connection' |},
                ...$Pick<Connection, { id: *, displayName: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, organization: ({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), totalVolumeOverride?: ?({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), timeline: ({
            ...{| __typename: 'Timeline' |},
          ...$Pick<Timeline, { unreadCount: *, unreadMessageCount: * }>
        }), containerTypeCounts: Array<({
            ...{| __typename: 'ContainerTypeCount' |},
          ...$Pick<ContainerTypeCount, { containerType: *, count: * }>
        })>, importer?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, exporter?: ?({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>,
          ...{ partner?: ?({
              ...{| __typename: 'Partner' |},
            ...$Pick<Partner, { id: *, name?: *, code?: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, cargoReady: ({
            ...{| __typename: 'TimelineDate' |},
          ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
          ...{ approvedBy?: ?({
              ...{| __typename: 'User' |},
            ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
            ...{ organization?: ?({
                ...{| __typename: 'Organization' |},
              ...$Pick<Organization, { id: *, name: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
              ...{| __typename: 'TimelineDateRevision' |},
            ...$Pick<TimelineDateRevision, { id: *, date: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}>, voyages: Array<({
            ...{| __typename: 'Voyage' |},
          ...$Pick<Voyage, { id: *, no?: *, vesselName?: * }>,
          ...{ departurePort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), arrivalPort?: ?({
              ...{| __typename: 'Port' |},
            ...$Pick<Port, { transportType: *, code: *, codeType?: *, name: *, seaport?: *, airport?: *, road?: *, rail?: *, dryport?: * }>
          }), departure: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, arrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containerGroups: Array<({
            ...{| __typename: 'ContainerGroup' |},
          ...$Pick<ContainerGroup, { id: * }>,
          ...{ customClearance: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrival: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, deliveryReady: ({
              ...{| __typename: 'TimelineDate' |},
            ...$Pick<TimelineDate, { id: *, date?: *, latestDate?: *, currentDate?: *, approvedAt?: *, resultDate?: * }>,
            ...{ approvedBy?: ?({
                ...{| __typename: 'User' |},
              ...$Pick<User, { id: *, firstName: *, lastName: *, email: * }>,
              ...{ organization?: ?({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, timelineDateRevisions: Array<({
                ...{| __typename: 'TimelineDateRevision' |},
              ...$Pick<TimelineDateRevision, { id: *, date: * }>
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, containers: Array<({
            ...{| __typename: 'Container' |},
          ...$Pick<Container, { id: *, no: *, freeTimeStartDate?: *, freeTimeDuration?: *, containerType?: *, warehouseArrivalAgreedDate?: *, warehouseArrivalAgreedDateApprovedAt?: *, warehouseArrivalActualDate?: *, warehouseArrivalActualDateApprovedAt?: * }>,
          ...{ warehouse?: ?({
              ...{| __typename: 'Warehouse' |},
            ...$Pick<Warehouse, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, batches: Array<({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>,
          ...{ orderItem: ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>,
            ...{ productProvider: ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>,
              ...{ exporter: ({
                  ...{| __typename: 'Organization' |},
                ...$Pick<Organization, { id: *, name: * }>
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
            ...{| __typename: 'Organization' |},
          ...$Pick<Organization, { id: *, name: *, types: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, notificationUnseen: Array<({
            ...{| __typename: 'Notification' |},
          ...$Pick<Notification, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, connectionBy?: ?({
            ...{| __typename: 'Connection' |},
          ...$Pick<Connection, { id: *, displayName: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, container?: ?({
          ...{| __typename: 'Container' |},
        ...$Pick<Container, { id: *, no: *, warehouseArrivalAgreedDate?: *, warehouseArrivalActualDate?: * }>,
        ...{ representativeBatch?: ?({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>,
          ...{ orderItem: ({
              ...{| __typename: 'OrderItem' |},
            ...$Pick<OrderItem, { id: * }>,
            ...{ productProvider: ({
                ...{| __typename: 'ProductProvider' |},
              ...$Pick<ProductProvider, { id: * }>,
              ...{ product: ({
                  ...{| __typename: 'Product' |},
                ...$Pick<Product, { id: *, name: *, serial: * }>,
                ...{ files: Array<({
                    ...{| __typename: 'File' |},
                  ...$Pick<File, { id: *, path: * }>,
                  ...{ pathSmall: $ElementType<File, 'path'>, pathMedium: $ElementType<File, 'path'>, pathLarge: $ElementType<File, 'path'> }
                }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
              }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
            }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, totalVolume: ({
            ...{| __typename: 'MetricValue' |},
          ...$Pick<MetricValue, { value: *, metric: * }>
        }), batches: Array<({
            ...{| __typename: 'Batch' |},
          ...$Pick<Batch, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, warehouse?: ?({
            ...{| __typename: 'Warehouse' |},
          ...$Pick<Warehouse, { id: *, name: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalAgreedDateApprovedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, warehouseArrivalActualDateApprovedBy?: ?({
            ...{| __typename: 'User' |},
          ...$Pick<User, { id: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, shipment: ({
            ...{| __typename: 'Shipment' |},
          ...$Pick<Shipment, { id: *, no: * }>
        }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, tags: Array<({
            ...{| __typename: 'Tag' |},
          ...$Pick<Tag, { id: *, name: *, color: * }>,
          ...{ ownedBy: ({
              ...{| __typename: 'Organization' |},
            ...$Pick<Organization, { id: *, name: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
              ...{| __typename: 'Connection' |},
            ...$Pick<Connection, { id: *, displayName: * }>
          }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
        }) | {| __typename: 'BadRequest' |} | ({
            ...{| __typename: 'Forbidden' |},
          ...$Pick<Forbidden, { reason?: * }>,
          ...{ reference?: ?({
              ...{| __typename: 'Reference' |},
            ...$Pick<Reference, { id: * }>
          }) }
        }) | {| __typename: 'NotFound' |}> }
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, ownedBy: ({
          ...{| __typename: 'Organization' |},
        ...$Pick<Organization, { id: *, name: *, types: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
          ...{| __typename: 'Connection' |},
        ...$Pick<Connection, { id: *, displayName: * }>
      }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}>, ownedBy: ({
        ...{| __typename: 'Organization' |},
      ...$Pick<Organization, { id: *, name: *, types: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}, connectionBy?: ?({
        ...{| __typename: 'Connection' |},
      ...$Pick<Connection, { id: *, displayName: * }>
    }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |}> }
});

export type ShipmentFormTimelineWarehouseFragment = ({
    ...{| __typename: 'Warehouse' |},
  ...$Pick<Warehouse, { id: *, name: * }>,
  ...{ ownedBy: ({
      ...{| __typename: 'Organization' |},
    ...$Pick<Organization, { id: *, name: * }>
  }) | {| __typename: 'BadRequest' |} | {| __typename: 'Forbidden' |} | {| __typename: 'NotFound' |} }
});
